import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapAlert} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import Dialog from '../../../../ui/Dialog';

class DialogSheetError extends Component {

	render() {
		let {errorMessage, action} = this.props;
		return (
			<Dialog title={'Error Message'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<div>
								Unfortunately, this sheet has errors.<br/>
								Please email support so we can assist you.
							</div>
							<div style={{color: partnerTapAlert, padding: 20}}>
								{errorMessage}
							</div>
							<div>
								<PrimaryButton label={'Send Error To Support'}
											   onClick={() => {
												   window.open('mailto:support@partnertap.com?subject=Channel Sheet Upload Error&body=' + errorMessage);
											   }}/>
							</div>
						</div>
					}
					yesAction={action}/>
		);
	}
}

DialogSheetError.propTypes = {
	errorMessage: PropTypes.string.isRequired,
	action: PropTypes.func.isRequired
};

export default DialogSheetError;
