import {Settings} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../endpoints/ManagerAnalyticsEndpoints';
import EnvHelper from '../../helpers/EnvHelper';
import SortHelper from '../../helpers/SortHelper';
import CheckboxButton from '../../ui/buttons/CheckboxButton';
import PagingBase from '../../ui/lists/PagingBase';

class ManagerSettingsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.orgName = this.props.managerAnalyticsState.orgName;
		this.state.mapIsFeatured = new Map();
		this.state.errorMessage = null;

		this.handleCheck = this.handleCheck.bind(this);
	}

	get storageKeyBase() {
		return 'manager_settings';
	}

	get title() {
		return 'Settings';
	}

	get icon() {
		return Settings;
	}

	get columnData() {
		return [
			{title: 'Partner Company', key: 'name'},
			{title: 'Featured Partners', key: 'actions'}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		Promise.all([
			ManagerAnalyticsEndpoints.fetchAllPartnerOrganizations(),
			ManagerAnalyticsEndpoints.fetchFeaturedPartners()
		])
		.then((results) => {
			if (this.unmounted) return;
			let rowData = [];
			let partnerOrgs = results[0].payload.partnerOrganizations;
			let partnerOrgsFeatured = results[1].payload;
			if (partnerOrgs) {
				let mapIsFeatured = new Map(partnerOrgs.map((partner) => [partner.name, false]));
				partnerOrgsFeatured.forEach((name) => mapIsFeatured.set(name, true));
				mapIsFeatured.forEach((value, key) => {
					rowData.push({
						name: key,
						actions: <CheckboxButton checked={value}
												 disabled={EnvHelper.isSpoofing}
												 onChange={(event) => this.handleCheck(key, event.target.checked)}/>
					});
				});
				rowData = SortHelper.filterSearchObjectContains(params.search, rowData, SortHelper.keysFromColumns(this.state.columnData));
			}
			this.processData(params, {payload: rowData});
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	handleCheck(partnerOrgName, isFeatured) {
		ManagerAnalyticsEndpoints.setFeaturedPartners(partnerOrgName, isFeatured)
		.then(() => {
			if (this.unmounted) return;
			this.setState({loading: true});
		})
		.catch((error) => {
			console.error('Error setting org as featured partner', error);
			this.setState({errorMessage: 'Error setting org as featured partner: ' + error, loading: false});
		});
	}
}

ManagerSettingsPage.propTypes = {
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(ManagerSettingsPage);
