import {Forum} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as ProfileEndpoints from '../../../endpoints/ProfilesEndpoints';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import ChatButton from '../../../ui/buttons/ChatButton';
import Loading from '../../../ui/Loading';
import SubNav from '../../../ui/navigation/SubNav';
import PageHeader from '../../../ui/PageHeader';
import IntelPage from '../../shared/IntelPage';
import PartnerAccountsPage from './PartnerAccountsPage';

class PartnerDetailsRouter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, partnerProfile: null, activeTab: 'accounts'};
		this.fetchPartner = this.fetchPartner.bind(this);
	}

	componentDidMount() {
		this.fetchPartner();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchPartner() {
		this.setState({loading: true});
		ProfileEndpoints.fetchProfile(this.props.match.params.partnerPersonId)
		.then((result) => {
				if (this.unmounted) return;
				this.setState({loading: false, partnerProfile: result.payload, activeTab: 'accounts'});
			}
		);
	}

	render() {
		if (this.state.loading) return <Loading>Loading Partner Connection Details...</Loading>;
		let partner = this.state.partnerProfile;
		let subNavData = [
			{
				label: 'Accounts',
				glyph: '5',
				route: Routes.DASHBOARD.PARTNERS.ROUTE + '/' + partner.partnerId + '/' + partner.personId + '/accounts'
			},
			{
				label: 'Intel',
				MaterialIcon: Forum,
				route: Routes.DASHBOARD.PARTNERS.ROUTE + '/' + partner.partnerId + '/' + partner.personId + '/intel'
			}
		];

		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<div>
					<PageHeader personId={partner.personId}
								partnerId={partner.id}
								title={partner.firstName + ' ' + partner.lastName}
								subtitle={partner.organization}>
						<div style={{display: 'flex', justifyContent: 'flex-end'}}>
							<ChatButton isNewChat={true} partner={partner}/>
						</div>
					</PageHeader>
				</div>
				{!EnvHelper.isDesktop &&
				 <div style={{marginBottom: 5}}>
					 <SubNav subNavData={subNavData}/>
				 </div>}
				<Switch>
					<Route path={Routes.DASHBOARD.PARTNERS.PARTNER.INTEL.ROUTE} render={(props) => {
						return (
							<IntelPage inDetails={true} {...props}/>
						);
					}}/>
					<Route path={this.props.match.path + '/accounts'} render={() => {
						return (
							<div style={{marginTop: -10}}>
								<PartnerAccountsPage partner={partner}/>
							</div>
						);
					}}/>
				</Switch>
			</div>
		);
	}
}

PartnerDetailsRouter.propTypes = {
	children: PropTypes.element,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerDetailsRouter));
