import {Tune} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import PopoverSearchList from '../PopoverSearchList';
import SecondaryButton from './SecondaryButton';

class FilterMenuButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {itemSelected: null, filterData: {}};
		this.onMenuChange = this.onMenuChange.bind(this);
		this.onItemChanged = this.onItemChanged.bind(this);
		this.processItems = this.processItems.bind(this);
	}

	componentDidMount() {
		this.processItems(this.props.filterData);
	}

	processItems(filterData) {
		let hasSavedSelection = this.props.savedSelection && Object.keys(this.props.savedSelection).length > 0;
		let filterDataProcessed = {};
		for (let i = 0; i < filterData.length; i++) {
			filterDataProcessed[filterData[i].key] = hasSavedSelection ?
				this.props.savedSelection[filterData[i].key] :
				(filterData[i].isFiltered !== undefined ? filterData[i].isFiltered : true);
		}

		if (this.props.isMultipleChoice) {
			this.props.onFilterChanged(filterDataProcessed, true);
		}

		this.setState({
			filterData: filterDataProcessed,
			itemSelected: this.props.defaultSelection ? this.props.defaultSelection : Object.keys(filterDataProcessed)[0]
		});
	}

	onMenuChange(item) {
		this.setState({itemSelected: item});
		this.props.onFilterChanged(item.key);
	}

	onItemChanged() {
		this.props.onFilterChanged(this.props.savedSelection);
	}

	render() {
		let MaterialIcon = this.props.MaterialIcon ? this.props.MaterialIcon : Tune;
		return (
			<PopoverSearchList list={this.props.filterData}
							   isMultipleChoice={this.props.isMultipleChoice}
							   labelRenderer={(item) => item.title}
							   valueRenderer={(item) => item.key}
							   isCheckedFunction={(item) => {
								   if (this.props.savedSelection) return this.props.savedSelection[item.key];
							   }}
							   setCheckedFunction={(item, isChecked) => {
								   if (this.props.savedSelection) this.props.savedSelection[item.key] = isChecked;
							   }}
							   preselectedItem={this.state.itemSelected}
							   onItemSelected={(item) => {
								   if (this.props.isMultipleChoice) {
									   this.onItemChanged();
								   }
								   else {
									   this.onMenuChange(item);
								   }
							   }}
							   customToggleButton={<SecondaryButton icon={<MaterialIcon/>} toolTip={this.props.toolTip} requiresEventPropagation={true}/>}/>
		);
	}
}

FilterMenuButton.propTypes = {
	toolTip: PropTypes.string,
	MaterialIcon: PropTypes.elementType,
	filterData: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	})),
	onFilterChanged: PropTypes.func.isRequired,
	defaultSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	savedSelection: PropTypes.object,
	isMultipleChoice: PropTypes.bool
};

export default FilterMenuButton;
