import {FilterList} from '@mui/icons-material';
import {MenuItem, Select} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import FilterHelper, {
	FILTER_STRING_SEARCH_CONTAINS,
	FILTER_STRING_SEARCH_EQUAL,
	FILTER_STRING_SEARCH_EXCLUDES,
	FILTER_STRING_SEARCH_NOT_EQUAL,
	MAX_ITEMS_FOR_SIMPLE_FILTER_SELECTOR
} from '../../helpers/FilterHelper';
import {partnerTapDefaultText, partnerTapSecondary, partnerTapStroke} from '../../styles/partnertap_theme';
import CheckboxButton from '../buttons/CheckboxButton';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import SearchBox from '../SearchBox';
import TextInputBox from '../TextInputBox';

class StringListSelector extends Component {

	constructor(props, context) {
		super(props, context);

		this.showSearch = true;

		this.makeFilterValueCheckbox = this.makeFilterValueCheckbox.bind(this);
	}

	makeFilterValueCheckbox(filter, index, filterValue) {
		let isValueEliminatedByOtherFilter = filter.searchType !== FILTER_STRING_SEARCH_NOT_EQUAL && !filterValue.valid;
		return (
			<MenuItem key={'filter_selector_checkbox_' + index}
					  selected={filterValue.selected}
					  onClick={() => {
						  filterValue.selected = !filterValue.selected;
						  this.forceUpdate(); // so checkboxes update immediately when clicked
					  }}>
				<CheckboxButton checked={filterValue.selected}/>
				<div style={{color: isValueEliminatedByOtherFilter ? partnerTapStroke : partnerTapDefaultText}}>
					{filterValue.displayName}
				</div>
			</MenuItem>
		);
	}

	setFilterSearchType(filter, searchType) {
		filter.searchType = searchType;
		filter.searchLike = null;
		this.props.onChange(); // so select menu updates immediately upon selection, and adjusts to potential height changes
	}

	renderStringSelector(filter) {
		if (filter.searchType === FILTER_STRING_SEARCH_CONTAINS || filter.searchType === FILTER_STRING_SEARCH_EXCLUDES) {
			const hintText = filter.searchType === FILTER_STRING_SEARCH_EXCLUDES ? 'Excludes' : 'Contains';
			return (
				<div style={{minWidth: 280}}>
					<TextInputBox icon={<FilterList/>}
								  hintText={hintText}
								  value={filter.searchLike ? filter.searchLike : ''}
								  onChange={(value) => {
									  filter.searchLike = value;
									  this.forceUpdate(); // so text field updates immediately when typing
								  }}
								  onClear={() => {
									  filter.searchLike = '';
									  this.forceUpdate(); // so text field updates immediately when typing
								  }}
								  maxChars={100}
								  isSimple={true}/>
				</div>
			);
		}

		let filterKeys = Object.keys(filter.values || {});
		let filterValueCheckBoxes =
			filterKeys
			.filter((filterKey) => filter.values[filterKey].show && filter.values[filterKey].displayName)
			.map((filterKey, index) => this.makeFilterValueCheckbox(filter, index, filter.values[filterKey]));
		let filterHasValues = filterValueCheckBoxes && (filterValueCheckBoxes.length || filter.count);
		let filterHasMoreValues = Boolean(filter.totalPages) && filter.currentPage < filter.totalPages - 1;
		this.showSearch = filterValueCheckBoxes.length > MAX_ITEMS_FOR_SIMPLE_FILTER_SELECTOR;
		return (
			<Fragment>
				{(this.showSearch || this.props.currentFilterSearch) &&
				 <SearchBox onSearch={(search) => this.props.onSearch(search)}
							defaultValue={this.props.currentFilterSearch}
							autoApply={true}
							singleCharApply={true}/>}
				{filterHasValues ?
					<div style={{margin: 5, minWidth: 240, maxHeight: '50vh', overflow: 'scroll'}}>
						{filterValueCheckBoxes}
						{filterHasMoreValues &&
						 <div style={Object.assign({cursor: 'pointer', color: partnerTapSecondary}, FilterHelper.getSimpleMessageStyle())}
							  onClick={this.props.onLoadMore}>
							 LOAD MORE
						 </div>}
					</div>
					:
					<div style={FilterHelper.getSimpleMessageStyle()}>
						{this.props.currentFilterSearch ? 'No search results' : 'No values found'}
					</div>}
			</Fragment>
		);
	}

	render() {
		let filter = this.props.filter;
		let stringSelector = this.renderStringSelector(filter);
		return (
			<div>
				{(this.showSearch || this.props.currentFilterSearch) &&
				 <div style={{paddingBottom: 4}}>
					 <Select variant={'outlined'}
							 data-cy={'filter_type_select'}
							 margin={'dense'}
							 style={{minWidth: 120}}
							 value={filter.searchType ? filter.searchType : FILTER_STRING_SEARCH_EQUAL}>
						 <MenuItem value={FILTER_STRING_SEARCH_EQUAL} onClick={() => this.setFilterSearchType(filter, FILTER_STRING_SEARCH_EQUAL)}>
							 Equal to checked values
						 </MenuItem>
						 <MenuItem value={FILTER_STRING_SEARCH_NOT_EQUAL} onClick={() => this.setFilterSearchType(filter, FILTER_STRING_SEARCH_NOT_EQUAL)}>
							 Not equal to checked values
						 </MenuItem>
						 <MenuItem value={FILTER_STRING_SEARCH_CONTAINS} onClick={() => this.setFilterSearchType(filter, FILTER_STRING_SEARCH_CONTAINS)}>
							 Contains value
						 </MenuItem>
						 <MenuItem value={FILTER_STRING_SEARCH_EXCLUDES} onClick={() => this.setFilterSearchType(filter, FILTER_STRING_SEARCH_EXCLUDES)}>
							 Excludes value
						 </MenuItem>
					 </Select>
				 </div>}
				{stringSelector}
				<div style={{display: 'flex', paddingTop: 5, justifyContent: 'center'}}>
					<div style={{padding: 5}}>
						<SecondaryButton label={'CANCEL'} onClick={() => this.props.onClose(false)}/>
					</div>
					<div style={{padding: 5}}>
						<PrimaryButton label={'APPLY'} onClick={() => this.props.onClose(true)}/>
					</div>
				</div>
			</div>
		);
	}
}

StringListSelector.propTypes = {
	filter: PropTypes.object.isRequired,
	onSearch: PropTypes.func.isRequired,
	onLoadMore: PropTypes.func.isRequired,
	currentFilterSearch: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default StringListSelector;
