import {withRouter} from 'react-router-dom';
import {CATEGORY_COMPANY_PARTNER_ACCOUNTS} from '../../../../globals/Enums';
import ReportHelper from '../../../../helpers/ReportHelper';
import PartnerOrgPagingBase from './PartnerOrgPagingBase';

class PartnerOrgAccountsPage extends PartnerOrgPagingBase {

	constructor(props, context) {
		super(props, context, 'Accounts', ReportHelper.companyPartnerAccountReportTypes, CATEGORY_COMPANY_PARTNER_ACCOUNTS);
	}

	get storageKeyBase() {
		return this.getStorageKeyBase('accounts');
	}
}

export default withRouter(PartnerOrgAccountsPage);
