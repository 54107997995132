import {Diversity3} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../globals/Routes';
import * as ApiHelper from '../../helpers/ApiHelper';
import EnvHelper from '../../helpers/EnvHelper';
import Dialog from '../Dialog';
import ScrimMessage from '../messages/ScrimMessage';
import TextInputBox from '../TextInputBox';
import Upsell from '../Upsell';
import ActionButton from './ActionButton';

class CoSellSaveButton extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			showUpsellDialog: false,
			showSaveListInfoDialog: false,
			actionListName: '',
			actionListDescription: '',
			actionListDescriptionMissing: false,
			showSaveInProgressDialog: false,
			showLoadingScrim: false
		};

		this.showUpsell = this.showUpsell.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	showUpsell() {
		this.setState({showUpsellDialog: true});
	}

	onSaveActionList() {
		if (!this.state.actionListDescription) {
			this.setState({actionListDescriptionMissing: true});
			return;
		}
		this.setState({showSaveListInfoDialog: false, showLoadingScrim: true});
		let actionListConfig = this.props.callingPage.coSellReportConfig;
		let {actionListName} = this.state;
		actionListConfig.actionListName = actionListName || actionListConfig.exportBaseFileName;
		actionListConfig.actionListDescription = this.state.actionListDescription;
		ApiHelper.sendPostReport(actionListConfig.endpoint, actionListConfig)
		.then((result) => {
			this.setState({showLoadingScrim: false, showSaveCompleteDialog: true, actionListName: '', actionListDescription: ''});
		})
		.catch((error) => {
			this.setState({showLoadingScrim: false});
			EnvHelper.serverError('Error from onSaveActionList', error);
		});
	}

	onSave() {
		let actionListConfig = this.props.callingPage.downloadReportConfig;
		this.setState({showSaveListInfoDialog: true, actionListName: actionListConfig.exportBaseFileName});
	}

	render() {
		let action = this.onSave;
		return (
			<div key={'co_sell_save_action_list_button'} style={{display: 'flex'}}>
				{this.state.showLoadingScrim &&
				 <ScrimMessage message={'Saving Co-Sell Action List...'}/>}
				{this.state.showUpsellDialog &&
				 <Dialog title={'Upgrade Required'}
						 message={<Upsell feature={'Channel Ecosystem'}
										  headline={'Do you want to save Action Lists for Co-Sell Workflow?'}
										  callToAction={
											  <div>
												  Reach out to your customer success representative to enable <em>Co-Sell Engine</em>
											  </div>}
										  productBullets={['Save Action Lists for Co-Sell Workflow']}/>}
						 yesAction={() => this.setState({showUpsellDialog: false})}
						 noContentPadding={true}/>}
				<ActionButton toolTip={'Save for Co-Sell Engine'} onAction={action} icon={<Diversity3/>}/>
				{this.state.showSaveListInfoDialog &&
				 <Dialog title={'Save Action List for Co-Sell Workflow'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400, margin: 'auto'}}>
								 <div>
									 Your Action List will contain the exact rows and columns currently shown in this report.
								 </div>
								 <div style={{paddingBottom: 20}}>
									 You'll have the option to remove rows and columns from an Action List prior to running a workflow.
								 </div>
								 <TextInputBox hintText={'Action List Name'}
											   value={this.state.actionListName}
											   onChange={(value) => this.setState({actionListName: value})}
											   doNotAutoFocus={true}
											   maxChars={64}/>
								 <TextInputBox hintText={'Action List Description'}
											   placeholderText={'Please tell your co-sell automation leader the strategy and next steps for this Action List'}
											   onChange={(value) => this.setState({actionListDescription: value, actionListDescriptionMissing: false})}
											   errorText={this.state.actionListDescriptionMissing ? 'Description is required' : null}
											   multiLine={true}
											   rows={3}
											   maxChars={512}/>
							 </div>}
						 yesLabel={'SAVE'}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showSaveListInfoDialog: false})}
						 yesAction={() => this.onSaveActionList()}/>}
				{this.state.showSaveCompleteDialog &&
				 <Dialog title={'Action List Saved'}
						 message={
							 <div style={{textAlign: 'center'}}>
								 <div>Your Action List has been saved.</div>
								 <div>You can view Action Lists and start workflows in Co-Sell Engine.</div>
							 </div>}
						 yesLabel={'Go to Co-Sell Engine'}
						 noLabel={'Close'}
						 noAction={() => {
							 this.setState({showSaveCompleteDialog: false});
						 }}
						 yesAction={() => {
							 this.setState({showSaveCompleteDialog: false});
							 EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE);
						 }}/>}
			</div>
		);
	}
}

CoSellSaveButton.propTypes = {
	callingPage: PropTypes.object
};

export default connect()(CoSellSaveButton);
