import {ErrorSharp} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapDropShadow, partnerTapWhite} from '../../styles/partnertap_theme';

class MessageBox extends Component {

	get IconClass() {
		return ErrorSharp;
	}

	get color() {
		return partnerTapWhite;
	}

	render() {
		let {title, hideIcon, children} = this.props;
		return (
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: 10,
				border: '1px solid ' + this.color,
				borderRadius: 20,
				boxShadow: partnerTapDropShadow,
				backgroundColor: partnerTapWhite,
				marginTop: 10,
				marginBottom: 10,
				marginLeft: 'auto',
				marginRight: 'auto',
				...this.props.style
			}}>
				{title &&
				 <div style={{display: 'flex', alignItems: 'center', fontSize: 16, fontWeight: 'bold'}}>
					 {!hideIcon &&
					  <this.IconClass fontSize={'large'} style={{color: this.color}}/>}
					 <div style={{padding: 10, whiteSpace: 'nowrap'}}>
						 {title}
					 </div>
				 </div>}
				<div style={{display: 'flex', alignItems: 'center'}}>
					{(!title && !hideIcon) &&
					 <this.IconClass fontSize={'large'} style={{color: this.color}}/>}
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 10,
						maxWidth: 640,
						overflow: 'auto',
						fontSize: 14,
						padding: 10,
						paddingTop: title ? 0 : 10
					}}>
						{children}
					</div>
				</div>
			</div>
		);
	}
}

MessageBox.propType = {
	title: PropTypes.string,
	hideIcon: PropTypes.bool,
	style: PropTypes.object
};

export default MessageBox;
