import {Search} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PrimaryButton from './buttons/PrimaryButton';
import TextInputBox from './TextInputBox';

const searchDangerousCharacters = /[!#$%^&*()'";:\\]/ig;

class SearchBox extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {searchValue: this.props.defaultValue || '', lastSearchValue: this.props.defaultValue, autoFocus: false};
		this.onSearchChange = this.onSearchChange.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onClear = this.onClear.bind(this);
	}

	onSearchChange(value) {
		value = value.replace(searchDangerousCharacters, '');
		this.setState({searchValue: value});
		if (this.state.lastSearchValue != null && value.length === 0) {
			this.onClear();
		}
		else if (this.props.autoApply) {
			this.clearAutoApplyTimeout();
			this.autoApplyTimeout = setTimeout(this.onSearch, 500);
		}
	}

	onSearch() {
		this.clearAutoApplyTimeout();
		if (this.validSearch) {
			let value = this.state.searchValue.trim();
			this.setState({searchValue: value, lastSearchValue: value});
			this.props.onSearch(value);
		}
	}

	onClear() {
		this.clearAutoApplyTimeout();
		if (this.state.lastSearchValue) {
			this.props.onSearch('');
		}
		this.setState({searchValue: '', lastSearchValue: null, autoFocus: true});
	}

	get validSearch() {
		let value = this.state.searchValue.trim();
		return value.length > (this.props.singleCharApply ? 0 : 1) && value !== this.state.lastSearchValue;
	}

	clearAutoApplyTimeout() {
		if (this.autoApplyTimeout) {
			clearTimeout(this.autoApplyTimeout);
			this.autoApplyTimeout = null;
		}
	}

	render() {
		return (
			<div style={{display: 'flex', alignItems: 'center'}}>
				<div data-cy={'search_box'} style={{flex: 1}}>
					<TextInputBox icon={<Search/>}
								  hintText={this.props.autoApply ? 'Search' : ''}
								  value={this.state.searchValue}
								  inputMode={'search'}
								  maxChars={100}
								  onChange={this.onSearchChange}
								  onClear={this.onClear}
								  onEnter={this.onSearch}
								  isSimple={true}/>
				</div>
				{!this.props.autoApply &&
				 <div style={{padding: 5}}>
					 <PrimaryButton label={'Search'} onClick={this.onSearch} disabled={!this.validSearch}/>
				 </div>}
			</div>
		);
	}
}

SearchBox.propTypes = {
	onSearch: PropTypes.func.isRequired,
	defaultValue: PropTypes.string,
	autoApply: PropTypes.bool,
	singleCharApply: PropTypes.bool
};

export default connect()(SearchBox);
