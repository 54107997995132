import React from 'react';
import Upsell from '../ui/Upsell';

export default class UpsellHelper {

	static get channelOpportunitySharing() {
		return (
			<Upsell feature={'Opportunity Sharing'}
					headline={'Do you want to enable opportunity sharing?'}
					callToAction={
						<div>
							Powerful opportunity sharing and shared pipeline capabilities are available with paid versions of PartnerTap.
						</div>}
					productBullets={[
						'See matched partners on your current opportunities',
						'Share opportunities with trusted partners',
						'See all your partner opportunities shared with you',
						'Get an ecosystem-wide view of shared pipeline on each account'
					]}/>
		);
	}
}
