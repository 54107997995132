import {GpsOff, LocationOn, TaskOutlined} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {ACCOUNTS_ASSET, CATEGORY_SHEET_ACCOUNTS, CHANNEL_MAP_V2_LOCATIONS, CHANNEL_MAP_V2_UNMATCHED, UPLOAD_PARTNER_SHEET} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import {tempFeatureFlag_CoSellEngine, tempFeatureFlag_CoSellEngine_live} from '../../../../helpers/FeatureHelper';
import ReportHelper, {
	CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_ACCOUNTS,
	CHANNEL_REPORT_TYPE_PARTNER_SHEET_UNMATCHED_ACCOUNTS,
	MATCHING_TYPE_UNMATCHED
} from '../../../../helpers/ReportHelper';
import StringHelper from '../../../../helpers/StringHelper';
import CoSellSaveButton from '../../../../ui/buttons/CoSellSaveButton';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import SaveReportButton from '../../../../ui/buttons/SaveReportButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import Loading from '../../../../ui/Loading';
import ReportMenu from '../../shared/ReportMenu';
import CoSellSaveButtonLive from '../../../../ui/buttons/CoSellSaveButtonLive';

class PartnerSheetAccountsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		let reportTypes = ReportHelper.sheetAccountReportTypes;
		let currentReport;
		let isDashboardReport;
		if (DialogHelper.savedReportData.settings) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, DialogHelper.savedReportData.settings.reportTitle); // from saved report
		}
		else if (this.reportType) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, decodeURIComponent(this.reportType)); // from report dropdown or dashboard summary
			isDashboardReport = DialogHelper.isDialogOpen; // from dashboard summary
		}
		if (!currentReport) {
			currentReport = reportTypes[0];
		}

		this.personQueueData = {};
		this.state.loading = false;
		this.state.accountReportTypes = reportTypes;
		this.state.currentReportType = currentReport;
		this.state.isDashboardReport = isDashboardReport;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {
			personQueueIds: [this.personQueueId],
			matchingType: currentReport.matchingType,
			channelReportType: this.channelReportType
		};
		this.state.filterSelectorMounted = false;
		let deepLinkFilters = ChannelHelper.getDeepLinkFilters();
		if (deepLinkFilters) {
			this.state.filterValues = deepLinkFilters;
		}
		if (DialogHelper.savedReportData.settings) {
			this.initialFilterValues = DialogHelper.savedReportData.settings.filters;
		}

		this.fetchColumns = this.fetchColumns.bind(this);
		this.renderReportMenu = this.renderReportMenu.bind(this);
		this.onUpdateReportType = this.onUpdateReportType.bind(this);
	}

	componentDidMount() {
		this.fetchColumns();
	}

	get personQueueId() {
		return this.props.match.params.personQueueId;
	}

	get partnerOrgName() {
		let partnerOrgName = this.props.match.params.partnerOrgName;
		if (partnerOrgName === 'null' || partnerOrgName === 'undefined') partnerOrgName = null;
		return partnerOrgName;
	}

	get sheetType() {
		switch (this.props.match.params.page) {
			case Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.UNMAPPED_ACCOUNTS.PAGE_NAME:
				return CHANNEL_MAP_V2_UNMATCHED;
			case Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_LOCATIONS.PAGE_NAME:
				return CHANNEL_MAP_V2_LOCATIONS;
			default:
				return UPLOAD_PARTNER_SHEET;
		}
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get forMappedAccounts() {
		return this.sheetType === UPLOAD_PARTNER_SHEET;
	}

	get forMappedLocations() {
		return this.sheetType === CHANNEL_MAP_V2_LOCATIONS;
	}

	get forUnmappedAccounts() {
		return this.sheetType === CHANNEL_MAP_V2_UNMATCHED;
	}

	get storageKeyBase() {
		let filterTitle = '';
		let paramFilterTitle = this.getParamValue('filterTitle');
		if (paramFilterTitle) {
			filterTitle = '_' + StringHelper.formatKey(paramFilterTitle);
		}
		let {currentReportType} = this.state;
		let reportType = currentReportType ? ('_' + StringHelper.formatKey(currentReportType.reportTitle)) : '';
		return 'sheet_accounts_' + this.sheetType + filterTitle + reportType;
	}

	get title() {
		let {currentReportType} = this.state;
		if (this.state.isDashboardReport) {
			return EnvHelper.getParam('filterTitle', this.props.location.search) || currentReportType.reportTitle;
		}
		else if (this.isSavedReport) {
			return currentReportType.reportTitle;
		}
		else if (this.forMappedAccounts) {
			return this.renderReportMenu();
		}
		else if (this.forUnmappedAccounts) {
			return 'Unmapped Accounts';
		}
		else if (this.forMappedLocations) {
			return 'Matched Locations';
		}
		else {
			return 'Partner Sheet';
		}
	}

	get icon() {
		if (this.state.isDashboardReport || this.isSavedReport) {
			let {currentReportType} = this.state;
			return ReportHelper.getReportFilterIcon(currentReportType.assetType, currentReportType.filterType);
		}
		else if (this.forMappedAccounts) {
			return null;
		}
		else if (this.forUnmappedAccounts) {
			return GpsOff;
		}
		else if (this.forMappedLocations) {
			return LocationOn;
		}
		else {
			return TaskOutlined;
		}
	}

	renderReportMenu() {
		let {accountReportTypes, currentReportType} = this.state;
		return <ReportMenu reportTypes={accountReportTypes}
						   currentReportType={currentReportType}
						   onUpdateReportType={this.onUpdateReportType}
						   data-cy={'partner_sheets_report_menu'}/>;
	}

	onUpdateReportType(reportType) {
		let route = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS;
		EnvHelper.push(route.PATH(this.personQueueId, route.MAPPED_ACCOUNTS.PAGE_NAME, reportType.reportTitle, this.partnerOrgName));
	}

	get additionalToolbarButtons() {
		let {currentReportType, filterValues} = this.state;
		let buttons = [];
		if (tempFeatureFlag_CoSellEngine && !tempFeatureFlag_CoSellEngine_live && this.forMappedAccounts) {
			buttons.push(<CoSellSaveButton callingPage={this}/>);
		}
		else if (tempFeatureFlag_CoSellEngine_live) {
			buttons.push(<CoSellSaveButtonLive callingPage={this} key={this.state.rowCount}/>);
		}
		buttons.push(<DownloadButton callingPage={this}/>);
		buttons.push(<SaveReportButton reportCategory={CATEGORY_SHEET_ACCOUNTS}
									   reportSettings={{
										   reportSettings: currentReportType,
										   baseSettings: ChannelHelper.baseSettings(this.storageKeyBase),
										   filter: filterValues
									   }}
									   reportParams={{
										   sheetType: this.sheetType,
										   personQueueId: this.personQueueId
									   }}
									   showSaveDialog={this.showSaveDialog}/>);
		return buttons;
	}

	get downloadReportConfig() {
		let {isDashboardReport, currentReportType, columnData} = this.state;
		let params = this.processParameters(true);
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			channelReportType: this.channelReportType,
			importType: this.sheetType,
			matchingType: this.forUnmappedAccounts ? MATCHING_TYPE_UNMATCHED : currentReportType.matchingType,
			mapByLocations: this.forMappedLocations,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: isDashboardReport ? StringHelper.formatKey(this.title) :
				this.forUnmappedAccounts ? 'unmatched_accounts' : currentReportType.downloadFileName,
			exportFields: DownloadHelper.getFields(columnData),
			selectedColumns: this.activeColumns,
			totalElements: '0',
			personQueueIds: [this.personQueueId]
		};
	}

	get coSellReportConfig() {
		let reportConfig = this.downloadReportConfig;
		reportConfig.endpoint = '/cosell/actionlist/save';
		reportConfig.partnerOrgName = this.partnerOrgName || 'None';
		reportConfig.assetType = ACCOUNTS_ASSET;
		return reportConfig;
	}

	get coSellReportConfigLive() {
		return {
			reportData: this.downloadReportConfig,
			partnerOrgPublicId: this.personQueueData.partnerOrgPublicId,
			assetType: ACCOUNTS_ASSET,
			rowCount: this.state.rowCount
		};
	}

	fetchColumns() {
		Promise.all([
			ChannelMappingEndpoints.getUploadDataForId(this.personQueueId),
			ChannelMappingEndpoints.getColumns({channelReportType: this.channelReportType, personQueueIds: [this.personQueueId], importType: this.sheetType})
		])
		.then((results) => {
			if (this.unmounted) return;
			this.personQueueData = results[0].payload;
			this.initColumnData(results[1].payload);
		});
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get filterSelectorConfig() {
		let config = super.filterSelectorConfig;
		if (config) {
			let {currentReportType} = this.state;
			config.inherentReportFilters = currentReportType.inherentReportFilters;
		}
		return config;
	}

	get action() {
		return ChannelMappingEndpoints.getRecords;
	}

	get channelReportType() {
		return this.forUnmappedAccounts ? CHANNEL_REPORT_TYPE_PARTNER_SHEET_UNMATCHED_ACCOUNTS : CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_ACCOUNTS;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		this.action(
			{
				personQueueIds: [this.personQueueId],
				selectedColumns: this.activeColumns,
				mapByLocations: this.forMappedLocations,
				channelReportType: this.channelReportType,
				importType: this.sheetType
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			this.state.totalElements)
		.then((result) => {
				if (this.unmounted) return;
				let stateUpdates; // we must not update state before we call processData, or getRowData will be called redundantly
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
					stateUpdates = {totalElements: result.metaData.totalElements};
				}
				this.processData(params, result, stateUpdates);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		if (this.isSavedReport) {
			return DialogHelper.renderSavedReportDialogButtons(super.render(), () => this.forceUpdate(), this.closeSavedReportDialog);
		}
		return super.render();
	}
}

export default withRouter(connect()(PartnerSheetAccountsPage));
