import {Routes} from '../../../../globals/Routes';

export default class PartnerOrgHelper {
	static getPartnerConnectionsRoute(companyPartnerPublicId) {
		return Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.PATH(companyPartnerPublicId, Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.CONNECTED_PARTNERS.PAGE_NAME);
	}

	static getPartnerSheetsRoute(companyPartnerPublicId) {
		return Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.PATH(companyPartnerPublicId, Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.SHEETS_UPLOADED.PAGE_NAME);
	}
}
