import React from 'react';
import {withRouter} from 'react-router-dom';
import {partnerTapAppBackground, partnerTapPrimary, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Stepper from '../../../ui/Stepper';
import EnvHelper from '../../../helpers/EnvHelper';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SheetUploader from '../shared/sheets/SheetUploader';
import {PRE_PROCESSED_BY_WEB_WORKER} from '../../../globals/Enums';
import ProgressBar from '../../../ui/ProgressBar';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {Routes} from '../../../globals/Routes';

const KEEP_OR_REMOVE = 0;
const UPLOAD = 1;
const PROCESSING = 2;

const ACTION_DELETE = 'delete';
const ACTION_KEEP = 'keep';

class CoSellActionListEdit extends React.Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: true,
			saving: false,
			steps: [
				{label: "Keep or Remove Records", active: true, complete: false},
				{label: "Upload Action List IDs", active: false, complete: false},
				{label: "Processing", active: false, complete: false}
			],
			action: ACTION_DELETE,
			currentConfigStep: 0,
			actionListMetadata: null,
			error: null,
			progress: 0,
			totalRecords: 0
		};
		this.csvWorker = new Worker(new URL("../../workers/getRowsFromSpecificColumn.js", import.meta.url));
	}

	async componentDidMount() {
		const res = await CoSellEngineEndpoints.getCoSellActionListMetadata(this.props.match.params.actionListId);
		const actionListMetadata = res.payload;
		this.setState({actionListMetadata});
		this.csvWorker.onmessage = function(event) {
			const {progress, columnData, error, totalRecords} = event.data;
			if (!this.unmounted) { // Check if component is still mounted
				if (error) {
					this.errorHandler(error);
					this.setState({loading: false, error: error});
				}
				if (progress) {
					this.setState({loading: true, progress: progress, totalRecords: totalRecords});
				}
				if (columnData) {
					CoSellEngineEndpoints.updateCoSellActionListRecords(this.props.match.params.actionListId, this.state.action, columnData)
					.then((result) => {
						if (this.unmounted) return;
						EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(this.props.match.params.actionListId));
					})
					.catch((error) => {
						EnvHelper.serverError('Error from applyActionToSelectedRecords', error);
						this.updateSteps(UPLOAD);
					});
				}
			}
		}.bind(this);

		this.csvWorker.onerror = function(error) {
			this.errorHandler(error.message);
		}.bind(this);
	}

	componentWillUnmount() {
		this.csvWorker.terminate();
		this.unmounted = true;
	}

	errorHandler(error) {
		EnvHelper.serverError('Error from onUpload', {message: error});
		this.updateSteps(UPLOAD);
	}

	updateSteps(currentConfigStep) {
		const steps = [...this.state.steps];
		steps.forEach((step, index) => {
			step.active = index === currentConfigStep;
			step.complete = index < currentConfigStep;
		});
		this.setState({steps: steps, currentConfigStep: currentConfigStep});
	}

	renderConfigStep() {
		const {currentConfigStep} = this.state;

		switch (currentConfigStep) {
			case KEEP_OR_REMOVE:
				return this.renderKeepOrRemoveRecords();
			case UPLOAD:
				return this.renderUploadCSV();
			case PROCESSING:
				return this.renderProgress();
			default:
				return null;
		}

	}

	renderKeepOrRemoveRecords() {
		return (
			<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
				<MessageBoxInfo hideIcon={true} style={{width: "70%", paddingTop: "20px", paddingBottom: "20px"}}>
					Choose how you want to modify this action list with the CSV upload.
				</MessageBoxInfo>
				<FormControl sx={{width: "71%"}}>
					<FormLabel component="legend"
							   sx={{backgroundColor: partnerTapPrimary, padding: '10px', color: partnerTapWhite}}
							   style={{color: partnerTapWhite}}>Action List: {this.state.actionListMetadata?.name}</FormLabel>
					<RadioGroup
						value={this.state.action}
						onChange={(e) => this.setState({action: e.target.value})}
						sx={{backgroundColor: partnerTapWhite, padding: '10px'}}
					>
						<FormControlLabel value={ACTION_KEEP} control={<Radio/>} label="Keep only the records in the CSV on the action list"/>
						<FormControlLabel value={ACTION_DELETE} control={<Radio/>} label="Remove the records in the CSV from the action list"/>

					</RadioGroup>
				</FormControl>
			</div>
		);
	}

	renderUploadCSV() {
		return (
			<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
				<MessageBoxInfo hideIcon={true} style={{width: "70%", paddingTop: "20px", paddingBottom: "20px"}}>
					<div>Upload your Action List ID CSV by selecting the file or drag and drop.</div>
					<div style={{fontSize: 16, fontWeight: 'bold'}}>The column “Action List Record ID” from the original action list is required.</div>
				</MessageBoxInfo>
				<SheetUploader
					onUploadSuccess={() => this.gotoNextStep()}
					uploadType={PRE_PROCESSED_BY_WEB_WORKER}
					webWorkerFunc={async (file) => this.csvWorker.postMessage({file: file, columnName: 'Action List Record ID'})}
				/>
			</div>
		);
	}

	renderSteppingButtons() {
		let {currentConfigStep} = this.state;
		return (
			<div style={{display: 'flex', justifyContent: 'center', gap: 20, padding: 10, borderTop: '1px solid ' + partnerTapStroke}}>
				{currentConfigStep > 0 ?
					<SecondaryButton label={'BACK'} onClick={() => this.gotoPreviousStep()}/> :
					<SecondaryButton label={'CANCEL'} onClick={() => {
						EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(this.props.match.params.actionListId));
					}}
					/>
				}
				{currentConfigStep === 0 && <PrimaryButton label={'CONTINUE'} onClick={() => this.gotoNextStep()}/>}
			</div>
		);
	}

	renderProgress() {
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				gap: 20,
				width: "100%",
				paddingTop: "100px"
			}}>
				<ProgressBar title={"Processing"}
							 truncateTitle={false}
							 onlyRenderProgressBar={true}
							 progressPercentage={this.state.progress}
							 noImportedFilesLabel={true}
				/>

				<MessageBoxInfo hideIcon={true} style={{width: "70%", paddingTop: "20px", paddingBottom: "20px"}}>
					You will be redirect to action list when processing is done.
				</MessageBoxInfo>
			</div>

		);
	}

	gotoPreviousStep() {
		let {currentConfigStep} = this.state;
		this.updateSteps(currentConfigStep - 1);
	}

	gotoNextStep() {
		let {currentConfigStep} = this.state;
		this.updateSteps(currentConfigStep + 1);
	}

	get isConfirmStep() {
		let {steps, currentConfigStep} = this.state;
		if (!steps) return false;
		return currentConfigStep === steps.length - 1;
	}

	render() {
		let {steps} = this.state;
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<ScrollingContainer divId={'co_sell_action_list_modify'} forceHeightOffset={true}>
					<Stepper steps={steps}/>
					<div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: 20, backgroundColor: partnerTapAppBackground, overflow: 'scroll'}}>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'top', gap: 20, height: '100vh'}}>
							{this.renderConfigStep()}
						</div>
					</div>
					{!this.isConfirmStep && this.renderSteppingButtons()}
				</ScrollingContainer>
			</div>
		);
	}

}

export default withRouter(CoSellActionListEdit);
