import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as ManagerAnalyticsEndpoints from '../../endpoints/ManagerAnalyticsEndpoints';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_MANAGER_ANALYTICS_ACCESS, FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS} from '../../helpers/FeatureHelper';
import NavHelper from '../../helpers/NavHelper';
import * as Notifications from '../../helpers/NotificationHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import * as ManagerAnalyticsActions from '../../redux/ManagerAnalyticsActions';
import {MANAGER_DIVISION_STATUS} from '../../redux/ReduxEnum';
import {partnerTapQuaternary} from '../../styles/partnertap_theme';
import LogoMessage from '../../ui/messages/LogoMessage';
import SideNav from '../../ui/navigation/SideNav';
import TopBar from '../../ui/TopBar';
import Upsell from '../../ui/Upsell';
import ProfilePage from '../profile/ProfilePage';
import DashboardAnalytics from './DashboardAnalytics';
import ManagerHeader from './ManagerHeader';
import ManagerReports from './ManagerReports';
import ManagerSettingsPage from './ManagerSettingsPage';
import NoDataWarning from './NoDataWarning';
import ConnectionsAnalytics from './page_wrapers/ConnectionsAnalytics';
import IntelAnalytics from './page_wrapers/IntelAnalytics';
import MatchedAccountAnalytics from './page_wrapers/MatchedAccountAnalytics';
import RepIntelAnalytics from './page_wrapers/RepIntelAnalytics';
import RepNewPartnersAnalytics from './page_wrapers/RepNewPartnersAnalytics';
import TapAnalytics from './page_wrapers/TapAnalytics';
import TapSpecificAnalytics from './page_wrapers/TapSpecificAnalytics';
import UnmatchedAccountAnalytics from './page_wrapers/UnmatchedAccountAnalytics';

class ManagerAnalyticsProduct extends Component {

	constructor(props, context) {
		super(props, context);
		try {
			Notifications.handleDeepLinks(props.authState.person, props.dispatch);
		}
		catch (error) {
			console.error('Error handing deep link', error);
		}
		this.state = {loading: FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ACCESS), searchList: [], isMobileNavOpen: false};
		this.onTimeChange = this.onTimeChange.bind(this);
	}

	componentDidMount() {
		if (!FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ACCESS)) return;
		let managerDetails;

		Promise.all([
			ManagerAnalyticsEndpoints.fetchManagerDetails(),
			ManagerAnalyticsEndpoints.fetchAllPartnerOrganizations()
		])
		.then((results) => {
			if (this.unmounted) return;
			managerDetails = results[0].payload;
			let orgResults = results[1].payload;
			this.setState({searchList: orgResults.partnerOrganizations});
			if (FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS)) {
				return ManagerAnalyticsEndpoints.fetchOwnerDivisions();
			}
			return null;
		})
		.then((result) => {
			if (this.unmounted) return;
			if (result) {
				if (result.payload) {
					let storedDivisions = PersistenceHelper.getValue(MANAGER_DIVISION_STATUS);
					if (storedDivisions) {
						storedDivisions = JSON.parse(storedDivisions);
					}
					managerDetails.divisions = result.payload.map((obj) => {
						if (storedDivisions) {
							for (let i = 0; i < storedDivisions.length; i++) {
								if (storedDivisions[i].division === obj) {
									return {division: obj, isChecked: storedDivisions[i].isChecked};
								}
							}
						}
						return {division: obj, isChecked: false};
					});
				}
			}

			if (managerDetails.divisions) {
				managerDetails.divisions.unshift({division: ManagerAnalyticsEndpoints.ALL_DIVISIONS});
			}

			this.setState({loading: false, managerDetails: managerDetails});
		})
		.catch((error) => {
			this.setState({loading: false});
			EnvHelper.serverError('Error from fetchManagerDetails or fetchAllPartnerOrganizations', error);
		});
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onTimeChange(newTime) {
		this.props.dispatch(ManagerAnalyticsActions.setSearchRange(newTime));
	}

	getView() {
		let {managerDetails} = this.state;
		if (managerDetails && managerDetails.divisions && managerDetails.divisions.length === 0 && managerDetails.role.toLowerCase() === 'manager') {
			return <NoDataWarning/>;
		}
		else if (!FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ACCESS) && !this.isProfileRoute) {
			return <Upsell feature={'Manager Analytics'}
						   headline={'Do you want Manager Analytics?'}
						   callToAction={
							   <div>
								   Upgrade to <em>Manager Analytics</em> for full access to complete opportunity and account mapping reports.
							   </div>}
						   productBullets={
							   [
								   'Direct partner-sourced pipeline',
								   'Identify top partners',
								   'Partner field engagement insights',
								   'Partner revenue influence',
								   'Current pipeline opportunity'
							   ]
						   }/>;
		}
		return this.routeSwitch;
	}

	get routeSwitch() {
		return (
			<Switch location={this.props.locationOverride}>
				<Route path={Routes.MANAGER_ANALYTICS.DASHBOARD.ROUTE} render={() => <DashboardAnalytics managerDetails={this.state.managerDetails}/>}/>
				<Route path={Routes.MANAGER_ANALYTICS.UNMATCHED_ACCOUNTS_ANALYTICS.ROUTE} component={UnmatchedAccountAnalytics}/>
				<Route path={Routes.MANAGER_ANALYTICS.MATCHED_ACCOUNTS_ANALYTICS.ROUTE} component={MatchedAccountAnalytics}/>
				<Route path={Routes.MANAGER_ANALYTICS.CONNECTIONS_ANALYTICS.ROUTE} component={ConnectionsAnalytics}/>
				<Route path={Routes.MANAGER_ANALYTICS.SALES_REP.ROUTE} component={RepNewPartnersAnalytics}/>
				<Route exact path={Routes.MANAGER_ANALYTICS.TAP.ORG.OUTBOUND} render={() => <TapSpecificAnalytics isOutbound={true}/>}/>
				<Route exact path={Routes.MANAGER_ANALYTICS.TAP.ORG.INBOUND} render={() => <TapSpecificAnalytics isOutbound={false}/>}/>
				<Route exact path={Routes.MANAGER_ANALYTICS.TAP.OUTBOUND} render={() => <TapAnalytics isOutbound={true}/>}/>
				<Route exact path={Routes.MANAGER_ANALYTICS.TAP.INBOUND} render={() => <TapAnalytics isOutbound={false}/>}/>
				<Route path={Routes.MANAGER_ANALYTICS.REP_INTEL.ROUTE} component={RepIntelAnalytics}/>
				<Route path={Routes.MANAGER_ANALYTICS.INTEL_ANALYTICS.ROUTE} component={IntelAnalytics}/>
				<Route path={Routes.MANAGER_ANALYTICS.REPORTS.ROUTE} component={ManagerReports}/>
				<Route path={Routes.MANAGER_ANALYTICS.SETTINGS.ROUTE} component={ManagerSettingsPage}/>
				<Route path={Routes.MANAGER_ANALYTICS.PROFILE.ROUTE} component={ProfilePage}/>
			</Switch>
		);
	}

	get isProfileRoute() {
		return this.props.location.pathname.indexOf(Routes.MANAGER_ANALYTICS.PROFILE.ROUTE) === 0;
	}

	render() {
		if (this.state.loading) {
			return <LogoMessage message={'Loading Analytics...'}/>;
		}

		if (this.props.locationOverride) {
			// GTM tracking of every page embedded in dialog
			window.dataLayer.push({event: 'pageview', page: {url: this.props.locationOverride.pathname + this.props.locationOverride.search}});
			return this.routeSwitch;
		}

		return (
			<div key={EnvHelper.path} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<TopBar/>
				<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
					<ManagerHeader onTimeChange={this.onTimeChange} searchList={this.state.searchList} managerDetails={this.state.managerDetails || {}}/>
					<div style={{flex: 1, display: 'flex', backgroundColor: partnerTapQuaternary}}>
						<div key={EnvHelper.path} className={'printNone'}>
							<SideNav navButtonData={NavHelper.managerAnalyticsData}
									 isMobileNavOpen={this.state.isMobileNavOpen}
									 onToggleMobileNavOpen={(state) => {
										 if (this.unmounted) return;
										 this.setState({isMobileNavOpen: state});
									 }}/>
						</div>
						<div className={'printFullWidth'} style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
							{this.getView()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ManagerAnalyticsProduct.propTypes = {
	locationOverride: PropTypes.object,
	authState: PropTypes.object.isRequired,
	managerAnalyticsState: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		managerAnalyticsState: state.managerAnalyticsState,
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(ManagerAnalyticsProduct));
