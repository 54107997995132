import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../../endpoints/ManagerAnalyticsEndpoints';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import PartnerStatusHelper from '../../../helpers/PartnerStatusHelper';
import Pic from '../../../ui/Pic';
import ManagerAnalyticsPage from '../ManagerAnalyticsPage';

class RepNewPartnersAnalytics extends Component {

	componentDidMount() {
		if (!this.props.managerAnalyticsState.currentRep.personId) {
			EnvHelper.push(Routes.MANAGER_ANALYTICS.CONNECTIONS_ANALYTICS.ROUTE);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.managerAnalyticsState.division !== prevProps.managerAnalyticsState.division ||
			this.props.managerAnalyticsState.currentCompanyName !== prevProps.managerAnalyticsState.currentCompanyName ||
			this.props.managerAnalyticsState.searchRange !== prevProps.managerAnalyticsState.searchRange) {
			EnvHelper.push(Routes.MANAGER_ANALYTICS.CONNECTIONS_ANALYTICS.ROUTE);
		}
	}

	renderRep(value, data) {
		if (data) {
			return (
				<div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
					<Pic personId={data.personId} picSize={40}/>
					<div style={{fontSize: 16, fontWeight: 'bold', paddingLeft: 10}}>
						{value}
					</div>
				</div>
			);
		}
		else {
			return value;
		}
	}

	render() {
		let columns = [
			{title: 'Rep Name', key: 'partnerName', renderFunction: this.renderRep},
			{title: 'Status', key: 'partnerStatus', renderFunction: PartnerStatusHelper.getPartnerStatus},
			{title: 'Partner Org', key: 'partnerOrg'},
			{title: 'Matched Accounts', key: 'matchedAccountsCount'},
			{title: 'Intel Exchanges', key: 'intelExchangeCount'},
			{title: 'Date', key: 'updatedTimestamp', renderFunction: DateHelper.epochToDate}
		];
		return (
			<ManagerAnalyticsPage labelNoFind={'No rep partner data found'}
								  labelLoading={'Loading Rep Partner Data...'}
								  sectionName={'Rep Partners'}
								  glyph={'x'}
								  columnData={columns}
								  keyData={[ManagerAnalyticsEndpoints.keyNewPartners]}/>
		);
	}
}

RepNewPartnersAnalytics.propTypes = {
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(RepNewPartnersAnalytics);
