import {Poll} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../../../globals/Routes';
import ChannelHelper, {
	REPORT_BENEFICIAL_CUSTOMERS,
	REPORT_GLOBAL_OVERLAP,
	REPORT_GLOBAL_UNMATCHED,
	REPORT_HOT_PROSPECTS,
	REPORT_MUTUAL_CUSTOMERS,
	REPORT_MUTUAL_PROSPECTS,
	REPORT_MY_OPPORTUNITIES,
	REPORT_MY_PARTNER_LIST,
	REPORT_PARTNER_LIST,
	REPORT_PIPELINE,
	REPORT_SOURCED_PIPELINE,
	REPORT_USER_ENGAGEMENT
} from '../../../../helpers/ChannelHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_VIEW_ECOSYSTEM_OPP_REPORTS,
	FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS,
	FEATURE_CHANNEL_VIEW_USER_REPORT
} from '../../../../helpers/FeatureHelper';
import PagingBase from '../../../../ui/lists/PagingBase';
import GlobalReportTileRenderer from './GlobalReportTileRenderer';
import LinkButton from '../../../../ui/buttons/LinkButton';

class GlobalReportsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.onViewReport = this.onViewReport.bind(this);
	}

	get storageKeyBase() {
		return 'ecosystem_reports';
	}

	get title() {
		return 'Ecosystem Global Reports';
	}

	get icon() {
		return Poll;
	}

	get columnData() {
		return [
			{
				title: 'Report Name',
				key: 'title',
				renderFunction: (columnValue, rowData) =>
					<LinkButton label={rowData.title} disabled={rowData.isDisabled} onClick={() => rowData.onViewReport(rowData.reportType)}/>,
				sortDisabled: true
			},
			{title: 'Description', key: 'description', sortDisabled: true}
		];
	}

	onViewReport(reportType) {
		let path = Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.REPORT.PATH(reportType);
		let params = '?reportType=' + reportType;
		this.openDetailDialog('Ecosystem Global Report', path, params);
	}

	get searchComponent() {
		return null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let rowData = [
			{
				reportType: REPORT_GLOBAL_OVERLAP,
				title: ChannelHelper.getReportTypeTitle(REPORT_GLOBAL_OVERLAP),
				description: 'Your accounts that overlap with partner accounts',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_GLOBAL_UNMATCHED,
				title: ChannelHelper.getReportTypeTitle(REPORT_GLOBAL_UNMATCHED),
				description: 'Your accounts that do not match with any partner',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_MUTUAL_PROSPECTS,
				title: ChannelHelper.getReportTypeTitle(REPORT_MUTUAL_PROSPECTS),
				description: 'Your prospects that overlap with partner prospects',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_BENEFICIAL_CUSTOMERS,
				title: ChannelHelper.getReportTypeTitle(REPORT_BENEFICIAL_CUSTOMERS),
				description: 'Your accounts that are targeted by your partners',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_HOT_PROSPECTS,
				title: ChannelHelper.getReportTypeTitle(REPORT_HOT_PROSPECTS),
				description: 'Your prospects that are partner customers',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_MUTUAL_CUSTOMERS,
				title: ChannelHelper.getReportTypeTitle(REPORT_MUTUAL_CUSTOMERS),
				description: 'Your customers that are also partner customers',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_MY_OPPORTUNITIES,
				title: ChannelHelper.getReportTypeTitle(REPORT_MY_OPPORTUNITIES),
				description: 'Your opportunities with all of your partners',
				onViewReport: this.onViewReport,
				isDisabled: !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_ECOSYSTEM_OPP_REPORTS)
			},
			{
				reportType: REPORT_SOURCED_PIPELINE,
				title: ChannelHelper.getReportTypeTitle(REPORT_SOURCED_PIPELINE),
				description: 'New opps created after accounts matched',
				onViewReport: this.onViewReport,
				isDisabled: !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_ECOSYSTEM_OPP_REPORTS)
			},
			{
				reportType: REPORT_USER_ENGAGEMENT,
				title: ChannelHelper.getReportTypeTitle(REPORT_USER_ENGAGEMENT),
				description: 'Company-wide PartnerTap user engagement with all partners',
				onViewReport: this.onViewReport,
				isDisabled: !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_USER_REPORT)
			},
			{
				reportType: REPORT_PIPELINE,
				title: ChannelHelper.getReportTypeTitle(REPORT_PIPELINE),
				description: 'Multi-partner list of all accounts and shared pipeline',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_MY_PARTNER_LIST,
				title: ChannelHelper.getReportTypeTitle(REPORT_MY_PARTNER_LIST),
				description: 'Summary of all of your connected partners',
				onViewReport: this.onViewReport
			},
			{
				reportType: REPORT_PARTNER_LIST,
				title: ChannelHelper.getReportTypeTitle(REPORT_PARTNER_LIST),
				description: 'Company-wide PartnerTap user connections with all partners',
				onViewReport: this.onViewReport,
				isDisabled: !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS)
			}
		];
		setTimeout(() => this.processData(params, {payload: rowData}));
	}

	get tileRenderer() {
		return GlobalReportTileRenderer;
	}
}

export default connect()(GlobalReportsPage);
