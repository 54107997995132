import {GroupAdd} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {ACTIONS} from '../../helpers/ColumnHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import PagingBase from '../../ui/lists/PagingBase';
import OrgSelector from './shared/OrgSelector';
import LinkButton from '../../ui/buttons/LinkButton';

const ORG_REPORT_TYPE_AUTO_PARTNER = 'AUTO_PARTNER';

class AdminAutoPartneredOrgsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.selectedOrg = null;
		this.state.filterSelectorFunction = AdminEndpoints.fetchOrgReportFilterData;
		this.state.filterSelectorMounted = false;

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
		this.setAutoPartner = this.setAutoPartner.bind(this);
	}

	componentDidMount() {
		this.fetchOrgColumns();
	}

	get storageKeyBase() {
		return 'admin_orgs';
	}

	get title() {
		return 'Auto Partner Orgs';
	}

	get icon() {
		return GroupAdd;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	fetchOrgColumns() {
		AdminEndpoints.fetchOrgReportColumns(ORG_REPORT_TYPE_AUTO_PARTNER)
		.then((result) => {
			if (this.unmounted) return;
			result.payload.push({
				title: 'Actions',
				key: ACTIONS,
				sortDisabled: true,
				renderFunction: (columnValue, rowData) => {
					let actionButton;
					if (rowData.enabled) {
						actionButton = <LinkButton key={'disable'}
												   label={'CANCEL AUTO PARTNER'}
												   onClick={() => this.setAutoPartner(rowData.orgId, false)}
												   confirmationMessage={'Are you sure you want to cancel auto partnering?'}/>;
					}
					else {
						actionButton = <LinkButton key={'enable'}
												   label={'AUTO PARTNER'}
												   onClick={() => this.setAutoPartner(rowData.orgId, true)}
												   confirmationMessage={
													   <div>
														   Are you sure you want to enable auto partnering?<br/>
														   This will also make {rowData.orgName} a recommended partner of {this.state.selectedOrg.name}.
													   </div>}/>;
					}
					return (
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
							{actionButton}
						</div>
					);
				}
			});
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchOrgReportColumns', error);
		});
	}

	setAutoPartner(partnerOrgId, enabled) {
		let {selectedOrg} = this.state;
		AdminEndpoints.updateOrgReportDetail(partnerOrgId, selectedOrg.orgId, enabled, ORG_REPORT_TYPE_AUTO_PARTNER)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				this.setState({loading: true});
			}
		});
	}

	handleOrgSelection(org) {
		this.setState({selectedOrg: org, loading: true});
	}

	get filterSelectorArgs() {
		return {orgId: this.state.selectedOrg.orgId, isAutoPartner: true, orgReportType: ORG_REPORT_TYPE_AUTO_PARTNER};
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let {selectedOrg} = this.state;
		let params = this.processParameters();
		AdminEndpoints.fetchOrgReportList(selectedOrg.orgId, params.search, params.filters, params.page, params.pageSize, params.sort, ORG_REPORT_TYPE_AUTO_PARTNER)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		let {selectedOrg} = this.state;
		return (
			<div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: partnerTapWhite}}>
					<div style={{padding: 10}}>
						<OrgSelector onOrgSelection={this.handleOrgSelection}/>
					</div>
				</div>
				{Boolean(selectedOrg) &&
				 super.render()}
			</div>
		);
	}
}

export default connect()(AdminAutoPartneredOrgsPage);
