import _ from 'lodash';
import React, {Component} from 'react';
import EnvHelper from '../../../../helpers/EnvHelper';
import StringHelper from '../../../../helpers/StringHelper';
import {partnerTapAppBackground, partnerTapInactive, partnerTapLightestGrey, partnerTapStroke} from '../../../../styles/partnertap_theme';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import LinkButton from '../../../../ui/buttons/LinkButton';
import Dialog from '../../../../ui/Dialog';
import Loading from '../../../../ui/Loading';
import TextInputBox from '../../../../ui/TextInputBox';
import DashboardTable from '../../shared/DashboardTable';
import * as CoSellEngineHelper from '../CoSellEngineHelper';

export class AdminConfigBase extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, adminSequenceConfigs: null, customCrmActivities: null, editingAdminSequenceConfig: null, workflowEmailContent: null};

		this.getWorkflowConfigs = this.getWorkflowConfigs.bind(this);
		this.onEditAdminConfig = this.onEditAdminConfig.bind(this);
		this.renderAdminConfigEditor = this.renderAdminConfigEditor.bind(this);
		this.saveAdminConfig = this.saveAdminConfig.bind(this);
		this.cancelAdminConfigEdit = this.cancelAdminConfigEdit.bind(this);
	}

	componentDidMount() {
		this.getWorkflowConfigs();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get columnData() {
		return [
			{
				title: 'Name',
				key: 'name',
				active: true,
				renderFunction: (columnValue, rowData) => <LinkButton label={columnValue} onClick={() => this.onEditAdminConfig(rowData)}/>
			},
			{title: 'Description', key: 'description', active: true},
			{title: 'Active', key: 'isActive', type: 'boolean', active: true}
		];
	}

	get workflow() {
		throw new Error('AdminConfigBase.workflow must be overridden!');
	}

	get title() {
		return this.workflow.name;
	}

	get icon() {
		return this.workflow.MaterialIcon;
	}

	get getWorkflowConfigsEndpoint() {
		throw new Error('AdminConfigBase.workflowConfigsEndpoint must be overridden!');
	}

	get updateWorkflowConfigEndpoint() {
		throw new Error('AdminConfigBase.updateWorkflowConfigEndpoint must be overridden!');
	}

	get renderAdditionalEditorFields() {
		return null;
	}

	get sectionStyle() {
		return {
			display: 'flex',
			flexDirection: 'column',
			gap: 10,
			padding: 20,
			border: '1px solid ' + partnerTapStroke,
			borderRadius: 10,
			backgroundColor: partnerTapAppBackground
		};
	}

	getWorkflowConfigs() {
		this.getWorkflowConfigsEndpoint()
		.then((result) => {
			if (this.unmounted) return;
			this.setState({loading: false, adminSequenceConfigs: result.payload});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCrmActions', error);
		});
	}

	onEditAdminConfig(adminConfig) {
		this.setState({editingAdminSequenceConfig: _.clone(adminConfig), workflowEmailContent: _.cloneDeep(CoSellEngineHelper.workflowEmailContent)});
	}

	saveAdminConfig() {
		let {editingAdminSequenceConfig, workflowEmailContent} = this.state;
		this.updateWorkflowConfigEndpoint(editingAdminSequenceConfig)
		.then((result) => {
			CoSellEngineHelper.updateWorkflowEmailContent(workflowEmailContent);
			this.setState({loading: true, editingAdminSequenceConfig: null});
			this.getWorkflowConfigs();
		})
		.catch((error) => {
			EnvHelper.serverError('Error from saveAdminConfig', error);
		});
	}

	cancelAdminConfigEdit() {
		this.setState({editingAdminSequenceConfig: null, workflowEmailContent: _.cloneDeep(CoSellEngineHelper.workflowEmailContent)});
	}

	renderAdminConfigEditor() {
		let {editingAdminSequenceConfig} = this.state;
		let additionalEditorFields = this.renderAdditionalEditorFields;
		let showActiveControl = false; // TODO:COLIN ability to remove workflow sequences is under debate (implementation incomplete)
		return (
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<div style={{display: 'flex', flexDirection: 'column', gap: 10, minWidth: 600, maxWidth: 600}}>
					<TextInputBox value={editingAdminSequenceConfig.name} hintText={'Name'} doNotAutoFocus={true} onChange={() => {
						// nothing to do for standard workflows
					}}/>
					<TextInputBox value={editingAdminSequenceConfig.description} hintText={'Description'} rows={3} doNotAutoFocus={true} onChange={() => {
						// nothing to do for standard workflows
					}}/>
					{showActiveControl &&
					 <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
						 <CheckboxButton checked={editingAdminSequenceConfig.isActive}
										 style={{padding: 0}}
										 onChange={() => {
											 editingAdminSequenceConfig.isActive = !editingAdminSequenceConfig.isActive;
											 this.forceUpdate();
										 }}/>
						 Active
					 </div>}
					{additionalEditorFields}
				</div>
			</div>
		);
	}

	renderEmailTemplateEditor(workflow, sequence, sequenceOrdinal) {
		let emailContent = this.state.workflowEmailContent[workflow][sequence]['email_' + sequenceOrdinal];
		return (
			<div key={sequence + '_' + sequenceOrdinal} style={this.sectionStyle}>
				<div style={{fontSize: 16, paddingBottom: 20}}>
					Email {sequenceOrdinal}: {emailContent.title}
				</div>
				<TextInputBox key={'subject'}
							  value={emailContent.subject}
							  hintText={'Subject'}
							  doNotAutoFocus={true}
							  onChange={(value) => {
								  emailContent.subject = value;
								  this.forceUpdate();
							  }}/>
				<TextInputBox key={'body_intro'}
							  value={emailContent.body_1}
							  hintText={'Intro'}
							  doNotAutoFocus={true}
							  multiLine={true}
							  rows={5}
							  onChange={(value) => {
								  emailContent.body_1 = value;
								  this.forceUpdate();
							  }}/>
				<div style={{backgroundColor: partnerTapLightestGrey, border: '1px solid ' + partnerTapStroke, borderRadius: 4, padding: 10, marginBottom: 5}}>
					<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
						<CheckboxButton checked={emailContent.customMessageEnabled}
										style={{padding: 0}}
										onChange={() => {
											emailContent.customMessageEnabled = !emailContent.customMessageEnabled;
											this.forceUpdate();
										}}/>
						Allow custom message
					</div>
					<div style={{padding: 10, color: partnerTapInactive}}>
						When enabled, this field will be editable by users running this workflow
					</div>
				</div>
				<TextInputBox key={'body_conclusion'}
							  value={emailContent.body_2}
							  hintText={'Conclusion'}
							  doNotAutoFocus={true}
							  multiLine={true}
							  rows={5}
							  onChange={(value) => {
								  emailContent.body_2 = value;
								  this.forceUpdate();
							  }}/>
			</div>
		);
	}

	render() {
		let {loading, adminSequenceConfigs, editingAdminSequenceConfig} = this.state;
		if (loading) return <Loading>Loading...</Loading>;
		return (
			<div style={{padding: 10, paddingTop: 0}}>
				<DashboardTable key={StringHelper.formatKey(this.title)}
								MaterialIcon={this.icon}
								title={'Workflow Sequences'}
								columnData={this.columnData}
								rowData={adminSequenceConfigs}
								maxHeight={'calc(100vh - 190px)'}
								hasScrollingContainerParent={true}
								noMargins={true}/>
				{editingAdminSequenceConfig &&
				 <Dialog title={'Configure ' + this.title}
						 message={this.renderAdminConfigEditor()}
						 yesLabel={'SAVE'}
						 yesAction={this.saveAdminConfig}
						 noLabel={'CANCEL'}
						 noAction={this.cancelAdminConfigEdit}/>}
			</div>
		);
	}
}
