import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapDropShadow, partnerTapPrimary, partnerTapWhite} from '../../../styles/partnertap_theme';

class DetailHeader extends Component {

	render() {
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: 10,
				padding: 10,
				backgroundColor: partnerTapWhite,
				boxShadow: partnerTapDropShadow,
				minHeight: 50
			}}>
				<div style={{display: 'flex', alignItems: 'center', paddingRight: 10}}>
					<this.props.MaterialIcon fontSize={'large'} style={{color: partnerTapPrimary}}/>
					<div style={{fontSize: 20, paddingLeft: 5}}>
						{this.props.title}
					</div>
				</div>
				{Boolean(this.props.detailCenter) &&
				 this.props.detailCenter}
				{Boolean(this.props.detailRight) &&
				 <div style={{display: 'flex', flexDirection: 'column', textAlign: 'right', paddingLeft: 10}}>
					 {this.props.detailRight}
				 </div>}
			</div>
		);
	}
}

DetailHeader.propTypes = {
	MaterialIcon: PropTypes.elementType.isRequired,
	title: PropTypes.string.isRequired,
	detailCenter: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	detailRight: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default DetailHeader;
