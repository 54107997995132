import React from 'react';
import {FEEDBACK_UI_ELEMENT_TIMEOUT} from '../app/App';
import {PERSISTENT_KEY} from '../globals/StorageKeys';
import {
	partnerTapAlert,
	partnerTapPrimary,
	partnerTapStroke,
	partnerTapTernary,
	partnerTapTernaryLight,
	partnerTapWarn,
	partnerTapWhite
} from '../styles/partnertap_theme';
import ToolTipOverlay from '../ui/ToolTipOverlay';
import DialogHelper from './DialogHelper';
import PersistenceHelper from './PersistenceHelper';

export default class TableHelper {

	static set blockSortOnResize(block) {
		this.blockSort = block;
	}

	static get blockSortOnResize() {
		return this.blockSort;
	}

	static set dragTable(table) {
		this.currentDragTable = table;
	}

	static get dragTable() {
		return this.currentDragTable;
	}

	static set dragColumn(column) {
		this.currentDragColumn = column;
	}

	static get dragColumn() {
		return this.currentDragColumn;
	}

	static set dropColumn(column) {
		this.currentDropColumn = column;
	}

	static get dropColumn() {
		return this.currentDropColumn;
	}

	static set dropColumnDirty(isDirty) {
		this.currentDropColumnDirty = isDirty;
	}

	static get dropColumnDirty() {
		return this.currentDropColumnDirty;
	}

	static isDragColumn(column) {
		return TableHelper.dragColumn && column.key === TableHelper.dragColumn.key;
	}

	static isDropColumn(column) {
		return TableHelper.dropColumn && column.key === TableHelper.dropColumn.key;
	}

	static addDropColumnStyle(column, cellStyle) {
		if (TableHelper.isDropColumn(column)) {
			if (TableHelper.dragColumn.index > column.index) {
				cellStyle.borderLeft = 'solid 10px ' + partnerTapWarn;
			}
			else {
				cellStyle.borderRight = 'solid 10px ' + partnerTapWarn;
			}
		}
	}

	static addResizeColumnStyle(column, cellStyle) {
		const maxWidth = 600;
		if (column.width) {
			if (column.width > maxWidth) column.width = maxWidth;
			cellStyle.width = column.width + 20; // 20 compensates for padding of 10 on both sides of content
			if (column.enforceMinWidth) cellStyle.minWidth = column.width;
		}
		cellStyle.maxWidth = maxWidth;
	}

	static storeColumnCustomizations(storageKey, columns) {
		let columnSizes = {};
		let columnOrder = {};
		columns.forEach((column, index) => {
			if (column.width) columnSizes[column.key] = column.width;
			columnOrder[column.key] = index;
		});
		PersistenceHelper.setValue(TableHelper.columnSizeStorageKey(storageKey), JSON.stringify(columnSizes));
		PersistenceHelper.setValue(TableHelper.columnOrderStorageKey(storageKey), JSON.stringify(columnOrder));
		if (DialogHelper.savedReportData.onDirty) DialogHelper.savedReportData.onDirty();
	}

	static initColumnCustomizations(storageKey, columns) {
		let columnSizesStored = PersistenceHelper.getValue(TableHelper.columnSizeStorageKey(storageKey));
		if (columnSizesStored) {
			let columnSizes = JSON.parse(columnSizesStored);
			columns.forEach((column) => {
				let width = columnSizes[column.key];
				if (width) {
					column.width = width;
					column.enforceMinWidth = true;
				}
			});
		}

		let columnOrderStored = PersistenceHelper.getValue(TableHelper.columnOrderStorageKey(storageKey));
		if (columnOrderStored) {
			let columnOrder = JSON.parse(columnOrderStored);
			columns.forEach((column) => {
				column.index = columnOrder[column.key];
			});
			columns.sort((columnA, columnB) => {
				return columnA.index < columnB.index ? -1 : 1;
			});
		}
	}

	static updateColumnOrderAttributes(columns, isForColumnSelector) {
		let index = 0;
		let ordinal = 1;
		columns.forEach((column) => {
			column.index = index++;
			if (!column.isHidden && !(isForColumnSelector && column.sortDisabled)) {
				column.ordinal = ordinal++;
			}
		});
	}

	static columnSizeStorageKey(storageKeyBase) {
		return PERSISTENT_KEY + storageKeyBase + '_size_columns';
	}

	static columnOrderStorageKey(storageKeyBase) {
		return PERSISTENT_KEY + storageKeyBase + '_order_columns';
	}

	static columnHeaderKey(tableKey, column, isForColumnSelector) {
		return tableKey + '_' + column.key + '_column_header' + (isForColumnSelector ? '_selector' : '');
	}

	static set resizeColumnKey(key) {
		this.currentResizeColummKey = key;
	}

	static get resizeColumnKey() {
		return this.currentResizeColummKey;
	}

	static tileStyle(width, height, borderColor, gap = 0, cursor = 'default') {
		return {
			position: 'relative',
			boxSizing: 'border-box',
			borderRadius: 10,
			padding: 10,
			margin: 10,
			width: width - 20,
			minWidth: width - 20,
			height: height - 20,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: gap,
			textAlign: 'center',
			overflow: 'hidden',
			backgroundColor: partnerTapWhite,
			border: '1px solid' + (borderColor || partnerTapStroke),
			cursor: cursor
		};
	}

	static get boxStyle() {
		return {
			boxSizing: 'border-box',
			borderRadius: 10,
			padding: 20,
			display: 'flex',
			flexDirection: 'column',
			gap: 10,
			backgroundColor: partnerTapTernaryLight,
			border: '1px solid' + partnerTapPrimary
		};
	}

	static get autoHeightTileStyle() {
		return {
			position: 'relative',
			boxSizing: 'border-box',
			borderRadius: 10,
			padding: 10,
			margin: 10,
			textAlign: 'center',
			backgroundColor: partnerTapWhite,
			border: '1px solid' + partnerTapTernary
		};
	}

	static get widthObserverFunction() {
		return function(element) {
			if (element) {
				if (this.observer) {
					this.observer.disconnect();
				}
				this.observer = new ResizeObserver(() => {
					// requestAnimationFrame is required to avoid ResizeObserver loop limit exceeded
					window.requestAnimationFrame(() => {
						if (this.unmounted) return;
						if (this.infoElement) {
							this.infoElement.style.width = Math.round((element.clientWidth * (window.innerWidth < 450 ? 0.5 : 0.6))) + 'px';
						}
					});
				});
				this.observer.observe(element);
			}
		};
	}

	static copyRenderer(caller, value, key) {
		if (!value) return;
		let tooltipDivId = 'tooltipDiv_' + key;
		let messageDivId = 'messageDiv_' + key;
		let showMessageFunction = (message, color) => {
			if (caller.unmounted) return;
			let tooltipDiv = document.getElementById(tooltipDivId);
			let messageDiv = document.getElementById(messageDivId);
			if (tooltipDiv && messageDiv) {
				tooltipDiv.style.visibility = 'hidden'; // ToolTipOverlay issues a warning when display is set to none, so using visibility+height
				tooltipDiv.style.height = '0px';
				messageDiv.style.display = 'inline';
				messageDiv.innerText = message;
				messageDiv.style.color = color;
				setTimeout(() => {
					if (caller.unmounted) return;
					tooltipDiv.style.visibility = 'visible';
					tooltipDiv.style.height = '20px';
					messageDiv.style.display = 'none';
				}, FEEDBACK_UI_ELEMENT_TIMEOUT);
			}
		};
		return (
			<div onClick={(event) => {
				event.stopPropagation(); // do not select table row
				navigator.clipboard.writeText(value)
				.then(() => {
					showMessageFunction('Copied', partnerTapTernary);
				})
				.catch((error) => {
					showMessageFunction('Sorry, error', partnerTapAlert);
					console.error('Error writing to clipboard', error);
				});
			}}>
				<div id={tooltipDivId}>
					<ToolTipOverlay title={'Press here to copy: ' + value}>
						Press here to copy
					</ToolTipOverlay>
				</div>
				<div id={messageDivId}/>
			</div>
		);
	}
}
