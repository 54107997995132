import * as ApiHelper from '../helpers/ApiHelper';

export function fetchOrgSamlDto() {
	return ApiHelper.sendGet('/admin-center/saml');
}

export function saveOrgSamlDto(payload) {
	return ApiHelper.sendPost('/admin-center/saml/save', payload);
}

export function deleteOrgSamlDto() {
	return ApiHelper.sendDelete('/admin-center/saml/delete');
}
