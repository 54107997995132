import PropTypes from 'prop-types';
import React, {Component} from 'react';
import PersistenceHelper from '../../helpers/PersistenceHelper';

const SIDE_BAR_STORAGE_KEY = 'persistent__channel_ecosystem_labels';
const SIDE_BAR_SIZE_NO_LABELS = 50;
const SIDE_BAR_SIZE_WITH_LABELS = 240;
const SMALL_SCREEN = 1060;
const MOBILE_SCREEN = 735;

class WidthAjustedMargin extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			windowWidth: window.innerWidth,
			isSideBarOpen: PersistenceHelper.getValue(SIDE_BAR_STORAGE_KEY)?.toLowerCase() === "true"
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateWindowDimensions);
		window.addEventListener('PersistenceChanged', this.handleStorageChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		window.removeEventListener('PersistenceChanged', this.handleStorageChange);
		this.unmounted = true;
	}

	updateWindowDimensions = () => {
		this.setState({windowWidth: window.innerWidth});
	};

	handleStorageChange = () => {
		this.setState({
			isSideBarOpen: PersistenceHelper.getValue(SIDE_BAR_STORAGE_KEY)?.toLowerCase() === "true"
		});
	};

	get divId() {
		return 'widthA_container_div_' + this.props.divId;
	}

	get marginLeftForWrappingDiv() {
		const {windowWidth, isSideBarOpen} = this.state;
		const sideBarWidth = isSideBarOpen ? SIDE_BAR_SIZE_WITH_LABELS : SIDE_BAR_SIZE_NO_LABELS;

		if (windowWidth < SMALL_SCREEN) {
			return windowWidth < MOBILE_SCREEN ? `${SMALL_SCREEN - windowWidth}px` : `${SMALL_SCREEN - windowWidth + sideBarWidth}px`;
		}

		return '0px';
	}

	get divStyle() {
		return {
			marginLeft: this.marginLeftForWrappingDiv,
		};
	}

	render() {
		return (
			<div id={this.divId} style={this.divStyle}>
				{this.props.children}
			</div>
		);
	}
}

WidthAjustedMargin.propTypes = {
	divId: PropTypes.string.isRequired
};

export default WidthAjustedMargin;