import {LOGIN_INVITER_PROFILE_KEY} from '../globals/StorageKeys';
import * as ApiHelper from '../helpers/ApiHelper';
import EnvHelper from '../helpers/EnvHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';

export function trackLogin(person) {
	let inviterId;
	let productCode;
	let inviterProfile = PersistenceHelper.getValue(LOGIN_INVITER_PROFILE_KEY);
	if (inviterProfile) {
		let profile = JSON.parse(inviterProfile);
		inviterId = profile.inviterPersonId;
		productCode = profile.productCode;
	}
	let referringEmailId = PersistenceHelper.getReferringEmailId();
	if (referringEmailId) referringEmailId = '&referringEmailId=' + referringEmailId;
	let endpoint = '/t?personId=' + person.id;
	if (inviterId) endpoint = endpoint + '&inviterId=' + inviterId;
	if (productCode) endpoint = endpoint + '&productCode=' + productCode.toUpperCase();
	ApiHelper.sendPut(endpoint + '&isMobile=' + EnvHelper.isMobile + '&appVersion=' + EnvHelper.version + referringEmailId, {});
}

export function trackLead(feature, partnerPersonId) {
	let endPoint = '/t/upsell?featureName=' + feature;
	if (partnerPersonId) {
		endPoint = endPoint + '&partnerPersonId=' + partnerPersonId;
	}
	return ApiHelper.sendPost(endPoint);
}
