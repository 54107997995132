import * as PartnersEndpoints from '../../../../endpoints/PartnersEndpoints';
import {CATEGORY_PARTNER_OPPS, INVITE_STATUS_ACCEPTED} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import SavedReportBase from './SavedReportBase';

class SavedReportPartnerConnection extends SavedReportBase {

	get path() {
		let pageName;
		if (this.reportCategory === CATEGORY_PARTNER_OPPS) {
			pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_OPPORTUNITIES.PAGE_NAME;
		}
		else {
			pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_ACCOUNTS.PAGE_NAME;
		}
		return this.reportUrl + '/' + this.reportParams.partner + '/' + pageName + (this.reportType ? '/' + this.reportType : '');
	}

	get params() {
		return super.params + '&filterTitle=' + this.reportParams.filterTitle;
	}

	async validateReport() {
		return await PartnersEndpoints.fetchPartner(this.reportParams.partner)
		.then((result) => {
			if (this.unmounted) return false;
			if (result.payload) {
				if (result.payload.status === INVITE_STATUS_ACCEPTED) {
					return true;
				}
				else {
					let {firstName, lastName, organization} = result.payload;
					this.errorMessage = 'Your partnership with ' + firstName + ' ' + lastName + ' of ' + organization + ' isn\'t active. You can no longer view this report.';
					return false;
				}
			}
			else {
				this.errorMessage = 'There was an unknown error retrieving your report.';
				return false;
			}
		})
		.catch((error) => {
			this.errorMessage = 'Error validating SavedReportPartnerSheet' + error;
			return false;
		});
	}
}

export default SavedReportPartnerConnection;
