import {LiveHelp} from '@mui/icons-material';
import React, {Component} from 'react';
import PrimaryButton from './PrimaryButton';

class SupportButton extends Component {
	render() {
		return <PrimaryButton label={'CONTACT SUPPORT'} icon={<LiveHelp/>} onClick={() => window.open('https://partnertap.com/support/')}/>;
	}
}

export default SupportButton;
