import {Delete, FileCopyOutlined, Publish} from '@mui/icons-material';
import React, {Component, Fragment} from 'react';
import * as AdminCenterEndpoints from '../../../endpoints/AdminCenterEndpoints';
import EnvHelper from '../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_DATA_MANAGER} from '../../../helpers/FeatureHelper';
import StringHelper from '../../../helpers/StringHelper';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import Loading from '../../../ui/Loading';
import TextInputBox from '../../../ui/TextInputBox';
import Upsell from '../../../ui/Upsell';

class Saml extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			copyLabel1: 'COPY',
			copyLabel2: 'COPY',
			partnerTapSsoUri: '',
			partnerTapEntityId: '',
			cert: '',
			certMissing: false,
			ssoUri: '',
			ssoUriMissing: false,
			issuerId: '',
			issuerIdMissing: false,
			setupComplete: false
		};
		this.handleDeleteOrgSamlDto = this.handleDeleteOrgSamlDto.bind(this);
		this.handleSubmitOrgSamlDto = this.handleSubmitOrgSamlDto.bind(this);
	}

	componentDidMount() {
		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER)) {
			this.getOrgSamlDto();
		}
		else {
			setTimeout(() => this.setState({showUpsell: 'SAML Configuration Options'}));
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getOrgSamlDto() {
		this.setState({loading: true});
		AdminCenterEndpoints.fetchOrgSamlDto()
		.then((result) => {
			if (this.unmounted) return;
			this.processPayload(result.payload);
		})
		.catch((error) => {
			this.setState({setupComplete: false, loading: false});
			EnvHelper.serverError('Error from getOrgSamlDto', error);
		});
	}

	handleSubmitOrgSamlDto() {
		let {cert, ssoUri, issuerId} = this.state;
		if (!ssoUri) {
			this.setState({ssoUriMissing: true});
		}
		else if (!issuerId) {
			this.setState({issuerIdMissing: true});
		}
		else if (!cert) {
			this.setState({certMissing: true});
		}
		else {
			this.setState({loading: true});
			let payload = {
				cert: StringHelper.trimWhiteSpace(cert),
				ssoUri: StringHelper.trimWhiteSpace(ssoUri),
				issuerId: StringHelper.trimWhiteSpace(issuerId)
			};
			AdminCenterEndpoints.saveOrgSamlDto(payload)
			.then((result) => {
				if (this.unmounted) return;
				this.processPayload(result.payload);
			})
			.catch((error) => {
				this.setState({setupComplete: false, loading: false});
				EnvHelper.serverError('Error from handleSubmitOrgSamlDto', error);
			});
		}
	}

	processPayload(payload) {
		let {partnerTapSsoUri, partnerTapEntityId, setupComplete} = payload;
		this.setState({partnerTapSsoUri: partnerTapSsoUri, partnerTapEntityId: partnerTapEntityId, setupComplete: setupComplete, loading: false});
	}

	handleDeleteOrgSamlDto() {
		this.setState({loading: true});
		AdminCenterEndpoints.deleteOrgSamlDto().then(() => {
			this.setState({cert: '', ssoUri: '', issuerId: ''});
			this.getOrgSamlDto();
		})
		.catch((error) => {
			this.setState({setupComplete: false, loading: false});
			EnvHelper.serverError('Error from handleDeleteOrgSamlDto', error);
		});
	}

	renderForm() {
		let {loading, cert, certMissing, ssoUri, ssoUriMissing, issuerId, issuerIdMissing} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 600}}>
				{this.renderInfoInputs()}
				<div style={{fontSize: 16, paddingTop: 20, paddingBottom: 10}}>
					Configure Saml
				</div>
				<TextInputBox hintText={'Identity Provider Single Sign-On URL'}
							  errorText={ssoUriMissing ? 'Identity Provider Single Sign-On URL is required' : null}
							  value={ssoUri}
							  onChange={(value) => this.setState({ssoUri: value, ssoUriMissing: false})}
							  maxChars={255}/>
				<TextInputBox hintText={'Issuer'}
							  errorText={issuerIdMissing ? 'Issuer ID is required' : null}
							  value={issuerId}
							  onChange={(value) => this.setState({issuerId: value, issuerIdMissing: false})}
							  maxChars={255}/>
				<TextInputBox hintText={'X.509 Certificate'}
							  multiLine={true}
							  errorText={certMissing ? 'X.509 Certificate is required' : null}
							  value={cert}
							  onChange={(value) => this.setState({cert: value, certMissing: false})}
							  maxChars={20000}/>
				<div style={{margin: 'auto'}}>
					<PrimaryButton disabled={loading || certMissing || ssoUriMissing || issuerIdMissing}
								   icon={<Publish/>}
								   label={'SUBMIT'}
								   onClick={this.handleSubmitOrgSamlDto}/>
				</div>
			</div>
		);
	}

	renderSamlComplete() {
		let {loading} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 360}}>
				{this.renderInfoInputs()}
				<div style={{fontSize: 16, paddingTop: 20, paddingBottom: 10}}>
					Configure Saml
				</div>
				<div style={{margin: 'auto'}}>
					<PrimaryButton disabled={loading} icon={<Delete/>} label={'DELETE'} onClick={this.handleDeleteOrgSamlDto}/>
				</div>
			</div>
		);
	}

	handleCopy(label, text) {
		navigator.clipboard.writeText(text)
		.then(() => {
			if (this.unmounted) return;
			this.setState({[label]: 'COPIED'});
		})
		.catch((error) => {
			this.setState({[label]: 'SORRY, ERROR!'});
			console.error('Error writing to clipboard', error);
		});
	}

	renderInfoInputs() {
		let {partnerTapSsoUri, partnerTapEntityId, copyLabel1, copyLabel2} = this.state;
		return (
			<Fragment>
				<div style={{fontSize: 16, paddingBottom: 10}}>
					Copy PartnerTap Info
				</div>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
					<TextInputBox hintText={'PartnerTap Single Sign-On URL'}
								  disabled={true}
								  value={partnerTapSsoUri}
								  onChange={() => {
								  }}
								  maxChars={255}/>
					<PrimaryButton label={copyLabel1}
								   icon={<FileCopyOutlined/>}
								   onClick={() =>
									   this.handleCopy('copyLabel1', partnerTapSsoUri)
								   }/>
				</div>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10}}>
					<TextInputBox hintText={'PartnerTap Entity Id'}
								  disabled={true}
								  value={partnerTapEntityId}
								  onChange={() => {
								  }}
								  maxChars={255}/>
					<PrimaryButton label={copyLabel2}
								   icon={<FileCopyOutlined/>}
								   onClick={() =>
									   this.handleCopy('copyLabel2', partnerTapEntityId)
								   }/>
				</div>
			</Fragment>
		);
	}

	render() {
		let {showUpsell, loading, setupComplete} = this.state;
		if (showUpsell) return <Upsell showUpsell={showUpsell}/>;
		if (loading) return <Loading>Loading SAML data...</Loading>;
		return setupComplete ? this.renderSamlComplete() : this.renderForm();
	}
}

export default Saml;
