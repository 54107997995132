import _ from 'lodash';
import AdminToolsProduct from '../app/admin_tools/AdminToolsProduct';
import ChannelEcosystemProduct from '../app/channel_ecosystem/ChannelEcosystemProduct';
import ManagerAnalyticsProduct from '../app/manager_analytics/ManagerAnalyticsProduct';
import SalesNetworkProduct from '../app/sales_network/SalesNetworkProduct';
import {Routes} from '../globals/Routes';
import * as ApiHelper from '../helpers/ApiHelper';
import * as AuthActions from '../redux/AuthActions';
import {MEDIA_TYPE_TEXT_PLAIN} from './ApiHelper';

// mobile platforms
const IOS = 'IOS';
const ANDROID = 'ANDROID';

// constant set by webpack.config.*.js */
const VERSION = process.env.VERSION;
const DEV_URI = process.env.DEV_URI;
const DEV_URI_REPORTS = process.env.DEV_URI_REPORTS;

export default class EnvHelper {

	static init() {
		console.info('------------------- ENV -------------------------');
		console.info('version:', this.version);
		console.info('env:', this.env);
		console.info('rest:', this.serviceUrl);
		console.info('reports:', this.reportUrl);
		console.info('cordova:', this.isCordova);
		console.info('------------------- END ENV ---------------------');

		if (!this.initialized) {
			this.initialized = true;
			this.profileImageUriValue = '';
			this.tokenUser = '';
			this.isDesktopValue = false;
			this.navBackCount = 0;
			this.navForwardCount = 0;
			this.subscribeToUniversalLinks();
			this.updateNoCache();
		}
	}

	static get version() {
		return VERSION;
	}

	static getReleaseVersion() {
		return ApiHelper.sendGet('/version.txt?nocache=' + Math.random(), null, MEDIA_TYPE_TEXT_PLAIN);
	}

	static get env() {
		if (this.isDevMobileTier) return 'mobile-dev';
		if (this.isDevWebTier) return 'dev';
		if (this.isTestTier) return 'test';
		if (this.isQaTier) return 'qa';
		if (this.isStageTier) return 'stage';
		return 'prod';
	}

	static get serviceUrl() {
		if (this.isDevMobileTier) return DEV_URI;
		if (this.isDevWebTier) return 'http://localhost:8080';
		if (this.isTestTier) return 'https://test.partnertap.com';
		if (this.isQaTier) return 'https://demo.partnertap.com';
		if (this.isStageTier) return 'https://stage.partnertap.com';
		return 'https://api.partnertap.com';
	}

	static get reportUrl() {
		if (this.isDevMobileTier) return DEV_URI_REPORTS;
		if (this.isDevWebTier) return 'http://localhost:6080';
		if (this.isTestTier) return 'https://test-reports.partnertap.com';
		if (this.isQaTier) return 'https://reports-qa.services.partnertap.net';
		if (this.isStageTier) return 'https://stage-reports.partnertap.com';
		return 'https://reports.partnertap.com';
	}

	static get webUrl() {
		if (this.isDevMobileTier) return DEV_URI;
		if (this.isDevWebTier) return 'http://localhost:3000';
		if (this.isTestTier) return 'https://test.partnertap.com';
		if (this.isQaTier) return 'https://demo.partnertap.com';
		if (this.isStageTier) return 'https://stage.partnertap.com';
		return 'https://app.partnertap.com';
	}

	static get coSellActionListUrl() {
		if (this.isDevWebTier) return 'http://localhost:9080';
		if (this.isTestTier) return 'https://cosell-action-list-test.services.partnertap.net';
		if (this.isQaTier) return 'https://cosell-action-list-qa.services.partnertap.net';
		if (this.isStageTier) return 'https://cosell-action-list-s-rest.services.partnertap.net';
		return 'https://cosell-action-list.partnertap.com';
	}

	static get coSellWorkflowUrl() {
		if (this.isDevWebTier) return 'http://localhost:9081';
		if (this.isTestTier) return 'https://cosell-workflow-test.services.partnertap.net';
		if (this.isQaTier) return 'https://cosell-workflow-qa.services.partnertap.net';
		if (this.isStageTier) return 'https://cosell-workflow-s-rest.services.partnertap.net';
		return 'https://cosell-workflow.partnertap.com';
	}

	static get coSellLandingUrl() {
		if (this.isDevWebTier) return 'http://localhost:9082';
		if (this.isTestTier) return 'https://cosell-landing-test.services.partnertap.net';
		if (this.isQaTier) return 'https://cosell-landing-qa.services.partnertap.net';
		if (this.isStageTier) return 'https://cosell-landing-s-rest.services.partnertap.net';
		return 'https://cosell-landing.partnertap.com';
	}

	static get apiKeyGeneratorUrl() {
		if (this.isDevMobileTier) return DEV_URI;
		if (this.isDevWebTier) return 'http://localhost:7777';
		if (this.isTestTier) return 'https://api-key-generator-test.services.partnertap.net';
		if (this.isQaTier) return 'https://api-key-generator-qa.services.partnertap.net';
		if (this.isStageTier) return 'https://api-key-generator-s-rest.services.partnertap.net';
		return 'https://api-key-generator.partnertap.com';
	}

	static get coSellAnalyticsUrl() {
		if (this.isDevWebTier) return 'http://localhost:8888';
		if (this.isTestTier) return 'https://cosell-analytics-test.services.partnertap.net';
		if (this.isQaTier) return 'https://cosell-analytics-qa.services.partnertap.net';
		if (this.isStageTier) return 'https://cosell-analytics-s-rest.services.partnertap.net';
		return 'https://cosell-analytics.partnertap.com';
	}

	static setCrmClientIds(config) {
		this.clientIdSalesforce = config.salesforceClientId;
		this.clientIdMicrosoft = config.microsoftClientId;
		this.clientIdHubspot = config.hubspotClientId;
	}

	static get salesforceClientId() {
		return this.clientIdSalesforce;
	}

	static get microsoftClientId() {
		return this.clientIdMicrosoft;
	}

	static get hubspotClientId() {
		return this.clientIdHubspot;
	}

	static get oktaClientId() {
		return '0oae42q6fjeD1EY1E0h7';
	}

	static get isDevMobileTier() {
		return this.runtimeEnv.indexOf('ngrok') !== -1;
	}

	static get isDevWebTier() {
		return this.runtimeEnv.indexOf('localhost') !== -1;
	}

	static get isDevTier() {
		return this.isDevMobileTier || this.isDevWebTier;
	}

	static get isTestTier() {
		return this.runtimeEnv.indexOf('test') !== -1;
	}

	static get isDemoTier() {
		return this.runtimeEnv.indexOf('demo') !== -1;
	}

	static get isStageTier() {
		return this.runtimeEnv.indexOf('stage') !== -1;
	}

	static get isQaTier() {
		return this.runtimeEnv.indexOf('qa') !== -1 || this.isTestTier || this.isDemoTier;
	}

	static get isNotProdTier() {
		return this.isQaTier || this.isStageTier || this.isDevTier;
	}

	static get runtimeEnv() {
		if (this.isCordova) {
			return window.cordova.partnerTapEnv;
		}
		else {
			return window.location.hostname;
		}
	}

	static get host() {
		if (window.location.href.indexOf('host=local') !== -1) return 'localhost';
		if (window.location.href.indexOf('host=qa') !== -1) return 'qa';
		if (window.location.href.indexOf('host=stage') !== -1) return 'stage';
		if (window.location.href.indexOf('host=prod') !== -1) return 'prod';
		return window.location.hostname;
	}

	static set isSpoofing(isSpoofing) {
		this.isSpoofingValue = isSpoofing;
	}

	static get isSpoofing() {
		return this.isSpoofingValue;
	}

	static set wasSpoofing(wasSpoofing) {
		this.wasSpoofingValue = wasSpoofing;
	}

	static get wasSpoofing() {
		return this.wasSpoofingValue;
	}

	static get isCordova() {
		return window.cordova !== undefined;
	}

	static get cordova() {
		if (this.isCordova) {
			return window.cordova;
		}
		else {
			return {};
		}
	}

	static get quietMode() {
		return window.location.href.indexOf('quiet=true') !== -1;
	}

	static initDispatch(dispatch) {
		this.dispatchRef = dispatch;
	}

	static get dispatch() {
		return this.dispatchRef;
	}

	static initHistory(history) {
		this.historyValue = history;
	}

	static get history() {
		return this.historyValue;
	}

	static get path() {
		return this.historyValue.location.pathname.replace(/\/$/ig, '');
	}

	static get inSalesNetworkProduct() {
		return EnvHelper.path.indexOf(Routes.DASHBOARD.ROUTE) === 0;
	}

	static get inManagerAnalyticsProduct() {
		return EnvHelper.path.indexOf(Routes.MANAGER_ANALYTICS.ROUTE) === 0;
	}

	static get inChannelEcosystemProduct() {
		return EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.ROUTE) === 0;
	}

	static get inAdminToolsProduct() {
		return EnvHelper.path.indexOf(Routes.ADMIN_TOOLS.ROUTE) === 0;
	}

	static get CurrentProductComponent() {
		if (EnvHelper.inSalesNetworkProduct) {
			return SalesNetworkProduct;
		}
		if (EnvHelper.inManagerAnalyticsProduct) {
			return ManagerAnalyticsProduct;
		}
		if (EnvHelper.inChannelEcosystemProduct) {
			return ChannelEcosystemProduct;
		}
		if (EnvHelper.inAdminToolsProduct) {
			return AdminToolsProduct;
		}
		return null;
	}

	static get profilePath() {
		if (EnvHelper.inSalesNetworkProduct) {
			return Routes.DASHBOARD.PROFILE.ROUTE;
		}
		if (EnvHelper.inManagerAnalyticsProduct) {
			return Routes.MANAGER_ANALYTICS.PROFILE.ROUTE;
		}
		if (EnvHelper.inChannelEcosystemProduct) {
			return Routes.CHANNEL_ECOSYSTEM.PROFILE.ROUTE;
		}
		if (EnvHelper.inAdminToolsProduct) {
			return Routes.ADMIN_TOOLS.PROFILE.ROUTE;
		}
		return Routes.PROFILE.ROUTE;
	}

	static push(path, search) {
		if (EnvHelper.history) {
			EnvHelper.navForwardCount = 0;
			EnvHelper.navBackCount++;
			if (search) {
				EnvHelper.history.push({pathname: path, search: search});
			}
			else {
				EnvHelper.history.push(path);
			}
		}
		else {
			console.warn('Could not push ' + path + ' because history has not been initialized');
		}
	}

	static goBack() {
		if (EnvHelper.navBackCount > 0) {
			EnvHelper.navForwardCount++;
			EnvHelper.navBackCount--;
			EnvHelper.history.goBack();
		}
	}

	static goForward() {
		if (EnvHelper.navForwardCount > 0) {
			EnvHelper.navBackCount++;
			EnvHelper.navForwardCount--;
			EnvHelper.history.goForward();
		}
	}

	static get canGoBack() {
		return EnvHelper.navBackCount > 0;
	}

	static get canGoForward() {
		return EnvHelper.navForwardCount > 0;
	}

	static set profileImageUri(url) {
		this.profileImageUriValue = url;
	}

	static get profileImageUri() {
		return this.profileImageUriValue;
	}

	static set logoImageUri(url) {
		this.logoImageUriValue = url;
	}

	static get logoImageUri() {
		return this.logoImageUriValue;
	}

	static set orgLogoImageUri(url) {
		this.orgLogoImageUriValue = url;
	}

	static get orgLogoImageUri() {
		return this.orgLogoImageUriValue;
	}

	static updateNoCache() {
		this.noCacheValue = Math.random();
	}

	static get noCache() {
		return this.noCacheValue;
	}

	static set token(token) {
		this.tokenUser = token;
	}

	static get token() {
		return this.tokenUser;
	}

	static subscribeToUniversalLinks() {
		if (this.isCordova) {
			if (window.cordova && window.cordova.universalLinks) {
				console.info('Universal links: subscribing...');
				window.cordova.universalLinks.subscribe(null, function(eventData) {
					console.info('Universal links: event received with hash:' + eventData.hash);
					EnvHelper.push(eventData.hash);
				});
			}
			else {
				console.info('Universal links: plugin not found.');
			}
		}
	}

	static getUrlParam(param) {
		return this.getParam(param, window.location.href);
	}

	static getParam(param, fromString) {
		if (fromString) {
			let startIndexOffset = 2;
			let paramStartIndex = fromString.indexOf('?' + param + '=');
			if (paramStartIndex === -1) paramStartIndex = fromString.indexOf('&' + param + '=');
			if (paramStartIndex === -1) {
				startIndexOffset = 1;
				paramStartIndex = fromString.indexOf(param + '=');
			}
			if (paramStartIndex !== -1) {
				let paramEndIndex = fromString.indexOf('&', paramStartIndex + param.length);
				if (paramEndIndex === -1) paramEndIndex = fromString.indexOf('#', paramStartIndex + param.length);
				if (paramEndIndex === -1) paramEndIndex = fromString.length;
				let value = fromString.substring(paramStartIndex + param.length + startIndexOffset, paramEndIndex);
				return decodeURIComponent(value);
			}
		}
		return '';
	}

	static get devicePlatform() {
		let supportedPlatforms = [ANDROID, IOS];
		let platform = '';
		let device = window.device;
		if (this.isCordova && !_.isEmpty(device)) {
			platform = device.platform.toUpperCase();
			if (supportedPlatforms.indexOf(platform) === -1) {
				platform = '';
			}
		}
		return platform;
	}

	static get deviceModel() {
		let device = window.device;
		if (this.isCordova && !_.isEmpty(device)) {
			return device.model.toLowerCase();
		}
		return '';
	}

	static get isDesktop() {
		return this.isDesktopValue;
	}

	static set isDesktop(value) {
		this.isDesktopValue = value;
	}

	static get isMobile() {
		return this.isIOS || this.isAndroid;
	}

	static get isIOS() {
		return this.devicePlatform === IOS;
	}

	static get isAndroid() {
		return this.devicePlatform === ANDROID;
	}

	static get isWebIOS() {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;
		return /iPad|iPhone|iPod/.test(userAgent);
	}

	static get isWebAndroid() {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;
		return /android/i.test(userAgent);
	}

	static reportError(error, message) {
		console.error('REPORT ERROR', error, message);
		if (typeof window.rg4js == 'function') {
			window.rg4js('send', {
				error: error,
				customData: [{version: this.version, env: this.env, message: message}]
			});
		}
	}

	static serverError(errorOrigin, error, httpCode) {
		console.error('SERVER ERROR', errorOrigin, error, httpCode);
		if (this.dispatch) {
			this.dispatch(AuthActions.serverError(errorOrigin, error.message || error.stack, httpCode));
		}
	}
}
