import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SecondaryButton from './SecondaryButton';

class ActionButton extends Component {

	render() {
		let {icon, onAction, toolTip, disabled, inProgress} = this.props;
		return (
			<SecondaryButton icon={inProgress ? <CircularProgress size={20} disableShrink={true}/> : icon}
							 toolTip={toolTip}
							 minWidth={40}
							 disabled={disabled}
							 onClick={onAction}/>
		);
	}
}

ActionButton.propTypes = {
	icon: PropTypes.object.isRequired,
	onAction: PropTypes.func.isRequired,
	toolTip: PropTypes.string,
	disabled: PropTypes.bool,
	inProgress: PropTypes.bool
};

export default ActionButton;
