import {Cancel} from '@mui/icons-material';
import {InputAdornment, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import EnvHelper from '../helpers/EnvHelper';
import StringHelper from '../helpers/StringHelper';
import {partnerTapSecondary, partnerTapStroke} from '../styles/partnertap_theme';

class TextInputBox extends Component {

	constructor(props, context) {
		super(props, context);
		this.value = this.props.value || '';
		this.onChange = this.onChange.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.value = this.props.value;
			this.forceUpdate();
		}
	}

	onChange(event) {
		let {maxChars, onChange} = this.props;
		this.value = event.target.value;
		if (maxChars) this.value = this.value.substring(0, maxChars);
		onChange(this.value);
	}

	render() {
		let {
			type,
			icon,
			hintText,
			placeholderText,
			errorText,
			onKeyDown,
			onEnter,
			onFocus,
			onBlur,
			onClear,
			doNotAutoFocus,
			inputMode,
			rows,
			minWidth,
			maxWidth,
			multiLine,
			disabled,
			isSimple,
			fontSize
		} = this.props;
		return <TextField variant={'outlined'}
						  color={'primary'}
						  size={isSimple ? 'small' : 'medium'}
						  style={{
							  backgroundColor: 'rgba(255, 255, 255, 0.5)',
							  borderRadius: 4,
							  minWidth: minWidth || 180,
							  maxWidth: maxWidth || null
						  }}
						  fullWidth={true}
						  rows={rows}
						  maxRows={rows ? null : 5}
						  multiline={Boolean(rows) || multiLine}
						  disabled={disabled}
						  label={isSimple ? undefined : hintText}
						  aria-placeholder={placeholderText || hintText}
						  placeholder={placeholderText || (isSimple && hintText)}
						  type={type ? type : 'text'}
						  value={this.value}
						  error={Boolean(errorText)}
						  helperText={errorText}
						  onChange={this.onChange}
						  onKeyDown={onKeyDown}
						  onKeyUp={(event) => {
							  if (event.key === 'Enter' && onEnter) {
								  onEnter(this.value);
							  }
						  }}
						  onFocus={onFocus}
						  onBlur={onBlur}
						  autoFocus={!doNotAutoFocus && EnvHelper.isDesktop && Boolean(this.value || placeholderText)}
						  inputProps={{
							  inputMode: inputMode || 'text',
							  'data-cy': 'text_field_' + (hintText ? StringHelper.formatKey(hintText) : '0')
						  }}
						  InputProps={{
							  style: {fontSize: isSimple && fontSize ? fontSize : null},
							  startAdornment: icon &&
											  <InputAdornment position={'start'} style={{color: partnerTapStroke}}>
												  {icon}
											  </InputAdornment>,
							  endAdornment: onClear &&
											(this.value ?
												<InputAdornment position={'end'}>
													<Cancel data-cy={'clear_input'}
															style={{cursor: 'pointer', color: partnerTapSecondary}}
															onClick={onClear}/>
												</InputAdornment>
												:
												<div style={{width: 32}}/>)
						  }}/>;
	}
}

TextInputBox.propTypes = {
	onChange: PropTypes.func.isRequired,
	onEnter: PropTypes.func,
	onClear: PropTypes.func,
	onKeyDown: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	doNotAutoFocus: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inputMode: PropTypes.string,
	hintText: PropTypes.string,
	placeholderText: PropTypes.string,
	errorText: PropTypes.string,
	rows: PropTypes.number,
	multiLine: PropTypes.bool,
	maxChars: PropTypes.number,
	minWidth: PropTypes.number,
	maxWidth: PropTypes.number,
	disabled: PropTypes.bool,
	icon: PropTypes.element,
	isSimple: PropTypes.bool,
	fontSize: PropTypes.number
};

export default TextInputBox;
