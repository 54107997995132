import React from 'react';
import {render} from 'react-dom';
import TagManager from 'react-gtm-module/dist/TagManager';
import './styles/default.css';
import '@babel/polyfill';
import {Provider} from 'react-redux';
import {HashRouter, Route} from 'react-router-dom';
import App from './app/App';
import {store} from './configureStore'; // this import must be last in order to avoid circular dependency
import {OKTA} from './globals/Enums';
import {Routes} from './globals/Routes';
import {LOGIN_SIGN_UP_TYPE_KEY} from './globals/StorageKeys';
import EnvHelper from './helpers/EnvHelper';
import PersistenceHelper from './helpers/PersistenceHelper';

// required assets for index.html
let favicon = require('../static/images/favicon.ico');
let logo = require('../static/images/partnertap-logo-tall.png');

if (EnvHelper.env !== 'dev') {
	__webpack_public_path__ = EnvHelper.webUrl + '/';
	console.log('__webpack_public_path__ = ', EnvHelper.webUrl);
}

/*
 oAuth2.0 standard uses a url fragment (hash) to pass the bearer token (access token)
 https://stackoverflow.com/questions/16733863/oauth2-0-implicit-grant-flow-why-use-url-hash-fragments/16747822
 We must strip it from the url if it is present and pass it as param
 */
const authHash = EnvHelper.getUrlParam('access_token');
const authCode = EnvHelper.getUrlParam('code');
const authError = EnvHelper.getUrlParam('error');
const consentType = EnvHelper.getUrlParam('state');
const signupType = authHash ? OKTA : PersistenceHelper.getValue(LOGIN_SIGN_UP_TYPE_KEY);
const isPermissionRequest = location.href.indexOf('/permission/') > -1;

if (authHash || authCode || authError) {
	let redirect;
	if (authError) {
		console.error('Error signing in', authError);
		redirect = '/#' + (isPermissionRequest ? Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH(signupType, 0, consentType, authError) :
			Routes.OAUTH.PATH(signupType, 0, authError));
	}
	else {
		console.info('Found code or access_token. permission request: ', isPermissionRequest);
		redirect = '/#' + (isPermissionRequest ? Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH(signupType, authCode || authHash, consentType) :
			Routes.OAUTH.PATH(signupType, authCode || authHash));
	}
	console.info('Navigate to', redirect);
	location.href = redirect;
}

TagManager.initialize({gtmId: 'GTM-PBBC85N'});
EnvHelper.init();

function doRender(component) {
	render(
		<Provider store={store}>
			<HashRouter>
				<Route component={component}/>
			</HashRouter>
		</Provider>, document.getElementById('app')
	);
}

doRender(App);

if (EnvHelper.env === 'dev' && module.hot) {
	// Whenever a new version of App.js is available
	module.hot.accept('./app/App', function() {
		// Require the new version and render it instead
		const NextApp = require('./app/App').default;
		doRender(NextApp);
	});
}
