import PropTypes from 'prop-types';
import React, {Component} from 'react';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapTernary} from '../../styles/partnertap_theme';
import PartnerTapIcon from '../PartnerTapIcon';
import CancelButton from './CancelButton';
import PrimaryButton from './PrimaryButton';

class ShareButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.shareClick = this.shareClick.bind(this);
	}

	shareClick() {
		this.props.onClick();
	}

	render() {
		return (
			this.props.isCancel ?
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}>
					<div style={{display: 'flex', alignItems: 'center', color: partnerTapTernary, paddingBottom: 10}}>
						<PartnerTapIcon glyph={'K'} style={{fontSize: 30, color: partnerTapTernary}}/>
						Sharing
					</div>
					<CancelButton onClick={this.shareClick} disabled={EnvHelper.isSpoofing}/>
				</div>
				:
				<div>
					<PrimaryButton label={this.props.isNavigation ? 'Share...' : 'Share'}
								   icon={<PartnerTapIcon glyph={'K'} style={PartnerTapIcon.buttonIconStyle}/>}
								   onClick={this.shareClick}
								   disabled={EnvHelper.isSpoofing}/>
				</div>
		);
	}
}

ShareButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	isCancel: PropTypes.bool,
	isNavigation: PropTypes.bool
};

export default ShareButton;
