import {Done, DoNotDisturb, Error, Help, Paid, Security, VolunteerActivism} from '@mui/icons-material';
import React from 'react';
import {LICENCE_FREE, LICENCE_INTERNAL, LICENCE_PAID, ROLE_TYPE_STANDARD} from '../globals/Enums';
import {partnerTapAlert, partnerTapDefaultText, partnerTapInactive, partnerTapSecondaryMedium, partnerTapTernary} from '../styles/partnertap_theme';
import CheckboxButton from '../ui/buttons/CheckboxButton';
import PopoverSearchList from '../ui/PopoverSearchList';
import ToolTipOverlay from '../ui/ToolTipOverlay';
import EcosystemShareHelper, {ALL_ACCOUNT_SHARE_OPTIONS, ALL_OPP_SHARE_OPTIONS, SHARE_SUMMARY} from './EcosystemShareHelper';
import {ASSET_TYPE_ACCOUNTS, ASSET_TYPE_OPPS} from './ReportHelper';

export default class AdminEditorHelper {

	static processProductFeatures(product, org, role, deltasForSave, onToggleEnableFeature, featureType) {
		let features = role ? role.features : org.features;
		return features.map((feature) => {

			if (product.productPublicId !== feature.product.productPublicId) {
				return;
			}
			if (feature && featureType && featureType !== feature.featureType) {
				return;
			}

			let isDisabledForOrg = !role && !feature.enable;
			let isDisabledForRole = role && !feature.enable;
			let isDisabledForRoleByOrg = false;
			if (role) {
				let orgFeature = org.features.find((orgFeature) => orgFeature.featureCode === feature.featureCode);
				isDisabledForRoleByOrg = !(orgFeature && orgFeature.enable);
			}
			let textColor = isDisabledForRoleByOrg ? partnerTapInactive : partnerTapDefaultText;
			let iconColor = isDisabledForRoleByOrg ? partnerTapInactive : partnerTapTernary;

			let licenseIcon;
			let licenseTip;
			if (feature.license === LICENCE_FREE) {
				licenseIcon = <VolunteerActivism style={{color: iconColor}}/>;
				licenseTip = 'FREE';
			}
			else if (feature.license === LICENCE_PAID) {
				licenseIcon = <Paid style={{color: iconColor}}/>;
				licenseTip = 'PAID';
			}
			else if (feature.license === LICENCE_INTERNAL) {
				licenseIcon = <Security style={{color: iconColor}}/>;
				licenseTip = 'INTERNAL';
			}
			else {
				licenseIcon = <Error style={{color: partnerTapAlert}}/>;
				licenseTip = 'Invalid feature license! Please report this bug.';
			}
			let tipTitle =
				<div style={{fontSize: 12, display: 'flex', flexDirection: 'column', gap: 5}}>
					<div>{feature.description}</div>
					<div>{licenseTip} FEATURE</div>
					<div>{isDisabledForOrg || isDisabledForRoleByOrg ? 'DISABLED' : 'ENABLED'} FOR ORG</div>
					{role && <div>{isDisabledForRole ? 'DISABLED' : 'ENABLED'} FOR ROLE</div>}
					<b>{feature.featureCode}</b>
				</div>;

			let isEditorDisabled = isDisabledForRoleByOrg || !(feature.license === LICENCE_FREE || (feature.license === LICENCE_PAID && product.license === LICENCE_PAID)) || feature.customerControlled;
			return {
				name:
					<ToolTipOverlay title={tipTitle}>
						<div style={{display: 'flex', alignItems: 'center', gap: 10, color: textColor}}>
							{licenseIcon}
							{feature.displayName}
						</div>
					</ToolTipOverlay>,
				value: feature.enable ?
					<Done style={{color: iconColor}}/> :
					<DoNotDisturb style={{color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapAlert}}/>,
				editor:
					<div style={feature.featureGroup && {border: 'solid 1px ' + partnerTapTernary, borderRadius: 20}}>
						<CheckboxButton checked={feature.enable}
										style={{padding: 0}}
										disabled={isEditorDisabled}
										onChange={() => {
											feature.enable = !feature.enable;
											if (!deltasForSave.features) {
												deltasForSave.features = [];
											}
											deltasForSave.features.push(feature);
											this.disableOtherFeaturesInGroup(features, feature, deltasForSave);
											onToggleEnableFeature();
										}}/>
					</div>
			};
		})
		.filter((feature) => feature);
	}

	static disableOtherFeaturesInGroup(features, featureUpdated, deltasForSave) {
		if (featureUpdated.enable && featureUpdated.featureGroup) {
			let featuresInGroup = features.filter((feature) => feature.featurePublicId !== featureUpdated.featurePublicId && feature.featureGroup === featureUpdated.featureGroup);
			featuresInGroup.forEach((feature) => {
				feature.enable = false;
				deltasForSave.features.push(feature);
			});
		}
	}

	static convertShareOptions(org, role) {
		let availableAccountShareOptions = [];
		ALL_ACCOUNT_SHARE_OPTIONS.forEach((shareOptionCode) => {
			let isEnabledForOrg = Boolean(org.accountShareOptions.find((enabledShareOption) => enabledShareOption === shareOptionCode));
			let isEnabledForRole = role && (role.roleType === ROLE_TYPE_STANDARD ||
											Boolean(role.accountShareOptions.find((enabledShareOption) => enabledShareOption === shareOptionCode)));
			let isEnabled = role ? isEnabledForRole : isEnabledForOrg;
			availableAccountShareOptions.push({shareOptionCode: shareOptionCode, enable: isEnabled, isDisabledForRoleByOrg: !isEnabledForOrg});
		});
		if (role) {
			role.accountShareOptions = availableAccountShareOptions;
		}
		else {
			org.accountShareOptions = availableAccountShareOptions;
		}

		let availableOppShareOptions = [];
		ALL_OPP_SHARE_OPTIONS.forEach((shareOptionCode) => {
			let isEnabledForOrg = Boolean(org.oppShareOptions.find((enabledShareOption) => enabledShareOption === shareOptionCode));
			let isEnabledForRole = role && (role.roleType === ROLE_TYPE_STANDARD ||
											Boolean(role.oppShareOptions.find((enabledShareOption) => enabledShareOption === shareOptionCode)));
			let isEnabled = role ? isEnabledForRole : isEnabledForOrg;
			availableOppShareOptions.push({shareOptionCode: shareOptionCode, enable: isEnabled, isDisabledForRoleByOrg: !isEnabledForOrg});
		});
		if (role) {
			role.oppShareOptions = availableOppShareOptions;
		}
		else {
			org.oppShareOptions = availableOppShareOptions;
		}
	}

	static processShareOptions(orgOrRole, deltasForSave, shareAssetType, onToggleShareOption) {
		let isForRole = Object.hasOwn(orgOrRole, 'roleType');
		let shareOptions = shareAssetType === ASSET_TYPE_ACCOUNTS ? orgOrRole.accountShareOptions : orgOrRole.oppShareOptions;
		let shareDefault = shareAssetType === ASSET_TYPE_ACCOUNTS ? orgOrRole.accountShareDefault : orgOrRole.oppShareDefault;
		let shareOptionList = shareOptions.map((shareOption) => {
			let isDefaultShareOption = shareDefault === shareOption.shareOptionCode;
			let isDisabledForOrg = !isForRole && !shareOption.enable;
			let isDisabledForRole = isForRole && !shareOption.enable;
			let isDisabledForRoleByOrg = isForRole && shareOption.isDisabledForRoleByOrg;
			let isEditorDisabled = shareOption.shareOptionCode === SHARE_SUMMARY || isDefaultShareOption || isDisabledForRoleByOrg;
			return {
				name:
					<ToolTipOverlay title={
						<div style={{fontSize: 12, display: 'flex', flexDirection: 'column', gap: 5}}>
							<div>{EcosystemShareHelper.descriptionForShareOption(shareOption.shareOptionCode, shareAssetType, 'your partner')}</div>
							<div>{isDisabledForOrg || isDisabledForRoleByOrg ? 'DISABLED' : 'ENABLED'} FOR ORG</div>
							{isForRole && <div>{isDisabledForRole ? 'DISABLED' : 'ENABLED'} FOR ROLE</div>}
						</div>}>
						<div style={{display: 'flex', alignItems: 'center', color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapDefaultText}}>
							<Help style={{color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapSecondaryMedium, paddingRight: 10}}/>
							{EcosystemShareHelper.labelForShareOption(shareOption.shareOptionCode)}
							{isDefaultShareOption && ' (default)'}
						</div>
					</ToolTipOverlay>,
				value: shareOption.enable ?
					<Done style={{color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapTernary}}/> :
					<DoNotDisturb style={{color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapAlert}}/>,
				editor: <CheckboxButton checked={shareOption.enable}
										style={{padding: 0}}
										disabled={isEditorDisabled}
										onChange={() => {
											shareOption.enable = !shareOption.enable;
											this.updateShareOptionDeltasForSave(orgOrRole, deltasForSave, shareOption, shareAssetType);
											onToggleShareOption();
										}}/>
			};
		});

		shareOptionList.unshift(
			{
				name: 'Default',
				value: EcosystemShareHelper.labelForShareOption(shareDefault),
				editor: <PopoverSearchList label={'Default'}
										   list={shareOptions}
										   preselectedItem={shareOptions.find((shareOption) => shareOption.shareOptionCode === shareDefault)}
										   labelRenderer={(shareOption) => EcosystemShareHelper.labelForShareOption(shareOption.shareOptionCode)}
										   valueRenderer={(shareOption) => shareOption.shareOptionCode}
										   onItemSelected={(shareOption) => {
											   if (shareAssetType === ASSET_TYPE_ACCOUNTS) {
												   orgOrRole.accountShareDefault = shareOption.shareOptionCode;
												   deltasForSave.accountShareDefault = shareOption.shareOptionCode;
											   }
											   else if (shareAssetType === ASSET_TYPE_OPPS) {
												   orgOrRole.oppShareDefault = shareOption.shareOptionCode;
												   deltasForSave.oppShareDefault = shareOption.shareOptionCode;
											   }
											   shareOption.enable = true;
											   this.updateShareOptionDeltasForSave(orgOrRole, deltasForSave, shareOption, shareAssetType);
											   onToggleShareOption();
										   }}
										   searchByObjectKeys={['label']}/>
			});
		return shareOptionList;
	}

	static updateShareOptionDeltasForSave(orgOrRole, deltasForSave, shareOption, shareAssetType) {
		let currentShareOptions;
		if (shareAssetType === ASSET_TYPE_ACCOUNTS) {
			currentShareOptions = orgOrRole.accountShareOptions;
		}
		else if (shareAssetType === ASSET_TYPE_OPPS) {
			currentShareOptions = orgOrRole.oppShareOptions;
		}
		if (!currentShareOptions) {
			currentShareOptions = {};
		}

		let shareOptionCodes = currentShareOptions.map((shareOption) => shareOption.enable ? shareOption.shareOptionCode : null)
		.filter((shareOption) => shareOption);
		if (shareAssetType === ASSET_TYPE_ACCOUNTS) {
			deltasForSave.accountShareOptions = shareOptionCodes;
		}
		else if (shareAssetType === ASSET_TYPE_OPPS) {
			deltasForSave.oppShareOptions = shareOptionCodes;
		}
	}
}
