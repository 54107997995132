import {Engineering} from '@mui/icons-material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import DetailHeader from '../../app/channel_ecosystem/shared/DetailHeader';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {ROLE_TYPE_STANDARD} from '../../globals/Enums';
import AdminEditorHelper from '../../helpers/AdminEditorHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY} from '../../helpers/FeatureHelper';
import {ASSET_TYPE_ACCOUNTS, ASSET_TYPE_OPPS} from '../../helpers/ReportHelper';
import StringHelper from '../../helpers/StringHelper';
import LinkButton from '../../ui/buttons/LinkButton';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import Loading from '../../ui/Loading';
import ScrimMessage from '../../ui/messages/ScrimMessage';
import PopoverSearchList from '../../ui/PopoverSearchList';
import TextInputBox from '../../ui/TextInputBox';
import AdminEditorSection from './shared/AdminEditorSection';

const GENERAL_INFO = 'General Info';

const ORG_VISIBILITY_ALL = 'ORG_VISIBILITY_ALL';
const ORG_VISIBILITY_INCLUDE = 'ORG_VISIBILITY_INCLUDE';
const ORG_VISIBILITY_EXCLUDE = 'ORG_VISIBILITY_EXCLUDE';
const orgVisibilityLabels = {
	ORG_VISIBILITY_ALL: 'See All Partner Orgs',
	ORG_VISIBILITY_INCLUDE: 'See Listed Partner Orgs',
	ORG_VISIBILITY_EXCLUDE: 'See All Except Listed Partner Orgs'
};

class AdminRolePage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			org: null,
			role: null,
			currentSection: GENERAL_INFO,
			accountShareOptions: null,
			oppShareOptions: null
		};

		this.editRole = this.editRole.bind(this);
		this.saveRoleChanges = this.saveRoleChanges.bind(this);
		this.cancelRoleChanges = this.cancelRoleChanges.bind(this);
		this.renderProductFeatures = this.renderProductFeatures.bind(this);
	}

	componentDidMount() {
		this.getRole();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getRole() {
		let {orgId, roleId} = this.props.match.params;
		Promise.all([AdminEndpoints.fetchOrg(orgId), AdminEndpoints.fetchRole(roleId), AdminEndpoints.fetchRolePartnerOrgs(orgId)])
		.then((results) => {
			if (this.unmounted) return;
			let org = results[0].payload;
			let role = results[1].payload;
			let allOrgs = results[2].payload;
			AdminEditorHelper.convertShareOptions(org, role);
			this.setState({org: org, role: role, allOrgs: allOrgs, loading: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getRole', error);
		});
	}

	editRole() {
		let {role, roleDeltasForSave} = this.state;
		if (!roleDeltasForSave) {
			this.setState({roleDeltasForSave: {}, roleCloneForCancel: _.cloneDeep(role)});
		}
	}

	saveRoleChanges(successCallback) {
		this.setState({roleDeltasForSave: null, roleCloneForCancel: null});
		this.updateRole(successCallback);
	}

	updateRole(successCallback) {
		let {role, roleDeltasForSave, roleCloneForCancel} = this.state;
		if (!roleDeltasForSave || !Object.keys(roleDeltasForSave).length) return;

		if (Object.hasOwn(roleDeltasForSave, 'displayName')) {
			roleDeltasForSave.displayName = StringHelper.trimWhiteSpace(roleDeltasForSave.displayName);
			if (roleDeltasForSave.displayName.length < 3) {
				role.displayName = roleCloneForCancel.displayName;
				roleDeltasForSave.displayName = roleCloneForCancel.displayName;
			}
		}

		this.setState({saving: true});
		AdminEndpoints.updateRole(role.rolePublicId, roleDeltasForSave)
		.then(() => {
			if (this.unmounted) return;
			this.setState({saving: false, roleDeltasForSave: null, roleCloneForCancel: null});
			successCallback();
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from updateRole', error);
		});
	}

	cancelRoleChanges() {
		let {role, roleCloneForCancel} = this.state;
		Object.assign(role, roleCloneForCancel);
		this.setState({roleDeltasForSave: null, roleCloneForCancel: null});
	}

	renderSection(sectionName, sectionValues, saveWarning, isDisabled) {
		let {role, currentSection} = this.state;
		let isStandardRole = role.roleType === ROLE_TYPE_STANDARD;
		return (
			<AdminEditorSection key={sectionName}
								sectionName={sectionName}
								sectionValues={sectionValues}
								isOpen={currentSection === sectionName}
								isDisabled={isDisabled}
								viewFunction={() => this.setState({currentSection: sectionName})}
								closeFunction={() => this.setState({currentSection: null})}
								editFunction={isStandardRole ? null : this.editRole}
								saveFunction={isStandardRole ? null : this.saveRoleChanges}
								saveWarning={isStandardRole ? null : saveWarning}
								cancelFunction={isStandardRole ? null : this.cancelRoleChanges}/>
		);
	}

	renderProductFeatures() {
		let {org, role, roleDeltasForSave} = this.state;
		return org.products.map((product) => {
			let productFeatures = AdminEditorHelper.processProductFeatures(product, org, role, roleDeltasForSave, () => this.forceUpdate());
			if (productFeatures.length) {
				return this.renderSection('Features: ' + product.displayName, productFeatures);
			}
		});
	}

	renderOrgVisibilityEditor() {
		let {role, roleDeltasForSave, allOrgs} = this.state;
		if (!role.partnerOrgVisibility) {
			role.partnerOrgVisibility = ORG_VISIBILITY_ALL;
			role.companyPartnerPublicIds = [];
		}
		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400}}>
				<PopoverSearchList label={'org_visibility'}
								   list={[ORG_VISIBILITY_ALL, ORG_VISIBILITY_INCLUDE, ORG_VISIBILITY_EXCLUDE]}
								   preselectedItem={role.partnerOrgVisibility}
								   labelRenderer={(visibility) => orgVisibilityLabels[visibility]}
								   onItemSelected={(visibility) => {
									   role.partnerOrgVisibility = visibility;
									   roleDeltasForSave.partnerOrgVisibility = visibility;
									   roleDeltasForSave.companyPartnerPublicIds = role.companyPartnerPublicIds;
									   this.forceUpdate();
								   }}/>
				{(role.partnerOrgVisibility === ORG_VISIBILITY_INCLUDE || role.partnerOrgVisibility === ORG_VISIBILITY_EXCLUDE) &&
				 <Fragment>
					 <PopoverSearchList label={'Selected Partner Org'}
										list={allOrgs}
										isMultipleChoice={true}
										isCheckedFunction={(item) => {
											return role.companyPartnerPublicIds.includes(item.companyPartnerPublicId);
										}}
										doNotRestoreValues={true}
										labelRenderer={(org) => org.partnerName}
										setCheckedFunction={(org, isChecked) => {
											org.isChecked = isChecked;
											if (isChecked) {
												if (!role.companyPartnerPublicIds.includes(org.companyPartnerPublicId)) {
													role.companyPartnerPublicIds.push(org.companyPartnerPublicId);
												}
											}
											else {
												role.companyPartnerPublicIds.splice(role.companyPartnerPublicIds.indexOf(org.companyPartnerPublicId), 1);
											}
											this.forceUpdate();
										}}
										onItemSelected={() => {
											roleDeltasForSave.partnerOrgVisibility = role.partnerOrgVisibility;
											roleDeltasForSave.companyPartnerPublicIds = role.companyPartnerPublicIds;
										}}
										searchByObjectKeys={['partnerName']}/>
					 {this.renderOrgVisibilityPartners(true)}
				 </Fragment>}
			</div>
		);
	}

	renderOrgVisibilityPartners(isEditable) {
		let {role, roleDeltasForSave, allOrgs} = this.state;
		return (
			<Fragment>
				<div style={{fontSize: 16}}>
					{role.partnerOrgVisibility === ORG_VISIBILITY_INCLUDE ? 'Visible Partner Orgs:' : 'Excluded Partner Orgs:'}
				</div>
				{role.companyPartnerPublicIds.length === 0 &&
				 <div>None</div>}
				{role.companyPartnerPublicIds.map((orgId) => {
					let org = allOrgs.find((org) => org.companyPartnerPublicId === orgId);
					if (org) {
						return (
							<div key={org.companyPartnerPublicId} style={{display: 'flex', justifyContent: 'space-between'}}>
								<div>
									{org.partnerName}
								</div>
								{isEditable &&
								 <LinkButton label={'REMOVE'}
											 onClick={() => {
												 org.isChecked = false;
												 role.companyPartnerPublicIds.splice(role.companyPartnerPublicIds.indexOf(org.companyPartnerPublicId), 1);
												 roleDeltasForSave.companyPartnerPublicIds = role.companyPartnerPublicIds;
												 roleDeltasForSave.partnerOrgVisibility = role.partnerOrgVisibility;
												 this.forceUpdate();
											 }}/>}
							</div>
						);
					}
					return (
						<div key={'invalid'}>Invalid Org Id: {orgId}</div>
					);
				})}
			</Fragment>
		);
	}

	render() {
		if (this.state.loading) return <Loading>Loading Org...</Loading>;
		let {org, role, roleDeltasForSave, saving} = this.state;
		let isStandardRole = role.roleType === ROLE_TYPE_STANDARD;
		return (
			<ScrollingContainer divId={'admin_role_page'}>
				{saving &&
				 <ScrimMessage message={'Saving Role...'}/>}
				<DetailHeader MaterialIcon={Engineering}
							  title={role.displayName}
							  detailRight={isStandardRole ? 'Standard Role' : org.name + ' Custom Role'}/>
				<div id={'admin_role_page_scrolling_div'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
					{this.renderSection(GENERAL_INFO,
						[
							{
								name: 'Role Name',
								value: role.displayName,
								editor: <TextInputBox hintText={'Role Name'}
													  value={role.displayName}
													  onChange={(value) => {
														  role.displayName = value;
														  roleDeltasForSave.displayName = value;
														  this.forceUpdate();
													  }}
													  maxChars={255}
													  minWidth={300}
													  isSimple={true}
													  doNotAutoFocus={true}/>
							},
							{
								name: 'Role Description',
								value: role.description,
								editor: <TextInputBox hintText={'Role Description'}
													  value={role.description}
													  onChange={(value) => {
														  role.description = value;
														  roleDeltasForSave.description = value;
														  this.forceUpdate();
													  }}
													  maxChars={255}
													  minWidth={300}
													  isSimple={true}
													  doNotAutoFocus={true}
													  multiLine={true}
													  rows={3}/>
							},
							{
								name: 'Primary Product',
								value: role.primaryProduct.displayName,
								editor: <PopoverSearchList label={'Primary Product'}
														   list={org.products}
														   preselectedItem={role.primaryProduct}
														   labelRenderer={(product) => product.displayName}
														   onItemSelected={(product) => {
															   role.primaryProduct = product;
															   roleDeltasForSave.primaryProduct = product;
														   }}/>
							}
						])}
					{this.renderProductFeatures()}
					{this.renderSection('Sharing: Accounts',
						AdminEditorHelper.processShareOptions(role, roleDeltasForSave, ASSET_TYPE_ACCOUNTS, () => this.forceUpdate()),
						'Changing Account Share Settings has consequences... BE CAREFUL!!!!')}
					{this.renderSection('Sharing: Opps',
						AdminEditorHelper.processShareOptions(role, roleDeltasForSave, ASSET_TYPE_OPPS, () => this.forceUpdate()),
						'Changing Opp Share Settings has consequences... BE CAREFUL!!!!')}
					{this.renderSection('Partner Org Visibility',
						[
							{
								name: 'Visibility',
								value:
									<div>
										{role.partnerOrgVisibility === ORG_VISIBILITY_ALL &&
										 orgVisibilityLabels[role.partnerOrgVisibility || ORG_VISIBILITY_ALL]}
										{(role.partnerOrgVisibility === ORG_VISIBILITY_INCLUDE || role.partnerOrgVisibility === ORG_VISIBILITY_EXCLUDE) &&
										 this.renderOrgVisibilityPartners(false)}
									</div>,
								editor: this.renderOrgVisibilityEditor()
							}
						],
						'Changing Partner Org Visibility Settings has consequences... BE CAREFUL!!!!',
						!FeatureHelper.isFeatureEnabledForRole(FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY, role))}
					<div style={{padding: 5}}/>
				</div>
			</ScrollingContainer>
		);
	}
}

AdminRolePage.propTypes = {
	match: PropTypes.object.isRequired
};

export default connect()(AdminRolePage);
