import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {MIN_APP_HEIGHT} from '../../app/App';
import DialogHelper from '../../helpers/DialogHelper';

class ScrollingContainer extends Component {

	constructor(props, context) {
		super(props, context);
		this.handleResize = this.handleResize.bind(this);
		this.setHeightConstraint = this.setHeightConstraint.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		this.setHeightConstraint();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.unmounted = true;
	}

	get divId() {
		return 'scrolling_container_div_' + this.props.divId;
	}

	handleResize() {
		if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout(() => {
			if (this.unmounted) return;
			this.setHeightConstraint();
		}, 200);
	}

	setHeightConstraint() {
		if (this.unmounted) return;
		let scrollingContainerDiv = document.getElementById(this.divId);
		if (scrollingContainerDiv) {
			let containerHeightOffset = 0;
			if (!this.props.noHeightOffset && DialogHelper.isDialogOpen && String(scrollingContainerDiv.offsetParent) === '[object HTMLDivElement]') {
				containerHeightOffset = DialogHelper.usingFullScreen ? 53 : 134;
			}
			if (this.props.forceHeightOffset) containerHeightOffset = 53;
			let virtualHeight = Math.max(window.innerHeight, MIN_APP_HEIGHT);
			let containerHeight = virtualHeight - containerHeightOffset - scrollingContainerDiv.offsetTop;
			scrollingContainerDiv.style.height = containerHeight + 'px';
		}
	}

	render() {
		return (
			<div id={this.divId}
				 ref={(element) => {
					 if (element) {
						 if (this.unmounted) return;
						 if (this.observer) {
							 this.observer.disconnect();
						 }
						 this.observer = new ResizeObserver(() => {
							 // requestAnimationFrame is required to avoid ResizeObserver loop limit exceeded
							 window.requestAnimationFrame(this.setHeightConstraint);
						 });
						 this.observer.observe(element);
					 }
				 }}
				 style={{display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden'}}>
				{this.props.children}
			</div>
		);
	}
}

ScrollingContainer.propTypes = {
	divId: PropTypes.string.isRequired,
	noHeightOffset: PropTypes.bool,
	forceHeightOffset: PropTypes.bool
};

export default ScrollingContainer;
