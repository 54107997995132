import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapAlert, partnerTapPrimary} from '../styles/partnertap_theme';
import PrimaryButton from './buttons/PrimaryButton';

class Loading extends Component {

	render() {
		let padding = this.props.small ? 10 : 20;
		return (
			<div style={{
				display: 'flex',
				flexDirection: this.props.horizontal ? 'row' : 'column',
				alignItems: 'center',
				justifyContent: 'space-between',
				height: this.props.small ? 26 : 80,
				padding: padding,
				paddingLeft: this.props.horizontal ? 0 : padding,
				fontSize: this.props.small ? 10 : 14
			}}>
				<CircularProgress size={this.props.small ? 20 : 40}
								  style={{color: partnerTapPrimary, background: 'transparent', padding: 10}}
								  disableShrink={true}/>
				{this.props.error &&
				 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					 <div style={{padding: 10}}>Sorry, we had an error...</div>
					 <div style={{padding: 10, color: partnerTapAlert}}>{this.props.error}</div>
					 <div style={{padding: 10}}><PrimaryButton onClick={this.props.retry} label={'RETRY'}/></div>
				 </div>}
				{this.props.children}
			</div>
		);
	}
}

Loading.propTypes = {
	small: PropTypes.bool,
	error: PropTypes.object,
	retry: PropTypes.func,
	horizontal: PropTypes.bool
};

export default Loading;
