import {Close} from '@mui/icons-material';
import {LinearProgress} from '@mui/material';
import objectAssign from 'object-assign';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ProfileEndpoints from '../../endpoints/ProfilesEndpoints.js';
import {CHANNEL_PARTNER_COUNT_KEY, NEW_CHANNEL_PARTNER_COUNT_KEY, NEW_PARTNER_COUNT_KEY, PARTNER_COUNT_KEY} from '../../globals/StorageKeys';
import EnvHelper from '../../helpers/EnvHelper';
import NumberHelper from '../../helpers/NumberHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import {PERSON_QUEUE_STATUS_PROCESSED, PERSON_QUEUE_STATUS_PROCESSING, PERSON_QUEUE_STATUS_READY} from '../../helpers/UploadHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapTernary, partnerTapWhite} from '../../styles/partnertap_theme';

export class FirstLoginStatus extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			closed: false,
			accountCount: 0,
			partnerCount: 0,
			isDone: false,
			queueCount: 0
		};
		this.timeout = -1;
	}

	componentDidMount() {
		if (!this.props.authState.person) return;
		this.updateStats();
		if (!this.interval) {
			if (this.props.authState.person.firstLogin) {
				this.interval = setInterval(this.longPull.bind(this), 1000);
			}
		}
	}

	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
		clearInterval(this.interval);
		this.interval = null;
		this.unmounted = true;
	}

	longPull() {
		this.updateStats();
	}

	updateStats() {
		this.setState({loading: true});
		ProfileEndpoints.fetchProfileData()
		.then((result) => {
				if (this.unmounted) return;
				this.setState({
					loading: false,
					accountCount: result.payload.totalSyncedAccounts || 0,
					partnerCount: result.payload.totalPartners,
					totalChannelPartners: result.payload.totalChannelPartners,
					queueCount: result.payload.profileQueueSize,
					personQueueStatus: result.payload.personQueueStatus
				});
				if (this.interval && !(result.payload.personQueueStatus === PERSON_QUEUE_STATUS_PROCESSING || result.payload.personQueueStatus === PERSON_QUEUE_STATUS_READY)) {
					clearInterval(this.interval);
					this.interval = null;
				}
				this.storePartnerCount(PARTNER_COUNT_KEY, NEW_PARTNER_COUNT_KEY, result.payload.totalPartners);
				this.storePartnerCount(CHANNEL_PARTNER_COUNT_KEY, NEW_CHANNEL_PARTNER_COUNT_KEY, result.payload.totalChannelPartners);
			}
		)
		.catch((error) => {
			EnvHelper.serverError('Error from fetchProfileData', error);
		});
	}

	onClose(person) {
		clearInterval(this.interval);
		this.interval = null;
		this.setState({closed: true});
		this.props.dispatch(AuthActions.clearFirstLoginMessage(objectAssign({}, person)));
	}

	storePartnerCount(key, newKey, count) {
		let lastPartnerCount = parseInt(PersistenceHelper.getValue(key)) || 0;
		let lastNewPartnerCount = parseInt(PersistenceHelper.getValue(newKey)) || 0;
		let newPartnerCount = lastNewPartnerCount + (count - lastPartnerCount);
		if (newPartnerCount < 0) newPartnerCount = 0;
		PersistenceHelper.setValue(key, count);
		PersistenceHelper.setValue(newKey, newPartnerCount);
	}

	render() {
		let {person} = this.props.authState;
		if (!person || !person.firstLogin) return <div/>;

		let numberOfAccountsLoaded = NumberHelper.formatNumber(this.state.accountCount);
		let isInQueue = this.state.queueCount > 0;
		let isDone = !isInQueue || this.state.personQueueStatus === PERSON_QUEUE_STATUS_PROCESSED;
		let numberOfPeople = NumberHelper.ordinalSuffix(this.state.queueCount);
		let message;
		if (isInQueue) {
			message =
				<div>
					You're <em>{numberOfPeople} in line</em> for account loading.<br/>
					We'll send you an email when your accounts are ready.
				</div>;
		}
		else if (isDone) {
			message =
				<div>
					<em>All done!</em><br/>
					We loaded {numberOfAccountsLoaded} accounts for you.
				</div>;
		}
		else {
			message =
				<div>
					We're loading your accounts:<em> {numberOfAccountsLoaded} so far... </em> <br/>
					We'll send you an email when we're done.
				</div>;
		}

		return (
			<div style={{zIndex: 100, display: 'inline', backgroundColor: partnerTapTernary}}>
				<div style={{float: 'right'}} onClick={() => this.onClose(person)}>
					<Close style={{margin: 6, color: partnerTapWhite}}/>
				</div>
				<div style={{color: partnerTapWhite, padding: 10, textAlign: 'center'}}>
					{message}
				</div>
				<LinearProgress mode={'determinate'} value={this.state.accountCount} color={'primary'}/>
			</div>
		);
	}
}

FirstLoginStatus.propTypes = {
	authState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(FirstLoginStatus);
