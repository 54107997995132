import {Business, Done, DoNotDisturb} from '@mui/icons-material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import DetailHeader from '../../app/channel_ecosystem/shared/DetailHeader';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {
	FEATURE_TYPE_ORG,
	FEATURE_TYPE_ROLE,
	HUBSPOT,
	LICENCE_FREE,
	LICENCE_INTERNAL,
	LICENCE_PAID,
	MSFT,
	OKTA,
	SFDC,
	STATUS_ACTIVE,
	STATUS_INACTIVE,
	STATUS_TERMINATED,
	SUPERADMIN
} from '../../globals/Enums';
import AdminEditorHelper from '../../helpers/AdminEditorHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {ASSET_TYPE_ACCOUNTS, ASSET_TYPE_OPPS} from '../../helpers/ReportHelper';
import StringHelper from '../../helpers/StringHelper';
import {partnerTapAlert, partnerTapInactive, partnerTapPrimary, partnerTapTernary, partnerTapWhite} from '../../styles/partnertap_theme';
import CheckboxButton from '../../ui/buttons/CheckboxButton';
import ImageSelector from '../../ui/ImageSelector';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import Loading from '../../ui/Loading';
import ScrimMessage from '../../ui/messages/ScrimMessage';
import PartnerTapIcon from '../../ui/PartnerTapIcon';
import Pic from '../../ui/Pic';
import PopoverSearchList from '../../ui/PopoverSearchList';
import TextInputBox from '../../ui/TextInputBox';
import AdminEditorSection from './shared/AdminEditorSection';

const GENERAL_INFO = 'General Info';

class AdminOrgPage extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			org: null,
			currentSection: GENERAL_INFO,
			orgProfiles: null,
			orgOwnerId: null,
			allRoles: null,
			photoUpdate: 0,
			orgAccountTypeMappings: [],
			orgUpdateErrorMessage: null,
			deltaAccountTypeMapping: false
		};

		this.editOrg = this.editOrg.bind(this);
		this.updateOrg = this.updateOrg.bind(this);
		this.cancelOrgChanges = this.cancelOrgChanges.bind(this);
	}

	componentDidMount() {
		this.getOrg();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getOrg() {
		let {orgId} = this.props.match.params;
		Promise.all([
			AdminEndpoints.fetchOrg(orgId),
			AdminEndpoints.fetchOrgAdminProfiles(orgId),
			AdminEndpoints.fetchRoles(),
			AdminEndpoints.fetchRoles(orgId),
			AdminEndpoints.fetchAllShareOptions(ASSET_TYPE_ACCOUNTS),
			AdminEndpoints.fetchAllShareOptions(ASSET_TYPE_OPPS)
		])
		.then((results) => {
			if (this.unmounted) return;
			let org = results[0].payload;
			let orgProfiles = results[1].payload;
			let orgOwner = null;
			if (orgProfiles && org.orgOwnerId) {
				orgOwner = orgProfiles.find((profile) => profile.personId === org.orgOwnerId);
			}
			let standardRoles = results[2].payload.filter((role) => role !== SUPERADMIN);
			let customRoles = results[3].payload;

			AdminEditorHelper.convertShareOptions(org);

			this.setState({
				org: org,
				orgProfiles: orgProfiles,
				orgOwner: orgOwner,
				allRoles: [...standardRoles, ...customRoles],
				loading: false,
				orgAccountTypeMappings: org.orgAccountTypeMappings
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getOrg', error);
		});
	}

	editOrg() {
		let {org, orgOwner, orgDeltasForSave} = this.state;
		if (!orgDeltasForSave) {
			this.setState({orgDeltasForSave: {}, orgCloneForCancel: _.cloneDeep(org), orgOwnerForCancel: orgOwner});
		}
	}

	updateOrg(successCallback) {
		let {org, orgDeltasForSave} = this.state;

		if (orgDeltasForSave.domains) {
			if (!orgDeltasForSave.domains?.length) {
				this.setState({orgUpdateErrorMessage: 'Domain List is required'});
				return;
			}
			else if (!StringHelper.validateDomainList(orgDeltasForSave.domains, true)) {
				this.setState({orgUpdateErrorMessage: 'Domain List is invalid'});
				return;
			}
		}
		if (this.state.deltaAccountTypeMapping) {
			this.updateAccountTypeMapping();
		}

		this.setState({saving: true});
		AdminEndpoints.updateOrg(org.orgId, orgDeltasForSave)
		.then(() => {
			if (this.unmounted) return;
			this.updateAccountTypeMapping(org.orgId);
			this.setState({saving: false, orgDeltasForSave: null, orgCloneForCancel: null, orgOwnerForCancel: null});
			if (this.productLicenseChanged) {
				this.productLicenseChanged = false;
				this.getOrg();
			}
			successCallback();
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from updateOrg', error);
		});
	}

	cancelOrgChanges() {
		let {org, orgCloneForCancel, orgOwnerForCancel} = this.state;
		Object.assign(org, orgCloneForCancel);
		this.setState({orgOwner: orgOwnerForCancel, orgDeltasForSave: null, orgCloneForCancel: null, orgOwnerForCancel: null});
	}

	updateAccountTypeMapping() {
		let {orgDeltasForSave, orgAccountTypeMappings} = this.state;
		orgDeltasForSave.orgAccountTypeMappings = orgAccountTypeMappings;
		this.setState({deltaAccountTypeMapping: false});
	}

	viewSelectedSection(sectionName) {
		this.setState({currentSection: sectionName});
	}

	renderSection(sectionName, sectionValues, saveWarning) {
		return (
			<AdminEditorSection key={sectionName}
								sectionName={sectionName}
								sectionValues={sectionValues}
								isOpen={this.state.currentSection === sectionName}
								viewFunction={() => this.viewSelectedSection(sectionName)}
								closeFunction={() => this.setState({currentSection: null})}
								editFunction={this.editOrg}
								saveFunction={this.updateOrg}
								saveWarning={saveWarning}
								cancelFunction={this.cancelOrgChanges}/>
		);
	}

	renderOrgOwnerTable(includeName) {
		let {orgOwner} = this.state;
		if (!orgOwner) return 'None';
		return (
			<table style={{width: '100%', whiteSpace: 'nowrap', backgroundColor: partnerTapWhite, borderRadius: 10}} cellSpacing={10}>
				<tbody>
				{includeName &&
				 <tr style={{height: 23}}>
					 <td>Name</td>
					 <td>{orgOwner.firstName + ' ' + orgOwner.lastName}</td>
				 </tr>}
				<tr style={{height: 23}}>
					<td>Email</td>
					<td>{orgOwner.emailAddress}</td>
				</tr>
				<tr style={{height: 23}}>
					<td>Title</td>
					<td>{orgOwner.ptJobTitle}</td>
				</tr>
				<tr style={{height: 23}}>
					<td>Role</td>
					<td>{orgOwner.roleDisplayName}</td>
				</tr>
				</tbody>
			</table>
		);
	}

	processProducts(org) {
		let {orgDeltasForSave} = this.state;
		return org.products.map((product) => {
			return {
				name:
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div style={{backgroundColor: partnerTapPrimary, padding: 4, paddingBottom: 0, marginRight: 10, borderRadius: 4}}>
							{PartnerTapIcon.logoSmall}
						</div>
						{product.displayName}
					</div>,
				value:
					(product.enable ? 'ENABLED: ' : 'DISABLED: ') + product.license,
				editor:
					<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
						<CheckboxButton checked={product.enable} style={{padding: 0}} onChange={() => this.onToggleEnableProduct(product)}/>
						<PopoverSearchList label={'License'}
										   list={[LICENCE_FREE, LICENCE_PAID, LICENCE_INTERNAL]}
										   preselectedItem={product.license}
										   onItemSelected={(license) => {
											   product.license = license;
											   if (!orgDeltasForSave.products) {
												   orgDeltasForSave.products = [];
											   }
											   orgDeltasForSave.products.push(product);
											   this.productLicenseChanged = true;
										   }}/>
					</div>
			};
		});
	}

	onToggleEnableProduct(product) {
		product.enable = !product.enable;
		let {orgDeltasForSave} = this.state;
		if (!orgDeltasForSave.products) {
			orgDeltasForSave.products = [];
		}
		orgDeltasForSave.products.push(product);
		this.forceUpdate();
	}

	renderProductFeatures() {
		let {org, orgDeltasForSave} = this.state;
		return org.products.map((product) => {
			let productOrgFeatures = AdminEditorHelper.processProductFeatures(product, org, null, orgDeltasForSave, () => this.forceUpdate(), FEATURE_TYPE_ORG);
			let productRoleFeatures = AdminEditorHelper.processProductFeatures(product, org, null, orgDeltasForSave, () => this.forceUpdate(), FEATURE_TYPE_ROLE);
			return (
				<Fragment key={product.productCode}>
					{productOrgFeatures.length > 0 &&
					 this.renderSection(product.displayName + ' Features: Org' + (!product.enable ? ' DISABLED!' : ''), productOrgFeatures)}
					{productRoleFeatures.length > 0 &&
					 this.renderSection(product.displayName + ' Features: All Roles' + (!product.enable ? ' DISABLED!' : ''), productRoleFeatures)}
				</Fragment>
			);
		});
	}

	processAccountTypeMapping(orgOrRole, deltasForSave, shareAssetType) {
		let isForRole = Object.hasOwn(orgOrRole, 'roleType');
		return this.state.orgAccountTypeMappings.map((accountType) => {
			let isDisabledForRoleByOrg = isForRole && accountType.isDisabledForRoleByOrg;
			return {
				name:
					<div>
						{accountType.accountTypeName}
					</div>,
				value: accountType.isCustomer ?
					<Done style={{color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapTernary}}/> :
					<DoNotDisturb style={{color: isDisabledForRoleByOrg ? partnerTapInactive : partnerTapAlert}}/>,
				editor:
					<CheckboxButton checked={accountType.isCustomer}
									style={{padding: 0}}
									onChange={() => {
										accountType.isCustomer = !accountType.isCustomer;
										this.setState({deltaAccountTypeMapping: true});
										this.forceUpdate();
									}}/>
			};
		});
	}

	render() {
		if (this.state.loading) return <Loading>Loading Org...</Loading>;
		let {org, allRoles, orgProfiles, orgOwner, orgDeltasForSave, saving, photoUpdate} = this.state;
		let defaultRole;
		if (org.defaultRole) {
			defaultRole = allRoles.find((role) => role.roleCode === org.defaultRole.roleCode);
		}
		let domains = '';
		if (org.domains?.length) {
			domains = org.domains.join(',');
		}

		return (
			<ScrollingContainer divId={'admin_org_page'}>
				{saving &&
				 <ScrimMessage message={'Saving Org...'}/>}
				<DetailHeader MaterialIcon={Business} title={org.name}/>
				<div id={'admin_org_page_scrolling_div'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
					{this.renderSection(GENERAL_INFO,
						[
							{
								name: 'Logo',
								value: <Pic orgId={org.orgId} disableClick={true} picSize={32}/>,
								editor:
									<div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
										<Pic orgId={org.orgId} disableClick={true} picSize={32}/>
										<ImageSelector orgCode={org.orgCode}
													   dataCy={'org_camera_icon'}
													   updateType={'photo'}
													   updateComplete={() => this.setState({photoUpdate: photoUpdate + 1})}/>
									</div>
							},
							{
								name: 'Domains',
								value: domains,
								editor: <TextInputBox hintText={'Domains'}
													  errorText={this.state.orgUpdateErrorMessage}
													  value={domains}
													  onChange={(value) => {
														  value = StringHelper.trimWhiteSpace(value);
														  if (value) {
															  org.domains = value.split(',');
															  orgDeltasForSave.domains = value.split(',');
														  }
														  else {
															  org.domains.length = 0;
															  orgDeltasForSave.domains.length = 0;
														  }
														  this.setState({orgUpdateErrorMessage: null});
													  }}
													  maxChars={1000}
													  minWidth={320}
													  isSimple={true}
													  doNotAutoFocus={true}/>
							},
							{
								name: 'Status',
								value: org.status,
								editor: <PopoverSearchList label={'Status'}
														   list={[STATUS_INACTIVE, STATUS_ACTIVE, STATUS_TERMINATED]}
														   preselectedItem={org.status}
														   onItemSelected={(status) => {
															   org.status = status;
															   orgDeltasForSave.status = status;
														   }}/>
							},
							{
								name: 'Default Role',
								value: defaultRole ? defaultRole.displayName : 'None',
								editor: <PopoverSearchList label={'Default Role'}
														   list={allRoles}
														   preselectedItem={defaultRole ? defaultRole : ''}
														   labelRenderer={(role) => role.displayName}
														   searchByObjectKeys={['displayName']}
														   onItemSelected={(role) => {
															   org.defaultRole = {roleCode: role.roleCode};
															   orgDeltasForSave.rolePublicId = role.rolePublicId;
														   }}/>
							},
							{
								name: 'Owner',
								value: this.renderOrgOwnerTable(true),
								editor: orgProfiles.length ?
									<Fragment>
										<PopoverSearchList label={'Owner'}
														   list={orgProfiles}
														   preselectedItem={orgOwner}
														   labelRenderer={(profile) => profile.firstName + ' ' + profile.lastName}
														   valueRenderer={(profile) => profile.personId}
														   searchByObjectKeys={['firstName', 'lastName']}
														   onItemSelected={(profile) => {
															   this.setState({orgOwner: profile});
															   orgDeltasForSave.orgOwnerId = profile.personId;
														   }}/>
										{this.renderOrgOwnerTable(false)}
									</Fragment>
									:
									<div style={{fontSize: 16, fontWeight: 'bold', color: partnerTapAlert}}>
										No Owners Found
									</div>
							},
							{
								name: 'SignUp Type',
								value: org.signUpType,
								editor: <PopoverSearchList label={'SignUp Type'}
														   list={[SFDC, HUBSPOT, MSFT, OKTA]}
														   preselectedItem={org.signUpType}
														   onItemSelected={(signUpType) => {
															   org.signUpType = signUpType;
															   orgDeltasForSave.signUpType = signUpType;
														   }}/>
							}
						])}
					{this.renderSection('Products', this.processProducts(org), 'Changing Products a is a BIG DEAL!')}
					{this.renderProductFeatures()}
					{this.renderSection('Sharing: Accounts',
						AdminEditorHelper.processShareOptions(org, orgDeltasForSave, ASSET_TYPE_ACCOUNTS, () => this.forceUpdate()),
						'Changing Account Share Settings has consequences... BE CAREFUL!!!!')}
					{this.renderSection('Sharing: Opps',
						AdminEditorHelper.processShareOptions(org, orgDeltasForSave, ASSET_TYPE_OPPS, () => this.forceUpdate()),
						'Changing Opp Share Settings has consequences... BE CAREFUL!!!!')}
					{this.renderSection('Account Type Mapping',
						this.processAccountTypeMapping(org, orgDeltasForSave, ASSET_TYPE_ACCOUNTS),
						'Changing Account Type Mapping Settings has consequences... BE CAREFUL!!!!')}
					<div style={{padding: 5}}/>
				</div>
			</ScrollingContainer>
		);
	}
}

AdminOrgPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default connect()(AdminOrgPage);
