import objectAssign from 'object-assign';
import {REGISTRATION_CREATED_USER_EMAIL, REGISTRATION_ERROR, REGISTRATION_TEMP_PROFILE} from './ReduxEnum';

export default function registrationState(state = {
	profileInformation: {
		firstName: null,
		lastName: null,
		company: null,
		email: null,
		phone: null
	},
	createdUserEmail: null,
	registrationError: ''
}, action) {
	switch (action.type) {
		case REGISTRATION_TEMP_PROFILE: {
			let newState = objectAssign({}, state);
			newState.profileInformation = action.profileInformation;
			return newState;
		}
		case REGISTRATION_CREATED_USER_EMAIL: {
			let newState = objectAssign({}, state);
			newState.createdUserEmail = action.createdUserEmail;
			return newState;
		}
		case REGISTRATION_ERROR: {
			let newState = objectAssign({}, state);
			newState.registrationError = action.registerProfileError;
			return newState;
		}
		default: {
			return state;
		}
	}
}
