import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ProfilesEndpoints from '../../endpoints/ProfilesEndpoints';
import {INVITE_STATUS_ACCEPTED} from '../../globals/Enums';
import EnvHelper from '../../helpers/EnvHelper';
import StringHelper from '../../helpers/StringHelper';
import {
	partnerTapAppBackground,
	partnerTapPrimary,
	partnerTapSecondary,
	partnerTapStroke,
	partnerTapTernary,
	partnerTapWhite
} from '../../styles/partnertap_theme';
import ImageSelector from '../../ui/ImageSelector';
import Loading from '../../ui/Loading';
import PartnerTapIcon from '../../ui/PartnerTapIcon';
import Pic from '../../ui/Pic';
import {FEEDBACK_UI_ELEMENT_TIMEOUT} from '../App';
import PartnerProfileControls from './PartnerProfileControls';

export class ProfileInfo extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			profile: this.props.profile,
			accountCount: this.props.profile ? this.props.profile.totalSyncedAccounts : 0,
			partnerCount: this.props.profile ? this.props.profile.totalPartners : 0,
			updated: false,
			updateKey: 0,
			needToSave: false,
			invalidValues: {}
		};
		this.maxPropertyLengths = {
			emailAddress: 255,
			cellPhoneNumber: 255,
			ptJobTitle: 255,
			territory: 255,
			organizationTagline: 255,
			division: 255,
			street: 255,
			city: 255,
			state: 100,
			zipCode: 15,
			phone: 50
		};
		this.onKeyboardUp = this.onKeyboardUp.bind(this);
		this.renderProfileValue = this.renderProfileValue.bind(this);
		this.getProfile = this.getProfile.bind(this);
		this.saveProfile = this.saveProfile.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		if (!this.state.profile && this.props.personId) {
			this.getProfile(this.props.personId);
		}
		window.addEventListener('keyboardDidShow', this.onKeyboardUp);
	}

	componentWillUnmount() {
		this.saveProfile(false);
		if (this.timeout) clearTimeout(this.timeout);
		window.removeEventListener('keyboardDidShow', this.onKeyboardUp);
		this.unmounted = true;
	}

	onKeyboardUp(event) {
		if (this.scrollTo) {
			// start scroll after a delay to ensure soft keyboard is done sliding into place
			setTimeout(() => {
				if (this.scrollTo) {
					window.scrollTo({top: this.scrollTo, behavior: 'smooth'});
					this.scrollTo = 0;
				}
			}, 100);
		}
	}

	getProfile(personId) {
		ProfilesEndpoints.fetchProfile(personId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({
				profile: result.payload,
				accountCount: result.payload.totalSyncedAccounts,
				partnerCount: result.payload.totalPartners
			});
		});
	}

	saveProfile(updateState) {
		if (this.timeout) clearTimeout(this.timeout);
		if (this.state.needToSave) {
			ProfilesEndpoints.updateProfileInfo(this.state.profile)
			.then((result) => {
				if (updateState) {
					this.setState({needToSave: false, updated: false});
				}
			})
			.catch((error) => {
				EnvHelper.serverError('Error from saveProfile', error);
			});
		}
	}

	onFocus(property) {
		let element = document.getElementById(property);
		let topContainer = document.getElementById('topContainer');
		this.scrollTo = 100 + element.getBoundingClientRect().y - topContainer.getBoundingClientRect().y;
		if (!EnvHelper.isMobile) {
			window.scrollTo({top: this.scrollTo, behavior: 'smooth'});
		}
	}

	onChange(property, formatFunction) {
		let newValue = document.getElementById(property).value;
		if (formatFunction) {
			let formattedValue = formatFunction(newValue);
			if (newValue && formattedValue) {
				newValue = formattedValue;
			}
		}

		let profile = this.props.authState.profile; // use auth state profile edits
		profile[property] = newValue;
		this.setState({profile: profile, updated: true, needToSave: true});

		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({updated: false});
		}, FEEDBACK_UI_ELEMENT_TIMEOUT);
	}

	renderProfileValue(property, defaultValue, color, formatFunction, cyDataName) {
		let profile = this.state.profile;
		let isMe = profile.personId === this.props.authState.person.id;
		if (isMe) profile = this.props.authState.profile; // use auth state profile for me, in case it has been edited
		let isEditing = this.props.isEditing;
		let canEdit = property && property !== 'emailAddress';
		let useTextArea = property === 'organizationTagline';
		let value = property ? profile[property] : defaultValue;
		if (isEditing && canEdit) {
			return (
				<div id={'topContainer'}
					 style={{
						 display: 'flex',
						 justifyContent: 'space-between',
						 alignItems: 'center',
						 paddingLeft: 20,
						 paddingRight: 20,
						 minWidth: EnvHelper.isDesktop ? 440 : 340,
						 minHeight: useTextArea ? 80 : 40,
						 zIndex: 2
					 }}>
					<div style={{whiteSpace: 'nowrap'}}>
						{defaultValue}
					</div>
					{useTextArea ?
						<textarea rows={3}
								  autoComplete={'off'}
								  maxLength={this.maxPropertyLengths[property]}
								  style={{
									  fontSize: 14,
									  width: EnvHelper.isDesktop ? 340 : 260,
									  padding: 4,
									  resize: 'none',
									  border: '1px solid #000000',
									  borderRadius: 5
								  }}
								  value={value ? value : ''}
								  id={property}
								  onBlur={() => {
									  this.saveProfile(this.state.profile);
								  }}
								  onFocus={() => {
									  this.onFocus(property);
								  }}
								  onChange={() => {
									  this.onChange(property, formatFunction);
								  }}
								  disabled={EnvHelper.isSpoofing}/>
						:
						<input type={'text'}
							   autoComplete={'off'}
							   maxLength={this.maxPropertyLengths[property]}
							   style={{
								   fontSize: 14,
								   width: EnvHelper.isDesktop ? 340 : 260,
								   padding: 4,
								   border: '1px solid #000000'
							   }}
							   value={value ? value : ''}
							   id={property}
							   onBlur={() => {
								   this.saveProfile(this.state.profile);
							   }}
							   onFocus={() => {
								   this.onFocus(property);
							   }}
							   onChange={() => {
								   this.onChange(property, formatFunction);
							   }}
							   disabled={EnvHelper.isSpoofing}/>}
				</div>
			);
		}

		if (formatFunction) value = formatFunction(value);
		let isPartner = profile.partnerId && profile.partnerStatus === INVITE_STATUS_ACCEPTED;
		if (!value && (isPartner || !isMe)) return '';
		let invalidValues = this.state.invalidValues;
		let invalidValue = invalidValues[property];
		invalidValues[property] = '';
		return (
			<div style={{
				flex: 1,
				color: invalidValue ? 'red' : (value ? (color ? color : '#000000') : partnerTapStroke),
				maxWidth: 550,
				paddingBottom: useTextArea && (value || invalidValue) ? 10 : 0
			}}
				 data-cy={cyDataName}>
				{invalidValue ? invalidValue : value ? value : defaultValue}
			</div>
		);
	}

	render() {
		let profile = this.state.profile;
		if (!profile) {
			return <Loading>Loading Profile...</Loading>;
		}
		let isMe = profile.personId === this.props.authState.person.id;
		if (isMe) profile = this.props.authState.profile; // use auth state profile for me, in case it has been edited

		let isEditing = this.props.isEditing;
		let isPartner = profile.partnerId && profile.partnerStatus === INVITE_STATUS_ACCEPTED;

		let profileName = profile.firstName + ' ' + profile.lastName;
		if (profileName === ' ') profileName = null;
		let emailField = this.renderProfileValue('emailAddress', 'Email', isPartner ? partnerTapSecondary : null, null, "email-id");
		if (emailField && isPartner) {
			emailField = <a href={'mailto:' + profile.emailAddress}>{emailField}</a>;
		}
		let cellPhoneField = this.renderProfileValue('cellPhoneNumber', 'Cell', isPartner && !EnvHelper.isDesktop ? partnerTapSecondary : null, StringHelper.formatPhoneNumber);
		if (cellPhoneField && isPartner && !EnvHelper.isDesktop) {
			cellPhoneField = <a href={'tel:' + profile.cellPhoneNumber}>{cellPhoneField}</a>;
		}
		let {city, state, zipCode} = profile;
		if (!city) city = '';
		if (!state) state = '';
		if (!zipCode) zipCode = '';
		let profilePlace = city + (city && state ? ', ' : '') + state + ' ' + zipCode;
		if (profilePlace === ' ') profilePlace = null;

		let bannerImage = EnvHelper.serviceUrl + '/images/texture-overlay.png';
		if (profile.hasBanner) {
			bannerImage = EnvHelper.profileImageUri + '/' + profile.personId + '-banner.jpg';
			bannerImage += '?nocache=' + EnvHelper.noCache;
		}

		return (
			<div key={this.state.updateKey}
				 style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: isMe ? 10 : 0, background: partnerTapAppBackground}}>
				<div style={{
					backgroundImage: 'url(' + bannerImage + ')',
					backgroundSize: 'cover',
					backgroundColor: partnerTapPrimary,
					backgroundPosition: 'center',
					width: isMe ? '100vw' : '90vw',
					maxWidth: 600,
					height: 140,
					position: 'relative'
				}}>
					{isEditing &&
					 <div style={{position: 'absolute', top: 6, right: 8}}>
						 <ImageSelector personId={profile.personId}
										dataCy={'banner_camera_icon'}
										updateType={'banner'}
										updateComplete={() => this.setState({update: true, updateKey: this.state.updateKey + 1})}/>
					 </div>}
					<div style={{position: 'absolute', top: 30, left: '50%', transform: 'translateX(-50%)'}}>
						<Pic personId={profile.personId} picSize={130} disableClick={true}>
							{isEditing &&
							 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								 <div style={{position: 'absolute', left: '50%', transform: 'translateX(-50%)', top: 96, zIndex: 6}}>
									 <ImageSelector personId={profile.personId}
													dataCy={'profile_camera_icon'}
													updateType={'photo'}
													updateComplete={() => this.setState({updated: true, updateKey: this.state.updateKey + 1})}/>
								 </div>
							 </div>}
						</Pic>
					</div>
				</div>
				<div style={{
					paddingTop: 40,
					paddingBottom: 20,
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					maxWidth: 600,
					backgroundColor: partnerTapWhite
				}}>
					<div style={{flex: 1, fontSize: 18, fontWeight: 'bold', paddingBottom: 5}}>
						{this.renderProfileValue(null, profileName)}
					</div>

					{(isMe || isPartner) && emailField}
					{(isMe || isPartner) && cellPhoneField}

					{this.renderProfileValue('ptJobTitle', 'Title')}
					{this.renderProfileValue('territory', 'Territory')}

					<hr style={{margin: 5}}/>

					<div style={{flex: 1, fontSize: 18, fontWeight: 'bold', paddingTop: 10, paddingBottom: 5}}>
						{this.renderProfileValue(null, profile.organization)}
					</div>
					{this.renderProfileValue('organizationTagline', 'About')}
					{this.renderProfileValue('division', 'Division')}

					{this.renderProfileValue('street', 'Street')}
					{!isEditing ?
						this.renderProfileValue(null, profilePlace ? profilePlace : ((isPartner || !isMe) ? '' : 'City, State Zip/Postal'), profilePlace ? null : partnerTapStroke)
						:
						<div>
							{this.renderProfileValue('city', 'City')}
							{this.renderProfileValue('state', 'State')}
							{this.renderProfileValue('zipCode', 'Zip/Postal', null, StringHelper.alphanumeric)}
						</div>
					}
					{this.renderProfileValue('country', 'Country')}
					{this.renderProfileValue('phone', 'Phone', isPartner && !EnvHelper.isDesktop ? partnerTapSecondary : null, StringHelper.formatPhoneNumber)}

					<hr style={{flex: 0, marginTop: 20, width: '90vw', maxWidth: 600}}/>

					{!isEditing &&
					 <div style={{display: 'flex', alignItems: 'center', paddingTop: 10, fontSize: 16, color: partnerTapTernary}}>
						 <PartnerTapIcon glyph={'A'} style={{fontSize: 28}}/>
						 {this.state.accountCount} Account{this.state.accountCount !== 1 && 's'}

						 <div style={{padding: 20}}/>

						 <PartnerTapIcon glyph={'X'} style={{fontSize: 36}}/>
						 {this.state.partnerCount} Partner{this.state.partnerCount !== 1 && 's'}
					 </div>}
					{this.state.updated &&
					 <div style={{paddingTop: 20, width: '100%', textAlign: 'center', color: partnerTapTernary}}>
						 Changes saved!
					 </div>}
					{(isEditing && !this.state.updated) &&
					 <div style={{padding: 20}}/>}
					{isPartner &&
					 <PartnerProfileControls person={this.props.authState.person} personId={this.props.personId} profile={this.state.profile}/>}
				</div>
			</div>
		);
	}
}

ProfileInfo.propTypes = {
	personId: PropTypes.string.isRequired,
	profile: PropTypes.object,
	isEditing: PropTypes.bool.isRequired,
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(ProfileInfo);
