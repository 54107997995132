import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import StringHelper from '../../../../helpers/StringHelper';
import {SHEET_ERROR_BLANK_HEADERS} from '../../../../helpers/UploadHelper';
import {partnerTapAlert, partnerTapTernary, partnerTapWarn} from '../../../../styles/partnertap_theme';
import ActionButton from '../../../../ui/buttons/ActionButton';

class SheetErrorTable extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {table: null, errorCount: 0, errorIndex: 0};

		this.makeHeaderTable = this.makeHeaderTable.bind(this);
		this.showError = this.showError.bind(this);
	}

	componentDidMount() {
		this.makeHeaderTable();
		if (this.initialAutoScroll) return;
		this.initialAutoScroll = true;
		setTimeout(() => this.showError(0));
	}

	get isBlankHeaders() {
		let {errorType} = this.props;
		return errorType === SHEET_ERROR_BLANK_HEADERS;
	}

	makeHeaderTable() {
		let {errorData} = this.props;
		let tableOrdinals = [];
		let tableHeaders = [];
		let tableRows = this.isBlankHeaders ? [[], []] : [[], [], [], []];
		let errorCount = 0;
		errorData.forEach((header, index) => {
			tableOrdinals.push(StringHelper.getAlphaOrdinal(index + 1));
			tableHeaders.push(header.header);
			tableRows.forEach((row, index) => row.push(header.samples ? header.samples[index] : '-'));
		});
		let ordinalStyle = {fontWeight: 'bold', textAlign: 'center', padding: 10, color: partnerTapTernary};
		let headerStyle = {fontWeight: 'bold', textAlign: 'center', whiteSpace: 'nowrap', textTransform: 'capitalize', padding: 10};
		let table =
			<table border={1} style={{padding: 10, borderCollapse: 'collapse'}}>
				<tbody>
				<tr>
					{tableOrdinals.map((ordinal) => <td key={ordinal} style={ordinalStyle}>{ordinal}</td>)}
				</tr>
				<tr>
					{tableHeaders.map((header, index) => {
						let style = headerStyle;
						let id = 'column_' + index;
						let isBlank = errorData[index].isHeaderBlank;
						if (isBlank) {
							style = Object.assign({backgroundColor: partnerTapAlert}, headerStyle);
							id = 'error_column_' + errorCount++;
						}
						return <td key={id} id={id} style={style}>{isBlank ? '' : header}</td>;
					})}
				</tr>
				{tableRows.map((row, index) => {
					return (
						<tr key={index}>
							{row.map((sampleValue, index) => {
								let style = {padding: 10};
								let id = 'column_' + index;
								let isMissing = errorData[index].isMissingDataSamples && !sampleValue;
								if (isMissing) {
									style = Object.assign({backgroundColor: partnerTapAlert}, headerStyle);
									id = 'error_column_' + errorCount++;
								}
								return (
									<td key={id} id={id} style={style}>
										<div style={{minWidth: 140, maxHeight: 100, overflow: 'scroll'}}>
											{sampleValue}
										</div>
									</td>
								);
							})}
						</tr>);
				})}
				</tbody>
			</table>;
		this.setState({table: table, errorCount: errorCount});
	}

	showError(direction) {
		let {errorIndex, errorCount} = this.state;
		errorIndex += direction;
		if (errorIndex < 0) errorIndex = 0;
		if (errorIndex > errorCount - 1) errorIndex = errorCount - 1;
		let element = document.getElementById('error_column_' + errorIndex);
		// Chrome simply refuses to scroll smoothly at either end (works fine in Safari and Firefox)
		let fixChromeBug = errorIndex === 0 && direction === -1 || errorIndex === errorCount - 1 && direction === 1;
		element.scrollIntoView({behavior: fixChromeBug ? 'instant' : 'smooth', inline: 'center'});
		this.blinkElement(element);
		this.setState({errorIndex: errorIndex});
	}

	blinkElement(element) {
		setTimeout(() => element.style.backgroundColor = partnerTapWarn, 250);
		setTimeout(() => element.style.backgroundColor = partnerTapAlert, 500);
		setTimeout(() => element.style.backgroundColor = partnerTapWarn, 750);
		setTimeout(() => element.style.backgroundColor = partnerTapAlert, 1000);
		setTimeout(() => element.style.backgroundColor = partnerTapWarn, 1250);
		setTimeout(() => element.style.backgroundColor = partnerTapAlert, 1500);
	}

	render() {
		let {table, errorCount, errorIndex} = this.state;
		let missingLabel = this.isBlankHeaders ? 'Missing Header' : 'Missing Sample';
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20}}>
				<div style={{maxWidth: 600, overflow: 'scroll'}}>
					{table}
				</div>
				{errorCount > 1 &&
				 <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
					 <ActionButton icon={<KeyboardArrowLeft/>} toolTip={'Previous error'} disabled={errorIndex <= 0} onAction={() => this.showError(-1)}/>
					 {missingLabel} {errorIndex + 1}
					 <ActionButton icon={<KeyboardArrowRight/>}
								   toolTip={'Next error'}
								   disabled={errorIndex >= errorCount - 1}
								   onAction={() => this.showError(1)}/>
				 </div>}
			</div>
		);
	}
}

SheetErrorTable.propTypes = {
	errorType: PropTypes.string.isRequired,
	errorData: PropTypes.array.isRequired
};

export default SheetErrorTable;
