import {combineReducers} from 'redux';
import authState from './AuthReducer';
import intelState from './IntelReducer.js';
import managerAnalyticsState from './ManagerAnalyticsReducer.js';
import notificationsState from './NotificationReducer.js';
import registrationState from './RegistrationReducer.js';

const rootReducer = combineReducers({
	authState,
	intelState,
	notificationsState,
	managerAnalyticsState,
	registrationState
});

export default rootReducer;
