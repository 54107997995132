import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ManagerAnalyticsEndpoints from '../../../endpoints/ManagerAnalyticsEndpoints';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import * as ManagerAnalyticsActions from '../../../redux/ManagerAnalyticsActions';
import {partnerTapSecondary} from '../../../styles/partnertap_theme';
import ManagerAnalyticsPage from '../ManagerAnalyticsPage';

class TapAnalytics extends Component {

	componentDidMount() {
		if (this.props.managerAnalyticsState.currentCompanyName) {
			this.props.dispatch(ManagerAnalyticsActions.setCurrentCompanyName({id: null, name: null}));
		}
	}

	componentDidUpdate(nextProps, nextState, snapshot) {
		if (nextProps.managerAnalyticsState.currentCompanyName !== null) {
			EnvHelper.push(this.props.isOutbound ? Routes.MANAGER_ANALYTICS.TAP.ORG.OUTBOUND : Routes.MANAGER_ANALYTICS.TAP.ORG.INBOUND);
		}
	}

	renderPartnerOrg(value, data) {
		if (data) {
			return (
				<div style={{color: partnerTapSecondary}}>
					{value}
				</div>
			);
		}
		else {
			return value;
		}
	}

	get title() {
		return this.props.isOutbound ? this.props.authState.profile.organization + ' Opps' : 'Partner Opps';
	}

	get icon() {
		return this.props.isOutbound ? 'k' : 'd';
	}

	render() {
		let columns = [
			{title: 'Partner Org', key: 'partnerOrg', renderFunction: this.renderPartnerOrg},
			{title: 'Opps', key: 'totalOpportunities'},
			{title: 'Partners in Opps', key: 'partnerCount'},
			{title: 'Opps Opened', key: 'openOpportunities'},
			{title: 'Opps Closed', key: 'closedOpportunities'}
		];
		return (
			<ManagerAnalyticsPage labelNoFind={'No opportunity data found'}
								  labelLoading={'Loading Opportunity Data...'}
								  sectionName={this.title}
								  glyph={this.icon}
								  columnData={columns}
								  keyData={this.props.isOutbound ? [ManagerAnalyticsEndpoints.keyOutboundOpportunities] : [ManagerAnalyticsEndpoints.keyInboundOpportunities]}
								  customCount={ManagerAnalyticsEndpoints.fetchOppSummaryCounts}
								  rowClick={(data) => {
									  this.props.dispatch(ManagerAnalyticsActions.setCurrentCompanyName({name: data.partnerOrg, id: null}));
									  EnvHelper.push(this.props.location.pathname === Routes.MANAGER_ANALYTICS.TAP.OUTBOUND ? Routes.MANAGER_ANALYTICS.TAP.ORG.OUTBOUND : Routes.MANAGER_ANALYTICS.TAP.ORG.INBOUND);
								  }}/>
		);
	}
}

TapAnalytics.propTypes = {
	isOutbound: PropTypes.bool.isRequired,
	authState: PropTypes.object.isRequired,
	managerAnalyticsState: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default withRouter(connect(mapStateToProps)(TapAnalytics));
