import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import AdminOrgsPage from '../../app/admin_tools/AdminOrgsPage';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_ADMIN_TOOLS_EDITORS} from '../../helpers/FeatureHelper';
import NavHelper from '../../helpers/NavHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapQuaternary} from '../../styles/partnertap_theme';
import SideNav from '../../ui/navigation/SideNav';
import TopBar from '../../ui/TopBar';
import ProfilePage from '../profile/ProfilePage';
import AdminAutoPartneredOrgsPage from './AdminAutoPartneredOrgsPage';
import AdminExcludedPartnersPage from './AdminExcludedPartnersPage';
import AdminOrgAccountTagsPage from './AdminOrgAccountTagsPage';
import AdminOrgPage from './AdminOrgPage';
import AdminPreRecommendedOrgsPage from './AdminPreRecommendedOrgsPage';
import AdminPreRegUsersPage from './AdminPreRegUsersPage';
import AdminRecommendedPartnerOrgsPage from './AdminRecommendedPartnerOrgsPage';
import AdminReportsPage from './AdminReportsPage';
import AdminRolePage from './AdminRolePage';
import AdminRolesPage from './AdminRolesPage';
import AdminUploadPage from './AdminUploadPage';
import AdminUsersPage from './AdminUsersPage';

class AdminToolsProduct extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {isMobileNavOpen: false};
	}

	componentDidMount() {
		if (!FeatureHelper.isFeatureEnabled(FEATURE_ADMIN_TOOLS_EDITORS)) {
			this.props.dispatch(AuthActions.logout());
			EnvHelper.push(Routes.LOGOUT.ROUTE);
		}
	}

	render() {
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<TopBar/>
				<div style={{flex: 1, display: 'flex', backgroundColor: partnerTapQuaternary}}>
					<div key={EnvHelper.path}>
						<SideNav navButtonData={NavHelper.adminToolsData}
								 isMobileNavOpen={this.state.isMobileNavOpen}
								 onToggleMobileNavOpen={(state) => {
									 if (this.unmounted) return;
									 this.setState({isMobileNavOpen: state});
								 }}/>
					</div>
					<div style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
						<Switch>
							<Route exact path={Routes.ADMIN_TOOLS.ORGS.ROUTE} component={AdminOrgsPage}/>
							<Route path={Routes.ADMIN_TOOLS.ORGS.ORG_DETAILS.ROUTE} component={AdminOrgPage}/>
							<Route path={Routes.ADMIN_TOOLS.ORGS.ACCOUNT_TAGS.ROUTE} component={AdminOrgAccountTagsPage}/>

							<Route exact path={Routes.ADMIN_TOOLS.ROLES.ROUTE} component={AdminRolesPage}/>
							<Route path={Routes.ADMIN_TOOLS.ROLES.ROLE_DETAILS.ROUTE} component={AdminRolePage}/>

							<Route path={Routes.ADMIN_TOOLS.USERS.DETAILS.ROUTE} component={AdminUsersPage}/>
							<Route path={Routes.ADMIN_TOOLS.USERS.PRE_REG.ROUTE} component={AdminPreRegUsersPage}/>
							<Route path={Routes.ADMIN_TOOLS.USERS.UPLOAD.ROUTE} component={AdminUploadPage}/>

							<Route path={Routes.ADMIN_TOOLS.PARTNERS.EXCLUDED.ROUTE} component={AdminExcludedPartnersPage}/>
							<Route path={Routes.ADMIN_TOOLS.PARTNERS.RECOMMENDED_ORGS.ROUTE} component={AdminRecommendedPartnerOrgsPage}/>
							<Route path={Routes.ADMIN_TOOLS.PARTNERS.AUTO_PARTNERED_ORGS.ROUTE} component={AdminAutoPartneredOrgsPage}/>
							<Route path={Routes.ADMIN_TOOLS.PARTNERS.PRE_RECOMMENDED_ORGS.ROUTE} component={AdminPreRecommendedOrgsPage}/>
							<Route path={Routes.ADMIN_TOOLS.PARTNERS.UPLOAD.ROUTE} component={AdminUploadPage}/>

							<Route path={Routes.ADMIN_TOOLS.REPORTS.ROUTE} component={AdminReportsPage}/>

							<Route path={Routes.ADMIN_TOOLS.PROFILE.ROUTE} component={ProfilePage}/>
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

AdminToolsProduct.propTypes = {
	dispatch: PropTypes.func.isRequired
};

export default connect()(AdminToolsProduct);
