import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as AccountsEndpoints from '../../../endpoints/AccountsEndpoints';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import FilterHelper from '../../../helpers/FilterHelper';
import SortHelper from '../../../helpers/SortHelper';
import SupportButton from '../../../ui/buttons/SupportButton';
import PagingBase from '../../../ui/lists/PagingBase';
import AccountsRowRenderer from './AccountRowRenderer';

export class AccountsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.forShareOpportunities = Boolean(this.props.forShareOpportunities);
		this.state.filter = this.isSubNavAll ? FilterHelper.FILTER_BY_ALL : FilterHelper.FILTER_BY_OPEN_OPPORTUNITIES;
	}

	get isSubNavAll() {
		return EnvHelper.path === Routes.DASHBOARD.ACCOUNTS.ROUTE || EnvHelper.path === Routes.DASHBOARD.ACCOUNTS.ALL.ROUTE;
	}

	get storageKeyBase() {
		if (this.props.forShareOpportunities) return 'network_accounts_share_opportunities';
		if (this.isSubNavAll) return 'network_accounts_page_all';
		return 'network_accounts_page_open_opps';
	}

	get title() {
		return this.state.forShareOpportunities ? 'Share Opps' : (this.isSubNavAll ? 'All Accounts' : 'Open Opps');
	}

	get icon() {
		return this.state.forShareOpportunities ? 'w' : (this.isSubNavAll ? 'a' : '%');
	}

	get sortData() {
		return [
			{name: 'Matched Partners', key: SortHelper.sortAccountsByMatchCount, selected: true},
			{name: 'Recommended Partners', key: SortHelper.sortAccountsByRecommendationCount},
			{name: 'Account Name', key: SortHelper.sortAccountsByName}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let filter = this.isSubNavAll ? FilterHelper.FILTER_BY_ALL : FilterHelper.FILTER_BY_OPEN_OPPORTUNITIES;
		AccountsEndpoints.searchAccounts(
			params.page,
			params.pageSize,
			params.search,
			params.sort,
			filter,
			this.state.forShareOpportunities)
		.then((result) => {
				if (this.unmounted) return;
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			if (this.isSubNavAll) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							You'll need to import your accounts to get started
						</div>
						<div>
							We'd be happy to help!
						</div>
						<div style={{padding: 10}}>
							<SupportButton/>
						</div>
					</div>
				);
			}
			else {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, paddingTop: 0, fontSize: 16}}>
						<div style={{textAlign: 'center', padding: 20}}>
							None of your accounts have open opportunities
						</div>
						<div>
							We'd be happy to help find your data! Please reach out...
						</div>
						<div style={{padding: 10}}>
							<SupportButton/>
						</div>
					</div>
				);
			}
		}
		return super.noDataMessage;
	}

	get rowRenderer() {
		return AccountsRowRenderer;
	}
}

AccountsPage.propTypes = {
	forShareOpportunities: PropTypes.bool,
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(AccountsPage);
