import {ArrowBack, Check, PlayArrow} from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapAppBackground, partnerTapStroke, partnerTapTernary, partnerTapTernaryLight} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import MessageBoxAlert from '../../../ui/messages/MessageBoxAlert';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import Stepper from '../../../ui/Stepper';
import {
	isWorkflowAvailable,
	WORKFLOW_CRM_TRACKING,
	WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL,
	WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL,
	WORKFLOW_INTRO_EMAIL,
	WORKFLOW_SHARE_ACTION_LIST,
	WORKFLOW_STATUS_STARTED
} from './CoSellEngineHelper';
import EmailWorkflowEditor from './workflow_editors/EmailWorkflowEditor';
import {WorkflowCrmTracking} from './workflow_editors/WorkflowCrmTracking';
import {WorkflowDistributeRecordsExternal} from './workflow_editors/WorkflowDistributeRecordsExternal';
import {WorkflowDistributeRecordsInternal} from './workflow_editors/WorkflowDistributeRecordsInternal';
import {WorkflowIntroEmail} from './workflow_editors/WorkflowIntroEmail';
import {WorkflowShareActionList} from './workflow_editors/WorkflowShareActionList';

class CoSellWorkflowConfig extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, saving: false, steps: null, currentConfigStep: 0, editor: null};

		this.initConfigsAndSteps = this.initConfigsAndSteps.bind(this);
		this.updateSteps = this.updateSteps.bind(this);
		this.renderConfigStep = this.renderConfigStep.bind(this);
		this.renderChooseSequenceStep = this.renderChooseSequenceStep.bind(this);
		this.renderConfirmConfig = this.renderConfirmConfig.bind(this);
		this.collateWorkflowRecords = this.collateWorkflowRecords.bind(this);
		this.renderSteppingButtons = this.renderSteppingButtons.bind(this);
		this.gotoPreviousStep = this.gotoPreviousStep.bind(this);
		this.gotoNextStep = this.gotoNextStep.bind(this);
		this.confirmConfiguration = this.confirmConfiguration.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
	}

	componentDidMount() {
		this.initConfigsAndSteps();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	initConfigsAndSteps() {
		let {editor} = this;
		let endpoint = editor.getWorkflowConfigsEndpoint;
		endpoint()
		.then((result) => {
			if (this.unmounted) return;

			let steps = editor.steps;
			steps.push({label: 'Confirm'});
			this.updateSteps(steps, 0);

			let adminSequenceConfigs = result.payload;
			adminSequenceConfigs.forEach((sequence) => sequence.disabled = !sequence.isActive);

			this.setState({loading: false, adminSequenceConfigs: adminSequenceConfigs, steps: steps, editor: editor});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from initSteps', error);
		});
	}

	get editor() {
		let {actionListInfo, workflow, workflowRecords} = this.props;
		switch (workflow.config) {
			case WORKFLOW_SHARE_ACTION_LIST:
				return new WorkflowShareActionList(actionListInfo, workflow, workflowRecords);
			case WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL:
				return new WorkflowDistributeRecordsInternal(actionListInfo, workflow, this.collateWorkflowRecords());
			case WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL:
				return new WorkflowDistributeRecordsExternal(actionListInfo, workflow, this.collateWorkflowRecords());
			case WORKFLOW_INTRO_EMAIL:
				return new WorkflowIntroEmail(actionListInfo, workflow, this.collateWorkflowRecords());
			case WORKFLOW_CRM_TRACKING:
				return new WorkflowCrmTracking(actionListInfo, workflow, workflowRecords);
		}
		return null;
	}

	saveChanges() {
		this.setState({saving: true});
		let {actionListInfo} = this.props;
		CoSellEngineEndpoints.updateCoSellActionList(actionListInfo)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({saving: false});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from saveChanges', error);
		});
	}

	updateSteps(steps, currentConfigStep) {
		if (!steps) steps = this.state.steps;
		steps.forEach((step, index) => {
			step.active = index === currentConfigStep;
			step.complete = index < currentConfigStep;
		});
		this.setState({steps: steps, currentConfigStep: currentConfigStep});
	}

	renderConfigStep() {
		let {actionListInfo, workflow} = this.props;
		let {currentConfigStep, editor} = this.state;
		let unavailableMessage = editor.isUnavailable;
		if (unavailableMessage) {
			return unavailableMessage;
		}
		if (currentConfigStep === 0) {
			return this.renderChooseSequenceStep();
		}
		if (this.isConfirmStep) {
			return this.renderConfirmConfig();
		}

		return <EmailWorkflowEditor workflowEditor={editor}
									actionListInfo={actionListInfo}
									workflow={workflow.config}
									currentConfigStep={currentConfigStep}
									onChange={() => this.forceUpdate()}/>;
	}

	renderChooseSequenceStep() {
		let {workflow} = this.props;
		let {editor, adminSequenceConfigs} = this.state;

		let currentAdminSequence = adminSequenceConfigs.find(editor.getCurrentSequence);
		if (workflow.sequence) {
			editor.onSelectSequence(currentAdminSequence);
		}

		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
				<div style={{textAlign: 'center'}}>
					<div style={{fontSize: 16, paddingBottom: 10}}>
						Please select your {workflow.name} Sequence
					</div>
					<div>
						{workflow.chooseSequenceHeader}
					</div>
				</div>
				<DropdownMenu title={workflow.name + ' Sequence'}
							  options={adminSequenceConfigs}
							  selectedOption={currentAdminSequence}
							  optionLabel={'name'}
							  onSelect={(adminSequenceConfig) => {
								  editor.onSelectSequence(adminSequenceConfig);
								  this.forceUpdate();
							  }}/>
			</div>
		);
	}

	renderConfirmConfig() {
		let {actionListInfo, workflow} = this.props;
		let {adminSequenceConfigs, editor} = this.state;
		let currentSequence = adminSequenceConfigs.find(editor.getCurrentSequence);
		let confirmed = actionListInfo.configuredWorkflows.indexOf(workflow.config) !== -1;
		let renderConfigStyle = {display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'top', gap: 20, height: '100vh'};
		let sectionStyle = {
			display: 'flex',
			flexDirection: 'column',
			gap: 10,
			width: 380,
			padding: 10,
			border: '1px solid ' + partnerTapTernary,
			borderRadius: 10,
			background: partnerTapTernaryLight
		};
		return (
			<div style={renderConfigStyle}>
				{confirmed ?
					<div style={sectionStyle}>
						<div style={{fontSize: 16, fontWeight: 'bold', textAlign: 'center'}}>
							Success! Your workflow is underway.
						</div>
					</div>
					:
					<Fragment>
						<div style={sectionStyle}>
							<div style={{fontSize: 20, fontWeight: 'bold'}}>
								{workflow.name}
							</div>
							<div style={{fontSize: 16, fontWeight: 'bold'}}>
								{currentSequence.name}
							</div>
							{editor.confirm()}
						</div>
						<div style={sectionStyle}>
							<div style={{fontSize: 16, fontWeight: 'bold'}}>
								Are you ready to start this workflow?
							</div>
							{!actionListInfo.isLocked &&
							 <div>
								 Starting this workflow will lock your Action List. You will not be able to make any changes to this list once it is locked.
							 </div>}
						</div>
					</Fragment>}
			</div>
		);
	}

	collateWorkflowRecords() {
		let {workflowRecords} = this.props;
		let distinctOwnerEmails = {};
		let distinctPartnerEmails = {};
		if (workflowRecords) {
			workflowRecords.forEach((workflowRecord) => {
				let row = workflowRecord.dto;
				let ownerEmail = row['OwnerId.email'] || row.customCrmObjectsJson?.OwnerId?.email;
				if (!distinctOwnerEmails[ownerEmail]) {
					distinctOwnerEmails[ownerEmail] = 0;
				}
				distinctOwnerEmails[ownerEmail]++;
				let partnerEmail = row['partnerowneridemail'] || row.partnerFieldsJson?.partnerowneridemail;
				if (!distinctPartnerEmails[partnerEmail]) {
					distinctPartnerEmails[partnerEmail] = 0;
				}
				distinctPartnerEmails[partnerEmail]++;
			});
		}
		return {distinctOwnerEmails: distinctOwnerEmails, distinctPartnerEmails: distinctPartnerEmails};
	}

	renderSteppingButtons() {
		let {actionListInfo, workflow} = this.props;
		let {editor, currentConfigStep} = this.state;
		let confirmed = actionListInfo.configuredWorkflows.indexOf(workflow.config) !== -1;
		if (confirmed) {
			return null;
		}
		let disableSave = !editor.validate(currentConfigStep);
		return (
			<div style={{display: 'flex', justifyContent: 'center', gap: 20, padding: 10, borderTop: '1px solid ' + partnerTapStroke}}>
				{currentConfigStep > 0 &&
				 <SecondaryButton label={'BACK'} icon={<ArrowBack/>} onClick={this.gotoPreviousStep}/>}
				{this.isConfirmStep ?
					<PrimaryButton label={'CONFIRM'} icon={<PlayArrow/>} onClick={this.confirmConfiguration}/> :
					<PrimaryButton label={'CONTINUE'} icon={<Check/>} disabled={disableSave} onClick={this.gotoNextStep}/>}
			</div>
		);
	}

	gotoPreviousStep() {
		let {steps, currentConfigStep} = this.state;
		this.updateSteps(steps, currentConfigStep - 1);
	}

	gotoNextStep() {
		let {steps, currentConfigStep} = this.state;
		this.updateSteps(steps, currentConfigStep + 1);
	}

	get isConfirmStep() {
		let {steps, currentConfigStep} = this.state;
		if (!steps) return false;
		return currentConfigStep === steps.length - 1;
	}

	confirmConfiguration() {
		let {actionListInfo, workflow} = this.props;
		actionListInfo.isLocked = true;
		actionListInfo.isActive = true;
		actionListInfo.status = WORKFLOW_STATUS_STARTED;
		actionListInfo.configuredWorkflows.push(workflow.config);
		actionListInfo.executedWorkflowDates[workflow.config] = Date.now();
		this.saveChanges();
	}

	render() {
		let {loading, steps, currentConfigStep, saving} = this.state;
		if (loading) return <Loading>Loading Editor...</Loading>;

		let {actionListInfo, workflow} = this.props;
		if (!isWorkflowAvailable(actionListInfo, workflow) && currentConfigStep === 0) {
			return (
				<div style={{display: 'flex'}}>
					<MessageBoxAlert title={'Workflow Unavailable'}>
						A conflicting workflow has already been started on this Action List.
					</MessageBoxAlert>
				</div>
			);
		}

		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				{saving &&
				 <ScrimMessage message={'Saving Changes...'}/>}
				<ScrollingContainer divId={'co_sell_configuration'} forceHeightOffset={true}>
					<Stepper steps={steps}/>
					<div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: 20, backgroundColor: partnerTapAppBackground, overflow: 'scroll'}}>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'top', gap: 20, height: '100vh'}}>
							{this.renderConfigStep()}
						</div>
					</div>
					{this.renderSteppingButtons()}
				</ScrollingContainer>
			</div>
		);
	}
}

CoSellWorkflowConfig.propTypes = {
	actionListInfo: PropTypes.object.isRequired,
	workflow: PropTypes.object.isRequired,
	workflowRecords: PropTypes.array
};

export default withRouter(CoSellWorkflowConfig);