// email invite responses
export const INVITE_SENT = 'INVITE_SENT';
export const INVITE_NOT_SENT = 'INVITE_NOT_SENT';
export const INVITE_NOT_SENT_REPEAT = 'INVITE_NOT_SENT_REPEAT';
export const INVITE_NOT_SENT_TOO_MANY = 'INVITE_NOT_SENT_TOO_MANY';
export const INVITE_BLOCKED_SETTINGS = 'INVITE_BLOCKED_SETTINGS';
export const INVITE_BLOCKED_BLACKLIST = 'INVITE_BLOCKED_BLACKLIST';
export const INVITE_BLOCKED_EMAIL_DOMAIN = 'INVITE_BLOCKED_EMAIL_DOMAIN';
export const PARTNER_INVITE_SENT = 'PARTNER_INVITE_SENT';
export const PARTNER_INVITE_NOT_SENT_REPEAT = 'PARTNER_INVITE_NOT_SENT_REPEAT';
export const PARTNER_INVITE_NOT_SENT_ALREADY_PARTNERS = 'PARTNER_INVITE_NOT_SENT_ALREADY_PARTNERS';
export const PARTNER_INVITE_NOT_SENT_ALREADY_DEAD_PARTNERS = 'PARTNER_INVITE_NOT_SENT_ALREADY_DEAD_PARTNERS';
export const PARTNER_INVITE_NOT_SENT_REVERSE_INVITE_ACCEPTED = 'PARTNER_INVITE_NOT_SENT_REVERSE_INVITE_ACCEPTED';

// partner sheets
export const PARTNER_SHEETS = 'PARTNER_SHEETS';

// partner invite types
export const INVITE_ADD = 'ADD';
export const INVITE_RECOMMENDATION = 'RECOMMENDATION';

// partner invite status
export const INVITE_STATUS_ACCEPTED = 'ACCEPTED';
export const INVITE_STATUS_DENIED = 'DENIED';
export const INVITE_STATUS_REQUESTED = 'REQUESTED';
export const INVITE_STATUS_INITIATED = 'INITIATED';
export const INVITE_STATUS_INITIATED_DENIED = 'INITIATED_DENIED';
export const INVITE_STATUS_UNPARTNERED = 'UNPARTNERED';
export const INVITE_STATUS_RETRACTED = 'RETRACTED';
export const INVITE_STATUS_RECOMMENDED = 'RECOMMENDED';
export const INVITE_STATUS_SENT = 'SENT';

// org/user status
export const STATUS_INACTIVE = 'INACTIVE';
export const STATUS_PREREGISTERED = 'PREREGISTERED';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_TERMINATED = 'TERMINATED';

// profile roles
// admin product
export const SUPERADMIN = 'SUPERADMIN';
export const CHANNEL_MANAGER = 'CHANNEL_MANAGER';
export const CHANNEL_ADMIN = 'CHANNEL_ADMIN';
export const CHANNEL_ADMIN_SALES_REP = 'CHANNEL_ADMIN_SALES_REP';
export const CHANNEL_SALES_REP = 'CHANNEL_SALES_REP';
export const ADMINISTRATOR = 'ADMINISTRATOR';
export const SALES_REP = 'SALES_REP';
export const SALES_ANALYTICS_MANAGER = 'SALES_ANALYTICS_MANAGER';
export const SALES_ANALYTICS_USER = 'SALES_ANALYTICS_USER';
export const SALES_ADMIN = 'SALES_ADMIN';
export const SALES_ADMIN_REP = 'SALES_ADMIN_REP';
// deprecated role
export const CHANNEL_MAPPER = 'CHANNEL_MAPPER';

// upload types
export const CHANNEL_MAP_V2_LOCATIONS = 'CHANNEL_MAP_V2_LOCATIONS'; // channel partner accounts by location
export const CHANNEL_MAP_V2_UNMATCHED = 'CHANNEL_MAP_V2_UNMATCHED'; // channel partner unmatched accounts
export const UPLOAD_PARTNER_SHEET = 'CHANNEL_MAP_V3'; // channel partner sheet accounts
export const UPLOAD_DATA_MANAGER_SHEET = 'CHANNEL_MAP_MANUAL'; // data manager sheet accounts
export const UPLOAD_DATA_MANAGER_ENRICH_SHEET = 'CHANNEL_MAP_MANUAL_AUGMENT'; // data manager sheet accounts augment
export const UPLOAD_PII_SHARING_OPT_IN = 'PII_SHARING_OPT_IN'; // data manager csv upload opt-in lists for PII
export const UPLOAD_FULL_SERVICE_CSV = 'FULL_SERVICE_CSV'; // data manager send full service csv
export const UPLOAD_PRE_REG_USERS = 'PRE_REG_USERS'; // admin pre-register user sheets
export const UPLOAD_RECOMMENDED_PARTNER_ORGS = 'RECOMMENDED_PARTNER_ORGS'; // admin partner org sheets
export const PRE_PROCESSED_BY_WEB_WORKER = 'PRE_PROCESSED_BY_WEB_WORKER';
// global report categories (THESE ARE STORED IN THE DATABASE AND MUST NOT CHANGE WITHOUT DATA MIGRATION)
export const CATEGORY_MAPPED_ACCOUNTS = 'mapped_accounts';
export const CATEGORY_GLOBAL_REPORTS = 'accounts';
export const CATEGORY_PARTNER_ACCOUNTS = 'Connected Partners Reports';
export const CATEGORY_PARTNER_LEADS = 'Connected Partners Lead Reports';
export const CATEGORY_PARTNER_OPPS = 'Connected Partners Opportunity Reports';
export const CATEGORY_COMPANY_PARTNER_ACCOUNTS = 'Partner Org Account Reports';
export const CATEGORY_COMPANY_PARTNER_OPPS = 'Partner Org Opportunity Reports';
export const CATEGORY_SHEET_ACCOUNTS = 'Spreadsheet Reports';
export const CATEGORY_SHEET_OPPS = 'Spreadsheet Opportunity Reports';

// download status
export const DOWNLOAD_STATUS_PROCESSED = 'PROCESSED';

// partnership action status
export const ALREADY_PARTNERED = 'ALREADY_PARTNERED';
export const REQUIRES_PARTNER_REQUEST = 'REQUIRES_PARTNER_REQUEST';
export const ACCEPT_PARTNER_REQUEST = 'ACCEPT_PARTNER_REQUEST';
export const REQUIRES_PARTNER_INVITE = 'REQUIRES_PARTNER_INVITE';

// special column names
export const COLUMN_KEY_ACCOUNT_NAME = 'accountName';
export const COLUMN_KEY_OPPORTUNITY_NAME = 'opportunityName';

// license codes
export const LICENCE_INTERNAL = 'INTERNAL';
export const LICENCE_FREE = 'FREE';
export const LICENCE_PAID = 'PAID';

// role types
export const ROLE_TYPE_STANDARD = 'STANDARD';
export const ROLE_TYPE_CUSTOM = 'CUSTOM';

// feature types
export const FEATURE_TYPE_ORG = 'ORG';
export const FEATURE_TYPE_ROLE = 'ROLE';

// asset types
export const ACCOUNTS_ASSET = 'ACCOUNTS_ASSET';
export const OPPORTUNITIES_ASSET = 'OPPORTUNITIES_ASSET';
export const ASSET_TYPES = [{label: ACCOUNTS_ASSET}, {label: OPPORTUNITIES_ASSET}];

// auth types
export const NONE = 'NONE';
export const DEMO = 'DEMO';
export const DEMO_PASSWORD = 'DEMO_PASSWORD';
export const SFDC = 'SFDC';
export const SFDC_SANDBOX = 'SFDC_SANDBOX';
export const MSFT = 'MSFT';
export const HUBSPOT = 'HUBSPOT';
export const OKTA = 'OKTA';
export const OKTA_REGISTER = 'OKTA_REGISTER';
export const OKTA_REACTIVATE = 'OKTA_REACTIVATE';

// crm/sheet asset source types
export const CRM_SALESFORCE = 'SFDC';
export const CRM_DYNAMICS = 'DYNAMICS';
export const CRM_HUBSPOT = 'HUBSPOT';
export const CRM_MANUAL = 'MANUAL';
export const SAML = 'SAML';

// sign up/login response codes
export const OK = 'OK';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const OKTA_ERROR = 'OKTA_ERROR';
export const INVALID_DATA = 'INVALID_DATA';
export const USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS';
export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const INVALID_SSO_FOR_ORG = 'INVALID_SSO_FOR_ORG';
export const PRE_REGISTRATION_REQUIRED = 'PRE_REGISTRATION_REQUIRED';

export const AUDIT_ENABLED = 'AUDIT_ENABLED';
export const AUDIT_COMPLETE = 'AUDIT_COMPLETE';
