import {Groups, Insights, JoinInner, ListAlt, People, VerifiedUser} from '@mui/icons-material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import DashboardTable from '../../../app/channel_ecosystem/shared/DashboardTable';
import DashboardTopItem from '../../../app/channel_ecosystem/shared/DashboardTopItem';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../globals/Routes';
import ChannelHelper, {
	REPORT_AUDIT,
	REPORT_BENEFICIAL_CUSTOMERS,
	REPORT_CUSTOMER_OPEN_PIPELINE,
	REPORT_GLOBAL_OVERLAP,
	REPORT_HOT_PROSPECTS,
	REPORT_MUTUAL_CUSTOMERS,
	REPORT_MUTUAL_PROSPECTS,
	REPORT_PROSPECT_OPEN_PIPELINE,
	REPORT_SEGMENT_GLOBAL,
	REPORT_SEGMENT_MID_MARKET,
	REPORT_SEGMENT_SMB,
	REPORT_SEGMENT_STARTUP
} from '../../../helpers/ChannelHelper';
import DialogHelper from '../../../helpers/DialogHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_VIEW_ECOSYSTEM_ACCOUNT_REPORTS} from '../../../helpers/FeatureHelper';
import {CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS} from '../../../helpers/ReportHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapPrimary, partnerTapSecondary, partnerTapWhite} from '../../../styles/partnertap_theme';
import SupportButton from '../../../ui/buttons/SupportButton';
import Dialog from '../../../ui/Dialog';
import GraphLegend from '../../../ui/GraphLegend';
import BarGraph from '../../../ui/graphs/BarGraph';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import {PageHeader} from '../../../ui/PageHeader';
import Upsell from '../../../ui/Upsell';

class EcosystemDashboardPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: false,
			showProcessingMessage: false,
			stackedCharts: [],
			dataTables: [],
			realTimeTables: [],
			connectedPartners: 0,
			channelUsers: 0,
			spreadsheetUploads: 0,
			totalAudits: 0
		};

		this.fetchDashboard = this.fetchDashboard.bind(this);
		this.onGraphClick = this.onGraphClick.bind(this);
		this.renderBarChartTip = this.renderBarChartTip.bind(this);
	}

	componentDidMount() {
		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_ECOSYSTEM_ACCOUNT_REPORTS)) {
			this.fetchDashboard();
		}
		else {
			setTimeout(() => this.setState({showUpsell: 'Ecosystem Dashboard'}));
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get accountsFoundColumnData() {
		return [
			{title: 'User', key: 'user', active: true},
			{title: 'Audit Count', key: 'numAudits', active: true},
			{title: 'Accounts Found', key: 'accountsFound', active: true}
		];
	}

	get dataTableColumnData() {
		return [
			{title: 'Partners', key: 'partnerOrg', active: true},
			{title: 'Account Count', key: 'count', active: true}
		];
	}

	fetchDashboard() {
		this.setState({loading: true});
		ChannelMappingEndpoints.getDashboard({channelReportType: CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS})
		.then((result) => {
			if (this.unmounted) return;
			if (!result) return;
			let {
				connectedPartnerCount,
				channelUserCount,
				partnerSpreadsheetCount,
				auditCount,
				overlapSummaryList,
				segmentOverlapSummaryList,
				hotProspectsList,
				prospectsByOpenPipelineList,
				customersByOpenPipelineList,
				auditBreakdownList
			} = result.payload;

			let stackedCharts = [];
			stackedCharts.push(
				this.createStackedBarChart(overlapSummaryList,
					[REPORT_MUTUAL_PROSPECTS, REPORT_MUTUAL_CUSTOMERS, REPORT_BENEFICIAL_CUSTOMERS, REPORT_HOT_PROSPECTS],
					JoinInner,
					'Overlap Summary',
					'Matched accounts grouped by account type'),
				this.createStackedBarChart(segmentOverlapSummaryList,
					[REPORT_SEGMENT_STARTUP, REPORT_SEGMENT_SMB, REPORT_SEGMENT_MID_MARKET, REPORT_SEGMENT_GLOBAL],
					JoinInner,
					'Segment Overlap',
					'Matched accounts grouped by market segment')
			);

			let dataTables = [];
			dataTables.push(
				this.createDataTable(hotProspectsList,
					this.dataTableColumnData,
					'Accounts that are our prospects and our partners\' customers',
					REPORT_HOT_PROSPECTS),
				this.createDataTable(prospectsByOpenPipelineList,
					this.dataTableColumnData,
					'Accounts that are our prospects with an open opp and our partners\' customers',
					REPORT_PROSPECT_OPEN_PIPELINE),
				this.createDataTable(customersByOpenPipelineList,
					this.dataTableColumnData,
					'Accounts that are mutual customers where we have an open opp',
					REPORT_CUSTOMER_OPEN_PIPELINE)
			);

			let realTimeTables = [
				this.createDataTable(auditBreakdownList,
					this.accountsFoundColumnData,
					'User audit counts and resulting new account matches',
					REPORT_AUDIT)
			];

			this.setState({
				loading: false,
				showProcessingMessage: !overlapSummaryList || overlapSummaryList.length === 0,
				stackedCharts: stackedCharts,
				dataTables: dataTables,
				realTimeTables: realTimeTables,
				connectedPartners: connectedPartnerCount,
				channelUsers: channelUserCount,
				spreadsheetUploads: partnerSpreadsheetCount,
				totalAudits: auditCount
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchDashboard', error);
		});
	}

	createDataTable(rowData, columnData, message, reportType) {
		let title = ChannelHelper.getReportTypeTitle(reportType);
		let drillDownEnabled = reportType !== REPORT_AUDIT;
		return (
			<DashboardTable key={'dashboard_table_' + reportType}
							MaterialIcon={ChannelHelper.getReportTypeIcon(reportType)}
							title={title}
							message={message}
							columnData={columnData}
							rowData={rowData}
							rowClick={drillDownEnabled ? (rowItem) => this.openDetailDialog(title, reportType, rowItem) : null}
							hasScrollingContainerParent={true}/>
		);
	}

	renderBarChartTip(payload) {
		return payload.active &&
			   <div style={{
				   color: partnerTapSecondary,
				   border: '1px solid ' + partnerTapSecondary,
				   backgroundColor: partnerTapWhite,
				   borderRadius: 3,
				   padding: 10
			   }}>
				   {payload.label}: <em>Press for details</em>
			   </div>;
	}

	createStackedBarChart(partnerDataList, reportTypes, MaterialIcon, title, description) {
		let legend = reportTypes.map((reportType) => {
			let totalValue = 0;
			partnerDataList.forEach((partnerData) => {
				totalValue += partnerData[ChannelHelper.getReportTypeColumn(reportType)];
			});
			return {name: ChannelHelper.getReportTypeTitle(reportType), fill: ChannelHelper.getReportTypeColor(reportType), value: totalValue};
		});

		return (
			<div key={title}
				 style={{
					 flex: 1,
					 backgroundColor: partnerTapWhite,
					 boxShadow: partnerTapDropShadow,
					 padding: 10,
					 marginBottom: 10,
					 marginLeft: 5,
					 marginRight: 5,
					 maxWidth: 500,
					 minWidth: 300
				 }}>
				<div style={{paddingLeft: 10, paddingBottom: 20}}>
					<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
						<MaterialIcon style={{color: partnerTapPrimary}} fontSize={'large'}/>
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<div style={{fontSize: 20}}>{title}</div>
							<div style={{color: partnerTapInactive}}>{description}</div>
						</div>
					</div>
				</div>
				<div style={{flex: 1, display: 'flex', fontSize: EnvHelper.isDesktop ? 12 : 10}}>
					<ResponsiveContainer minWidth={200} minHeight={partnerDataList.length * 60}>
						<BarChart data={partnerDataList} layout={'vertical'} margin={{right: 20, left: 20}} key={title}>
							<XAxis type={'number'}/>
							<YAxis type={'category'} dataKey={'partnerOrg'}/>
							<CartesianGrid strokeDasharray={'3 3'}/>
							<Tooltip allowEscapeViewBox={{x: false, y: true}} offset={20} content={this.renderBarChartTip}/>
							{reportTypes.map((reportType, index) => {
								return <Bar key={index}
											stackId={title}
											dataKey={ChannelHelper.getReportTypeColumn(reportType)}
											name={ChannelHelper.getReportTypeTitle(reportType)}
											fill={ChannelHelper.getReportTypeColor(reportType)}
											onClick={(partnerDataItem) => this.onGraphClick(partnerDataItem, reportTypes, reportType, title)}/>;
							})}
						</BarChart>
					</ResponsiveContainer>
					<GraphLegend sections={legend}/>
				</div>
			</div>
		);
	}

	openDetailDialog(title, reportType, payload) {
		let path = Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.REPORT.PATH(reportType);
		let org = encodeURIComponent(payload.partnerOrgCode && payload.partnerOrgCode.toLowerCase() || '');
		let params = '?partnerOrgCode=' + org + '&reportType=' + reportType;
		DialogHelper.openDetailDialog('Dashboard Detail', path, params, this.setState.bind(this));
	}

	onGraphClick(partnerDataItem, reportTypes, reportType, title) {
		let totalValue = 0;
		let sections = reportTypes.map((reportType) => {
			let value = partnerDataItem[ChannelHelper.getReportTypeColumn(reportType)];
			totalValue += value;
			return {
				reportType: reportType,
				value: value,
				name: ChannelHelper.getReportTypeTitle(reportType),
				fill: ChannelHelper.getReportTypeColor(reportType)
			};
		});
		sections.push({
			reportType: REPORT_GLOBAL_OVERLAP,
			value: totalValue,
			name: ChannelHelper.getReportTypeTitle(REPORT_GLOBAL_OVERLAP),
			fill: ChannelHelper.getReportTypeColor(REPORT_GLOBAL_OVERLAP)
		});

		let details =
			<BarGraph sections={sections}
					  onBarClick={(section) => this.openDetailDialog(title, section.reportType, partnerDataItem)}
					  noFrame={true}/>;
		let drillDownDialog =
			<Dialog title={title + ': ' + partnerDataItem.partnerOrg}
					message={details}
					yesAction={() => this.setState({drillDownDialog: null})}
					noContentPadding={true}/>;
		this.setState({drillDownDialog: drillDownDialog});
	}

	renderShowProcessingMessage() {
		return (
			<div style={{width: 600, backgroundColor: partnerTapWhite, boxShadow: partnerTapDropShadow, padding: 10, margin: 10}}>
				<div style={{padding: 20, paddingTop: 10}}>
					<div style={{fontSize: 20, fontWeight: 'bold'}}>
						Not Enough Data
					</div>
					<div style={{paddingTop: 10, color: partnerTapInactive}}>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							We don't have enough information to provide your Ecosystem Dashboard at the moment.
							Dashboard data is refreshed every 24 hours. Please reach out to us if this seems like an error.
							<div style={{paddingTop: 10}}>
								<SupportButton/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.state.showUpsell) return <Upsell showUpsell={this.state.showUpsell} useScrollingContainer={true}/>;
		if (this.state.loading) return <Loading>Loading Dashboard...</Loading>;
		let {connectedPartners, channelUsers, spreadsheetUploads, totalAudits, stackedCharts, dataTables, realTimeTables} = this.state;
		return (
			<div>
				<PageHeader title={'Ecosystem Dashboard'} icon={<Insights/>}/>
				<ScrollingContainer divId={'ecosystem_dashboard'}>
					<div style={{marginLeft: 5, marginRight: 5, overflow: 'scroll'}}>
						<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'center'}}>
							<DashboardTopItem MaterialIcon={People}
											  title={'Partner Connections'}
											  message={'Total connected partners in PartnerTap'}
											  value={connectedPartners}/>
							<DashboardTopItem MaterialIcon={Groups}
											  title={'Channel Users'}
											  message={'Total channel users in PartnerTap'}
											  value={channelUsers}/>
							<DashboardTopItem MaterialIcon={ListAlt}
											  title={'Partner Sheets'}
											  message={'Total partner sheets uploaded'}
											  value={spreadsheetUploads}/>
							<DashboardTopItem MaterialIcon={VerifiedUser}
											  title={'Total Audits'}
											  message={'Total audits completed'}
											  value={totalAudits}/>
						</div>
						<div>
							<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
								{this.state.showProcessingMessage ? this.renderShowProcessingMessage() : stackedCharts}
							</div>
							<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
								{dataTables}
							</div>
							<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
								{realTimeTables}
							</div>
						</div>
					</div>
					{this.state.detailDialog}
					{this.state.drillDownDialog}
				</ScrollingContainer>
			</div>
		);
	}
}

export default withRouter(connect()(EcosystemDashboardPage));
