import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FixedSizeList} from 'react-window';
import {partnerTapWhite} from '../../../styles/partnertap_theme';

class CoSellEmailVerificationBox extends Component {

	constructor(props, context) {
		super(props, context);

		this.renderRow = this.renderRow.bind(this);
	}

	renderRow(props) {
		const {index, style} = props;
		let {emailAddresses} = this.props;
		return (
			<ListItem key={index} style={style} component={'div'}>
				<ListItemText primary={emailAddresses[index]}/>
			</ListItem>
		);
	}

	render() {
		let {emailAddresses} = this.props;
		let itemSize = 30;
		let width = 360;
		let height = Math.min(emailAddresses.length, 5) * itemSize;
		return (
			<Box sx={{width: '100%', height: height, maxWidth: width, bgcolor: partnerTapWhite}}>
				<FixedSizeList height={height} width={width} itemSize={itemSize} itemCount={emailAddresses.length} overscanCount={5}>
					{this.renderRow}
				</FixedSizeList>
			</Box>
		);
	}
}

CoSellEmailVerificationBox.propTypes = {
	emailAddresses: PropTypes.array.isRequired
};

export default CoSellEmailVerificationBox;
