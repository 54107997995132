import React from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapWhite} from '../../../styles/partnertap_theme';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import CoSellActionListMetadataLive from './co_sell_components/CoSellActionListMetadataLive';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {CHANNEL_REPORT_TYPE_CO_SELL_VIEW_ACTION_LIST} from '../../../helpers/ReportHelper';
import DownloadHelper from '../../../helpers/DownloadHelper';
import DownloadButton from '../../../ui/buttons/DownloadButton';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import CoSellActionListWorkflowSelector from './co_sell_components/CoSellActionListWorkflowSelector';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import {Routes} from '../../../globals/Routes';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import {canUserEditActionLists} from './CoSellEngineHelperLive';
import ActionButton from '../../../ui/buttons/ActionButton';
import {ChecklistRtl, UploadFile} from '@mui/icons-material';
import MessageBoxAlert from '../../../ui/messages/MessageBoxAlert';

const ACTION_DELETE = 'delete';
const ACTION_RESTORE = 'restore';

class CoSellActionListPageLive extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.showRemoved = false;
		this.state.loadingMetadata = true;

		this.getActionList = this.getActionList.bind(this);
		this.updateSelectAllCheckbox = this.updateSelectAllCheckbox.bind(this);
		this.updateAllRows = this.updateAllRows.bind(this);
		this.updateRow = this.updateRow.bind(this);
		this.applyActionToSelectedRecords = this.applyActionToSelectedRecords.bind(this);
	}

	componentDidMount() {
		this.getActionList();
	}

	getActionList() {
		Promise.all([
			CoSellEngineEndpoints.getCoSellActionListMetadata(this.cosellActionListMetadataPublicId),
			CoSellEngineEndpoints.getActiveActionListWorkflow(this.cosellActionListMetadataPublicId)
		])
		.then((results) => {
			if (this.unmounted) return;
			const actionListMetadata = results[0].payload;
			const activeWorkflow = results[1].payload instanceof Array ? null : results[1].payload;
			this.setState({actionListMetadata: actionListMetadata, activeWorkflow: activeWorkflow, loadingMetadata: false});
			this.fetchColumns();
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionListMetadata', error);
			this.setState({loadingMetadata: false, error: true});
		});
	}

	get cosellActionListMetadataPublicId() {
		return this.props.match.params.actionListId;
	}

	get isActionListUsed() {
		return Boolean(this.state.activeWorkflow);
	}

	get canUserEditActionLists() {
		let {actionListMetadata} = this.state;
		const {authState} = this.props;
		return canUserEditActionLists(actionListMetadata, authState);
	}

	get storageKeyBase() {
		return 'co_sell_action_list';
	}

	get title() {
		let {showRemoved} = this.state;
		let options = [{key: false, label: 'Action List Records'}, {key: true, label: 'Removed Records'}];
		return (
			<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 340, height: 55}}>
				{this.actionRowCount ?
					this.actionButtons :
					<DropdownMenu options={options}
								  selectedOption={options[showRemoved ? 1 : 0]}
								  onSelect={(option) => this.setState({showRemoved: option.key, loading: true})}/>}
			</div>);
	}

	get actionButtons() {
		let countSelectedForRemove = 0;
		let countSelectedForRestore = 0;
		let {rowData, showRemoved} = this.state;
		rowData.forEach((row) => {
			if (row.applyAction) {
				if (row.is_deleted) {
					countSelectedForRestore++;
				}
				else {
					countSelectedForRemove++;
				}
			}
		});
		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 10, paddingLeft: 10}}>
				<div style={{fontSize: 16}}>
					{countSelectedForRemove || countSelectedForRestore} selected
				</div>
				{showRemoved ?
					<PrimaryButton label={'RESTORE'}
								   disabled={!countSelectedForRestore}
								   onClick={() => this.applyActionToSelectedRecords(ACTION_RESTORE)}/>
					:
					<PrimaryButton label={'REMOVE'}
								   disabled={!countSelectedForRemove}
								   onClick={() => this.applyActionToSelectedRecords(ACTION_DELETE)}/>}
				<SecondaryButton label={'CANCEL'} onClick={() => this.updateAllRows(false)}/>
			</div>
		);
	}

	get icon() {
		return null;
	}

	fetchColumns() {
		ChannelMappingEndpoints.getColumns({
			channelReportType: CHANNEL_REPORT_TYPE_CO_SELL_VIEW_ACTION_LIST,
			coSellActionListMetadataPublicId: this.cosellActionListMetadataPublicId
		})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getGlobalReportCompanyAssetColumns', error);
		});
	}

	get additionalToolbarButtons() {
		return [
			<ActionButton icon={<ChecklistRtl/>}
						  toolTip={'Manually modify action list'}
						  disabled={this.isActionListUsed || !this.canUserEditActionLists}
						  key={"manually_modify_action_list"}
						  onAction={() => {
							  const isInManualEdit = !!this.state.columnData.find(column => column.key === ACTIONS);
							  const columnData = [...this.state.columnData];
							  if (isInManualEdit) {
								  this.updateAllRows(false);
								  columnData.shift();
							  }
							  else {
								  columnData.unshift({
									  key: ACTIONS,
									  title: <CheckboxButton style={{color: partnerTapWhite}} onChange={(event) => this.updateAllRows(event.target.checked)}/>,
									  renderFunction: (columnValue, rowData) =>
										  <div style={{display: 'flex', justifyContent: 'center'}}>
											  <CheckboxButton checked={rowData.applyAction} onClick={() => this.updateRow(rowData)}/>
										  </div>,
									  active: true
								  });
							  }
							  this.setState({columnData: columnData});
						  }}/>,
			<ActionButton icon={<UploadFile/>}
						  toolTip={'Bulk modify action list via CSV upload\n'}
						  disabled={this.isActionListUsed || !this.canUserEditActionLists}
						  key={"bulk_modify"}
						  onAction={() => {
							  EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_EDIT_LIVE.PATH(this.props.match.params.actionListId));
						  }}/>,
			<DownloadButton key={'download_button'} callingPage={this}/>
		];
	}

	updateSelectAllCheckbox(checked) {
		this.columnData[0].title =
			<CheckboxButton checked={checked} style={{color: partnerTapWhite}} onChange={(event) => this.updateAllRows(event.target.checked)}/>;
	}

	updateAllRows(checked) {
		let {rowData} = this.state;
		rowData.forEach((row) => row.applyAction = checked);
		this.updateSelectAllCheckbox(checked);
		this.forceUpdate();
	}

	updateRow(row) {
		row.applyAction = !row.applyAction;
		this.forceUpdate();
	}

	get actionRowCount() {
		let actionCount = 0;
		let {rowData} = this.state;
		rowData.forEach((row) => row.applyAction ? actionCount++ : null);
		return actionCount;
	}

	getActionRowPublicIds(actionToApply) {
		let {rowData} = this.state;
		let actionRowPublicIds = [];
		rowData.forEach((row) => {
			if (row.applyAction &&
				((actionToApply === ACTION_DELETE && !row.is_deleted) ||
				 (actionToApply === ACTION_RESTORE && row.is_deleted))) {
				actionRowPublicIds.push(row.public_id);
			}
		});
		return actionRowPublicIds;
	}

	applyActionToSelectedRecords(actionToApply) {
		this.setState({saving: true});
		let actionRowPublicIds = this.getActionRowPublicIds(actionToApply);
		CoSellEngineEndpoints.updateCoSellActionListRecords(this.cosellActionListMetadataPublicId, actionToApply, actionRowPublicIds)
		.then((result) => {
			if (this.unmounted) return;
			this.updateSelectAllCheckbox(false);
			this.setState({loading: true, saving: false});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from applyActionToSelectedRecords', error);
		});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let {showRemoved} = this.state;
		Object.assign(params.filters, {is_deleted: showRemoved});
		ChannelMappingEndpoints.getRecords(
			{
				channelReportType: CHANNEL_REPORT_TYPE_CO_SELL_VIEW_ACTION_LIST,
				coSellActionListMetadataPublicId: this.cosellActionListMetadataPublicId
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
					ChannelHelper.processRowData(result.payload, this.reportType);
				}
				result.payload.forEach((row) => row.applyAction = false);
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {showRemoved} = this.state;
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						{showRemoved ? 'No records have been removed from this action list' : 'All records have been removed from this action list'}
					</div>
					{showRemoved ?
						<PrimaryButton label={'SHOW ACTION LIST RECORDS'} onClick={() => this.setState({showRemoved: false, loading: true})}/> :
						<PrimaryButton label={'SHOW REMOVED RECORDS'} onClick={() => this.setState({showRemoved: true, loading: true})}/>}
				</div>
			);
		}
		return super.noDataMessage;
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		let {actionListMetadata} = this.state;
		let title = actionListMetadata.name;
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			channelReportType: CHANNEL_REPORT_TYPE_CO_SELL_VIEW_ACTION_LIST,
			coSellActionListMetadataPublicId: this.cosellActionListMetadataPublicId,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: 'action_list_' + title,
			exportFields: DownloadHelper.getFields(this.state.columnData),
			selectedColumns: this.activeColumns,
			totalElements: '0',
			mapByLocations: false,
			personQueueIds: []
		};
	}

	render() {
		let {actionListMetadata, activeWorkflow, saving, loadingMetadata, error} = this.state;
		if (loadingMetadata) return <Loading>Loading Action List...</Loading>;
		if (error) return <MessageBoxAlert>An unexpected error occurred.</MessageBoxAlert>;
		return (
			<div key={this.actionRowCount}>
				{saving &&
				 <ScrimMessage message={'Saving...'}/>}
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
					<CoSellActionListMetadataLive actionListMetadata={actionListMetadata} activeWorkflow={activeWorkflow}/>
					<CoSellActionListWorkflowSelector actionListMetadata={actionListMetadata}/>
				</div>
				{super.render()}
			</div>
		);
	}
}

CoSellActionListPageLive.propTypes = {
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(CoSellActionListPageLive));
