import {Add, LocalOffer} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Dialog from '../../ui/Dialog';
import PagingBase from '../../ui/lists/PagingBase';
import PopoverSearchList from '../../ui/PopoverSearchList';
import OrgSelector from './shared/OrgSelector';
import LinkButton from '../../ui/buttons/LinkButton';

class AdminOrgAccountTagsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.selectedOrg = null;
		this.state.partnerOrg = null;
		this.state.tag = null;

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
		this.handlePartnerOrgSelection = this.handlePartnerOrgSelection.bind(this);
		this.onPartnerTagSelection = this.onPartnerTagSelection.bind(this);
		this.saveTag = this.saveTag.bind(this);
	}

	handleOrgSelection(org) {
		this.setState({selectedOrg: org, loading: true});
	}

	get storageKeyBase() {
		return 'tools_admin_org_account_tags';
	}

	get title() {
		return 'Org Account Tags';
	}

	get icon() {
		return LocalOffer;
	}

	get additionalToolbarButtons() {
		return [
			<PrimaryButton key={'add_tag_button'}
						   rowDataNotRequired={true}
						   label={'Add Tag'}
						   disabled={!this.state.selectedOrg}
						   icon={<Add/>}
						   onClick={() => this.setState({addTag: true})}/>
		];
	}

	get columnData() {
		return [
			{title: 'PartnerTap Org', objectKey: 'partnerOrg', key: 'name'},
			{title: 'Tag', key: 'tag'},
			{title: 'Created', key: 'createdDate', type: 'date'},
			{
				title: 'Remove',
				key: 'remove',
				renderFunction: (columnValue, rowData) =>
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<LinkButton label={'REMOVE'} onClick={() => this.removeTag(rowData.id)}/>
					</div>
			}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {selectedOrg} = this.state;
		let params = this.processParameters();
		Promise.all([AdminEndpoints.fetchAllOrgAccountTags(selectedOrg.orgId), AdminEndpoints.fetchAllAvailableAccountTags(selectedOrg.orgId)])
		.then((results) => {
			if (this.unmounted) return;
			this.setState({availableTags: results[1].payload || []});
			this.processData(params, {payload: results[0].payload});
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						This org has no tags.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	removeTag(tagId) {
		AdminEndpoints.deleteAccountTag(tagId)
		.then((result) => {
			this.setState({loading: true});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from deleteAccountTag ' + tagId, error);
		});
	}

	saveTag() {
		let {orgId, partnerOrgId, tag} = this.state;
		if (!partnerOrgId || !tag) {
			this.setState({addTag: false, partnerOrgId: 0, tag: null});
			console.warn('Warning: You must select a partner and tag');
			return;
		}
		AdminEndpoints.saveAccountTag(orgId, partnerOrgId, tag)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({loading: true, addTag: false, partnerOrgId: 0, tag: null});
		})
		.catch((error) => {
			this.setState({loading: true, addTag: false, partnerOrgId: 0, tag: null});
			EnvHelper.serverError('Error from saveAccountTag', error);
		});
	}

	handlePartnerOrgSelection(org) {
		this.setState({partnerOrg: org});
	}

	onPartnerTagSelection(tag) {
		this.setState({tag: tag});
	}

	get dialog() {
		if (this.state.addTag) {
			let {availableTags, tag} = this.state;
			return (
				<Dialog title={'Account Tags'}
						message={
							<div>
								{availableTags && availableTags.length > 0 ?
									<div style={{alignItems: 'center'}}>
										<div style={{padding: 5}}>
											<PopoverSearchList label={'Select an Account Tag'}
															   list={availableTags}
															   preselectedItem={tag}
															   minWidth={300}
															   onItemSelected={this.onPartnerTagSelection}/>
										</div>
										<div style={{padding: 5}}>
											<OrgSelector onOrgSelection={this.handlePartnerOrgSelection}/>
										</div>
									</div>
									:
									<div style={{textAlign: 'center', backgroundColor: partnerTapWhite, padding: 10}}>
										No account tags found for this org.
									</div>
								}
							</div>
						}
						yesLabel={'SAVE'}
						noLabel={'CANCEL'}
						yesAction={() => {
							this.saveTag();
						}}
						noAction={() => {
							this.setState({addTag: false, partnerOrg: null, tag: null});
						}}/>
			);
		}
		return null;
	}

	render() {
		return (
			<div>
				<div style={{display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: partnerTapWhite}}>
					<OrgSelector onOrgSelection={this.handleOrgSelection}/>
				</div>
				{Boolean(this.state.selectedOrg) && super.render()}
				{this.dialog}
			</div>
		);
	}
}

export default connect()(AdminOrgAccountTagsPage);
