import PropTypes from 'prop-types';
import React, {Component} from 'react';
import NumberHelper from '../helpers/NumberHelper';
import {partnerTapPrimary, partnerTapWhite} from '../styles/partnertap_theme';

class ItemCount extends Component {

	render() {
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: this.props.backgroundColor || partnerTapPrimary,
				minWidth: 32,
				height: 32,
				borderRadius: 16,
				fontSize: 16,
				fontWeight: 'bold',
				color: partnerTapWhite
			}}>
				<div data-cy={'item_count'} style={{padding: this.props.customContent ? 0 : 10}}>
					{this.props.customContent || NumberHelper.formatNumber(this.props.count)}
				</div>
			</div>
		);
	}
}

ItemCount.propTypes = {
	count: PropTypes.number.isRequired,
	backgroundColor: PropTypes.string,
	customContent: PropTypes.element
};

export default ItemCount;
