import CheckIcon from '@mui/icons-material/Check';
import {Star} from '@mui/icons-material';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as ProfileEndpoints from '../../../endpoints/ProfilesEndpoints';
import {partnerTapPrimary, partnerTapPrimaryLight, partnerTapPrimaryMedium, partnerTapTernary, partnerTapWhite} from '../../../styles/partnertap_theme';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import PageHeader from '../../../ui/PageHeader';
import LinkButton from '../../../ui/buttons/LinkButton';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import {UPLOAD_DATA_MANAGER_SHEET, UPLOAD_PARTNER_SHEET} from '../../../globals/Enums';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';

const tileStyle = {
	minWidth: 315,
	maxWidth: 350,
	minHeight: 200,
	margin: 10,
	backgroundColor: partnerTapWhite,
	border: '1px solid ' + partnerTapPrimary,
	borderRadius: 10,
	overflow: 'auto'
};
const tileContentStyle = {display: 'flex', flexDirection: 'column', gap: 20, padding: 10};

export class GettingStartedWizard extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: true,
			isCRMConnected: false,
			isAdmin: false,
			isManualUploadProcessed: false,
			isOrientationLandscape: this.checkOrientationLandscape()
		};
		this.fetchWizardStatus = this.fetchWizardStatus.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		this.fetchWizardStatus();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.unmounted = true;
	}

	handleResize() {
		this.setState({isOrientationLandscape: this.checkOrientationLandscape()});
	}

	fetchWizardStatus() {
		ProfileEndpoints.getWizardData()
		.then((result) => {
			if (this.unmounted) return;
			this.setState({
				loading: false,
				isAdmin: result.payload?.admin,
				isCRMConnected: result.payload?.crmSynced,
				isManualUploadProcessed: result.payload?.manualUploadProcessed
			});
		})
		.catch((error) => {
			console.error('Error fetchWizardStatus', error);
		});
	}

	checkOrientationLandscape() {
		return window.innerWidth > 1285;
	}

	get step1() {
		let {isAdmin, isCRMConnected, isManualUploadProcessed} = this.state;
		return (
			<div style={tileStyle}>
				{isAdmin ?
					<Fragment>
						{this.getTileHeader(1, 'Add Your Base Data', partnerTapPrimaryLight)}
						<div style={tileContentStyle}>
							{this.getTileContent('Connect and sync your account data via CRM', 'CRM SYNC', Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE, isCRMConnected ? 'CONNECTED' : '')}
							<div style={{
								fontWeight: 'bold', fontFamily: 'sans-serif',
								margin: '-10px 0px -10px 50px'
							}}> or
							</div>
							{this.getTileContent('Upload your account data via CSV', 'ADD ACCOUNTS', Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_DATA_MANAGER_SHEET), isManualUploadProcessed ? 'UPLOADED' : '')}
						</div>
					</Fragment>
					:
					<Fragment>
						{this.getTileHeader(1, 'Upload Partner Sheets', partnerTapPrimaryLight)}
						<div style={tileContentStyle}>
							{this.getTileContent('Upload partner sheets to immediately identify joint opportunities', 'UPLOAD PARTNER DATA', Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_PARTNER_SHEET))}
						</div>
					</Fragment>}
			</div>
		);
	}

	get step2() {
		let {isAdmin} = this.state;
		return (
			<div style={tileStyle}>
				{isAdmin ?
					<Fragment>
						{this.getTileHeader(2, 'Add Partner Data', partnerTapPrimaryMedium)}
						<div style={tileContentStyle}>
							{this.getTileContent('Upload data from your partner', 'UPLOAD PARTNER DATA', Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(UPLOAD_PARTNER_SHEET))}
							<div style={{fontWeight: 'bold', fontFamily: 'sans-serif', margin: '-10px 0px -10px 50px'}}> and/or</div>
							{this.getTileContent('Connect with partners', 'INVITE PARTNERS', Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH())}
						</div>
					</Fragment>
					:
					<Fragment>
						{this.getTileHeader(2, 'Connect With Partners', partnerTapPrimaryMedium)}
						<div style={tileContentStyle}>
							{this.getTileContent('Inviting partners to join you on PartnerTap is the best path to long term co-selling success', 'INVITE PARTNERS', Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH())}
						</div>
					</Fragment>}
			</div>
		);
	}

	get step3() {
		let {isAdmin} = this.state;
		return (
			<div style={tileStyle}>
				{this.getTileHeader(3, 'Analyze Mapped Data', partnerTapPrimary)}
				<div style={tileContentStyle}>
					{isAdmin ? '' : 'Once you have partner data inside PartnerTap use reports to zero in on the best accounts to action'}
					<LinkButton label={'How to view summary dashboards'}
								onClick={() => window.open('https://partnertap.com/help/how-to-view-connected-partner-account-overlap-dashboard/')}
								hasExternalLinkIcon={true}/>
					<LinkButton label={'How to view account reports'}
								onClick={() => window.open('https://partnertap.com/help/how-to-analyze-your-connected-partners-reports/')}
								hasExternalLinkIcon={true}/>
				</div>
			</div>
		);
	}

	getTileHeader = (ordinal, title, backgroundColor) => (
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			color: partnerTapWhite,
			backgroundColor: backgroundColor,
			padding: 10,
			fontSize: 16,
			fontWeight: 'bold'
		}}>
			{ordinal}. {title}
		</div>
	);

	getTileContent(title, linkLabel, route, successContent) {
		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
				{title}
				<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
					<LinkButton label={linkLabel} onClick={() => EnvHelper.push(route)}/>
					{successContent &&
					 <div style={{color: partnerTapTernary, fontFamily: 'sans-serif', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
						 {successContent} {<CheckIcon/>}
					 </div>}
				</div>
			</div>
		);
	}

	render() {
		let {loading, isOrientationLandscape, isAdmin} = this.state;
		if (loading) return <Loading>Loading Data...</Loading>;
		return (
			<ScrollingContainer divId={'channel_wizard_page'}>
				<PageHeader title={'Getting Started'} icon={<Star fontSize={'large'}/>}/>
				<div style={{display: 'flex', flexDirection: 'column', overflow: 'scroll'}}>
					<MessageBoxInfo title={'To start account mapping and driving more revenue with your partners, follow the steps below.'} hideIcon={true}>
						{!isAdmin &&
						 <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							 <div>
								 Your account data has already been loaded.
								 To view it, go to <LinkButton label={'My Accounts'}
															   onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.ROUTE)}/>
							 </div>
						 </div>}
						<div>
							For additional guidance and resources check out our <LinkButton label={'Help Center'}
																							onClick={() => window.open('https://partnertap.com/help-center/')}
																							hasExternalLinkIcon={true}/>
						</div>
					</MessageBoxInfo>
					<div style={{display: 'flex', flexDirection: isOrientationLandscape ? 'row' : 'column', alignItems: 'center', justifyContent: 'center'}}>
						{this.step1}
						{this.step2}
						{this.step3}
					</div>
				</div>
				<div style={{padding: 5}}/>
			</ScrollingContainer>
		);
	}
}

export default connect()(GettingStartedWizard);
