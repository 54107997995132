import EnvHelper from '../helpers/EnvHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';
import * as ReduxEnum from './ReduxEnum';

export function loginPerson(person) {
	if (!Object.prototype.hasOwnProperty.call(person, 'firstLogin')) {
		person.firstLogin = false;
	}
	return {type: ReduxEnum.LOGIN_PERSON, person};
}

export function loginProfile(profile) {
	return {type: ReduxEnum.LOGIN_PROFILE, profile};
}

export function channelWizardSteps(channelWizardSteps) {
	return {type: ReduxEnum.CHANNEL_WIZARD_STEPS, channelWizardSteps};
}

export function clearFirstLoginMessage(person) {
	person.firstLogin = false;
	return {type: ReduxEnum.CLEAR_FIRST_LOGIN_MESSAGE, person};
}

export function logout() {
	PersistenceHelper.clearClient();
	EnvHelper.token = '';
	return {type: ReduxEnum.LOGOUT};
}

export function agreeToTerms() {
	return {type: ReduxEnum.AGREE_TO_TERMS};
}

export function serverError(errorOrigin, errorMessage, httpCode) {
	return {type: ReduxEnum.SERVER_ERROR, errorOrigin: errorOrigin, errorMessage: errorMessage, httpCode: httpCode};
}

export function clearServerError() {
	return {type: ReduxEnum.CLEAR_SERVER_ERROR};
}

export function setWelcomeMode(welcomeMode) {
	return {type: ReduxEnum.WELCOME_MODE, welcomeMode};
}

export function newVersionAvailable(status) {
	return {type: ReduxEnum.NEW_VERSION_AVAILABLE, status};
}

export function setChannelSubordinates(channelSubordinates) {
	return {type: ReduxEnum.SET_CHANNEL_SUBORDINATES, channelSubordinates};
}

export function refreshApp() {
	return {type: ReduxEnum.REFRESH_APP};
}
