import React, {Fragment} from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import InfoButton from '../../../../ui/buttons/InfoButton';
import DropdownMenu from '../../../../ui/selectors/DropdownMenu';
import CoSellEmailVerificationBox from '../CoSellEmailVerificationBox';
import * as CoSellEngineHelper from '../CoSellEngineHelper';
import {
	crmTrackingOptions,
	nudgeOptions,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_NONE,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN
} from '../CoSellEngineHelper';

export class WorkflowIntroEmail {

	constructor(actionListInfo, workflow, collatedWorkflowRecords) {
		this.actionListInfo = actionListInfo;
		this.workflow = workflow;
		this.collatedWorkflowRecords = collatedWorkflowRecords;

		this.getCurrentSequence = this.getCurrentSequence.bind(this);
		this.onSelectSequence = this.onSelectSequence.bind(this);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsIntroEmail;
	}

	get steps() {
		return [{label: 'Sequence'}, {label: 'Recipients'}, {label: 'Messages'}, {label: 'Follow Ups'}];
	}

	getCurrentSequence(adminSequenceConfig) {
		return adminSequenceConfig.workflowSequenceIntroEmail === this.actionListInfo.workflowSequenceIntroEmail;
	}

	onSelectSequence(adminSequenceConfig) {
		this.actionListInfo.workflowSequenceIntroEmail = adminSequenceConfig.workflowSequenceIntroEmail;
	}

	get isUnavailable() {
		return null;
	}

	configureEmail(emailWorkflowEditor, currentConfigStep) {
		let {workflowConfigEmailIntroEmail, workflowSequenceIntroEmail} = this.actionListInfo;
		switch (currentConfigStep) {
			case 1: {
				return (
					<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
						{emailWorkflowEditor.renderFromEditor()}
						{emailWorkflowEditor.renderToOwnerEditor()}
						{emailWorkflowEditor.renderToPartnerEditor()}
					</div>
				);
			}
			case 2: {
				let sequenceOrdinal = 1;
				return (
					<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
						{(workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN ||
						  workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN) &&
						 emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailIntroEmail, workflowSequenceIntroEmail, sequenceOrdinal++)}
						{(workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN) &&
						 emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailIntroEmail, workflowSequenceIntroEmail, sequenceOrdinal++)}

						{(workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN ||
						  workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN) &&
						 emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailIntroEmail, workflowSequenceIntroEmail, sequenceOrdinal++)}
						{(workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN) &&
						 emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailIntroEmail, workflowSequenceIntroEmail, sequenceOrdinal++)}

						{emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailIntroEmail, workflowSequenceIntroEmail, sequenceOrdinal)}
					</div>
				);
			}
			case 3: {
				let {introEmailNudgeReminderDays, introEmailNudgeLastChanceDays, introEmailCrmTask} = this.actionListInfo;
				let remindOption = nudgeOptions.find((option) => option.value === introEmailNudgeReminderDays);
				let lastChanceOption = nudgeOptions.find((option) => option.value === introEmailNudgeLastChanceDays);
				let crmTrackingOption = crmTrackingOptions.find((option) => option.value === introEmailCrmTask);
				return (
					<div style={{display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'top', justifyContent: 'center'}}>
						{workflowSequenceIntroEmail !== WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO &&
						 <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							 <div style={{display: 'flex', alignItems: 'center'}}>
								 Send nudges if recipient has not responded
								 <InfoButton message={'All parties that have not responded will receive the opt-in email again with REMINDER or LAST CHANCE added to the subject line.'}/>
							 </div>
							 <DropdownMenu title={'Remind in'} options={nudgeOptions} selectedOption={remindOption} onSelect={(option) => {
								 this.actionListInfo.introEmailNudgeReminderDays = option.value;
								 emailWorkflowEditor.forceUpdate();
							 }}/>
							 <DropdownMenu title={'Last Chance in'} options={nudgeOptions} selectedOption={lastChanceOption} onSelect={(option) => {
								 this.actionListInfo.introEmailNudgeLastChanceDays = option.value;
								 emailWorkflowEditor.forceUpdate();
							 }}/>
						 </div>}
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							<div style={{display: 'flex', alignItems: 'center'}}>
								Create new task in CRM after successful intro
								<InfoButton message={'A successful intro occurs when both parties have opted-in to at least one common account.'}/>
							</div>
							<DropdownMenu title={'Track in CRM'} options={crmTrackingOptions} selectedOption={crmTrackingOption} onSelect={(option) => {
								this.actionListInfo.introEmailCrmTask = option.value;
								emailWorkflowEditor.forceUpdate();
							}}/>
						</div>
					</div>
				);
			}
		}
	}

	validate(currentWorkflowStep) {
		let {workflowConfigEmailIntroEmail, workflowSequenceIntroEmail} = this.actionListInfo;
		switch (currentWorkflowStep) {
			case 0:
				return workflowSequenceIntroEmail !== WORKFLOW_SEQUENCE_INTRO_EMAIL_NONE;
			case 1:
			case 2:
				return CoSellEngineHelper.validateWorkflowConfigEmail(workflowConfigEmailIntroEmail, true, true);
			case 3:
				return true;
		}
	}

	confirm() {
		let ownerEmailAddresses = Object.keys(this.collatedWorkflowRecords.distinctOwnerEmails);
		let partnerEmailAddresses = Object.keys(this.collatedWorkflowRecords.distinctPartnerEmails);
		return (
			<Fragment>
				<div>
					Send to {ownerEmailAddresses.length} Sales Rep{ownerEmailAddresses.length === 1 ? '' : 's'}
				</div>
				<CoSellEmailVerificationBox emailAddresses={ownerEmailAddresses}/>
				<div>
					Send to {partnerEmailAddresses.length} Partner{partnerEmailAddresses.length === 1 ? '' : 's'}
				</div>
				<CoSellEmailVerificationBox emailAddresses={partnerEmailAddresses}/>
			</Fragment>
		);
	}
}
