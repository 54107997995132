import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {PERSON_QUEUE_STATUS_PROCESSING, PERSON_QUEUE_STATUS_READY} from '../../helpers/UploadHelper';
import {AUDIT_COMPLETE, AUDIT_ENABLED} from '../../globals/Enums';
import {partnerTapDefaultText, partnerTapInactive} from '../../styles/partnertap_theme';

class SheetAuditLabel extends Component {

	render() {
		let {rowData} = this.props;
		let auditButtonLabel = 'Not audited';
		let isProcessing = rowData.status === PERSON_QUEUE_STATUS_PROCESSING || rowData.status === PERSON_QUEUE_STATUS_READY || rowData.auditStatus === AUDIT_ENABLED;
		if (rowData.auditStatus === AUDIT_ENABLED) {
			auditButtonLabel = 'Auditing';
		}
		else if (rowData.auditStatus === AUDIT_COMPLETE) {
			auditButtonLabel = 'Audited';
		}

		return (<div style={{display: 'flex', color: isProcessing ? partnerTapInactive : partnerTapDefaultText}}>
				{auditButtonLabel}
		</div>);
	}
}

SheetAuditLabel.propTypes = {
	rowData: PropTypes.object.isRequired
};

export default SheetAuditLabel;
