import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {tempFeatureFlag_CoSellEngine} from '../../../helpers/FeatureHelper';
import {partnerTapWhite} from '../../../styles/partnertap_theme';
import {AdminCrmTracking} from '../co_sell_engine/admin_editors/AdminCrmTracking';
import {AdminDistributeRecordsExternal} from '../co_sell_engine/admin_editors/AdminDistributeRecordsExternal';
import {AdminDistributeRecordsInternal} from '../co_sell_engine/admin_editors/AdminDistributeRecordsInternal';
import {AdminIntroEmail} from '../co_sell_engine/admin_editors/AdminIntroEmail';
import {AdminShareActionList} from '../co_sell_engine/admin_editors/AdminShareActionList';
import {
	WORKFLOW_CRM_TRACKING,
	WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL,
	WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL,
	WORKFLOW_INTRO_EMAIL,
	WORKFLOW_SHARE_ACTION_LIST
} from '../co_sell_engine/CoSellEngineHelper';

export default class CoSellAdminPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {editor: null};
	}

	componentDidMount() {
		this.setState({editor: this.editor});
	}

	get editor() {
		let {workflowConfig} = this.props;
		switch (workflowConfig) {
			case WORKFLOW_SHARE_ACTION_LIST:
				return <AdminShareActionList/>;
			case WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL:
				return <AdminDistributeRecordsInternal/>;
			case WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL:
				return <AdminDistributeRecordsExternal/>;
			case WORKFLOW_INTRO_EMAIL:
				return <AdminIntroEmail/>;
			case WORKFLOW_CRM_TRACKING:
				return <AdminCrmTracking/>;
		}
		return null;
	}

	render() {
		if (!tempFeatureFlag_CoSellEngine) {
			return (
				<div style={{background: partnerTapWhite, margin: 20, marginTop: 0, padding: 20}}>
					Co-sell workflow automation is coming soon!
				</div>
			);
		}

		let {workflowConfig} = this.props;
		let {editor} = this.state;
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column', paddingTop: 10}}>
				{editor ? editor : 'Invalid workflowConfig ' + workflowConfig}
			</div>
		);
	}
}

CoSellAdminPage.propTypes = {
	workflowConfig: PropTypes.string.isRequired,
	workflowSequence: PropTypes.string
};
