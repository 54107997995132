import {Cancel, Close, Edit, Save} from '@mui/icons-material';
import React from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import SecondaryButton from '../ui/buttons/SecondaryButton';
import Dialog from '../ui/Dialog';
import ChannelHelper from './ChannelHelper';
import EnvHelper from './EnvHelper';

export default class DialogHelper {

	static set countDialogState(openDialogCount) {
		if (typeof this.openDialogCount === 'undefined') this.openDialogCount = 0;
		if (openDialogCount) {
			this.openDialogCount++;
		}
		else {
			this.openDialogCount--;
		}

		if (this.openDialogCount < 0) {
			console.warn('openDialogCount reference counter is negative, this is a bug!');
		}
	}

	static get isDialogOpen() {
		return this.openDialogCount > 0;
	}

	static set usingFullScreen(useFullScreen) {
		if (typeof this.usingFullScreenCount === 'undefined') this.usingFullScreenCount = 0;
		if (useFullScreen) {
			this.usingFullScreenCount++;
		}
		else {
			this.usingFullScreenCount--;
		}
		this.useFullScreen = this.usingFullScreenCount > 0;

		if (this.usingFullScreenCount < 0) {
			console.warn('usingFullScreenCount reference counter is negative, this is a bug!');
		}
	}

	static get usingFullScreen() {
		return this.useFullScreen || !EnvHelper.isDesktop;
	}

	static openDetailDialog(title, path, params, setStateFunction) {
		if (!title || !path || !setStateFunction) {
			console.error('Error from openDetailDialog: missing requirement', title, path, setStateFunction);
			return;
		}

		console.info('Opening detail dialog:', title, path, params);
		let dialog =
			<Dialog title={title}
					message={<EnvHelper.CurrentProductComponent locationOverride={{pathname: path, search: params}}/>}
					yesAction={() => setStateFunction({detailDialog: null})}
					noContentPadding={true}
					checkForFullScreen={true}/>;
		setStateFunction({detailDialog: dialog});
	}

	static set savedReportData(reportData) {
		this.reportData = reportData;
	}

	static get savedReportData() {
		return this.reportData || {};
	}

	static openSavedReportDialog(savedReport, setStateFunction, isEditable) {
		let {row, path, params, reportSettings} = savedReport;
		if (!row || !path || !setStateFunction) {
			console.error('Error from openSavedReportDialog: missing requirement', row, path, setStateFunction);
			return;
		}

		console.info('Opening saved report dialog:', savedReport, path, params);
		this.savedReportData = row;
		if (reportSettings) {
			this.savedReportData.settings = reportSettings.reportSettings;
			this.savedReportData.settings.filters = reportSettings.filter;
			this.savedReportData.settings.reportByLocation = reportSettings.reportByLocation;
		}
		this.savedReportData.onClose = () => {
			this.savedReportData = {};
			setStateFunction({detailDialog: null, loading: true});
		};
		this.savedReportData.onRefresh = () => {
			setStateFunction({detailDialog: this.getSavedReportDialog(path, params)});
		};
		this.savedReportData.onDirty = () => {
			this.savedReportData.isDirty = true;
			this.savedReportData.onUpdateDirty();
		};
		this.savedReportData.isEditable = isEditable;
		setStateFunction({detailDialog: this.getSavedReportDialog(path, params)});
	}

	static getSavedReportDialog(path, params) {
		return <Dialog title={'Saved ' + ChannelHelper.getSavedReportCategoryTitle(this.savedReportData.reportCategory) + ': ' + this.savedReportData.name}
					   message={<EnvHelper.CurrentProductComponent locationOverride={{pathname: path, search: params}}/>}
					   onClose={this.savedReportData.onClose}
					   noContentPadding={true}
					   checkForFullScreen={true}/>;
	}

	static renderSavedReportDialogButtons(reportPage, onUpdateDirty, onShowUpdateDialog) {
		this.savedReportData.onUpdateDirty = onUpdateDirty;
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				{reportPage}
				<div style={{position: 'absolute', bottom: 8, right: 10}}>
					{!this.savedReportData.isEditable ?
						<PrimaryButton label={'CLOSE'} icon={<Close/>} onClick={this.savedReportData.onClose}/>
						:
						this.savedReportData.isDirty ?
							<div style={{display: 'flex'}}>
								<SecondaryButton label={'DISCARD CHANGES'} icon={<Cancel/>} onClick={this.savedReportData.onClose}/>
								<div style={{padding: 10}}/>
								<PrimaryButton label={'UPDATE REPORT'} icon={<Save/>} onClick={onShowUpdateDialog} disabled={EnvHelper.isSpoofing}/>
							</div>
							:
							<div style={{display: 'flex'}}>
								<SecondaryButton label={'UPDATE INFO'} icon={<Edit/>} onClick={onShowUpdateDialog} disabled={EnvHelper.isSpoofing}/>
								<div style={{padding: 10}}/>
								<PrimaryButton label={'CLOSE'} icon={<Close/>} onClick={this.savedReportData.onClose}/>
							</div>}
				</div>
			</div>
		);
	}
}
