import {ViewList} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DialogHelper from '../../helpers/DialogHelper';
import EnvHelper from '../../helpers/EnvHelper';
import NumberHelper from '../../helpers/NumberHelper';
import {partnerTapDefaultText, partnerTapDropShadow, partnerTapInactive, partnerTapSecondary, partnerTapWhite} from '../../styles/partnertap_theme';
import SecondaryButton from '../buttons/SecondaryButton';
import Pic from '../Pic';

class BarGraph extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {maxValue: this.props.sections.map((section) => section.value).reduce((a, b) => Math.max(a, b)), detailDialog: null};
	}

	calculatePercentage(value) {
		return (value / this.state.maxValue) * 100;
	}

	render() {
		let {title, description, sections, extraStats, destination, onBarClick} = this.props;
		let rowHeight = onBarClick ? 40 : 25;
		return (
			<div style={{
				flex: 1,
				backgroundColor: partnerTapWhite,
				padding: EnvHelper.isDesktop ? 20 : 10,
				boxShadow: this.props.noFrame ? null : partnerTapDropShadow,
				marginBottom: this.props.noFrame ? 0 : 10,
				marginLeft: this.props.noFrame ? 0 : 5,
				marginRight: this.props.noFrame ? 0 : 5,
				maxWidth: 500,
				minWidth: 300,
				cursor: destination ? 'pointer' : 'default'
			}}
				 onClick={() => {
					 if (destination) {
						 DialogHelper.openDetailDialog(title, destination, null, this.setState.bind(this));
					 }
				 }}>
				{this.state.detailDialog}
				<div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
					{title &&
					 <div style={{paddingBottom: 20}}>
						 <div style={{fontSize: EnvHelper.isDesktop ? 20 : 16}}>{title}</div>
						 <div style={{color: partnerTapInactive}}>{description}</div>
					 </div>}
					<div style={{display: 'flex', backgroundColor: partnerTapWhite, flexDirection: 'column', justifyContent: 'center'}}>
						{sections.map((section, index) => {
							return (
								<div key={index}
									 style={{
										 height: rowHeight,
										 display: 'flex',
										 alignItems: 'center',
										 justifyContent: 'space-evenly',
										 marginRight: 10,
										 marginBottom: 20,
										 color: destination ? partnerTapSecondary : partnerTapDefaultText
									 }}>
									{section.personId &&
									 <Pic personId={section.personId} picSize={30}/>}

									<div style={{display: 'flex', flexDirection: 'column', padding: 10}}>
										<div>
											{section.name}
										</div>
										<div style={{display: 'flex', alignItems: 'center', minWidth: 200}}
											 onClick={() => Boolean(onBarClick) && onBarClick(section)}>
											<div style={{
												height: 10,
												backgroundColor: section.fill,
												width: this.calculatePercentage(section.value) + '%',
												minWidth: 2
											}}/>
											<div style={{color: section.fill, paddingLeft: 5}}>{NumberHelper.formatNumber(section.value)}</div>
										</div>
									</div>

									{this.props.onBarClick &&
									 <SecondaryButton label={'View'} icon={<ViewList/>} onClick={() => this.props.onBarClick(section)}/>}
								</div>
							);
						})}
					</div>
				</div>
				{extraStats &&
				 <div style={{paddingTop: 20, display: 'flex', justifyContent: 'space-around', flexFlow: 'row'}}>
					 {extraStats.map((extraStat) =>
						 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', whiteSpace: 'nowrap'}} key={extraStat.key}>
							 <h3>{extraStat.value}</h3>
							 <h4>{extraStat.name}</h4>
						 </div>)}
				 </div>}
			</div>);
	}
}

BarGraph.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	sections: PropTypes.array.isRequired,
	extraStats: PropTypes.array,
	destination: PropTypes.string,
	onBarClick: PropTypes.func,
	noFrame: PropTypes.bool
};

export default BarGraph;
