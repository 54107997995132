import {UploadFile} from '@mui/icons-material';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {UPLOAD_PRE_REG_USERS, UPLOAD_RECOMMENDED_PARTNER_ORGS} from '../../globals/Enums';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import PageHeader from '../../ui/PageHeader';
import ProcessUpload from '../channel_ecosystem/shared/sheets/ProcessUpload';
import SheetUploader from '../channel_ecosystem/shared/sheets/SheetUploader';
import OrgSelector from './shared/OrgSelector';

class AdminUploadPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {selectedOrg: null, personUploadId: null, errorMessage: null, summary: null};
		this.handleOrgSelection = this.handleOrgSelection.bind(this);
		this.onUploadSuccess = this.onUploadSuccess.bind(this);
	}

	handleOrgSelection(org) {
		this.setState({selectedOrg: org});
	}

	get uploadTypeLabel() {
		let {uploadType} = this.props.match.params;
		if (uploadType === UPLOAD_PRE_REG_USERS) return 'Pre-Register Users';
		if (uploadType === UPLOAD_RECOMMENDED_PARTNER_ORGS) return 'Recommended Partner Orgs';
		return 'Invalid uploadType for uploadTypeLabel: ' + uploadType;
	}

	get uploadTypeRequiredColumns() {
		let {uploadType} = this.props.match.params;
		if (uploadType === UPLOAD_PRE_REG_USERS) return '"FIRST NAME", "LAST NAME", "EMAIL ADDRESS"';
		if (uploadType === UPLOAD_RECOMMENDED_PARTNER_ORGS) return '"COMPANY NAME", "COMPANY DOMAIN"';
		return 'Invalid uploadType for uploadTypeFormat: ' + uploadType;
	}

	get uploadTypeOptionalColumns() {
		let {uploadType} = this.props.match.params;
		if (uploadType === UPLOAD_PRE_REG_USERS) return '"ROLE", "CRM TYPE", "DIVISION", "JOB TITLE"';
		if (uploadType === UPLOAD_RECOMMENDED_PARTNER_ORGS) return 'None';
		return 'Invalid uploadType for uploadTypeFormat: ' + uploadType;
	}

	onUploadSuccess(numRecords, results) {
		this.setState({personUploadId: results.personUploadId, errorMessage: null, summary: null});
	}

	render() {
		let {selectedOrg, personUploadId, errorMessage, summary} = this.state;
		return (
			<div>
				<div style={{display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: partnerTapWhite}}>
					<OrgSelector onOrgSelection={this.handleOrgSelection}/>
				</div>
				<PageHeader title={'Upload ' + this.uploadTypeLabel} icon={<UploadFile fontSize={'large'}/>}/>
				{selectedOrg &&
				 <SheetUploader orgCode={selectedOrg.orgCode} orgId={selectedOrg.orgId} onUploadSuccess={this.onUploadSuccess}/>}
				{personUploadId &&
				 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
					 <ProcessUpload personUploadId={personUploadId}
									onUploadComplete={(uploadData) => this.setState({
										personUploadId: null,
										errorMessage: uploadData.errorMessage,
										summary: uploadData.summary
									})}/>
				 </div>}
				{errorMessage &&
				 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
					 Sheet errors: {errorMessage.split('\n').map((line, index) => <div key={index}>{line}</div>)}
				 </div>}
				{summary &&
				 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
					 Sheet processed: {summary.split('\n').map((line, index) => <div key={index}>{line}</div>)}
				 </div>}
				{(!personUploadId && !errorMessage && !summary) &&
				 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10}}>
					 <div>
						 Required columns: {this.uploadTypeRequiredColumns}
					 </div>
					 <div>
						 Optional Columns: {this.uploadTypeOptionalColumns}
					 </div>
				 </div>}
			</div>
		);
	}
}

export default withRouter(AdminUploadPage);
