import {Close} from '@mui/icons-material';
import {Dialog as MaterialDialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DialogHelper from '../helpers/DialogHelper';
import EnvHelper from '../helpers/EnvHelper';
import StringHelper from '../helpers/StringHelper';
import {partnerTapAppBackground, partnerTapInactive, partnerTapPrimary, partnerTapQuaternary, partnerTapWhite} from '../styles/partnertap_theme';
import PrimaryButton from './buttons/PrimaryButton';
import SecondaryButton from './buttons/SecondaryButton';

class Dialog extends Component {

	constructor(props, context) {
		super(props, context);

		this.wasYesPressed = false;
		this.countedOpen = false;
		this.countedClose = false;
		this.lastWindowHeight = 0;
		this.hasRenderedSinceClose = false;

		this.state = {useFullScreen: !EnvHelper.isDesktop || this.props.useFullScreen};

		this.onYes = this.onYes.bind(this);
		this.onNo = this.onNo.bind(this);
		this.onClose = this.onClose.bind(this);
		this.countOpen = this.countOpen.bind(this);
		this.countClose = this.countClose.bind(this);
		this.checkHeightForFullScreen = this.checkHeightForFullScreen.bind(this);
	}

	componentDidMount() {
		if (this.props.checkForFullScreen) {
			this.checkHeightInterval = setInterval(this.checkHeightForFullScreen, 500);
		}
		this.countOpen();
	}

	componentWillUnmount() {
		this.unmounted = true;
		if (this.checkHeightInterval) {
			clearInterval(this.checkHeightInterval);
			this.checkHeightInterval = null;
		}
		this.countClose();
	}

	get dialogDivId() {
		return this.props.title + '_dialog';
	}

	get contentDivId() {
		return this.props.title + '_content';
	}

	onYes() {
		if (!this.props.forceAction && (this.countedClose || this.wasYesPressed)) return;
		this.wasYesPressed = true;
		this.countClose();
		if (this.props.yesAction) this.props.yesAction();
	}

	onNo() {
		if (this.countedClose && !this.wasYesPressed) return;
		this.countClose();
		if (this.props.noAction) this.props.noAction();
	}

	onClose() {
		if (this.countedClose && !this.wasYesPressed) return;
		this.countClose();
		if (this.props.onClose) this.props.onClose();
	}

	countOpen() {
		if (!this.countedOpen) {
			this.countedOpen = true;
			DialogHelper.countDialogState = true;
		}
	}

	countClose() {
		if (!this.countedClose) {
			this.countedClose = true;
			DialogHelper.countDialogState = false;
			if (this.state.useFullScreen) DialogHelper.usingFullScreen = false;
		}
	}

	checkHeightForFullScreen() {
		if (this.unmounted) return;
		if (!EnvHelper.isDesktop) return;
		if (!this.state.useFullScreen && this.props.checkForFullScreen) {
			let pagingTableScroll = document.getElementById('paging_table_scroll_dialog');
			if (pagingTableScroll) {
				if (pagingTableScroll.clientHeight < pagingTableScroll.scrollHeight) {
					DialogHelper.usingFullScreen = true;
					this.setState({useFullScreen: true});
				}
				return;
			}
		}

		if (this.lastWindowHeight === window.innerHeight) return;

		let dialogElement = document.getElementById(this.dialogDivId);
		let contentElement = document.getElementById(this.contentDivId);
		if (dialogElement && contentElement) {
			let isWindowTaller = this.lastWindowHeight !== 0 && this.lastWindowHeight < window.innerHeight;
			this.lastWindowHeight = window.innerHeight;

			if (!isWindowTaller && dialogElement.clientHeight >= window.innerHeight - 200) {
				if (!this.state.useFullScreen) {
					DialogHelper.usingFullScreen = true;
					this.setState({useFullScreen: true});
				}
			}

			if (isWindowTaller && contentElement.clientHeight < window.innerHeight - 200) {
				if (this.state.useFullScreen) {
					DialogHelper.usingFullScreen = false;
					this.setState({useFullScreen: false});
				}
			}
		}
	}

	resetOnNextRender() {
		// when a closing button is pressed, we allow the dialog buttons to be used again after the next render, which was presumably triggered by a form update (after an entry error)
		if (this.countedClose) {
			if (this.hasRenderedSinceClose) {
				if (this.unmounted) return;
				this.wasYesPressed = false;
				this.countedOpen = false;
				this.countedClose = false;
				this.hasRenderedSinceClose = false;
				this.countOpen();
			}
			else {
				this.hasRenderedSinceClose = true;
			}
		}
	}

	render() {
		this.resetOnNextRender();

		let actionButtons = null;
		if (this.props.yesAction && this.props.noAction) {
			if (this.props.hideNoButton) {
				actionButtons =
					<DialogActions style={{background: partnerTapQuaternary}}>
						<div style={{display: 'flex'}}>
							<div style={{padding: 10}}/>
							<PrimaryButton key={'yes'}
										   label={this.props.yesLabel || 'yes'}
										   minWidth={120}
										   onClick={this.onYes}
										   disabled={this.props.yesDisable}/>
						</div>
					</DialogActions>;
			}
			else {
				actionButtons =
					<DialogActions style={{background: partnerTapQuaternary}}>
						<div style={{display: 'flex'}}>
							<SecondaryButton key={'no'} label={this.props.noLabel || 'no'} minWidth={120} onClick={this.onNo}/>
							<div style={{padding: 10}}/>
							<PrimaryButton key={'yes'}
										   label={this.props.yesLabel || 'yes'}
										   minWidth={120}
										   onClick={this.onYes}
										   disabled={this.props.yesDisable}/>
						</div>
					</DialogActions>;
			}
		}
		else if (this.props.yesAction) {
			actionButtons =
				<DialogActions style={{background: partnerTapQuaternary}}>
					<PrimaryButton key={'ok'} label={this.props.yesLabel || 'ok'} onClick={this.onYes} disabled={this.props.yesDisable}/>
				</DialogActions>;
		}
		else if (!this.props.hideActionBar) {
			actionButtons =
				<DialogActions style={{background: partnerTapQuaternary}}>
					<div style={{padding: 18}}/>
				</DialogActions>;
		}
		let hasTitle = Boolean(this.props.title);
		let dialogContentStyle = {};
		if (this.props.noContentPadding) {
			dialogContentStyle.padding = 0;
		}
		if (this.props.checkForFullScreen) {
			dialogContentStyle.overflow = 'hidden';
		}
		return (
			<MaterialDialog open={true}
							maxWidth={false}
							fullScreen={this.state.useFullScreen}
							onClose={this.props.noAction ? this.onNo : this.onYes}
							onClick={(event) => {
								event.stopPropagation(); // make sure clicks do not trigger anything underneath this dialog
							}}>
				{hasTitle &&
				 <DialogTitle style={{
					 background: partnerTapPrimary,
					 border: '1px solid ' + partnerTapAppBackground,
					 borderRadius: 4,
					 padding: 5,
					 paddingLeft: 10
				 }}>
					 <div style={{color: partnerTapWhite, paddingRight: 50, overflow: 'hidden', textOverflow: 'ellipsis'}}>
						 {this.props.title}
					 </div>
					 <IconButton data-cy={'dialog_close_button_' + (this.props.title ? StringHelper.formatKey(this.props.title) : '0')}
								 size={'large'}
								 style={{position: 'absolute', right: 0, top: -2, color: partnerTapInactive}}
								 onClick={() => {
									 if (this.props.noAction) {
										 this.onNo();
									 }
									 else if (this.props.yesAction) {
										 this.onYes();
									 }
									 if (this.props.onClose) {
										 this.onClose();
									 }
								 }}>
						 <Close/>
					 </IconButton>
				 </DialogTitle>}
				<DialogContent id={this.dialogDivId}
							   ref={(element) => {
								   if (this.unmounted) return;
								   if (element) {
									   if (this.observer) {
										   this.observer.disconnect();
									   }
									   this.observer = new ResizeObserver(() => {
										   // requestAnimationFrame is required to avoid ResizeObserver loop limit exceeded
										   window.requestAnimationFrame(this.checkHeightForFullScreen);
									   });
									   this.observer.observe(element);
								   }
							   }}
							   style={dialogContentStyle}
							   dividers={Boolean(this.props.title)}>
					<div id={this.contentDivId}>
						{this.props.message}
					</div>
				</DialogContent>
				{actionButtons}
			</MaterialDialog>
		);
	}
}

Dialog.propTypes = {
	title: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	noContentPadding: PropTypes.bool,
	useFullScreen: PropTypes.bool,
	checkForFullScreen: PropTypes.bool,
	yesLabel: PropTypes.string,
	yesAction: PropTypes.func,
	yesDisable: PropTypes.bool,
	noLabel: PropTypes.string,
	noAction: PropTypes.func,
	hideActionBar: PropTypes.bool,
	onClose: PropTypes.func,
	forceAction: PropTypes.bool,
	hideNoButton: PropTypes.bool
};

export default Dialog;
