import {Realtime} from 'ably';
import _ from 'lodash';
import React from 'react';
import * as ChatEndpoints from '../endpoints/ChatEndpoints';
import {Routes} from '../globals/Routes';
import * as ChatActions from '../redux/IntelActions';
import EnvHelper from './EnvHelper';

const urlRegex = /^(\bhttps?:\/\/|www\.\b)[^\s]+|[^\s]+\.(\bcom|org|net|edu|gov|biz|info|([a-z]{2})\b)$/i;

export default class ChatHelper {

	static getClient(partnerId, saleAccountLocationId) {
		return new Promise((resolve, reject) => {
			if (_.isEmpty(partnerId)) {
				reject('Error getting client: partnerId is required');
			}

			if (!this.ably) {
				this.ably = new Realtime({
					queryTime: true,
					authCallback: (tokenParams, callback) => {
						ChatEndpoints.fetchChatToken(partnerId, saleAccountLocationId)
						.then((result) => {
							callback(null, result.payload.tokenRequest);
							resolve({client: this.ably, channelKey: result.payload.channelKey});
						})
						.catch((error) => reject(error));
					}
				});
			}
			else {
				ChatEndpoints.fetchChatToken(partnerId, saleAccountLocationId)
				.then((result) => {
					resolve({client: this.ably, channelKey: result.payload.channelKey});
				})
				.catch((error) => reject(error));
			}
		});
	}

	static getChannelKey(partnerId, saleAccountLocationId) {
		return new Promise(function(resolve, reject) {
			ChatEndpoints.fetchChatToken(partnerId, saleAccountLocationId)
			.then((result) => {
				resolve(result.payload.channelKey);
			})
			.catch((error) => reject(error));
		});
	}

	static get NEW_CHAT() {
		return 'NEW_CHAT';
	}

	static set currentChatThreads(chatThreads) {
		this.chatThreads = chatThreads;
	}

	static get disableNewChat() {
		return Boolean(this.chatThreads && this.chatThreads.find((thread) => thread.personChatId === ChatHelper.NEW_CHAT));
	}

	static showChat(personChatId, props) {
		if (EnvHelper.path.indexOf('/intel') === -1) {
			let {partnerId, partnerPersonId, personSaleAccountId, saleAccountLocationId} = props.match.params;
			ChatHelper.showChatPartner(partnerId, partnerPersonId, personSaleAccountId, saleAccountLocationId, personChatId);
		}
		else {
			props.dispatch(ChatActions.showChat(personChatId, props.partner, props.account));
		}
	}

	static showChatPartner(partnerId, partnerPersonId, personSaleAccountId, saleAccountLocationId, personChatId, fromPartnerOpp) {
		if (!partnerId) partnerId = '_';
		if (!partnerPersonId) partnerPersonId = '_';
		if (!personSaleAccountId) personSaleAccountId = '_';
		if (!saleAccountLocationId) saleAccountLocationId = '_';
		if (!personChatId) personChatId = '_';
		if (EnvHelper.inChannelEcosystemProduct) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INTEL.PARTNER.PATH(partnerPersonId, personChatId));
		}
		else if (ChatHelper.inSalesNetworkPartners) {
			if (personChatId === ChatHelper.NEW_CHAT) personSaleAccountId = '_';
			EnvHelper.push(Routes.DASHBOARD.PARTNERS.ROUTE + '/' + partnerId + '/' + partnerPersonId + '/intel/' + personSaleAccountId + '/' + saleAccountLocationId + '/' + personChatId + (fromPartnerOpp ? '/' + fromPartnerOpp : ''));
		}
		else if (ChatHelper.inSalesNetworkAccounts) {
			if (personChatId === ChatHelper.NEW_CHAT) partnerPersonId = '_';
			EnvHelper.push(Routes.DASHBOARD.ACCOUNTS.ROUTE + '/' + personSaleAccountId + '/' + saleAccountLocationId + '/intel/' + partnerPersonId + '/' + personChatId);
		}
		else {
			EnvHelper.push(Routes.DASHBOARD.INTEL.ROUTE + '/' + partnerPersonId + '/' + personSaleAccountId + '/' + saleAccountLocationId + '/' + personChatId);
		}
	}

	static initChatThreads(userPersonId, chatThreads) {
		chatThreads.forEach((thread) => {
			thread.partnerPersonId = userPersonId === thread.publisherId ? thread.subscriberId : thread.publisherId;
			thread.partnerName = userPersonId === thread.publisherId ? thread.subscriberName : thread.publisherName;
			thread.saleAccountLocation = (thread.saleAccountCity ? thread.saleAccountCity : '') +
										 (thread.saleAccountCity && thread.saleAccountState ? ', ' : '') +
										 (thread.saleAccountState ? thread.saleAccountState : '');

			if (EnvHelper.inSalesNetworkProduct && !thread.saleAccountLocationId) {
				ChatHelper.setChatValuesFromAccount(thread, ChatHelper.noAccountItem);
			}
		});
	}

	static setChatValuesFromPartner(thread, partnerOrProfile) {
		if (!thread || !partnerOrProfile) return;
		thread.partnerId = partnerOrProfile.partnerId || partnerOrProfile.id;
		thread.partnerStatus = partnerOrProfile.partnerStatus || partnerOrProfile.status;
		thread.partnerPersonId = partnerOrProfile.personId;
		thread.partnerName = partnerOrProfile.firstName + ' ' + partnerOrProfile.lastName;
		thread.partnerOrg = partnerOrProfile.organization;
	}

	static hasPartner(thread) {
		if (!thread) return false;
		return Boolean(thread.partnerId && thread.partnerStatus && thread.partnerPersonId && thread.partnerName && thread.partnerOrg);
	}

	static setChatValuesFromAccount(thread, account) {
		if (!thread || !account) return;
		if (account.isGeneralChat) {
			thread.isGeneralChat = true;
			thread.saleAccountName = account.name;
			thread.saleAccountId = null;
			thread.saleAccountLocationId = null;
			thread.saleAccountLocation = null;
		}
		else {
			thread.isGeneralChat = false;
			thread.saleAccountId = account.saleAccountId;
			thread.saleAccountLocationId = account.saleAccountLocationId;
			thread.saleAccountName = account.name;
			thread.saleAccountLocation = (account.city ? account.city : '') +
										 (account.city && account.state ? ', ' : '') +
										 (account.state ? account.state : '');
		}
	}

	static hasAccount(thread) {
		if (!thread) return false;
		return Boolean(thread.saleAccountId && thread.saleAccountLocationId && thread.saleAccountName && thread.saleAccountLocation) || thread.isGeneralChat;
	}

	static get noAccountItem() {
		return {name: 'General (No Account)', isGeneralChat: true};
	}

	static get inSalesNetworkPartners() {
		return EnvHelper.path.indexOf(Routes.DASHBOARD.PARTNERS.ROUTE) === 0 || EnvHelper.path.indexOf(Routes.DASHBOARD.TAP.ROUTE) === 0;
	}

	static get inSalesNetworkAccounts() {
		return EnvHelper.path.indexOf(Routes.DASHBOARD.ACCOUNTS.ROUTE) === 0;
	}

	static linkUrls(text) {
		let split = text.split(' ');
		return [
			split.map((word, i) => {
				if (urlRegex.test(word)) {
					return (
						<div key={i} style={{display: 'inline'}}>
							<u style={{cursor: 'pointer'}}
							   onClick={() => {
								   let url = word.substring(0, 4) !== 'http' ? 'https://' + word : word;
								   EnvHelper.isCordova ? EnvHelper.cordova.InAppBrowser.open(url, '_blank') : window.open(url, '_blank');
							   }}>
								{word}
							</u>
							&nbsp;
						</div>
					);
				}
				else {
					return word + ' ';
				}
			})
		];
	}
}
