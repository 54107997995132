import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Loading from '../Loading';

class ScrimMessage extends Component {

	render() {
		return (
			<div style={{
				position: 'absolute',
				top: 0,
				left: 0,
				zIndex: 10000,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.2)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
				<div style={{backgroundColor: 'rgba(255, 255, 255, 0.8)', margin: 10, borderRadius: 20}}>
					<Loading>{this.props.message}</Loading>
				</div>
			</div>
		);
	}
}

ScrimMessage.propType = {
	message: PropTypes.string.isRequired
};

export default ScrimMessage;
