import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import DialogHelper from '../../../../helpers/DialogHelper';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import SupportButton from '../../../../ui/buttons/SupportButton';
import Dialog from '../../../../ui/Dialog';
import TextInputBox from '../../../../ui/TextInputBox';

class SaveReportDialog extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			reportSaved: false,
			reportSaveError: false,
			reportName: DialogHelper.savedReportData.name,
			reportDescription: DialogHelper.savedReportData.description,
			reportShared: DialogHelper.savedReportData.shared
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onSave() {
		let {reportType, reportCategory, reportSettings, reportParams} = this.props;
		let {reportName, reportDescription, reportShared} = this.state;
		if (!reportName) {
			this.setState({reportPreSaveError: 'Please name your report'});
			return;
		}

		ChannelMappingEndpoints.saveReport(reportType, reportCategory, reportSettings, reportParams, reportName, reportDescription, reportShared)
		.then((result) => {
			if (this.unmounted) return;
			console.info('Success onSave:', result);
			this.setState({reportSaved: true});
		})
		.catch((error) => {
			console.error('Error trying to save report:', error);
			this.setState({reportSaveError: true});
		});
	}

	onUpdate() {
		let {reportSettings} = this.props;
		let {reportName, reportDescription, reportShared} = this.state;
		if (!reportName) {
			this.setState({reportPreSaveError: 'Please name your report'});
			return;
		}

		ChannelMappingEndpoints.updateReport(DialogHelper.savedReportData.id, reportSettings, reportName, reportDescription, reportShared)
		.then((result) => {
			console.info('Success onUpdate:', result);
			DialogHelper.savedReportData.name = reportName;
			DialogHelper.savedReportData.description = reportDescription;
			DialogHelper.savedReportData.shared = reportShared;
			DialogHelper.savedReportData.onRefresh();
			this.setState({reportSaved: true});
		})
		.catch((error) => {
			console.error('Error trying to update report:', error);
			this.setState({reportSaveError: true});
		});
	}

	render() {
		let {isShared} = this.props;
		let {reportName, reportDescription, reportShared, reportPreSaveError, reportSaved, reportSaveError} = this.state;
		let isUpdate = DialogHelper.savedReportData.id;
		let message;
		if (reportSaved) {
			let savedToOrUpdatedIn = isUpdate ? 'updated in' : 'saved to';
			message =
				<div>
					'{reportName}' has been {savedToOrUpdatedIn} Ecosystem Reports.
				</div>;
		}
		else if (reportSaveError) {
			message =
				<div>
					Sorry, {'\'' + reportName + '\'' || 'Your report'} was not {isUpdate ? 'updated' : 'saved'}. Please reach out if you need help...
					<div style={{paddingTop: 10, display: 'flex', justifyContent: 'center'}}>
						<SupportButton/>
					</div>
				</div>;
		}
		else {
			message =
				<div>
					<div data-cy={'saved_report_name'} style={{padding: 10}}>
						<TextInputBox hintText={'Report Name'}
									  value={reportName || ''}
									  errorText={reportPreSaveError}
									  onChange={(value) => this.setState({reportName: value})}
									  doNotAutoFocus={true}
									  maxChars={64}/>
					</div>
					<div data-cy={'saved_report_description'} style={{padding: 10}}>
						<TextInputBox hintText={'Description'}
									  value={reportDescription || ''}
									  onChange={(value) => this.setState({reportDescription: value})}
									  doNotAutoFocus={true}
									  multiLine={true}
									  rows={3}
									  maxChars={512}/>
					</div>
					{isShared &&
					 <div style={{display: 'flex', alignItems: 'center'}}>
						 <CheckboxButton checked={reportShared} onChange={() => this.setState({reportShared: !reportShared})}/>
						 Share with Organization
					 </div>}
				</div>;
		}

		let saveOrUpdate = isUpdate ? 'Update' : 'Save';
		let settings = isUpdate && !DialogHelper.savedReportData.isDirty ? ' Info' : '';
		return (
			<Dialog key={reportSaved}
					title={reportSaved ? 'Report ' + saveOrUpdate + 'd' : saveOrUpdate + ' Report' + settings}
					message={message}
					yesLabel={reportSaved ? 'Ok' : saveOrUpdate}
					yesAction={() => {
						DialogHelper.savedReportData.isDirty = false;
						if (reportSaved) {
							this.props.onClose();
						}
						else if (isUpdate) {
							this.onUpdate();
						}
						else {
							this.onSave();
						}
					}}
					noAction={this.props.onClose}
					hideNoButton={true}/>
		);
	}
}

SaveReportDialog.propTypes = {
	reportType: PropTypes.string,
	reportCategory: PropTypes.string.isRequired,
	reportSettings: PropTypes.object.isRequired,
	reportParams: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	isShared: PropTypes.bool
};

SaveReportDialog.defaultProps = {
	isShared: true
};

export default SaveReportDialog;
