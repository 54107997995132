import {CameraAlt} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import loadImage from 'blueimp-load-image';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../endpoints/AdminEndpoints';
import * as ProfilesEndpoints from '../endpoints/ProfilesEndpoints';
import EnvHelper from '../helpers/EnvHelper';
import {partnerTapSecondary, partnerTapWhite} from '../styles/partnertap_theme';

class ImageSelector extends Component {

	constructor(props, context) {
		super(props, context);
		this.onDrop = this.onDrop.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.state = {loading: false};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onDragOver(event) {
		event.preventDefault();
	}

	onDrop(event) {
		event.preventDefault();
		this.onFileChange(event, event.dataTransfer.files[0]);
	}

	onFileChange(e, file) {
		file = file || e.target.files[0];
		if (!file) return;

		let pattern = /image-*/;
		// old android does not have a file type, allowing blank type
		if (!file.type.match(pattern) && !_.isEmpty(file.type)) {
			alert('Please select an image file. type: "' + file.type + '"');
			return;
		}

		if (file.size > 3000000) {
			alert('Please select a file under 3 MB');
			return;
		}

		this.setState({loading: true});

		loadImage.parseMetaData(file,
			(data) => {
				let orientation;
				if (typeof data.exif !== 'undefined') {
					orientation = parseInt(data.exif.get('Orientation'));
				}
				loadImage(
					file,
					(img) => {
						let data = img.toDataURL();
						let indexDataType = data.indexOf(',') + 1;
						if (this.props.updateType === 'photo' || this.props.updateType === 'banner') {
							let updateOrg = this.props.orgCode;
							let id = this.props.personId || this.props.orgCode;
							let action = updateOrg ? AdminEndpoints.updateOrgLogo : ProfilesEndpoints.updateProfileImage;
							action(this.props.updateType, data.substring(indexDataType), id)
							.then((result) => {
								if (this.unmounted) return;
								if (updateOrg) {
									this.setState({loading: false});
									EnvHelper.updateNoCache();
									if (this.props.updateComplete) {
										this.props.updateComplete();
									}
								}
								else {
									ProfilesEndpoints.fetchProfile(this.props.authState.person.id)
									.then((result) => {
										if (this.unmounted) return;
										this.setState({loading: false});
										EnvHelper.updateNoCache();
										if (this.props.updateComplete) {
											this.props.updateComplete();
										}
									});
								}
							});
						}
						else {
							this.setState({loading: false});
						}
					},
					{
						canvas: true,
						orientation: orientation,
						maxWidth: this.props.updateType === 'banner' ? 3300 : 825,
						maxHeight: 825
					}
				);
			});
	}

	render() {
		return (
			<label onDragOver={this.onDragOver}
				   data-cy={this.props.dataCy}
				   onDrop={this.onDrop}
				   style={{
					   cursor: 'pointer',
					   display: 'flex',
					   alignItems: 'center',
					   justifyContent: 'center',
					   width: 36,
					   height: 36,
					   position: 'relative',
					   borderRadius: '100%',
					   border: '1px solid ' + partnerTapWhite,
					   backgroundColor: partnerTapSecondary
				   }}>
				{this.state.loading ?
					<CircularProgress size={20} style={{color: partnerTapWhite}} disableShrink={true}/>
					:
					<CameraAlt style={{color: partnerTapWhite, paddingTop: 2}}/>}
				<input type={'file'} accept={'image/*'} onChange={this.onFileChange} ref={'input'} style={{display: 'none'}}/>
			</label>
		);
	}
}

ImageSelector.propTypes = {
	personId: PropTypes.string,
	orgCode: PropTypes.string,
	updateType: PropTypes.string.isRequired,
	updateComplete: PropTypes.func,
	authState: PropTypes.object.isRequired,
	dataCy: PropTypes.string.isRequired

};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(ImageSelector);
