import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as AccountsEndpoints from '../../endpoints/AccountsEndpoints';
import {PAGE_SIZE_DEFAULT} from '../../helpers/PageableHelper';
import Loading from '../Loading';
import PopoverSearchList from '../PopoverSearchList';

export class AccountSelector extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, accounts: [], page: 0, lastPage: false};
		this.getMatchedAccounts = this.getMatchedAccounts.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.loadMore = this.loadMore.bind(this);
	}

	componentDidMount() {
		this.getMatchedAccounts();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getMatchedAccounts(page, search) {
		AccountsEndpoints.fetchSharedAccountsPage(page, PAGE_SIZE_DEFAULT, this.props.partnerPersonId, search)
		.then((result) => {
			if (this.unmounted) return;
			let results = this.append ? this.state.accounts.concat(result.payload) : result.payload;
			this.append = false;
			this.setState({loading: false, search: search, accounts: results, page: page, lastPage: result.metaData.last});
		})
		.catch((error) => {
			console.error('Error fetching shared accounts for AccountSelector:', error);
		});
	}

	onSearch(search) {
		this.getMatchedAccounts(0, search);
	}

	loadMore() {
		this.append = true;
		this.getMatchedAccounts(this.state.page + 1, this.state.search);
	}

	render() {
		if (this.state.loading) return (<Loading small={true} horizontal={true}>Loading Accounts...</Loading>);
		return (
			<PopoverSearchList label={'Select Account'}
							   list={this.state.accounts}
							   labelRenderer={(account) => account.name}
							   onItemSelected={this.props.onAccountSelected}
							   pageableSearch={this.state.search}
							   pageableSearchHandler={this.onSearch}
							   loadMore={this.state.lastPage ? null : this.loadMore}/>
		);
	}
}

AccountSelector.propTypes = {
	partnerPersonId: PropTypes.string.isRequired,
	onAccountSelected: PropTypes.func.isRequired
};

export default AccountSelector;
