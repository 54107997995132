import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapInactive, partnerTapSecondary, partnerTapStroke, partnerTapTernary} from '../../../styles/partnertap_theme';
import ChatButton from '../../../ui/buttons/ChatButton';
import ShareButton from '../../../ui/buttons/ShareButton';
import MatchCount from '../../../ui/MatchCount';
import Pic from '../../../ui/Pic';
import SharedOppCount from '../../../ui/SharedOppCount';

class AccountRowRenderer extends Component {

	constructor(props, context) {
		super(props, context);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if (this.forShareOpportunities) {
			EnvHelper.push(Routes.DASHBOARD.TAP.SHARE_PARTNERS.PATH + '/' + this.props.rowData.id);
		}
		else {
			EnvHelper.push(Routes.DASHBOARD.ACCOUNTS.ROUTE + '/' + this.props.rowData.id + '/' + this.props.rowData.saleAccountLocationId + '/partners');
		}
	}

	get forShareOpportunities() {
		return this.props.rowData.opportunityStatus && EnvHelper.history.location.pathname.indexOf('tap/share') > -1;
	}

	render() {
		return (
			<tr>
				<td>
					<div ref={TableHelper.widthObserverFunction.bind(this)}
						 className={'hoverActive'}
						 style={{
							 display: 'flex',
							 alignItems: 'center',
							 justifyContent: 'space-between',
							 padding: EnvHelper.isDesktop ? 10 : 8,
							 borderBottom: '1px solid ' + partnerTapStroke
						 }}>
						<div style={{flex: 1, display: 'flex', alignItems: 'center'}}
							 onClick={this.onClick}
							 ref={(element) => this.infoElement = element}>
							<Pic tmpLogo={this.props.rowData.name || this.props.rowData.accountName} bizId={this.props.rowData.saleAccountId}/>
							<div style={{
								height: 68,
								paddingLeft: 10,
								paddingRight: 10,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								color: partnerTapSecondary
							}}>
								<div style={{
									color: partnerTapSecondary,
									fontSize: EnvHelper.isDesktop ? 18 : 16,
									paddingBottom: 1,
									display: 'block',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}>
									{this.props.rowData.name || this.props.rowData.accountName}
								</div>
								<div style={{
									color: partnerTapStroke,
									fontSize: EnvHelper.isDesktop ? 14 : 12,
									paddingBottom: 2,
									display: 'block',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}>
									{this.props.rowData.city}
									{this.props.rowData.city && this.props.rowData.state ? ', ' : ''}
									{this.props.rowData.state}
									<span> - {this.props.rowData.accountType}</span>
								</div>
								<div style={{display: 'flex', alignItems: 'center'}}>
									{this.props.rowData.partnerMatchCount !== -1 &&
									 <MatchCount count={this.props.rowData.partnerMatchCount || this.props.rowData.partnerCount}
												 icon={'E'}
												 label={!EnvHelper.isDesktop && this.forShareOpportunities ? '' : 'Partner'}/>}
									{this.props.rowData.recommendedPartnerCount > 0 &&
									 <div style={{paddingLeft: (EnvHelper.isDesktop ? 12 : 10)}}>
										 <MatchCount count={this.props.rowData.recommendedPartnerCount}
													 icon={'E'}
													 label={!EnvHelper.isDesktop && this.forShareOpportunities ? '' : 'Recommended'}
													 color={partnerTapTernary}
													 doNotPluralize={true}/>
									 </div>}
									{(this.forShareOpportunities && this.props.rowData.opportunitySharedCount > 0) &&
									 <div style={{paddingLeft: (EnvHelper.isDesktop ? 12 : 10)}}>
										 <SharedOppCount count={this.props.rowData.opportunitySharedCount}/>
									 </div>}
								</div>
							</div>
						</div>
						<div style={{display: 'flex', alignItems: 'center'}}>
							{this.props.rowData.showChatIcon &&
							 <ChatButton partnerId={this.props.rowData.partnerId}
										 partnerPersonId={this.props.rowData.partnerPersonId}
										 personSaleAccountId={this.props.rowData.id}
										 saleAccountLocationId={this.props.rowData.saleAccountLocationId}/>}
							{this.forShareOpportunities &&
							 <div style={{textAlign: 'right'}}>
								 <div style={{color: partnerTapInactive, whiteSpace: 'nowrap', textAlign: 'right', paddingBottom: 10}}>
									 {this.props.rowData.opportunityStatus.indexOf('OPEN') !== -1 ? 'Opened ' : 'Closed '}
									 {DateHelper.epochToDate(this.props.rowData.opportunityStatusDate)}
								 </div>
								 <ShareButton onClick={this.onClick} isNavigation={true}/>
							 </div>}
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

AccountRowRenderer.propTypes = {
	rowData: PropTypes.shape({
		id: PropTypes.string,
		saleAccountId: PropTypes.string.isRequired,
		saleAccountLocationId: PropTypes.string,
		name: PropTypes.string,
		territory: PropTypes.string,
		contactPhoneNumber: PropTypes.string,
		street: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		accountType: PropTypes.string,
		country: PropTypes.string,
		zipCode: PropTypes.string,
		rating: PropTypes.string,
		myRating: PropTypes.number,
		comment: PropTypes.string,
		status: PropTypes.string,
		targeted: PropTypes.bool,
		partnerId: PropTypes.string,
		partnerPersonId: PropTypes.string,
		partnerName: PropTypes.string,
		partnerMatchCount: PropTypes.number,
		opportunityStatus: PropTypes.string,
		opportunityStatusDate: PropTypes.number,
		opportunitySharedCount: PropTypes.number,
		showChatIcon: PropTypes.bool,
		recommendedPartnerCount: PropTypes.number,
		assetObjId: PropTypes.number,
		opportunityObjId: PropTypes.number
	}).isRequired
};

export default connect()(AccountRowRenderer);
