import * as ApiHelper from '../helpers/ApiHelper';

export function getAdminPartnerSheets(page, pageSize, search, sort) {
	let endPoint = `/rest-admin-center/partnersheet?page=${page}`;
	if (pageSize) endPoint += '&size=' + pageSize;
	if (search) endPoint += '&search=' + search;
	if (sort) endPoint += '&sort=' + sort;
	return ApiHelper.sendGet(endPoint);
}

export function reassignPartnerOrgs(payload) {
	return ApiHelper.sendPut('/rest-admin-center/partnersheet', payload);
}

export function deletePartnerOrgs(payload) {
	return ApiHelper.sendPost('/rest-admin-center/delete-partnersheet', payload);
}