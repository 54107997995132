import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../../endpoints/ManagerAnalyticsEndpoints';
import {COLUMN_KEY_ACCOUNT_NAME} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import NavHelper from '../../../helpers/NavHelper';
import {partnerTapSecondary} from '../../../styles/partnertap_theme';
import ManagerAnalyticsPage from '../ManagerAnalyticsPage';

class TapSpecificAnalytics extends Component {

	constructor(props, context) {
		super(props, context);
		this.onClickAccountName = this.onClickAccountName.bind(this);
	}

	componentDidMount() {
		this.checkForRedirect(this.props);
	}

	checkForRedirect(props) {
		if (props.managerAnalyticsState.currentCompanyName === null) {
			EnvHelper.push(this.props.isOutbound ? Routes.MANAGER_ANALYTICS.TAP.OUTBOUND : Routes.MANAGER_ANALYTICS.TAP.INBOUND);
		}
	}

	onClickAccountName(value, data) {
		let {person} = this.props.authState;
		let linkSFDC = person && data && data.thirdPartyId;
		let link = linkSFDC && NavHelper.getCrmAccountLink(person.crmType, person.crmInstance, data.thirdPartyId);
		if (link) {
			return (
				<div style={{color: partnerTapSecondary, cursor: 'pointer'}} onClick={() => window.open(link, '_system')}>
					{value}
				</div>
			);
		}
		else {
			return value;
		}
	}

	render() {
		let columns = [
			{title: 'Account Name', key: COLUMN_KEY_ACCOUNT_NAME, renderFunction: this.onClickAccountName},
			{title: 'Recipient', key: 'recipient'},
			{title: 'Recipient Email', key: 'recipientEmail'},
			{title: 'Recipient Title', key: 'recipientTitle'},
			{title: 'Sender', key: 'sender'},
			{title: 'Sender Email', key: 'senderEmail'},
			{title: 'Sender Title', key: 'senderTitle'},
			{title: 'Status', key: 'closed', renderFunction: (columnValue) => columnValue ? 'Closed' : 'Open'},
			{title: 'Date', key: 'updatedTimestamp', renderFunction: DateHelper.epochToDate}
		];
		let {isOutbound} = this.props;
		return (
			<ManagerAnalyticsPage labelNoFind={'No opps data found'}
								  labelLoading={'Loading Opps Data...'}
								  sectionName={isOutbound ? this.props.authState.profile.organization + ' Opps' : 'Partner Opps'}
								  glyph={isOutbound ? 'k' : 'd'}
								  columnData={columns}
								  keyData={isOutbound ? [ManagerAnalyticsEndpoints.keyTapOutbound] : [ManagerAnalyticsEndpoints.keyTapInbound]}/>
		);
	}
}

TapSpecificAnalytics.propTypes = {
	authState: PropTypes.object.isRequired,
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(TapSpecificAnalytics);
