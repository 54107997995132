import {Save} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import SaveReportDialog from '../../app/channel_ecosystem/ecosystem/saved_reports/SaveReportDialog';
import EnvHelper from '../../helpers/EnvHelper';
import SecondaryButton from './SecondaryButton';

class SaveReportButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.hideSaveDialog = false;
		this.state = {showSaveDialog: false};
		this.closeDialog = this.closeDialog.bind(this);
	}

	closeDialog() {
		this.hideSaveDialog = true;
		this.setState({showSaveDialog: false});
	}

	render() {
		let dialog;
		if (this.state.showSaveDialog || (this.props.showSaveDialog && !this.hideSaveDialog)) {
			let {reportType, reportCategory, reportSettings, reportParams, isShared} = this.props;
			dialog = <SaveReportDialog reportType={reportType}
									   reportCategory={reportCategory}
									   reportSettings={reportSettings}
									   reportParams={reportParams}
									   isShared={isShared}
									   onClose={this.closeDialog}/>;
		}
		this.hideSaveDialog = false;
		return (
			<Fragment>
				<SecondaryButton key={'save_report_button'}
								 toolTip={'Save report'}
								 icon={<Save/>}
								 minWidth={40}
								 onClick={() => this.setState({showSaveDialog: true})}
								 disabled={EnvHelper.isSpoofing}/>
				{dialog}
			</Fragment>
		);
	}
}

SaveReportButton.propTypes = {
	reportType: PropTypes.string,
	reportCategory: PropTypes.string,
	reportSettings: PropTypes.object,
	reportParams: PropTypes.object,
	isShared: PropTypes.bool,
	showSaveDialog: PropTypes.bool
};

export default SaveReportButton;
