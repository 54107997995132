import objectAssign from 'object-assign';
import * as ReduxEnum from './ReduxEnum';

export default function intelState(state = {
	personChatId: null
}, action) {

	switch (action.type) {
		case ReduxEnum.SHOW_CHAT: {
			let newState = objectAssign({}, state);
			newState.personChatId = action.personChatId;
			newState.partner = action.partner;
			newState.account = action.account;
			return newState;
		}
		case ReduxEnum.UPDATE_CHAT: {
			let newState = objectAssign({}, state);
			return newState;
		}
		case ReduxEnum.HIDE_CHAT: {
			let newState = objectAssign({}, state);
			newState.personChatId = null;
			newState.partner = null;
			newState.account = null;
			return newState;
		}
		default: {
			return state;
		}
	}
}
