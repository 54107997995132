import Build from '@mui/icons-material/Build';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import SortHelper from '../../helpers/SortHelper';
import {partnerTapQuaternary, partnerTapWhite} from '../../styles/partnertap_theme';
import PagingBase from '../../ui/lists/PagingBase';
import OrgSelector from './shared/OrgSelector';

class AdminPreRecommendedOrgsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.selectedOrg = null;

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
	}

	handleOrgSelection(org) {
		this.setState({selectedOrg: org, loading: true});
	}

	get storageKeyBase() {
		return 'admin_pre_recommended_orgs';
	}

	get title() {
		return 'Pre-Recommended Partner Orgs';
	}

	get icon() {
		return Build;
	}

	get columnData() {
		return [
			{title: 'PartnerTap Org', key: 'ownerOrgName'},
			{title: 'Recommended Org', key: 'companyName'},
			{title: 'Recommended Org Domain', key: 'companyDomain'},
			{title: 'Status', key: 'status'}
		];
	}

	get supportsPaging() {
		return false; // demo users endpoint doesn't support paging
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {selectedOrg, columnData} = this.state;
		let params = this.processParameters();
		AdminEndpoints.fetchPreRecommendedPartnerOrgs(selectedOrg.orgId)
		.then((result) => {
			if (this.unmounted) return;
			if (this.hasRowData(result)) {
				result.payload = SortHelper.filterSearchObjectContains(params.search, result.payload, SortHelper.keysFromColumns(columnData));
				if (params.sort) result.payload = SortHelper.sortByNullLast(result.payload, params.sort);
			}
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		return (
			<div style={{backgroundColor: partnerTapQuaternary}}>
				<div style={{display: 'flex', justifyContent: 'center', padding: 10, backgroundColor: partnerTapWhite}}>
					<OrgSelector onOrgSelection={this.handleOrgSelection} addAllOption={true}/>
				</div>
				{Boolean(this.state.selectedOrg) && super.render()}
			</div>
		);
	}
}

export default connect()(AdminPreRecommendedOrgsPage);
