import PersistenceHelper from '../helpers/PersistenceHelper';
import * as ReduxEnum from './ReduxEnum';

export function setCurrentCompanyName(payload) {
	return {type: ReduxEnum.MANAGER_COMPANY_NAME, payload};
}

export function setCurrentRepName(payload) {
	return {type: ReduxEnum.MANAGER_REP_NAME, payload};
}

export function setSearchRange(payload) {
	return {type: ReduxEnum.MANAGER_SEARCH_RANGE, payload};
}

export function setAdminDivision(payload) {
	return {type: ReduxEnum.MANAGER_ADMIN_DIVISION, payload};
}

export function setManagerDivision(payload) {
	return {type: ReduxEnum.MANAGER_MANAGER_DIVISION, payload};
}

export function setDivisionStatus(payload) {
	return {type: ReduxEnum.MANAGER_DIVISION_STATUS, payload};
}

export function clearDivisionStatus() {
	return {type: ReduxEnum.CLEAR_MANAGER_DIVISION_STATUS};
}

export function setForceAllTime(payload) {
	return {type: ReduxEnum.MANAGER_SET_FORCE_ALL_TIME, payload};
}

export function setDivisionGroup(group) {
	PersistenceHelper.setValue(ReduxEnum.MANAGER_DIVISION_GROUP, group);
}

export function getDivisionGroup() {
	return PersistenceHelper.getValue(ReduxEnum.MANAGER_DIVISION_GROUP);
}
