import * as ApiHelper from '../helpers/ApiHelper';
import EnvHelper from '../helpers/EnvHelper';

export function fetchChatToken(partnerId, saleAccountLocationId) {
	let endpoint = '/chat/auth';
	let params = [];
	if (partnerId) params.push('partnerId=' + partnerId);
	if (saleAccountLocationId) params.push('saleAccountLocationId=' + saleAccountLocationId);
	return ApiHelper.sendGet(ApiHelper.appendParams(endpoint, params));
}

export function fetchChatLatest(subscriberId, saleAccountLocationId) {
	let endpoint = '/chat/latest/v2';
	let params = [];
	if (subscriberId) params.push('subscriberId=' + subscriberId);
	if (saleAccountLocationId) params.push('saleAccountLocationId=' + saleAccountLocationId);
	params.push('isChannel=' + EnvHelper.inChannelEcosystemProduct);
	return ApiHelper.sendGet(ApiHelper.appendParams(endpoint, params));
}

export function fetchChat(personChatId) {
	return ApiHelper.sendGet('/chat/' + personChatId);
}

export function fetchChatHistory(channelId, page, pageSize) {
	let endpoint = '/chat/history/' + channelId;
	let params = [];
	if (page) params.push('page=' + page);
	if (pageSize) params.push('size=' + pageSize);
	if (EnvHelper.isSpoofing) params.push('isSpoofing=true');
	return ApiHelper.sendGet(ApiHelper.appendParams(endpoint, params));
}

export function fetchUnreadChats() {
	return ApiHelper.sendGet('/chat/history/unread/count');
}

export function createOrUpdateChatToRead(messageId, channelId, message, timeStamp, publisherId) {
	let endpoint = '/chat/history/markread';
	let params = [];
	if (messageId) params.push('messageId=' + messageId);
	if (channelId) params.push('channelId=' + channelId);
	if (message) params.push('message=' + message);
	if (timeStamp) params.push('timeStamp=' + timeStamp);
	if (publisherId) params.push('publisherId=' + publisherId);
	return ApiHelper.sendGet(ApiHelper.appendParams(endpoint, params));
}
