import {AdminPanelSettings, ArrowBack, VerifiedUser} from '@mui/icons-material';
import React, {Component} from 'react';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import {tempFeatureFlag_CoSellEngine} from '../../../helpers/FeatureHelper';
import {partnerTapSecondaryLight, partnerTapStroke, partnerTapTernary, partnerTapWhite} from '../../../styles/partnertap_theme';
import LinkButton from '../../../ui/buttons/LinkButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import {
	WORKFLOW_CRM_TRACKING,
	WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL,
	WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL,
	WORKFLOW_INTRO_EMAIL,
} from '../co_sell_engine/CoSellEngineHelper';
import DetailHeader from '../shared/DetailHeader';
import CoSellAdminPage from './CoSellAdminPage';
import PartnerOrgCrmId from './PartnerOrgCrmId';
import Saml from './Saml';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';
import ApiKeysManagementPage from './ApiKeysManagementPage';
import AdminPartnerUpdatePage from './AdminPartnerUpdatePage';
import {queryClient} from '../../App';
import {fetchWorkflows} from '../../../endpoints/CoSellEngineEndpoints';

export class AdminCenter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			workflows: {},
			fetchStatus: "loading"
		};
	}

	async componentDidMount() {
		try {
			const workflows = await queryClient.fetchQuery("all-workflows", fetchWorkflows);
			this.setState({
				workflows: workflows,
				fetchStatus: "done"
			});
		}
		catch {
			this.setState({fetchStatus: "error"});
		}
	}

	getWorkflowLabel(workflowName) {
		const {workflows} = this.state;
		return workflows?.[workflowName]?.label || "";
	}

	get content() {
		return [
			{
				title: 'Feature Settings',
				sections:
					[
						{
							linkTitle: 'Account Mapping',
							title: 'Customize Account Mapping Algorithms',
							content:
								<div>
									PartnerTap's account mapping algorithms map on DUNS number when available, and on a combination of account name and account
									location information when the DUNS number is not available.
									<div style={{padding: 10}}/>
									The PartnerTap account mapping algorithms can be easily updated to also map on any custom field or fields you want it to
									use.
									<div style={{padding: 10}}/>
									To customize the account mapping logic to include custom fields from your CRM system simply contact customer support
									at {this.supportLink}.
								</div>
						},
						{
							linkTitle: 'Default Partners',
							title: 'Pre-populate Your Default Partners',
							content:
								<div>
									We're happy to pre-populate your default partners so all your users see the companies you want them to account map with when
									they first login.
									<div style={{padding: 10}}/>
									To pre-populate your list of partners in PartnerTap just email customer support at {this.supportLink}.
								</div>
						},
						{
							linkTitle: 'Partner Sourced',
							title: 'Customize Your Partner-sourced Pipeline Rules',
							content:
								<div>
									With PartnerTap it will soon be easy to see your partner-sourced pipeline within our reports. We track partnership activity
									to identify new opportunities that were likely sourced through your partnership efforts and flag these for channel teams to
									review.
									<div style={{padding: 10}}/>
									This feature is available to customers that connect directly to their CRM system.
									<div style={{padding: 10}}/>
									Customers can customize the timeframe for this recent partnership activity.
									<div style={{padding: 10}}/>
									Simply email customer support at {this.supportLink} and we will set the partner-sourced pipeline rules to the time frame
									that's right for your business.
								</div>
						},
						{
							linkTitle: 'Partner Influenced',
							title: 'Customize Your Partner-influenced Pipeline Rules',
							content:
								<div>
									With PartnerTap it will soon be easy to see your partner-influenced pipeline within our reports. By default, we assume that
									opportunities that progressed within three months of partners being mapped to an account, and teams collaborating on an
									account, are likely accelerated through this partnership work. Three months is the default definition of "recent",
									but you can customize this timeframe based on your typical sales cycle.
								</div>
						},
						{
							linkTitle: 'Enterprise Sales Network',
							title: 'Request Enterprise Sales Network',
							sideNavTitle: 'Enterprise Sales',
							content:
								<div>
									With PartnerTap's sales product you can let reps invite and connect with partners on their own,
									or turn on co-selling with a strategic partner.
									<div style={{padding: 10}}/>
									When you and a strategic partner are ready to turn on rep-to-rep co-selling between your companies,
									simply contact us at {this.supportLink}.
									Once we have confirmed sign-off from each side, we will turn on rep-to-rep co-selling between your companies.
								</div>
						}
					]
			},
			{
				title: 'Users & Roles',
				sections:
					[
						{
							linkTitle: 'Configure SAML',
							title: 'Add SAML SSO support',
							content: <Saml/>
						},
						{
							linkTitle: 'Manage Users',
							title: 'Add and Manage Users',
							content:
								<div>
									Email {this.supportLink} anytime you need to add or remove a user and we will handle it ASAP.
									<div style={{padding: 10}}/>
									You should also email anytime you want to change a user's permissions and access level.
								</div>
						},
						{
							linkTitle: 'Manage Profiles',
							title: 'Create and Manage Profiles',
							content:
								<div>
									PartnerTap comes with default profiles for Partner Managers, Channel Sellers, and Direct Sales Reps. It is also easy to
									create custom profiles to give users access to the specific functionality and data you want them to see within the product.
									<div style={{padding: 10}}/>
									Email {this.supportLink} anytime you need to modify or create a custom profile and we'll get that setup for you ASAP.
								</div>
						},
						{
							linkTitle: 'Data Visibility',
							title: 'Customize Data Visibility Rules',
							content:
								<div>
									In PartnerTap, user roles control what types of records people can see in PartnerTap. For example, a Sales Rep can only see
									the accounts that they own, while a Partner Manager can see all accounts they have visibility to in their CRM.
									<div style={{padding: 10}}/>
									With PartnerTap you can also control what data you push back into your CRM for sales reps to see. For example, if you have
									sensitive partners that you want to protect from your sales teams, you can flag the sensitive partners and their mapped
									account information will not be pushed back into your CRM.
									<div style={{padding: 10}}/>
									If you would like to change what type of records a user can see, or flag sensitive partners, simply
									email {this.supportLink} and we will make the change.
								</div>
						},
						{
							linkTitle: 'PII Consent',
							title: 'Manage User Consent To Share PII',
							content:
								<div>
									PII privacy laws require companies to protect personal data like contacts, leads, and employee contact information.
									Companies are responsible for obtaining employee consent to sell or share their data with partners.
									This is typically handled in the documents employees review and sign as part of their employment.
									<div style={{padding: 10}}/>
									Some companies want to give employees a choice to share their PII with partners.
									PartnerTap supports this scenario for protecting employee PII by allowing Administrators to upload lists of employees who
									have opted-in to share their PII data, so it can be masked when shared with partners.
									<div style={{padding: 10}}/>
									To upload a consent opt-in list, talk with your Customer Success Manager.
								</div>
						},
						{
							linkTitle: 'Reassign Partners',
							title: 'Reassign Connected Partners',
							content:
								<div>
									Employee turnover is a fact of life for every business. When a Partner Manager or Sales Rep changes roles or leaves the
									company you need to transfer their partner relationships to the new person for a smooth transition.
									<div style={{padding: 10}}/>
									We can reassign the connected partner relationships to your new person when someone leaves.
									<div style={{padding: 10}}/>
									Email {this.supportLink} and we can process this for you on the day you need the reassignment to happen.
								</div>
						},
						{
							linkTitle: 'Usage Report',
							title: 'Login and Usage Report',
							content:
								<div>
									Want to see who's logging in to PartnerTap and what they're doing? We can send you a detailed user login and usage report
									that shows everything.
									<div style={{padding: 10}}/>
									Just email {this.supportLink} and we can email you the detailed login and usage report.
								</div>
						},
						{
							linkTitle: 'Invite Activity',
							title: 'Partner Invite Activity Report',
							content:
								<div>
									Curious to see who's sending the most invites and which partners have accepted?
									<div style={{padding: 10}}/>
									Email {this.supportLink} and we can send you a detailed partner invite activity report.
								</div>
						},
						{
							title: 'Billing Report',
							content:
								<div>
									We are happy to send you a detailed report showing your users and advanced feature usage.
									<div style={{padding: 10}}/>
									Email {this.supportLink} for a detailed billing report.
								</div>
						}
					]
			},
			{
				title: 'Co-Sell Automation',
				sections:
					[
						{
							isComingSoon: !tempFeatureFlag_CoSellEngine,
							linkTitle: 'Action Lists',
							title: 'Create Action Lists',
							content:
								<div>
									Action Lists are point-in-time snapshots of a set of matched records with a single partner. They can be created from any
									connected partner, partner spreadsheet, or partner org rollup report and saved to the Co-Sell Engine.
									<div style={{padding: 10}}/>
									Once an action list has been created, the lists can be run through workflows in the Co-Sell Engine to facilitate the
									distribution of records to be actioned by sales reps and/or partner sellers.
									<div style={{padding: 10}}/>
									To learn more about action lists and the Co-Sell Engine, please reach out to customer support at {this.supportLink}.
								</div>
						},
						// TODO:COLIN share action list is a v2 workflow
						// {
						// 	isComingSoon: !tempFeatureFlag_CoSellEngine,
						// 	title: workflows.find((workflow) => workflow.config === WORKFLOW_SHARE_ACTION_LIST).name,
						// 	page: <CoSellAdminPage workflowConfig={WORKFLOW_SHARE_ACTION_LIST}/>
						// },
						{
							isComingSoon: !tempFeatureFlag_CoSellEngine,
							title: `${this.getWorkflowLabel("SALES_PLAY")} Workflow`,
							sideNavTitle: this.getWorkflowLabel("SALES_PLAY"),
							page: <CoSellAdminPage workflowConfig={WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL}/>
						},
						{
							isComingSoon: !tempFeatureFlag_CoSellEngine,
							title: `${this.getWorkflowLabel("THRU_CHANNEL_PLAY")} Workflow`,
							sideNavTitle: this.getWorkflowLabel("THRU_CHANNEL_PLAY"),
							page: <CoSellAdminPage workflowConfig={WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL}/>
						},
						{
							isComingSoon: !tempFeatureFlag_CoSellEngine,
							title: `${this.getWorkflowLabel("COSELL_PLAY")} Workflow`,
							sideNavTitle: this.getWorkflowLabel("COSELL_PLAY"),
							page: <CoSellAdminPage workflowConfig={WORKFLOW_INTRO_EMAIL}/>
						},
						{
							isComingSoon: !tempFeatureFlag_CoSellEngine,
							title: `${this.getWorkflowLabel("CRM_TRACKING")} Workflow`,
							sideNavTitle: this.getWorkflowLabel("CRM_TRACKING"),
							page: <CoSellAdminPage workflowConfig={WORKFLOW_CRM_TRACKING}/>
						}
					]
			},
			{
				title: 'Security Controls',
				sections:
					[
						{
							linkTitle: 'Enterprise Sharing',
							title: 'Customize Your Enterprise Sharing Rules',
							content:
								<div>
									When you turn on rep-to-rep co-selling in PartnerTap you can set up your sharing rules with each strategic partner company.
									<div style={{padding: 10}}/>
									For example, with Partner A, your default sharing rule may be all mapped accounts and open opportunities,
									while with Partner B it may be only mapped customers and prospects, with no opportunity data shared by default.
									<div style={{padding: 10}}/>
									PartnerTap's support team will set your default co-sell sharing rules when you and a strategic partner are ready to turn on
									rep-to-rep co-selling. Just reach out to {this.supportLink}.
								</div>
						},
						{
							linkTitle: 'Exclusion Lists',
							title: 'Add Sensitive Accounts to Your Exclusion List',
							content:
								<div>
									If your company has a few sensitive accounts that should never be shared with a partner simply add them to your account
									exclusion list. Once a company is added to this list we will ensure that those accounts are never pulled into PartnerTap and
									never shared with a partner.
									<div style={{padding: 10}}/>
									Email {this.supportLink} to add a company to your exclusion list.
								</div>
						},
						{
							linkTitle: 'Protected Fields',
							title: 'Block Sensitive Fields',
							content:
								<div>
									Every company has fields on their account records that are sensitive, and some are so sensitive that you want to ensure they
									are never pulled into PartnerTap so they can never be shared.
									<div style={{padding: 10}}/>
									If you have a field you want to block just email {this.supportLink} and we will block it from coming in to PartnerTap.
								</div>
						},
						{
							linkTitle: 'Excluded Partner Orgs',
							title: 'Block Companies From Partnering With Your Teams',
							content:
								<div>
									There may be companies that should never get data shared with them via PartnerTap.
									<div style={{padding: 10}}/>
									With PartnerTap you can identify companies that you want to block from ever partnering with your employees on PartnerTap
									<div style={{padding: 10}}/>
									Simply email {this.supportLink} and we can add these companies to your excluded partners list.
								</div>
						},
						{
							linkTitle: 'Sensitive Partners',
							title: 'Protect Sensitive Partners From Sales',
							content:
								<div>
									Some companies have strong partnerships with companies that may compete in some way with your direct sales team.
									<div style={{padding: 10}}/>
									With PartnerTap it's easy to protect the mapped account data for sensitive partners by simply not pushing their mapped
									account data back into your CRM.
									<div style={{padding: 10}}/>
									If you have sensitive partners that you want to map with, but also protect from sales, just email {this.supportLink} and we
									can make sure their mapped account data is not pushed back into your CRM.
								</div>
						}
					]
			},
			{
				title: 'Data',
				sections:
					[
						{
							linkTitle: 'Connect to CRM',
							title: 'Connect PartnerTap With Your CRM',
							route: Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE
						},
						{
							linkTitle: 'Link to CRM',
							title: 'Link Partner Orgs to CRM Accounts',
							content:
								<div>
									Link your partner company records in PartnerTap to their account record in your CRM.
									This will allow users to link directly to your CRM record for this partner from within PartnerTap.
								</div>,
							page: <PartnerOrgCrmId/>
						},
						{
							linkTitle: 'View Partner Sheets',
							title: 'Partner Sheets',
							page: <AdminPartnerUpdatePage/>
						},
						{
							linkTitle: 'Enrich Data',
							title: 'Enrich Account Data',
							content:
								<div>
									With PartnerTap's Enrich Account Data feature, you can bring in additional account data points that help to surface the best
									accounts to target with specific partners and sales plays.
									<div style={{padding: 10}}/>
									This data is not used in the account mapping algorithm but can be shared with partners or used in PartnerTap reports.
									Including additional account data points increases your ability to create hyper-target account lists to action with your
									teams and partners.
									<div style={{padding: 10}}/>
									To enrich your existing accounts with new data points, click into the Data Manager and click on the Enrich Accounts tile.
									Be sure to include a column with a unique account identifier that was used in your original data set.
									<div style={{padding: 10}}/>
									If you need help enriching you account data, please reach out to customer support at {this.supportLink}.
								</div>
						},
						{
							linkTitle: 'API Keys',
							title: 'API Keys',
							page: <ApiKeysManagementPage/>
						},
						{
							linkTitle: 'Current Audits',
							title: 'See Matching Audits In Progress',
							content:
								<div>
									PartnerTap has the most sophisticated account mapping algorithms in the industry. On top of that we offer matching audits to
									visually review all un-matched accounts to ensure you identify every last possible account and opportunity with each
									partner.
									<div style={{padding: 10}}/>
									When you want to see the progress of matching audits that are underway, simply email {this.supportLink} and we'll send you
									an in-progress report.
								</div>
						},
						{
							linkTitle: 'Past Audits',
							title: 'Review Past Matching Audits',
							content:
								<div>
									PartnerTap has the most sophisticated account mapping algorithms in the industry. On top of that we offer matching audits to
									visually review all un-matched accounts to ensure you identify every last possible account and opportunity with each
									partner.
									<div style={{padding: 10}}/>
									To see a summary of your past matching audits simply email {this.supportLink} and we'll send you a detailed matching audit
									report.
								</div>
						}
					]
			}
		];
	}

	get groupFromParams() {
		let group = parseInt(EnvHelper.getUrlParam('group'));
		if (group >= 0) {
			return this.content[group];
		}
		return null;
	}

	get sectionFromParams() {
		let group = this.groupFromParams;
		let section = parseInt(EnvHelper.getUrlParam('section'));
		if (group && section >= 0) {
			return group.sections[section];
		}
		return null;
	}

	get supportLink() {
		return <LinkButton label={'support@partnertap.com'} onClick={() => window.location = 'mailto:support@partnertap.com'}/>;
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	routeToSection(groupIndex, sectionIndex) {
		EnvHelper.push(
			Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.SECTION_DETAIL.ROUTE + '?group='
			+ groupIndex + '&section=' + sectionIndex);
	}

	renderContentGroups() {
		return (
			<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
				{this.content.map((group, index) => {
					return (
						<div key={index} style={{
							background: partnerTapWhite,
							border: '1px solid ' + partnerTapStroke,
							borderRadius: 5,
							margin: 10,
							minWidth: 300
						}}>
							<div style={{
								background: partnerTapSecondaryLight,
								borderRadius: 5,
								fontWeight: 'bold',
								whiteSpace: 'nowrap',
								padding: 10,
								margin: 2
							}}>
								{group.title}
							</div>
							{this.renderContentSections(group.sections, index)}
						</div>
					);
				})}
			</div>
		);
	}

	renderContentSections(sections, groupIndex) {
		return (
			<div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
				{sections.map((section, index) => {
					return (
						<LinkButton key={index}
									label={
										<div style={{padding: 5}}>
											{section.linkTitle || section.title}
											{section.isComingSoon &&
											 <span style={{color: partnerTapTernary}}> - Coming soon</span>}
										</div>}
									onClick={() => {
										if (section.route) {
											EnvHelper.push(section.route);
										}
										else {
											this.routeToSection(groupIndex, index);
										}
									}}/>
					);
				})}
			</div>
		);
	}

	renderContentSection(section) {
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				{section.content &&
				 <div style={{display: 'flex', flexDirection: 'column', background: partnerTapWhite, margin: 10, padding: 20, maxWidth: 600}}>
					 {section.content}
				 </div>}
				{section.page}
			</div>
		);
	}

	render() {
		let group = this.groupFromParams;
		let section = this.sectionFromParams;
		let title = (group && section) ? group.title + ': ' + section.title : 'Admin Center';
		return (
			<ScrollingContainer divId={'admin_center_page'}>
				<DetailHeader MaterialIcon={section ? VerifiedUser : AdminPanelSettings}
							  title={title}
							  detailRight={section &&
										   <LinkButton label={'Admin Center'} onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE)}/>
							  }/>
				<div style={{display: 'flex', justifyContent: 'space-around', overflow: 'scroll'}}>
					{!section ?
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<MessageBoxInfo hideIcon={true}>
								PartnerTap administrators can use the Admin Center to enable
								specific features, manage users, control data visibility and
								sharing rules, and run reports to monitor usage.
							</MessageBoxInfo>
							{this.renderContentGroups()}
						</div>
						:
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							{this.renderContentSection(section)}
							<SecondaryButton label={'Back to Admin Center'}
											 icon={<ArrowBack/>}
											 onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE)}/>

						</div>}
				</div>
				<div style={{padding: 5}}/>
			</ScrollingContainer>
		);
	}
}

export default AdminCenter;