import PropTypes from 'prop-types';
import React, {Component} from 'react';
import NumberHelper from '../helpers/NumberHelper';
import StringHelper from '../helpers/StringHelper';
import {partnerTapAppBackground, partnerTapDefaultText, partnerTapPrimary, partnerTapSecondary, partnerTapWhite} from '../styles/partnertap_theme';

class GraphLegend extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {sectionClicked: 0};
		this.props.sections.forEach((section) => section.active = true);
	}

	contrastingColor(color) {
		if (!color) return partnerTapDefaultText;
		return (this.luma(color) >= 165) ? partnerTapDefaultText : partnerTapWhite;
	}

	luma(color) // color can be a hex string or an array of RGB values 0-255
	{
		let rgb = (typeof color === 'string') ? this.hexToRGBArray(color) : color;
		return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
	}

	hexToRGBArray(color) {
		if (color.length === 7) {
			color = color.replace('#', '');
		}
		if (color.length === 3) {
			color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
		}
		else if (color.length !== 6) {
			throw ('Invalid hex color: ' + color);
		}
		let rgb = [];
		for (let i = 0; i <= 2; i++) {
			rgb[i] = parseInt(color.substr(i * 2, 2), 16);
		}
		return rgb;
	}

	render() {
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				{this.props.sections.map((section, index) => {
					let fillContrastingColor = this.contrastingColor(section.fill);
					let hasClick = this.props.parentHasClick || this.props.sectionClick;
					return (
						<div key={'legend-' + section.name + index}
							 style={{cursor: hasClick ? 'pointer' : 'default', color: hasClick ? partnerTapSecondary : partnerTapDefaultText}}
							 onClick={() => {
								 if (this.props.sectionClick) {
									 section.active = !section.active;
									 this.props.sectionClick(section);
									 this.setState({sectionClicked: section.key});
								 }
							 }}>
							<div style={{display: 'flex', alignItems: 'center', paddingTop: index === 0 ? 0 : 10, width: this.props.width}}>
								<div style={{
									backgroundColor: section.fill || partnerTapPrimary,
									border: '1px solid ' + (section.active ? partnerTapDefaultText : partnerTapAppBackground),
									marginRight: 10,
									minWidth: 30,
									minHeight: 30,
									fontSize: 12,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: 3,
									color: fillContrastingColor
								}}>
									{NumberHelper.formatNumber(section.value)}
								</div>
								{StringHelper.truncateWithToolTip(section.name, 32)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

GraphLegend.defaultProps = {
	height: 'auto',
	width: 'auto'
};

GraphLegend.propTypes = {
	sections: PropTypes.array.isRequired,
	sectionClick: PropTypes.func,
	parentHasClick: PropTypes.bool
};

export default GraphLegend;
