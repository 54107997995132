import React, {Component} from 'react';
import Dialog from '../../../ui/Dialog';
import PropTypes from 'prop-types';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import * as RestAdminCenterEndPoints from '../../../endpoints/RestAdminCenterEndPoints';
import Loading from '../../../ui/Loading';
import {partnerTapAlert, partnerTapWarn} from '../../../styles/partnertap_theme';
import PopoverSearchList from '../../../ui/PopoverSearchList';
import {Warning} from '@mui/icons-material';
import TextInputBox from '../../../ui/TextInputBox';
import EnvHelper from '../../../helpers/EnvHelper';

const NO_PARTNER_ORG = 'no_partner_org';
const NEW_PARTNER_ORG = 'new_partner_org';

class AdminPartnerReassignDialog extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			orgList: [],
			selectedOrg: null,
			uploadError: null,
			newPartnerOrgName: null,
			showNewPartnerOrgInput: false,
			isNewPartnerOrgNameMissing: false,
			showVisibleOnlyToMeNote: false,
			reassignmentInProgress: false,
			unmounted: false
		};
		this.onEdit = this.onEdit.bind(this);
	}

	componentDidMount() {
		this.getPartnerOrgs();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	bulkUpdateSheets(payload) {
		RestAdminCenterEndPoints.reassignPartnerOrgs(payload)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({reassignmentInProgress: false});
			this.props.onClose(true);
		})
		.catch((error) => {
			this.setState({reassignmentInProgress: false, uploadError: 'Error occurred while reassigning the org'});
			EnvHelper.serverError('Error from onUpload', error);
		});
	}

	getPartnerOrgs = () => {
		this.setState({loading: true});
		ChannelMappingEndpoints.getChannelPartnerOrgs()
		.then((result) => {
			if (this.unmounted) return;
			let {payload} = result;
			payload.unshift({orgCode: NO_PARTNER_ORG, name: 'VISIBLE ONLY TO ME'});
			payload.unshift({orgCode: NEW_PARTNER_ORG, name: 'NEW PARTNER ORG'});
			this.setState({orgList: payload, loading: false});
		});
	};

	onEdit() {
		let {orgList, selectedOrg, newPartnerOrgName} = this.state;
		const selectedPersonQueuePublicIds = this.props.selectedSheets;
		if (!selectedOrg) {
			this.setState({uploadError: 'Please select a Partner Org'});
			return;
		}
		if (selectedOrg.orgCode === NEW_PARTNER_ORG && !newPartnerOrgName) {
			this.setState({isNewPartnerOrgNameMissing: true});
			return;
		}
		if (newPartnerOrgName) {
			let existingOrg = orgList.find(org => org.name.trim().toLowerCase() === newPartnerOrgName.trim().toLowerCase());
			if (existingOrg) {
				selectedOrg = existingOrg;
				newPartnerOrgName = null;
			}
		}
		this.setState({reassignmentInProgress: true});
		if (newPartnerOrgName) {
			let newCompanyPartnerPublicId = null;
			ChannelMappingEndpoints.addNewPartnerOrg(newPartnerOrgName)
			.then((result) => {
				newCompanyPartnerPublicId = result.payload;
				this.bulkUpdateSheets({
					personQueuePublicIds: selectedPersonQueuePublicIds,
					companyPartnerPublicId: newCompanyPartnerPublicId,
					partnerOrgCode: null
				});
			})
			.catch((error) => {
				EnvHelper.serverError('Error from onUpload', error);
				this.setState({reassignmentInProgress: false, uploadError: 'Error occurred while adding new Company Partner'});
			});
		}
		else {
			let cpPublicId = null, selectedOrgCode = null;
			if (selectedOrg.orgCode !== 'no_partner_org') {
				cpPublicId = selectedOrg.companyPartnerPublicId;
				selectedOrgCode = selectedOrg.orgCode;
			}
			this.bulkUpdateSheets({
				personQueuePublicIds: selectedPersonQueuePublicIds,
				companyPartnerPublicId: cpPublicId,
				partnerOrgCode: selectedOrgCode
			});
		}
	}

	render() {
		const {onClose, selectedSheets} = this.props;
		let {newPartnerOrgName, loading} = this.state;
		const selectedSheetsCount = selectedSheets.length;
		return (<Dialog
			title="Reassign Partner Sheet Org"
			message={
				(loading) ?
					<Loading>Loading Partner Orgs...</Loading>
					:
					<div>
						{this.state.reassignmentInProgress &&
						 <div style={{
							 position: 'absolute',
							 marginLeft: 'auto',
							 marginRight: 'auto',
							 left: 0,
							 right: 0,
							 backgroundColor: 'rgba(255, 255, 255, 0.8)',
							 borderRadius: 20
						 }}>
							 <Loading>Reassignment is in progress</Loading>
						 </div>}

						<div style={{display: 'flex', flexDirection: 'column', minWidth: 700, maxWidth: 700}}>
							{this.state.uploadError &&
							 <div style={{fontSize: 16, color: partnerTapAlert, textAlign: 'center'}}>
								 {this.state.uploadError}
							 </div>}
							<div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, width: 700}}>
								You have selected {`${selectedSheetsCount} partner ${selectedSheetsCount > 1 ? 'sheets' : 'sheet'} `} to reassign. Please select
								the org you want to assign them to.
							</div>
							<div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
								<div style={{width: '35%'}}>New Org Assignment</div>
								<PopoverSearchList label={'Select Partner Org'}
												   list={this.state.orgList}
												   preselectedItem={this.state.selectedOrg}
												   labelRenderer={(org) => org.name}
												   valueRenderer={(org) => org.orgCode}
												   onItemSelected={(org) => {
													   this.setState({
														   selectedOrg: org,
														   showNewPartnerOrgInput: org.orgCode === NEW_PARTNER_ORG,
														   showVisibleOnlyToMeNote: org.orgCode === NO_PARTNER_ORG,
														   uploadError: null,
														   newPartnerOrgName: null
													   });
												   }}
												   searchByObjectKeys={['name']}
												   width={400}/>
							</div>
							{this.state.showVisibleOnlyToMeNote &&
							 <div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, width: 700}}>
								 <Warning style={{color: partnerTapWarn}}/>
								 VISIBLE ONLY TO ME accounts will not be accessible to others, nor included in Ecosystem Reports or managed package
								 syncs.
							 </div>}
							{this.state.showNewPartnerOrgInput &&
							 <div style={{display: 'flex', padding: 10, alignItems: 'center'}}>
								 <div style={{width: '35%'}}>Partner Org Name</div>
								 <TextInputBox hintText={'Partner Org Name'}
											   errorText={this.state.isNewPartnerOrgNameMissing ? 'Partner Org Name is required' : ''}
											   onChange={(value) => this.setState({
												   newPartnerOrgName: value,
												   uploadError: null,
												   isNewPartnerOrgNameMissing: false
											   })}
											   maxWidth={400} /*Calculated as <65% of 700*/
											   maxChars={255}/>
							 </div>}
						</div>
					</div>
			}
			onClose={() => onClose(false)}
			yesLabel={'REASSIGN'}
			yesAction={this.onEdit}
			noLabel={'CANCEL'}
			noAction={() => onClose(false)}
		/>);
	}
}

AdminPartnerReassignDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	selectedSheets: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AdminPartnerReassignDialog;
