import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';

class ReportMenu extends Component {

	render() {
		return (
			<DropdownMenu title={'Report'}
						  options={this.props.reportTypes}
						  optionLabel={'reportTitle'}
						  selectedOption={this.props.currentReportType}
						  onSelect={this.props.onUpdateReportType}/>
		);
	}
}

ReportMenu.propTypes = {
	reportTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentReportType: PropTypes.object.isRequired,
	onUpdateReportType: PropTypes.func.isRequired
};

export default ReportMenu;
