import {PersonAdd, PersonSearch} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as PartnersEndpoints from '../../../endpoints/PartnersEndpoints';
import {CHANNEL_SALES_REP} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import AuthHelper from '../../../helpers/AuthHelper';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import PartnerStatusHelper from '../../../helpers/PartnerStatusHelper';
import {partnerTapTernary} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import PagingBase from '../../../ui/lists/PagingBase';
import PartnerConnectionTileRenderer from './parnter_connections/PartnerConnectionTileRenderer';

class PartnerRecommendationsPage extends PagingBase {

	componentWillUnmount() {
		this.unmounted = true;
	}

	get storageKeyBase() {
		return 'ecosystem_recommendations';
	}

	get title() {
		return 'Recommended Partners';
	}

	get icon() {
		return PersonSearch;
	}

	get columnData() {
		return [
			{title: 'Name', key: 'partnerName'},
			{title: 'Title', key: 'ptJobTitle'},
			{title: 'Organization', key: 'partnerOrganization'},
			{title: 'Status', key: 'partnerStatusMessage', sortDisabled: true, active: this.isChannelRep},
			{title: 'Actions', key: ACTIONS, renderFunction: (columnValue, rowData) => PartnerStatusHelper.renderAction(rowData, true)}
		];
	}

	get isChannelRep() {
		return AuthHelper.userProfile.roleCode === CHANNEL_SALES_REP;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		PartnersEndpoints.fetchPartnerRecommendations(params.page, params.search, params.sort)
		.then((result) => {
			if (this.unmounted) return;
			if (this.hasRowData(result)) {
				result.payload.forEach((partnerRecommendation) => {
					partnerRecommendation.partnerName = partnerRecommendation.firstName + ' ' + partnerRecommendation.lastName;
					partnerRecommendation.id = partnerRecommendation.partnerId;
					if (this.isChannelRep) {
						partnerRecommendation.partnerStatusMessage =
							<div style={{display: 'flex', alignItems: 'center', color: partnerTapTernary, fontSize: 16, fontWeight: 'bold'}}>
								{partnerRecommendation.accountMatchCount ? partnerRecommendation.accountMatchCount : 'No'} Matched Accounts
							</div>;
					}
				});
			}
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						We don't have any partner recommendations for you at the moment.
					</div>
					<div>
						Recommendations are updated daily, so check again soon!
					</div>
					<div style={{padding: 10}}>
						<PrimaryButton label={'INVITE PARTNERS'}
									   icon={<PersonAdd/>}
									   onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.PATH())}/>
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	get tileRenderer() {
		return PartnerConnectionTileRenderer;
	}
}

export default connect()(PartnerRecommendationsPage);
