import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import EnvHelper from '../../../../helpers/EnvHelper';
import {ASSET_TYPE_ACCOUNTS, ASSET_TYPE_OPPS} from '../../../../helpers/ReportHelper';
import SortHelper from '../../../../helpers/SortHelper';
import Dialog from '../../../../ui/Dialog';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import PartnerConnectionShareEditor from './PartnerConnectionShareEditor';

class PartnerConnectionShare extends Component {

	constructor(props, context) {
		super(props, context);

		this.assetTypeIsDirty = {SHARE_ASSET_TYPE_ACCOUNTS: false, SHARE_ASSET_TYPE_OPPORTUNITIES: false, SHARE_ASSET_TYPE_LEADS: false};
		this.state = {loading: true, shareCounts: null, shareColumns: null, showSaveBeforeLeavingDialog: false};

		this.fetchShareSettings = this.fetchShareSettings.bind(this);
	}

	componentDidMount() {
		this.unblock = EnvHelper.history.block((path) => {
			if (!this.isDirty || this.leaveConfirmed || EnvHelper.isSpoofing || EnvHelper.wasSpoofing) {
				this.unmounted = true;
				return true;
			}
			this.setState({showSaveBeforeLeavingDialog: true, leaveToPath: path.pathname});
			return false;
		});
		this.fetchShareSettings();
	}

	componentWillUnmount() {
		this.unblock();
		this.unmounted = true;
	}

	get sharingTypeData() {
		let {shareSettings} = this.props;
		let {shareColumns, shareCounts} = this.state;
		return [
			{
				shareAssetType: ASSET_TYPE_ACCOUNTS,
				shareSettings: shareSettings.accountShareSettings,
				shareOptions: shareSettings.accountShareSettings.availableShareOptions,
				sharableColumns: shareColumns.accountSharableColumns.sharableColumns,
				sharedCounts: shareCounts.accountSharedCounts
			},
			{
				shareAssetType: ASSET_TYPE_OPPS,
				shareSettings: shareSettings.oppShareSettings,
				shareOptions: shareSettings.oppShareSettings.availableShareOptions,
				sharableColumns: shareColumns.oppSharableColumns.sharableColumns,
				sharedCounts: shareCounts.oppSharedCounts
			}
		];
	}

	get partnerPersonId() {
		return this.props.partner.personId;
	}

	fetchShareSettings() {
		Promise.all([
			ChannelMappingEndpoints.getSharingCounts(this.partnerPersonId),
			ChannelMappingEndpoints.getSharedColumns(this.partnerPersonId)
		])
		.then((results) => {
			if (this.unmounted) return;
			if (results[0]) {
				this.setState({shareCounts: results[0].payload});
			}
			if (results[1]) {
				let shareColumns = results[1].payload;
				let accountSharableColumns = SortHelper.sortByNullLast(shareColumns.accountSharableColumns.sharableColumns, 'prettyName', 'asc');
				let oppSharableColumns = SortHelper.sortByNullLast(shareColumns.oppSharableColumns.sharableColumns, 'prettyName', 'asc');
				shareColumns.accountSharableColumns.sharableColumns = accountSharableColumns;
				shareColumns.oppSharableColumns.sharableColumns = oppSharableColumns;
				this.setState({shareColumns: shareColumns});
			}
			this.setState({loading: false});
		})
		.catch((error) => {
			console.error(error);
		});
	}

	setIsDirty(assetType, isDirty) {
		this.assetTypeIsDirty[assetType] = isDirty;
	}

	get isDirty() {
		let isDirty = false;
		Object.keys(this.assetTypeIsDirty).forEach((assetType) => isDirty = isDirty || this.assetTypeIsDirty[assetType]);
		return isDirty;
	}

	render() {
		if (this.state.loading) return <Loading>Loading Share Settings...</Loading>;
		return (
			<ScrollingContainer divId={'ecosystem_share_settings'}>
				{this.state.showSaveBeforeLeavingDialog &&
				 <Dialog title={'Save Changes?'}
						 message={
							 <div>
								 You made changes to your share settings, but haven't saved them.
								 <div style={{padding: 5}}/>
								 To update the way you share, CLOSE this dialog and press UPDATE SHARING.
							 </div>}
						 yesLabel={'LEAVE WITHOUT SAVING'}
						 yesAction={() => {
							 this.leaveConfirmed = true;
							 EnvHelper.push(this.state.leaveToPath);
						 }}
						 noLabel={'CLOSE'}
						 noAction={() => {
							 this.setState({showSaveBeforeLeavingDialog: false});
						 }}/>}
				<div style={{display: 'flex', flexDirection: 'column', height: '100%', overflow: 'scroll', margin: 10}}>
					<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-start'}}>
						{this.sharingTypeData.map((shareData) => {
							return <PartnerConnectionShareEditor key={shareData.shareAssetType}
																 partner={this.props.partner}
																 setDirtyFunction={(isDirty) => this.setIsDirty(shareData.shareAssetType, isDirty)}
																 shareData={shareData}/>;
						})}
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

PartnerConnectionShare.propTypes = {
	partner: PropTypes.object.isRequired,
	shareSettings: PropTypes.shape({
		accountShareSettings: PropTypes.shape({
			availableShareOptions: PropTypes.array,
			shareAssetType: PropTypes.string,
			ownerAssetShareOption: PropTypes.string,
			partnerAssetShareOption: PropTypes.string,
			filters: PropTypes.object
		}).isRequired,
		oppShareSettings: PropTypes.shape({
			availableShareOptions: PropTypes.array,
			shareAssetType: PropTypes.string,
			ownerAssetShareOption: PropTypes.string,
			partnerAssetShareOption: PropTypes.string,
			filters: PropTypes.object
		}).isRequired
	}).isRequired
};

export default withRouter(connect()(PartnerConnectionShare));
