import React, {Component} from 'react';
import MessageBoxAlert from '../../ui/messages/MessageBoxAlert';
import SupportButton from '../../ui/buttons/SupportButton';

class NoDataWarning extends Component {

	render() {
		return (
			<MessageBoxAlert title={'We have no data for your subordinates yet'}>
				<div>
					Analytics data is delayed up to 24 hours.
				</div>
				<div>
					Your direct reports may not have logged into PartnerTap yet.
				</div>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, fontSize: 16}}>
					Please reach out to us if you have any questions!
					<SupportButton/>
				</div>
			</MessageBoxAlert>
		);
	}
}

export default NoDataWarning;
