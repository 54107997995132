import {Email, PersonAdd} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import {PRODUCT_SALES_NETWORK} from '../../../helpers/FeatureHelper';
import * as AuthActions from '../../../redux/AuthActions';
import {partnerTapSecondary, partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import EmailInvite, {EMAIL_INVITE_NOTE} from '../../../ui/EmailInvite';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import PageHeader from '../../../ui/PageHeader';

export class PartnerInvitePage extends Component {

	constructor(props, context) {
		super(props, context);
		this.endTutorial = this.endTutorial.bind(this);
		this.state = {emailInput: '', noteInput: '', shareOpps: true, statusMessage: '', error: false};
	}

	endTutorial() {
		EnvHelper.push(Routes.DASHBOARD.PARTNERS.WELCOME.ROUTE);
		this.props.dispatch(AuthActions.setWelcomeMode(false));
	}

	render() {
		let profile = this.props.authState.profile;
		let userNote = EMAIL_INVITE_NOTE + '-' + profile.firstName;
		return (
			<ScrollingContainer divId={'partner_invite_page'}>
				{this.props.authState.welcomeMode &&
				 <div style={{color: partnerTapSecondary, backgroundColor: partnerTapWhite}}>
					 <div style={{margin: 'auto', padding: 20, paddingBottom: 0}}>
						 <PrimaryButton label={'NEXT'} onClick={this.endTutorial} disabled={this.state.sending}/>
					 </div>
					 <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
						 <Email fontSize={'large'}/>
						 <h3 style={{lineHeight: '30px'}}>
							 <i>You can also invite any of your partners<br/>to join PartnerTap via email...</i>
						 </h3>
					 </div>
					 <hr style={{margin: 0, marginTop: 10}}/>
				 </div>}

				<PageHeader title={'Invite Partners'} icon={<PersonAdd fontSize={'large'}/>}/>
				<div style={{display: 'flex', justifyContent: 'center', overflow: 'scroll'}}>
					<div style={{width: 600}}>
						<div style={EmailInvite.emailContainerStyle}>
							<EmailInvite productCode={PRODUCT_SALES_NETWORK} defaultNote={userNote}/>
						</div>
					</div>
				</div>
				<div style={{padding: 5}}/>
			</ScrollingContainer>
		);
	}
}

PartnerInvitePage.propTypes = {
	authState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(PartnerInvitePage);
