import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as AuthEndpoints from '../../endpoints/AuthEndpoints.js';
import {DEMO, SFDC} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import {LOGIN_SIGN_UP_TYPE_KEY} from '../../globals/StorageKeys';
import AuthHelper from '../../helpers/AuthHelper';
import EnvHelper from '../../helpers/EnvHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapAlert} from '../../styles/partnertap_theme';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../ui/buttons/SecondaryButton';
import SupportButton from '../../ui/buttons/SupportButton';
import LogoMessage from '../../ui/messages/LogoMessage';

const defaultSignupType = SFDC;
const RESPONSE_CODE = 'responseCode';

class OAuthPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {responseCode: null, redirect: null};
		this.onLogOut = this.onLogOut.bind(this);
		this.login = this.login.bind(this);
	}

	componentDidMount() {
		this.code = this.props.match.params.token || EnvHelper.getUrlParam('code');
		this.signUpType = this.props.match.params.signupType || PersistenceHelper.getValue(LOGIN_SIGN_UP_TYPE_KEY) || defaultSignupType;
		this.login();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get errorMessage() {
		let messages = [];
		messages.push(this.props.match.params.error, EnvHelper.getUrlParam('error'), EnvHelper.getUrlParam('error_description'));
		messages = messages.filter(message => message !== null && message !== '');
		return messages ? messages.join(', ') : null;
	}

	login() {
		if (this.code || this.signUpType === DEMO) {
			AuthEndpoints.createAccount(decodeURIComponent(this.code), this.signUpType)
			.then((result) => {
				if (this.unmounted) return;
				if (result && result.payload && result.payload.code === 428) {
					EnvHelper.serverError("Error from createAccount", new Error(result.payload.message), result.payload.code);
					return Promise.reject(RESPONSE_CODE);
				}
				if (result && result.payload && result.payload.responseCode) {
					this.setState({responseCode: result.payload.responseCode, redirect: result.payload.redirect, signUpType: result.payload.signUpType});
					return Promise.reject(RESPONSE_CODE);
				}
				return AuthHelper.handleAfterLoginPerson(result.payload);
			})
			.then((result) => {
				if (this.unmounted) return;
				return AuthHelper.handleAfterLoginProfile(result.payload);
			})
			.then((result) => {
				if (this.unmounted) return;
				AuthHelper.handleAfterChannelWizardSteps(result.payload);
			})
			.catch((error) => {
				if (error !== RESPONSE_CODE) {
					EnvHelper.serverError('Error from createAccount', error);
					EnvHelper.push(Routes.LOGOUT.EXPIRED.ROUTE_TRUE);
				}
			});
		}
		else {
			console.error('Error from loging: auth code required');
		}
	}

	onLogOut() {
		this.props.dispatch(AuthActions.logout());
		this.props.dispatch(AuthActions.clearServerError());
		EnvHelper.push(Routes.HOME.ROUTE);
	}

	render() {
		let message = 'Authenticating...';
		let action;
		if (this.errorMessage) {
			action =
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10}}>
					<div style={{fontSize: 20}}>Sorry, an error occurred</div>
					<div style={{color: partnerTapAlert}}>{this.errorMessage}</div>
					<PrimaryButton label={'LOG OUT'} onClick={this.onLogOut}/>
					<SupportButton/>
				</div>;
		}
		else if (this.state.responseCode) {
			message = 'Authentication Issue';
			action =
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 15}}>
					<div style={{fontSize: 20, maxWidth: 285, textAlign: 'center'}}>
						{AuthHelper.getDisplayString(this.state.responseCode)}
					</div>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 15}}>
						<SecondaryButton label={'CANCEL'} onClick={() => location.href = "/"}/>
						{AuthHelper.getSSOButton(this.state.signUpType, this.state.redirect)}
					</div>
				</div>;
		}
		return <LogoMessage message={message} action={action}/>;
	}
}

OAuthPage.propTypes = {
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

export default withRouter(connect()(OAuthPage));
