import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as DataManagerEndpoints from '../../../endpoints/DataManagerEndpoints';
import {OPPORTUNITIES_ASSET} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapAlert, partnerTapAppBackground, partnerTapStroke, partnerTapTernary, partnerTapWhite} from '../../../styles/partnertap_theme';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import SupportButton from '../../../ui/buttons/SupportButton';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import Stepper from '../../../ui/Stepper';

class DataManagerWizardPageStep2 extends Component {

	constructor(props, context) {
		super(props, context);

		this.crmType = this.props.match.params.crmType;
		this.state = {
			loading: false,
			valid: true,
			error: null,
			assetTypes: {
				ACCOUNTS_ASSET: true,
				OPPORTUNITIES_ASSET: true
			},
			steps: [
				{label: 'Choose CRM', active: false, complete: true},
				{label: 'Customize Data', active: true, complete: false},
				{label: 'Sync CRM', active: false, complete: false}
			]
		};

		this.handleWizardStep2 = this.handleWizardStep2.bind(this);
	}

	componentDidMount() {
		if (DataManagerHelper.crmTypeSynced) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get tileWidth() {
		return 250;
	}

	get tileHeight() {
		return 350;
	}

	handleWizardStep2() {
		this.setState({loading: true});
		DataManagerEndpoints.wizardStep2(this.crmType, this.state.assetTypes)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload && result.payload.code) {
				this.setState({authenticated: false, error: result.message, loading: false});
			}
			else {
				EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_3.PATH(this.crmType));
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from wizardStep2', error);
		});
	}

	handleAssetCheckbox(key) {
		let assetTypes = {...this.state.assetTypes};
		assetTypes[key] = !assetTypes[key];
		this.setState({assetTypes: assetTypes});
	}

	renderStep() {
		return (
			<div style={TableHelper.tileStyle(this.tileWidth, this.tileHeight, partnerTapTernary)}>
				{DataManagerHelper.getCrmIcon(this.crmType)}
				<div>
					Select your asset types, then press SYNC CRM below
				</div>
				<div>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<CheckboxButton checked={true} disabled={true}/>
						<div style={{fontSize: 16, fontWeight: 'bold'}}>Accounts</div>
					</div>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<CheckboxButton checked={this.state.assetTypes[OPPORTUNITIES_ASSET]}
										onChange={() => this.handleAssetCheckbox(OPPORTUNITIES_ASSET)}/>
						<div style={{fontSize: 16, fontWeight: 'bold'}}>Opportunities</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let upsell = DataManagerHelper.dataManagerCrmWizardAccess;
		if (upsell) return upsell;

		let errorMessage = this.state.error;
		return (
			<ScrollingContainer divId={'data_manager_wizard_page'}>
				<Stepper steps={this.state.steps}/>
				{errorMessage &&
				 <Dialog title={'Sorry, an error occurred'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								 <div style={{color: partnerTapAlert, padding: 20, wordWrap: 'break-word', maxWidth: 600}}>
									 {errorMessage}
								 </div>
								 <SupportButton/>
							 </div>}
						 yesAction={() => this.setState({error: null})}/>}
				<div style={{
					margin: 10,
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					overflow: 'scroll',
					border: 'solid 1px' + partnerTapStroke,
					borderRadius: 10,
					backgroundColor: partnerTapAppBackground
				}}>
					{this.state.loading ? <Loading>Loading...</Loading> : this.renderStep()}
				</div>
				<div style={{flex: 1}}/>
				<div style={{
					backgroundColor: partnerTapWhite,
					padding: 20,
					display: 'flex',
					gap: 10,
					justifyContent: 'center',
					borderTop: '1px solid ' + partnerTapStroke
				}}>
					<SecondaryButton label={'CANCEL'} onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE)}/>
					<PrimaryButton label={'SYNC CRM'}
								   onClick={this.handleWizardStep2}/>
				</div>
			</ScrollingContainer>
		);
	}
}

DataManagerWizardPageStep2.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(DataManagerWizardPageStep2));
