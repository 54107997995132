// Auth
export const LOGIN_PERSON = 'LOGIN_PERSON';
export const LOGIN_PROFILE = 'LOGIN_PROFILE';
export const CHANNEL_WIZARD_STEPS = 'CHANNEL_WIZARD_STEPS';
export const LOGOUT = 'LOGOUT';
export const AGREE_TO_TERMS = 'AGREE_TO_TERMS';
export const CLEAR_FIRST_LOGIN_MESSAGE = 'CLEAR_FIRST_LOGIN_MESSAGE';
export const SERVER_ERROR = 'SERVER_ERROR';
export const CLEAR_SERVER_ERROR = 'CLEAR_SERVER_ERROR';
export const WELCOME_MODE = 'WELCOME_MODE';
export const NEW_VERSION_AVAILABLE = 'NEW_VERSION_AVAILABLE';
export const SET_CHANNEL_SUBORDINATES = 'SET_CHANNEL_SUBORDINATES';
export const REFRESH_APP = 'REFRESH_APP';

// Intel (a.k.a. Chat)
export const SHOW_CHAT = 'SHOW_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const HIDE_CHAT = 'HIDE_CHAT';

// Manager
export const MANAGER_COMPANY_NAME = 'MANAGER_COMPANY_NAME';
export const MANAGER_SEARCH_RANGE = 'MANAGER_SEARCH_RANGE';
export const MANAGER_REP_NAME = 'MANAGER_REP_NAME';
export const MANAGER_DIVISION_STATUS = 'MANAGER_DIVISION_STATUS';
export const CLEAR_MANAGER_DIVISION_STATUS = 'CLEAR_MANAGER_DIVISION_STATUS';
export const MANAGER_ADMIN_DIVISION = 'MANAGER_ADMIN_DIVISION';
export const MANAGER_MANAGER_DIVISION = 'MANAGER_MANAGER_DIVISION';
export const MANAGER_SET_FORCE_ALL_TIME = 'MANAGER_SET_FORCE_ALL_TIME';
export const MANAGER_DIVISION_GROUP = 'MANAGER_DIVISION_GROUP';

// Notification
export const RECEIVED_NOTIFICATION = 'RECEIVED_NOTIFICATION';
export const UPDATE_ROUTE_FROM_NOTIFICATION = 'UPDATE_ROUTE_FROM_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const PARTNER_REQUEST_COUNT = 'PARTNER_REQUEST_COUNT';

// Registration
export const REGISTRATION_TEMP_PROFILE = 'REGISTRATION_TEMP_PROFILE';
export const REGISTRATION_CREATED_USER_EMAIL = 'REGISTRATION_CREATED_USER_EMAIL';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
