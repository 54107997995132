import {CloudSync, Delete, History} from '@mui/icons-material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as DataManagerEndpoints from '../../../endpoints/DataManagerEndpoints';
import {Routes} from '../../../globals/Routes';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_DATA_MANAGER} from '../../../helpers/FeatureHelper';
import UploadHelper from '../../../helpers/UploadHelper';
import {partnerTapAppBackground, partnerTapStroke} from '../../../styles/partnertap_theme';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import PageHeader from '../../../ui/PageHeader';
import DashboardTable from '../shared/DashboardTable';

class DataManagerSyncSummary extends Component {

	constructor(props, context) {
		super(props, context);

		this.signUpType = this.props.match.params.signupType;
		this.state = {loading: true};

		this.getSyncSummary = this.getSyncSummary.bind(this);
		this.syncArchive = this.syncArchive.bind(this);
	}

	componentDidMount() {
		this.getSyncSummary();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get columnData() {
		return [
			{title: 'Sync Date', key: 'createdDate', active: true, renderFunction: DateHelper.epochToDate},
			{title: 'Status', key: 'status', active: true, renderFunction: UploadHelper.getUploadStatus},
			{title: 'Total Records', key: 'numAccounts', active: true},
			{title: 'Total Rows Processed', key: 'totalRowsProcessed', active: true},
			{title: 'CRM Type', key: 'crmType', active: true, renderFunction: DataManagerHelper.getCrmName},
			{title: 'Asset Type', key: 'importType', active: true, renderFunction: DataManagerHelper.getAssetType}
		];
	}

	getSyncSummary() {
		DataManagerEndpoints.syncSummary()
		.then((result) => {
			if (this.unmounted) return;
			this.setState({syncSummary: result.payload, loading: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getSyncSummary', error);
		});
	}

	syncArchive() {
		DataManagerEndpoints.syncArchive()
		.then((result) => {
			if (this.unmounted) return;
			DataManagerHelper.crmTypeSynced = null;
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from syncArchive', error);
		});
	}

	render() {
		let {syncSummary, loading} = this.state;
		let firstSync = syncSummary && syncSummary[0];
		let importType = firstSync ? firstSync.importType : 'Unknown';
		let importUser = firstSync ? firstSync.userName : 'Unknown';
		let importDate = firstSync ? firstSync.createdDate : 'Unknown';
		let canDelete = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER);
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<PageHeader title={'CRM Sync Summary'} icon={<CloudSync fontSize={'large'}/>}/>
				<ScrollingContainer divId={'data_manager_sync_summary'}>
					<div style={{
						margin: 10,
						overflow: 'scroll',
						border: 'solid 1px' + partnerTapStroke,
						borderRadius: 10,
						backgroundColor: partnerTapAppBackground
					}}>
						{loading ?
							<Loading>Loading CRM Sync Summary...</Loading>
							:
							<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
								<div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 20, padding: 20}}>
									{DataManagerHelper.getCrmIcon(importType)}
									<div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: 10}}>
										<div style={{fontSize: 24}}>
											{DataManagerHelper.getCrmName(importType)} Connection
										</div>
										<div style={{display: 'flex', alignItems: 'center', fontSize: 16}}>
											<div style={{fontWeight: 'bold', width: 110}}>
												Imported By:
											</div>
											{importUser}
										</div>
										<div style={{display: 'flex', alignItems: 'center', fontSize: 16}}>
											<div style={{fontWeight: 'bold', width: 110}}>
												Date Created:
											</div>
											{DateHelper.epochToDate(importDate)}
										</div>
									</div>
									{canDelete &&
									 <div style={{display: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-start'}}>
										 <SecondaryButton label={'DELETE CONNECTION'}
														  icon={<Delete/>}
														  onClick={this.syncArchive}
														  disabled={EnvHelper.isSpoofing || !syncSummary || syncSummary.length === 0}
														  confirmationMessage={
															  <div style={{maxWidth: 400}}>
																  You are attempting to remove your CRM connection. Removing this connection will delete all
																  data brought into partnerTap via CRM. Are you sure you want to continue?
															  </div>}/>
									 </div>}
								</div>
								<div style={{padding: 20, paddingTop: 0}}>
									<DashboardTable key={'data_manager_sync_history'}
													MaterialIcon={History}
													title={'CRM Sync History'}
													columnData={this.columnData}
													rowData={syncSummary}
													hasScrollingContainerParent={true}/>
								</div>
							</div>}
					</div>
				</ScrollingContainer>
			</div>
		);
	}
}

export default connect()(DataManagerSyncSummary);
