import {History, UploadFile} from '@mui/icons-material';
import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Routes} from '../../../../globals/Routes';
import SubNav from '../../../../ui/navigation/SubNav';
import SheetUploadPage from '../../shared/sheets/SheetUploadPage';
import SheetColumnMapping from './SheetColumnMapping';
import ProcessUpload from './ProcessUpload';
import SheetUploadHistoryPage from './SheetUploadHistoryPage';
import {UPLOAD_DATA_MANAGER_ENRICH_SHEET, UPLOAD_DATA_MANAGER_SHEET, UPLOAD_PII_SHARING_OPT_IN} from '../../../../globals/Enums';
import DataManagerHelper from '../../../../helpers/DataManagerHelper';

class SheetUploadRouter extends Component {

	constructor(props, context) {
		super(props, context);
		let {uploadType} = this.props.match.params;
		this.subNavData = [
			{
				label: 'Upload',
				MaterialIcon: UploadFile,
				route: Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(uploadType),
				activeRoutes: [
					Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.ROUTE,
					Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.ROUTE
				]
			},
			{
				label: 'History',
				MaterialIcon: History,
				route: Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.PATH(uploadType)
			}
		];
	}

	get uploadType() {
		return this.props.match.params.uploadType;
	}

	get isDataManagerSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_SHEET;
	}

	get isDataManagerEnrichSheet() {
		return this.uploadType === UPLOAD_DATA_MANAGER_ENRICH_SHEET;
	}

	get isPiiOptIn() {
		return this.uploadType === UPLOAD_PII_SHARING_OPT_IN;
	}

	render() {
		if (this.isDataManagerSheet && DataManagerHelper.dataManagerAddAccountsAccess) return DataManagerHelper.dataManagerAddAccountsAccess;
		if (this.isDataManagerEnrichSheet && DataManagerHelper.dataManagerEnrichAccountsAccess) return DataManagerHelper.dataManagerEnrichAccountsAccess;
		if (this.isPiiOptIn && DataManagerHelper.dataManagerPiiAccess) return DataManagerHelper.dataManagerPiiAccess;

		return (
			<div key={this.props.location.pathname} style={{display: 'flex', flexDirection: 'column'}}>
				<SubNav subNavData={this.subNavData}/>
				<Switch>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.UPLOAD.ROUTE} component={SheetUploadPage}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.UPLOAD.COLUMN_MAPPING.ROUTE} component={SheetColumnMapping}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.UPLOAD.PROCESS_UPLOAD.ROUTE} component={ProcessUpload}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.UPLOAD.HISTORY.ROUTE} component={SheetUploadHistoryPage}/>
				</Switch>
			</div>
		);
	}
}

export default withRouter(SheetUploadRouter);
