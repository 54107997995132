import {UploadFile} from '@mui/icons-material';
import React, {Component} from 'react';
import EnvHelper from '../../../../helpers/EnvHelper';
import {partnerTapSecondary} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';

class DropzoneContent extends Component {

	render() {
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<UploadFile style={{color: partnerTapSecondary, fontSize: 60}}/>
				<div style={{padding: 10}}>
					or
				</div>
				<PrimaryButton label={'SELECT CSV FILE'} disabled={EnvHelper.isSpoofing}/>
			</div>
		);
	}
}

export default DropzoneContent;
