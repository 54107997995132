import {
	CATEGORY_COMPANY_PARTNER_ACCOUNTS,
	CATEGORY_COMPANY_PARTNER_OPPS,
	CATEGORY_GLOBAL_REPORTS,
	CATEGORY_MAPPED_ACCOUNTS,
	CATEGORY_PARTNER_ACCOUNTS,
	CATEGORY_PARTNER_OPPS,
	CATEGORY_SHEET_ACCOUNTS,
	CATEGORY_SHEET_OPPS
} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';

class SavedReportBase {

	constructor(row) {
		this.row = row;
		this.parsedReportParams = JSON.parse(row.reportParams);
		this.parsedSettings = JSON.parse(row.settings);
		this.errorMessage = null;
	}

	get reportUrl() {
		const reportCategoryUrlMap = new Map([
			[CATEGORY_GLOBAL_REPORTS, Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.ROUTE],
			[CATEGORY_PARTNER_ACCOUNTS, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.BASE_ROUTE],
			[CATEGORY_PARTNER_OPPS, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.BASE_ROUTE],
			[CATEGORY_SHEET_ACCOUNTS, Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.BASE_ROUTE],
			[CATEGORY_SHEET_OPPS, Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.BASE_ROUTE],
			[CATEGORY_MAPPED_ACCOUNTS, Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.BASE_ROUTE],
			[CATEGORY_COMPANY_PARTNER_ACCOUNTS, Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.BASE_ROUTE],
			[CATEGORY_COMPANY_PARTNER_OPPS, Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.BASE_ROUTE]
		]);
		let url = reportCategoryUrlMap.get(this.reportCategory);
		if (url.endsWith('/')) {
			url = url.substring(0, url.length - 1);
		}
		return url;
	}

	get reportCategory() {
		return this.row.reportCategory;
	}

	get reportType() {
		return this.row.reportType;
	}

	get reportParams() {
		return this.parsedReportParams;
	}

	get reportSettings() {
		return this.parsedSettings;
	}

	get reportId() {
		return this.row.id;
	}

	get path() {
		throw new Error('SavedReportBase subclasses must override getPath method');
	}

	get params() {
		return 'savedReportId=' + this.reportId + '&filter=' + encodeURIComponent(JSON.stringify(this.reportSettings.filter));
	}

	async validateReport() {
		return true;
	}
}

export default SavedReportBase;
