import {History} from '@mui/icons-material';
import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../../globals/Routes';
import {INVITED_CONTACT_KEY} from '../../../globals/StorageKeys';
import EnvHelper from '../../../helpers/EnvHelper';
import PersistenceHelper from '../../../helpers/PersistenceHelper';
import SortHelper from '../../../helpers/SortHelper';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import PagingBase from '../../../ui/lists/PagingBase';
import PartnerInviteRowRenderer from './PartnerInviteRowRenderer';

class PartnerInviteHistoryPage extends PagingBase {

	get storageKeyBase() {
		return 'partner_invite_history_page';
	}

	get title() {
		return 'Invite History';
	}

	get icon() {
		return History;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let invitedList = this.getInvitedList();
		let result = {payload: []};
		invitedList.forEach((email) => result.payload.push({id: email, firstName: email, inviteSent: true}));
		result.payload = SortHelper.filterSearchObjectContains(params.search, result.payload, ['id']);
		setTimeout(() => this.processData(params, result));
	}

	getInvitedList() {
		let invitedEmails = PersistenceHelper.getValue(INVITED_CONTACT_KEY);
		if (_.isEmpty(invitedEmails)) {
			return [];
		}
		return invitedEmails.split(',');
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						You haven't sent any partner invites yet.
					</div>
					<PrimaryButton label={'EMAIL INVITES'} onClick={() => EnvHelper.push(Routes.DASHBOARD.PARTNERS.INVITE.ROUTE)}/>
				</div>
			);
		}
		return super.noDataMessage;
	}

	get rowRenderer() {
		return PartnerInviteRowRenderer;
	}
}

export default connect()(PartnerInviteHistoryPage);
