import {HubOutlined} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES} from '../../../helpers/ReportHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';

class MyOpportunitiesPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES};
		this.state.filterSelectorMounted = false;
	}

	componentDidMount() {
		this.fetchCompanyOpportunityColumnData();
	}

	get storageKeyBase() {
		return 'my_opportunities';
	}

	get title() {
		return 'My Opps';
	}

	get icon() {
		return HubOutlined;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{personId: this.personId, selectedColumns: this.activeColumns, channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			0)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	fetchCompanyOpportunityColumnData() {
		ChannelMappingEndpoints.getColumns({channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCompanyOpportunityColumns', error);
		});
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		return super.render();
	}
}

export default connect()(MyOpportunitiesPage);
