import {Help} from '@mui/icons-material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {SUPERADMIN} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapAlert, partnerTapSecondaryMedium, partnerTapWhite} from '../../styles/partnertap_theme';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../ui/buttons/SecondaryButton';
import Dialog from '../../ui/Dialog';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import ScrimMessage from '../../ui/messages/ScrimMessage';
import ToolTipOverlay from '../../ui/ToolTipOverlay';
import AdminEditorSection from './shared/AdminEditorSection';
import OrgSelector from './shared/OrgSelector';

const STANDARD_ROLES = 'Standard Roles';
const CUSTOM_ROLES = 'Custom Roles';

class AdminRolesPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, standardRoles: null, customRoles: null, currentSection: null};

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
	}

	handleOrgSelection(org) {
		this.getRoles(org);
	}

	getRoles(org, fetchCompleteFunction) {
		Promise.all([AdminEndpoints.fetchRoles(), AdminEndpoints.fetchRoles(org.orgId)])
		.then((results) => {
			if (this.unmounted) return;
			let standardRoles = results[0].payload.filter((role) => role.roleCode !== SUPERADMIN);
			let customRoles = results[1].payload;
			this.setState({selectedOrg: org, standardRoles: standardRoles, customRoles: customRoles});
			if (fetchCompleteFunction) setTimeout(fetchCompleteFunction);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getRoles', error);
		});
	}

	view(role) {
		EnvHelper.push(Routes.ADMIN_TOOLS.ROLES.ROLE_DETAILS.PATH(this.state.selectedOrg.orgId, role.rolePublicId));
	}

	clone(role) {
		let {selectedOrg} = this.state;
		this.setState({updateInProgress: true});
		AdminEndpoints.cloneRole(selectedOrg.orgId, role.rolePublicId)
		.then((result) => {
			this.getRoles(this.state.selectedOrg, () => this.setState({updateInProgress: false, currentSection: CUSTOM_ROLES}));
		})
		.catch((error) => {
			this.setState({updateInProgress: false});
			EnvHelper.serverError('Error from cloneRole', error);
		});
	}

	delete(role) {
		this.setState({updateInProgress: true, roleForDeleteConfirmation: null});
		AdminEndpoints.deleteRole(role.rolePublicId)
		.then((result) => {
			if (result === 'true') {
				this.getRoles(this.state.selectedOrg, () => this.setState({updateInProgress: false, currentSection: CUSTOM_ROLES}));
			}
			else {
				this.setState({updateInProgress: false, roleForDeleteFailed: role});
			}
		})
		.catch((error) => {
			this.setState({updateInProgress: false});
			EnvHelper.serverError('Error from deleteRole', error);
		});
	}

	renderSection(sectionName, sectionValues) {
		return (
			<AdminEditorSection sectionName={sectionName}
								sectionValues={sectionValues}
								isOpen={this.state.currentSection === sectionName}
								viewFunction={() => this.setState({currentSection: sectionName})}
								closeFunction={() => this.setState({currentSection: null})}/>
		);
	}

	render() {
		let {selectedOrg, standardRoles, customRoles, updateInProgress, roleForDeleteConfirmation, roleForDeleteFailed} = this.state;
		return (
			<div>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: partnerTapWhite}}>
					<OrgSelector onOrgSelection={this.handleOrgSelection}/>
				</div>
				{updateInProgress &&
				 <ScrimMessage message={'One moment please...'}/>}
				{Boolean(selectedOrg) &&
				 <ScrollingContainer divId={'admin_org_page'}>
					 <div id={'admin_org_page_scrolling_div'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
						 {this.renderSection(STANDARD_ROLES,
							 standardRoles.map((role) => {
								 return {
									 name:
										 <div style={{display: 'flex', alignItems: 'center'}}>
											 <ToolTipOverlay title={role.description}>
												 <Help style={{color: partnerTapSecondaryMedium, paddingRight: 10}}/>
											 </ToolTipOverlay>
											 {role.displayName}
										 </div>,
									 value:
										 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10}}>
											 <SecondaryButton label={'VIEW'} onClick={() => this.view(role)}/>
											 <SecondaryButton label={'CLONE'} onClick={() => this.clone(role)}/>
										 </div>
								 };
							 }))}
						 {this.renderSection(CUSTOM_ROLES,
							 customRoles.length ?
								 customRoles.map((role) => {
									 return {
										 name:
											 <div style={{display: 'flex', alignItems: 'center'}}>
												 <ToolTipOverlay title={role.description}>
													 <Help style={{color: partnerTapSecondaryMedium, paddingRight: 10}}/>
												 </ToolTipOverlay>
												 {role.displayName}
											 </div>,
										 value:
											 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10}}>
												 <SecondaryButton label={'EDIT'} onClick={() => this.view(role)}/>
												 <SecondaryButton label={'CLONE'} onClick={() => this.clone(role)}/>
												 <PrimaryButton label={'DELETE'}
																color={partnerTapAlert}
																onClick={() => this.setState({roleForDeleteConfirmation: role})}/>
											 </div>
									 };
								 })
								 :
								 [{name: 'To add a Custom Role, clone a Standard Role.'}]
						 )}
					 </div>
					 {roleForDeleteConfirmation &&
					  <Dialog title={'Please Confirm'}
							  message={'Are you sure you want to delete the custom role "' + roleForDeleteConfirmation.displayName + '"?'}
							  yesAction={() => this.delete(roleForDeleteConfirmation)}
							  noAction={() => this.setState({roleForDeleteConfirmation: null})}/>}

					 {roleForDeleteFailed &&
					  <Dialog title={'Role In Use'}
							  message={
								  <div>
									  The custom role "{roleForDeleteFailed.displayName}" is currently assigned to active users.<br/>
									  Please assign another role to all users with this role, then delete this role.
								  </div>}
							  yesAction={() => this.setState({roleForDeleteFailed: null})}/>}

					 <div style={{padding: 5}}/>
				 </ScrollingContainer>}
			</div>
		);
	}
}

export default connect()(AdminRolesPage);
