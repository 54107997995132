import {PRODUCT_ADMIN_TOOLS, PRODUCT_CHANNEL_ECOSYSTEM, PRODUCT_MANAGER_ANALYTICS, PRODUCT_SALES_NETWORK} from '../helpers/FeatureHelper';
import {UPLOAD_PRE_REG_USERS, UPLOAD_RECOMMENDED_PARTNER_ORGS} from './Enums';

export const Routes = {
	HOME: {
		PATH: (authState) => {
			let {person} = authState;
			let primaryProduct = person.primaryProduct;
			switch (primaryProduct.productCode) {
				case PRODUCT_CHANNEL_ECOSYSTEM:
					return Routes.CHANNEL_ECOSYSTEM.GETTING_STARTED_WIZARD.ROUTE;
				case PRODUCT_SALES_NETWORK:
					return Routes.DASHBOARD.PARTNERS.DISCOVER.ROUTE;
				case PRODUCT_MANAGER_ANALYTICS:
					return Routes.MANAGER_ANALYTICS.DASHBOARD.ROUTE;
				case PRODUCT_ADMIN_TOOLS:
					return Routes.ADMIN_TOOLS.REPORTS.ROUTE;
				default:
					return Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE;
			}
		},
		ROUTE: '/'
	},
	SANDBOX_HOME: {ROUTE: '/sandbox'},
	LOGIN: {
		ROUTE: '/login',
		USERNAME: {ROUTE: '/login/:username'}
	},
	DEMO_LOGIN: {
		ROUTE: '/demologin/:username?'
	},
	LOGOUT: {
		ROUTE: '/logout',
		EXPIRED: {ROUTE: '/logout/:expired?', ROUTE_TRUE: '/logout/true'}
	},
	OAUTH: {
		PATH: (signupType, token, error) => {
			if (error) {
				return `/oauth/${signupType}/${token}/${error}`;
			}
			else {
				return `/oauth/${signupType}/${token}`;
			}
		},
		ROUTE: '/oauth',
		AUTHENTICATE: {ROUTE: '/oauth/:signupType/:token?/:error?'}
	},
	SAML: {ROUTE: '/saml/:token?'},
	PROFILE: {ROUTE: '/profile'},
	SETTINGS: {ROUTE: '/settings'},
	ADMIN_TOOLS: {
		ROUTE: '/admin',
		ORGS: {
			ROUTE: '/admin/orgs',
			ORG_DETAILS: {
				BASE_ROUTE: '/admin/orgs/org_details/',
				ROUTE: '/admin/orgs/org_details/:orgId?',
				PATH: (orgId) => `/admin/orgs/org_details/${orgId}`
			},
			ACCOUNT_TAGS: {ROUTE: '/admin/orgs/account_tag'}
		},
		ROLES: {
			ROUTE: '/admin/roles',
			ROLE_DETAILS: {
				BASE_ROUTE: '/admin/roles/role_details/',
				ROUTE: '/admin/roles/role_details/:orgId/:roleId?',
				PATH: (orgId, roleId) => `/admin/roles/role_details/${orgId}/${roleId}`
			}
		},
		USERS: {
			DETAILS: {ROUTE: '/admin/users/details'},
			PRE_REG: {ROUTE: '/admin/users/pre_reg'},
			UPLOAD: {ROUTE: '/admin/users/upload/:uploadType', PATH: () => '/admin/users/upload/' + UPLOAD_PRE_REG_USERS}
		},
		PARTNERS: {
			EXCLUDED: {ROUTE: '/admin/partners/excluded'},
			RECOMMENDED_ORGS: {ROUTE: '/admin/partners/recommended_orgs'},
			AUTO_PARTNERED_ORGS: {ROUTE: '/admin/partners/auto_partnered_orgs'},
			PRE_RECOMMENDED_ORGS: {ROUTE: '/admin/partners/pre_recommended_orgs'},
			UPLOAD: {ROUTE: '/admin/partners/upload/:uploadType', PATH: () => '/admin/partners/upload/' + UPLOAD_RECOMMENDED_PARTNER_ORGS}
		},
		REPORTS: {ROUTE: '/admin/reports'},
		PROFILE: {ROUTE: '/admin/profile', PARTNER: {ROUTE: '/admin/profile/:personId?/:partnerId?'}}
	},
	MANAGER_ANALYTICS: {
		ROUTE: '/manager',
		DASHBOARD: {ROUTE: '/manager/dashboard'},
		UNMATCHED_ACCOUNTS_ANALYTICS: {ROUTE: '/manager/unmatchedaccountsanalytics'},
		MATCHED_ACCOUNTS_ANALYTICS: {ROUTE: '/manager/matchedaccountsanalytics'},
		CONNECTIONS_ANALYTICS: {ROUTE: '/manager/connectionsanalytics'},
		SALES_REP: {ROUTE: '/manager/salserep'},
		TAP: {
			ROUTE: '/manager/tap',
			ORG: {OUTBOUND: '/manager/tap/org/1', INBOUND: '/manager/tap/org/0'},
			OUTBOUND: '/manager/tap/1',
			INBOUND: '/manager/tap/0'
		},
		REP_INTEL: {ROUTE: '/manager/repintel'},
		INTEL_ANALYTICS: {ROUTE: '/manager/intelanalytics'},
		REPORTS: {ROUTE: '/manager/reports'},
		SETTINGS: {ROUTE: '/manager/settings'},
		PROFILE: {ROUTE: '/manager/profile', PARTNER: {ROUTE: '/manager/profile/:personId?/:partnerId?'}}
	},
	CHANNEL_ECOSYSTEM: {
		ROUTE: '/channel-mapping',
		GETTING_STARTED_WIZARD: {ROUTE: '/channel-mapping/getting-started-wizard'},
		PARTNER_ORGS: {
			ORGS: {
				ROUTE: '/channel-mapping/partners/orgs/:reportType?',
				PATH: (reportType) => {
					if (reportType) {
						return `/channel-mapping/partners/orgs/${reportType}`;
					}
					return '/channel-mapping/partners/orgs';
				}
			},
			ORG: {
				BASE_ROUTE: '/channel-mapping/partners/org/',
				ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/:page/:reportType?/:reportByLocation?',
				PATH: (companyPartnerPublicId, page, reportType, reportByLocation) => {
					let baseUri = `/channel-mapping/partners/org/${companyPartnerPublicId}/${page}`;
					if (reportType) {
						baseUri += '/' + reportType;
					}
					if (reportByLocation) {
						baseUri += '/' + reportByLocation;
					}
					return baseUri;
				}
			},
			SUMMARY: {ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/summary', PAGE_NAME: 'summary'},
			DETAILS: {ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/details', PAGE_NAME: 'details'},
			MAPPED_ACCOUNTS: {ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/accounts/:reportType?/:reportByLocation?', PAGE_NAME: 'accounts'},
			MAPPED_OPPS: {ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/opps/:reportType?/:reportByLocation?', PAGE_NAME: 'opps'},
			SHEETS_UPLOADED: {ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/sheets', PAGE_NAME: 'sheets'},
			CONNECTED_PARTNERS: {ROUTE: '/channel-mapping/partners/org/:companyPartnerPublicId/partners', PAGE_NAME: 'partners'}
		},
		PARTNER_CONNECTIONS: {
			PARTNERS: {
				ROUTE: '/channel-mapping/partners/connections'
			},
			PARTNER: {
				BASE_ROUTE: '/channel-mapping/partners/connection/',
				ROUTE: '/channel-mapping/partners/connection/:partnerId/:page/:reportType?/:reportByLocation?',
				PATH: (partnerId, page, reportType, reportByLocation) => {
					let baseUri = `/channel-mapping/partners/connection/${partnerId}/${page}`;
					if (reportType) {
						baseUri += '/' + reportType;
					}
					if (reportByLocation) {
						baseUri += '/' + reportByLocation;
					}
					return baseUri;
				},
				SUMMARY: {ROUTE: '/channel-mapping/partners/connection/:partnerId/summary', PAGE_NAME: 'summary'},
				SHARE: {ROUTE: '/channel-mapping/partners/connection/:partnerId/share/:advanced?', PAGE_NAME: 'share'},
				SHARED_ACCOUNTS: {ROUTE: '/channel-mapping/partners/connection/:partnerId/accounts/:reportType?/:reportByLocation?', PAGE_NAME: 'accounts'},
				SHARED_OPPORTUNITIES: {ROUTE: '/channel-mapping/partners/connection/:partnerId/opps/:reportType?/:reportByLocation?', PAGE_NAME: 'opps'},
				INTEL: {ROUTE: '/channel-mapping/partners/connection/:partnerId/intel', PAGE_NAME: 'intel'}
			},
			INVITE: {
				ROUTE: '/channel-mapping/partners/connections/invite/:personEmailId?',
				PATH: (personEmailId) => {
					let baseUri = '/channel-mapping/partners/connections/invite';
					if (personEmailId) {
						baseUri += '/' + personEmailId;
					}
					return baseUri;
				}
			},
			RECOMMENDATIONS: {
				ROUTE: '/channel-mapping/partners/connections/recommendations'
			},
			INTEL: {
				ROUTE: '/channel-mapping/partners/connections/intel',
				PARTNER: {
					ROUTE: '/channel-mapping/partners/connection/intel/:partnerPersonId?/:personChatId?',
					PATH: (partnerPersonId, personChatId) => `/channel-mapping/partners/connection/intel/${partnerPersonId}/${personChatId}`
				}
			}
		},
		PARTNER_SHEETS: {
			BASE_ROUTE: '/channel-mapping/sheet/',
			ROUTE: '/channel-mapping/sheet/:page/:personQueueId/:reportType?/:partnerOrgName?',
			PATH: (personQueueId, page, reportType, partnerOrgName) => {
				return `/channel-mapping/sheet/${page}/${personQueueId}/${reportType}/${partnerOrgName}`;
			},
			UPLOAD_HISTORY: {ROUTE: '/channel-mapping/upload-history'},
			DASHBOARD: {ROUTE: '/channel-mapping/sheet/dashboard/:personQueueId', PAGE_NAME: 'dashboard'},
			MAPPED_ACCOUNTS: {ROUTE: '/channel-mapping/sheet/mapped_accounts/:personQueueId/:reportType?', PAGE_NAME: 'mapped_accounts'},
			MAPPED_OPPS: {ROUTE: '/channel-mapping/sheet/mapped_opportunities/:personQueueId', PAGE_NAME: 'mapped_opportunities'},
			MAPPED_LOCATIONS: {ROUTE: '/channel-mapping/sheet/mapped_locations/:personQueueId/:reportType?', PAGE_NAME: 'mapped_locations'},
			UNMAPPED_ACCOUNTS: {ROUTE: '/channel-mapping/sheet/unmapped_accounts/:personQueueId', PAGE_NAME: 'unmapped_accounts'}
		},
		ACCOUNTS: {
			BASE_ROUTE: '/channel-mapping/accounts/',
			ROUTE: '/channel-mapping/accounts/:reportType?',
			PATH: (reportType) => {
				let baseUri = '/channel-mapping/accounts';
				if (reportType) {
					baseUri += '/' + reportType;
				}
				return baseUri;
			},
			ACCOUNT_DETAILS: {
				ROUTE: '/channel-mapping/accounts/account-details/:accountId?',
				PATH: (accountId) => `/channel-mapping/accounts/account-details/${accountId}`
			}
		},
		OPPORTUNITIES: {
			BASE_ROUTE: '/channel-mapping/opportunities/',
			ROUTE: '/channel-mapping/opportunities/:reportType?',
			OPPORTUNITY_DETAILS: {
				ROUTE: '/channel-mapping/opportunities/opportunity-details/:opportunityId?',
				PATH: (opportunityId) => `/channel-mapping/opportunities/opportunity-details/${opportunityId}`
			}
		},
		CO_SELL_ENGINE: {
			ROUTE: '/channel-mapping/co-sell/:page/:actionListId?/:workflowConfig?',
			OVERVIEW: {
				ROUTE: '/channel-mapping/co-sell/overview'
			},
			ACTION_LIST: {
				ROUTE: '/channel-mapping/co-sell/action-list/:actionListId',
				PATH: (actionListId) => '/channel-mapping/co-sell/action-list/' + actionListId
			},
			WORKFLOW_WIZARD: {
				ROUTE: '/channel-mapping/co-sell/workflow-wizard/:actionListId/:sequenceRecipePublicId',
				PATH: (actionListId, sequenceRecipePublicId) => '/channel-mapping/co-sell/workflow-wizard/' + actionListId + '/' + sequenceRecipePublicId
			},
			ACTION_LIST_RESULTS: {
				ROUTE: '/channel-mapping/co-sell/action-list-results/:actionListId',
				PATH: (actionListId) => `/channel-mapping/co-sell/action-list-results/${actionListId}`
			},
			ACTION_LIST_LIVE: {
				ROUTE: '/channel-mapping/co-sell/action-list/:actionListId/:workflowName?/:workflowSequenceName?',
				PATH: (actionListId, workflowName, workflowSequenceName) => '/channel-mapping/co-sell/action-list/' + actionListId +
																			(workflowName ? '/' + workflowName : '') +
																			(workflowSequenceName ? '/' + workflowSequenceName : '')
			},
			ACTION_LIST_RESULTS_LIVE: {
				ROUTE: '/channel-mapping/co-sell/action-list-results/:actionListId',
				PATH: (actionListId) => `/channel-mapping/co-sell/action-list-results/${actionListId}`
			},
			ACTION_LIST_EDIT_LIVE: {
				ROUTE: '/channel-mapping/co-sell/action-list/:actionListId/edit',
				PATH: (actionListId) => `/channel-mapping/co-sell/action-list/${actionListId}/edit`
			},
			WORKFLOW_LANDING_LIVE: {
				ROUTE: '/channel-mapping/co-sell/workflow-landing/:stepInstancePublicId'
			},
			DASHBOARD: {
				ROUTE: '/channel-mapping/co-sell/dashboard'
			},
			EMAIL_ACCESS: {
				ROUTE: '/channel-mapping/co-sell/email-access/:actionListId/:emailAddress/:securityKey/:authorPersonId'
			}
		},
		ECOSYSTEM: {
			DASHBOARD: {
				ROUTE: '/channel-mapping/ecosystem/dashboard'
			},
			GLOBAL: {
				ROUTE: '/channel-mapping/ecosystem/reports/global',
				REPORT: {
					ROUTE: '/channel-mapping/ecosystem/reports/global/:reportType?',
					PATH: (reportType) => {
						let baseUri = '/channel-mapping/ecosystem/reports/global';
						if (reportType) {
							baseUri += '/' + reportType;
						}
						return baseUri;
					}
				}
			},
			SAVED: {
				ROUTE: '/channel-mapping/ecosystem/reports/saved',
				PERSONAL: {ROUTE: '/channel-mapping/ecosystem/reports/saved/personal'},
				ORG: {ROUTE: '/channel-mapping/ecosystem/reports/saved/org'}
			},
			DOWNLOADED: {
				BASE_ROUTE: '/channel-mapping/ecosystem/reports/downloaded/',
				ROUTE: '/channel-mapping/ecosystem/reports/downloaded/:downloadId?',
				PATH: (downloadId) => {
					let baseUri = '/channel-mapping/ecosystem/reports/downloaded';
					if (downloadId) {
						baseUri += '/' + downloadId;
					}
					return baseUri;
				}
			}
		},
		ADMIN_CENTER: {
			ROUTE: '/channel-mapping/admin-center',
			SECTION_DETAIL: {ROUTE: '/channel-mapping/admin-center/section-detail'},
			PII_CONSENT: {ROUTE: '/channel-mapping/admin-center/pii-consent'},
			API_KEYS: {ROUTE: '/channel-mapping/admin-center/api-keys'}
		},
		DATA_MANAGER: {
			ROUTE: '/channel-mapping/data-manager',
			MY_ACCOUNTS: {
				ROUTE: '/channel-mapping/data-manager/list',
				LIST: {ROUTE: '/channel-mapping/data-manager/list/:personQueueId?'},
				PATH: (personQueueId) => personQueueId ? `/channel-mapping/data-manager/list/${personQueueId}` : '/channel-mapping/data-manager/list'
			},
			MY_OPPORTUNITIES: {
				ROUTE: '/channel-mapping/company-opportunities/list',
				LIST: {ROUTE: '/channel-mapping/company-opportunities/list'}
			},
			WIZARD: {
				STEP_1: {
					ROUTE: '/channel-mapping/data-manager/wizard-step1/:crmType?/:token?/:consentType?/:error?',
					PATH: (crmType, token, consentType, error) => {
						if (error) {
							return `/channel-mapping/data-manager/wizard-step1/${crmType}/${token}/${consentType}/${error}`;
						}
						if (crmType && token) {
							return `/channel-mapping/data-manager/wizard-step1/${crmType}/${token}/${consentType}`;
						}
						return '/channel-mapping/data-manager/wizard-step1';
					}
				},
				STEP_2: {
					ROUTE: '/channel-mapping/data-manager/wizard-step2/:crmType',
					PATH: (crmType) => {
						return `/channel-mapping/data-manager/wizard-step2/${crmType}`;
					}
				},
				STEP_3: {
					ROUTE: '/channel-mapping/data-manager/wizard-step3/:crmType',
					PATH: (crmType) => {
						return `/channel-mapping/data-manager/wizard-step3/${crmType}`;
					}
				}
			},
			SYNC_SUMMARY: {
				ROUTE: '/channel-mapping/data-manager/sync-summary'
			}
		},
		PROFILE: {
			ROUTE: '/channel-mapping/profile',
			PARTNER: {ROUTE: '/channel-mapping/profile/:personId?/:partnerId?'}
		},
		SETTINGS: {ROUTE: '/channel-mapping/settings'},
		UPLOAD: {
			ROUTE: '/channel-mapping/upload/:uploadType',
			PATH: (uploadType) => `/channel-mapping/upload/${uploadType}`,
			COLUMN_MAPPING: {
				ROUTE: '/channel-mapping/upload/:uploadType/column-mapping/:personUploadId/:step',
				PATH: (uploadType, personUploadId, step) => '/channel-mapping/upload/' + uploadType + '/column-mapping/' +
															(personUploadId ? personUploadId : ':personUploadId') +
															'/' + (step ? step : ':step')
			},
			PROCESS_UPLOAD: {
				ROUTE: '/channel-mapping/upload/:uploadType/process-upload/:personUploadId',
				PATH: (uploadType, personUploadId) => '/channel-mapping/upload/' + uploadType + '/process-upload/' +
													  (personUploadId ? personUploadId : ':personUploadId')
			},
			HISTORY: {
				ROUTE: '/channel-mapping/upload/:uploadType/history',
				PATH: (uploadType) => `/channel-mapping/upload/${uploadType}/history`
			}
		}
	},
	DASHBOARD: {
		ROUTE: '/dashboard',
		TAP: {
			ROUTE: '/dashboard/tap',
			ACTIVE: {ROUTE: '/dashboard/tap/active'},
			SHARE: {ROUTE: '/dashboard/tap/share'},
			SHARE_PARTNERS: {PATH: '/dashboard/tap/share/partners', ROUTE: '/dashboard/tap/share/partners/:personSaleAccountId'}
		},
		INTEL: {
			ROUTE: '/dashboard/intel',
			THREAD: {
				ROUTE: '/dashboard/intel/:partnerPersonId/:personSaleAccountId?/:saleAccountLocationId?/:personChatId?',
				PATH: (personChatId) => `/dashboard/intel/_/_/_/${personChatId}`
			}
		},
		ACCOUNTS: {
			ROUTE: '/dashboard/accounts',
			ALL: {ROUTE: '/dashboard/accounts/all'},
			OPEN: {ROUTE: '/dashboard/accounts/open'},
			ACCOUNT: {
				ROUTE: '/dashboard/accounts/:personSaleAccountId/:saleAccountLocationId',
				INTEL: {ROUTE: '/dashboard/accounts/:personSaleAccountId/:saleAccountLocationId/intel/:partnerPersonId?/:personChatId?'}
			}
		},
		PARTNERS: {
			ROUTE: '/dashboard/partners',
			WELCOME: {ROUTE: '/dashboard/partners/welcome'},
			ACTIVE: {ROUTE: '/dashboard/partners/active'},
			PENDING: {ROUTE: '/dashboard/partners/pending'},
			DISCOVER: {ROUTE: '/dashboard/partners/discover'},
			INVITE: {
				ROUTE: '/dashboard/partners/invite',
				HISTORY: {ROUTE: '/dashboard/partners/invite/history'}
			},
			PARTNER: {
				ROUTE: '/dashboard/partners/:partnerId/:partnerPersonId',
				INTEL: {ROUTE: '/dashboard/partners/:partnerId/:partnerPersonId/intel/:personSaleAccountId?/:saleAccountLocationId?/:personChatId?/:fromPartnerOpp?'}
			}
		},
		PROFILE: {ROUTE: '/dashboard/profile', PARTNER: {ROUTE: '/dashboard/profile/:personId?/:partnerId?'}},
		SETTINGS: {ROUTE: '/dashboard/settings'}
	},
	REGISTRATION: {
		ROUTE: '/register/:productCode/:page/:orgId?/:orgName?/:personId?',
		PATH: (productCode, page, orgId, orgName, personId) => {
			if (orgId && orgName && personId) {
				return `/register/${productCode}/${page}/${orgId}/${orgName}/${personId}`;
			}
			else if (orgId && orgName) {
				return `/register/${productCode}/${page}/${orgId}/${orgName}`;
			}
			else {
				return `/register/${productCode}/${page}`;
			}
		},
		PROFILE: {ROUTE: '/register/:productCode/profile/:orgId?/:orgName?/:personId?', PAGE_NAME: 'profile'}
	},
	VERIFY_ACCOUNT: {
		ROUTE: '/verify/:personId/:personHash/:verificationCode?'
	},
	INTEL_ALL_PRODUCTS: {
		PAGE_NAME: 'all_intel',
		ROUTE: '/all_intel/:personChatId?',
		PATH: (personChatId) => `/all_intel/${personChatId}`
	}
};
