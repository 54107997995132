import {Business, Person} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import ProfileDialog from '../app/profile/ProfileDialog';
import EnvHelper from '../helpers/EnvHelper';
import {partnerTapInactive, partnerTapPrimary, partnerTapSecondary, partnerTapTempLogo, partnerTapWhite} from '../styles/partnertap_theme';

class Pic extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showDialog: false};
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		this.processProps();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let {bizId, personId, orgId} = this.props;
		if (prevProps.bizId !== bizId || prevProps.personId !== personId || prevProps.orgId !== orgId) {
			this.processProps();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	processProps() {
		let imageUri = null;
		let NoPhotoMaterialIcon = Person;
		let {bizId, personId, orgId, tmpLogo, picSize} = this.props;

		if (bizId) {
			imageUri = EnvHelper.logoImageUri + '/' + bizId + '.jpg';
			NoPhotoMaterialIcon = Business;
		}
		else if (personId) {
			imageUri = EnvHelper.profileImageUri + '/' + personId + '.jpg';
		}
		else if (orgId) {
			imageUri = EnvHelper.orgLogoImageUri + '/' + orgId + '.jpg';
			NoPhotoMaterialIcon = Business;
		}

		let showTempLogo = tmpLogo && !personId;
		if (imageUri) {
			imageUri += '?nocache=' + EnvHelper.noCache;
			let img = new Image();
			img.onerror = () => {
				if (this.unmounted) return;
				this.setState({showNoPhotoIcon: !showTempLogo});
			};
			img.onload = () => {
				if (this.unmounted) return;
				let width = img.width;
				let height = img.height;
				let scale;
				picSize = picSize || (EnvHelper.isDesktop ? 60 : 30);
				if (width < height) {
					scale = picSize / width;
				}
				else {
					scale = picSize / height;
				}

				this.setState({
					showNoPhotoIcon: false,
					showTempLogo: false,
					imageWidth: width * scale,
					imageHeight: height * scale
				});
			};
			img.src = imageUri;
		}

		this.setState({
			showNoPhotoIcon: !tmpLogo,
			NoPhotoMaterialIcon: NoPhotoMaterialIcon,
			imageUri: imageUri,
			showTempLogo: showTempLogo
		});
	}

	onClick(event) {
		if (!this.hasClick) return;
		event.stopPropagation(); // make sure profile pics in hot list items do not trigger the list item action
		let {onClick, personId, partnerId, authState} = this.props;
		if (onClick) {
			onClick();
		}
		else if (personId) {
			if (personId !== authState.person.id) {
				this.setState({showDialog: true});
			}
			else {
				EnvHelper.push(EnvHelper.profilePath + '/' + personId + (partnerId ? '/' + partnerId : ''));
			}
		}
	}

	get hasClick() {
		return !this.props.disabled && !this.props.disableClick && (this.props.onClick || this.props.personId);
	}

	render() {
		let {showNoPhotoIcon, showTempLogo, NoPhotoMaterialIcon, imageUri, imageWidth, imageHeight} = this.state;
		let {personId, tmpLogo, picSize, backgroundColor} = this.props;
		picSize = picSize || (EnvHelper.isDesktop ? 60 : 30);
		let tmpLogoFontSize = Math.max(Math.ceil(0.5 * picSize), 12);

		let borderColor;
		if (this.props.disabled) {
			borderColor = partnerTapInactive;
		}
		else if (this.hasClick) {
			borderColor = partnerTapSecondary;
		}
		else {
			borderColor = partnerTapPrimary;
		}

		let picStyle = {
			borderRadius: '100%',
			width: picSize,
			height: picSize,
			border: '1px solid ' + borderColor,
			backgroundColor: backgroundColor
		};

		let picImageStyle = {
			backgroundImage: 'url(' + imageUri + ')',
			backgroundColor: backgroundColor || partnerTapWhite,
			backgroundSize: imageWidth + 'px ' + imageHeight + 'px',
			backgroundPosition: '50% 30%',
			backgroundRepeat: 'no-repeat',
			width: '100%',
			height: ' 100%',
			borderRadius: '100%'
		};

		let missingPicStyle = {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: backgroundColor || (showTempLogo ? partnerTapTempLogo : partnerTapWhite),
			width: '100%',
			height: ' 100%',
			borderRadius: '100%',
			border: '1px solid ' + partnerTapWhite,
			boxSizing: 'border-box'
		};

		return (
			<Fragment>
				<div style={{display: 'inline-block', cursor: this.hasClick ? 'pointer' : 'arrow'}} onClick={this.onClick}>
					<div style={picStyle}>
						{showNoPhotoIcon &&
						 <div style={missingPicStyle}>
							 <NoPhotoMaterialIcon style={{color: borderColor, fontSize: tmpLogoFontSize}}/>
						 </div>}
						{showTempLogo &&
						 <div style={missingPicStyle}>
							 <span style={{color: partnerTapWhite, fontSize: tmpLogoFontSize, fontWeight: 'bold'}}>
								 {tmpLogo[0].toUpperCase()}
							 </span>
						 </div>}
						{!showNoPhotoIcon && !showTempLogo && imageUri && <div style={picImageStyle}/>}
					</div>
					{this.props.children}
				</div>
				{this.state.showDialog &&
				 <ProfileDialog personId={personId} closeDialog={() => this.setState({showDialog: false})}/>}
			</Fragment>
		);
	}
}

Pic.propTypes = {
	personId: PropTypes.string,
	partnerId: PropTypes.string,
	bizId: PropTypes.string,
	orgId: PropTypes.string,
	backgroundColor: PropTypes.string,
	tmpLogo: PropTypes.string,
	picSize: PropTypes.number,
	onClick: PropTypes.func,
	disableClick: PropTypes.bool,
	disabled: PropTypes.bool,
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(Pic);
