import {NavigateBefore, NavigateNext} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import ColumnHelper from '../../helpers/ColumnHelper';
import DialogHelper from '../../helpers/DialogHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {PAGE_SIZE_KNOWN_DEFAULT, PAGE_SIZE_OPTIONS} from '../../helpers/PageableHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import StringHelper from '../../helpers/StringHelper';
import TableHelper from '../../helpers/TableHelper';
import {partnerTapPrimary, partnerTapSecondary, partnerTapStroke, partnerTapTernary, partnerTapWhite} from '../../styles/partnertap_theme';
import SecondaryButton from '../buttons/SecondaryButton';
import PopoverSearchList from '../PopoverSearchList';
import ToolTipOverlay from '../ToolTipOverlay';
import PagingTableColumnHeader from './PagingTableColumnHeader';
import ScrollingContainer from './ScrollingContainer';
import HackHelper from '../../helpers/HackHelper';

export class PagingTable extends Component {

	constructor(props, context) {
		super(props, context);

		this.onPreviousPage = this.onPreviousPage.bind(this);
		this.onNextPage = this.onNextPage.bind(this);
		this.initScrollPersistence = this.initScrollPersistence.bind(this);
		this.onColumnWidthChanged = this.onColumnWidthChanged.bind(this);
	}

	componentDidMount() {
		// double timeout required so pagingTableScroll element height is set prior to scrolling
		setTimeout(() => setTimeout(this.initScrollPersistence));
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get hasPreviousPage() {
		return this.props.currentPage > 1;
	}

	get hasNextPage() {
		return this.props.currentPage < this.props.pageCount;
	}

	onPreviousPage() {
		if (this.hasPreviousPage) {
			this.props.onPageChange(this.props.currentPage - 1);
		}
	}

	onNextPage() {
		if (this.hasNextPage) {
			this.props.onPageChange(this.props.currentPage + 1);
		}
	}

	initScrollPersistence() {
		let pagingTableScroll = document.getElementById('paging_table_scroll_dialog');
		if (!pagingTableScroll) {
			pagingTableScroll = document.getElementById('paging_table_scroll');
		}
		if (pagingTableScroll) {
			pagingTableScroll.onscroll = () => {
				PersistenceHelper.setValue(this.scrollTopStorageKey, String(pagingTableScroll.scrollTop));
				PersistenceHelper.setValue(this.scrollLeftStorageKey, String(pagingTableScroll.scrollLeft));
			};

			let scrollTop = parseInt(PersistenceHelper.getValue(this.scrollTopStorageKey) || '0');
			let scrollLeft = parseInt(PersistenceHelper.getValue(this.scrollLeftStorageKey) || '0');
			if (scrollTop || scrollLeft) {
				pagingTableScroll.scrollTo({top: scrollTop, left: scrollLeft});
			}
		}
	}

	onColumnWidthChanged(storeColumnCustomizations) {
		if (storeColumnCustomizations) {
			TableHelper.storeColumnCustomizations(this.props.storageKey, this.props.columnData);
		}

		// onColumnWidthChanged is called by every PagingTableColumnHeader, so compacting into a single call
		if (this.callOnce) {
			clearTimeout(this.callOnce);
		}
		this.callOnce = setTimeout(() => {
			if (this.unmounted) return;
			this.forceUpdate();
		});
	}

	get scrollTopStorageKey() {
		return this.props.storageKey + '_page_' + this.props.currentPage + '_scroll_top';
	}

	get scrollLeftStorageKey() {
		return this.props.storageKey + '_page_' + this.props.currentPage + '_scroll_left';
	}

	renderPageButtons() {
		let {currentPage, pageCount} = this.props;
		let visiblePages = [];

		if (currentPage > 2 && currentPage === pageCount) {
			visiblePages.push(currentPage - 2);
		}
		if (currentPage > 1) {
			visiblePages.push(currentPage - 1);
		}

		visiblePages.push(currentPage);

		if (currentPage + 1 <= pageCount) {
			visiblePages.push(currentPage + 1);
		}
		if (currentPage + 2 <= pageCount && currentPage === 1) {
			visiblePages.push(currentPage + 2);
		}

		return (
			<div style={{display: 'flex', fontSize: 16, fontWeight: 'bold', color: partnerTapSecondary}}>
				{visiblePages.map((page) => {
					return (
						<div key={page} style={{paddingLeft: 2, paddingRight: 2}}>
							<SecondaryButton label={page} disabled={page === currentPage} onClick={() => this.props.onPageChange(page)}/>
						</div>
					);
				})}
			</div>
		);
	}

	get table() {
		let columnOrdinal = 0;
		let displayPagingToolbar = (this.props.pageSize && this.props.rowData.length > PAGE_SIZE_OPTIONS[0]) || this.props.pageCount > 1;
		let divId = 'paging_table_scroll';
		if (DialogHelper.isDialogOpen) divId += '_dialog';
		return (
			<Fragment>
				{this.props.renderTiles ?
					<div id={divId} style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', overflow: 'scroll'}}>
						{HackHelper.horribleHack}
						{this.props.rowData.map((item, index) => {
							return <this.props.tileRenderer key={index}
															index={index}
															rowData={item}
															reloadPageFunction={() => this.props.onPageChange(this.props.currentPage)}/>;
						})}
					</div>
					:
					<div id={divId}
						 style={{
							 overflow: 'scroll',
							 marginLeft: 5,
							 marginRight: 5,
							 border: 'solid 1px' + partnerTapStroke,
							 borderRadius: 2,
							 backgroundColor: partnerTapWhite
						 }}>
						<table style={{width: '100%', borderSpacing: 0}} data-cy={StringHelper.formatKey(divId)}>
							{this.props.columnData &&
							 <thead>
							 <tr>
								 {this.props.columnData.map((column, index) => {
									 if (ColumnHelper.isVisible(column)) {
										 column.index = columnOrdinal++;
										 let cellStyle = {
											 fontWeight: 'bold',
											 color: partnerTapWhite,
											 background: column.isPartnerData ? partnerTapTernary : partnerTapPrimary,
											 // do not use border shorthand because drop style overrides left and right borders
											 borderTop: 'solid 1px ' + partnerTapWhite,
											 borderRight: 'solid 1px ' + partnerTapWhite,
											 borderBottom: 'solid 1px ' + partnerTapWhite,
											 borderLeft: 'solid 1px ' + partnerTapWhite,
											 borderRadius: 2,
											 position: 'sticky',
											 top: 0,
											 zIndex: 1000 - column.index
										 };
										 let isSticky = column.index === 0;
										 if (isSticky) {
											 cellStyle.left = 0;
										 }
										 TableHelper.addDropColumnStyle(column, cellStyle);
										 TableHelper.addResizeColumnStyle(column, cellStyle);
										 return (
											 <th key={column.key + '_' + index} style={cellStyle}>
												 <PagingTableColumnHeader tableKey={this.props.storageKey}
																		  column={column}
																		  columnData={this.props.columnData}
																		  onColumnSortChanged={this.props.onColumnSortChanged}
																		  onColumnOrderChanged={this.props.onColumnOrderChanged}
																		  onColumnWidthChanged={this.onColumnWidthChanged}/>
											 </th>
										 );
									 }
								 })}
							 </tr>
							 </thead>}
							<tbody key={TableHelper.resizeColumnKey}>
							{this.props.rowRenderer &&
							 this.props.rowData.map((row, index) => {
								 return <this.props.rowRenderer key={index}
																index={index}
																columnData={this.props.columnData}
																rowData={row}
																rowClick={this.props.rowClick}/>;
							 })}
							</tbody>
						</table>
					</div>}
				{displayPagingToolbar ?
					<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-evenly'}}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<IconButton color={'secondary'}
										onClick={this.onPreviousPage}
										disabled={!this.hasPreviousPage}
										size={'large'}
										data-cy={'paginate_previous'}>
								<ToolTipOverlay title={'Previous page'}><NavigateBefore/></ToolTipOverlay>
							</IconButton>
							{this.renderPageButtons()}
							<IconButton color={'secondary'}
										onClick={this.onNextPage}
										disabled={!this.hasNextPage}
										size={'large'}
										data-cy={'paginate_next'}>
								<ToolTipOverlay title={'Next page'}><NavigateNext/></ToolTipOverlay>
							</IconButton>
						</div>
						{this.props.onPageSizeChange &&
						 <div style={{display: 'flex', alignItems: 'center'}}>
							 <div style={{whiteSpace: 'nowrap'}}>
								 {this.props.pageCount} page{this.props.pageCount !== 1 && 's'} of
							 </div>
							 <div data-cy={'page_size_dropdown'} style={{padding: 10}}>
								 <PopoverSearchList list={PAGE_SIZE_OPTIONS}
													preselectedItem={this.props.pageSize || PAGE_SIZE_KNOWN_DEFAULT}
													labelRenderer={(item) => EnvHelper.isDesktop ? item + ' rows' : item}
													onItemSelected={this.props.onPageSizeChange}/>
							 </div>
						 </div>}
					</div>
					:
					<div style={{paddingTop: 10}}/>
				}
			</Fragment>
		);
	}

	render() {
		if (this.props.hasScrollingContainerParent) {
			return this.table;
		}
		return (
			<ScrollingContainer divId={this.props.storageKey}>
				{this.table}
			</ScrollingContainer>
		);
	}
}

PagingTable.propTypes = {
	storageKey: PropTypes.string.isRequired,

	columnData: PropTypes.array,
	onColumnOrderChanged: PropTypes.func,
	onColumnSortChanged: PropTypes.func,

	pageCount: PropTypes.number,
	currentPage: PropTypes.number,
	onPageChange: PropTypes.func,
	pageSize: PropTypes.number,
	onPageSizeChange: PropTypes.func,

	rowData: PropTypes.array.isRequired,
	rowRenderer: PropTypes.elementType,
	rowClick: PropTypes.func,
	tileRenderer: PropTypes.elementType,
	renderTiles: PropTypes.bool,

	hasScrollingContainerParent: PropTypes.bool
};

export default connect()(PagingTable);
