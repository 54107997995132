import {matchSorter} from 'match-sorter';
import ColumnHelper from './ColumnHelper';

export default class SortHelper {

	static get sortPartnersByName() {
		return 'isNew,desc&sort=partnerStatus,desc&sort=partnerName,asc&sort=partnerOrganization,asc&sort=accountMatchCount,desc';
	}

	static get sortChannelPartnersByName() {
		return 'isNew,desc&sort=partnerStatus,desc&sort=partnerName,asc&sort=partnerOrganization,asc';
	}

	static get sortPartnersByNamePending() {
		return 'partnerStatus,desc&isNew,desc&sort=partnerName,asc&sort=accountMatchCount,desc&sort=partnerOrganization,asc';
	}

	static get sortPartnersByOrganization() {
		return 'isNew,desc&sort=partnerOrganization,asc&sort=partnerName,asc&sort=accountMatchCount,desc';
	}

	static get sortPartnersByMatchCount() {
		return 'isNew,desc&sort=accountMatchCount,desc&sort=partnerName,asc&sort=partnerOrganization,asc';
	}

	static get sortRecommendedPartnersByName() {
		return 'partnerName,asc';
	}

	static get sortRecommendedPartnersByMatchCount() {
		return 'accountMatchCount,desc&sort=partnerName,asc&sort=organization,asc';
	}

	static get sortRecommendedPartnersByOrganization() {
		return 'partnerOrganization,asc&sort=partnerName,asc&sort=accountMatchCount,desc';
	}

	static get sortAccountsByName() {
		return 'accountName,asc&sort=partnerMatchCount,desc';
	}

	static get sortAccountsByMatchCount() {
		return 'partnerMatchCount,desc&sort=accountName,asc';
	}

	static get sortRecommendedPartners() {
		return 'accountMatchCount,desc&sort=partnerName,asc';
	}

	static get sortAccountsByRecommendationCount() {
		return 'recommendedPartnerCount,desc&sort=name,asc';
	}

	static filterSearchArray(search, listFull) {
		if (search) return matchSorter(listFull, search);
		return listFull;
	}

	static filterSearchObjectContains(search, listFull, keys) {
		if (search) return matchSorter(listFull, search, {keys: keys, threshold: matchSorter.rankings.CONTAINS});
		return listFull;
	}

	static keysFromColumns(columns) {
		let visibleColumnKeys = [];
		if (columns) {
			columns.forEach((column) => {
				if (ColumnHelper.isVisible(column)) visibleColumnKeys.push(column.key);
			});
		}
		return visibleColumnKeys;
	}

	static sortByNullLast(list, key, ascdesc) {
		if (!key) return;
		if (!ascdesc) {
			let values = key.split(',');
			key = values[0];
			ascdesc = values[1];
		}
		let allKeys = key.split('.');
		let baseKey = allKeys[0];
		let subKey;
		if (allKeys.length > 0) {
			subKey = allKeys[1];
		}
		return list.sort((a, b) => {
			let mul = ascdesc === 'asc' ? 1 : -1;
			let aVal = a[baseKey];
			let bVal = b[baseKey];
			if (subKey && aVal) {
				aVal = aVal[subKey];
			}
			if (subKey && bVal) {
				bVal = bVal[subKey];
			}
			return (aVal === null || aVal === undefined) - (bVal === null || bVal === undefined) || mul * (+(aVal > bVal) || -(aVal < bVal));
		});
	}
}
