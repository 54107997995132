import {AddCircle, Cancel, FilterList, Visibility, VisibilityOff} from '@mui/icons-material';
import {CircularProgress, Popover} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import ColumnHelper from '../../helpers/ColumnHelper';
import DateHelper from '../../helpers/DateHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper from '../../helpers/FeatureHelper';
import FilterHelper, {
	FILTER_RANGE_DELIMITER,
	FILTER_STRING_SEARCH_CONTAINS,
	FILTER_STRING_SEARCH_EQUAL,
	FILTER_STRING_SEARCH_EXCLUDES,
	FILTER_STRING_SEARCH_NOT_EQUAL,
	FILTER_VALUE_CONTAINS_SUFFIX,
	FILTER_VALUE_DELIMITER,
	FILTER_VALUE_EXCLUDES_SUFFIX,
	FILTER_VALUE_NOT_EQUAL_SUFFIX
} from '../../helpers/FilterHelper';
import NumberHelper from '../../helpers/NumberHelper';
import * as PageSizeHelper from '../../helpers/PageableHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import StringHelper from '../../helpers/StringHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapPrimary, partnerTapSecondary, partnerTapSecondaryLight, partnerTapWhite} from '../../styles/partnertap_theme';
import PrimaryButton from '../buttons/PrimaryButton';
import PopoverSearchList from '../PopoverSearchList';
import DateRangeSelector from './DateRangeSelector';
import NumberRangeSelector from './NumberRangeSelector';
import StringListSelector from './StringListSelector';
import ChannelHelper from '../../helpers/ChannelHelper';
import SecondaryButton from '../buttons/SecondaryButton';

class FilterSelector extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {editingFilters: false, currentFilter: null, currentFilterSearch: null, filterUpdating: false};

		this.initFilters = this.initFilters.bind(this);
		this.makeFilterButton = this.makeFilterButton.bind(this);
		this.updateCurrentFilterValues = this.updateCurrentFilterValues.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onLoadMore = this.onLoadMore.bind(this);
		this.filterChanged = this.filterChanged.bind(this);
	}

	componentDidMount() {
		this.initFilters();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.storageKey !== this.props.storageKey) {
			this.initFilters();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get hasInitialValues() {
		let {initialFilterValues} = this.props.config;
		return Boolean(initialFilterValues && Object.keys(initialFilterValues).length > 0);
	}

	get allowPersistence() {
		let {blockPersistence, inherentReportFilters, initialFilterValues} = this.props.config;
		return !blockPersistence && !inherentReportFilters && (!initialFilterValues || Object.keys(initialFilterValues).length === 0);
	}

	get filterValueStorageKey() {
		return 'filter_values_' + this.props.storageKey;
	}

	initFilters() {
		if (this.hasInitialValues) {
			let {initialFilterValues} = this.props.config;
			Object.keys(initialFilterValues).forEach((filterKey) => {
				let filter = this.props.config.filters.find((item) => {
					let key = filterKey.toLowerCase();
					if (item.otherFieldsPrefix && key.startsWith(item.otherFieldsPrefix.toLowerCase())) {
						key = key.substring(item.otherFieldsPrefix.length + '->'.length);
						key = key.replaceAll('\'', '');
						key = key.replaceAll('->', '.');
					}
					let customValueDelimiterIndex = key.indexOf('->');
					if (customValueDelimiterIndex !== -1) {
						key = key.substring(customValueDelimiterIndex + 4, key.length - 1);
					}
					return item.key.toLowerCase() === key;
				});
				if (filter) {
					filter.isAdded = true;
					filter.values = {};
					let filterValuesString = String(initialFilterValues[filterKey]);
					if (filterValuesString) {
						if (filterValuesString.endsWith(FILTER_VALUE_CONTAINS_SUFFIX)) {
							filterValuesString = filterValuesString.substring(0, filterValuesString.length - FILTER_VALUE_CONTAINS_SUFFIX.length);
							filter.searchType = FILTER_STRING_SEARCH_CONTAINS;
							filter.searchLike = filterValuesString;
							filterValuesString = '';
						}
						else if (filterValuesString.endsWith(FILTER_VALUE_EXCLUDES_SUFFIX)) {
							filterValuesString = filterValuesString.substring(0, filterValuesString.length - FILTER_VALUE_EXCLUDES_SUFFIX.length);
							filter.searchType = FILTER_STRING_SEARCH_EXCLUDES;
							filter.searchLike = filterValuesString;
							filterValuesString = '';
						}
						else if (filterValuesString.endsWith(FILTER_VALUE_NOT_EQUAL_SUFFIX)) {
							filterValuesString = filterValuesString.substring(0, filterValuesString.length - FILTER_VALUE_NOT_EQUAL_SUFFIX.length);
							filter.searchType = FILTER_STRING_SEARCH_NOT_EQUAL;
						}
						else if (filterValuesString.indexOf(FILTER_RANGE_DELIMITER) !== -1) {
							let rangeValues = filterValuesString.split(FILTER_RANGE_DELIMITER);
							try {
								filter.searchMin = parseFloat(rangeValues[0]);
								filter.searchMax = parseFloat(rangeValues[1]);
								filter.searchRange = filterValuesString;
								filterValuesString = '';
							}
							catch (error) {
								console.error('Error parsing initial filter value', rangeValues, error);
							}
						}

						if (filterValuesString) {
							if (filterValuesString.indexOf(FILTER_VALUE_DELIMITER) !== -1) {
								let filterValues = filterValuesString.split(FILTER_VALUE_DELIMITER);
								filterValues.forEach(filterValue => {
									filter.values[filterValue] = {displayName: filterValue, rawValue: filterValue, selected: true, show: true, valid: true};
								});
							}
							else {
								filter.values[filterValuesString] = {
									displayName: filterValuesString,
									rawValue: filterValuesString,
									selected: true,
									show: true,
									valid: true
								};
							}
						}
					}
				}
			});
		}

		if (this.allowPersistence) {
			let savedFilterValues = PersistenceHelper.getValue(this.filterValueStorageKey);
			if (savedFilterValues) {
				let savedFilters = JSON.parse(savedFilterValues);
				savedFilters && savedFilters.forEach((savedFilter) => {
					savedFilter.currentPage = 0;
					let propsFilter = this.props.config.filters.find((item) => item.key === savedFilter.key);
					if (propsFilter) {
						let preserveType = propsFilter.type;
						Object.assign(propsFilter, savedFilter);
						propsFilter.type = preserveType;
					}
				});
			}
		}

		this.props.config.onChange(this.filterValues, true);
		this.props.mountedFunction();
	}

	updateCurrentFilterValues(filter, search, loadMore) {
		if (filter.filterType === FILTER_STRING_SEARCH_CONTAINS || filter.filterType === FILTER_STRING_SEARCH_EXCLUDES) return;
		this.setState({currentFilter: filter, filterUpdating: true});
		this.props.config.getFilterDataFunction(
			this.props.config.getFilterDataFunctionArgs,
			search,
			this.filterValues,
			this.getFilterType(filter),
			filter.currentPage ? filter.currentPage : 0,
			PageSizeHelper.PAGE_SIZE_DEFAULT)
		.then((result) => {
			if (this.unmounted) return;
			if (!loadMore) this.showAllFilterValues(filter, false);
			result.payload.forEach((filterValueServer) => {
				if (filterValueServer.filterData || filterValueServer.filterData === false || filterValueServer.filterData === 0) {
					let filterValueClient = filter.values[filterValueServer.filterData];
					if (filterValueClient) {
						filterValueClient.show = true;
						filterValueClient.valid = true;
						if (ColumnHelper.isBoolean(filter) && String(filterValueClient.rawValue)) {
							filterValueClient.displayName = String(filterValueClient.rawValue).toLowerCase() === 'true' ? 'True' : 'False';
						}
					}
					else {
						let rawValue = filterValueServer.filterData;
						let displayName = rawValue;
						if (ColumnHelper.isBoolean(filter)) {
							displayName = String(rawValue).toLowerCase() === 'true' ? 'True' : 'False';
						}
						if (filter.renderFunction) {
							displayName = filter.renderFunction(displayName);
						}
						if (ColumnHelper.isDate(filter)) {
							displayName = DateHelper.epochToDate(displayName);
						}
						else if (ColumnHelper.isCurrency(filter)) {
							displayName = NumberHelper.formatCurrency(displayName);
						}
						else if (ColumnHelper.isNumber(filter)) {
							displayName = NumberHelper.formatNumber(displayName);
						}
						else if (ColumnHelper.isArrayOfString(filter) && displayName) {
							try {
								let values = JSON.parse(displayName);
								if (values && Array.isArray(values)) {
									displayName = values.join(', ');
								}
							}
							catch (error) {
								console.error('Error parsing array of strings for filter', filter, error);
							}
						}
						else if (FeatureHelper.products && ColumnHelper.isProductCode(filter)) {
							let product = FeatureHelper.products.find((product) => product.productCode === rawValue);
							if (product) {
								displayName = product.displayName;
							}
						}
						else if (FeatureHelper.roles && ColumnHelper.isRoleCode(filter)) {
							let role = FeatureHelper.roles.find((role) => role.roleCode === rawValue);
							if (role) {
								displayName = role.displayName;
							}
						}

						displayName = ChannelHelper.convertEnumsInFilterValues(displayName, filter.key, rawValue);

						filter.values[rawValue] = {
							displayName: displayName,
							rawValue: rawValue,
							show: true,
							valid: true,
							selected: false,
							filterRangeMin: filterValueServer.filterRangeMin || 0,
							filterRangeMax: filterValueServer.filterRangeMax || 0
						};
					}
				}
			});
			if (result.metaData) {
				filter.totalPages = result.metaData.totalPages;
				filter.currentPage = result.metaData.pageable.pageNumber;
			}
			this.setState({filterUpdating: false, currentFilterSearch: search});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from updateCurrentFilterValues', error);
			EnvHelper.dispatch(AuthActions.refreshApp()); // refresh the app to make sure local data is current
		});
	}

	showAllFilterValues(filter, show) {
		Object.keys(filter.values).forEach((filterKey) => {
			let filterValue = filter.values[filterKey];
			filterValue.show = show || filterValue.selected;
			filterValue.valid = false;
		});
	}

	removeFilter(filter) {
		this.setState({currentFilter: null, currentFilterSearch: null});
		filter.isAdded = false;
		filter.searchType = FILTER_STRING_SEARCH_EQUAL;
		filter.searchMin = null;
		filter.searchMax = null;
		filter.searchRange = null;
		filter.values = {};
		this.filterChanged();
	}

	get filtersAddedCount() {
		let addedCount = 0;
		this.props.config.filters.forEach((filter) => {
			if (filter.isAdded && ColumnHelper.isFilterable(filter) && !FilterHelper.isInherent(filter, this.props.config)) {
				addedCount++;
			}
		});
		return addedCount;
	}

	filterChanged() {
		if (this.allowPersistence) {
			PersistenceHelper.setValue(this.filterValueStorageKey, JSON.stringify(this.props.config.filters));
		}
		this.props.config.onChange(this.filterValues);
	}

	getNumericRange(filter) {
		let range = [0, 0];
		if (filter.values && Object.keys(filter.values).length > 0) {
			let filterValueRange = filter.values[Object.keys(filter.values)[0]];
			if (filterValueRange) {
				range[0] = filterValueRange.filterRangeMin;
				range[1] = filterValueRange.filterRangeMax;
			}
		}
		return range;
	}

	onSearch(search) {
		let {currentFilter} = this.state;
		currentFilter.currentPage = 0;
		this.setState({currentFilterSearch: search});
		this.updateCurrentFilterValues(currentFilter, search);
	}

	onLoadMore() {
		let {currentFilter, currentFilterSearch} = this.state;
		currentFilter.currentPage++;
		this.updateCurrentFilterValues(currentFilter, currentFilterSearch, true);
	}

	getFilterType(filter) {
		if (filter.isJson) {
			return filter.otherFieldsPrefix + '->\'' + filter.key.split('.').join('\'->\'') + '\'';
		}
		return filter.isFromOtherFields ? filter.otherFieldsPrefix + '->\'' + filter.key + '\'' : filter.key;
	}

	get filterValues() {
		let filterObject = {};
		this.props.config.filters.forEach((filter) => {
			if (!filter.isAdded) return;
			let filterType = this.getFilterType(filter);
			if (filter.searchRange) {
				filterObject[filterType] = filter.searchRange;
			}
			else if (filter.searchLike) {
				if (filter.searchType === FILTER_STRING_SEARCH_CONTAINS) {
					filterObject[filterType] = filter.searchLike + FILTER_VALUE_CONTAINS_SUFFIX;
				}
				else if (filter.searchType === FILTER_STRING_SEARCH_EXCLUDES) {
					filterObject[filterType] = filter.searchLike + FILTER_VALUE_EXCLUDES_SUFFIX;
				}
			}
			else {
				Object.keys(filter.values).forEach((filterKey) => {
					let filterValue = filter.values[filterKey];
					if (filterValue.selected) {
						if (typeof filterObject[filterType] === 'undefined') {
							filterObject[filterType] = filterValue.rawValue;
						}
						else {
							filterObject[filterType] += FILTER_VALUE_DELIMITER + filterValue.rawValue;
						}
					}
				});

				if (filterObject[filterType] &&
					Object.prototype.hasOwnProperty.call(filter, 'searchType') &&
					filter.searchType === FILTER_STRING_SEARCH_NOT_EQUAL) {
					filterObject[filterType] += FILTER_VALUE_NOT_EQUAL_SUFFIX;
				}
			}
		});
		if (this.props.config.inherentReportFilters) {
			Object.assign(filterObject, this.props.config.inherentReportFilters);
		}
		return filterObject;
	}

	makeFilterButton(filter) {
		if (!filter.isAdded || !ColumnHelper.isFilterable(filter) || FilterHelper.isInherent(filter, this.props.config)) return;
		let searchTool = <div style={FilterHelper.getSimpleMessageStyle()}>No values found</div>;
		let filterKeys = Object.keys(filter.values || {});
		if (filterKeys.length) {
			let useDateSelector = ColumnHelper.isDate(filter);
			let useNumberSelector = ColumnHelper.isNumber(filter) || ColumnHelper.isCurrency(filter);
			if (useDateSelector) {
				let range = this.getNumericRange(filter);
				let defaultDateRange = {
					startDate: moment.unix(filter.searchMin || range[0]),
					endDate: moment.unix(filter.searchMax || range[1])
				};
				searchTool = <DateRangeSelector defaultDateRange={defaultDateRange}
												onClose={(searchRange) => {
													this.setState({currentFilter: null, currentFilterSearch: null});
													if (searchRange && searchRange.startDate && searchRange.endDate) {
														filter.searchMin = Math.floor(searchRange.startDate.valueOf() / 1000);
														filter.searchMax = Math.ceil(searchRange.endDate.valueOf() / 1000);
														filter.searchRange = filter.searchMin + FILTER_RANGE_DELIMITER + filter.searchMax;
														this.filterChanged();
													}
												}}/>;
			}
			else if (useNumberSelector) {
				let range = this.getNumericRange(filter);
				searchTool = <NumberRangeSelector isCurrency={ColumnHelper.isCurrency(filter)}
												  valueMin={range[0]}
												  valueMax={range[1]}
												  searchMin={filter.searchMin || range[0]}
												  searchMax={filter.searchMax || range[1]}
												  onClose={(searchMin, searchMax) => {
													  this.setState({currentFilter: null, currentFilterSearch: null});
													  if (!isNaN(searchMin) && !isNaN(searchMax)) {
														  filter.searchMin = searchMin;
														  filter.searchMax = searchMax;
														  filter.searchRange = filter.searchMin + FILTER_RANGE_DELIMITER + filter.searchMax;
														  this.filterChanged();
													  }
												  }}/>;
			}
			else {
				let filterCloneForCancel = _.cloneDeep(filter);
				let popoverOnclose = (applyChanges) => {
					this.setState({currentFilter: null, currentFilterSearch: null});
					if (applyChanges === true) {
						this.filterChanged();
					}
					else {
						Object.assign(filter, filterCloneForCancel);
					}
					filter.currentPage = 0;
				};
				searchTool = <StringListSelector filter={filter}
												 onSearch={this.onSearch}
												 onLoadMore={this.onLoadMore}
												 currentFilterSearch={this.state.currentFilterSearch}
												 onChange={this.forceUpdate.bind(this)}
												 onClose={popoverOnclose}/>;
			}
		}

		let popoverOnclose = () => {
			filter.currentPage = 0;
			this.setState({currentFilter: null, currentFilterSearch: null});
		};

		return (
			<div key={this.props.storageKey + filter.key} id={filter.key} style={{margin: 5}}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					background: partnerTapWhite,
					color: partnerTapSecondary,
					border: '1px solid ' + partnerTapSecondary,
					height: 24,
					borderRadius: 18,
					padding: 5,
					whiteSpace: 'nowrap'
				}}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer'}}
						 onClick={() => this.updateCurrentFilterValues(filter, this.state.currentFilterSearch)}>
						<FilterList/>
						<div style={{padding: 5}}>
							{filter.title}
						</div>
					</div>
					<Cancel data-cy={'remove_filter_' + StringHelper.formatKey(filter.key)}
							style={{cursor: 'pointer'}}
							onClick={() => this.removeFilter(filter)}/>
				</div>

				{filter === this.state.currentFilter &&
				 <Popover open={true}
						  style={{paddingBottom: 10, minWidth: 240}}
						  anchorEl={document.getElementById(filter.key)}
						  anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
						  transformOrigin={{horizontal: 'left', vertical: 'top'}}
						  onClose={popoverOnclose}>
					 <div style={{padding: 5}}>
						 {this.state.filterUpdating &&
						  <div style={{
							  position: 'absolute',
							  top: -10,
							  left: -10,
							  zIndex: 1000,
							  width: 'calc(100% + 20px)',
							  height: 'calc(100% + 20px)',
							  display: 'flex',
							  flexDirection: 'column',
							  justifyContent: 'center',
							  alignItems: 'center',
							  backgroundColor: 'rgba(0, 0, 0, 0.2)'
						  }}>
							  <div style={{
								  display: 'flex',
								  alignItems: 'center',
								  backgroundColor: 'rgba(255, 255, 255, 0.8)',
								  borderRadius: 20
							  }}>
								  <CircularProgress size={20} style={{color: partnerTapPrimary, background: 'transparent', padding: 10}} disableShrink={true}/>
							  </div>
						  </div>}
						 {searchTool}
					 </div>
				 </Popover>}
			</div>
		);
	}

	render() {
		let addedFilterCount = this.filtersAddedCount;
		let allowMinimize = addedFilterCount > 3;
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				background: partnerTapSecondaryLight,
				borderRadius: 2,
				marginTop: 5,
				marginBottom: 5,
				minWidth: this.props.headerWidth * 0.6,
				minHeight: 48
			}}>
				<div style={{flex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
					<div style={{padding: 4}}>
						<PopoverSearchList list={FilterHelper.getAvailableFilters(this.props.config)}
										   onItemSelected={(filter) => {
											   filter.isAdded = true;
											   // forceUpdate is required to render new filter immediately
											   this.forceUpdate();
											   // setTimeout is required to ensure anchor element is available
											   setTimeout(() => this.updateCurrentFilterValues(filter, this.state.currentFilterSearch));
										   }}
										   searchByObjectKeys={['title']}
										   labelRenderer={(filter) => filter.title}
										   customToggleButton={<SecondaryButton label={'ADD FILTER'}
																				icon={<AddCircle/>}
																				onClick={() => this.setState({editingFilters: true})}
																				requiresEventPropagation={true}/>}/>
					</div>
					{(this.state.editingFilters || !allowMinimize) ?
						<Fragment>
							{this.props.config.filters.map((filter) => this.makeFilterButton(filter))}
						</Fragment>
						:
						<div style={{padding: 10, fontSize: 16, fontWeight: 'bold'}}>
							{addedFilterCount} Filter{addedFilterCount !== 1 && 's'}
						</div>}
					{allowMinimize &&
					 <div style={{padding: 5}}>
						 <PrimaryButton label={this.state.editingFilters ? 'HIDE FILTERS' : 'SHOW FILTERS'}
										icon={this.state.editingFilters ? <VisibilityOff/> : <Visibility/>}
										onClick={() => this.setState({editingFilters: !this.state.editingFilters})}/>
					 </div>}
				</div>
			</div>
		);
	}
}

FilterSelector.propTypes = {
	storageKey: PropTypes.string.isRequired,
	config: PropTypes.shape({
		filters: PropTypes.array.isRequired,
		initialFilterValues: PropTypes.object,
		getFilterDataFunction: PropTypes.func.isRequired,
		getFilterDataFunctionArgs: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		inherentReportFilters: PropTypes.object,
		blockPersistence: PropTypes.bool
	}).isRequired,
	headerWidth: PropTypes.number.isRequired,
	mountedFunction: PropTypes.func.isRequired
};

export default FilterSelector;
