import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_MATCHING_BY_LOCATION_CONNECTED,
	FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS,
	FEATURE_CHANNEL_VIEW_GREENFIELD_ACCOUNTS,
	FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES,
	tempFeatureFlag_CoSellEngine,
	tempFeatureFlag_CoSellEngine_live
} from '../../../../helpers/FeatureHelper';
import ReportHelper, {
	ASSET_TYPE_OPPS,
	CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS,
	CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS,
	CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE,
	CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS,
	CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED
} from '../../../../helpers/ReportHelper';
import StringHelper from '../../../../helpers/StringHelper';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import CoSellSaveButton from '../../../../ui/buttons/CoSellSaveButton';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import SaveReportButton from '../../../../ui/buttons/SaveReportButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import Upsell from '../../../../ui/Upsell';
import ReportMenu from '../../shared/ReportMenu';
import CoSellSaveButtonLive from '../../../../ui/buttons/CoSellSaveButtonLive';
import Loading from '../../../../ui/Loading';
import EcosystemShareHelper from '../../../../helpers/EcosystemShareHelper';
import {PageHeader} from '../../../../ui/PageHeader';

class PartnerConnectionPagingBase extends PagingBase {

	constructor(props, context, assetType, reportTypes, reportCategory) {
		super(props, context);

		let currentReport;
		let isDashboardReport;
		if (DialogHelper.savedReportData.settings) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, DialogHelper.savedReportData.settings.reportTitle); // from saved report
		}
		else if (this.reportType) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, decodeURIComponent(this.reportType)); // from report dropdown or dashboard summary
			isDashboardReport = DialogHelper.isDialogOpen; // from dashboard summary
		}
		if (!currentReport) {
			currentReport = reportTypes[0];
		}

		this.state.loading = false;
		this.state.assetType = assetType;
		this.state.reportTypes = reportTypes;
		this.state.reportCategory = reportCategory;
		this.state.currentReportType = currentReport;
		this.state.isDashboardReport = isDashboardReport;
		this.state.reportByLocation = Boolean((DialogHelper.savedReportData.settings && DialogHelper.savedReportData.settings.reportByLocation) || this.props.match.params.reportByLocation);

		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {
			channelReportType: currentReport.channelReportType,
			matchingType: currentReport.matchingType,
			personId: this.partnerPersonId,
			mapByLocations: this.state.reportByLocation
		};
		this.state.filterSelectorMounted = false;
		let deepLinkFilters = ChannelHelper.getDeepLinkFilters();
		if (deepLinkFilters) {
			this.state.filterValues = deepLinkFilters;
		}
		if (DialogHelper.savedReportData.settings) {
			this.initialFilterValues = DialogHelper.savedReportData.settings.filters;
		}

		this.fetchColumns = this.fetchColumns.bind(this);
		this.onUpdateReportType = this.onUpdateReportType.bind(this);
	}

	componentDidMount() {
		let {currentReportType, assetType} = this.state;
		let {channelReportType} = currentReportType;
		let showOppsReportsUpsell = channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED &&
									!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS);
		let showOppsGreenfieldUpsell = currentReportType.isOppsGreenfield &&
									   !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES);
		let showUpsellDialogForFeature = currentReportType.upsellIfDisabledReportFeature(this.state.reportByLocation);
		if (showOppsReportsUpsell || showOppsGreenfieldUpsell) {
			setTimeout(() => this.setState({showUpsellDialogForFeature: 'Shared ' + assetType}));
		}
		else if (showUpsellDialogForFeature) {
			setTimeout(() => this.setState({showUpsellDialogForFeature: showUpsellDialogForFeature}));
		}
		else if (this.isPartnerSharing) {
			this.fetchColumns(channelReportType);
		}
	}

	get isPartnerSharing() {
		throw new Error('PartnerConnectionPagingBase.isPartnerSharing must be overridden!');
	}

	renderNotSharingMessage(type) {
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
				<div style={{padding: 20}}>
					{this.props.partner.firstName} isn't sharing {type} with you.
				</div>
				<div>
					We recommend you contact {this.props.partner.firstName} to pitch the mutual value of sharing {type}.
				</div>
				<div style={{padding: 10}}>
					{EcosystemShareHelper.renderReachOutActions(this.props.partner)}
				</div>
			</div>
		);
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	getStorageKeyBase(assetType) {
		let {isDashboardReport, currentReportType, reportByLocation} = this.state;
		if (isDashboardReport) {
			let filterTitle = '';
			let paramFilterTitle = this.getParamValue('filterTitle');
			if (paramFilterTitle) {
				filterTitle = decodeURIComponent(paramFilterTitle);
				filterTitle = '_' + StringHelper.formatKey(filterTitle);
			}
			return 'ecosystem_shared_' + assetType + '_' + this.reportType + filterTitle;
		}

		let reportType = currentReportType ? '_' + StringHelper.formatKey(currentReportType.reportTitle) : '';
		return 'ecosystem_shared_' + assetType + '_' + this.partnerId + reportType + (reportByLocation ? '_by_location' : '');
	}

	get title() {
		let {assetType, reportTypes, currentReportType, reportByLocation, isDashboardReport} = this.state;
		let title = EnvHelper.getParam('filterTitle', this.props.location.search) || currentReportType.reportTitle;
		let key = assetType === ASSET_TYPE_OPPS ? 'partner_connection_opps_report_menu' : 'partner_connection_accounts_report_menu';
		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
				{(isDashboardReport || this.isSavedReport) ?
					title :
					<ReportMenu reportTypes={reportTypes}
								currentReportType={currentReportType}
								onUpdateReportType={(reportType) => this.onUpdateReportType(reportType, reportByLocation)}
								data-cy={key}/>}
				<div style={{display: 'flex', alignItems: 'center', fontSize: 14}}>
					<CheckboxButton style={{padding: 4}}
									checked={reportByLocation}
									onChange={() => this.onUpdateReportType(currentReportType, !reportByLocation)}/>
					by Location
				</div>
			</div>
		);
	}

	onUpdateReportType(reportType, reportByLocation) {
		let {isDashboardReport} = this.state;
		if (isDashboardReport || this.isSavedReport) {
			this.setState({reportByLocation: reportByLocation, loading: true});
		}
		else {
			let route = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER;
			let {partnerId} = this.props.match.params;
			EnvHelper.push(route.PATH(partnerId, this.state.assetType.toLowerCase(), reportType.reportTitle, reportByLocation));
		}
	}

	get icon() {
		let {currentReportType, isDashboardReport} = this.state;
		return (isDashboardReport || this.isSavedReport) ? ReportHelper.getReportFilterIcon(currentReportType.assetType, currentReportType.filterType) : null;
	}

	get partnerPersonId() {
		return this.props.partner.personId;
	}

	get partnerId() {
		return this.props.partner.partnerId;
	}

	fetchColumns(channelReportType) {
		ChannelMappingEndpoints.getColumns({channelReportType: channelReportType, personId: this.partnerPersonId, matchingType: this.matchingType})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
			this.setState({filters: this.filterSelectorColumns});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getColumns', error);
		});
	}

	get displayCoSellSave() {
		let {currentReportType} = this.state;
		return currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_MATCHED_ACCOUNTS ||
			   currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_PIPELINE ||
			   currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_OPPS_MATCHED ||
			   currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_MY_UNMATCHED_ACCOUNTS ||
			   currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_PERSON_GREENFIELD_ACCOUNTS;
	}

	get additionalToolbarButtons() {
		let {currentReportType, reportCategory, filterValues, reportByLocation} = this.state;
		let buttons = [];
		if (currentReportType.isDownloadable) {
			if (tempFeatureFlag_CoSellEngine && !tempFeatureFlag_CoSellEngine_live) {
				buttons.push(<CoSellSaveButton callingPage={this}/>);
			}
			else if (tempFeatureFlag_CoSellEngine_live && this.displayCoSellSave) {
				buttons.push(<CoSellSaveButtonLive callingPage={this} key={this.state.rowCount}/>);
			}

			buttons.push(<DownloadButton callingPage={this}/>);
		}
		buttons.push(<SaveReportButton reportType={this.reportType}
									   reportCategory={reportCategory}
									   reportSettings={{
										   reportSettings: currentReportType,
										   baseSettings: ChannelHelper.baseSettings(this.storageKeyBase),
										   filter: filterValues,
										   reportByLocation: reportByLocation
									   }}
									   reportParams={{
										   partner: this.partnerId,
										   filterTitle: this.getParamValue('filterTitle')
									   }}
									   showSaveDialog={this.showSaveDialog}
									   isShared={false}/>);
		return buttons;
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		let {currentReportType} = this.state;
		let title = EnvHelper.getParam('filterTitle', this.props.location.search) || currentReportType.reportTitle;
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			channelReportType: currentReportType.channelReportType,
			matchingType: currentReportType.matchingType,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: this.state.isDashboardReport ? StringHelper.formatKey(title) :
				this.forUnmappedAccounts ? 'unmatched_accounts' : currentReportType.downloadFileName,
			exportFields: DownloadHelper.getFields(this.state.columnData),
			selectedColumns: this.activeColumns,
			totalElements: '0',
			mapByLocations: this.state.reportByLocation,
			personQueueIds: [this.props.personQueueId],
			personId: this.partnerPersonId
		};
	}

	get coSellReportConfig() {
		let {currentReportType} = this.state;
		let reportConfig = this.downloadReportConfig;
		reportConfig.endpoint = '/cosell/actionlist/save';
		reportConfig.partnerOrgName = this.props.partner.organization;
		reportConfig.assetType = currentReportType.assetType;
		return reportConfig;
	}

	get coSellReportConfigLive() {
		return {
			reportData: this.downloadReportConfig,
			partnerOrgPublicId: this.props.partner.partnerOrgPublicId,
			assetType: ReportHelper.getAssetTypeFromDisplayName(this.state.assetType),
			rowCount: this.state.rowCount
		};
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get filterSelectorConfig() {
		let config = super.filterSelectorConfig;
		if (config) {
			let {currentReportType} = this.state;
			config.inherentReportFilters = currentReportType.inherentReportFilters;
		}
		return config;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {currentReportType, filterSelectorMounted, reportByLocation} = this.state;
		if (!filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				personId: this.partnerPersonId,
				selectedColumns: this.activeColumns,
				mapByLocations: reportByLocation,
				matchingType: currentReportType.matchingType,
				channelReportType: currentReportType.channelReportType
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get upsellFeature() {
		let {showUpsellDialogForFeature} = this.state;
		if (showUpsellDialogForFeature === FEATURE_CHANNEL_MATCHING_BY_LOCATION_CONNECTED) {
			return <Upsell headline={'Do you want to use Location-based Account Mapping?'}
						   callToAction={
							   <div style={{textAlign: 'left'}}>
								   This feature applies a location-match requirement to PartnerTap's account matching algorithm.
								   This feature is commonly used by companies that need to map franchises and small businesses.
							   </div>}
						   feature={'Location-based Account Mapping'}/>;
		}
		else if (showUpsellDialogForFeature === FEATURE_CHANNEL_VIEW_GREENFIELD_ACCOUNTS) {
			return <Upsell headline={'Do you want to see Greenfield Accounts?'}
						   callToAction={
							   <div style={{textAlign: 'left'}}>
								   Your partners choose which accounts they share with you,
								   and PartnerTap automatically maps these against your accounts to determine your matched accounts.
								   Sometimes partners share accounts that aren't yet in your CRM.
								   We call these net new accounts Greenfield Accounts.
							   </div>}
						   feature={'Greenfield Accounts'}/>;
		}
		else if (showUpsellDialogForFeature === FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES) {
			return <Upsell headline={'Do you want to see Greenfield Opps?'}
						   callToAction={
							   <div style={{textAlign: 'left'}}>
								   Your partners choose which opportunities they share with you,
								   and PartnerTap automatically maps these against your opportunities to determine your matched opportunities.
								   Sometimes partners share opportunities that aren’t yet in your CRM.
								   We call these net new opportunities Greenfield Opps.
							   </div>}
						   feature={'Greenfield Opportunities'}/>;
		}
		else if (showUpsellDialogForFeature === FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS) {
			return <Upsell headline={'Do you want to see Shared Opportunity Reports?'}
						   callToAction={
							   <div>
								   This feature lets you see a rich collection of opportunity reports.
							   </div>}
						   productBullets={[
							   'All partner opps shared with you',
							   'All unmapped opps',
							   'Greenfield opps',
							   'A combined list of your opps and partner opps',
							   'And more'
						   ]}
						   feature={'Shared Opportunity Reports'}/>;
		}
		else if (showUpsellDialogForFeature) {
			return <Upsell showUpsell={showUpsellDialogForFeature}/>;
		}
		return null;
	}

	render() {
		if (!this.isPartnerSharing) {
			return (
				<Fragment>
					<PageHeader title={''}>{this.title}</PageHeader>
					{this.renderNotSharingMessage(this.state.assetType.toLowerCase())}
				</Fragment>
			);
		}
		if (!this.state.columnData && !this.upsellFeature) return <Loading>Loading Metadata...</Loading>;
		if (this.isSavedReport) {
			return DialogHelper.renderSavedReportDialogButtons(super.render(), () => this.forceUpdate(), this.closeSavedReportDialog);
		}
		return super.render();
	}
}

PartnerConnectionPagingBase.propTypes = {
	partner: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default PartnerConnectionPagingBase;
