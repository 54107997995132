import {AccountTree, Air, PlaylistAddCheck} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {Routes} from '../../../globals/Routes';
import ChannelHelper from '../../../helpers/ChannelHelper';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import ReportHelper from '../../../helpers/ReportHelper';
import {partnerTapInactive} from '../../../styles/partnertap_theme';
import Loading from '../../../ui/Loading';
import SubNav from '../../../ui/navigation/SubNav';
import {PageHeader} from '../../../ui/PageHeader';
import CoSellActionListPage from './CoSellActionListPage';
import CoSellActionListResultsPage from './CoSellActionListResultsPage';

class CoSellActionListRouter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, actionListInfo: null};
		this.fetchActionList = this.fetchActionList.bind(this);
	}

	componentDidMount() {
		this.fetchActionList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get actionListId() {
		return this.props.match.params.actionListId;
	}

	fetchActionList() {
		CoSellEngineEndpoints.getCoSellActionList(this.actionListId)
		.then((result) => {
			if (this.unmounted) return;
			let actionListInfo = result.payload;
			ChannelHelper.convertOtherJsonValues([actionListInfo.sampleWorkflowRow]);
			this.setState({actionListInfo: actionListInfo, loading: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionList', error);
		});
	}

	get subNavData() {
		let route = Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE;
		return [
			{
				label: 'Workflows',
				MaterialIcon: Air,
				route: route.ACTION_LIST.PATH(this.actionListId)
			},
			{
				label: 'Results',
				MaterialIcon: AccountTree,
				route: route.ACTION_LIST_RESULTS.PATH(this.actionListId)
			}
		];
	}

	render() {
		if (this.state.loading) return <Loading>Loading Action List...</Loading>;
		let {actionListInfo} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<PageHeader title={'Action List'} icon={<PlaylistAddCheck fontSize={'large'}/>}>
					<div style={{display: 'flex', alignItems: 'center', gap: 10, padding: 10, paddingLeft: 94}}>
						<div style={{flex: 1, textAlign: 'center', fontSize: 16}}>
							{actionListInfo.partnerOrgName} {ReportHelper.getAssetTypeDisplayName(actionListInfo.assetType)}
						</div>
						<div style={{fontSize: 12, color: partnerTapInactive}}>
							Created by {actionListInfo.createdByPersonName} on {DateHelper.epochToDate(actionListInfo.createdDate)}
						</div>
					</div>
				</PageHeader>
				<SubNav subNavData={this.subNavData}/>
				<Switch>
					<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.ROUTE}
						   render={(props) => <CoSellActionListPage {...props}/>}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_RESULTS.ROUTE}
						   render={(props) => <CoSellActionListResultsPage {...props}/>}/>
				</Switch>
			</div>
		);
	}
}

CoSellActionListRouter.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(CoSellActionListRouter);
