import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import ChannelHelper, {
	CHANNEL_REPORT_CONNECTIONS,
	CHANNEL_REPORT_CUSTOMER_CUSTOMER,
	CHANNEL_REPORT_CUSTOMER_PROSPECT,
	CHANNEL_REPORT_PROSPECT_CUSTOMER,
	CHANNEL_REPORT_PROSPECT_PROSPECT,
	CHANNEL_REPORT_SPREADSHEETS,
	TITLE_ACCOUNTS_BY_COMPANY_SIZE,
	TITLE_ACCOUNTS_BY_COUNTRY,
	TITLE_ACCOUNTS_BY_INDUSTRY,
	TITLE_ACCOUNTS_MUTUAL_OVERLAP,
	TITLE_OPPS_BY_COMPANY_SIZE,
	TITLE_OPPS_BY_COUNTRY,
	TITLE_OPPS_BY_INDUSTRY,
	TITLE_OPPS_MUTUAL_OVERLAP
} from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS} from '../../../../helpers/FeatureHelper';
import ReportHelper, {
	ASSET_TYPE_ACCOUNTS,
	ASSET_TYPE_COMPANY_PARTNER,
	ASSET_TYPE_OPPS,
	CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS,
	MATCHING_TYPE_MUTUAL,
	MATCHING_TYPE_PARTNER_MUTUAL_OPPS
} from '../../../../helpers/ReportHelper';
import UpsellHelper from '../../../../helpers/UpsellHelper';
import CircleGraph from '../../../../ui/graphs/CircleGraph';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import DashboardTopItem from '../../shared/DashboardTopItem';
import PartnerOrgHelper from './PartnerOrgHelper';

class PartnerOrgSummary extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, partnerOrgName: this.props.companyPartnerName};

		this.fetchDashboard = this.fetchDashboard.bind(this);
		this.onCircleGraphAccountClick = this.onCircleGraphAccountClick.bind(this);
		this.onCircleGraphOppClick = this.onCircleGraphOppClick.bind(this);
	}

	componentDidMount() {
		this.fetchDashboard();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get companyPartnerPublicId() {
		return this.props.match.params.companyPartnerPublicId;
	}

	fetchDashboard() {
		ChannelMappingEndpoints.getDashboard({
			companyPartnerPublicId: this.companyPartnerPublicId,
			channelReportType: CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS
		})
		.then((result) => {
			if (this.unmounted) return;
			let summaryResult = result.payload;
			let {overlapCounts, oppOverlapCounts, oppGreenfieldCount, companyPartnerCounts} = summaryResult;

			let accountByIndustryCounts = ChannelHelper.getCountByColumnData(ASSET_TYPE_ACCOUNTS, summaryResult.countByIndustry.counts, 'industry', TITLE_ACCOUNTS_BY_INDUSTRY);
			let accountByCountryCounts = ChannelHelper.getCountByColumnData(ASSET_TYPE_ACCOUNTS, summaryResult.countByCountry.counts, 'country', TITLE_ACCOUNTS_BY_COUNTRY);
			let accountByCompanySizeCounts = ChannelHelper.getCompanySizeData(ASSET_TYPE_ACCOUNTS, overlapCounts.startupCount, overlapCounts.smbCount, overlapCounts.midMarketCount, overlapCounts.globalCount);
			let accountMutualOverlapCounts = ChannelHelper.getClientOverlapData(ASSET_TYPE_ACCOUNTS, overlapCounts.clientOverlapCount, overlapCounts.prospectOverlapCount);

			let oppByIndustryCounts;
			let oppByCountryCounts;
			let oppByCompanySizeCounts;
			let oppMutualOverlapCounts;
			if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS)) {
				oppByIndustryCounts = ChannelHelper.getCountByColumnData(ASSET_TYPE_OPPS, summaryResult.oppIndustryCounts.counts, 'industry', TITLE_OPPS_BY_INDUSTRY);
				oppByCountryCounts = ChannelHelper.getCountByColumnData(ASSET_TYPE_OPPS, summaryResult.oppCountryCounts.counts, 'country', TITLE_OPPS_BY_COUNTRY);
				oppByCompanySizeCounts = ChannelHelper.getCompanySizeData(ASSET_TYPE_OPPS, oppOverlapCounts.startupCount, oppOverlapCounts.smbCount, oppOverlapCounts.midMarketCount, oppOverlapCounts.globalCount);
				oppMutualOverlapCounts = ChannelHelper.getClientOverlapData(ASSET_TYPE_OPPS, oppOverlapCounts.clientOverlapCount, oppOverlapCounts.prospectOverlapCount);
			}

			this.setState({
				loading: false,

				accountOverlapCounts: overlapCounts,
				oppOverlapCounts: oppOverlapCounts,
				oppGreenfieldCount: oppGreenfieldCount,

				accountByIndustryCounts: accountByIndustryCounts,
				accountByCountryCounts: accountByCountryCounts,
				accountByCompanySizeCounts: accountByCompanySizeCounts,
				accountMutualOverlapCounts: accountMutualOverlapCounts,

				companyPartnerCounts: companyPartnerCounts,

				oppByIndustryCounts: oppByIndustryCounts,
				oppByCountryCounts: oppByCountryCounts,
				oppByCompanySizeCounts: oppByCompanySizeCounts,
				oppMutualOverlapCounts: oppMutualOverlapCounts
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getPartnerDashboard', error);
		});
	}

	getTopItemAccountRoute(filterType) {
		let reportTitle = ReportHelper.getReportTitle(ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, filterType);
		return this.getTopItemRoute(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_ACCOUNTS.PAGE_NAME, reportTitle);
	}

	getTopItemOppRoute(matchingType, filterType) {
		let reportTitle = ReportHelper.getReportTitle(ASSET_TYPE_OPPS, matchingType, filterType);
		return this.getTopItemRoute(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_OPPS.PAGE_NAME, reportTitle);
	}

	getTopItemRoute(pageName, reportTitle) {
		return Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.PATH(this.companyPartnerPublicId, pageName, encodeURIComponent(reportTitle));
	}

	onCircleGraphAccountClick(payload) {
		this.onCircleGraphClick(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_ACCOUNTS.PAGE_NAME, payload);
	}

	onCircleGraphOppClick(payload) {
		this.onCircleGraphClick(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_OPPS.PAGE_NAME, payload);
	}

	onCircleGraphClick(pageName, payload) {
		let path = Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.PATH(this.companyPartnerPublicId, pageName, encodeURIComponent(payload.reportTitle));
		let params = '?filter=' + encodeURIComponent(JSON.stringify(payload.filterValue)) + '&filterTitle=' + encodeURIComponent(payload.filterTitle);
		DialogHelper.openDetailDialog('Dashboard Detail', path, params, this.setState.bind(this));
	}

	render() {
		if (this.state.loading) return <Loading>Loading Dashboard...</Loading>;
		let {accountOverlapCounts, oppOverlapCounts, oppGreenfieldCount, partnerOrgName, companyPartnerCounts} = this.state;
		let hasOppReportingFeature = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS);
		let {
			accountByIndustryCounts,
			oppByIndustryCounts,
			accountByCountryCounts,
			oppByCountryCounts,
			accountByCompanySizeCounts,
			oppByCompanySizeCounts,
			accountMutualOverlapCounts,
			oppMutualOverlapCounts
		} = this.state;
		return (
			<ScrollingContainer divId={'ecosystem_partner_dashboard'}>
				{this.state.detailDialog}
				<div style={{display: 'flex', flexDirection: 'column', margin: 10, marginLeft: 5, marginRight: 5, overflow: 'scroll'}}>
					<div>
						<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'center'}}>
							<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}
											  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}
											  message={'Customers we have in common with ' + partnerOrgName}
											  value={accountOverlapCounts.clientOverlapCount}
											  destination={this.getTopItemAccountRoute(CHANNEL_REPORT_CUSTOMER_CUSTOMER)}/>
							<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_CUSTOMER)}
											  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_CUSTOMER)}
											  message={'Our prospects that are customers of ' + partnerOrgName}
											  value={accountOverlapCounts.hotProspectCount}
											  destination={this.getTopItemAccountRoute(CHANNEL_REPORT_PROSPECT_CUSTOMER)}/>
							<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_PROSPECT)}
											  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_PROSPECT)}
											  message={'Our customers that are prospects of ' + partnerOrgName}
											  value={accountOverlapCounts.hotClientCount}
											  destination={this.getTopItemAccountRoute(CHANNEL_REPORT_CUSTOMER_PROSPECT)}/>
							<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_COMPANY_PARTNER, CHANNEL_REPORT_CONNECTIONS)}
											  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_COMPANY_PARTNER, CHANNEL_REPORT_CONNECTIONS)}
											  message={'Total people we are connected with at ' + partnerOrgName}
											  value={companyPartnerCounts.activeConnections}
											  destination={PartnerOrgHelper.getPartnerConnectionsRoute(this.companyPartnerPublicId)}/>
							<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_COMPANY_PARTNER, CHANNEL_REPORT_SPREADSHEETS)}
											  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_COMPANY_PARTNER, CHANNEL_REPORT_SPREADSHEETS)}
											  message={'Total partner sheets uploaded'}
											  value={companyPartnerCounts.spreadsheetsShared}
											  destination={PartnerOrgHelper.getPartnerSheetsRoute(this.companyPartnerPublicId)}/>
							{hasOppReportingFeature &&
							 <Fragment>
								 <DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_OPPS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}
												   title={ReportHelper.getReportFilterTitle(ASSET_TYPE_OPPS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}
												   message={'Partner opps on mutual customer accounts'}
												   value={oppOverlapCounts.clientOverlapCount}
												   destination={this.getTopItemOppRoute(MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}/>
								 <DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_OPPS, CHANNEL_REPORT_PROSPECT_PROSPECT)}
												   title={ReportHelper.getReportFilterTitle(ASSET_TYPE_OPPS, CHANNEL_REPORT_PROSPECT_PROSPECT)}
												   message={'Partner opps on mutual prospect accounts'}
												   value={oppOverlapCounts.prospectOverlapCount}
												   destination={this.getTopItemOppRoute(MATCHING_TYPE_PARTNER_MUTUAL_OPPS, CHANNEL_REPORT_PROSPECT_PROSPECT)}/>
								 {/*<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_OPPS, CHANNEL_REPORT_GREENFIELD)}*/}
								 {/*			   title={ReportHelper.getReportFilterTitle(ASSET_TYPE_OPPS, CHANNEL_REPORT_GREENFIELD)}*/}
								 {/*			   message={'Partner opps on accounts I don\'t have'}*/}
								 {/*			   value={oppGreenfieldCount}*/}
								 {/*			   destination={this.getTopItemOppRoute(MATCHING_TYPE_GREENFIELD, CHANNEL_REPORT_ALL)}/>*/}
							 </Fragment>
							}
						</div>
						<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
							<CircleGraph title={TITLE_ACCOUNTS_BY_INDUSTRY}
										 sections={accountByIndustryCounts}
										 onClick={this.onCircleGraphAccountClick}/>
							<CircleGraph title={TITLE_ACCOUNTS_BY_COMPANY_SIZE}
										 sections={accountByCompanySizeCounts}
										 onClick={this.onCircleGraphAccountClick}/>
							<CircleGraph title={TITLE_ACCOUNTS_BY_COUNTRY}
										 sections={accountByCountryCounts}
										 onClick={this.onCircleGraphAccountClick}/>
							<CircleGraph title={TITLE_ACCOUNTS_MUTUAL_OVERLAP}
										 sections={accountMutualOverlapCounts}
										 onClick={this.onCircleGraphAccountClick}/>
							{hasOppReportingFeature &&
							 <Fragment>
								 <CircleGraph title={TITLE_OPPS_BY_INDUSTRY}
											  sections={oppByIndustryCounts}
											  onClick={this.onCircleGraphOppClick}/>
								 <CircleGraph title={TITLE_OPPS_BY_COMPANY_SIZE}
											  sections={oppByCompanySizeCounts}
											  onClick={this.onCircleGraphOppClick}/>
								 <CircleGraph title={TITLE_OPPS_BY_COUNTRY}
											  sections={oppByCountryCounts}
											  onClick={this.onCircleGraphOppClick}/>
								 <CircleGraph title={TITLE_OPPS_MUTUAL_OVERLAP}
											  sections={oppMutualOverlapCounts}
											  onClick={this.onCircleGraphOppClick}/>
							 </Fragment>}
							{!hasOppReportingFeature &&
							 UpsellHelper.channelOpportunitySharing}
						</div>
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

PartnerOrgSummary.propTypes = {
	companyPartnerName: PropTypes.string.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerOrgSummary));
