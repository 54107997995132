import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {UPLOAD_FULL_SERVICE_CSV, UPLOAD_PARTNER_SHEET, UPLOAD_PII_SHARING_OPT_IN} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import EnvHelper from '../../../../helpers/EnvHelper';
import PersonQueueHelper from '../../../../helpers/PersonQueueHelper';
import UploadHelper, {
	PERSON_QUEUE_STATUS_FAILED_TO_PROCESS,
	PERSON_QUEUE_STATUS_NOT_PROCESSED,
	PERSON_QUEUE_STATUS_PROCESSED
} from '../../../../helpers/UploadHelper';
import {partnerTapAlert, partnerTapAppBackground, partnerTapDefaultText} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SupportButton from '../../../../ui/buttons/SupportButton';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import MessageBoxAlert from '../../../../ui/messages/MessageBoxAlert';
import ProgressBar from '../../../../ui/ProgressBar';
import MessageBoxInfo from '../../../../ui/messages/MessageBoxInfo';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';

class ProcessUpload extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true};

		this.getPersonQueueIdForUploadId = this.getPersonQueueIdForUploadId.bind(this);
		this.updateCurrentUpload = this.updateCurrentUpload.bind(this);
	}

	componentDidMount() {
		this.getPersonQueueIdForUploadId();
	}

	componentWillUnmount() {
		PersonQueueHelper.stopPolling(this.personQueueId);
		this.unmounted = true;
	}

	get personUploadId() {
		return this.props.match.params.personUploadId || this.props.personUploadId;
	}

	get uploadType() {
		return this.props.match.params.uploadType;
	}

	get isPartnerSheet() {
		return this.uploadType === UPLOAD_PARTNER_SHEET;
	}

	get isPiiOptIn() {
		return this.uploadType === UPLOAD_PII_SHARING_OPT_IN;
	}

	get isFullServiceCsv() {
		return this.uploadType === UPLOAD_FULL_SERVICE_CSV;
	}

	get hasViewSheet() {
		return !this.isPiiOptIn && !this.isFullServiceCsv;
	}

	getPersonQueueIdForUploadId() {
		ChannelMappingEndpoints.getPersonUpload(this.personUploadId)
		.then((result) => {
			if (this.unmounted) return;
			let {personQueueId} = result.payload;
			if (!personQueueId) {
				throw new Error('Server did not return a personQueueId for personUploadId ' + this.personUploadId);
			}
			return this.updateCurrentUpload(personQueueId);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getPersonUpload', error);
			this.setState({loading: false});
		});
	}

	updateCurrentUpload(personQueueId) {
		this.personQueueId = personQueueId;
		PersonQueueHelper.pollPersonQueueStatus(personQueueId,
			(payload) => {
				if (this.unmounted || EnvHelper.isSpoofing) {
					PersonQueueHelper.stopPolling(this.personQueueId);
					return;
				}
				this.setState({loading: false, uploadItem: payload});
			},
			(payload, error) => {
				if (this.unmounted) {
					PersonQueueHelper.stopPolling(this.personQueueId);
					return;
				}
				this.setState({loading: false, uploadItem: payload});
				if (!error) {
					if (this.props.onUploadComplete) {
						this.props.onUploadComplete(payload);
					}
				}
			});
	}

	render() {
		let {loading, uploadItem} = this.state;
		if (loading) return <Loading>Loading Upload Status...</Loading>;
		if (!uploadItem) return <div>Upload Failed</div>;

		let onlyRenderProgressBar = Boolean(this.props.personUploadId);
		let progressMessage = PersonQueueHelper.progressMessage(onlyRenderProgressBar, uploadItem);
		let progressPercentage = PersonQueueHelper.progressPercentage(uploadItem);
		let progressBar =
			<ProgressBar title={uploadItem.batchName}
						 truncateTitle={false}
						 progressMessage={progressMessage}
						 onlyRenderProgressBar={onlyRenderProgressBar}
						 progressPercentage={progressPercentage}
						 totalProcessedRows={uploadItem.totalRowsProcessed}/>;
		if (onlyRenderProgressBar) {
			return progressBar;
		}

		let isError = uploadItem.status === PERSON_QUEUE_STATUS_FAILED_TO_PROCESS || uploadItem.status === PERSON_QUEUE_STATUS_NOT_PROCESSED;
		let isComplete = uploadItem.status === PERSON_QUEUE_STATUS_PROCESSED;
		let statusColor = isError ? partnerTapAlert : partnerTapDefaultText;
		return (
			<ScrollingContainer divId={'custom_field_mapping'}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					overflow: 'scroll',
					margin: 10,
					padding: 10,
					borderRadius: 10,
					backgroundColor: partnerTapAppBackground
				}}>
					<div style={{fontSize: 18, fontWeight: 'bold', color: statusColor, padding: 20}}>
						{UploadHelper.getUploadStatus(uploadItem.status)}
					</div>
					{isError &&
					 <MessageBoxAlert>
						 Sorry, an error occurred while processing '{uploadItem.batchName}'
						 <div style={{paddingTop: 10}}>
							 <SupportButton/>
						 </div>
					 </MessageBoxAlert>}
					{(isComplete && this.hasViewSheet) &&
					 <PrimaryButton label={'VIEW SHEET'}
									onClick={() => {
										EnvHelper.push(this.isPartnerSheet ?
											Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.PATH(uploadItem.id, Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_ACCOUNTS.PAGE_NAME) :
											Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.PATH(this.personQueueId));
									}}/>}
					{(!isError && !isComplete) &&
					 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						 {progressBar}
						 <MessageBoxInfo hideIcon={true}>
							 <div>
								 Your sheet will take some time to process. We'll notify you when it's ready.
							 </div>
							 <div>
								 In the meantime, feel free to upload another sheet or navigate to other PartnerTap features.
							 </div>
						 </MessageBoxInfo>
					 </div>}
					<div style={{padding: 10}}>
						<SecondaryButton label={'ADD ANOTHER SHEET'} onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.UPLOAD.PATH(this.uploadType))}/>
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

ProcessUpload.propTypes = {
	personUploadId: PropTypes.number,
	onUploadComplete: PropTypes.func,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(ProcessUpload));
