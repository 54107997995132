import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../../endpoints/ManagerAnalyticsEndpoints';
import {COLUMN_KEY_ACCOUNT_NAME} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import NavHelper from '../../../helpers/NavHelper';
import {partnerTapSecondary} from '../../../styles/partnertap_theme';
import ManagerAnalyticsPage from '../ManagerAnalyticsPage';

class RepIntelAnalytics extends Component {

	constructor(props, context) {
		super(props, context);
		this.onClickAccountName = this.onClickAccountName.bind(this);
	}

	componentDidMount() {
		if (!this.props.managerAnalyticsState.currentRep.personId) {
			EnvHelper.push(Routes.MANAGER_ANALYTICS.INTEL_ANALYTICS.ROUTE);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.managerAnalyticsState.division !== prevProps.managerAnalyticsState.division ||
			this.props.managerAnalyticsState.currentCompanyName !== prevProps.managerAnalyticsState.currentCompanyName ||
			this.props.managerAnalyticsState.searchRange !== prevProps.managerAnalyticsState.searchRange) {
			EnvHelper.push(Routes.MANAGER_ANALYTICS.INTEL_ANALYTICS.ROUTE);
		}
	}

	onClickAccountName(value, data) {
		let {person} = this.props.authState;
		let linkSFDC = person && data && data.thirdPartyId;
		let link = linkSFDC && NavHelper.getCrmAccountLink(person.crmType, person.crmInstance, data.thirdPartyId);
		if (link) {
			return (
				<div style={{color: partnerTapSecondary, cursor: 'pointer'}} onClick={() => window.open(link, '_system')}>
					{value}
				</div>
			);
		}
		else {
			return value;
		}
	}

	render() {
		let columns = [
			{title: 'Account Name', key: COLUMN_KEY_ACCOUNT_NAME, renderFunction: this.onClickAccountName},
			{title: 'Partner Rep', key: 'partnerName'},
			{title: 'Partner Title', key: 'partnerJobTitle'},
			{title: 'Organization', key: 'partnerOrg'},
			{title: 'Exchanges', key: 'intelsExchangedCount'},
			{title: 'Date', key: 'lastExchangeTimestamp', renderFunction: DateHelper.epochToDate}
		];
		return (
			<ManagerAnalyticsPage labelNoFind={'No rep intel data found'}
								  labelLoading={'Loading Rep Intel Data...'}
								  sectionName={'Rep Intel Exchanges'}
								  glyph={'c'}
								  columnData={columns}
								  keyData={[ManagerAnalyticsEndpoints.keyRepIntel]}/>
		);
	}
}

RepIntelAnalytics.propTypes = {
	authState: PropTypes.object.isRequired,
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(RepIntelAnalytics);
