import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as PartnersEndpoints from '../../endpoints/PartnersEndpoints';
import * as ProfileEndpoints from '../../endpoints/ProfilesEndpoints';
import {INVITE_STATUS_ACCEPTED, INVITE_STATUS_UNPARTNERED} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import {PRODUCT_CHANNEL_ECOSYSTEM, PRODUCT_SALES_NETWORK} from '../../helpers/FeatureHelper';
import * as AuthActions from '../../redux/AuthActions';
import CancelButton from '../../ui/buttons/CancelButton';
import Dialog from '../../ui/Dialog';
import Loading from '../../ui/Loading';

class PartnerProfileControls extends Component {

	constructor(props, context) {
		super(props, context);
		this.removePartner = this.removePartner.bind(this);
		this.removePartnerConfirmed = this.removePartnerConfirmed.bind(this);
		this.removePartnerDenied = this.removePartnerDenied.bind(this);
		this.toggleSharedOppsStatus = this.toggleSharedOppsStatus.bind(this);
		this.state = {
			loading: !this.props.profile,
			partnerProfile: this.props.profile,
			sharing: this.props.profile ? this.props.profile.sharedOpp : false,
			showRemovePartnerConfirm: false
		};
	}

	componentDidMount() {
		if (!this.state.partnerProfile && this.props.personId) {
			ProfileEndpoints.fetchProfile(this.props.personId)
			.then((result) => {
				if (this.unmounted) return;
				this.setState({
					loading: false,
					partnerProfile: result.payload,
					sharing: result.payload.sharedOpp
				});
			});
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	removePartner() {
		this.setState({showRemovePartnerConfirm: true});
	}

	removePartnerConfirmed() {
		let productCode = EnvHelper.inChannelEcosystemProduct ? PRODUCT_CHANNEL_ECOSYSTEM : PRODUCT_SALES_NETWORK;
		PartnersEndpoints.updatePartner(this.state.partnerProfile.partnerId, INVITE_STATUS_UNPARTNERED, false, productCode)
		.then((result) => {
			if (this.unmounted) return;
			let targetRoute = EnvHelper.inChannelEcosystemProduct ? Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE : Routes.DASHBOARD.PARTNERS.ACTIVE.ROUTE;
			if (this.props.location.pathname === targetRoute) {
				EnvHelper.dispatch(AuthActions.refreshApp());
			}
			else {
				EnvHelper.push(targetRoute);
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from updatePartner', error);
		});
	}

	removePartnerDenied() {
		this.setState({showRemovePartnerConfirm: false});
	}

	toggleSharedOppsStatus() {
		let partnerProfile = this.state.partnerProfile;
		partnerProfile.sharedOpp = !partnerProfile.sharedOpp;
		PartnersEndpoints.updatePartner(partnerProfile.partnerId, INVITE_STATUS_ACCEPTED, partnerProfile.sharedOpp);
		this.setState({sharing: !this.state.sharing});
	}

	render() {
		if (this.state.loading) return (<Loading>Loading Partner Info...</Loading>);
		let dialog;
		if (this.state.showRemovePartnerConfirm) {
			dialog = <Dialog title={'Please Confirm'}
							 message={'Are you sure you want to remove ' +
									  this.state.partnerProfile.firstName + ' ' + this.state.partnerProfile.lastName +
									  ' as a partner?'}
							 yesAction={this.removePartnerConfirmed}
							 noAction={this.removePartnerDenied}/>;
		}
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				{EnvHelper.inSalesNetworkProduct &&
				 <div style={{paddingTop: 10}}>
					 Share Opportunities
					 <Switch checked={this.state.sharing} onChange={this.toggleSharedOppsStatus} disabled={EnvHelper.isSpoofing}/>
				 </div>}
				<div style={{paddingTop: 20}}>
					<CancelButton label={'REMOVE PARTNER'} onClick={this.removePartner} disabled={EnvHelper.isSpoofing}/>
				</div>
				{dialog}
			</div>
		);
	}
}

PartnerProfileControls.propTypes = {
	personId: PropTypes.string.isRequired,
	profile: PropTypes.object,
	person: PropTypes.object,
	location: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerProfileControls));
