import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {CATEGORY_PARTNER_OPPS} from '../../../../globals/Enums';
import EcosystemShareHelper, {SHARE_SUMMARY} from '../../../../helpers/EcosystemShareHelper';
import ReportHelper, {ASSET_TYPE_OPPS} from '../../../../helpers/ReportHelper';
import PartnerConnectionPagingBase from './PartnerConnectionPagingBase';

class PartnerConnectionOpportunitiesPage extends PartnerConnectionPagingBase {

	constructor(props, context) {
		super(props, context, 'Opps', ReportHelper.partnerOpportunityReportTypes, CATEGORY_PARTNER_OPPS);
	}

	get storageKeyBase() {
		return this.getStorageKeyBase('opps');
	}

	get isPartnerSharing() {
		let {oppShareSettings} = this.props;
		let {currentReportType} = this.state;
		return oppShareSettings.partnerAssetShareOption !== SHARE_SUMMARY || currentReportType.isMyOpps || currentReportType.isMySharedOpps;
	}

	get noDataMessage() {
		let {rowData} = this.state;
		if (rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {partner, oppShareSettings} = this.props;
			let {currentReportType} = this.state;
			if (this.upsellFeature) {
				return this.upsellFeature;
			}
			if (currentReportType.isOppsGreenfield || currentReportType.isOppsCombined) {
				if (EcosystemShareHelper.includesOppsGreenfield(oppShareSettings.partnerAssetShareOption)) {
					return (
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
							<div style={{padding: 20}}>
								{partner.firstName} is sharing greenfield opportunities with you, but none were found.
							</div>
						</div>
					);
				}
				return this.renderNotSharingMessage('greenfield opportunities');
			}
			if (!this.isPartnerSharing) {
				return this.renderNotSharingMessage('opportunities');
			}
			if (currentReportType.isMyOpps) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							No opportunities found on accounts that match with {partner.firstName}.
						</div>
					</div>
				);
			}
			if (currentReportType.isMySharedOpps && oppShareSettings.ownerAssetShareOption === SHARE_SUMMARY) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							You aren't sharing any opportunities with {partner.firstName}.
						</div>
						{EcosystemShareHelper.renderAdjustAction(partner)}
					</div>
				);
			}
			return EcosystemShareHelper.renderNoOverlapMessaging(
				ASSET_TYPE_OPPS,
				oppShareSettings.ownerAssetShareOption,
				oppShareSettings.partnerAssetShareOption,
				partner,
				currentReportType.filterType);
		}
		return super.noDataMessage;
	}
}

PartnerConnectionOpportunitiesPage.propTypes = {
	oppShareSettings: PropTypes.object.isRequired,
	partner: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerConnectionOpportunitiesPage));
