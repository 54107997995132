import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import Dialog from '../Dialog';
import SecondaryButton from './SecondaryButton';

class CancelButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.onClick = this.onClick.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
		this.state = {showConfirmDialog: false};
	}

	onClick() {
		if (this.props.confirmationMessage) {
			this.setState({showConfirmDialog: true});
		}
		else {
			this.props.onClick();
		}
	}

	onCancel() {
		this.setState({showConfirmDialog: false});
	}

	onConfirm() {
		this.setState({showConfirmDialog: false});
		this.props.onClick();
	}

	render() {
		let label = this.props.label ? this.props.label : 'CANCEL';
		let dialog = '';
		if (this.state.showConfirmDialog) {
			dialog = (
				<Dialog title={'Please Confirm'} message={this.props.confirmationMessage} noAction={this.onCancel} yesAction={this.onConfirm}/>
			);
		}
		return (
			<Fragment>
				<SecondaryButton label={label} disabled={this.props.disabled} onClick={this.onClick}/>
				{dialog}
			</Fragment>
		);
	}
}

CancelButton.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	confirmationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool
};

export default CancelButton;
