import {CATEGORY_PARTNER_OPPS} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import SavedReportBase from './SavedReportBase';

class SavedReportPartnerOrg extends SavedReportBase {

	get path() {
		let pageName;
		if (this.reportCategory === CATEGORY_PARTNER_OPPS) {
			pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_OPPORTUNITIES.PAGE_NAME;
		}
		else {
			pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_ACCOUNTS.PAGE_NAME;
		}
		return this.reportUrl + '/' + this.reportParams.companyPartnerPublicId + '/' + pageName + (this.reportType ? '/' + this.reportType : '');
	}

	get params() {
		return super.params + '&filterTitle=' + this.reportParams.filterTitle;
	}

	async validateReport() {
		return true;
	}
}

export default SavedReportPartnerOrg;
