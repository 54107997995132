import {AddCircle} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ChatHelper from '../../helpers/ChatHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import PartnerTapIcon from '../PartnerTapIcon';
import PrimaryButton from './PrimaryButton';

class ChatButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {disabled: this.props.disabled};
		this.onClick = this.onClick.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.intelState && this.props.isNewChat && this.state.disabled !== ChatHelper.disableNewChat) {
			setTimeout(() => {
				if (this.unmounted) return;
				this.setState({disabled: ChatHelper.disableNewChat});
			});
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onClick() {
		if (this.props.isNewChat) {
			ChatHelper.showChat(ChatHelper.NEW_CHAT, this.props);
		}
		else {
			let {partnerId, partnerPersonId, personSaleAccountId, saleAccountLocationId, fromPartnerOpp} = this.props;
			ChatHelper.showChatPartner(partnerId, partnerPersonId, personSaleAccountId, saleAccountLocationId, null, fromPartnerOpp);
		}
	}

	render() {
		let icon;
		if (this.props.isNewChat) {
			icon = <AddCircle style={{color: partnerTapWhite}}/>;
		}
		else {
			icon = <PartnerTapIcon glyph={'C'} style={PartnerTapIcon.buttonIconStyle}/>;
		}
		return (
			<PrimaryButton label={this.props.label ? this.props.label : (this.props.isNewChat ? 'NEW INTEL' : (EnvHelper.isDesktop ? 'INTEL CHAT' : 'INTEL'))}
						   icon={icon}
						   onClick={this.onClick}
						   disabled={this.state.disabled}/>
		);
	}
}

ChatButton.propTypes = {
	isNewChat: PropTypes.bool,
	partner: PropTypes.object,
	partnerId: PropTypes.string,
	partnerPersonId: PropTypes.string,
	account: PropTypes.object,
	personSaleAccountId: PropTypes.string,
	saleAccountLocationId: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	fromPartnerOpp: PropTypes.bool,
	intelState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		intelState: state.intelState
	};
}

export default withRouter(connect(mapStateToProps)(ChatButton));
