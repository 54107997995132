import {JoinLeftOutlined, Sync, TaskOutlined} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {CHANNEL_REPORT_TYPE_BASE_ACCOUNTS} from '../../../helpers/ReportHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';

class MyAccountsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {personQueueIds: [this.personQueueId], channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS};
		this.state.filterSelectorMounted = false;
	}

	componentDidMount() {
		this.fetchCompanyAccountColumns();
	}

	fetchCompanyAccountColumns() {
		ChannelMappingEndpoints.getColumns({channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCompanyAccountColumns', error);
		});
	}

	get storageKeyBase() {
		return 'ecosystem_my_accounts';
	}

	get title() {
		if (this.crmType) {
			let crmName = DataManagerHelper.getCrmName(this.props.match.params.personQueueId);
			return crmName + ' Sync';
		}
		if (this.personQueueId) return 'My Sheet';
		return 'My Accounts';
	}

	get icon() {
		if (this.crmType) return Sync;
		if (this.personQueueId) return TaskOutlined;
		return JoinLeftOutlined;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get personQueueId() {
		if (this.crmType) return null;
		return this.props.match.params.personQueueId;
	}

	get crmType() {
		if (this.props.match.params.personQueueId && this.props.match.params.personQueueId.indexOf('COMPANY_SALE_ACCOUNTS_') === 0) {
			return DataManagerHelper.getCrmType(this.props.match.params.personQueueId);
		}
		return null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				selectedColumns: this.activeColumns,
				personQueueIds: this.personQueueId ? [this.personQueueId] : [],
				crmType: this.crmType,
				channelReportType: CHANNEL_REPORT_TYPE_BASE_ACCOUNTS
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		return super.render();
	}
}

MyAccountsPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default connect()(MyAccountsPage);
