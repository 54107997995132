import {CHANNEL_MAPPER} from '../globals/Enums';
import * as ApiHelper from '../helpers/ApiHelper';
import {PRODUCT_CHANNEL_ECOSYSTEM} from '../helpers/FeatureHelper';

export function register(firstName, lastName, companyName, email, phone, productCode, connectWithPartner, inviterOrgId, inviterPersonId) {
	if (productCode === CHANNEL_MAPPER) productCode = PRODUCT_CHANNEL_ECOSYSTEM;
	let payload = {
		firstName: firstName,
		lastName: lastName,
		organization: companyName,
		email: email,
		phone: phone,
		productCode: productCode,
		connectWithPartner: connectWithPartner || false,
		inviterOrgId: inviterOrgId || '',
		inviterPersonId: inviterPersonId || ''
	};
	return ApiHelper.sendPost('/register', payload);
}

export function fetchOrgInfo(orgId, personId) {
	return ApiHelper.sendGet('/register/orginfo?orgId=' + orgId + '&personId=' + personId);
}
