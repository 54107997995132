import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Loading from '../Loading';
import PartnerTapIcon from '../PartnerTapIcon';

class LogoMessage extends Component {

	render() {
		let {message, action} = this.props;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10, height: '100%'}}>
				{PartnerTapIcon.logoTall}
				{action ? <div/> : <Loading>{message}</Loading>}
				{action}
			</div>
		);
	}
}

LogoMessage.propType = {
	message: PropTypes.string,
	action: PropTypes.element
};

export default LogoMessage;
