import {Business, PlayArrow} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import ChannelHelper from '../../../helpers/ChannelHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapInactive, partnerTapPrimary, partnerTapStroke, partnerTapTernary} from '../../../styles/partnertap_theme';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import {PageHeader} from '../../../ui/PageHeader';
import PartnerTapIcon from '../../../ui/PartnerTapIcon';

class CoSellEmailAccessPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, actionListInfo: null, optInKeys: []};

		this.fetchActionList = this.fetchActionList.bind(this);
		this.renderAccountSelector = this.renderAccountSelector.bind(this);
		this.onCheck = this.onCheck.bind(this);
		this.onCheckAll = this.onCheckAll.bind(this);
		this.updateOptInCount = this.updateOptInCount.bind(this);
		this.confirm = this.confirm.bind(this);
		this.confirmCanceled = this.confirmCanceled.bind(this);
		this.confirmed = this.confirmed.bind(this);
	}

	componentDidMount() {
		this.fetchActionList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get actionListId() {
		return this.props.match.params.actionListId;
	}

	fetchActionList() {
		let {emailAddress, securityKey, authorPersonId} = this.props.match.params;
		CoSellEngineEndpoints.getCoSellActionList(this.actionListId, {emailAddress: emailAddress, securityKey: securityKey, authorPersonId: authorPersonId})
		.then((result) => {
			if (this.unmounted) return;
			let actionListInfo = result.payload;

			let workflowRecords = actionListInfo.emailAccessWorkflowRecords.map((workflowRecord) => {
				if (!workflowRecord.isRemoved) {
					return workflowRecord.dto;
				}
			})
			.filter((workflowRecord) => workflowRecord);
			ChannelHelper.convertOtherJsonValues(workflowRecords);
			actionListInfo.emailAccessWorkflowRecords = this.collateWorkflowRecords(workflowRecords);

			this.setState({actionListInfo: actionListInfo, loading: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionList', error);
		});
	}

	collateWorkflowRecords(workflowRecords) {
		let partnerAccountLists = {};
		let count = 0;
		if (workflowRecords) {
			workflowRecords.forEach((workflowRecord) => {
				let ownerEmail = workflowRecord['OwnerId.email'];
				if (ownerEmail === 'A.Beasley@jaredsystems.net') {
					let partnerEmail = workflowRecord['partnerowneridname'] + ', ' + workflowRecord['partnerowneridtitle'];
					if (!partnerAccountLists[partnerEmail]) {
						partnerAccountLists[partnerEmail] = [];
					}
					partnerAccountLists[partnerEmail].push({name: workflowRecord.accountName, isChecked: false});
					count++;
				}
			});
		}
		this.setState({countTotal: count, countOptedIn: 0});
		return partnerAccountLists;
	}

	get allChecked() {
		return this.state.countTotal === this.state.countOptedIn;
	}

	onCheck(accountOptIn, checked) {
		accountOptIn.isChecked = checked;
		this.updateOptInCount();
	}

	onCheckAll(checked) {
		let {actionListInfo} = this.state;
		Object.keys(actionListInfo.emailAccessWorkflowRecords).map((key) => {
			let accountOptIns = actionListInfo.emailAccessWorkflowRecords[key];
			accountOptIns.forEach((accountOptIn) => accountOptIn.isChecked = checked);
		});
		this.updateOptInCount();
	}

	updateOptInCount() {
		let {actionListInfo} = this.state;
		let countOptedIn = 0;
		Object.keys(actionListInfo.emailAccessWorkflowRecords).map((key) => {
			let accountOptIns = actionListInfo.emailAccessWorkflowRecords[key];
			accountOptIns.forEach((accountOptIn) => countOptedIn += accountOptIn.isChecked ? 1 : 0);
		});
		this.setState({countOptedIn: countOptedIn});
	}

	renderAccountSelector() {
		let {actionListInfo, countOptedIn, showConfirmDialog} = this.state;
		let plural = countOptedIn !== 1 ? 's' : '';
		return (
			<ScrollingContainer divId={'co_sell_email_access'}>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll'}}>
					<div style={{display: 'flex', flexDirection: 'column', padding: 20, maxWidth: 600, fontSize: 16, gap: 10}}>
						<div>Hi Anthony!</div>
						<div>Here are some high value co-sell targets for you to consider teaming up on.</div>
						<div>We identified {Object.keys(actionListInfo.emailAccessWorkflowRecords).length} partners with really strong overlap.</div>
						<div>Check the box beside each account you want to pursue, and we'll introduce you to partners that share your interests!</div>
					</div>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
							<SecondaryButton label={this.allChecked ? 'UNCHECK ALL' : 'CHECK ALL'} onClick={() => this.onCheckAll(!this.allChecked)}/>
						</div>
						{Object.keys(actionListInfo.emailAccessWorkflowRecords).map((key) => {
							let partnerName = key;
							let accountOptIns = actionListInfo.emailAccessWorkflowRecords[key];
							return (
								<div key={key} style={{padding: 10}}>
									<div>
										<u>{partnerName}</u>
									</div>
									<div>
										{accountOptIns.map((accountOptIn) => {
											return (
												<div key={accountOptIn.name} style={{display: 'flex', alignItems: 'center'}}>
													<CheckboxButton checked={accountOptIn.isChecked}
																	onChange={(event) => this.onCheck(accountOptIn, event.target.checked)}/>
													{accountOptIn.name}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div style={{display: 'flex', justifyContent: 'center', gap: 20, padding: 10, borderTop: '1px solid ' + partnerTapStroke}}>
					<PrimaryButton label={'CONFIRM'} icon={<PlayArrow/>} disabled={countOptedIn === 0} onClick={this.confirm}/>
				</div>
				{showConfirmDialog &&
				 <Dialog title={'Please Confirm'}
						 message={
							 <div>
								 You opted in to {countOptedIn} Account{plural}. Are you ready to submit your response?
							 </div>}
						 yesAction={this.confirmed}
						 yesLabel={'YES'}
						 noAction={this.confirmCanceled}
						 noLabel={'NOT YET'}/>}
			</ScrollingContainer>
		);
	}

	renderConfirmedMessage() {
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, fontSize: 16, gap: 10}}>
				<div>
					Congratulations! You have successfully opted in to co-sell with your partners.
				</div>
				<div>
					Please keep an eye out for your introduction email.
				</div>
				<div style={{fontSize: 14, color: partnerTapInactive}}>
					(You can close this page now)
				</div>
			</div>
		);
	}

	confirm() {
		this.setState({showConfirmDialog: true});
	}

	confirmCanceled() {
		this.setState({showConfirmDialog: false});
	}

	confirmed() {
		this.setState({showConfirmDialog: false, confirmed: true});
	}

	render() {
		if (this.state.loading) return <Loading>Loading Action List...</Loading>;
		let {countOptedIn, confirmed} = this.state;
		let icons =
			<div style={{display: 'flex', alignItems: 'center', gap: 15, fontSize: 30}}>
				{PartnerTapIcon.logo} + <Business style={{fontSize: 60}}/>
			</div>;
		let plural = countOptedIn !== 1 ? 's' : '';
		return (
			<div>
				<PageHeader title={' '} icon={icons}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div style={{flex: 1, padding: 20, textAlign: 'center', color: partnerTapPrimary, fontSize: 28, fontWeight: 'bold'}}>
							PartnerTap Co-Sell Opt In
						</div>
						<div style={{color: partnerTapTernary, width: 60}}>
							<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<div style={{fontSize: 24}}>
									{countOptedIn}
								</div>
								<div style={{fontSize: 12}}>
									<div>
										Opt In{plural}
									</div>
								</div>
							</div>
						</div>
					</div>
				</PageHeader>
				{confirmed ? this.renderConfirmedMessage() : this.renderAccountSelector()}
			</div>
		);
	}
}

CoSellEmailAccessPage.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(CoSellEmailAccessPage);
