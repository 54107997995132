import {GpsOff, Hub, JoinInner, LocationOn, PieChartOutline} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import FeatureHelper, {FEATURE_CHANNEL_MATCHING_BY_LOCATION_SHEETS} from '../../../../helpers/FeatureHelper';
import Loading from '../../../../ui/Loading';
import SubNav from '../../../../ui/navigation/SubNav';
import SheetHeader from '../../shared/sheets/SheetHeader';
import PartnerSheetAccountsPage from './PartnerSheetAccountsPage';
import PartnerSheetDashboard from './PartnerSheetDashboard';
import PartnerSheetOpportunitiesPage from './PartnerSheetOpportunitiesPage';

class PartnerSheetRouter extends Component {

	constructor(props, context) {
		super(props, context);

		let sheet_routes = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS;
		let {personQueueId, reportType, partnerOrgName} = this.props.match.params;
		this.subNavData = [
			{
				label: 'Summary',
				MaterialIcon: PieChartOutline,
				route: sheet_routes.PATH(personQueueId, sheet_routes.DASHBOARD.PAGE_NAME, null, partnerOrgName)
			},
			{
				label: 'Accounts',
				MaterialIcon: JoinInner,
				route: sheet_routes.PATH(personQueueId, sheet_routes.MAPPED_ACCOUNTS.PAGE_NAME, reportType, partnerOrgName),
				activeRoutes: [sheet_routes.MAPPED_ACCOUNTS.ROUTE]
			},
			{
				label: 'Opportunities',
				MaterialIcon: Hub,
				route: sheet_routes.PATH(personQueueId, sheet_routes.MAPPED_OPPS.PAGE_NAME, null, partnerOrgName)
			}
		];
		let showMappedLocations = FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_MATCHING_BY_LOCATION_SHEETS);
		if (showMappedLocations) {
			this.subNavData.push({
				label: 'Locations',
				MaterialIcon: LocationOn,
				route: sheet_routes.PATH(personQueueId, sheet_routes.MAPPED_LOCATIONS.PAGE_NAME, null, partnerOrgName)
			});
		}
		this.subNavData.push({
			label: 'Unmapped',
			MaterialIcon: GpsOff,
			route: sheet_routes.PATH(personQueueId, sheet_routes.UNMAPPED_ACCOUNTS.PAGE_NAME, null, partnerOrgName)
		});

		this.state = {loading: true, sheetData: null};
		this.fetchSpreadsheetMetaData = this.fetchSpreadsheetMetaData.bind(this);
	}

	componentDidMount() {
		this.fetchSpreadsheetMetaData();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchSpreadsheetMetaData() {
		let {personQueueId} = this.props.match.params;
		ChannelMappingEndpoints.getUploadDataForId(personQueueId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({sheetData: result.payload || {}, loading: false});
		})
		.catch((error) => {
			console.error('Error loading sheet details', error);
		});
	}

	render() {
		if (this.state.loading) return <Loading>Loading...</Loading>;
		let inReportDialog = this.props.history.location.pathname !== this.props.location.pathname;
		let {sheetData} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<SheetHeader sheetData={sheetData}/>
				{!inReportDialog &&
				 <SubNav subNavData={this.subNavData}/>}
				<Switch>
					<Route path={Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.DASHBOARD.ROUTE} component={PartnerSheetDashboard}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_OPPS.ROUTE} component={PartnerSheetOpportunitiesPage}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.ROUTE} component={PartnerSheetAccountsPage}/>
				</Switch>
			</div>
		);
	}
}

PartnerSheetRouter.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerSheetRouter));
