import {WarningSharp} from '@mui/icons-material';
import {partnerTapAlert} from '../../styles/partnertap_theme';
import MessageBox from './MessageBox';

class MessageBoxAlert extends MessageBox {

	get IconClass() {
		return WarningSharp;
	}

	get color() {
		return partnerTapAlert;
	}
}

export default MessageBoxAlert;
