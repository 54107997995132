import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {
	CATEGORY_SHEET_OPPS,
	CHANNEL_MAP_V2_LOCATIONS,
	CHANNEL_MAP_V2_UNMATCHED,
	UPLOAD_PARTNER_SHEET
} from '../../../../globals/Enums';
import {Routes} from '../../../../globals/Routes';
import {PERSON_QUEUE_STATUS_PROCESSED} from '../../../../helpers/UploadHelper';
import SavedReportBase from './SavedReportBase';

class SavedReportPartnerSheet extends SavedReportBase {

	get path() {
		let pageName;
		switch (this.reportParams.crmType) {
			case CHANNEL_MAP_V2_UNMATCHED:
				pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.UNMAPPED_ACCOUNTS.PAGE_NAME;
				break;
			case UPLOAD_PARTNER_SHEET:
				pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_ACCOUNTS.PAGE_NAME;
				break;
			case CHANNEL_MAP_V2_LOCATIONS:
				pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_LOCATIONS.PAGE_NAME;
				break;
			case CATEGORY_SHEET_OPPS:
				pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_OPPS.PAGE_NAME;
				break;
			default:
				pageName = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_ACCOUNTS.PAGE_NAME;
		}
		return this.reportUrl + '/' + pageName + '/' + this.reportParams.personQueueId + (this.reportType ? '/' + this.reportType : '');
	}

	async validateReport() {
		return await ChannelMappingEndpoints.getUploadDataForId(this.reportParams.personQueueId)
		.then((result) => {
			if (this.unmounted) return false;
			if (result.payload) {
				if (result.payload.status === PERSON_QUEUE_STATUS_PROCESSED) {
					return true;
				}
				else {
					let {sheetDetails} = result.payload;
					this.errorMessage = 'Your spreadsheet \'' + sheetDetails.batchName + '\' is inactive. You can no longer view this report.';
					return false;
				}
			}
			else {
				this.errorMessage = 'There was an unknown error retrieving your report.';
				return false;
			}
		})
		.catch((error) => {
			this.errorMessage = 'Error validating SavedReportPartnerSheet' + error;
			return false;
		});
	}
}

export default SavedReportPartnerSheet;
