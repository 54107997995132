import {Notifications, Warning} from '@mui/icons-material';
import {Badge, Menu, MenuItem} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ChatEndpoints from '../endpoints/ChatEndpoints';
import * as PartnersEndpoints from '../endpoints/PartnersEndpoints';
import * as SiteStatusEndpoints from '../endpoints/SiteStatusEndpoints';
import * as TapEndpoints from '../endpoints/TapEndpoints';
import {INVITE_STATUS_REQUESTED} from '../globals/Enums';
import {Routes} from '../globals/Routes';
import {NEW_CHANNEL_PARTNER_COUNT_KEY, NEW_PARTNER_COUNT_KEY, OPPORTUNITY_COUNT_KEY} from '../globals/StorageKeys';
import EnvHelper from '../helpers/EnvHelper';
import {PRODUCT_SALES_NETWORK} from '../helpers/FeatureHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';
import * as AuthActions from '../redux/AuthActions';
import * as NotificationActions from '../redux/NotificationActions';
import {partnerTapAlert, partnerTapSecondary, partnerTapWhite} from '../styles/partnertap_theme';
import PartnerTapIcon from './PartnerTapIcon';

class AlertMenu extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			anchorEl: null,
			intelReceived: [],
			connectionRequests: [],
			opportunityCount: 0,
			newOpportunityCount: 0,
			siteStatus: null
		};
		this.onOpenAlertMenu = this.onOpenAlertMenu.bind(this);
		this.onCloseAlertMenu = this.onCloseAlertMenu.bind(this);
		this.updateFastAlerts = this.updateFastAlerts.bind(this);
		this.updateSlowAlerts = this.updateSlowAlerts.bind(this);
	}

	componentDidMount() {
		this.fastTimeout = setTimeout(this.updateFastAlerts, 3000);
		this.slowTimeout = setTimeout(this.updateSlowAlerts, 3000);
		if (!EnvHelper.quietMode) {
			this.intervalFast = setInterval(this.updateFastAlerts, 15000);
			this.intervalSlow = setInterval(this.updateSlowAlerts, 120000);
		}
		else {
			console.info('QUIET MODE! AlertMenu polling disabled');
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
		clearInterval(this.fastTimeout);
		clearInterval(this.slowTimeout);
		clearInterval(this.intervalFast);
		clearInterval(this.intervalSlow);
	}

	updateFastAlerts() {
		if (this.blockAlerts) {
			console.info('Blocking fast alerts until server error has been resolved');
			return;
		}

		Promise.all([
			ChatEndpoints.fetchUnreadChats(),
			PartnersEndpoints.fetchPartnerRequestsReceived()
		])
		.then((results) => {
				if (this.unmounted || this.blockAlerts) return;
				let updatedRequests = results[1].payload;
				if (this.state.connectionRequests.length !== updatedRequests.length) {
					this.props.dispatch(NotificationActions.updatePartnerRequestCount(updatedRequests.length));
				}
				this.setState({
					intelReceived: results[0].payload,
					connectionRequests: updatedRequests,
					newPartnerCount: this.getNewPartnerCount(NEW_PARTNER_COUNT_KEY),
					newChannelPartnerCount: this.getNewPartnerCount(NEW_CHANNEL_PARTNER_COUNT_KEY)
				});
			}
		)
		.catch((error) => {
				console.error('Error fetching fast alerts', error);
			}
		);
	}

	get blockAlerts() {
		return this.unmounted || this.props.authState.serverError || this.props.authState.newVersionAvailable || !this.props.authState.person;
	}

	updateSlowAlerts() {
		if (this.blockAlerts) {
			console.info('Blocking slow alerts until server error has been resolved');
			return;
		}

		Promise.all([
			SiteStatusEndpoints.getDowntimeStatus(),
			TapEndpoints.fetchOpportunitiesForAlertMenu()
		])
		.then((results) => {
				if (this.blockAlerts || this.unmounted) return;
				let siteStatus = results[0].payload;
				let opportunityCount = this.getOpportunityCount(results[1].payload);
				let newOpportunityCount = this.getNewOpportunityCount(opportunityCount);
				this.setState({siteStatus: siteStatus, opportunityCount: opportunityCount, newOpportunityCount: newOpportunityCount});
			}
		)
		.catch((error) => {
				console.error('Error fetching slow alerts', error);
			}
		);
	}

	getNewPartnerCount(key) {
		let newPartnerCount = parseInt(PersistenceHelper.getValue(key));
		if (!newPartnerCount) newPartnerCount = 0;
		return newPartnerCount;
	}

	getOpportunityCount(opportunities) {
		let oppCount = 0;
		for (let iOpp in opportunities) {
			let opp = opportunities[iOpp];
			oppCount += opp.closeCount + opp.openCount;
		}
		return oppCount;
	}

	getNewOpportunityCount(currentOppCount) {
		let lastOppCount = parseInt(PersistenceHelper.getValue(OPPORTUNITY_COUNT_KEY));
		if (currentOppCount !== lastOppCount) {
			PersistenceHelper.setValue(OPPORTUNITY_COUNT_KEY, currentOppCount);
			lastOppCount = currentOppCount;
		}
		return Math.max(currentOppCount - lastOppCount, 0);
	}

	onOpenAlertMenu(event) {
		this.setState({anchorEl: event.currentTarget});
		this.updateFastAlerts();
		this.updateSlowAlerts();
	}

	onCloseAlertMenu() {
		this.setState({anchorEl: null});
	}

	render() {
		let alertList = [];
		let iconStyle = {fontSize: 36, height: 40, color: partnerTapSecondary, position: 'relative', top: -10};

		for (let i = 0; i < this.state.connectionRequests.length; i++) {
			let connection = this.state.connectionRequests[i];
			if (connection.status === INVITE_STATUS_REQUESTED) {
				alertList.push({
					type: 'connectionRequests',
					index: i,
					label: (_.isEmpty(connection.firstName) ? 'Someone' : connection.firstName + ' ' + connection.lastName) +
						   (_.isEmpty(connection.organization) ? '' : ' from ' + connection.organization) +
						   ' wants to connect!',
					path: connection.productCode === PRODUCT_SALES_NETWORK ? Routes.DASHBOARD.PARTNERS.PENDING.ROUTE : Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE,
					alertIcon: <PartnerTapIcon glyph={'e'} style={iconStyle}/>
				});
			}
		}

		for (let i = 0; i < this.state.intelReceived.length; i++) {
			let intel = this.state.intelReceived[i];
			if (intel.chatCount > 0) {
				alertList.push({
					type: 'intelReceived',
					index: i,
					label: intel.chatCount + ' Intel' +
						   (_.isEmpty(intel.publisherName) ? '' : ' from ' + intel.publisherName) +
						   (_.isEmpty(intel.saleAccountName) ? '' : ' about ' + intel.saleAccountName),
					path: Routes.INTEL_ALL_PRODUCTS.PATH(intel.personChatId),
					alertIcon: <PartnerTapIcon glyph={'c'} style={iconStyle}/>
				});
			}
		}

		if (!_.isEmpty(this.state.siteStatus)) {
			if (this.state.siteStatus.now > this.state.siteStatus.downtimeStart &&
				(!this.state.siteStatus.downtimeEnd || this.state.siteStatus.now < this.state.siteStatus.downtimeEnd)) {
				this.props.dispatch(AuthActions.logout());
			}
			alertList.push({
				label: 'PartnerTap is currently undergoing maintenance.',
				alertIcon: <Warning style={{color: partnerTapAlert}}/>
			});
		}

		if (this.state.newPartnerCount) {
			alertList.push({
				type: 'newPartnerCount',
				label: 'You have ' + this.state.newPartnerCount + ' new partner' + (this.state.newPartnerCount !== 1 ? 's!' : '!'),
				path: '/dashboard/partners/active',
				alertIcon: <PartnerTapIcon glyph={'e'} style={iconStyle}/>
			});
		}

		if (this.state.newChannelPartnerCount) {
			alertList.push({
				type: 'newChannelPartnerCount',
				label: 'You have ' + this.state.newChannelPartnerCount + ' new channel partner' + (this.state.newChannelPartnerCount !== 1 ? 's!' : '!'),
				path: Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE,
				alertIcon: <PartnerTapIcon glyph={'e'} style={iconStyle}/>
			});
		}

		if (this.state.newOpportunityCount) {
			alertList.push({
				type: 'newOpportunityCount',
				label: 'You have ' + this.state.newOpportunityCount + ' new opportunit' + (this.state.newOpportunityCount !== 1 ? 'ies!' : 'y!'),
				path: '/dashboard/tap',
				alertIcon: <PartnerTapIcon glyph={'d'} style={iconStyle}/>
			});
		}

		let alertCount = alertList.length;
		let alertsOpen = Boolean(this.state.anchorEl);
		let key = 0;
		return (
			<div>
				<div onClick={this.onOpenAlertMenu} style={{paddingTop: 8, paddingRight: 16, cursor: 'pointer'}}>
					{(alertCount && !alertsOpen) ?
						<Badge badgeContent={alertCount} color={'secondary'}>
							<Notifications style={{color: partnerTapWhite}}/>
						</Badge>
						:
						<Notifications style={{color: partnerTapWhite}}/>
					}
				</div>
				<Menu autoFocus={false}
					  anchorEl={this.state.anchorEl}
					  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
					  transformOrigin={{vertical: 'top', horizontal: 'center'}}
					  open={alertsOpen}
					  onClose={this.onCloseAlertMenu}>
					{!alertCount ?
						<div style={{padding: 15, whiteSpace: 'nowrap'}}>You're all up to date!</div>
						:
						alertList.map((alertData) => {
							return <MenuItem key={key++}
											 style={{minWidth: 300, whiteSpace: 'normal'}}
											 onClick={() => {
												 if (alertData.path && alertData.path.indexOf('/dashboard/partners') !== -1) {
													 this.props.dispatch(NotificationActions.updateRoute(alertData.path));
												 }
												 if (alertData.type) {
													 // remove the item from alerts until polling gets to it
													 let newState = {};
													 if (Array.isArray(this.state[alertData.type])) {
														 this.state[alertData.type].splice(alertData.index, 1);
														 newState[alertData.type] = this.state[alertData.type];
													 }
													 else if (alertData.type === 'newPartnerCount') {
														 newState[alertData.type] = 0;
														 PersistenceHelper.removeValue(NEW_PARTNER_COUNT_KEY);
													 }
													 else if (alertData.type === 'newChannelPartnerCount') {
														 newState[alertData.type] = 0;
														 PersistenceHelper.removeValue(NEW_CHANNEL_PARTNER_COUNT_KEY);
													 }
													 else if (alertData.type === 'newOpportunityCount') {
														 newState[alertData.type] = 0;
														 PersistenceHelper.setValue(OPPORTUNITY_COUNT_KEY, this.state.opportunityCount);
													 }
													 this.setState(newState);
												 }
												 if (alertData.path) {
													 EnvHelper.push(alertData.path);
												 }
												 this.onCloseAlertMenu();
											 }}>
								{alertData.alertIcon}
								{alertData.label}
							</MenuItem>;
						})}
				</Menu>
			</div>
		);
	}
}

AlertMenu.propTypes = {
	authState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(AlertMenu);
