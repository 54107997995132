import {Clear, CloudDownload, Done} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import _ from 'lodash';
import objectAssign from 'object-assign';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../endpoints/ManagerAnalyticsEndpoints';
import {COLUMN_KEY_ACCOUNT_NAME} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import * as CSVHelper from '../../helpers/CsvHelper';
import DateHelper from '../../helpers/DateHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_ADMIN_TOOLS_REPORTS} from '../../helpers/FeatureHelper';
import NavHelper from '../../helpers/NavHelper';
import {
	partnerTapAlert,
	partnerTapDropShadow,
	partnerTapInactive,
	partnerTapPrimary,
	partnerTapSecondary,
	partnerTapStroke,
	partnerTapTernary,
	partnerTapWhite
} from '../../styles/partnertap_theme';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import PageHeader from '../../ui/PageHeader';

const passed = 'PASSED';
const failed = 'FAILED';

class ManagerReports extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			navButtons: NavHelper.managerAnalyticsData,
			selectedKey: this.props.selectedDestination,
			downloading: false,
			reports: [
				{
					name: 'Pending Partner Requests Sent',
					description: 'See who your Reps are waiting to connect with',
					prefix: 'pendingrequestslist',
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Rep Name', key: 'repName'},
						{title: 'Rep Division', key: 'division'},
						{title: 'Matched Account Count', key: 'matchedAccountsCount'},
						{title: 'Partner Name', key: 'partnerName'},
						{title: 'Partner Organization', key: 'partnerOrg'},
						{title: 'Matched Timestamp', key: 'matchedTimestamp', renderFunction: DateHelper.epochToDate}
					]
				},
				{
					name: 'Pending Partner Requests Received',
					description: 'See who is waiting to connect with your Reps',
					prefix: 'pendingrequestsforreplist',
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Rep Name', key: 'repName'},
						{title: 'Rep Division', key: 'division'},
						{title: 'Matched Account Count', key: 'matchedAccountsCount'},
						{title: 'Partner Name', key: 'partnerName'},
						{title: 'Partner Organization', key: 'partnerOrg'},
						{title: 'Matched Timestamp', key: 'matchedTimestamp', renderFunction: DateHelper.epochToDate}
					]
				},
				{
					name: 'Rep Territory Report',
					description: 'Get general insight on Rep activity',
					prefix: 'repterritorylist',
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Rep Name', key: 'repName'},
						{title: 'Partner Count', key: 'partnerCount'},
						{title: 'Account Name', key: COLUMN_KEY_ACCOUNT_NAME}
					]
				},
				{
					name: 'Rep Chat Activity Report',
					description: 'See who\'s exchanging Intel and with whom',
					prefix: 'repchatactivitylist',
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Chat Count', key: 'chatCount'},
						{title: 'Rep Name', key: 'repName'},
						{title: 'Partner Name', key: 'partnerName'},
						{title: 'Account Name', key: COLUMN_KEY_ACCOUNT_NAME},
						{title: 'Last Chat Date', key: 'lastChatDate'}
					]
				},
				{
					name: 'Reps Without Partners',
					description: 'See which Reps have not yet Partnered with anyone',
					prefix: 'zeroconnectionreplist',
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Rep Name', key: 'repName'}
					]
				},
				{
					name: 'All Users Report',
					description: 'List Of All Users',
					prefix: 'alluserslist',
					isSuperUser: true,
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Rep Name', key: 'repName'},
						{title: 'Mobile Number', key: 'mobileNumber'},
						{title: 'Email', key: 'emailAddress'},
						{title: 'Organization', key: 'organization'}
					]
				},
				{
					name: 'Rep Chat Details Report',
					description: 'See chat messages exchanged',
					prefix: 'allchats',
					status: failed,
					isVisible: false,
					columns: [
						{title: 'Sender Name', key: 'senderName'},
						{title: 'Sender Division', key: 'senderDivision'},
						{title: 'Sender Organization', key: 'senderOrganization'},
						{title: 'Recipient Name', key: 'recipientName'},
						{title: 'Recipient Division', key: 'recipientDivision'},
						{title: 'Recipient Organization', key: 'recipientOrganization'},
						{title: 'Account Name', key: COLUMN_KEY_ACCOUNT_NAME},
						{title: 'Last Chat Date', key: 'lastChatDate'},
						{title: 'Chat Message', key: 'chatMessage'}
					]
				}
			]
		};
	}

	componentDidMount() {
		if (!EnvHelper.isDesktop) EnvHelper.push(Routes.MANAGER_ANALYTICS.DASHBOARD.ROUTE);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	updateCheck(key, status, isVisible) {
		let state = objectAssign({}, this.state);
		for (let i = 0; i < state.reports.length; i++) {
			if (state.reports[i].prefix === key) {
				state.reports[i].status = status;
				state.reports[i].isVisible = isVisible;
				this.setState({reports: state.reports});
				return;
			}
		}
	}

	onClickDownload(data) {
		if (data.downloaded) {
			console.warn('already downloaded this report');
			return;
		}
		data.downloaded = true;
		if (this.downloading === true) {
			console.warn('already downloading a report');
			return;
		}
		data.downloading = true;
		this.downloading = true;
		this.setState({downloading: true});

		ManagerAnalyticsEndpoints.fetchReportByPrefix(data.prefix, this.props.managerAnalyticsState.division)
		.then((result) => {
			if (this.unmounted) return;
			let status = !_.isEmpty(result.payload) ? passed : failed;
			data.downloading = false;
			this.updateCheck(data.prefix, status, true);
			this.setState({downloading: false});
			this.downloading = false;
			CSVHelper.downloadCSVSelectedColumns(result.payload, data.name + '.csv', data.columns);
		})
		.catch((error) => {
			console.error('Error downloading ' + data.prefix, error);
			data.downloading = false;
			this.downloading = false;
			this.setState({downloading: false});
			this.updateCheck(data.prefix, failed, true);
		});
	}

	render() {
		return (
			<ScrollingContainer divId={'manager_reports'}>
				<PageHeader title={'Download Reports'} icon={<CloudDownload fontSize={'large'}/>}/>
				<div style={{
					overflow: 'scroll',
					margin: 10,
					marginTop: 0,
					boxShadow: partnerTapDropShadow,
					backgroundColor: partnerTapWhite,
					borderRadius: 10
				}}>
					{this.state.reports.map((data) => {
						let cursor = data.downloaded ? 'not-allowed' : this.state.downloading ? 'wait' : 'pointer';
						let color = data.downloaded || this.state.downloading ? partnerTapInactive : partnerTapSecondary;
						if (!data.isSuperUser || FeatureHelper.isFeatureEnabled(FEATURE_ADMIN_TOOLS_REPORTS)) {
							return (
								<div key={data.name}
									 className={(data.downloaded || this.state.downloading) ? '' : 'hoverActive'}
									 style={{
										 display: 'flex',
										 alignItems: 'center',
										 justifyContent: 'space-between',
										 borderBottom: '1px solid ' + partnerTapStroke,
										 cursor: cursor
									 }}
									 onClick={() => this.onClickDownload(data)}>

									<div style={{flex: 1, display: 'flex', flexDirection: 'column', padding: 10}}>
										<div style={{fontSize: 14}}>
											{data.name}
										</div>
										<div style={{fontSize: 12, color: partnerTapInactive}}>
											{data.description}
										</div>
									</div>

									{data.isVisible &&
									 <div style={{
										 display: 'flex',
										 alignItems: 'center',
										 color: data.status === passed ? partnerTapTernary : partnerTapAlert,
										 whiteSpace: 'nowrap',
										 padding: 10
									 }}>
										 {data.status === passed ? <Done/> : <Clear/>}
										 <div style={{fontSize: 14, paddingLeft: 4}}>
											 {data.status === passed ? 'Download success' : 'Sorry, no data'}
										 </div>
									 </div>}

									<div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', padding: 10}}>
										{!data.downloading ?
											<CloudDownload style={{color: color}}/> :
											<CircularProgress size={20} style={{color: partnerTapPrimary}} disableShrink={true}/>}
										<div style={{fontSize: 14, paddingLeft: 6, color: color}}>
											Get Report
										</div>
									</div>
								</div>
							);
						}
					})}
				</div>
			</ScrollingContainer>
		);
	}
}

ManagerReports.propTypes = {
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(ManagerReports);
