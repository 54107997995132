import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Dialog from '../../ui/Dialog';
import ProfileInfo from './ProfileInfo';

class ProfileDialog extends Component {

	render() {
		return (
			<Dialog message={
				<ProfileInfo personId={this.props.personId} isEditing={false} isDialog={true}>
					{this.props.children}
				</ProfileInfo>}
					yesLabel={'Close'}
					yesAction={this.props.closeDialog}
					noContentPadding={true}/>
		);
	}
}

ProfileDialog.propTypes = {
	personId: PropTypes.string.isRequired,
	closeDialog: PropTypes.func.isRequired
};

export default ProfileDialog;
