import {PersonSearch} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import SortHelper from '../../helpers/SortHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import CheckboxButton from '../../ui/buttons/CheckboxButton';
import Dialog from '../../ui/Dialog';
import PagingBase from '../../ui/lists/PagingBase';
import OrgSelector from './shared/OrgSelector';

class AdminRecommendedPartnerOrgsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.selectedOrg = null;
		this.state.orgList = null;
		this.state.showWarnings = true;
		this.state.errorMessage = null;

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
		this.setRecommendedPartners = this.setRecommendedPartners.bind(this);
	}

	handleOrgSelection(org, orgList) {
		this.setState({selectedOrg: org, orgList: orgList, loading: true});
	}

	get storageKeyBase() {
		return 'tools_admin_recommendations';
	}

	get title() {
		return 'Recommended Partner Orgs';
	}

	get icon() {
		return PersonSearch;
	}

	get columnData() {
		return [
			{title: 'PartnerTap Org', key: 'partnerOrgName'},
			{
				title: 'Recommended',
				key: 'isRecommended',
				renderFunction: (columnValue, rowData) =>
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<CheckboxButton checked={rowData.isRecommended} onChange={() => this.handleCheck(rowData)}/>
					</div>
			}
		];
	}

	handleCheck(row) {
		if (this.state.showWarnings) {
			if (row.isRecommended && row.isAutoPartnered) {
				alert('You can\'t remove a recommended partner when auto partner is enabled. You must first STOP AUTO PARTNER using the Auto Partner tool.');
			}
			else {
				if (confirm('Do you really want ' + row.partnerOrgName + ' and ' + this.state.selectedOrg.name + ' to ' +
							(!row.isRecommended ? 'be recommended partners?' : 'no longer be recommended partners?'))) {
					row.isRecommended = !row.isRecommended;
					this.setRecommendedPartners(row);
				}
			}
		}
		else {
			row.isRecommended = !row.isRecommended;
			this.setRecommendedPartners(row);
		}
	}

	setRecommendedPartners(row) {
		AdminEndpoints.setRecommendedPartners(this.state.selectedOrg.orgId, row.partnerOrg.orgId, row.isRecommended)
		.then(() => {
			if (this.unmounted) return;
			this.setState({loading: true});
		})
		.catch((error) => {
			console.error('Error setting recommended partners', error);
			this.setState({errorMessage: 'Error setting recommended partners: ' + error.message});
		});
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {selectedOrg, orgList, columnData} = this.state;
		let params = this.processParameters();
		AdminEndpoints.fetchRecommendedPartners(selectedOrg.orgId)
		.then((result) => {
			if (this.unmounted) return;
			let recommended = {};
			let autoPartnered = {};
			result.payload.forEach((recommendedPartnerOrg) => {
				recommended[recommendedPartnerOrg.partnerOrgId] = true;
				autoPartnered[recommendedPartnerOrg.partnerOrgId] = recommendedPartnerOrg.autoPartnered;
			});
			let rowData = [];
			orgList.forEach((org) => {
				if (org.orgId !== selectedOrg.orgId) {
					rowData.push({
						partnerOrg: org,
						partnerOrgName: org.name,
						isRecommended: Boolean(recommended[org.orgId]),
						isAutoPartnered: autoPartnered[org.orgId]
					});
				}
			});

			rowData = SortHelper.filterSearchObjectContains(params.search, rowData, SortHelper.keysFromColumns(columnData));
			if (params.sort) rowData = SortHelper.sortByNullLast(rowData, params.sort);

			this.processData(params, {payload: rowData});
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		return (
			<div>
				{this.state.errorMessage &&
				 <Dialog title={'Error Message'} message={this.state.errorMessage} yesAction={() => this.setState({errorMessage: null})}/>}
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: partnerTapWhite}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div style={{padding: 10}}>
							<OrgSelector onOrgSelection={this.handleOrgSelection}/>
						</div>
						<div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}>
							<CheckboxButton checked={this.state.showWarnings} onChange={() => this.setState({showWarnings: !this.state.showWarnings})}/>
							Show Warnings
						</div>
					</div>
				</div>
				{Boolean(this.state.selectedOrg) && super.render()}
			</div>
		);
	}
}

AdminRecommendedPartnerOrgsPage.propTypes = {
	authState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(AdminRecommendedPartnerOrgsPage);
