import {List, ViewModule} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PERSISTENT_KEY} from '../../globals/StorageKeys';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import ToggleButton from './ToggleButton';

class ToggleButtonListView extends Component {

	constructor(props, context) {
		super(props, context);

		this.TOGGLE_STORE_KEY = PERSISTENT_KEY + this.props.storageKey + '_list_view';
		this.toggleList = [
			{toolTip: 'Tile view', MaterialIcon: ViewModule},
			{toolTip: 'List view', MaterialIcon: List}
		];
		let storedViewToggleState = PersistenceHelper.getValue(this.TOGGLE_STORE_KEY);
		if (!storedViewToggleState) storedViewToggleState = 'tile';
		this.state = {renderTiles: storedViewToggleState === 'tile'};
		if (this.props.onToggle) {
			let index = storedViewToggleState === 'tile' ? 0 : 1;
			this.props.onToggle(index, this.toggleList[index]);
		}

		this.onToggle = this.onToggle.bind(this);
	}

	onToggle(index) {
		PersistenceHelper.setValue(this.TOGGLE_STORE_KEY, index === 0 ? 'tile' : 'list');
		this.setState({renderTiles: index === 0});
		if (this.props.onToggle) this.props.onToggle(index);
	}

	render() {
		return (
			<ToggleButton defaultSelection={this.state.renderTiles ? 0 : 1} toggles={this.toggleList} onToggle={this.onToggle}/>
		);
	}
}

ToggleButtonListView.propTypes = {
	storageKey: PropTypes.string.isRequired,
	onToggle: PropTypes.func
};

export default connect()(ToggleButtonListView);
