import * as ReduxEnum from './ReduxEnum';

export function receivedNotification(payload) {
	return {type: ReduxEnum.RECEIVED_NOTIFICATION, payload};
}

export function clearNotification() {
	return {type: ReduxEnum.CLEAR_NOTIFICATION};
}

export function updateRoute(route) {
	return {type: ReduxEnum.UPDATE_ROUTE_FROM_NOTIFICATION, route};
}

export function updatePartnerRequestCount(count) {
	return {type: ReduxEnum.PARTNER_REQUEST_COUNT, count};
}
