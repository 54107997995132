import {HowToReg} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as ProfileEndpoints from '../../../../endpoints/ProfilesEndpoints';
import {SHARE_SUMMARY} from '../../../../helpers/EcosystemShareHelper';
import FeatureHelper, {FEATURE_CHANNEL_SHARE_OPPS, FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS} from '../../../../helpers/FeatureHelper';
import Loading from '../../../../ui/Loading';
import MessageBoxAlert from '../../../../ui/messages/MessageBoxAlert';
import Pic from '../../../../ui/Pic';
import DetailHeader from '../../shared/DetailHeader';

class PartnerConnectionHeader extends Component {

	constructor(props, context) {
		super(props, context);
		this.fetchPartner = this.fetchPartner.bind(this);
		this.state = {loading: false, partnerData: this.props.partnerData};
	}

	componentDidMount() {
		if (!this.props.partnerData) {
			this.fetchPartner(this.props.match.params.partnerId);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchPartner(partnerId) {
		this.setState({loading: true});
		ProfileEndpoints.fetchChannelPartnerProfile(partnerId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({partnerData: result.payload, loading: false});
		});
	}

	render() {
		if (this.state.loading) return <Loading>Loading Channel Partner...</Loading>;
		let {partnerData, shareSettings} = this.props;
		let partnerInfo =
			<div style={{display: 'flex', alignItems: 'center'}}>
				<div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
					<div style={{textAlign: 'right', paddingRight: 5}}>
						<span style={{fontWeight: 'bold'}}>
							{partnerData.firstName + ' ' + partnerData.lastName}
						</span>
						<br/>
						<div style={{fontSize: 12}}>
							{partnerData.ptJobTitle}
						</div>
					</div>
					<Pic picSize={40} personId={partnerData.personId}/>
				</div>
				<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
					<Pic picSize={40} orgId={partnerData.orgId} tmpLogo={partnerData.organization}/>
					<div style={{paddingLeft: 5, textAlign: 'left'}}>
						<span style={{fontWeight: 'bold'}}>
							{partnerData.organization}
						</span>
						<br/>
						<div style={{fontSize: 12}}>
							{partnerData.organizationTagline}
						</div>
					</div>
				</div>
			</div>;

		let shareWarning;
		let shareWarningType;
		let isNotSharingAccounts = shareSettings.accountShareSettings.ownerAssetShareOption === SHARE_SUMMARY;
		let isNotSharingOpps = shareSettings.oppShareSettings.ownerAssetShareOption === SHARE_SUMMARY &&
							   FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS) &&
							   FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS);
		if (isNotSharingAccounts) {
			shareWarningType = 'accounts';
		}
		else if (isNotSharingOpps) {
			shareWarningType = 'opportunities';
		}
		if (shareWarningType) {
			shareWarning =
				<MessageBoxAlert title={'You\'re not sharing ' + shareWarningType + ' with ' + partnerData.firstName}>
					Please adjust your {shareWarningType} scope below.
				</MessageBoxAlert>;
		}
		return <DetailHeader MaterialIcon={HowToReg} title={'Partner Connection Details'} detailCenter={shareWarning} detailRight={partnerInfo}/>;
	}
}

PartnerConnectionHeader.propTypes = {
	partnerData: PropTypes.object.isRequired,
	shareSettings: PropTypes.object.isRequired,
	reportType: PropTypes.string,
	match: PropTypes.object.isRequired
};

export default withRouter(PartnerConnectionHeader);
