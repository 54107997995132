import React from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import * as CoSellEngineHelper from '../CoSellEngineHelper';
import {
	WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_CONNECTED_PARTNER,
	WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_EMAIL_ADDRESS,
	WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_FELLOW_EMPLOYEE,
	WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_NONE
} from '../CoSellEngineHelper';

export class WorkflowShareActionList {

	constructor(actionListInfo, workflow, workflowRecords) {
		this.actionListInfo = actionListInfo;
		this.workflow = workflow;
		this.workflowRecords = workflowRecords;

		this.getCurrentSequence = this.getCurrentSequence.bind(this);
		this.onSelectSequence = this.onSelectSequence.bind(this);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsShareActionList;
	}

	get steps() {
		return [{label: 'Sequence'}, {label: 'Recipients'}, {label: 'Messages'}];
	}

	getCurrentSequence(adminSequenceConfig) {
		return adminSequenceConfig.workflowSequenceShareActionList === this.actionListInfo.workflowSequenceShareActionList;
	}

	onSelectSequence(adminSequenceConfig) {
		this.actionListInfo.workflowSequenceShareActionList = adminSequenceConfig.workflowSequenceShareActionList;
	}

	get isUnavailable() {
		return null;
	}

	configureEmail(emailWorkflowEditor, currentConfigStep) {
		let {workflowConfigEmailShareActionList, workflowSequenceShareActionList} = this.actionListInfo;
		switch (currentConfigStep) {
			case 1: {
				return (
					<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
						{emailWorkflowEditor.renderFromEditor()}

						{workflowSequenceShareActionList === WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_CONNECTED_PARTNER &&
						 emailWorkflowEditor.renderToSelectPartner()}

						{(workflowSequenceShareActionList === WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_FELLOW_EMPLOYEE ||
						  workflowSequenceShareActionList === WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_EMAIL_ADDRESS) &&
						 emailWorkflowEditor.renderToEnterRecipient()}
					</div>
				);
			}
			case 2: {
				return emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailShareActionList, workflowSequenceShareActionList, 1);
			}
		}
	}

	validate(currentWorkflowStep) {
		let {workflowConfigEmailShareActionList, workflowSequenceShareActionList} = this.actionListInfo;
		switch (currentWorkflowStep) {
			case 0:
				return workflowSequenceShareActionList !== WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_NONE;
			case 1:
			case 2:
				return CoSellEngineHelper.validateWorkflowConfigEmail(workflowConfigEmailShareActionList, false, true);
		}
	}

	confirm() {
		let {workflowRecords} = this;
		let {workflowConfigEmailShareActionList} = this.actionListInfo;
		return (
			<div>
				Your Action List of {workflowRecords.length} account{workflowRecords.length === 1 ? '' : 's'} will be sent
				to {workflowConfigEmailShareActionList.partnerEmailColumn}
			</div>
		);
	}
}
