import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapPrimary, partnerTapQuaternary} from '../../styles/partnertap_theme';
import SubNavButton from './SubNavButton';

class SubNav extends Component {

	constructor(props, context) {
		super(props, context);
	}

	render() {
		return (
			<div style={{display: 'flex', background: partnerTapQuaternary, borderBottom: '1px solid ' + partnerTapPrimary, borderTop: 0, paddingTop: 5}}>
				<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', margin: 'auto', gap: 2}}>
					{this.props.subNavData.map((subNav, index) => {
						return <SubNavButton key={index} subNav={subNav}/>;
					})}
				</div>
			</div>
		);
	}
}

SubNav.propTypes = {
	subNavData: PropTypes.array.isRequired
};

export default SubNav;
