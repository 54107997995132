import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Dialog from './Dialog';
import LinkButton from './buttons/LinkButton';

class TermsOfServiceDialog extends Component {

	onUserAgreementClick() {
		window.open('http://www.partnertap.com/user-agreement', '_system');
	}

	render() {
		return (
			<Dialog title={'User Agreement'}
					message={
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							<div>
								The <LinkButton label={'PartnerTap User Agreement'} onClick={this.onUserAgreementClick}/> has been updated.
							</div>
							<div>
								To continue, please review and agree to the terms.
							</div>
							<div>
								Do you agree to the terms?
							</div>
						</div>
					}
					yesAction={this.props.onUserAgreementDialogYes}
					noAction={this.props.onUserAgreementDialogNo}/>
		);
	}
}

TermsOfServiceDialog.propTypes = {
	onUserAgreementDialogYes: PropTypes.func.isRequired,
	onUserAgreementDialogNo: PropTypes.func.isRequired
};

export default TermsOfServiceDialog;
