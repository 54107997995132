import {CheckCircle} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import * as TrackingEndpoints from '../endpoints/TrackingEndpoints';
import {partnerTapDropShadow, partnerTapPrimary, partnerTapTernary, partnerTapWhite} from '../styles/partnertap_theme';
import PrimaryButton from './buttons/PrimaryButton';
import SupportButton from './buttons/SupportButton';
import ScrollingContainer from './lists/ScrollingContainer';

class Upsell extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {upsellComplete: false};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	render() {
		let {showUpsell, feature, headline, callToAction, productBullets, personId, useScrollingContainer, showSupportButton} = this.props;

		if (showUpsell) {
			headline = 'Do you want to see ' + showUpsell + '?';
			feature = showUpsell;
		}

		let upsell =
			<div style={{flex: 1, display: 'flex', flexDirection: 'column', alignSelf: 'center', alignItems: 'center', margin: 10}}>
				<div style={{
					margin: 20,
					padding: 20,
					maxWidth: 600,
					border: '1px solid ' + partnerTapPrimary,
					borderRadius: 10,
					boxShadow: partnerTapDropShadow,
					background: partnerTapWhite
				}}>
					<div style={{textAlign: 'center', fontSize: showSupportButton ? 16 : 24}}>
						{headline}
					</div>
					{callToAction ?
						<div style={{textAlign: 'center', fontSize: 16, padding: 40}}>
							{callToAction}
						</div>
						:
						<div style={{padding: 10}}/>}
					{productBullets &&
					 <div style={{paddingBottom: 40}}>
						 <div style={{textAlign: 'center', fontSize: 24, color: partnerTapPrimary}}>
							 {feature}
						 </div>
						 <div style={{textAlign: 'center', padding: 10}}>
							 Everything you have, <em>plus</em>:
						 </div>
						 <div style={{textAlign: 'center'}}>
							 <div style={{display: 'inline-block', borderRadius: 10}}>
								 {productBullets.map((bullet) => {
									 return (
										 <div key={bullet} style={{padding: 5, display: 'flex', alignItems: 'center'}}>
											 <CheckCircle style={{color: partnerTapTernary}}/>
											 <div style={{paddingLeft: 5}}>{bullet}</div>
										 </div>
									 );
								 })}
							 </div>
						 </div>
					 </div>}
					<div style={{fontSize: 24, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						{this.state.upsellComplete ?
							<div style={{fontSize: 16, fontWeight: 'bold'}}>
								Thank you! We will be in touch soon.
							</div>
							:
							showSupportButton ?
								<SupportButton/>
								:
								<Fragment>
									<div style={{fontSize: 16, paddingBottom: 10}}>
										Contact us if you’d like to upgrade.
									</div>
									<PrimaryButton label={'Contact Sales'}
												   onClick={() => {
													   TrackingEndpoints.trackLead(feature, personId)
													   .then(() => {
														   if (this.unmounted) return;
														   this.setState({upsellComplete: true});
													   });
												   }}/>
								</Fragment>}
					</div>
				</div>
			</div>;

		if (useScrollingContainer) {
			return (
				<ScrollingContainer divId={'upsell'}>
					<div style={{overflow: 'scroll'}}>
						{upsell}
					</div>
				</ScrollingContainer>
			);
		}
		return upsell;
	}
}

Upsell.propTypes = {
	showUpsell: PropTypes.string,
	personId: PropTypes.string,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	callToAction: PropTypes.element,
	feature: PropTypes.string,
	productBullets: PropTypes.arrayOf(PropTypes.string),
	useScrollingContainer: PropTypes.bool,
	showSupportButton: PropTypes.bool
};

export default Upsell;
