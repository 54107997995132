import {Cancel, Check, Edit} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Loading from '../../../ui/Loading';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import PopoverSearchList from '../../../ui/PopoverSearchList';
import TextInputBox from '../../../ui/TextInputBox';

export default class CoSellActionListInfo extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {editingActionListInfo: null, savingActionListInfo: false};

		this.saveChanges = this.saveChanges.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	saveChanges() {
		let {actionListInfo} = this.props;
		let {editingActionListInfo} = this.state;
		if (editingActionListInfo) {
			if (editingActionListInfo.actionListName.length > 3) {
				actionListInfo.actionListName = editingActionListInfo.actionListName;
			}
			actionListInfo.actionListDescription = editingActionListInfo.actionListDescription;
			actionListInfo.isActive = editingActionListInfo.isActive;
			actionListInfo.expirationDate = editingActionListInfo.expirationDate;
		}

		this.setState({savingActionListInfo: true, editingActionListInfo: null});
		CoSellEngineEndpoints.updateCoSellActionList(actionListInfo)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({savingActionListInfo: false});
		})
		.catch((error) => {
			this.setState({savingActionListInfo: false});
			EnvHelper.serverError('Error from saveChanges', error);
		});
	}

	get sectionStyle() {
		return {display: 'flex', alignItems: 'top', padding: 10, borderRadius: 5, backgroundColor: partnerTapWhite};
	}

	renderInfo() {
		let {actionListInfo} = this.props;
		if (!actionListInfo) return;
		let {editingActionListInfo, savingActionListInfo} = this.state;
		return (
			<div style={this.sectionStyle}>
				{savingActionListInfo &&
				 <ScrimMessage message={'Saving Changes...'}/>}
				<table style={{width: '100%'}} cellSpacing={10}>
					<tbody>
					<tr>
						<td style={{fontWeight: 'bold'}}>Action List Name:</td>
						<td>
							{editingActionListInfo ?
								<TextInputBox value={editingActionListInfo.actionListName}
											  onChange={(value) => {
												  editingActionListInfo.actionListName = value;
												  this.forceUpdate();
											  }}
											  doNotAutoFocus={true}
											  isSimple={true}
											  maxChars={255}/>
								: actionListInfo.actionListName}
						</td>
						<td style={{fontWeight: 'bold'}}>Active:</td>
						<td>
							{editingActionListInfo ?
								<PopoverSearchList list={['Yes', 'No']}
												   preselectedItem={editingActionListInfo.isActive ? 'Yes' : 'No'}
												   maxWidth={220}
												   onItemSelected={(value) => editingActionListInfo.isActive = value === 'Yes'}/>
								: actionListInfo.isActive ? 'Yes' : 'No'}
						</td>
					</tr>
					<tr>
						<td style={{fontWeight: 'bold'}}>Description:</td>
						<td>
							{editingActionListInfo ?
								<TextInputBox value={editingActionListInfo.actionListDescription}
											  onChange={(value) => {
												  editingActionListInfo.actionListDescription = value;
												  this.forceUpdate();
											  }}
											  doNotAutoFocus={true}
											  isSimple={true}
											  maxChars={255}/>
								: (actionListInfo.actionListDescription || '-')}
						</td>
						<td style={{fontWeight: 'bold'}}>Expiration Date:</td>
						<td>
							{editingActionListInfo ?
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker label={'Date'}
												value={moment.unix(new Date(actionListInfo.expirationDate)).toDate()}
												minDate={moment().add(30, 'days').toDate()}
												maxDate={moment().add(1, 'years').toDate()}
												format={'MM/dd/yyyy'}
												onChange={(value, context) => {
													if (value && !context.validationError) {
														editingActionListInfo.expirationDate = value.valueOf() / 1000;
													}
												}}/>
								</LocalizationProvider>
								: DateHelper.epochToDate(actionListInfo.expirationDate)}
						</td>
					</tr>
					</tbody>
				</table>
				<div>
					{editingActionListInfo ?
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							<PrimaryButton label={'SAVE'} icon={<Check/>} fullWidth={true} onClick={this.saveChanges}/>
							<SecondaryButton label={'CANCEL'} icon={<Cancel/>} fullWidth={true} onClick={() => this.setState({editingActionListInfo: null})}/>
						</div>
						:
						<SecondaryButton label={'EDIT'}
										 icon={<Edit/>}
										 onClick={() => this.setState({editingActionListInfo: _.clone(actionListInfo)})}/>}
				</div>
			</div>
		);
	}

	render() {
		let {actionListInfo} = this.props;
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: 10}}>
				{!actionListInfo ?
					<Loading>Loading Action List Info...</Loading> :
					this.renderInfo()}
			</div>
		);
	}
}

CoSellActionListInfo.propTypes = {
	actionListInfo: PropTypes.object
};
