import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import ChannelHelper, {
	CHANNEL_REPORT_CUSTOMER_CUSTOMER,
	CHANNEL_REPORT_CUSTOMER_PROSPECT,
	CHANNEL_REPORT_PROSPECT_CUSTOMER,
	TITLE_ACCOUNTS_BY_COMPANY_SIZE,
	TITLE_ACCOUNTS_BY_COUNTRY,
	TITLE_ACCOUNTS_BY_INDUSTRY,
	TITLE_ACCOUNTS_MUTUAL_OVERLAP
} from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import ReportHelper, {ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_ACCOUNTS, MATCHING_TYPE_MUTUAL} from '../../../../helpers/ReportHelper';
import CircleGraph from '../../../../ui/graphs/CircleGraph';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import DashboardTopItem from '../../shared/DashboardTopItem';

class PartnerSheetDashboard extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true};

		this.fetchDashboard = this.fetchDashboard.bind(this);
		this.onCircleGraphClick = this.onCircleGraphClick.bind(this);
	}

	componentDidMount() {
		this.fetchDashboard();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get personQueueId() {
		return this.props.match.params.personQueueId;
	}

	fetchDashboard() {
		ChannelMappingEndpoints.getDashboard({
			personQueueIds: [this.personQueueId],
			channelReportType: CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_ACCOUNTS
		})
		.then((result) => {
			if (this.unmounted) return;
			let accountCountByIndustry = result.payload.countByIndustry.counts || [];
			let accountCountByCountry = result.payload.countByCountry.counts || [];
			let {
				matchedAccountCount,
				clientOverlapCount,
				prospectOverlapCount,
				hotProspectCount,
				hotClientCount,
				startupCount,
				smbCount,
				midMarketCount,
				globalCount
			} = result.payload.overlapCounts;
			this.setState({
				loading: false,
				dashboardProcessing: false,
				matchedAccountCount: matchedAccountCount || 0,
				clientOverlapCount: clientOverlapCount || 0,
				prospectOverlapCount: prospectOverlapCount || 0,
				hotProspectCount: hotProspectCount || 0,
				hotClientCount: hotClientCount || 0,
				companySizeChart: ChannelHelper.getCompanySizeData(ASSET_TYPE_ACCOUNTS, startupCount, smbCount, midMarketCount, globalCount),
				clientOverlapChart: ChannelHelper.getClientOverlapData(ASSET_TYPE_ACCOUNTS, clientOverlapCount, prospectOverlapCount),
				accountByIndustryCounts: ChannelHelper.getCountByColumnData(ASSET_TYPE_ACCOUNTS, accountCountByIndustry, 'industry', TITLE_ACCOUNTS_BY_INDUSTRY),
				accountByCountryCounts: ChannelHelper.getCountByColumnData(ASSET_TYPE_ACCOUNTS, accountCountByCountry, 'country', TITLE_ACCOUNTS_BY_COUNTRY)
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getSpreadsheetDashboard', error);
		});
	}

	getTopItemRoute(filterType) {
		let reportTitle = ReportHelper.getReportTitle(ASSET_TYPE_ACCOUNTS, MATCHING_TYPE_MUTUAL, filterType);
		return Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.PATH(this.personQueueId, Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_ACCOUNTS.PAGE_NAME, encodeURIComponent(reportTitle));
	}

	onCircleGraphClick(payload) {
		let path = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.PATH(this.personQueueId, Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.MAPPED_ACCOUNTS.PAGE_NAME, encodeURIComponent(payload.reportTitle));
		let params = '?filter=' + encodeURIComponent(JSON.stringify(payload.filterValue)) + '&filterTitle=' + encodeURIComponent(payload.filterTitle);
		DialogHelper.openDetailDialog('Dashboard Detail', path, params, this.setState.bind(this));
	}

	render() {
		if (this.state.loading) return <Loading>Loading Dashboard...</Loading>;
		let {
			clientOverlapCount,
			hotProspectCount,
			hotClientCount,
			companySizeChart,
			clientOverlapChart,
			accountByIndustryCounts,
			topIndustriesCount,
			accountByCountryCounts,
			topCountriesCount
		} = this.state;
		return (
			<ScrollingContainer key={topIndustriesCount + '_' + topCountriesCount} divId={'spreadsheet_dashboard'}>
				{this.state.detailDialog}
				<div style={{margin: 10, marginLeft: 5, marginRight: 5, overflow: 'scroll'}}>
					<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'center'}}>
						<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}
										  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_CUSTOMER)}
										  message={'Customers I have in common with my partner'}
										  value={clientOverlapCount}
										  destination={this.getTopItemRoute(CHANNEL_REPORT_CUSTOMER_CUSTOMER)}/>
						<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_CUSTOMER)}
										  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_PROSPECT_CUSTOMER)}
										  message={'My prospects that are partner customers'}
										  value={hotProspectCount}
										  destination={this.getTopItemRoute(CHANNEL_REPORT_PROSPECT_CUSTOMER)}/>
						<DashboardTopItem MaterialIcon={ReportHelper.getReportFilterIcon(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_PROSPECT)}
										  title={ReportHelper.getReportFilterTitle(ASSET_TYPE_ACCOUNTS, CHANNEL_REPORT_CUSTOMER_PROSPECT)}
										  message={'My customers that are partner prospects'}
										  value={hotClientCount}
										  destination={this.getTopItemRoute(CHANNEL_REPORT_CUSTOMER_PROSPECT)}/>
					</div>
					<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
						<CircleGraph title={TITLE_ACCOUNTS_BY_INDUSTRY}
									 sections={accountByIndustryCounts}
									 onClick={this.onCircleGraphClick}/>
						<CircleGraph title={TITLE_ACCOUNTS_BY_COMPANY_SIZE}
									 sections={companySizeChart}
									 onClick={this.onCircleGraphClick}/>
						<CircleGraph title={TITLE_ACCOUNTS_MUTUAL_OVERLAP}
									 sections={clientOverlapChart}
									 onClick={this.onCircleGraphClick}/>
						<CircleGraph title={TITLE_ACCOUNTS_BY_COUNTRY}
									 sections={accountByCountryCounts}
									 onClick={this.onCircleGraphClick}/>
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

export default withRouter(connect()(PartnerSheetDashboard));
