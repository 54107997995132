import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import TableHelper from '../../../../helpers/TableHelper';
import {partnerTapInactive, partnerTapSecondary} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';

class GlobalReportTileRenderer extends Component {

	get color() {
		return this.props.rowData.isDisabled ? partnerTapInactive : partnerTapSecondary;
	}

	get tileStyle() {
		return TableHelper.tileStyle(this.width, this.height, this.color, 10, this.props.rowData.isDisabled ? 'default' : 'pointer');
	}

	render() {
		let {reportType, description, onViewReport, isDisabled} = this.props.rowData;

		let MaterialIcon = ChannelHelper.getReportTypeIcon(reportType);

		return (
			<div style={TableHelper.tileStyle(250, 250, this.color, 10, this.props.rowData.isDisabled ? 'default' : 'pointer')}
				 onClick={isDisabled ? null : () => onViewReport(reportType)}>
				<div style={{fontSize: 20, fontWeight: 'bold'}}>
					{ChannelHelper.getReportTypeTitle(reportType)}
				</div>
				<MaterialIcon style={{fontSize: 60, color: this.color}}/>
				{description}
				{isDisabled ?
					<div style={{fontSize: 14, fontWeight: 'bold'}}>
						Ask your admin for this report
					</div>
					:
					<PrimaryButton label={'VIEW'} onClick={() => onViewReport(reportType)}/>}
			</div>
		);
	}
}

GlobalReportTileRenderer.propTypes = {
	rowData: PropTypes.shape({
		reportType: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		onViewReport: PropTypes.func,
		isDisabled: PropTypes.bool
	}).isRequired
};

export default GlobalReportTileRenderer;
