import {Launch} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapSecondary, partnerTapWhite} from '../../../styles/partnertap_theme';
import ToolTipOverlay from '../../../ui/ToolTipOverlay';
import AuthHelper from '../../../helpers/AuthHelper';

class OpportunityDetailHeader extends Component {

	render() {
		let {city, state, accountType, mainAccountOwner, orgName} = this.props.details;
		let {opportunityName, stageName, opportunityStatus, opportunityOwnerName, crmOpportunityId, crmOpportunityBaseUri} = this.props.oppDetails;
		return (
			<div style={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				justifyContent: 'space-between',
				boxShadow: partnerTapDropShadow,
				backgroundColor: partnerTapWhite,
				padding: 10,
				margin: 10
			}}>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					{crmOpportunityBaseUri && crmOpportunityId ?
						<div style={{fontSize: 24, fontWeight: 'bold', paddingBottom: 5}}>
							<ToolTipOverlay title={'Open in ' + DataManagerHelper.getCrmName(AuthHelper.userPerson.crmType)}>
								<a href={crmOpportunityBaseUri + crmOpportunityId}
								   style={{color: partnerTapSecondary, cursor: 'pointer', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
								   target={'_blank'}>
									{opportunityName}
									<Launch style={{position: 'relative', top: 5, left: 5}}/>
								</a>
							</ToolTipOverlay>
						</div>
						:
						<div style={{fontSize: 24, fontWeight: 'bold'}}>
							{opportunityName}
						</div>}
					<div style={{fontSize: 16, color: partnerTapInactive, paddingBottom: 10}}>
						{city}, {state}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap', fontSize: 14, paddingBottom: 10}}>
						<div style={{fontWeight: 'bold', width: 80}}>Opp Status:</div>
						{opportunityStatus}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap', fontSize: 14}}>
						<div style={{fontWeight: 'bold', width: 80}}>Opp Stage:</div>
						{stageName}
					</div>
				</div>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div style={{display: 'flex', whiteSpace: 'nowrap', paddingBottom: 10}}>
						<div style={{fontWeight: 'bold', width: 140}}>Company:</div>
						{orgName}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap', paddingBottom: 10}}>
						<div style={{fontWeight: 'bold', width: 140}}>Account Type:</div>
						{accountType}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap', paddingBottom: 10}}>
						<div style={{fontWeight: 'bold', width: 140}}>Account Owner:</div>
						{mainAccountOwner}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap'}}>
						<div style={{fontWeight: 'bold', width: 140}}>Opportunity Owner:</div>
						{opportunityOwnerName}
					</div>
				</div>
			</div>
		);
	}
}

OpportunityDetailHeader.propTypes = {
	details: PropTypes.object.isRequired,
	oppDetails: PropTypes.object.isRequired
};

export default OpportunityDetailHeader;
