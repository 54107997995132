import {AccountTree, Air} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Routes} from '../../../globals/Routes';
import SubNav from '../../../ui/navigation/SubNav';
import CoSellActionListPageLive from './CoSellActionListPageLive';
import CoSellActionListResultsPageLive from './CoSellActionListResultsPageLive';
import CoSellActionListEdit from './CoSellActionListEdit';
import CoSellWorkflowConfigLive from './CoSellWorkflowConfigLive';

class CoSellActionListRouterLive extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, actionListInfo: null};
	}

	get actionListId() {
		return this.props.match.params.actionListId;
	}

	get subNavData() {
		let route = Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE;
		return [
			{
				label: 'Action List',
				MaterialIcon: Air,
				route: route.ACTION_LIST.PATH(this.actionListId),
				activeRoutes: [route.ACTION_LIST.ROUTE]
			},
			{
				label: 'Reports',
				MaterialIcon: AccountTree,
				route: route.ACTION_LIST_RESULTS.PATH(this.actionListId)
			}
		];
	}

	render() {
		console.log(this.props);
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				{!this.props.location.pathname.includes("edit") && <SubNav subNavData={this.subNavData}/>}
				<Switch>
					<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_EDIT_LIVE.ROUTE} component={CoSellActionListEdit}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_LIVE.ROUTE} component={CoSellActionListPageLive}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_RESULTS_LIVE.ROUTE} component={CoSellActionListResultsPageLive}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.WORKFLOW_WIZARD.ROUTE} component={CoSellWorkflowConfigLive}/>
				</Switch>
			</div>
		);
	}
}

CoSellActionListRouterLive.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(CoSellActionListRouterLive);
