import objectAssign from 'object-assign';
import * as ReduxEnum from './ReduxEnum';

export default function authState(state = {
	welcomeMode: false,
	person: null,
	profile: null,
	channelWizardSteps: null,
	errorOrigin: '',
	errorMessage: '',
	httpCode: 0,
	newVersionAvailable: false,
	channelSubordinates: [],
	appRefreshKey: 0
}, action) {
	switch (action.type) {
		case ReduxEnum.LOGIN_PERSON: {
			let newState = objectAssign({}, state);
			newState.person = action.person;
			return newState;
		}
		case ReduxEnum.LOGIN_PROFILE: {
			let newState = objectAssign({}, state);
			newState.profile = action.profile;
			return newState;
		}
		case ReduxEnum.LOGOUT: {
			let newState = objectAssign({}, state);
			newState.person = null;
			newState.profile = null;
			newState.channelWizardSteps = null;
			return newState;
		}
		case ReduxEnum.CHANNEL_WIZARD_STEPS: {
			let newState = objectAssign({}, state);
			newState.channelWizardSteps = action.channelWizardSteps;
			return newState;
		}
		case ReduxEnum.AGREE_TO_TERMS: {
			let newState = objectAssign({}, state);
			newState.profile = objectAssign({}, newState.profile);
			newState.profile.tosComplete = true;
			return newState;
		}
		case ReduxEnum.CLEAR_FIRST_LOGIN_MESSAGE: {
			let newState = objectAssign({}, state);
			newState.person = action.person;
			return newState;
		}
		case ReduxEnum.WELCOME_MODE: {
			let newState = objectAssign({}, state);
			newState.welcomeMode = action.welcomeMode;
			return newState;
		}
		case ReduxEnum.NEW_VERSION_AVAILABLE: {
			let newState = objectAssign({}, state);
			newState.newVersionAvailable = action.status;
			return newState;
		}
		case ReduxEnum.SET_CHANNEL_SUBORDINATES: {
			let newState = objectAssign({}, state);
			newState.channelSubordinates = action.channelSubordinates;
			return newState;
		}
		case ReduxEnum.SERVER_ERROR: {
			let newState = objectAssign({}, state);
			newState.errorOrigin = action.errorOrigin;
			newState.errorMessage = action.errorMessage;
			newState.httpCode = action.httpCode;
			return newState;
		}
		case ReduxEnum.CLEAR_SERVER_ERROR: {
			let newState = objectAssign({}, state);
			newState.errorOrigin = '';
			newState.errorMessage = '';
			newState.httpCode = 0;
			return newState;
		}
		case ReduxEnum.REFRESH_APP: {
			let newState = objectAssign({}, state);
			newState.appRefreshKey++;
			return newState;
		}
		default: {
			return state;
		}
	}
}
