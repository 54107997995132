import {List} from '@mui/icons-material';
import {connect} from 'react-redux';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {COLUMN_KEY_ACCOUNT_NAME, COLUMN_KEY_OPPORTUNITY_NAME} from '../../../globals/Enums';
import {CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES} from '../../../helpers/ReportHelper';
import PagingBase from '../../../ui/lists/PagingBase';

export class OpportunityListPage extends PagingBase {

	get storageKeyBase() {
		return 'ecosystem_opportunity_list';
	}

	get title() {
		return 'Opp Lists';
	}

	get icon() {
		return List;
	}

	get columnData() {
		return [
			{title: 'Account Name', key: COLUMN_KEY_ACCOUNT_NAME},
			{title: 'Opportunity Name', key: COLUMN_KEY_OPPORTUNITY_NAME},
			{title: 'Stage Name', key: 'stageName'},
			{title: 'Status', key: 'ptStatus'},
			{title: 'Owner Name', key: 'opportunityOwnerName'}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {rowCount} = this.state;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{channelReportType: CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			rowCount)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}
}

export default connect()(OpportunityListPage);
