import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import {WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL, workflows} from '../CoSellEngineHelper';
import {AdminConfigBase} from './AdminConfigBase';

export class AdminDistributeRecordsInternal extends AdminConfigBase {

	get workflow() {
		return workflows.find((workflow) => workflow.config === WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsDistributeRecordsInternal;
	}

	get updateWorkflowConfigEndpoint() {
		return CoSellEngineEndpoints.updateCoSellAdminConfigDistributeRecordsInternal;
	}

	get renderAdditionalEditorFields() {
		let {editingAdminSequenceConfig} = this.state;
		let {workflowSequenceDistributeRecordsInternal} = editingAdminSequenceConfig;
		let sequenceOrdinal = 1;
		return [this.renderEmailTemplateEditor(WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL, workflowSequenceDistributeRecordsInternal, sequenceOrdinal)];
	}
}
