import * as ApiHelper from '../helpers/ApiHelper';
import EnvHelper from '../helpers/EnvHelper';
import SortHelper from '../helpers/SortHelper';

export function fetchProfile(personId) {
	let endPoint = '/profiles?personId=' + personId;
	if (EnvHelper.inChannelEcosystemProduct) {
		endPoint += '&isChannel=true';
	}
	return ApiHelper.sendGet(endPoint);
}

export function fetchChannelPartnerProfile(partnerId) {
	return ApiHelper.sendGet('/profiles?partnerId=' + partnerId + '&isChannel=true');
}

export function fetchProfileData() {
	let endPoint = '/profiles/data';
	if (EnvHelper.inChannelEcosystemProduct) {
		endPoint += '&isChannel=true';
	}
	return ApiHelper.sendGet(endPoint);
}

export function searchProfiles(page, search, sort, minMatchedAccounts) {
	if (!page) page = 0;
	if (!search) search = '';
	if (!sort) sort = SortHelper.sortPartnersByName;
	if (!minMatchedAccounts) minMatchedAccounts = 0;
	let endpoint = '/profiles/page?page=' + page + '&search=' + search + '&sort=' + sort + '&minMatchedAccounts=' + minMatchedAccounts;
	return ApiHelper.sendGet(endpoint);
}

export function updateProfileTosComplete(profileId) {
	return ApiHelper.sendPut('/profiles/' + profileId, {tosComplete: true});
}

export function updateClientPersistenceJson(profile, json) {
	let payload = {
		clientPersistenceJson: json,
		tosComplete: profile.tosComplete,
		userCustomized: profile.userCustomized,
		hasBanner: profile.hasBanner
	};
	return ApiHelper.sendPut('/profiles/' + profile.id, payload);
}

export function updateProfileInfo(profile) {
	let payload = {
		ptJobTitle: profile.ptJobTitle,
		territory: profile.territory,
		emailAddress: profile.emailAddress,
		cellPhoneNumber: profile.cellPhoneNumber,

		organizationTagline: profile.organizationTagline,
		division: profile.division,
		street: profile.street,
		city: profile.city,
		state: profile.state,
		zipCode: profile.zipCode,
		phone: profile.phone,

		hasBanner: profile.hasBanner,
		userCustomized: true,
		tosComplete: profile.tosComplete
	};
	return ApiHelper.sendPut('/profiles/' + profile.id, payload);
}

export function updateProfileSettings(profile, settings, emailKey) {
	let payload = {
		hasBanner: profile.hasBanner,
		tosComplete: profile.tosComplete,
		settings: settings,
		emailKey: emailKey
	};
	return ApiHelper.sendPut('/profiles/' + profile.id, payload);
}

export function updateProfileImage(updateType, base64Data) {
	return ApiHelper.sendPut('/profiles/image?updateType=' + updateType, base64Data);
}

export function getWizardData() {
	return ApiHelper.sendGetReport('/channelwizard/gettingstarted');
}
