import PropTypes from 'prop-types';
import Dialog from '../Dialog';
import * as ChannelMappingEndpoints from '../../endpoints/ChannelMappingEndpoints';
import React, {Component} from 'react';

class SheetDeleteDialog extends Component {
	
	componentWillUnmount() {
		this.unmounted = true;
	}

	render() {
		const {rowData, onClose} = this.props;
		return (<Dialog title={'Please Confirm'}
						message={
							<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
								<div style={{fontSize: 16}}>
									Are you sure you want to delete '{rowData.batchName}'?
								</div>
								<div>
									If you want to restore these accounts, you can upload your sheet again.
								</div>
							</div>}
						yesAction={() => {
							ChannelMappingEndpoints.archiveUpload(rowData.id)
							.then(() => {
								if (this.unmounted) return;
								onClose(true);
							});
						}}
						noAction={() => onClose(false)}/>);
	}
}

SheetDeleteDialog.propTypes = {
	rowData: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired
};

export default SheetDeleteDialog;
