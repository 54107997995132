import {ContactMail, Email, Preview} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import * as ProfileEndpoints from '../../../../endpoints/ProfilesEndpoints';
import AuthHelper from '../../../../helpers/AuthHelper';
import TableHelper from '../../../../helpers/TableHelper';
import {partnerTapInactive, partnerTapTernary} from '../../../../styles/partnertap_theme';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import DropdownMenu from '../../../../ui/selectors/DropdownMenu';
import PartnerSelector from '../../../../ui/selectors/PartnerSelector';
import TextInputBox from '../../../../ui/TextInputBox';
import * as CoSellEngineHelper from '../CoSellEngineHelper';
import {
	WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL,
	WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL,
	WORKFLOW_INTRO_EMAIL,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN,
	WORKFLOW_SHARE_ACTION_LIST
} from '../CoSellEngineHelper';

class EmailWorkflowEditor extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showPreviewDialog: false, partnerProfile: null};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get personTileStyle() {
		let style = TableHelper.tileStyle(320, 260, partnerTapTernary);
		return Object.assign(style, {gap: 10, justifyContent: 'top', textAlign: 'left'});
	}

	get emailTileStyle() {
		let style = TableHelper.tileStyle(480, 500, partnerTapTernary);
		return Object.assign(style, {gap: 10});
	}

	get workflowConfig() {
		let {actionListInfo, workflow} = this.props;
		if (workflow === WORKFLOW_SHARE_ACTION_LIST) {
			return actionListInfo.workflowConfigEmailShareActionList;
		}
		if (workflow === WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL) {
			return actionListInfo.workflowConfigEmailDistributeRecordsInternal;
		}
		if (workflow === WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL) {
			return actionListInfo.workflowConfigEmailDistributeRecordsExternal;
		}
		if (workflow === WORKFLOW_INTRO_EMAIL) {
			return actionListInfo.workflowConfigEmailIntroEmail;
		}
		return null;
	}

	renderFromEditor() {
		let fromEmail = {value: AuthHelper.userProfile.emailAddress, configField: 'authorEmail'};
		let fromName = {value: AuthHelper.userProfile.firstName + ' ' + AuthHelper.userProfile.lastName, configField: 'authorName'};
		let fromTitle = {value: AuthHelper.userProfile.ptJobTitle, configField: 'authorTitle'};
		return this.renderPersonEditorTile('Author', 'From', fromEmail, fromName, fromTitle, false);
	}

	renderToOwnerEditor() {
		let ownerEmailOptions = {columns: [{column: 'OwnerId.email', label: 'Owner Id Email'}], configField: 'ownerEmailColumn'};
		let ownerNameOptions = {columns: [{column: 'OwnerId.name', label: 'Owner Id Name'}], configField: 'ownerNameColumn'};
		let ownerTitleOptions = {columns: [{column: 'OwnerId.title', label: 'Owner Id Title'}], configField: 'ownerTitleColumn'};
		return this.renderPersonEditorTile('Sales Rep Recipient', 'To', ownerEmailOptions, ownerNameOptions, ownerTitleOptions);
	}

	renderToPartnerEditor() {
		let partnerEmailOptions = {columns: [{column: 'partnerowneridemail', label: 'Partner Owner Id Email'}], configField: 'partnerEmailColumn'};
		let partnerNameOptions = {columns: [{column: 'partnerowneridname', label: 'Partner Owner Id Email'}], configField: 'partnerNameColumn'};
		let partnerTitleOptions = {columns: [{column: 'partnerowneridtitle', label: 'Owner Partner Owner Id Email'}], configField: 'partnerTitleColumn'};
		return this.renderPersonEditorTile('Partner Recipient', 'To', partnerEmailOptions, partnerNameOptions, partnerTitleOptions);
	}

	renderToSelectPartner() {
		let {partnerProfile} = this.state;
		if (partnerProfile) {
			let partnerEmail = {value: partnerProfile.emailAddress, configField: 'partnerEmailColumn'};
			let partnerName = {value: partnerProfile.partnerName, configField: 'partnerNameColumn'};
			let partnerTitle = {value: partnerProfile.ptJobTitle, configField: 'partnerTitleColumn'};
			return this.renderPersonEditorTile('Partner Recipient', 'To', partnerEmail, partnerName, partnerTitle, false);
		}

		let partnerSelector =
			<PartnerSelector onPartnerSelected={(partner) => {
				ProfileEndpoints.fetchProfile(partner.personId)
				.then((result) => {
					if (this.unmounted) return;
					this.setState({partnerProfile: result.payload});
					this.props.onChange();
				});
			}}/>;
		return this.renderPersonEditorTile('Partner Recipient', 'To', null, null, null, true, partnerSelector);
	}

	renderToEnterRecipient() {
		let partnerInputFields =
			<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
				<TextInputBox hintText={'Email'}
							  isSimple={true}
							  onChange={(value) => {
								  this.workflowConfig.partnerEmailColumn = value;
								  this.props.onChange();
							  }}/>
				<TextInputBox hintText={'Name'}
							  isSimple={true}
							  onChange={(value) => {
								  this.workflowConfig.partnerNameColumn = value;
								  this.props.onChange();
							  }}/>
				<TextInputBox hintText={'Title'}
							  isSimple={true}
							  onChange={(value) => {
								  this.workflowConfig.partnerTitleColumn = value;
								  this.props.onChange();
							  }}/>
			</div>;
		return this.renderPersonEditorTile('Partner Recipient', 'To', null, null, null, true, partnerInputFields);
	}

	renderPersonEditorTile(title, toOrFrom, emailData, nameData, titleData, editable = true, customEditor = null) {
		let {actionListInfo, workflow} = this.props;
		let workflowConfig;
		if (workflow === WORKFLOW_SHARE_ACTION_LIST) {
			workflowConfig = actionListInfo.workflowConfigEmailShareActionList;
		}
		else if (workflow === WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL) {
			workflowConfig = actionListInfo.workflowConfigEmailDistributeRecordsInternal;
		}
		else if (workflow === WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL) {
			workflowConfig = actionListInfo.workflowConfigEmailDistributeRecordsExternal;
		}
		else if (workflow === WORKFLOW_INTRO_EMAIL) {
			workflowConfig = actionListInfo.workflowConfigEmailIntroEmail;
		}
		if (!editable && workflowConfig) {
			workflowConfig[emailData.configField] = emailData.value;
			workflowConfig[nameData.configField] = nameData.value;
			workflowConfig[titleData.configField] = titleData.value;
		}

		return (
			<div style={this.personTileStyle}>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, paddingBottom: 5}}>
					<ContactMail style={{color: partnerTapTernary}} fontSize={'large'}/>
					<div style={{fontSize: 20, fontWeight: 'bold'}}>
						{title}
					</div>
				</div>
				<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', textAlign: 'left', gap: 5, paddingBottom: 10}}>
					<div style={{fontWeight: 'bold', paddingTop: 10, minWidth: 50}}>
						{toOrFrom}:
					</div>
					<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'top', alignItems: 'left', textAlign: 'left'}}>
						{customEditor ?
							customEditor :
							editable ?
								<Fragment>
									{this.renderPersonEditorDropdown('Email', emailData, workflowConfig)}
									{this.renderPersonEditorDropdown('Name', nameData, workflowConfig)}
									{this.renderPersonEditorDropdown('Title', titleData, workflowConfig)}
								</Fragment>
								:
								<Fragment>
									<div style={{padding: 10}}>
										{emailData.value}
									</div>
									<div style={{padding: 10}}>
										{nameData.value}
									</div>
									<div style={{padding: 10}}>
										{titleData.value}
									</div>
								</Fragment>}
					</div>
				</div>
			</div>
		);
	}

	renderPersonEditorDropdown(title, options, workflowSequence) {
		let {onChange} = this.props;
		return (
			<DropdownMenu title={title}
						  options={options.columns}
						  selectedOption={options.columns.find((option) => option.column === workflowSequence[options.configField])}
						  onSelect={(option) => {
							  workflowSequence[options.configField] = option.column;
							  onChange();
						  }}
						  width={200}/>
		);
	}

	renderEmailPreviewTile(workflowConfig, sequence, sequenceOrdinal) {
		let {workflow} = this.props;
		let emailContent = CoSellEngineHelper.workflowEmailContent[workflow][sequence]['email_' + sequenceOrdinal];
		return (
			<div style={this.emailTileStyle}>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, paddingBottom: 5}}>
						<Email style={{color: partnerTapTernary}} fontSize={'large'}/>
						<div style={{fontSize: 20, fontWeight: 'bold'}}>
							{emailContent.title}
						</div>
					</div>
					{emailContent.sendOrder &&
					 <div style={{fontSize: 16, fontWeight: 'bold'}}>
						 {emailContent.sendOrder}
					 </div>}
					<div style={{color: partnerTapInactive}}>
						{emailContent.sendPrerequisite}
					</div>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', gap: 10, paddingLeft: 10, paddingRight: 10, textAlign: 'left'}}>
					<hr style={{height: 1, margin: 0}}/>
					<div style={{display: 'flex', alignItems: 'top'}}>
						<div style={{fontWeight: 'bold', minWidth: 60}}>
							To:
						</div>
						<div>
							{workflow === WORKFLOW_SHARE_ACTION_LIST ? workflowConfig.partnerEmailColumn : emailContent.to}
						</div>
					</div>
					<div style={{display: 'flex', alignItems: 'top'}}>
						<div style={{fontWeight: 'bold', minWidth: 60}}>
							From:
						</div>
						<div>
							{workflowConfig.authorEmail ? workflowConfig.authorEmail : '[Author Email]'}
						</div>
					</div>
					<div style={{display: 'flex', alignItems: 'top'}}>
						<div style={{fontWeight: 'bold', minWidth: 60}}>
							Subject:
						</div>
						<div>
							{emailContent.subject}
						</div>
					</div>
					<hr style={{height: 1, margin: 0}}/>
					<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
						<div>
							{emailContent.body_1}
						</div>
						{emailContent.customMessageEnabled &&
						 this.renderCustomMessage(workflowConfig, sequence, sequenceOrdinal)}
						<div>
							{emailContent.body_2}
						</div>
						<div>
							- {workflowConfig.authorName ? workflowConfig.authorName : '[Author Name]'}
						</div>
					</div>
					<hr style={{height: 1, margin: 0}}/>
				</div>
				<SecondaryButton label={'Preview'} icon={<Preview/>} onClick={() => this.setState({showPreviewDialog: true})}/>
			</div>
		);
	}

	renderCustomMessage(workflowConfig, sequence, sequenceOrdinal) {
		let customMessageFunction = (value = null) => {
			if ((sequence === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN && sequenceOrdinal === 1) ||
				(sequence === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN && sequenceOrdinal === 1) ||
				(sequence === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN && sequenceOrdinal === 2)) {
				if (value != null) {
					workflowConfig.emailCustomMessageOwner = value;
				}
				return workflowConfig.emailCustomMessageOwner;
			}
			else if ((sequence === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN && sequenceOrdinal === 1) ||
					 (sequence === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN && sequenceOrdinal === 1) ||
					 (sequence === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN && sequenceOrdinal === 2)) {
				if (value != null) {
					workflowConfig.emailCustomMessagePartner = value;
				}
				return workflowConfig.emailCustomMessagePartner;
			}
			if (value != null) {
				workflowConfig.emailCustomMessage = value;
			}
			return workflowConfig.emailCustomMessage;
		};
		return (
			<div style={{fontSize: 8}}>
				<TextInputBox hintText={'Enter additional message (optional)'}
							  value={customMessageFunction()}
							  multiLine={true}
							  rows={2}
							  isSimple={true}
							  fontSize={14}
							  onChange={(value) => {
								  customMessageFunction(value);
								  this.forceUpdate();
							  }}/>
			</div>
		);
	}

	render() {
		let {workflowEditor, currentConfigStep} = this.props;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'top', justifyContent: 'center'}}>
				{workflowEditor.configureEmail(this, currentConfigStep)}
			</div>
		);
	}
}

EmailWorkflowEditor.propTypes = {
	workflowEditor: PropTypes.object.isRequired,
	actionListInfo: PropTypes.object.isRequired,
	workflow: PropTypes.string.isRequired,
	currentConfigStep: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired
};

export default EmailWorkflowEditor;
