import {AlarmOn, Group, PersonSearch} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as PartnersEndpoints from '../../../endpoints/PartnersEndpoints';
import * as ProfilesEndpoints from '../../../endpoints/ProfilesEndpoints';
import {INVITE_STATUS_ACCEPTED, INVITE_STATUS_INITIATED, INVITE_STATUS_REQUESTED, PARTNER_INVITE_SENT} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import {PARTNER_FILTER_KEY} from '../../../globals/StorageKeys';
import EnvHelper from '../../../helpers/EnvHelper';
import PersistenceHelper from '../../../helpers/PersistenceHelper';
import SortHelper from '../../../helpers/SortHelper';
import {partnerTapAlert, partnerTapSecondary} from '../../../styles/partnertap_theme';
import FilterMenuButton from '../../../ui/buttons/FilterMenuButton';
import FindPartnersButton from '../../../ui/buttons/FindPartnersButton';
import PendingButton from '../../../ui/buttons/PendingButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import Dialog from '../../../ui/Dialog';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';
import PartnerRowRenderer from './PartnerRowRenderer';

export class PartnersPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.allFilters = [];
		this.state.activeFilters = {};
		this.state.savedFilterSelection = null;
		this.state.minMatchedAccounts = 1;
		this.state.partnerStatus = this.partnerStatus;
		this.state.bulkResults = [];
		this.state.acceptingPartners = false;
		this.state.invitingPartners = false;
		this.state.partneringComplete = false;

		this.initFilter = this.initFilter.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.getAppliedFilterTitles = this.getAppliedFilterTitles.bind(this);
		this.clearAllFilters = this.clearAllFilters.bind(this);
	}

	componentDidMount() {
		this.initFilter();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.initFilter();
		}
	}

	onEmailInvite(event) {
		EnvHelper.push(Routes.DASHBOARD.PARTNERS.INVITE.ROUTE);
	}

	get isSubNavActive() {
		return EnvHelper.path === Routes.DASHBOARD.ROUTE || EnvHelper.path === Routes.DASHBOARD.PARTNERS.ROUTE || EnvHelper.path === Routes.DASHBOARD.PARTNERS.ACTIVE.ROUTE;
	}

	get isSubNavPending() {
		return EnvHelper.path === Routes.DASHBOARD.PARTNERS.PENDING.ROUTE;
	}

	get isSubNavDiscover() {
		return EnvHelper.path === Routes.DASHBOARD.PARTNERS.DISCOVER.ROUTE;
	}

	get storageKeyBase() {
		if (this.isSubNavPending) return 'network_partners_page_pending';
		if (this.isSubNavDiscover) return 'network_partners_page_discover';
		return 'network_partners_page_active';
	}

	get title() {
		if (this.isSubNavPending) return 'Pending Partners';
		if (this.isSubNavDiscover) return 'Find Partners';
		return 'Partner Connections';
	}

	get icon() {
		if (this.isSubNavPending) return AlarmOn;
		if (this.isSubNavDiscover) return PersonSearch;
		return Group;
	}

	get sortData() {
		if (this.isSubNavPending) {
			return [
				{name: 'Matched Accounts', key: SortHelper.sortPartnersByMatchCount, selected: true},
				{name: 'Partner Name', key: SortHelper.sortPartnersByNamePending},
				{name: 'Company Name', key: SortHelper.sortPartnersByOrganization}
			];
		}
		if (this.isSubNavDiscover) {
			return [
				{name: 'Matched Accounts', key: SortHelper.sortRecommendedPartnersByMatchCount, selected: true},
				{name: 'Partner Name', key: SortHelper.sortRecommendedPartnersByName},
				{name: 'Company Name', key: SortHelper.sortRecommendedPartnersByOrganization}
			];
		}
		return [
			{name: 'Matched Accounts', key: SortHelper.sortPartnersByMatchCount, selected: true},
			{name: 'Partner Name', key: SortHelper.sortPartnersByName},
			{name: 'Company Name', key: SortHelper.sortPartnersByOrganization}
		];
	}

	get componentAboveToolbar() {
		if (this.props.match.params.welcome) {
			return (
				<div style={{
					color: partnerTapSecondary,
					margin: 'auto',
					textAlign: 'center',
					padding: 20,
					paddingBottom: 10
				}}>
					<h3><i>Awesome! Now you can start inviting Partners!</i></h3>
				</div>
			);
		}
		return null;
	}

	get additionalToolbarButtons() {
		if (!this.isSubNavDiscover) {
			return [
				<FilterMenuButton key={'filter_button'}
								  toolTip={'Filters'}
								  filterData={this.state.allFilters}
								  savedSelection={this.state.savedFilterSelection}
								  onFilterChanged={this.onFilterChange}
								  isMultipleChoice={true}/>
			];
		}
		return [];
	}

	get componentBelowToolbar() {
		let component = null;
		if (this.isSubNavPending) {
			let foundPartners = this.state.rowData.filter((partnerItem) => partnerItem.partnerStatus !== INVITE_STATUS_INITIATED);
			if (foundPartners.length) {
				component = <PendingButton partnerList={foundPartners}
										   acceptAllClicked={() => {
											   this.setState({acceptingPartners: true, partneringComplete: false});
										   }}
										   updatePage={() => {
											   this.setState({acceptingPartners: true, partneringComplete: true});
										   }}/>;
			}
		}
		if (component) {
			return (
				<div style={{display: 'flex', justifyContent: 'center', paddingBottom: 10}}>
					{component}
				</div>
			);
		}
		return component;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		let action = this.action(params.search);
		action(params.page,
			params.search,
			params.sort,
			this.isSubNavDiscover ? null : this.state.minMatchedAccounts,
			this.isSubNavDiscover ? null : this.state.partnerStatus)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					result.payload.forEach((partnerItem) => {
						partnerItem.matchedAccountCount = partnerItem.accountMatchCount;
					});
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let message;
			if (this.isSubNavActive || this.isSubNavPending) {
				let appliedFilters = this.getAppliedFilterTitles();
				message =
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						<div style={{paddingTop: 10}}>
							{appliedFilters.length > 0 &&
							 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 10}}>
								 <div style={{padding: 10}}>
									 No partners found with the following filters applied:
								 </div>
								 <div style={{padding: 10, color: partnerTapAlert}}>
									 {appliedFilters.map((filterTitle, index) => {
										 return <div key={'filters_' + index}><em>{filterTitle}</em></div>;
									 })}
								 </div>
								 <div>
									 Please adjust your filters choices
								 </div>
								 <div style={{padding: 10}}>
									 <PrimaryButton label={'CLEAR FILTERS'} onClick={this.clearAllFilters}/>
								 </div>
							 </div>}
							{this.state.search &&
							 <div style={{paddingBottom: 10}}>
								 No partners found for search '{this.state.search}'.
							 </div>}
							{!this.state.search && appliedFilters.length === 0 &&
							 <div style={{paddingBottom: 10}}>
								 Time to make some partners!
							 </div>}
						</div>
						<div style={{padding: 10}}>
							If you don't see the partner you're looking for...
						</div>
						<div>
							<FindPartnersButton/>
						</div>
					</div>;
			}
			else if (this.isSubNavDiscover) {
				message =
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							Enter a partner or company name above to search for potential partners.
						</div>
					</div>;
			}

			if (message) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							{message}
						</div>
						<div>
							If you want to reach out directly...
						</div>
						<div style={{padding: 10}}>
							<PrimaryButton label={'EMAIL INVITE'} onClick={this.onEmailInvite}/>
						</div>
						{this.props.match.params.welcome &&
						 <div style={{padding: 10}}>
							 <PrimaryButton label={'DONE'} onClick={() => EnvHelper.push(Routes.DASHBOARD.ACCOUNTS.ROUTE)} disabled={this.state.sending}/>
						 </div>}
					</div>
				);
			}
		}

		let addDiscoverMessage = this.state.rowCount === 0 && this.state.searchValue !== '';
		if (addDiscoverMessage) {
			if (this.isSubNavActive || this.isSubNavPending) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						{super.noDataMessage}
						<div>
							Find the partners you're looking for!
						</div>
						<div style={{padding: 10}}>
							<FindPartnersButton/>
						</div>
					</div>
				);
			}
			else if (this.isSubNavDiscover) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						{super.noDataMessage}
						<div>
							We recommend you reach out directly.
						</div>
						<div style={{padding: 10}}>
							<PrimaryButton label={'EMAIL INVITE'} onClick={this.onEmailInvite}/>
						</div>
					</div>
				);
			}
		}

		return super.noDataMessage;
	}

	get rowRenderer() {
		return PartnerRowRenderer;
	}

	render() {
		return (
			<div>
				{super.render()}
				{this.dialog}
			</div>
		);
	}

	get dialog() {
		if (this.state.acceptingPartners || this.state.invitingPartners) {
			return (
				<Dialog title={this.state.invitingPartners ? 'Inviting Partners' : 'Accepting Partners'}
						message={this.state.partneringComplete ?
							<div>
								{this.state.invitingPartners ? 'Partner invites sent!' : 'Your new partners are all set up.'}
								{this.state.bulkResults.map((result, index) => {
									return <div key={'bulk_results_' + index}>
										{result !== PARTNER_INVITE_SENT && ('Warning:' + result)}
									</div>;
								})}
							</div>
							:
							<Loading>
								<div>{this.state.invitingPartners ? 'Inviting' : 'Setting up'} your new partners!</div>
							</Loading>}
						yesAction={() => {
							if (this.state.invitingPartners) {
								EnvHelper.push(Routes.DASHBOARD.PARTNERS.PENDING.ROUTE);
							}
							else {
								EnvHelper.push(Routes.DASHBOARD.PARTNERS.ACTIVE.ROUTE);
							}
						}}/>
			);
		}
		return null;
	}

	get filterItems() {
		if (this.isSubNavActive) {
			return [
				{key: 'MORE_THAN_ONE_V2', title: 'Has Matched Accounts', isFiltered: false}
			];
		}
		if (this.isSubNavPending) {
			return [
				{key: 'MORE_THAN_ONE_V2', title: 'Has Matched Accounts', isFiltered: false},
				{key: INVITE_STATUS_INITIATED, title: 'Invites Sent'},
				{key: INVITE_STATUS_REQUESTED, title: 'Invites Received'}
			];
		}
		return [];
	}

	initFilter() {
		let storedFilter = PersistenceHelper.getValue(this.filterStorageKey);
		if (storedFilter) {
			storedFilter = JSON.parse(storedFilter);
			this.onFilterChange(storedFilter);
		}
		this.setState({allFilters: this.filterItems, loading: true});
		if (this.isSubNavPending) {
			this.setState({activeFilters: {}, savedFilterSelection: null});
		}
	}

	get filterStorageKey() {
		if (this.isSubNavActive) return PARTNER_FILTER_KEY + 'active-v2';
		if (this.isSubNavPending) return PARTNER_FILTER_KEY + 'pending-v2';
		if (this.isSubNavDiscover) return PARTNER_FILTER_KEY + 'discover';
		return '';
	}

	onFilterChange(activeFilters, fromMount) {
		if (!activeFilters) return;
		let newMinMatched = this.processMatchFilters(activeFilters);
		let newPartnerStatus = this.processStatusFilters(activeFilters);
		this.setState({
			loading: !fromMount,
			activeFilters: activeFilters,
			savedFilterSelection: activeFilters,
			minMatchedAccounts: newMinMatched,
			partnerStatus: newPartnerStatus
		});
		PersistenceHelper.setValue(this.filterStorageKey, JSON.stringify(activeFilters));
	}

	clearAllFilters() {
		let {savedFilterSelection} = this.state;
		if (savedFilterSelection) {
			Object.keys(savedFilterSelection).forEach((filterKey) => savedFilterSelection[filterKey] = false);
			this.onFilterChange(savedFilterSelection);
		}
	}

	processMatchFilters(activeFilters) {
		return activeFilters['MORE_THAN_ONE_V2'] ? 1 : 0;
	}

	processStatusFilters(activeFilters) {
		let statusTypes = [];
		Object.keys(activeFilters).forEach((filter) => {
			if ((filter === INVITE_STATUS_REQUESTED || filter === INVITE_STATUS_INITIATED || filter === INVITE_STATUS_ACCEPTED) && activeFilters[filter]) statusTypes.push(filter);
		});
		if (statusTypes.length === 0) {
			statusTypes = this.partnerStatus;
		}
		return statusTypes;
	}

	getAppliedFilterTitles() {
		let {activeFilters} = this.state;
		let appliedFilterTitles = [];
		Object.keys(activeFilters).forEach((key) => {
			if (activeFilters[key]) {
				this.filterItems.forEach((item) => {
					if (item.key === key) appliedFilterTitles.push(item.title);
				});
			}
		});
		return appliedFilterTitles;
	}

	action(searchValue) {
		if (this.isSubNavDiscover) {
			if (searchValue && searchValue.length >= 2) {
				return ProfilesEndpoints.searchProfiles;
			}
			return PartnersEndpoints.fetchPartnerRecommendations;
		}
		return PartnersEndpoints.searchPartners;
	}

	get partnerStatus() {
		if (this.isSubNavActive) return [INVITE_STATUS_ACCEPTED];
		if (this.isSubNavPending) return [INVITE_STATUS_REQUESTED, INVITE_STATUS_INITIATED];
		return [];
	}

	get alwaysShowSearch() {
		return this.isSubNavDiscover;
	}
}

PartnersPage.propTypes = {
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnersPage));
