import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../../endpoints/ManagerAnalyticsEndpoints';
import DateHelper from '../../../helpers/DateHelper';
import NavHelper from '../../../helpers/NavHelper';
import PartnerStatusHelper from '../../../helpers/PartnerStatusHelper';
import * as ManagerAnalyticsActions from '../../../redux/ManagerAnalyticsActions';
import {partnerTapSecondary} from '../../../styles/partnertap_theme';
import ManagerAnalyticsPage from '../ManagerAnalyticsPage';

class MatchedAccountAnalytics extends Component {

	constructor(props, context) {
		super(props, context);
		this.onClickAccountName = this.onClickAccountName.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(ManagerAnalyticsActions.setForceAllTime(true));
	}

	componentWillUnmount() {
		this.props.dispatch(ManagerAnalyticsActions.setForceAllTime(false));
	}

	onClickAccountName(value, data) {
		let {person} = this.props.authState;
		let linkSFDC = person && data && data.thirdPartyId;
		let link = linkSFDC && NavHelper.getCrmAccountLink(person.crmType, person.crmInstance, data.thirdPartyId);
		if (link) {
			return (
				<div style={{color: partnerTapSecondary, cursor: 'pointer'}} onClick={() => window.open(link, '_system')}>
					{value}
				</div>
			);
		}
		else {
			return value;
		}
	}

	render() {
		let columns = [
			{title: 'Account Name', key: 'name', renderFunction: this.onClickAccountName},
			{title: 'Account Type', key: 'accountType'},
			{title: 'Rep', key: 'repName'},
			{title: 'Rep Email', key: 'repEmail'},
			{title: 'Rep Title', key: 'ownerJobTitle'},
			{title: 'City', key: 'city'},
			{title: 'State', key: 'state'},
			{title: 'Partner Rep', key: 'partnerRepName'},
			{title: 'Partner Email', key: 'partnerEmail'},
			{title: 'Partner Title', key: 'partnerJobTitle'},
			{title: 'Partner Org', key: 'partnerOrganization'},
			{title: 'Partnership Status', key: 'partnershipStatus', renderFunction: PartnerStatusHelper.getPartnerStatus},
			{title: 'Industry', key: 'industry'},
			{title: 'Date', key: 'recentMatchedTimestamp', renderFunction: DateHelper.epochToDate}
		];
		return (
			<ManagerAnalyticsPage labelNoFind={'No mapped account data found'}
								  labelLoading={'Loading Matched Account Data...'}
								  sectionName={'Matched Accounts'}
								  glyph={'%'}
								  columnData={columns}
								  keyData={[ManagerAnalyticsEndpoints.keyMatchedAccounts]}/>
		);
	}
}

MatchedAccountAnalytics.propTypes = {
	authState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(MatchedAccountAnalytics);
