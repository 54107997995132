import {AllInclusive, Block, CheckBox, CheckBoxOutlineBlank, Dashboard, FolderShared, FolderSpecial, Layers, Storefront, Tune} from '@mui/icons-material';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import EnvHelper from '../../helpers/EnvHelper';
import StringHelper from '../../helpers/StringHelper';
import {
	partnerTapDefaultText,
	partnerTapInactive,
	partnerTapPrimary,
	partnerTapSecondary,
	partnerTapStroke,
	partnerTapWhite
} from '../../styles/partnertap_theme';
import ToolTipOverlay from '../ToolTipOverlay';

const serverSideIconMap = {
	block: Block,
	dashboard: Dashboard,
	all_inclusive: AllInclusive,
	layers: Layers,
	folder_special: FolderSpecial,
	folder_shared: FolderShared,
	storefront: Storefront,
	tune: Tune
};

class ToggleButton extends Component {

	onToggle(index, toggle) {
		if (this.props.onToggle) {
			this.props.onToggle(index, toggle);
		}
	}

	render() {
		let {toggles, showSelectCheck} = this.props;
		return (
			<div style={{
				display: 'flex',
				flexWrap: toggles.length > 2 ? 'wrap' : null,
				justifyContent: 'center',
				margin: 'auto',
				whiteSpace: 'nowrap'
			}}>
				{toggles.map((toggle, index) => {
					let isDisabled = this.props.disabled || toggle.disabled;
					let isSelected = !isDisabled && index === (this.props.defaultSelection || 0);
					let color = isDisabled ? partnerTapWhite : (isSelected ? partnerTapPrimary : partnerTapSecondary);
					let icon;
					let MaterialIcon = toggle.MaterialIcon;
					if (toggle.iconName) {
						MaterialIcon = serverSideIconMap[toggle.iconName];
					}
					if (MaterialIcon) {
						icon = <MaterialIcon style={{fontSize: toggle.description ? 32 : 24, width: toggle.description ? 32 : 24, paddingRight: 4}}/>;
					}
					if (!icon && showSelectCheck) {
						icon = isSelected ?
							<CheckBox style={{color: color, paddingRight: 4}}/> :
							<CheckBoxOutlineBlank style={{color: color, paddingRight: 4}}/>;
					}

					let buttonStyle = {
						backgroundColor: this.props.disabled ? partnerTapStroke : partnerTapWhite,
						border: !showSelectCheck && '1px solid ' + (isDisabled ? partnerTapInactive : isSelected ? partnerTapPrimary : partnerTapSecondary),
						borderRadius: 0,
						margin: 0.5
					};
					if (toggle.description) {
						buttonStyle.width = 260;
						buttonStyle.height = 120;
						buttonStyle.margin = 2;
						buttonStyle.borderRadius = 5;
					}

					let label = EnvHelper.isDesktop ? toggle.label : null;
					let button =
						<Button key={index}
								data-cy={'toggle_button_' + (label ? StringHelper.formatKey(label) : '') + '_' + index}
								color={isSelected ? 'primary' : 'secondary'}
								disabled={isDisabled}
								style={buttonStyle}
								onClick={() => this.onToggle(index, toggle)}>
							{toggle.description ?
								<div style={{display: 'flex', alignItems: 'center'}}>
									<div style={{padding: 5, paddingRight: 10}}>
										{icon}
									</div>
									<div style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
										<div style={{display: 'flex'}}>
											{label}
										</div>
										<div style={{
											fontSize: 12,
											color: partnerTapDefaultText,
											textTransform: 'none',
											whiteSpace: 'normal',
											textAlign: 'left',
											paddingRight: 10,
											lineHeight: '18px'
										}}>
											{toggle.description}
										</div>
									</div>
								</div>
								:
								<div style={{display: 'flex', alignItems: 'center', paddingRight: label ? 4 : 0}}>
									{icon}
									{label}
								</div>}
						</Button>;
					let toolTip = EnvHelper.isDesktop ? toggle.toolTip : (toggle.toolTip || toggle.label);
					if (toolTip) {
						return (
							<ToolTipOverlay key={index} title={toolTip}>
								{button}
							</ToolTipOverlay>
						);
					}
					else {
						return button;
					}
				})}
			</div>
		);
	}
}

ToggleButton.propTypes = {
	toggles: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		MaterialIcon: PropTypes.elementType,
		toolTip: PropTypes.string,
		disabled: PropTypes.bool
	})).isRequired,
	onToggle: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	defaultSelection: PropTypes.number,
	showSelectCheck: PropTypes.bool
};

export default ToggleButton;
