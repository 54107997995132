import {Star} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import AccountDetail from '../../app/channel_ecosystem/accounts/AccountDetail';
import AccountListPage from '../../app/channel_ecosystem/accounts/AccountListPage';
import MyAccountsPage from '../../app/channel_ecosystem/accounts/MyAccountsPage';
import MyOpportunitiesPage from '../../app/channel_ecosystem/opportunities/MyOpportunitiesPage';
import OpportunityDetail from '../../app/channel_ecosystem/opportunities/OpportunityDetail';
import OpportunityListPage from '../../app/channel_ecosystem/opportunities/OpportunityListPage';
import * as ChannelMappingEndpoints from '../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_ECOSYSTEM_ACCESS,
	FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS,
	tempFeatureFlag_CoSellEngine_live
} from '../../helpers/FeatureHelper';
import NavHelper from '../../helpers/NavHelper';
import * as Notifications from '../../helpers/NotificationHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapQuaternary, partnerTapSecondaryMedium, partnerTapWhite} from '../../styles/partnertap_theme';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import LogoMessage from '../../ui/messages/LogoMessage';
import SideNav from '../../ui/navigation/SideNav';
import TopBar from '../../ui/TopBar';
import ProfilePage from '../profile/ProfilePage';
import IntelPage from '../shared/IntelPage';
import SettingsPage from '../shared/SettingsPage';
import AdminCenter from './admin_center/AdminCenter';
import CoSellActionListRouter from './co_sell_engine/CoSellActionListRouter';
import CoSellActionListRouterLive from './co_sell_engine/CoSellActionListRouterLive';
import CoSellDashboardPage from './co_sell_engine/CoSellDashboardPage';
import CoSellEmailAccessPage from './co_sell_engine/CoSellEmailAccessPage';
import CoSellOverviewPage from './co_sell_engine/CoSellOverviewPage';
import CoSellOverviewPageLive from './co_sell_engine/CoSellOverviewPageLive';
import DataManagerPage from './data_manager/DataManagerPage';
import DataManagerSyncSummary from './data_manager/DataManagerSyncSummary';
import DataManagerWizardPageStep1 from './data_manager/DataManagerWizardPageStep1';
import DataManagerWizardPageStep2 from './data_manager/DataManagerWizardPageStep2';
import DataManagerWizardPageStep3 from './data_manager/DataManagerWizardPageStep3';
import CsvDownloadPage from './ecosystem/CsvDownloadPage';
import EcosystemDashboardPage from './ecosystem/EcosystemDashboardPage';
import GlobalReportPage from './ecosystem/global_reports/GlobalReportPage';
import GlobalReportsPage from './ecosystem/global_reports/GlobalReportsPage';
import SavedReportsRouter from './ecosystem/saved_reports/SavedReportsRouter';
import GettingStartedWizard from './getting_started/GettingStartedWizard';
import PartnerConnectionsPage from './partners/parnter_connections/PartnerConnectionPage';
import PartnerConnectionRouter from './partners/parnter_connections/PartnerConnectionRouter';
import PartnerOrgListPage from './partners/partner_orgs/PartnerOrgListPage';
import PartnerOrgRouter from './partners/partner_orgs/PartnerOrgRouter';
import PartnerSheetRouter from './partners/partner_sheets/PartnerSheetRouter';
import PartnerConnectionInvitePage from './partners/PartnerConnectionInvitePage';
import PartnerRecommendationsPage from './partners/PartnerRecommendationsPage';
import SheetUploadRouter from './shared/sheets/SheetUploadRouter';
import LinkButton from '../../ui/buttons/LinkButton';
import MessageBox from '../../ui/messages/MessageBox';

class ChannelEcosystemProduct extends Component {

	constructor(props, context) {
		super(props, context);
		try {
			Notifications.handleDeepLinks(props.authState.person, props.dispatch);
		}
		catch (error) {
			console.error('Error handing deep link', error);
		}
		this.state = {loading: true, isMobileNavOpen: false};
		this.getSubordinates = this.getSubordinates.bind(this);
	}

	componentDidMount() {
		this.getSubordinates();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getSubordinates() {
		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_ECOSYSTEM_ACCESS) && FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS)) {
			ChannelMappingEndpoints.getSubordinates()
			.then((result) => {
				if (this.unmounted) return;
				this.props.dispatch(AuthActions.setChannelSubordinates(result.payload));
				this.setState({loading: false});
			})
			.catch((error) => {
				EnvHelper.serverError('Error from getSubordinates', error);
			});
		}
		else {
			this.setState({loading: false});
			this.props.dispatch(AuthActions.setChannelSubordinates(null));
		}
	}

	get routeSwitch() {
		return (
			<Switch location={this.props.locationOverride}>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.GETTING_STARTED_WIZARD.ROUTE} component={GettingStartedWizard}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORGS.ROUTE} component={PartnerOrgListPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.ROUTE} component={PartnerOrgRouter}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE} component={PartnerConnectionsPage}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.ROUTE} component={PartnerConnectionRouter}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INVITE.ROUTE} component={PartnerConnectionInvitePage}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.RECOMMENDATIONS.ROUTE} component={PartnerRecommendationsPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INTEL.ROUTE} component={IntelPage}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS.ROUTE} component={PartnerSheetRouter}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.ROUTE} component={AccountListPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.ACCOUNT_DETAILS.ROUTE} component={AccountDetail}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.ROUTE} component={OpportunityListPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.OPPORTUNITY_DETAILS.ROUTE} component={OpportunityDetail}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE}
					   component={(tempFeatureFlag_CoSellEngine_live ? CoSellOverviewPageLive : CoSellOverviewPage)}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.DASHBOARD.ROUTE} component={CoSellDashboardPage}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.EMAIL_ACCESS.ROUTE} component={CoSellEmailAccessPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ROUTE}
					   component={(tempFeatureFlag_CoSellEngine_live ? CoSellActionListRouterLive : CoSellActionListRouter)}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.DASHBOARD.ROUTE} component={EcosystemDashboardPage}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.ROUTE} component={GlobalReportsPage}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.REPORT.ROUTE} component={GlobalReportPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.ROUTE} component={SavedReportsRouter}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.DOWNLOADED.ROUTE} component={CsvDownloadPage}/>

				<Route path={Routes.CHANNEL_ECOSYSTEM.ADMIN_CENTER.ROUTE} component={AdminCenter}/>

				<Route exact path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE} component={DataManagerPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_ACCOUNTS.LIST.ROUTE} component={MyAccountsPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.MY_OPPORTUNITIES.LIST.ROUTE} component={MyOpportunitiesPage}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.ROUTE} component={DataManagerWizardPageStep1}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_2.ROUTE} component={DataManagerWizardPageStep2}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_3.ROUTE} component={DataManagerWizardPageStep3}/>
				<Route exact path={Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE} component={DataManagerSyncSummary}/>

				<Route path={Routes.CHANNEL_ECOSYSTEM.UPLOAD.ROUTE} component={SheetUploadRouter}/>

				<Route path={Routes.CHANNEL_ECOSYSTEM.PROFILE.ROUTE} component={ProfilePage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.SETTINGS.ROUTE} component={SettingsPage}/>
				<Route path={Routes.CHANNEL_ECOSYSTEM.ROUTE} component={GettingStartedWizard}/>
			</Switch>
		);
	}

	get isProfileRoute() {
		return this.props.location.pathname.indexOf(Routes.CHANNEL_ECOSYSTEM.PROFILE.ROUTE) === 0;
	}

	render() {
		if (this.state.loading) {
			return <LogoMessage message={'Loading Ecosystem...'}/>;
		}

		if (this.props.locationOverride) {
			// GTM tracking of every page embedded in dialog
			window.dataLayer.push({event: 'pageview', page: {url: this.props.locationOverride.pathname + this.props.locationOverride.search}});
			return this.routeSwitch;
		}

		if (this.props.location.pathname === Routes.CHANNEL_ECOSYSTEM.GETTING_STARTED_WIZARD.ROUTE) {
			NavHelper.returnToGettingStarted = false;
		}

		return (
			<div key={EnvHelper.path} style={{display: 'flex', flexDirection: 'column'}}>
				<TopBar/>
				{NavHelper.returnToGettingStarted &&
				 <div style={{
					 display: 'flex',
					 justifyContent: 'center',
					 alignItems: 'center',
					 height: 60,
					 color: partnerTapWhite,
					 backgroundColor: partnerTapSecondaryMedium
				 }}>
					 <div style={{paddingRight: 20}}>When you're ready...</div>
					 <PrimaryButton label={'Return to Getting Started'}
									icon={<Star style={{color: partnerTapWhite}}/>}
									onClick={() => {
										NavHelper.returnToGettingStarted = false;
										EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.GETTING_STARTED_WIZARD.ROUTE);
									}}/>
				 </div>}
				<div style={{display: 'flex', backgroundColor: partnerTapQuaternary}}>
					<div key={EnvHelper.path} className={'printNone'}>
						<SideNav navButtonData={NavHelper.channelEcosystemData}
								 isMobileNavOpen={this.state.isMobileNavOpen}
								 onToggleMobileNavOpen={(state) => {
									 if (this.unmounted) return;
									 this.setState({isMobileNavOpen: state});
								 }}/>
					</div>
					<div className={'printFullWidth'} style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
						{(FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_ECOSYSTEM_ACCESS) || this.isProfileRoute) ? this.routeSwitch :
							<MessageBox title={'PartnerTap Partner Ecosystem Platform'} hideIcon={true}>
								<div>
									Use PartnerTap’s leading ecosystem platform and co-selling solution to source more pipeline and accelerate sales with and
									through your partners.
								</div>
								<div style={{fontWeight: 'bold'}}>
									Use PartnerTap’s Ecosystem Platform and Co-Sell Engine to:
								</div>
								<ol style={{paddingLeft: 40}}>
									<li> Securely share and receive data from partners in digital clean rooms, or upload partner spreadsheets</li>
									<li> Automate account mapping and opportunity mapping</li>
									<li> Use reports to zero in on the highest value accounts for your sales reps and partners to focus on</li>
									<li> Save these reports as actions lists</li>
									<li> Run co-sell workflows to automate direct sales plays, through-channel plays, and co-sell plays</li>
									<li> Track and measure the net-new pipeline generated by each workflow with closed-loop attribution</li>
									<li> Get real-time visibility to partner pipeline and collaborate on deals as they move through the sales process</li>
									<li> Build up a multi-partner view of each account and opportunity with ecosystem reports</li>
								</ol>
								<div>
									Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																									onClick={() => window.open('https://partnertap.com/support/')}/> for
									more information.
								</div>
							</MessageBox>}
					</div>
				</div>
			</div>);
	}
}

ChannelEcosystemProduct.propTypes = {
	authState: PropTypes.object.isRequired, location: PropTypes.object.isRequired, dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(ChannelEcosystemProduct));
