import React from 'react';
import {CircularProgress} from '@mui/material';
import * as ChannelMappingEndpoints from '../endpoints/ChannelMappingEndpoints';
import DateHelper from './DateHelper';
import EnvHelper from './EnvHelper';
import {
	PERSON_QUEUE_STATUS_DW_PENDING,
	PERSON_QUEUE_STATUS_FAILED_TO_PROCESS,
	PERSON_QUEUE_STATUS_PROCESSED,
	PERSON_QUEUE_STATUS_PROCESSING,
	PERSON_QUEUE_STATUS_READY
} from './UploadHelper';
import {partnerTapPrimary} from '../styles/partnertap_theme';

export default class PersonQueueHelper {

	static init() {
		if (!this.initialized) {
			this.initialized = true;
			this.intervals = {};
		}
	}

	static pollPersonQueueStatus(personQueueId, progressCallback, completeCallback) {
		this.init();
		ChannelMappingEndpoints.getUploadDataForId(personQueueId)
		.then((result) => {
			if ((result.payload.status === PERSON_QUEUE_STATUS_PROCESSING ||
				 result.payload.status === PERSON_QUEUE_STATUS_READY ||
				 result.payload.status === PERSON_QUEUE_STATUS_DW_PENDING)
				&& this.intervals[personQueueId] == null && !result.payload.errorMessage) {
				this.intervals[personQueueId] = setInterval(() => {
					this.pollPersonQueueStatus(personQueueId, progressCallback, completeCallback);
				}, 1000);
			}
			else if (result.payload.status !== PERSON_QUEUE_STATUS_PROCESSING &&
					 result.payload.status !== PERSON_QUEUE_STATUS_READY &&
					 result.payload.status !== PERSON_QUEUE_STATUS_DW_PENDING) {
				this.stopPolling(personQueueId);
			}

			if (result.payload.status === PERSON_QUEUE_STATUS_PROCESSED ||
				result.payload.status === PERSON_QUEUE_STATUS_FAILED_TO_PROCESS ||
				result.payload.errorMessage) {
				completeCallback(result.payload);
			}
			else {
				progressCallback(result.payload);
			}
		})
		.catch((error) => {
			completeCallback({errorMessage: error.message});
			EnvHelper.serverError('Error from getUploadDataForId', error);
		});
	}

	static stopPolling(personQueueId) {
		clearInterval(this.intervals[personQueueId]);
		delete this.intervals[personQueueId];
	}

	static progressMessage(onlyRenderProgressBar, personQueue) {
		let progressMessage =
			<div style={{display: 'flex', alignItems: 'center', padding: 10, fontWeight: 'bold'}}>
				<CircularProgress size={20} style={{color: partnerTapPrimary, background: 'transparent', padding: 10}}/>
				Connecting...
			</div>;
		if (onlyRenderProgressBar) progressMessage = 'Connecting...';

		if (personQueue.status === PERSON_QUEUE_STATUS_PROCESSING && !personQueue.estimatedCompleteDate) {
			progressMessage = <div style={{padding: 10, fontWeight: 'bold'}}>Uploading...</div>;
			if (onlyRenderProgressBar) progressMessage = 'Uploading...';
		}
		else if (personQueue.status === PERSON_QUEUE_STATUS_PROCESSED) {
			progressMessage = <div style={{padding: 10, fontWeight: 'bold'}}>Complete</div>;
			if (onlyRenderProgressBar) progressMessage = 'Complete';
		}
		else if (personQueue.estimatedCompleteDate) {
			let milliSeconds = DateHelper.getMillisecondsWithTimeZoneOffset(personQueue.estimatedCompleteDate);
			let formattedCompleteDate = milliSeconds ? DateHelper.getFormattedDateTime(new Date(milliSeconds)) : '';
			progressMessage =
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
					<div>
						<b>Mapping...</b>
					</div>
					<div>
						Estimated Completion: {DateHelper.getElapsedTime(milliSeconds, null, true)} {formattedCompleteDate}
					</div>
				</div>;
			if (onlyRenderProgressBar) progressMessage = 'Time remaining: ' + DateHelper.getElapsedTime(milliSeconds, null, true) + ' ' + formattedCompleteDate;
		}
		return progressMessage;
	}

	static progressPercentage(personQueue) {
		let progressPercentage = personQueue.totalRowsProcessed > 0 ? (personQueue.totalRowsProcessed / personQueue.totalRows) * 100 : 0;
		if (progressPercentage < 5) progressPercentage = 5;
		return progressPercentage;
	}
}
