// action columns (not toggleable, sortable, or filterable)
import {partnerTapWhite} from '../styles/partnertap_theme';
import {COLUMN_KEY_ACCOUNT_NAME, COLUMN_KEY_OPPORTUNITY_NAME} from '../globals/Enums';

export const ACTIONS = 'actions';

// system column (not visible)
export const IS_AUDITED = 'isAudited';

export default class ColumnHelper {

	static isFilterable(column) {
		return ColumnHelper.isSortable(column);
	}

	static isSortable(column) {
		return !column.sortDisabled && !ColumnHelper.isAction(column) && !ColumnHelper.isSystem(column);
	}

	static isResizeable(column) {
		return column.key !== COLUMN_KEY_ACCOUNT_NAME && column.key !== COLUMN_KEY_OPPORTUNITY_NAME && column.key !== ACTIONS;
	}

	static isSortDesc(column) {
		return ColumnHelper.isNumber(column) || ColumnHelper.isCurrency(column) || ColumnHelper.isDate(column) || ColumnHelper.isBoolean(column);
	}

	static isAction(column) {
		return column.key === ACTIONS;
	}

	static isSystem(column) {
		return column.isHidden || column.key.toUpperCase() === IS_AUDITED.toUpperCase();
	}

	static isVisible(column) {
		return Boolean((column.activeInverted ? !column.active : column.active) && !ColumnHelper.isSystem(column));
	}

	static getFirstVisibleColumn(columns) {
		return columns.find((column) => ColumnHelper.isVisible(column));
	}

	static isString(column) {
		return column?.type?.toLowerCase() === 'string';
	}

	static isNumber(column) {
		return column?.type?.toLowerCase() === 'number' || column?.type?.toLowerCase() === 'integer';
	}

	static isCurrency(column) {
		return column?.type?.toLowerCase() === 'currency';
	}

	static isDate(column) {
		return column?.type?.toLowerCase() === 'date';
	}

	static isBoolean(column) {
		return column?.type?.toLowerCase() === 'boolean';
	}

	static isArrayOfString(column) {
		return column?.type?.toLowerCase() === 'array_of_string';
	}

	static isProductCode(column) {
		return column.key === 'productCode';
	}

	static isRoleCode(column) {
		return column.key === 'roleCode';
	}

	static get columnHeaderControlStyle() {
		return {color: partnerTapWhite, opacity: 0.5};
	}
}
