import _ from 'lodash';
import * as RegistrationEndpoints from '../endpoints/RegistrationEndpoints';
import {
	CHANNEL_MAPPER,
	INVITE_BLOCKED_BLACKLIST,
	INVITE_BLOCKED_EMAIL_DOMAIN,
	INVITE_BLOCKED_SETTINGS,
	INVITE_NOT_SENT,
	INVITE_NOT_SENT_REPEAT,
	INVITE_NOT_SENT_TOO_MANY,
	INVITE_SENT,
	PARTNER_INVITE_NOT_SENT_ALREADY_DEAD_PARTNERS,
	PARTNER_INVITE_NOT_SENT_ALREADY_PARTNERS,
	PARTNER_INVITE_NOT_SENT_REPEAT,
	PARTNER_INVITE_NOT_SENT_REVERSE_INVITE_ACCEPTED,
	PARTNER_INVITE_SENT
} from '../globals/Enums';
import {INVITED_CONTACT_KEY} from '../globals/StorageKeys';
import {PRODUCT_CHANNEL_ECOSYSTEM} from './FeatureHelper';
import PersistenceHelper from './PersistenceHelper';

export default class InviteHelper {

	static getStatusMessage(inviteStatus, invited) {
		switch (inviteStatus) {
			case INVITE_SENT:
				return 'Invite sent to ' + invited;
			case INVITE_NOT_SENT:
				return 'Invite was not sent to ' + invited;
			case INVITE_NOT_SENT_REPEAT:
				return 'You already invited ' + invited;
			case INVITE_NOT_SENT_TOO_MANY:
				return 'Invite to ' + invited + ' was not sent. Open invite limit is 100.';
			case INVITE_BLOCKED_SETTINGS:
				return 'Invite to ' + invited + ' was blocked by settings';
			case INVITE_BLOCKED_BLACKLIST:
				return 'Invite to ' + invited + ' was blocked by organization';
			case INVITE_BLOCKED_EMAIL_DOMAIN:
				return 'Invite to ' + invited + ' was blocked by email domain';

			// for email invites to members (from recommended partners, email invites converted to partner requests, or partner accepted on server side)
			case PARTNER_INVITE_SENT:
				return 'Partner invite sent to member ' + invited;
			case PARTNER_INVITE_NOT_SENT_REPEAT:
				return 'You already invited member ' + invited;
			case PARTNER_INVITE_NOT_SENT_ALREADY_PARTNERS:
				return 'You already partnered with member ' + invited;
			case PARTNER_INVITE_NOT_SENT_ALREADY_DEAD_PARTNERS:
				return 'Member ' + invited + ' doesn\'t want to partner at this time';
			case PARTNER_INVITE_NOT_SENT_REVERSE_INVITE_ACCEPTED:
				return 'Member ' + invited + ' has also invited you, so now you\'re partners!';
		}
		return 'ERROR: Unknown inviteStatus: ' + inviteStatus;
	}

	static storeInviteEmail(emailAddresses) {
		if (this.isEmailInviteStored(emailAddresses)) {
			return;
		}
		let invitedEmails = PersistenceHelper.getValue(INVITED_CONTACT_KEY);
		if (_.isEmpty(invitedEmails)) {
			invitedEmails = emailAddresses;
		}
		else {
			invitedEmails += ',' + emailAddresses;
		}
		PersistenceHelper.setValue(INVITED_CONTACT_KEY, invitedEmails);
	}

	static isEmailInviteStored(emailAddress) {
		let invitedEmails = PersistenceHelper.getValue(INVITED_CONTACT_KEY);
		if (invitedEmails) {
			return invitedEmails.indexOf(emailAddress) !== -1;
		}
		return false;
	}

	static getJoinMeUriOrgAndUser(pathname, callback) {
		const Uri = PersistenceHelper.initialLocation || pathname;
		PersistenceHelper.clearInitialLocation();
		const UriChunks = Uri.split('/');
		const isJoinMeUri = UriChunks.length > 6 && UriChunks[1] === 'register' && UriChunks[3] === 'profile' && UriChunks[4].length === 36 && UriChunks[6].length === 32;
		if (isJoinMeUri) {
			let productCode = UriChunks[2];
			if (productCode === CHANNEL_MAPPER) productCode = PRODUCT_CHANNEL_ECOSYSTEM;
			const inviterOrgId = UriChunks[4];
			const inviterPersonId = UriChunks[6];
			RegistrationEndpoints.fetchOrgInfo(inviterOrgId, inviterPersonId)
			.then((result) => {
				let {name, inviterName, inviterTitle, personId} = result.payload;
				callback({
					orgName: name,
					inviterName: inviterName,
					inviterTitle: inviterTitle,
					inviterPersonId: personId,
					productCode: productCode
				});
			})
			.catch((error) => {
				console.error('Error from handleJoinMeURI', error);
			});
		}
		callback();
	}
}
