// local storage persistent key prefix
export const PERSISTENT_KEY = 'persistent_';

// storage key for initial location value
export const INITIAL_LOCATION_KEY = PERSISTENT_KEY + 'initial_location';

// storage key for deep link value
export const DEEP_LINK_KEY = PERSISTENT_KEY + 'deep_link';

// storage key to make sure we only ask for version update once, in case version.txt file gets out of sync
export const UPDATE_VERSION_KEY = PERSISTENT_KEY + 'update_version';

// storage keys for referring email id and whether it comes from the corresponding notification
export const REFERRING_EMAIL_ID_KEY = PERSISTENT_KEY + 'referring_email_id';
export const FROM_NOTIFICATION_KEY = PERSISTENT_KEY + 'from_notification';

// storage keys for partners lists
export const PARTNER_FILTER_KEY = PERSISTENT_KEY + 'partner_filter_';

// storage key for partner count, and count of partners added since last login
export const PARTNER_COUNT_KEY = PERSISTENT_KEY + 'partner_count';
export const NEW_PARTNER_COUNT_KEY = 'new_partner_count';
export const CHANNEL_PARTNER_COUNT_KEY = PERSISTENT_KEY + 'channel_partner_count';
export const NEW_CHANNEL_PARTNER_COUNT_KEY = 'new_channel_partner_count';

// storage key for opportunity count
export const OPPORTUNITY_COUNT_KEY = PERSISTENT_KEY + 'opportunity_count';

// storage keys for oauth
export const LOGIN_USERNAME_KEY = PERSISTENT_KEY + 'login_username';
export const LOGIN_SIGN_UP_TYPE_KEY = 'sign_up_type';
export const LOGIN_INVITER_PROFILE_KEY = 'inviter_profile';
export const CODE_VERIFIER_KEY = 'code_verifier';
export const REDIRECT_URI_KEY = 'redirectUri';
export const DYNAMICS_URI_KEY = 'crm_uri';

// storage key for login token
export const LOGIN_TOKEN_KEY = 'login_token';

// storage key for last route
export const LAST_ROUTE_KEY = PERSISTENT_KEY + 'last_route';

// storage key for admin tools current org
export const ADMIN_CURRENT_ORG_ID_KEY = 'admin_org_selector_org_id';

// storage key for first visit to channel partner sheet upload
export const PARTNER_SHEETS_FIRST_VISIT_KEY = PERSISTENT_KEY + 'partner_sheets_first_visit';

// storage key for first visit to channel partner sheet upload
export const UPLOAD_HELP_KEY = PERSISTENT_KEY + 'upload_help_';

// storage key for invited contacts
export const INVITED_CONTACT_KEY = PERSISTENT_KEY + 'invited_contacts';
