import {convert, LocalDateTime} from '@js-joda/core';

const oneSecond = 1000;
const oneMinute = oneSecond * 60;
const oneHour = oneMinute * 60;
const oneDay = oneHour * 24;
const oneWeek = oneDay * 7;
const oneMonth = oneDay * 30;
const oneYear = oneDay * 365;

export default class DateHelper {

	static epochToDate(epoch) {
		if (!epoch) return '-';
		if (typeof epoch === 'string') return epoch;
		return DateHelper.getFormattedDate(new Date(epoch * 1000));
	}

	static getFormattedDateTime(date) {
		return DateHelper.getFormattedDate(date) + ' ' + DateHelper.formatAMPM(date);
	}

	static getFormattedDate(date) {
		return DateHelper.padLeadingZero(date.getMonth() + 1) + '/' + DateHelper.padLeadingZero(date.getDate()) + '/' + date.getFullYear();
	}

	static padLeadingZero(value) {
		return value > 9 ? value : '0' + value;
	}

	static formatAMPM(date) {
		let hours = date.getHours() % 12;
		return (hours === 0 ? 12 : hours) + ':' + this.padLeadingZero(date.getMinutes()) + ' ' + (date.getHours() >= 12 ? 'pm' : 'am');
	}

	static getElapsedTime(milliseconds, date, forceCountDown) {
		let value = '';
		let now = Date.now();
		let elapsed = now - milliseconds;
		let isCountDown = false;
		if (milliseconds > now || forceCountDown) {
			elapsed = milliseconds - now;
			isCountDown = true;
		}
		value = this.getTimePeriod(elapsed);
		if (!value) {
			value = isCountDown ? 'Any moment' : 'Just now';
		}
		else {
			value = value + (isCountDown ? '' : ' ago');
		}
		if (date) {
			if (elapsed / oneDay > 1) value = this.getFormattedDate(date);
			if (elapsed / oneMinute < 1) value = 'Just now';
		}
		return value;
	}

	static getTimePeriod(milliseconds) {
		let value = '';
		let timeRanges = [
			{label: 'year', value: milliseconds / oneYear},
			{label: 'month', value: milliseconds / oneMonth},
			{label: 'week', value: milliseconds / oneWeek},
			{label: 'day', value: milliseconds / oneDay},
			{label: 'hour', value: milliseconds / oneHour},
			{label: 'min', value: milliseconds / oneMinute},
			{label: 'sec', value: milliseconds / oneSecond}
		];
		for (let i in timeRanges) {
			let label = timeRanges[i].label;
			let time = Math.floor(timeRanges[i].value);
			if (time >= 1) {
				value = time + ' ' + (time === 1 ? label : label + 's');
				break;
			}
		}
		return value;
	}

	static getMillisecondsWithTimeZoneOffset(dateString) {
		try {
			let local = LocalDateTime.parse(dateString);
			return convert(local).toEpochMilli();
		}
		catch (error) {
			return 0;
		}
	}

	static isTodayAfter(dateString) {
		const givenDate = new Date(dateString);
		const today = new Date();

		givenDate.setHours(0, 0, 0, 0);
		today.setHours(1, 0, 0, 0);

		return today > givenDate;
	}

}
