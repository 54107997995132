import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {CHANNEL_DOWNLOAD_ENDPOINT} from '../../../endpoints/ChannelMappingEndpoints';
import {CATEGORY_MAPPED_ACCOUNTS} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import ChannelHelper from '../../../helpers/ChannelHelper';
import DialogHelper from '../../../helpers/DialogHelper';
import DownloadHelper from '../../../helpers/DownloadHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import ReportHelper from '../../../helpers/ReportHelper';
import StringHelper from '../../../helpers/StringHelper';
import DownloadButton from '../../../ui/buttons/DownloadButton';
import SaveReportButton from '../../../ui/buttons/SaveReportButton';
import PagingBase from '../../../ui/lists/PagingBase';
import ReportMenu from '../shared/ReportMenu';

export class AccountListPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		let reportTypes = ReportHelper.mappedAccountsReportTypes;
		let currentReport;
		if (DialogHelper.savedReportData.settings) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, DialogHelper.savedReportData.settings.reportTitle); // from saved report
		}
		else if (this.reportType) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, this.reportType); // from report dropdown
		}
		if (!currentReport) {
			currentReport = reportTypes[0];
		}

		this.state.mappedAccountsReportTypes = reportTypes;
		this.state.currentReportType = currentReport;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {matchingType: currentReport.matchingType, channelReportType: currentReport.channelReportType};
		this.state.filterSelectorMounted = false;
		if (DialogHelper.savedReportData.settings) {
			this.initialFilterValues = DialogHelper.savedReportData.settings.filters;
		}
		this.showRecentViewed = currentReport.isRecentlyViewed;

		this.renderReportMenu = this.renderReportMenu.bind(this);
		this.onUpdateReportType = this.onUpdateReportType.bind(this);
	}

	componentDidMount() {
		this.fetchGlobalCompanyAccountColumns();
	}

	fetchGlobalCompanyAccountColumns() {
		let {currentReportType} = this.state;
		ChannelMappingEndpoints.getColumns({channelReportType: currentReportType.channelReportType})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getGlobalCompanyAccountsColumns', error);
		});
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get storageKeyBase() {
		let {currentReportType} = this.state;
		let reportType = currentReportType ? '_' + StringHelper.formatKey(currentReportType.reportTitle) : '';
		return 'mapped_accounts_' + reportType;
	}

	get title() {
		return this.renderReportMenu();
	}

	get icon() {
		return null;
	}

	renderReportMenu() {
		let {mappedAccountsReportTypes, currentReportType} = this.state;
		if (this.isSavedReport) return currentReportType.reportTitle;
		return (
			<ReportMenu outlinedLabel={'Report'}
						maxWidth={340}
						currentReportType={currentReportType}
						onUpdateReportType={this.onUpdateReportType}
						reportTypes={mappedAccountsReportTypes}
						data-cy={'accounts_report_menu'}/>
		);
	}

	onUpdateReportType(reportType) {
		if (reportType.isRecentlyViewed) {
			this.showRecentViewed = true;
		}
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.PATH(reportType.reportTitle));
	}

	get additionalToolbarButtons() {
		let {currentReportType, filterValues} = this.state;
		let buttons = [];
		if (currentReportType.isDownloadable) {
			buttons.push(<DownloadButton callingPage={this}/>);
		}
		buttons.push(<SaveReportButton reportType={this.reportType}
									   reportCategory={CATEGORY_MAPPED_ACCOUNTS}
									   reportSettings={{
										   reportSettings: currentReportType,
										   baseSettings: ChannelHelper.baseSettings(this.storageKeyBase),
										   filter: filterValues
									   }}
									   reportParams={{
										   filterTitle: this.getParamValue('filterTitle')
									   }}
									   showSaveDialog={this.showSaveDialog}/>);
		return buttons;
	}

	get downloadReportConfig() {
		let {currentReportType, columnData} = this.state;
		let params = this.processParameters(true);
		return {
			endpoint: CHANNEL_DOWNLOAD_ENDPOINT,
			channelReportType: currentReportType.channelReportType,
			matchingType: currentReportType.matchingType,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: currentReportType.downloadFileName,
			exportFields: DownloadHelper.getFields(columnData)
		};
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get filterSelectorConfig() {
		let config = super.filterSelectorConfig;
		if (config) {
			let {currentReportType} = this.state;
			config.inherentReportFilters = currentReportType.inherentReportFilters;
		}
		return config;
	}

	get sortClause() {
		if (this.showRecentViewed) {
			return 'viewedDate,desc';
		}
		return super.sortClause;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let {currentReportType, rowCount} = this.state;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{matchingType: currentReportType.matchingType, channelReportType: currentReportType.channelReportType},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			this.sortClause,
			rowCount)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
				this.showRecentViewed = false;
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		let {currentReportType, rowData} = this.state;
		if (rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						Sorry, there are no results for '{currentReportType.reportTitle}'
					</div>
					<div>
						Please try a different report.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	render() {
		if (this.isSavedReport) {
			return DialogHelper.renderSavedReportDialogButtons(super.render(), () => this.forceUpdate(), this.closeSavedReportDialog);
		}
		return super.render();
	}
}

AccountListPage.propTypes = {
	reportType: PropTypes.string,
	match: PropTypes.object.isRequired
};

export default connect()(AccountListPage);
