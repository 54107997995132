import {Email, Forum, Share} from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, {Fragment} from 'react';
import {Routes} from '../globals/Routes';
import {partnerTapTernaryLight} from '../styles/partnertap_theme';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import SupportButton from '../ui/buttons/SupportButton';
import {EMAIL_LABEL_STYLE} from '../ui/EmailInvite';
import EnvHelper from './EnvHelper';
import ReportHelper, {
	ASSET_TYPE_ACCOUNTS,
	ASSET_TYPE_LEADS,
	ASSET_TYPE_OPPS,
	CHANNEL_REPORT_TYPE_BASE_ACCOUNTS,
	CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES
} from './ReportHelper';

// common share options
export const SHARE_SUMMARY = 'SHARE_SUMMARY';

// account share options
export const SHARE_ACCOUNTS_ALL = 'SHARE_ACCOUNTS_ALL';
export const SHARE_ACCOUNTS_ALL_CUSTOMERS = 'SHARE_ACCOUNTS_ALL_CUSTOMERS';
export const SHARE_ACCOUNTS_ALL_PROSPECTS = 'SHARE_ACCOUNTS_ALL_PROSPECTS';
export const SHARE_ACCOUNTS_MATCHED_CUSTOMERS = 'SHARE_ACCOUNTS_MATCHED_CUSTOMERS';
export const SHARE_ACCOUNTS_MATCHED_PROSPECTS = 'SHARE_ACCOUNTS_MATCHED_PROSPECTS';
export const SHARE_ACCOUNTS_MUTUAL = 'SHARE_ACCOUNTS_MUTUAL';
export const SHARE_ACCOUNTS_MUTUAL_CUSTOMERS = 'SHARE_ACCOUNTS_MUTUAL_CUSTOMERS';
export const SHARE_ACCOUNTS_MUTUAL_PROSPECTS = 'SHARE_ACCOUNTS_MUTUAL_PROSPECTS';
export const SHARE_ACCOUNTS_OWNER_CUSTOMERS_PARTNER_PROSPECTS = 'SHARE_ACCOUNTS_OWNER_CUSTOMERS_PARTNER_PROSPECTS';
export const SHARE_ACCOUNTS_OWNER_PROSPECTS_PARTNER_CUSTOMERS = 'SHARE_ACCOUNTS_OWNER_PROSPECTS_PARTNER_CUSTOMERS';

export const ALL_ACCOUNT_SHARE_OPTIONS = [
	SHARE_SUMMARY,
	SHARE_ACCOUNTS_ALL,
	SHARE_ACCOUNTS_ALL_CUSTOMERS,
	SHARE_ACCOUNTS_ALL_PROSPECTS,
	SHARE_ACCOUNTS_MUTUAL,
	SHARE_ACCOUNTS_MATCHED_CUSTOMERS,
	SHARE_ACCOUNTS_MATCHED_PROSPECTS,
	SHARE_ACCOUNTS_MUTUAL_CUSTOMERS,
	SHARE_ACCOUNTS_MUTUAL_PROSPECTS,
	SHARE_ACCOUNTS_OWNER_CUSTOMERS_PARTNER_PROSPECTS,
	SHARE_ACCOUNTS_OWNER_PROSPECTS_PARTNER_CUSTOMERS
];

// opportunity share options
export const SHARE_OPPS_ALL = 'SHARE_OPPS_ALL';
export const SHARE_OPPS_ALL_OPEN = 'SHARE_OPPS_ALL_OPEN';
export const SHARE_OPPS_ALL_CLOSED = 'SHARE_OPPS_ALL_CLOSED';
export const SHARE_OPPS_MATCHED = 'SHARE_OPPS_MATCHED';
export const SHARE_OPPS_MUTUAL_CUSTOMERS = 'SHARE_OPPS_MUTUAL_CUSTOMERS';
export const SHARE_OPPS_MUTUAL_PROSPECTS = 'SHARE_OPPS_MUTUAL_PROSPECTS';

export const ALL_OPP_SHARE_OPTIONS = [
	SHARE_SUMMARY,
	SHARE_OPPS_ALL,
	SHARE_OPPS_ALL_OPEN,
	SHARE_OPPS_ALL_CLOSED,
	SHARE_OPPS_MATCHED,
	SHARE_OPPS_MUTUAL_CUSTOMERS,
	SHARE_OPPS_MUTUAL_PROSPECTS
];

export default class EcosystemShareHelper {

	static labelForShareAssetType(assetType, isPlural) {
		switch (assetType) {
			case ASSET_TYPE_ACCOUNTS:
				return isPlural ? 'Accounts' : 'Account';
			case ASSET_TYPE_OPPS:
				return isPlural ? 'Opportunities' : 'Opportunity';
			case ASSET_TYPE_LEADS:
				return isPlural ? 'Leads' : 'Lead';
			default:
				return 'Invalid assetType: ' + assetType;
		}
	}

	static labelForShareOption(shareOption) {
		switch (shareOption) {
			case SHARE_SUMMARY:
				return 'Summary only';

			case SHARE_ACCOUNTS_MUTUAL_CUSTOMERS:
				return 'Mutual customer accounts';
			case SHARE_ACCOUNTS_MUTUAL_PROSPECTS:
				return 'Mutual prospect accounts';
			case SHARE_ACCOUNTS_MUTUAL:
				return 'Matched accounts';
			case SHARE_ACCOUNTS_MATCHED_CUSTOMERS:
				return 'Matched customer accounts';
			case SHARE_ACCOUNTS_MATCHED_PROSPECTS:
				return 'Matched prospect accounts';
			case SHARE_ACCOUNTS_ALL:
				return 'All accounts';
			case SHARE_ACCOUNTS_ALL_CUSTOMERS:
				return 'All customer accounts';
			case SHARE_ACCOUNTS_ALL_PROSPECTS:
				return 'All prospect accounts';
			case SHARE_ACCOUNTS_OWNER_CUSTOMERS_PARTNER_PROSPECTS:
				return 'My customers that match partner prospects';
			case SHARE_ACCOUNTS_OWNER_PROSPECTS_PARTNER_CUSTOMERS:
				return 'My prospects that match partner customers';

			case SHARE_OPPS_ALL:
				return 'All opps';
			case SHARE_OPPS_ALL_OPEN:
				return 'Only open opps';
			case SHARE_OPPS_ALL_CLOSED:
				return 'Only closed opps';
			case SHARE_OPPS_MATCHED:
				return 'Only opps on matched accounts';
			case SHARE_OPPS_MUTUAL_CUSTOMERS:
				return 'Only opps on mutual customers';
			case SHARE_OPPS_MUTUAL_PROSPECTS:
				return 'Only opps on mutual prospects';

			default:
				return 'Summary only';
		}
	}

	static descriptionForShareOption(shareOption, shareType, partnerName) {
		let partnerNameCapitalized = partnerName.charAt(0).toUpperCase() + partnerName.slice(1);
		switch (shareOption) {
			case SHARE_SUMMARY:
				return 'Share only a high-level dashboard of ' + this.labelForShareAssetType(shareType, false).toLowerCase() +
					   ' overlap with ' + partnerName + '. ' + partnerNameCapitalized + ' will not be able to see your individual ' +
					   this.labelForShareAssetType(shareType, true).toLowerCase() + ' records.';
			case SHARE_ACCOUNTS_MUTUAL_CUSTOMERS:
				return 'Share only mutual customer accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will only see your customer accounts that are also their customers.';
			case SHARE_ACCOUNTS_MUTUAL_PROSPECTS:
				return 'Share only mutual prospect accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will only see your prospect accounts that are also their prospects.';
			case SHARE_ACCOUNTS_MUTUAL:
				return 'Share all overlapping accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all of your accounts that match their accounts.';
			case SHARE_ACCOUNTS_MATCHED_CUSTOMERS:
				return 'Share your overlapping customer accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will only see your customer accounts that match their customer or prospect accounts.';
			case SHARE_ACCOUNTS_MATCHED_PROSPECTS:
				return 'Share your overlapping prospect accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will only see your prospect accounts that match their customer or prospect accounts.';
			case SHARE_ACCOUNTS_ALL:
				return 'Share all of your accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all of your accounts whether they overlap with their accounts or are new greenfield accounts for their company.';
			case SHARE_ACCOUNTS_ALL_CUSTOMERS:
				return 'Share all of your customer accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all of your customer accounts whether they overlap with their accounts or are new greenfield accounts for their company.';
			case SHARE_ACCOUNTS_ALL_PROSPECTS:
				return 'Share all of your prospect accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all of your prospect accounts whether they overlap with their accounts or are new greenfield accounts for their company.';
			case SHARE_ACCOUNTS_OWNER_CUSTOMERS_PARTNER_PROSPECTS:
				return 'Share your customer accounts with ' + partnerName + ' that match their prospect accounts.';
			case SHARE_ACCOUNTS_OWNER_PROSPECTS_PARTNER_CUSTOMERS:
				return 'Share your prospect accounts with ' + partnerName + ' that match their customer accounts.';

			case SHARE_OPPS_ALL:
				return 'Share all of your opportunities with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all your opportunities whether they overlap with their accounts or not.';
			case SHARE_OPPS_ALL_OPEN:
				return 'Share all your open opportunities with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all your open opportunities whether they overlap with their accounts or not.';
			case SHARE_OPPS_ALL_CLOSED:
				return 'Share all your closed opportunities with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all your closed opportunities whether they overlap with their accounts or not.';
			case SHARE_OPPS_MATCHED:
				return 'Only share opportunities on accounts that match with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will see all opportunities on your accounts that match their accounts.';
			case SHARE_OPPS_MUTUAL_CUSTOMERS:
				return 'Only share opportunities on mutual customer accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will only see opportunities on your customer accounts that are also their customers.';
			case SHARE_OPPS_MUTUAL_PROSPECTS:
				return 'Only share opportunities on mutual prospect accounts with ' + partnerName + '. ' +
					   partnerNameCapitalized + ' will only see opportunities on your prospect accounts that are also their prospects.';

			default:
				return 'Unknown Sharing Type: ' + shareOption;
		}
	}

	static renderShareOptionsHelp(partnerName, shareType, shareOptions) {
		let padding = EnvHelper.isDesktop ? 10 : 2;
		let assetType;
		let sharingScopeData = [];
		if (shareType === ASSET_TYPE_ACCOUNTS) {
			assetType = 'account';
			sharingScopeData = [
				['Summary only', false, false, false, false, false],
				['All accounts', true, true, true, true, true],
				['All customer accounts', true, false, true, true, true],
				['All prospect accounts', false, true, true, true, true],
				['All matched accounts', true, true, true, true, false],
				['Matched customer accounts', true, false, true, true, false],
				['Matched prospect accounts', false, true, true, true, false],
				['Mutual customer accounts', true, false, true, false, false],
				['Mutual prospects accounts', false, true, false, true, false],
				['My customers that match partner prospects', true, false, false, true, false],
				['My prospects that match partner customers', false, true, true, false, false]
			];
		}
		else if (shareType === ASSET_TYPE_OPPS) {
			assetType = 'opp';
			sharingScopeData = [
				['Summary only', false, false, false, false, false],
				['All opps', true, true, true, true, true],
				['Only open opps', true, true, true, true, true],
				['Only closed opps', true, true, true, true, true],
				['Only opps on matched accounts', true, true, true, true, false],
				['Only opps on mutual customers', true, false, true, false, false],
				['Only opps on mutual prospects', false, true, false, true, false]
			];
		}
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 360, maxWidth: 600, margin: 'auto'}}>
				<div style={{display: 'flex', flexDirection: 'column', padding: 10}}>
					{shareOptions.map((shareOption) => {
						return (
							<Fragment key={shareOption}>
								<div style={{fontSize: 16, fontWeight: 'bold'}}>
									{EcosystemShareHelper.labelForShareOption(shareOption)}
								</div>
								<div style={{padding: 10, paddingTop: 0}}>
									{EcosystemShareHelper.descriptionForShareOption(shareOption, shareType, partnerName)}
								</div>
							</Fragment>
						);
					})}
				</div>
				<hr style={{height: 1, width: '80%'}}/>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: 10,
					paddingTop: 20,
					paddingBottom: 20,
					paddingLeft: EnvHelper.isDesktop ? 10 : 60 // we must compensate for weird mobile layout behavior
				}}>
					<div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 10}}>
						The following table displays the types of {assetType}s that are used by each {assetType} sharing scope.
					</div>
					<div>
						Please note that some options may not be available to your org and/or role.
					</div>
					<table border="1" cellPadding="0" cellSpacing="0">
						<thead>
						<tr>
							<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>{assetType} sharing scope</td>
							<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>My customers</td>
							<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>My prospects</td>
							<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>Partner customers</td>
							<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>Partner prospects</td>
							<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>Greenfield for partner</td>
						</tr>
						</thead>
						<tbody>
						{sharingScopeData.map((row, index) => {
							return (
								<tr key={index}>
									<td style={{padding: padding, textAlign: 'center', fontWeight: 'bold'}}>{row[0]}</td>
									<td style={{padding: padding, textAlign: 'center'}}>{row[1] ? 'X' : '-'}</td>
									<td style={{padding: padding, textAlign: 'center'}}>{row[2] ? 'X' : '-'}</td>
									<td style={{padding: padding, textAlign: 'center', background: partnerTapTernaryLight}}>{row[3] ? 'X' : '-'}</td>
									<td style={{padding: padding, textAlign: 'center', background: partnerTapTernaryLight}}>{row[4] ? 'X' : '-'}</td>
									<td style={{padding: padding, textAlign: 'center'}}>{row[5] ? 'X' : '-'}</td>
								</tr>
							);
						})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	static renderAccountShareMenu(shareOptions, shareOption) {
		if (!shareOptions) return;
		EcosystemShareHelper.inviteAccountShareOption = shareOption;
		let shareOptionData = [];
		ALL_ACCOUNT_SHARE_OPTIONS.forEach((shareOption) => {
			let isAvailable = shareOptions.find((availableShareOption) => availableShareOption === shareOption);
			shareOptionData.push({key: shareOption, disabled: !isAvailable});
		});
		return this.renderShareMenu('account',
			shareOptionData,
			EcosystemShareHelper.inviteAccountShareOption,
			(shareOption) => EcosystemShareHelper.inviteAccountShareOption = shareOption.target.value);
	}

	static renderOpportunityShareMenu(shareOptions, shareOption) {
		if (!shareOptions) return;
		EcosystemShareHelper.inviteOpportunityShareOption = shareOption;
		let shareOptionData = [];
		ALL_OPP_SHARE_OPTIONS.forEach((shareOption) => {
			let isAvailable = shareOptions.find((availableShareOption) => availableShareOption === shareOption);
			shareOptionData.push({key: shareOption, disabled: !isAvailable});
		});
		return this.renderShareMenu('opportunity',
			shareOptionData,
			EcosystemShareHelper.inviteOpportunityShareOption,
			(shareOption) => EcosystemShareHelper.inviteOpportunityShareOption = shareOption.target.value);
	}

	static renderShareMenu(assetTypeLabel, shareOptionData, defaultShareOption, onChangeFunction) {
		return (
			<div>
				<div style={EMAIL_LABEL_STYLE}>
					Choose your {assetTypeLabel} sharing scope
				</div>
				<FormControl sx={{width: 380}} size={'small'}>
					<Select defaultValue={defaultShareOption} onChange={onChangeFunction}>
						{shareOptionData.map((shareOption) => {
							return (
								<MenuItem key={shareOption.key} value={shareOption.key} disabled={shareOption.disabled}>
									{EcosystemShareHelper.labelForShareOption(shareOption.key)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
		);
	}

	static renderAdjustAction(partner) {
		return (
			<div style={{display: 'flex', justifyContent: 'center'}}>
				<PrimaryButton label={'ADJUST SHARE SETTINGS'} icon={<Share/>} onClick={() => {
					EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(partner.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARE.PAGE_NAME));
				}}/>
			</div>
		);
	}

	static renderReachOutActions(partner) {
		return (
			<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<PrimaryButton label={'COLLABORATE'}
							   icon={<Forum/>}
							   onClick={() => {
								   EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(partner.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.INTEL.PAGE_NAME));
							   }}/>
				<div style={{padding: 5}}/>
				<PrimaryButton label={'EMAIL'}
							   icon={<Email/>}
							   onClick={() => {
								   window.open('mailto:' + partner.emailAddress + '?subject=PartnerTap share settings');
							   }}/>
			</div>
		);
	}

	static renderNoOverlapMessaging(assetType, ownerShareOption, partnerShareOption, partner, filterType) {
		let assetTypeLabel = EcosystemShareHelper.labelForShareAssetType(assetType, true).toLowerCase();
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 10}}>
				<div style={{paddingBottom: 10}}>
					You're sharing
					<b> {EcosystemShareHelper.labelForShareOption(ownerShareOption).toUpperCase()}</b>
				</div>
				<div style={{paddingBottom: 10}}>
					{partner.firstName} is sharing
					<b> {EcosystemShareHelper.labelForShareOption(partnerShareOption).toUpperCase()}</b>
				</div>
				<div style={{paddingBottom: 10}}>
					You and {partner.firstName} currently
					have <b>NO {filterType ? ReportHelper.getReportFilterTitle(assetType, filterType).toUpperCase() : 'SHARED OPPORTUNITIES'}</b> using
					this combination.
				</div>
				<div style={{paddingBottom: 20}}>
					{EcosystemShareHelper.renderAdjustAction(partner)}
				</div>

				<div style={{paddingBottom: 10}}>
					Reach out to {partner.firstName} and find your valuable {assetTypeLabel}!
				</div>
				<div style={{paddingBottom: 20}}>
					{EcosystemShareHelper.renderReachOutActions(partner)}
				</div>

				<div style={{paddingBottom: 10}}>
					If you'd like help finding the best sharing solutions, please contact support.
				</div>
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<SupportButton/>
				</div>
			</div>
		);
	}

	static getInherentAccountTypeFilter(shareOption) {
		if (shareOption === SHARE_ACCOUNTS_ALL_CUSTOMERS || shareOption === SHARE_ACCOUNTS_MUTUAL_CUSTOMERS) return {isCustomer: true};
		if (shareOption === SHARE_ACCOUNTS_ALL_PROSPECTS || shareOption === SHARE_ACCOUNTS_MUTUAL_PROSPECTS) return {isCustomer: false};
		return null;
	}

	static getChannelReportType(shareAssetType) {
		if (shareAssetType === ASSET_TYPE_ACCOUNTS) return CHANNEL_REPORT_TYPE_BASE_ACCOUNTS;
		if (shareAssetType === ASSET_TYPE_OPPS) return CHANNEL_REPORT_TYPE_BASE_OPPORTUNITIES;
		return null;
	}

	static includesGreenfield(shareOption) {
		return shareOption === SHARE_ACCOUNTS_ALL || shareOption === SHARE_ACCOUNTS_ALL_CUSTOMERS || shareOption === SHARE_ACCOUNTS_ALL_PROSPECTS;
	}

	static includesOppsGreenfield(shareOption) {
		return shareOption === SHARE_OPPS_ALL || shareOption === SHARE_OPPS_ALL_OPEN || shareOption === SHARE_OPPS_ALL_CLOSED;
	}
}
