import {HelpOutline} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapSecondary} from '../../styles/partnertap_theme';
import Dialog from '../Dialog';

class InfoButton extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {showInfoDialog: false};
	}

	render() {
		let {showInfoDialog} = this.state;
		let messageElement;
		let {message} = this.props;
		if (typeof message === 'string') {
			messageElement = <div style={{maxWidth: 400}}>{message}</div>;
		}
		else {
			messageElement = message;
		}
		return (
			<IconButton style={{color: partnerTapSecondary}} onClick={() => this.setState({showInfoDialog: true})}>
				<HelpOutline/>
				{showInfoDialog &&
				 <Dialog title={'Info'} message={messageElement} yesAction={() => this.setState({showInfoDialog: false})}/>}
			</IconButton>
		);
	}
}

InfoButton.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};

export default InfoButton;
