import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as AdminEndpoints from '../../../endpoints/AdminEndpoints';
import {ADMIN_CURRENT_ORG_ID_KEY} from '../../../globals/StorageKeys';
import EnvHelper from '../../../helpers/EnvHelper';
import PersistenceHelper from '../../../helpers/PersistenceHelper';
import Loading from '../../../ui/Loading';
import PopoverSearchList from '../../../ui/PopoverSearchList';

class OrgSelector extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {orgList: null, selectedOrg: null};
		this.getOrganizations = this.getOrganizations.bind(this);
		this.restoreOrgSelection = this.restoreOrgSelection.bind(this);
		this.handleOrgSelection = this.handleOrgSelection.bind(this);
	}

	componentDidMount() {
		this.getOrganizations();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getOrganizations() {
		AdminEndpoints.fetchOrgList()
		.then((result) => {
			if (this.unmounted) return;
			let orgList = result.payload;
			if (this.props.addAllOption) {
				orgList.unshift({name: 'All Orgs', orgCode: ''});
			}
			this.restoreOrgSelection(orgList);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchOrgList', error);
		});
	}

	restoreOrgSelection(orgList) {
		let savedOrgId = PersistenceHelper.getValue(ADMIN_CURRENT_ORG_ID_KEY);
		let savedOrgSelection = orgList.find((org) => String(org.orgId) === savedOrgId);
		if (savedOrgSelection) {
			this.handleOrgSelection(savedOrgSelection, orgList);
		}
		else {
			this.handleOrgSelection(orgList[0], orgList);
		}
	}

	handleOrgSelection(org, orgList) {
		PersistenceHelper.setValue(ADMIN_CURRENT_ORG_ID_KEY, org.orgId);
		this.setState({selectedOrg: org, orgList: orgList});
		this.props.onOrgSelection(org, orgList);
	}

	render() {
		if (!this.state.orgList) return <Loading small={true} horizontal={true}>Loading...</Loading>;
		return (
			<PopoverSearchList label={'Select Organization'}
							   list={this.state.orgList}
							   preselectedItem={this.state.selectedOrg}
							   labelRenderer={(org) => org.name}
							   valueRenderer={(org) => org.orgCode}
							   onItemSelected={(org) => this.handleOrgSelection(org, this.state.orgList)}
							   searchByObjectKeys={['name']}
							   minWidth={300}/>
		);
	}
}

OrgSelector.propTypes = {
	onOrgSelection: PropTypes.func.isRequired,
	addAllOption: PropTypes.bool
};

export default OrgSelector;
