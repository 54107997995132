import ColumnHelper from '../helpers/ColumnHelper';
import EnvHelper from './EnvHelper';

export default class CsvHelper {
	static set ignoreBlankHeaders(ignore) {
		this.ignoreHeaderErrors = ignore;
	}

	static get ignoreBlankHeaders() {
		return this.ignoreHeaderErrors;
	}

	static removeMissingHeaders(headerList) {
		if (!headerList) return headerList;
		return headerList.filter((header) => header && header.indexOf('#') !== 0);
	}
}

function getPrettyKey(key, mapKeyAndPrettyName) {
	if ((mapKeyAndPrettyName === null || mapKeyAndPrettyName.has(key))) {
		return mapKeyAndPrettyName != null ? mapKeyAndPrettyName.get(key) : key;
	}
}

function applyFunction(key, data, mapKeyAndFunctionForData) {
	let itemResult = data;
	if (mapKeyAndFunctionForData != null && mapKeyAndFunctionForData.has(key)) {
		itemResult = mapKeyAndFunctionForData.get(key)(data);
		if (!itemResult) {
			console.error('Error from applyFunction: function for ' + key + ' does not exist.');
		}
	}
	return itemResult;
}

function convertArrayOfObjectsToCSV(data, set, drill, mapKeyAndPrettyName, mapKeyAndFunctionForData) {
	let result, ctr, columnDelimiter, lineDelimiter;
	if (data == null || !data.length) {
		console.error('Error from convertArrayOfObjectsToCSV: data is null');
		return null;
	}
	columnDelimiter = ',';
	lineDelimiter = '\n';

	let firstObject = data[0];
	let prettyKeys = mapKeyAndPrettyName === null ? Object.keys(firstObject) : [...mapKeyAndPrettyName.keys()];
	let allKeys = Object.keys(firstObject);
	let keys = [];
	result = '';
	for (let i = 0; i < allKeys.length; i++) {
		// check if this key needs to be drilled into
		if (drill !== null && drill.has((allKeys[i]))) {
			keys.push(allKeys[i]);
			let drillObject = JSON.parse(firstObject[allKeys[i]]);
			if (!drillObject) continue;
			let drillKeys = Object.keys(drillObject);

			// append the drilled in keys
			// since drill down fields are dynamic, we don't have pretty names
			for (let i = 0; i < drillKeys.length; i++) {
				result += drillKeys[i] + ',';
			}
			continue;
		}
		if ((set === null || !set.has(allKeys[i])) &&
			firstObject[allKeys[i]] != null && prettyKeys.indexOf(allKeys[i]) >= 0) {
			keys.push(allKeys[i]);
			result += getPrettyKey(allKeys[i], mapKeyAndPrettyName) + ',';
		}
	}
	result = result.replace(/,(\s+)?$/, '');
	result += lineDelimiter;

	data.forEach((item) => {
		ctr = 0;
		keys.forEach((key) => {
			// check if this key needs to be drilled into
			if (drill !== null && drill.has((key))) {
				let drillObject = JSON.parse(item[key]);
				if (!drillObject) return;
				let drillKeys = Object.keys(drillObject);
				drillKeys.forEach((drillKey) => {
					let data = drillObject[drillKey];

					// extract the data from the drilled object and append it to the result
					let drillItemResult = applyFunction(drillKey, data, mapKeyAndFunctionForData);
					if (ctr > 0) result += columnDelimiter;
					if (!drillItemResult) {
						console.error('Error from convertArrayOfObjectsToCSV: item does not exist for ' + drillKey);
					}
					else {
						result += '"' + drillItemResult.toString().replace(/"/g, '\\"') + '"';
					}
					ctr++;
				});
				return;
			}
			if (ctr > 0) result += columnDelimiter;
			let itemResult = item[key];
			itemResult = applyFunction(key, itemResult, mapKeyAndFunctionForData);
			if (itemResult) {
				result += '"' + itemResult.toString().replace(/"/g, '\\"') + '"';
			}
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

export function downloadCSVSelectedColumns(data, filename, columnData, selectedColumns, customRender) {
	let headerLine = [];
	let fileData = '';
	let columnDelimiter = ',';
	let lineDelimiter = '\n';
	columnData.forEach((column) => {
		if (selectedColumns) {
			let key = column.key;
			if (column.isFromOtherFields) {
				key = column.otherFieldsPrefix + '->' + key;
			}
			let selected = selectedColumns.indexOf(key) > -1;
			if (selected) {
				column.selected = true;
				headerLine.push('"' + column.title + '"');
			}
			else {
				column.selected = false;
			}
		}
		else {
			column.selected = true;
			headerLine.push('"' + column.title + '"');
		}
	});

	fileData = headerLine.join(',') + lineDelimiter;

	data.forEach((row) => {
		let rowValues = [];
		columnData.filter((column) => column.selected).forEach((column) => {
			let rawValue;
			if (customRender) {
				rawValue = customRender(row[column.key], column.key);
			}
			else if (column.renderFunction) {
				rawValue = column.renderFunction(row[column.key]);
			}
			else {
				rawValue = row[column.key];
			}

			if (ColumnHelper.isBoolean(column)) {
				rawValue = simpleBooleanRender(row[column.key]);
			}

			if (typeof rawValue === 'undefined' || rawValue === null) {
				rawValue = '';
			}

			let cleanValue = rawValue.toString()
			.replaceAll(/\\n/g, ' ')
			.replaceAll(/\\r/g, ' ')
			.replaceAll('"', '""');
			let MAX_CELL_SIZE = 30000;
			cleanValue = cleanValue.substring(0, Math.min(MAX_CELL_SIZE, cleanValue.length));
			rowValues.push('"' + cleanValue + '"');
		});
		fileData += rowValues.join(columnDelimiter) + lineDelimiter;
	});
	triggerFileDownload(fileData, filename);
}

function simpleBooleanRender(displayValue) {
	if (typeof displayValue === 'string') {
		displayValue = displayValue.toUpperCase() === 'TRUE';
	}
	else if (!displayValue) {
		displayValue = false;
	}
	return displayValue;
}

export function downloadCSV(data, filename, setExcluded = null, mapKeyAndPrettyName = null, mapKeyAndFunctionForData = null, setDrillField = null) {
	triggerFileDownload(convertArrayOfObjectsToCSV(data, setExcluded, setDrillField, mapKeyAndPrettyName, mapKeyAndFunctionForData), filename);
}

export function triggerFileDownload(csv, filename) {
	if (csv == null) return;
	filename = filename || 'export.csv';
	let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename);
	}
	else {
		let link = document.createElement('a');
		if (link.download !== undefined) {
			// Browsers that support HTML5 download attribute
			let url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', filename);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}

export function fileDownload(fileName) {
	let link = document.createElement('a');
	link.href = EnvHelper.profileImageUri.substring(0, EnvHelper.profileImageUri.length - 'profiles'.length) + fileName;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
