import {Group, Person} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Routes} from '../../../../globals/Routes';
import Loading from '../../../../ui/Loading';
import SubNav from '../../../../ui/navigation/SubNav';
import SavedReportsPage from './SavedReportsPage';

class SavedReportsRouter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: false};
		this.subNavData = [
			{
				label: 'Personal',
				MaterialIcon: Person,
				route: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.PERSONAL.ROUTE
			},
			{
				label: 'Organization',
				MaterialIcon: Group,
				route: Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.ORG.ROUTE
			}
		];
	}

	render() {
		if (this.state.loading) return <Loading>Loading Saved Reports...</Loading>;
		return (
			<div key={this.props.location.pathname} style={{display: 'flex', flexDirection: 'column'}}>
				<SubNav subNavData={this.subNavData}/>
				<Switch>
					<Route path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.PERSONAL.ROUTE} component={SavedReportsPage}/>
					<Route path={Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.ORG.ROUTE} component={SavedReportsPage}/>
				</Switch>
			</div>
		);
	}
}

SavedReportsRouter.propTypes = {
	authState: PropTypes.object,
	managerAnalyticsState: PropTypes.object
};

export default withRouter(connect()(SavedReportsRouter));
