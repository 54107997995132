import {CHANNEL_MAPPER} from '../globals/Enums';
import * as ApiHelper from '../helpers/ApiHelper';
import {SHARE_SUMMARY} from '../helpers/EcosystemShareHelper';
import {PRODUCT_CHANNEL_ECOSYSTEM} from '../helpers/FeatureHelper';

export function addPartnerWithEmail(emailList, userNote, shareOpps, productCode, accountsShareOption, oppsShareOption) {
	if (productCode === CHANNEL_MAPPER) productCode = PRODUCT_CHANNEL_ECOSYSTEM;
	let endpoint = '/partner-invites',
		payload = {
			inviteeEmailAddresses: emailList,
			userNote: userNote ? userNote : '',
			shareOpps: Boolean(shareOpps),
			productCode: productCode,
			accountsShareOption: accountsShareOption ? accountsShareOption : SHARE_SUMMARY,
			oppsShareOption: oppsShareOption ? oppsShareOption : SHARE_SUMMARY
		};
	return ApiHelper.sendPost(endpoint, payload);
}
