import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PartnersEndpoints from '../../endpoints/PartnersEndpoints';
import {INVITE_STATUS_ACCEPTED, INVITE_STATUS_DENIED, INVITE_STATUS_REQUESTED} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapStroke, partnerTapTernary} from '../../styles/partnertap_theme';
import Dialog from '../Dialog';
import ScrimMessage from '../messages/ScrimMessage';
import CancelButton from './CancelButton';
import PrimaryButton from './PrimaryButton';
import AuthHelper from '../../helpers/AuthHelper';
import LinkButton from './LinkButton';

class PendingButton extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			showConfirmDeclineDialog: false,
			updatingPositive: (this.props.itemData && this.props.itemData.updatingPositive) ||
							  (this.props.partnerList && this.props.partnerList.length && this.props.partnerList[0].updatingPositive) ||
							  false,
			updatingNegative: false
		};

		this.onAcceptInvitation = this.onAcceptInvitation.bind(this);
		this.onDeclineInvitation = this.onDeclineInvitation.bind(this);
		this.onCancelDeclinePartner = this.onCancelDeclinePartner.bind(this);
		this.onConfirmDeclinePartner = this.onConfirmDeclinePartner.bind(this);
		this.updatePartner = this.updatePartner.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onAcceptInvitation() {
		this.updatePartner(INVITE_STATUS_ACCEPTED, true);
	}

	onDeclineInvitation() {
		this.setState({showConfirmDeclineDialog: true});
	}

	onCancelDeclinePartner() {
		this.setState({showConfirmDeclineDialog: false});
	}

	onConfirmDeclinePartner() {
		this.setState({showConfirmDeclineDialog: false});
		this.updatePartner(INVITE_STATUS_DENIED, false);
	}

	updatePartner(status, shareOpps) {
		let {itemData} = this.props;
		this.setState({updatingPositive: status === INVITE_STATUS_ACCEPTED, updatingNegative: status !== INVITE_STATUS_ACCEPTED});
		if (this.isSingle) {
			PartnersEndpoints.updatePartner(itemData.partnerId, status, shareOpps, itemData.productCode)
			.then((result) => {
					if (this.unmounted) return;
					if (status === INVITE_STATUS_ACCEPTED) {
						if (EnvHelper.inChannelEcosystemProduct) {
							this.setState({showRedirectMessage: true});
							setTimeout(() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(itemData.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARE.PAGE_NAME)), 3000);
						}
					}
					itemData.partnerStatus = status;
					this.forceUpdate();
				}
			)
			.catch((error) => {
				this.setState({updatingPositive: false, updatingNegative: false});
				EnvHelper.serverError('Error from updatePartner', error);
			});
		}
		else {
			if (this.props.acceptAllClicked) {
				this.props.acceptAllClicked();
			}

			let invitesPayload = this.props.partnerList.map((partner) => {
				partner.partnerStatus = status;
				return {
					'ownerId': AuthHelper.userPerson.id,
					'personId': partner.personId,
					'partnerId': partner.partnerId,
					'status': partner.partnerStatus,
					'sharedOpp': shareOpps
				};
			});
			let payload = {
				'partnerRequestDtos': invitesPayload
			};

			PartnersEndpoints.updatePartners(payload)
			.then((result) => {
				if (this.unmounted) return;
				if (this.props.updatePage) {
					this.props.updatePage();
				}
			})
			.catch((error) => {
				EnvHelper.serverError('Error from updatePartners', error);
			});
		}
	}

	get isSingle() {
		return this.props.itemData || !this.props.partnerList;
	}

	get pendingInviteCount() {
		let count = 0;
		if (this.props.partnerList) {
			this.props.partnerList.forEach((partner) => {
				if (partner.partnerStatus === INVITE_STATUS_REQUESTED) count++;
			});
		}
		else if (this.props.itemData) {
			count++;
		}
		return count;
	}

	render() {
		if (this.state.showRedirectMessage) {
			return <ScrimMessage message={'Navigating to Share settings...'}/>;
		}

		let dialog = '';
		if (this.state.showConfirmDeclineDialog) {
			dialog = <Dialog title={'Please Confirm'}
							 message={'Are you sure you want to decline this partnership?'}
							 noAction={this.onCancelDeclinePartner}
							 yesAction={this.onConfirmDeclinePartner}/>;
		}

		let pendingInviteCount = this.pendingInviteCount;

		let {useLinkButton} = this.props;
		const ButtonForAccept = useLinkButton ? LinkButton : PrimaryButton;
		const ButtonForCancel = useLinkButton ? LinkButton : CancelButton;
		return (
			<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
				{this.state.updatingPositive &&
				 <h3 style={{padding: 16, color: partnerTapTernary}}>Accepted</h3>}
				{this.state.updatingNegative &&
				 <h3 style={{paddingLeft: 5, paddingRight: 10, color: partnerTapStroke}}>Declined</h3>}
				{(!this.state.updatingPositive && !this.state.updatingNegative) &&
				 <div style={{display: 'flex', gap: 10}}>
					 {this.isSingle &&
					  <ButtonForCancel label={'DECLINE'} onClick={this.onDeclineInvitation} disabled={EnvHelper.isSpoofing}/>}
					 {pendingInviteCount > 0 &&
					  <ButtonForAccept label={this.isSingle ? 'ACCEPT' : 'ACCEPT ALL (' + pendingInviteCount + ')'}
									   onClick={this.onAcceptInvitation}
									   disabled={EnvHelper.isSpoofing}/>}
				 </div>}
				{dialog}
			</div>
		);
	}
}

PendingButton.propTypes = {
	itemData: PropTypes.shape({
		id: PropTypes.string,
		partnerName: PropTypes.string,
		updatingPositive: PropTypes.bool
	}),
	partnerList: PropTypes.array,
	updatePage: PropTypes.func,
	acceptAllClicked: PropTypes.func,
	useLinkButton: PropTypes.bool
};

export default connect()(PendingButton);
