import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_SALES_NETWORK_ACCESS} from '../../helpers/FeatureHelper';
import NavHelper from '../../helpers/NavHelper';
import * as Notifications from '../../helpers/NotificationHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapQuaternary} from '../../styles/partnertap_theme';
import SideNav from '../../ui/navigation/SideNav';
import TopBar from '../../ui/TopBar';
import ProfilePage from '../profile/ProfilePage';
import IntelPage from '../shared/IntelPage';
import SettingsPage from '../shared/SettingsPage';
import AccountDetailsRouter from './accounts/AccountDetailsRouter';
import AccountsPage from './accounts/AccountsPage';
import FirstLoginStatus from './FirstLoginStatus';
import PartnerDetailsRouter from './partners/PartnerDetailsRouter';
import PartnerInviteHistoryPage from './partners/PartnerInviteHistoryPage';
import PartnerInvitePage from './partners/PartnerInvitePage';
import PartnersPage from './partners/PartnersPage';
import TapPage from './tap/TapPage';
import LinkButton from '../../ui/buttons/LinkButton';
import MessageBox from '../../ui/messages/MessageBox';

class SalesNetworkProduct extends Component {

	constructor(props, context) {
		super(props, context);
		try {
			Notifications.handleDeepLinks(props.authState.person, props.dispatch);
		}
		catch (error) {
			console.error('Error handing deep link', error);
		}
		this.state = {isMobileNavOpen: false};
		this.logoutFromApp = this.logoutFromApp.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	logoutFromApp() {
		this.setState({showError: false});
		this.props.dispatch(AuthActions.logout());
		EnvHelper.push(Routes.LOGOUT.ROUTE);
	}

	get routeSwitch() {
		return (
			<Switch>
				<Route exact path={Routes.DASHBOARD.TAP.SHARE_PARTNERS.ROUTE} component={TapPage}/>
				<Route path={Routes.DASHBOARD.TAP.ROUTE} component={TapPage}/>

				<Route path={Routes.DASHBOARD.INTEL.THREAD.ROUTE} component={IntelPage}/>
				<Route path={Routes.DASHBOARD.INTEL.ROUTE} component={IntelPage}/>

				<Route path={Routes.DASHBOARD.ACCOUNTS.ACCOUNT.INTEL.ROUTE} component={AccountDetailsRouter}/>
				<Route path={Routes.DASHBOARD.ACCOUNTS.ACCOUNT.ROUTE} component={AccountDetailsRouter}/>
				<Route path={Routes.DASHBOARD.ACCOUNTS.ALL.ROUTE} component={AccountsPage}/>
				<Route path={Routes.DASHBOARD.ACCOUNTS.OPEN.ROUTE} component={AccountsPage}/>
				<Route path={Routes.DASHBOARD.ACCOUNTS.ROUTE} component={AccountsPage}/>

				<Route exact path={Routes.DASHBOARD.PARTNERS.INVITE.ROUTE} component={PartnerInvitePage}/>

				<Route exact path={Routes.DASHBOARD.PARTNERS.INVITE.HISTORY.ROUTE} component={PartnerInviteHistoryPage}/>
				<Route path={Routes.DASHBOARD.PARTNERS.PARTNER.INTEL.ROUTE} component={PartnerDetailsRouter}/>
				<Route path={Routes.DASHBOARD.PARTNERS.PARTNER.ROUTE} component={PartnerDetailsRouter}/>
				<Route path={Routes.DASHBOARD.PARTNERS.ROUTE} component={PartnersPage}/>

				<Route path={Routes.DASHBOARD.PROFILE.PARTNER.ROUTE} component={ProfilePage}/>
				<Route path={Routes.DASHBOARD.SETTINGS.ROUTE} component={SettingsPage}/>

				<Route path={Routes.DASHBOARD.ROUTE} component={PartnersPage}/>
			</Switch>
		);
	}

	get isProfileRoute() {
		return this.props.location.pathname.indexOf(Routes.DASHBOARD.PROFILE.ROUTE) === 0;
	}

	render() {
		let hasProfile = this.props.authState && this.props.authState.profile;
		let {isMobileNavOpen} = this.state;
		return (
			<div key={EnvHelper.path} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<TopBar/>
				<FirstLoginStatus/>
				<div style={{flex: 1, display: 'flex', backgroundColor: partnerTapQuaternary}}>
					<div key={EnvHelper.path} className={'printNone'}>
						{hasProfile &&
						 <SideNav navButtonData={NavHelper.getSalesNetworkData(this.props)}
								  isMobileNavOpen={isMobileNavOpen}
								  onToggleMobileNavOpen={(state) => {
									  if (this.unmounted) return;
									  this.setState({isMobileNavOpen: state});
								  }}/>}
					</div>
					<div className={'printFullWidth'} style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
						{(FeatureHelper.isFeatureEnabled(FEATURE_SALES_NETWORK_ACCESS) || this.isProfileRoute) ?
							this.routeSwitch :
							<MessageBox title={'Sales Network'} hideIcon={true}>
								<div>
									With PartnerTap’s Sales Network product your direct sales and channel sales reps can connect directly with partners in
									digital deal rooms to strategize and share information about specific accounts and opportunities.
								</div>
								<div>
									Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																									onClick={() => window.open('https://partnertap.com/support/')}/> for
									more information.
								</div>
							</MessageBox>}
					</div>
				</div>
			</div>
		);
	}
}

SalesNetworkProduct.propTypes = {
	authState: PropTypes.object.isRequired,
	partnerRequestCount: PropTypes.number.isRequired,
	location: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		partnerRequestCount: state.notificationsState.partnerRequestCount
	};
}

export default withRouter(connect(mapStateToProps)(SalesNetworkProduct));
