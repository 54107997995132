import * as ApiHelper from '../helpers/ApiHelper';

export function excludedEmailAddress(emailAddress, emailKey, action) {
	let payload = {
		emailAddress: emailAddress,
		emailKey: emailKey,
		action: action
	};
	return ApiHelper.sendPost('/emails/blacklist', payload);
}
