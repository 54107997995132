import _ from 'lodash';
import * as AuthEndpoints from '../endpoints/AuthEndpoints';
import * as AuthActions from '../redux/AuthActions';
import * as NotificationActions from '../redux/NotificationActions';
import {partnerTapPrimary} from '../styles/partnertap_theme';
import EnvHelper from './EnvHelper';
import PersistenceHelper from './PersistenceHelper';

export function handleDeepLinks(person, dispatch) {
	if (EnvHelper.isCordova && person && dispatch) {
		console.info('----- pass check 1');
		registerForNotifications()
		.then((result) => {
			console.info('----- pass check 2');
			result.push.on('notification', (data) => {
				let deepLink = data.additionalData.deeplink;
				console.info('----- push to ' + deepLink);
				if (!data.additionalData.foreground && !_.isEmpty(deepLink)) {
					PersistenceHelper.storeReferringEmailId(deepLink);
					AuthActions.loginPerson(person);
					console.info('----- push happening');
					EnvHelper.push(deepLink);
				}
				else {
					console.info('----- push not happening, handle internal ' + JSON.stringify(data));
					dispatch(NotificationActions.receivedNotification(data));
				}
			});
			result.push.on('error', function(e) {
				console.info('----- Notification Error', e);
			});
			console.info('----- result data: ' + result.data.registrationId);
			return AuthEndpoints.updateDevices(person.id, result.data.registrationId);
		})
		.then((result) => {
			console.info('----- Done updating devices ' + JSON.stringify(result));
		})
		.catch((error) => {
			EnvHelper.reportError('Error during device registration', error.message);
		});
	}
	else {
		console.info('deep links are disabled');
	}
}

export function registerForNotifications() {
	return new Promise(function(resolve) {
		let push = PushNotification.init({
			ios: {
				alert: 'true',
				badge: 'true',
				sound: 'true',
				clearBadge: 'true'
			},
			android: {
				senderID: '489641788638',
				clearBadge: 'true',
				glyph: 'notification_icon',
				iconColor: partnerTapPrimary,
				color: partnerTapPrimary,
				smallIcon: 'res://notification_icon'
			}
		});

		push.on('registration', function(data) {
			resolve({
				'data': data,
				'push': push
			});
		});
	});
}