import {withRouter} from 'react-router-dom';
import {CATEGORY_COMPANY_PARTNER_OPPS} from '../../../../globals/Enums';
import ReportHelper, {ASSET_TYPE_OPPS} from '../../../../helpers/ReportHelper';
import PartnerOrgPagingBase from './PartnerOrgPagingBase';

class PartnerOrgOppsPage extends PartnerOrgPagingBase {

	constructor(props, context) {
		super(props, context, ASSET_TYPE_OPPS, ReportHelper.companyPartnerOpportunityReportTypes, CATEGORY_COMPANY_PARTNER_OPPS);
	}

	get storageKeyBase() {
		return this.getStorageKeyBase('opps');
	}
}

export default withRouter(PartnerOrgOppsPage);
