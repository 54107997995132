import {Done, Edit, Face} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ProfilesEndpoints from '../../endpoints/ProfilesEndpoints';
import EnvHelper from '../../helpers/EnvHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import Loading from '../../ui/Loading';
import PageHeader from '../../ui/PageHeader';
import ProfileInfo from './ProfileInfo';

export class ProfilePage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: true,
			isEditing: false,
			profile: null,
			currentPersonId: this.props.match.params.personId ? this.props.match.params.personId : this.props.authState.person.id
		};
		this.onEditProfile = this.onEditProfile.bind(this);
	}

	componentDidMount() {
		if (this.state.currentPersonId) {
			this.getProfile(this.state.currentPersonId);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getProfile(personId) {
		ProfilesEndpoints.fetchProfile(personId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({loading: false, profile: result.payload});
		});
	}

	onEditProfile() {
		this.setState({isEditing: !this.state.isEditing});
	}

	render() {
		if (this.state.loading) return <Loading>Loading Profile...</Loading>;
		let isEditing = this.state && this.state.isEditing;
		let {personId} = this.state.profile;
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<PageHeader title={'Profile'} icon={<Face fontSize={'large'}/>}>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<PrimaryButton label={this.state.isEditing ? 'Done' : 'Edit'}
									   icon={this.state.isEditing ? <Done style={{color: partnerTapWhite}}/> : <Edit style={{color: partnerTapWhite}}/>}
									   onClick={this.onEditProfile}
									   disabled={EnvHelper.isSpoofing}/>
					</div>
				</PageHeader>
				<ScrollingContainer divId={'profile_page'}>
					<div style={{overflow: 'scroll'}}>
						<ProfileInfo personId={personId} profile={this.state.profile} isEditing={isEditing}/>
					</div>
				</ScrollingContainer>
			</div>
		);
	}
}

ProfilePage.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(ProfilePage));
