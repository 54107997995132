import React, {Fragment} from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import CoSellEmailVerificationBox from '../CoSellEmailVerificationBox';
import * as CoSellEngineHelper from '../CoSellEngineHelper';
import {WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL, WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL_NONE} from '../CoSellEngineHelper';

export class WorkflowDistributeRecordsExternal {

	constructor(actionListInfo, workflow, collatedWorkflowRecords) {
		this.actionListInfo = actionListInfo;
		this.workflow = workflow;
		this.collatedWorkflowRecords = collatedWorkflowRecords;

		this.getCurrentSequence = this.getCurrentSequence.bind(this);
		this.onSelectSequence = this.onSelectSequence.bind(this);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsDistributeRecordsExternal;
	}

	get steps() {
		return [{label: 'Sequence'}, {label: 'Recipients'}, {label: 'Messages'}];
	}

	getCurrentSequence(adminSequenceConfig) {
		return adminSequenceConfig.workflowSequenceDistributeRecordsExternal === this.actionListInfo.workflowSequenceDistributeRecordsExternal ||
			   adminSequenceConfig.workflowSequenceDistributeRecordsExternal === this.workflow.sequence;
	}

	onSelectSequence(adminSequenceConfig) {
		this.actionListInfo.workflowSequenceDistributeRecordsExternal = adminSequenceConfig.workflowSequenceDistributeRecordsExternal;
	}

	get isUnavailable() {
		return null;
	}

	configureEmail(emailWorkflowEditor, currentConfigStep) {
		let {workflowConfigEmailDistributeRecordsExternal, workflowSequenceDistributeRecordsExternal} = this.actionListInfo;
		switch (currentConfigStep) {
			case 1: {
				return (
					<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
						{emailWorkflowEditor.renderFromEditor()}
						{workflowSequenceDistributeRecordsExternal === WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL &&
						 emailWorkflowEditor.renderToPartnerEditor()}
					</div>
				);
			}
			case 2: {
				return emailWorkflowEditor.renderEmailPreviewTile(workflowConfigEmailDistributeRecordsExternal, workflowSequenceDistributeRecordsExternal, 1);
			}
		}
	}

	validate(currentWorkflowStep) {
		let {workflowConfigEmailDistributeRecordsExternal, workflowSequenceDistributeRecordsExternal} = this.actionListInfo;
		switch (currentWorkflowStep) {
			case 0:
				return workflowSequenceDistributeRecordsExternal !== WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL_NONE;
			case 1:
			case 2:
				return CoSellEngineHelper.validateWorkflowConfigEmail(workflowConfigEmailDistributeRecordsExternal, false, true);
		}
	}

	confirm() {
		let partnerEmailAddresses = Object.keys(this.collatedWorkflowRecords.distinctPartnerEmails);
		return (
			<Fragment>
				<div>
					Send to {partnerEmailAddresses.length} Partner{partnerEmailAddresses.length === 1 ? '' : 's'}
				</div>
				<CoSellEmailVerificationBox emailAddresses={partnerEmailAddresses}/>
			</Fragment>
		);
	}
}
