import React, {Component} from 'react';

export default class AppErrorBoundary extends Component {

	static getDerivedStateFromError(error) {
		return {runtimeError: error};
	}

	constructor(props) {
		super(props);
		this.state = {runtimeError: false};
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
	}

	render() {
		if (this.state.runtimeError) {
			return <div>Sorry went wrong: {this.state.runtimeError}</div>;
		}
		return this.props.children;
	}
}
