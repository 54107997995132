import {FilterList, Help, Hub, JoinInner, Mediation, Share} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import EcosystemShareHelper, {SHARE_SUMMARY} from '../../../../helpers/EcosystemShareHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_GREENFIELD_ACCOUNT_SHARING,
	FEATURE_CHANNEL_GREENFIELD_OPP_SHARING,
	FEATURE_CHANNEL_SHARE_OPPS
} from '../../../../helpers/FeatureHelper';
import {ASSET_TYPE_ACCOUNTS, ASSET_TYPE_LEADS, ASSET_TYPE_OPPS} from '../../../../helpers/ReportHelper';
import UpsellHelper from '../../../../helpers/UpsellHelper';
import * as AuthActions from '../../../../redux/AuthActions';
import {partnerTapInactive, partnerTapPrimary, partnerTapSecondary, partnerTapWhite} from '../../../../styles/partnertap_theme';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import Dialog from '../../../../ui/Dialog';
import Upsell from '../../../../ui/Upsell';
import PartnerConnectionShareAdvanced from './PartnerConnectionShareAdvanced';

const NONE = 'None';

class PartnerConnectionShareEditor extends Component {

	constructor(props, context) {
		super(props, context);

		this.dirtyColumns = [];
		this.state = {isDirty: false, leaveToPath: null, leaveConfirmed: false, showUpsellForOppsSharing: false};

		this.updateShareOption = this.updateShareOption.bind(this);
		this.updateShareFilters = this.updateShareFilters.bind(this);
		this.saveShareSettings = this.saveShareSettings.bind(this);
		this.navigateToPartnerShared = this.navigateToPartnerShared.bind(this);
		this.updateShareSettings = this.updateShareSettings.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get partnerPersonId() {
		return this.props.partner.personId;
	}

	updateShareOption(selectionEvent) {
		this.props.shareData.shareSettings.filters = {};
		this.props.shareData.shareSettings.ownerAssetShareOption = selectionEvent.target.value;
		this.setState({isDirty: true});
		this.props.setDirtyFunction(true);
	}

	updateShareColumn(column) {
		if (!column.editable) return;
		column.shared = !column.shared;
		if (this.dirtyColumns.indexOf(column) === -1) {
			this.dirtyColumns.push(column);
		}
		this.setState({isDirty: true});
		this.props.setDirtyFunction(true);
	}

	updateShareFilters(filters) {
		this.props.shareData.shareSettings.filters = filters;
		this.setState({isDirty: true});
		this.props.setDirtyFunction(true);
	}

	updateShareSettings() {
		let {shareAssetType} = this.props.shareData;
		if (shareAssetType === ASSET_TYPE_OPPS && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_SHARE_OPPS)) {
			this.setState({showUpsellForOppsSharing: true});
			this.props.setDirtyFunction(false);
		}
		else {
			this.saveShareSettings();
		}
	}

	saveShareSettings() {
		if (this.state.isDirty) {
			setTimeout(() => {
				this.setState({isDirty: false});
				this.dirtyColumns = [];
				this.props.setDirtyFunction(false);
			});

			let {shareAssetType, shareSettings} = this.props.shareData;
			ChannelMappingEndpoints.saveShareSettings(this.partnerPersonId, shareSettings.ownerAssetShareOption, shareSettings.filters, this.dirtyColumns, shareAssetType)
			.then((result) => {
				if (this.unmounted) return;
				EnvHelper.dispatch(AuthActions.refreshApp()); // refresh the app to update share settings messaging in PartnerHeader
			})
			.catch((error) => {
				console.error('Error saving share settings', error);
			});
		}
	}

	get allowColumnsHelp() {
		let {sharableColumns} = this.props.shareData;
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<div style={{display: 'flex', flexDirection: 'column', padding: 10, fontSize: 16}}>
					Your company allows these additional columns to be shared with your partners
					<div style={{paddingTop: 10, paddingLeft: 20}}>
						<ul>
							{sharableColumns.map((sharedColumn, index) => {
								return (
									<li key={index} style={{fontSize: 14, fontWeight: 'bold'}}>
										{sharedColumn.prettyName}
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		);
	}

	get assetType() {
		let {shareAssetType} = this.props.shareData;
		if (shareAssetType === ASSET_TYPE_ACCOUNTS) {
			return 'Account';
		}
		else if (shareAssetType === ASSET_TYPE_LEADS) {
			return 'Lead';
		}
		else if (shareAssetType === ASSET_TYPE_OPPS) {
			return 'Opp';
		}
		return 'Invalid assetType: ' + shareAssetType;
	}

	get iconForSharingType() {
		let {shareAssetType} = this.props.shareData;
		if (shareAssetType === ASSET_TYPE_ACCOUNTS) {
			return <JoinInner/>;
		}
		else if (shareAssetType === ASSET_TYPE_LEADS) {
			return <Mediation/>;
		}
		else if (shareAssetType === ASSET_TYPE_OPPS) {
			return <Hub/>;
		}
		return null;
	}

	navigateToPartnerShared() {
		let {shareAssetType} = this.props.shareData;
		if (shareAssetType === ASSET_TYPE_ACCOUNTS) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(this.props.partner.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_ACCOUNTS.PAGE_NAME));
		}
		else if (shareAssetType === ASSET_TYPE_OPPS) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.PATH(this.props.partner.partnerId, Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNER.SHARED_OPPORTUNITIES.PAGE_NAME));
		}
	}

	renderShareOptionsMenu(ownerShareOption, shareOptions, onUpdateShareOption) {
		if (this.state.showGreenfieldUpsell) {
			return <Dialog title={'Upgrade Required'}
						   message={
							   <Upsell feature={'Greenfield Sharing'}
									   headline={'Do you want to enable Greenfield Sharing?'}
									   callToAction={
										   <div>
											   If you want to share accounts that <b>do not match</b> any of your partner’s account records,
											   please contact PartnerTap sales to learn more about this feature.
										   </div>}
							   />}
						   yesAction={() => this.setState({showGreenfieldUpsell: false})}
						   noContentPadding={true}/>;
		}

		let updateShareFunction = (selectionEvent) => {
			let shareOption = selectionEvent.target.value;
			let {shareAssetType} = this.props.shareData;
			if ((shareAssetType === ASSET_TYPE_ACCOUNTS && EcosystemShareHelper.includesGreenfield(shareOption) && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_GREENFIELD_ACCOUNT_SHARING)) ||
				(shareAssetType === ASSET_TYPE_OPPS && EcosystemShareHelper.includesOppsGreenfield(shareOption) && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_GREENFIELD_OPP_SHARING))) {
				this.setState({showGreenfieldUpsell: true});
			}
			else {
				onUpdateShareOption(selectionEvent);
			}
		};

		return (
			<div style={{display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10}}>
				<FormControl sx={{width: 260}} size={'small'}>
					<InputLabel>Scope</InputLabel>
					<Select value={ownerShareOption} onChange={updateShareFunction} input={<OutlinedInput label={'Scope'}/>}>
						{shareOptions.map((shareOption) => {
							return (
								<MenuItem key={shareOption.key} value={shareOption.key} disabled={shareOption.disabled}>
									{EcosystemShareHelper.labelForShareOption(shareOption.key)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
		);
	}

	render() {
		let {showAdvancedDialog, showShareOptionsDialog, showAllowColumnsDialog, showUpsellForOppsSharing} = this.state;
		let {partner, shareData} = this.props;
		let {shareAssetType, shareSettings, sharableColumns, sharedCounts} = shareData;
		let {availableShareOptions} = shareSettings;
		let {partnerColumnsShared} = sharedCounts;
		let sharedColumns = sharableColumns.filter((sharedColumn) => sharedColumn.shared);
		if (!sharedColumns.length) sharedColumns.push(NONE);
		let isSharingEnabled = shareSettings.ownerAssetShareOption !== SHARE_SUMMARY;
		let activeFilterCount = shareSettings.filters ? Object.keys(shareSettings.filters).length : 0;
		if (activeFilterCount > 0 && EcosystemShareHelper.getInherentAccountTypeFilter(shareSettings.ownerAssetShareOption)) activeFilterCount--;
		let wrapperStyle = {
			display: 'flex',
			flexDirection: 'column',
			width: 320,
			margin: 10,
			padding: 10,
			border: '1px solid ' + partnerTapPrimary,
			borderRadius: 10,
			backgroundColor: partnerTapWhite
		};
		return (
			<div>
				<div key={shareAssetType} style={wrapperStyle}>
					<div style={{display: 'flex', justifyContent: 'center', fontSize: 20, color: partnerTapPrimary, textTransform: 'capitalize'}}>
						{EcosystemShareHelper.labelForShareAssetType(shareAssetType, true)}
					</div>
					<div style={{paddingLeft: 10, paddingTop: 20, display: 'flex', alignItems: 'center'}}>
						{this.renderShareOptionsMenu(shareSettings.ownerAssetShareOption, availableShareOptions, this.updateShareOption)}
						<IconButton style={{color: partnerTapSecondary}} onClick={() => this.setState({showShareOptionsDialog: true})}>
							<Help/>
						</IconButton>
						{showShareOptionsDialog &&
						 <Dialog title={'Share ' + EcosystemShareHelper.labelForShareAssetType(shareAssetType, true)}
								 message={EcosystemShareHelper.renderShareOptionsHelp(partner.firstName, shareAssetType, availableShareOptions.map((shareOption) => shareOption.key))}
								 yesAction={() => this.setState({showShareOptionsDialog: false})}/>}
					</div>
					<div style={{paddingLeft: 10, paddingTop: 20, display: 'flex', alignItems: 'center'}}>
						<FormControl sx={{width: 260}} size={'small'} disabled={!isSharingEnabled}>
							<InputLabel>Columns</InputLabel>
							<Select multiple
									value={sharedColumns}
									input={<OutlinedInput label={'Columns'}/>}
									renderValue={(selected) => {
										if (sharedColumns[0] === NONE) return 'No ' + this.assetType.toLowerCase() + ' columns';
										return selected.length + ' ' + this.assetType.toLowerCase() + ' column' + (selected.length !== 1 ? 's' : '');
									}}>
								{sharableColumns.map((column, index) => (
									<MenuItem key={index} value={column.prettyName} onClick={() => this.updateShareColumn(column)}>
										<CheckboxButton disabled={!column.editable} checked={column.shared}/>
										<ListItemText primary={column.prettyName}/>
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<IconButton style={{padding: 10, color: partnerTapSecondary}} onClick={() => this.setState({showAllowColumnsDialog: true})}>
							<Help/>
						</IconButton>
						{showAllowColumnsDialog &&
						 <Dialog title={'Allow ' + this.assetType + ' Columns'}
								 message={this.allowColumnsHelp}
								 yesAction={() => this.setState({showAllowColumnsDialog: false})}/>}
					</div>
					<div style={{paddingLeft: 10, paddingTop: 20}} key={shareAssetType}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<SecondaryButton label={'ADVANCED'}
											 icon={<FilterList/>}
											 onClick={() => this.setState({showAdvancedDialog: true})}
											 disabled={!isSharingEnabled}/>
							<div style={{flex: 1, display: 'flex', justifyContent: 'center', padding: 10, color: partnerTapInactive}}>
								{activeFilterCount === 0 || !isSharingEnabled ? 'No' : activeFilterCount} filter{activeFilterCount !== 1 && 's'} applied
							</div>
						</div>
						{showAdvancedDialog &&
						 <Dialog title={'Filter ' + EcosystemShareHelper.labelForShareAssetType(shareAssetType, true)
						 .toLowerCase() + ' to share with ' + partner.firstName}
								 message={<PartnerConnectionShareAdvanced partner={partner}
																  shareAssetType={shareAssetType}
																  shareOptions={availableShareOptions}
																  ownerShareOption={shareSettings.ownerAssetShareOption}
																  shareFilters={shareSettings.filters || {}}
																  onUpdateShareOption={this.updateShareOption}
																  onUpdateShareFilters={this.updateShareFilters}/>}
								 yesAction={() => this.setState({showAdvancedDialog: false})}
								 noContentPadding={true}
								 checkForFullScreen={true}/>}
					</div>
					<div style={{display: 'flex', alignItems: 'center', paddingTop: 20, paddingBottom: 10, margin: 'auto', minHeight: 36}}>
						<PrimaryButton label={'UPDATE SHARING'}
									   icon={<Share/>}
									   onClick={this.updateShareSettings}
									   disabled={!this.state.isDirty || EnvHelper.isSpoofing}/>
					</div>
				</div>

				<div style={wrapperStyle}>
					<div style={{textAlign: 'center', fontSize: 16, padding: 10}}>
						{partner.firstName} shares <b>{EcosystemShareHelper.labelForShareOption(shareSettings.partnerAssetShareOption).toUpperCase()}</b>
						<br/>with {partnerColumnsShared} {this.assetType.toLowerCase()} column{partnerColumnsShared !== 1 && 's'}
					</div>
					<div style={{paddingBottom: 10, margin: 'auto'}}>
						<SecondaryButton label={'VIEW'} icon={this.iconForSharingType} onClick={this.navigateToPartnerShared}/>
					</div>
					{showUpsellForOppsSharing &&
					 <Dialog title={'Upgrade Required'}
							 message={UpsellHelper.channelOpportunitySharing}
							 yesAction={() => {
								 this.setState({showUpsellForOppsSharing: false});
							 }}
							 noContentPadding={true}/>}
				</div>
			</div>
		);
	}
}

PartnerConnectionShareEditor.propTypes = {
	partner: PropTypes.object.isRequired,
	setDirtyFunction: PropTypes.func.isRequired,
	shareData: PropTypes.shape({
		shareAssetType: PropTypes.string.isRequired,
		shareOptions: PropTypes.array.isRequired,
		shareSettings: PropTypes.shape({
			availableShareOptions: PropTypes.array,
			shareAssetType: PropTypes.string,
			ownerAssetShareOption: PropTypes.string,
			partnerAssetShareOption: PropTypes.string,
			filters: PropTypes.object
		}).isRequired,
		sharableColumns: PropTypes.array.isRequired,
		sharedCounts: PropTypes.object.isRequired

	}).isRequired
};

export default PartnerConnectionShareEditor;
