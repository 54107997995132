import {Share} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import EcosystemShareHelper from '../../../../helpers/EcosystemShareHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import PagingBase from '../../../../ui/lists/PagingBase';
import Loading from '../../../../ui/Loading';

class PartnerConnectionShareAdvanced extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {personId: this.personId, channelReportType: EcosystemShareHelper.getChannelReportType(props.shareAssetType)};
		this.state.filterSelectorMounted = false;

		this.updateSharingOption = this.updateSharingOption.bind(this);
	}

	componentDidMount() {
		this.fetchSharableColumns();
	}

	get storageKeyBase() {
		return 'ecosystem_advanced_sharing_' + this.props.partner.partnerId;
	}

	get title() {
		return EcosystemShareHelper.labelForShareOption(this.props.ownerShareOption);
	}

	get icon() {
		return Share;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	fetchSharableColumns() {
		ChannelMappingEndpoints.getColumns({channelReportType: EcosystemShareHelper.getChannelReportType(this.props.shareAssetType)})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getAssetColumnsEndpoint', error);
		});
	}

	get partnerPersonId() {
		return this.props.partner.personId;
	}

	updateSharingOption(selectionEvent) {
		this.props.onUpdateShareOption(selectionEvent);
		this.setState({loading: true});
	}

	onFilterSelectorChanged(filterValues, isMount) {
		super.onFilterSelectorChanged(filterValues, isMount);
		if (!isMount) this.props.onUpdateShareFilters(filterValues);
	}

	get filterSelectorColumns() {
		if (EcosystemShareHelper.getInherentAccountTypeFilter(this.props.ownerShareOption)) {
			return this.state.columnData.filter((column) => column.key !== 'accountType');
		}
		return super.filterSelectorColumns;
	}

	get filterSelectorConfig() {
		let config = super.filterSelectorConfig;
		if (config) {
			let initialFilters = {};
			Object.keys(this.props.shareFilters).forEach((filterKey) => {
				if (filterKey.indexOf('customCrmFields ->') === 0) {
					let plainKey = filterKey.substring(20, filterKey.length - 1);
					initialFilters[plainKey] = this.props.shareFilters[filterKey];
				}
				else {
					initialFilters[filterKey] = this.props.shareFilters[filterKey];
				}
			});
			config.blockPersistence = true;
			config.initialFilterValues = initialFilters;
			config.inherentReportFilters = EcosystemShareHelper.getInherentAccountTypeFilter(this.props.ownerShareOption);
		}
		return config;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		let inherentReportFilters = EcosystemShareHelper.getInherentAccountTypeFilter(this.props.ownerShareOption);
		if (inherentReportFilters) {
			Object.assign(params.filters, inherentReportFilters);
		}
		ChannelMappingEndpoints.getRecords(
			{channelReportType: EcosystemShareHelper.getChannelReportType(this.props.shareAssetType), selectedColumns: this.activeColumns},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			this.state.rowCount)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
				this.gettingRowData = false;
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		return super.render();
	}
}

PartnerConnectionShareAdvanced.propTypes = {
	partner: PropTypes.object.isRequired,
	shareAssetType: PropTypes.string.isRequired,
	shareOptions: PropTypes.array.isRequired,
	ownerShareOption: PropTypes.string.isRequired,
	shareFilters: PropTypes.object.isRequired,
	onUpdateShareOption: PropTypes.func.isRequired,
	onUpdateShareFilters: PropTypes.func.isRequired
};

export default withRouter(connect()(PartnerConnectionShareAdvanced));
