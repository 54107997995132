import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapInactive, partnerTapSecondary} from '../../../styles/partnertap_theme';
import MatchCount from '../../../ui/MatchCount';
import Pic from '../../../ui/Pic';
import SharedOppCount from '../../../ui/SharedOppCount';

class ShareOpportunityAccountHeader extends Component {

	render() {
		let {account} = this.props;
		return (
			<div style={{position: 'relative'}}>
				<div style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					alignItems: 'center',
					paddingTop: 10,
					paddingBottom: 20,
					height: EnvHelper.isDesktop ? 140 : 120
				}}>
					<Pic bizId={account.saleAccountId} tmpLogo={account.name} picSize={EnvHelper.isDesktop ? 60 : 50}/>
					<h3 style={{color: partnerTapSecondary}}>{account.name}</h3>
					<div style={{display: 'inline', color: partnerTapInactive}}>
						{account.city}, {account.state} |
						{account.opportunityStatus && account.opportunityStatus.indexOf('OPEN') !== -1 ? ' Opened ' : ' Closed '}
						{DateHelper.epochToDate(account.opportunityStatusDate)}
					</div>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<MatchCount count={account.partnerMatchCount} icon={'E'} label={'Partner'}/>
						<div style={{paddingLeft: (EnvHelper.isDesktop ? 20 : 10)}}>
							<SharedOppCount count={account.opportunitySharedCount}/>
						</div>
					</div>
				</div>
				<hr style={{margin: 0}}/>
			</div>
		);
	}
}

ShareOpportunityAccountHeader.propTypes = {
	account: PropTypes.object.isRequired
};

export default connect()(ShareOpportunityAccountHeader);
