import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import salesforce_icon from '../../../static/images/crm-icon-salesforce.png';
import AuthHelper from '../../helpers/AuthHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {salesforceColor} from '../../styles/partnertap_theme';
import TermsOfServiceDialog from '../TermsOfServiceDialog';
import PrimaryButton from './PrimaryButton';

class SalesforceButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showUserAgreementDialog: false, isTosChecked: false};
		this.onClick = this.onClick.bind(this);
		this.onUserAgreementDialogYes = this.onUserAgreementDialogYes.bind(this);
		this.onUserAgreementDialogNo = this.onUserAgreementDialogNo.bind(this);
	}

	onClick() {
		if (this.props.showTerms && !this.state.isTosChecked) {
			this.setState({showUserAgreementDialog: true});
		}
		else if (!this.props.disableLogin) {
			AuthHelper.salesforceLogin(this.props.registrationState.profileInformation.email, this.props.useSandbox);
		}

		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	onUserAgreementDialogYes() {
		this.setState({isTosChecked: true, showUserAgreementDialog: false}, this.onClick);
	}

	onUserAgreementDialogNo() {
		this.setState({showUserAgreementDialog: false});
	}

	render() {
		return (
			<div>
				<PrimaryButton label={this.props.label}
							   color={salesforceColor}
							   icon={<img src={EnvHelper.serviceUrl + salesforce_icon} style={{height: 18}}/>}
							   onClick={this.onClick}
							   fullWidth={true}/>
				{this.state.showUserAgreementDialog &&
				 <TermsOfServiceDialog onUserAgreementDialogYes={this.onUserAgreementDialogYes} onUserAgreementDialogNo={this.onUserAgreementDialogNo}/>}
			</div>
		);
	}
}

SalesforceButton.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	useSandbox: PropTypes.bool,
	showTerms: PropTypes.bool,
	disableLogin: PropTypes.bool,
	registrationState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		registrationState: state.registrationState
	};
}

export default withRouter(connect(mapStateToProps)(SalesforceButton));
