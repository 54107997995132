import {Report} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import SheetErrorTable from '../../app/channel_ecosystem/shared/sheets/SheetErrorTable';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import {SHEET_ERROR_BLANK_HEADERS, SHEET_ERROR_MISSING_MANDATORY_SAMPLES, SHEET_ERROR_OVERSIZED_HEADERS} from '../../helpers/UploadHelper';
import * as AuthActions from '../../redux/AuthActions';
import {partnerTapInactive} from '../../styles/partnertap_theme';
import LinkButton from '../buttons/LinkButton';
import Dialog from '../Dialog';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import {DEEP_LINK_KEY, LAST_ROUTE_KEY} from '../../globals/StorageKeys';
import CSVHelper from '../../helpers/CsvHelper';

class ErrorMessageDialog extends Component {

	constructor(props, context) {
		super(props, context);
		this.onLogOut = this.onLogOut.bind(this);
		this.dismissError = this.dismissError.bind(this);
	}

	onLogOut() {
		this.props.dispatch(AuthActions.logout());
		this.props.dispatch(AuthActions.clearServerError());
		EnvHelper.push(Routes.HOME.ROUTE);
	}

	handleRichErrors(errorJson) {
		if (errorJson.error.type === SHEET_ERROR_BLANK_HEADERS || errorJson.error.type === SHEET_ERROR_MISSING_MANDATORY_SAMPLES) {
			return <SheetErrorTable errorType={errorJson.error.type} errorData={errorJson.error.data}/>;
		}
		if (errorJson.error.type === SHEET_ERROR_OVERSIZED_HEADERS) {
			return (
				<div>
					{errorJson.error.data.map((oversizedHeader, index) => {
						return (
							<div key={index} style={{display: 'flex', alignItems: 'center', padding: 10, gap: 10, fontWeight: 'bold'}}>
								<Report/>
								<div>{oversizedHeader}</div>
							</div>
						);
					})}
				</div>
			);
		}
	}

	dismissError() {
		if (this.props.authState.httpCode === 428) {
			this.props.onGoToLogin();
		}
		PersistenceHelper.removeValue(LAST_ROUTE_KEY);
		PersistenceHelper.removeValue(DEEP_LINK_KEY);
		EnvHelper.dispatch(AuthActions.clearServerError());
	}

	ignoreMissingHeaderError() {
		CSVHelper.ignoreBlankHeaders = true;
		EnvHelper.dispatch(AuthActions.clearServerError());
	}

	render() {
		let {errorMessage} = this.props.authState;
		let supportMessage =
			<div style={{fontSize: 14}}>
				If you are unable to resolve the issue, please <LinkButton label={'contact support'}
																		   onClick={() => window.open('https://partnertap.com/support')}/>
			</div>;
		let dialogContent;
		let errorJson;
		let isMissingHeaderError;
		try {
			// new style error message is a json string
			errorJson = JSON.parse(errorMessage);
			isMissingHeaderError = errorJson.error.type === SHEET_ERROR_BLANK_HEADERS;
			if (errorJson.httpStatus) {
				dialogContent =
					<div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
						<div style={{fontSize: 16, maxWidth: 600, wordWrap: 'break-word'}}>
							{errorJson.error.message}
						</div>
						{this.handleRichErrors(errorJson)}
						<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
							{supportMessage}
							<div style={{fontSize: 12, color: partnerTapInactive}}>
								Issue ID: {errorJson.uuid}
							</div>
						</div>
					</div>;
			}
		}
		catch (error) {
			// old style error message is a string
			dialogContent =
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20}}>
					<div style={{fontSize: 16, maxWidth: 600, wordWrap: 'break-word'}}>
						{errorMessage}
					</div>
					{supportMessage}
				</div>;
		}

		if (isMissingHeaderError) {
			return (
				<Dialog title={'Error Message'}
						message={dialogContent}
						yesLabel={'IGNORE COLUMNS'}
						yesAction={this.ignoreMissingHeaderError}
						noLabel={'CANCEL'}
						noAction={this.dismissError}/>
			);
		}

		return (
			<Dialog title={'Error Message'} message={dialogContent} yesAction={this.dismissError}/>
		);
	}
}

ErrorMessageDialog.propTypes = {
	onGoToLogin: PropTypes.func.isRequired,
	authState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(ErrorMessageDialog);
