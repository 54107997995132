import {PersonAdd} from '@mui/icons-material';
import React, {Component} from 'react';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import PrimaryButton from './PrimaryButton';

class FindPartnersButton extends Component {

	findPartners() {
		EnvHelper.push(Routes.DASHBOARD.PARTNERS.DISCOVER.ROUTE);
	}

	render() {
		return <PrimaryButton label={'FIND PARTNERS'} icon={<PersonAdd/>} onClick={this.findPartners}/>;
	}
}

export default FindPartnersButton;
