import {InfoOutlined, PersonAdd} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import EcosystemShareHelper, {ALL_ACCOUNT_SHARE_OPTIONS, ALL_OPP_SHARE_OPTIONS} from '../../../helpers/EcosystemShareHelper';
import {PRODUCT_CHANNEL_ECOSYSTEM} from '../../../helpers/FeatureHelper';
import {ASSET_TYPE_ACCOUNTS, ASSET_TYPE_OPPS} from '../../../helpers/ReportHelper';
import {partnerTapSecondary, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import Dialog from '../../../ui/Dialog';
import EmailInvite, {EMAIL_INVITE_NOTE} from '../../../ui/EmailInvite';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import PageHeader from '../../../ui/PageHeader';
import ToolTipOverlay from '../../../ui/ToolTipOverlay';

export class PartnerConnectionInvitePage extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showAccountScopeHelpDialog: false};
	}

	get personEmailId() {
		return this.props.match.params.personEmailId;
	}

	render() {
		let {profile} = this.props.authState;
		let userNote = EMAIL_INVITE_NOTE + '-' + profile.firstName;
		let email = this.personEmailId ? this.personEmailId : '';
		return (
			<ScrollingContainer divId={'channel_invite_page'}>
				<PageHeader title={'Invite Partners'} icon={<PersonAdd fontSize={'large'}/>}/>
				<div style={{display: 'flex', justifyContent: 'center', overflow: 'scroll'}}>
					<div style={{width: 600}}>
						<div style={{
							padding: 10,
							marginBottom: 10,
							borderRadius: 10,
							backgroundColor: partnerTapWhite,
							border: '1px solid ' + partnerTapStroke
						}}>
							<div style={{display: 'flex', alignItems: 'center'}}>
								{EcosystemShareHelper.renderAccountShareMenu(profile.availableAccountShareOptions, profile.defaultAccountShareOption)}
								<div style={{flex: 1}}/>
								<ToolTipOverlay title={
									<div>
										Click to learn more about account sharing scopes...
									</div>}>
									<InfoOutlined style={{color: partnerTapSecondary, paddingLeft: 4, cursor: 'pointer'}} onClick={() => {
										this.setState({showAccountScopeHelpDialog: true});
									}}/>
								</ToolTipOverlay>
								{this.state.showAccountScopeHelpDialog &&
								 <Dialog title={'What Are Account Sharing Scopes?'}
										 message={EcosystemShareHelper.renderShareOptionsHelp('your partner', ASSET_TYPE_ACCOUNTS, ALL_ACCOUNT_SHARE_OPTIONS)}
										 yesAction={() => this.setState({showAccountScopeHelpDialog: false})}/>}

							</div>
							<div style={{display: 'flex', alignItems: 'center'}}>
								{EcosystemShareHelper.renderOpportunityShareMenu(profile.availableOppShareOptions, profile.defaultOppShareOption)}
								<div style={{flex: 1}}/>
								<ToolTipOverlay title={
									<div>
										Click to learn more about opportunity sharing scopes...
									</div>}>
									<InfoOutlined style={{color: partnerTapSecondary, paddingLeft: 4, cursor: 'pointer'}} onClick={() => {
										this.setState({showOppScopeHelpDialog: true});
									}}/>
								</ToolTipOverlay>
								{this.state.showOppScopeHelpDialog &&
								 <Dialog title={'What Are Opportunity Sharing Scopes?'}
										 message={EcosystemShareHelper.renderShareOptionsHelp('your partner', ASSET_TYPE_OPPS, ALL_OPP_SHARE_OPTIONS)}
										 yesAction={() => this.setState({showOppScopeHelpDialog: false})}/>}
							</div>
						</div>
						<EmailInvite productCode={PRODUCT_CHANNEL_ECOSYSTEM} defaultNote={userNote} profile={profile} emailInput={email}/>
					</div>
				</div>
				<div style={{padding: 5}}/>
			</ScrollingContainer>
		);
	}
}

PartnerConnectionInvitePage.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(PartnerConnectionInvitePage);
