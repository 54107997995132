import {Language} from '@mui/icons-material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../endpoints/ManagerAnalyticsEndpoints';
import FeatureHelper, {FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS} from '../../helpers/FeatureHelper';
import * as ManagerAnalyticsActions from '../../redux/ManagerAnalyticsActions';
import {partnerTapPrimary, partnerTapStroke} from '../../styles/partnertap_theme';
import Pic from '../../ui/Pic';
import PopoverSearchList from '../../ui/PopoverSearchList';

const ALL_ORGS = 'All Organizations';

class ManagerHeader extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {currentSearch: '', group: ManagerAnalyticsEndpoints.getDivisionGroup()};

		this.renderPartnerOrg = this.renderPartnerOrg.bind(this);
		this.handleOrgChange = this.handleOrgChange.bind(this);
		this.handleAdminDivisionChange = this.handleAdminDivisionChange.bind(this);
		this.handleAdminAllDivisionChange = this.handleAdminAllDivisionChange.bind(this);
		this.handleAdminGroupDivisionChange = this.handleAdminGroupDivisionChange.bind(this);
		this.handleManagerDivisionChange = this.handleManagerDivisionChange.bind(this);
		this.handleTimeFrameChange = this.handleTimeFrameChange.bind(this);
		this.toggleMutexDivisions = this.toggleMutexDivisions.bind(this);
	}

	componentDidMount() {
		this.loadPartnerOrgs();
		this.toggleMutexDivisions();
		if (!this.props.authState.profile.divisions || this.props.authState.profile.divisions.length === 0) {
			return;
		}
		if (!this.props.managerAnalyticsState.division) {
			for (let i = 0; i < this.props.authState.profile.divisions.length; i++) {
				if (this.props.authState.profile.divisions[i].default) {
					this.props.dispatch(ManagerAnalyticsActions.setAdminDivision(this.props.authState.profile.divisions[i].division));
					return;
				}
			}
			if (this.props.authState.profile.divisions.length > 0) {
				this.props.dispatch(ManagerAnalyticsActions.setAdminDivision(this.props.authState.profile.divisions[0].division));
			}
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	loadPartnerOrgs() {
		Promise.all([
			ManagerAnalyticsEndpoints.fetchRecommendedPartners(),
			ManagerAnalyticsEndpoints.fetchFeaturedPartners()
		])
		.then((results) => {
			if (this.unmounted) return;
			this.sortPartnerOrgs(results[0].payload, results[1].payload);
		})
		.catch((error) => {
			console.error('Error fetching recommended and featured partners', error);
			this.setState({errorMessage: 'Error fetching recommended and featured partners: ' + error, loading: false});
		});
	}

	sortPartnerOrgs(recommendedList, featuredList) {
		let featuredOrgs = [];
		let recommendedOrgs = [];
		let searchOrgs = [];
		this.props.searchList.forEach((data) => {
			let element = data.name;
			let orgId = data.id;
			if (recommendedList.includes(element)) {
				if (featuredList.includes(element)) {
					// recommended, also featured
					featuredOrgs.push({name: element, id: orgId});
				}
				else {
					// recommended, but not featured
					recommendedOrgs.push({name: element, id: orgId});
				}
			}
			else {
				if (featuredList.includes(element)) {
					// not recommended, but featured
					featuredOrgs.push({name: element, id: orgId});
				}
				else {
					// neither recommended, nor featured
					searchOrgs.push({name: element, id: orgId});
				}
			}
		});

		let orgList = [{name: ALL_ORGS}].concat(featuredOrgs, recommendedOrgs, searchOrgs);
		this.setState({orgList});
	}

	renderPartnerOrg(data) {
		return (
			<div style={{display: 'flex', alignItems: 'center'}}>
				{data.name === ALL_ORGS ?
					<Language style={{color: partnerTapPrimary}}/> :
					<Pic picSize={22} orgId={data.id} tmpLogo={data.name}/>}
				<div style={{fontSize: 18, fontWeight: 'bold', paddingLeft: 10, paddingRight: 15, textTransform: 'capitalize'}}>
					{data.name.toLowerCase()}
				</div>
			</div>
		);
	}

	handleOrgChange(org) {
		if (!org.name || org.name === ALL_ORGS) {
			this.props.dispatch(ManagerAnalyticsActions.setCurrentCompanyName({name: null}));
		}
		else {
			this.props.dispatch(ManagerAnalyticsActions.setCurrentCompanyName(org));
		}
	}

	handleTimeFrameChange(value) {
		this.setState({timeSpanValue: value});
		if (this.props.onTimeChange) {
			this.props.onTimeChange(value);
		}
	}

	handleAdminDivisionChange(groupsAndDivisions) {
		this.props.dispatch(ManagerAnalyticsActions.setDivisionStatus(_.cloneDeep(groupsAndDivisions)));
	}

	handleAdminAllDivisionChange() {
		this.props.dispatch(ManagerAnalyticsActions.setAdminDivision(ManagerAnalyticsEndpoints.ALL_DIVISIONS));
		this.props.dispatch(ManagerAnalyticsActions.clearDivisionStatus());
		ManagerAnalyticsEndpoints.setDivisionGroup(null);
		this.setState({group: null});
	}

	handleAdminGroupDivisionChange(group, divisions) {
		this.setState({group: group.group});
		ManagerAnalyticsEndpoints.setDivisionGroup(group.group);
		divisions.forEach((divisionItem) => {
			if (!divisionItem.isGroup) {
				divisionItem.isChecked = divisionItem.group === group.group;
				this.props.dispatch(ManagerAnalyticsActions.setDivisionStatus(divisionItem.division));
			}
		});
	}

	handleManagerDivisionChange(division) {
		this.toggleMutexDivisions(division);
		this.props.dispatch(ManagerAnalyticsActions.setManagerDivision(division.division));
	}

	toggleMutexDivisions(divisionSelected) {
		let {divisions} = this.props.managerDetails;
		if (!divisions) return;
		if (divisionSelected && divisionSelected.isChecked && divisionSelected.division === ManagerAnalyticsEndpoints.ALL_DIVISIONS) {
			divisions.forEach((divisionItem) => {
				if (divisionItem.division !== ManagerAnalyticsEndpoints.ALL_DIVISIONS) {
					divisionItem.isChecked = false;
				}
			});
		}
		else {
			let allDivisions = divisions.find((division) => division.division === ManagerAnalyticsEndpoints.ALL_DIVISIONS);
			if (allDivisions) {
				let hasSelectedDivision = divisions.find((division) => division.isChecked && division.division !== ManagerAnalyticsEndpoints.ALL_DIVISIONS && !division.isGroup);
				allDivisions.isChecked = !hasSelectedDivision;
			}
		}
	}

	getDivisionGroups(organization) {
		switch (organization.toLowerCase()) {
			case 'partnertap':
			case 'adp employer services':
				return ['National Accounts', 'Major Accounts'];
			default:
				return [];
		}
	}

	markDivisionGroups(organization, divisions) {
		switch (organization.toLowerCase()) {
			case 'partnertap':
			case 'adp employer services':
				return this.processAdpDivisionGroups(divisions);
			default:
				return divisions;
		}
	}

	processAdpDivisionGroups(divisions) {
		let ADP_NATIONAL_LABELS = ['NAS', 'NATIONAL', 'GES', 'AVS', 'GLOBAL'];
		divisions.forEach((divisionObject) => {
			let isMAS = true;
			for (let labelIndex = 0; labelIndex < ADP_NATIONAL_LABELS.length; labelIndex++) {
				if (divisionObject.division && divisionObject.division.toUpperCase().indexOf(ADP_NATIONAL_LABELS[labelIndex]) === 0) {
					divisionObject.group = 'National Accounts';
					isMAS = false;
					break;
				}
			}
			if (isMAS) {
				divisionObject.group = 'Major Accounts';
			}
		});
		return divisions;
	}

	render() {
		let {divisions} = this.props.managerDetails;
		divisions = this.markDivisionGroups(this.props.authState.profile.organization, divisions || []);
		this.toggleMutexDivisions();
		let isAdmin = FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS);
		let divisionGroups = this.getDivisionGroups(this.props.authState.profile.organization);
		let divisionGroupObjects = [];
		divisionGroups.forEach((group) => divisionGroupObjects.push({division: group, group: group, isGroup: true, isChecked: group === this.state.group}));
		let groupsAndDivisions = divisionGroupObjects.concat(divisions);
		return (
			<div style={{display: 'flex', flexWrap: 'wrap', borderBottom: '1px solid ' + partnerTapStroke}}>
				{this.state.orgList &&
				 <div style={{minWidth: 240, padding: 10}}>
					 <PopoverSearchList list={this.state.orgList}
										preselectedItem={this.state.orgList.find((org) => org.name === this.props.managerAnalyticsState.currentCompanyName) || this.state.orgList[0]}
										searchByObject={true}
										labelRenderer={this.renderPartnerOrg}
										onItemSelected={this.handleOrgChange}/>
				 </div>}
				<div style={{display: 'flex', alignItems: 'center'}}>
					{isAdmin ?
						<div style={{width: 150, padding: 10}}>
							<PopoverSearchList label={'Division'}
											   list={groupsAndDivisions}
											   preselectedItem={groupsAndDivisions.find((item) => item.division === this.props.managerAnalyticsState.division) || groupsAndDivisions[0]}
											   isMultipleChoice={true}
											   searchByObjectKeys={['division']}
											   labelRenderer={(item) => item.division === ManagerAnalyticsEndpoints.ALL_DIVISIONS ? 'All Divisions' : item.division}
											   setCheckedFunction={(item, isChecked) => {
												   item.isChecked = isChecked;
												   item.wasChecked = !isChecked;
											   }}
											   onItemSelected={() => this.handleAdminDivisionChange(groupsAndDivisions)}/>
						</div>
						:
						<div style={{width: 150, padding: 10}}>
							<PopoverSearchList label={'Division'}
											   list={divisions}
											   preselectedItem={this.props.managerAnalyticsState.division ? divisions.find((item) => item.division === this.props.managerAnalyticsState.division) : divisions[0]}
											   searchByObjectKeys={['division']}
											   labelRenderer={(item) => item.division === ManagerAnalyticsEndpoints.ALL_DIVISIONS ? 'All Divisions' : item.division}
											   onItemSelected={this.handleManagerDivisionChange}/>
						</div>
					}
					{!this.props.managerAnalyticsState.forceAllTime ?
						<div style={{width: 150, padding: 10}}>
							<PopoverSearchList list={[-1, 30, 60, 90, 150]}
											   preselectedItem={this.props.managerAnalyticsState.searchRange}
											   labelRenderer={(item) => item === -1 ? 'All Time' : 'Past ' + item + ' days'}
											   onItemSelected={this.handleTimeFrameChange}/>
						</div>
						:
						<div style={{color: partnerTapStroke, whiteSpace: 'nowrap', padding: 10}}>
							All Time
						</div>}
				</div>
			</div>
		);
	}
}

ManagerHeader.propTypes = {
	onTimeChange: PropTypes.func,
	showBack: PropTypes.bool,
	onSearchComplete: PropTypes.func,
	searchList: PropTypes.array,
	managerDetails: PropTypes.object.isRequired,
	authState: PropTypes.object.isRequired,
	managerAnalyticsState: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(ManagerHeader);
