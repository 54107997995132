import {
	CancelScheduleSend,
	Diversity3,
	DriveFolderUpload,
	EmojiPeople,
	FmdBad,
	ForwardToInbox,
	Handshake,
	JoinInner,
	PersonOff,
	ScheduleSend,
	ThumbDown,
	ThumbUp,
	Verified
} from '@mui/icons-material';
import React from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import DashboardTopItem from '../shared/DashboardTopItem';

class CoSellActionListResultsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;

		this.getActionListResults = this.getActionListResults.bind(this);
	}

	componentDidMount() {
		this.setState({loading: true});
	}

	getActionListResults() {
		CoSellEngineEndpoints.getCoSellActionListResults(this.actionListId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({actionListResults: result.payload, loading: true});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionListResults', error);
		});
	}

	get actionListId() {
		return this.props.match.params.actionListId;
	}

	get storageKeyBase() {
		return 'co_sell_action_list_results';
	}

	get title() {
		return 'Account Results';
	}

	get icon() {
		return JoinInner;
	}

	get componentAboveToolbar() {
		let actionListResults = {
			records: 480,
			initiated: 400,
			invalid: 80,
			bounces: 20,
			salesRepOptedIn: 384,
			partnerOptedIn: 386,
			salesRepDeclined: 16,
			partnerDeclined: 14,
			pending: 33,
			introductions: 337,
			crmActivities: 400,
			actionListsSent: 3
		};
		let route = Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST_RESULTS;
		return (
			<div style={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'stretch',
				justifyContent: 'center',
				margin: 5,
				maxHeight: '40vh',
				overflow: 'scroll'
			}}>
				<DashboardTopItem MaterialIcon={Diversity3}
								  title={'Records'}
								  message={'Number of records processed by workflows'}
								  value={actionListResults.records}
								  destination={route.PATH(this.actionListId, 'records')}/>
				<DashboardTopItem MaterialIcon={Verified}
								  title={'Initiated'}
								  message={'Number of valid records detected by workflows'}
								  value={actionListResults.initiated}
								  destination={route.PATH(this.actionListId, 'initiated')}/>
				<DashboardTopItem MaterialIcon={FmdBad}
								  title={'Invalid'}
								  message={'Number of invalid records with missing information'}
								  value={actionListResults.invalid}
								  destination={route.PATH(this.actionListId, 'invalid')}/>
				<DashboardTopItem MaterialIcon={CancelScheduleSend}
								  title={'Bounces'}
								  message={'Number of records where email bounced'}
								  value={actionListResults.bounces}
								  destination={route.PATH(this.actionListId, 'bounces')}/>
				<DashboardTopItem MaterialIcon={ThumbUp}
								  title={'Sales Rep Opted In'}
								  message={'Number of records where the sales rep opted in'}
								  value={actionListResults.salesRepOptedIn}
								  destination={route.PATH(this.actionListId, 'salesRepOptedIn')}/>
				<DashboardTopItem MaterialIcon={ThumbDown}
								  title={'Sales Rep Declined'}
								  message={'Number of records where the sales rep declined'}
								  value={actionListResults.salesRepDeclined}
								  destination={route.PATH(this.actionListId, 'salesRepDeclined')}/>
				<DashboardTopItem MaterialIcon={EmojiPeople}
								  title={'Partner Opted In'}
								  message={'Number of records where the partner opted in'}
								  value={actionListResults.partnerOptedIn}
								  destination={route.PATH(this.actionListId, 'partnerOptedIn')}/>
				<DashboardTopItem MaterialIcon={PersonOff}
								  title={'Partner Declined'}
								  message={'Number of records where the partner declined'}
								  value={actionListResults.partnerDeclined}
								  destination={route.PATH(this.actionListId, 'partnerDeclined')}/>
				<DashboardTopItem MaterialIcon={ScheduleSend}
								  title={'Pending'}
								  message={'Number of records waiting for a response'}
								  value={actionListResults.pending}
								  destination={route.PATH(this.actionListId, 'pending')}/>
				<DashboardTopItem MaterialIcon={Handshake}
								  title={'Introductions'}
								  message={'Number of introduction emails sent'}
								  value={actionListResults.introductions}
								  destination={route.PATH(this.actionListId, 'introductions')}/>
				<DashboardTopItem MaterialIcon={DriveFolderUpload}
								  title={'CRM Activities'}
								  message={'Number of CRM activities uploaded'}
								  value={actionListResults.crmActivities}
								  destination={route.PATH(this.actionListId, 'crmActivities')}/>
				<DashboardTopItem MaterialIcon={ForwardToInbox}
								  title={'Action Lists Sent'}
								  message={'Number of Action Lists sent to sales reps'}
								  value={actionListResults.actionListsSent}
								  destination={route.PATH(this.actionListId, 'actionListsSent')}/>
			</div>
		);
	}

	get columnData() {
		return [
			{title: 'Name', key: 'accountName'},
			{title: 'Type', key: 'accountType'},
			{title: 'Owner', key: 'accountOwner'},
			{title: 'Partner', key: 'partnerRepName', isPartnerData: true}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		CoSellEngineEndpoints.getCoSellActionListWorkflowRecords(
			this.actionListId,
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				result.payload = result.payload.map((workflowRecord) => {
					if (!workflowRecord.isRemoved) {
						return workflowRecord.dto;
					}
				})
				.filter((workflowRecord) => workflowRecord);
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}
}

export default withRouter(CoSellActionListResultsPage);
