import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {LOGIN_TOKEN_KEY} from '../../globals/StorageKeys';
import EnvHelper from '../../helpers/EnvHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import {partnerTapAlert} from '../../styles/partnertap_theme';
import SupportButton from '../../ui/buttons/SupportButton';
import LogoMessage from '../../ui/messages/LogoMessage';

class SamlPage extends Component {

	constructor(props, context) {
		super(props, context);
		this.token = null;
		this.state = {error: null};
	}

	componentDidMount() {
		this.token = this.props.match.params.token;
		console.log('this.props.match.params: ', this.props.match.params);
		if (this.token) {
			PersistenceHelper.setValue(LOGIN_TOKEN_KEY, this.token);
			EnvHelper.token = this.token;
			console.log('got token ', this.token);
			location.href = '/';
		}
		else {
			setTimeout(() => {
				if (this.unmounted) return;
				this.setState({error: 'SAML Login failed, null token.'});
			}, 200);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	render() {
		let message = 'Authenticating...';
		let action;
		if (this.state.error) {
			action =
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10}}>
					<div style={{fontSize: 20, textAlign: 'center'}}>Sorry, an error occurred</div>
					<div style={{color: partnerTapAlert, textAlign: 'center'}}>{this.state.error}</div>
					<SupportButton/>
				</div>;
		}
		return <LogoMessage message={message} action={action}/>;
	}
}

export default withRouter(connect()(SamlPage));
