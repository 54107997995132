import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as PartnersEndpoints from '../../endpoints/PartnersEndpoints';
import {
	INVITE_NOT_SENT,
	INVITE_STATUS_ACCEPTED,
	INVITE_STATUS_DENIED,
	INVITE_STATUS_INITIATED,
	INVITE_STATUS_RECOMMENDED,
	INVITE_STATUS_REQUESTED,
	INVITE_STATUS_UNPARTNERED,
	PARTNER_INVITE_SENT
} from '../../globals/Enums';
import AuthHelper from '../../helpers/AuthHelper';
import EcosystemShareHelper from '../../helpers/EcosystemShareHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {
	FEATURE_CHANNEL_ECOSYSTEM_ACCESS,
	FEATURE_MANAGER_ANALYTICS_ACCESS,
	PRODUCT_CHANNEL_ECOSYSTEM,
	PRODUCT_SALES_NETWORK
} from '../../helpers/FeatureHelper';
import InviteHelper from '../../helpers/InviteHelper';
import {partnerTapAlert, partnerTapDefaultText, partnerTapPrimary, partnerTapStroke} from '../../styles/partnertap_theme';
import Dialog from '../Dialog';
import {EMAIL_LABEL_STYLE} from '../EmailInvite';
import TextInputBox from '../TextInputBox';
import PendingButton from './PendingButton';
import PrimaryButton from './PrimaryButton';
import LinkButton from './LinkButton';

class InviteButton extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {inviteStatus: null, requestSent: false, showShareDialog: false, noteInput: this.props.userNote, shareOpps: true};

		this.sendPartnerInvite = this.sendPartnerInvite.bind(this);
		this.onAddPartner = this.onAddPartner.bind(this);
		this.onChangeNote = this.onChangeNote.bind(this);
		this.onCloseInviteFailedDialog = this.onCloseInviteFailedDialog.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onAddPartner() {
		let itemData = this.props.inviteData;
		itemData.isInitiatedPending = true;
		itemData.initialPartnerStatus = itemData.partnerStatus;
		itemData.partnerStatus = INVITE_STATUS_INITIATED;
		this.setState({shareOpps: true, showShareDialog: true});
	}

	onChangeNote(value) {
		this.setState({noteInput: value});
	}

	cancelPartnerInvite() {
		let itemData = this.props.inviteData;
		itemData.isInitiatedPending = false;
		itemData.partnerStatus = this.props.inviteData.initialPartnerStatus;
		this.setState({showShareDialog: false});
	}

	sendPartnerInvite() {
		if (!this.state.requestSent) {
			let {userNote, shareOpps} = this.state;
			this.setState({requestSent: true, showShareDialog: false, inviteStatus: null});

			let {inviteData} = this.props;
			inviteData.isInitiatedPending = false;
			inviteData.partnerStatus = INVITE_STATUS_INITIATED;
			this.setState({showShareDialog: false});

			let productCode = EnvHelper.inChannelEcosystemProduct ? PRODUCT_CHANNEL_ECOSYSTEM : PRODUCT_SALES_NETWORK;
			PartnersEndpoints.sendPartnerInvite(AuthHelper.userPerson.id, inviteData.personId, userNote, shareOpps, productCode, EcosystemShareHelper.inviteAccountShareOption, EcosystemShareHelper.inviteOpportunityShareOption)
			.then((result) => {
					if (this.unmounted) return;
					this.setState({inviteStatus: result ? result.payload : INVITE_NOT_SENT});
				}
			)
			.catch((error) => {
				EnvHelper.serverError('Error from sendPartnerInvite', error);
			});
		}
	}

	onCloseInviteFailedDialog() {
		this.setState({inviteStatus: null, inviteFailed: true});
	}

	render() {
		let inviteFailed = this.state.inviteStatus && this.state.inviteStatus !== PARTNER_INVITE_SENT;
		let dialog;
		if (inviteFailed) {
			dialog = <Dialog title={'Sorry, we made a recommendation error!'}
							 message={InviteHelper.getStatusMessage(this.state.inviteStatus, this.props.inviteData.partnerName)}
							 yesAction={this.onCloseInviteFailedDialog}/>;
		}
		else if (this.state.showShareDialog) {
			dialog = <Dialog title={this.props.dialogTitle || 'Great work reaching out to a new partner!'}
							 message={
								 <div>
									 {this.props.dialogContent || ''}
									 <div style={{paddingTop: 10}}>
										 <div style={EMAIL_LABEL_STYLE}>
											 Edit your personal note (optional)
										 </div>
										 <TextInputBox hintText={'Personal Note'}
													   value={this.state.noteInput}
													   multiLine={true}
													   maxChars={2048}
													   rows={this.props.userNoteRows || 3}
													   isSimple={true}
													   onChange={this.onChangeNote}/>
									 </div>
								 </div>
							 }
							 noLabel={'CANCEL'}
							 noAction={() => this.cancelPartnerInvite()}
							 yesLabel={'SEND'}
							 yesAction={() => this.sendPartnerInvite()}/>;
		}

		let {inviteData, forceEnabled, useLinkButton} = this.props;
		let {partnerStatus} = inviteData;
		let isRecommended = partnerStatus === INVITE_STATUS_RECOMMENDED || partnerStatus === INVITE_STATUS_UNPARTNERED;
		let isRequested = partnerStatus === INVITE_STATUS_REQUESTED;
		let isInitiated = partnerStatus === INVITE_STATUS_INITIATED;
		let isComplete = partnerStatus === INVITE_STATUS_ACCEPTED || partnerStatus === INVITE_STATUS_DENIED;
		let isInitiatedPending = inviteData.isInitiatedPending;
		let partneringEnabled = !isInitiated &&
								!isRequested &&
								!isComplete &&
								(forceEnabled ||
								 inviteData.matchedAccountCount > 0 ||
								 FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_ECOSYSTEM_ACCESS) ||
								 FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ACCESS));

		let inviteButton = '';
		if (partneringEnabled && !this.state.requestSent) {
			const ButtonForAction = useLinkButton ? LinkButton : PrimaryButton;
			inviteButton = <ButtonForAction label={'INVITE'} onClick={this.onAddPartner} disabled={EnvHelper.isSpoofing}/>;
		}

		if (inviteData.empty) {
			return (
				<div>
					<div style={{padding: 8, borderBottom: '1px solid ' + partnerTapStroke}}
						 key={inviteData.id}>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<div style={{paddingLeft: 10, fontSize: 18}}>
								No results for '{inviteData.search}'.
								Please try a different search
							</div>
						</div>
					</div>
					{dialog}
				</div>
			);
		}

		if (this.state.inviteFailed) inviteFailed = true;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				{(EnvHelper.inSalesNetworkProduct && isRecommended) &&
				 <div style={{color: partnerTapPrimary, paddingBottom: 10}}>
					 Recommended
				 </div>}
				{isRequested &&
				 <PendingButton itemData={inviteData} useLinkButton={useLinkButton}/>}
				{isInitiated &&
				 <div style={{
					 display: 'flex',
					 alignItems: 'center',
					 color: inviteFailed ? partnerTapAlert : partnerTapDefaultText,
					 whiteSpace: 'nowrap',
					 height: 36
				 }}>
					 {isInitiatedPending ? 'Preparing Invite' : (inviteFailed ? 'Invite Not Sent' : 'Invite Sent')}
				 </div>}
				{inviteButton}
				{dialog}
			</div>
		);
	}
}

InviteButton.propTypes = {
	inviteData: PropTypes.object.isRequired,
	userNote: PropTypes.string.isRequired,
	userNoteRows: PropTypes.number,
	dialogTitle: PropTypes.string,
	dialogContent: PropTypes.object,
	forceEnabled: PropTypes.bool,
	useLinkButton: PropTypes.bool
};

export default InviteButton;
