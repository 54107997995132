import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as ManagerAnalyticsEndpoints from '../../../endpoints/ManagerAnalyticsEndpoints';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import DialogHelper from '../../../helpers/DialogHelper';
import * as ManagerAnalyticsActions from '../../../redux/ManagerAnalyticsActions';
import Pic from '../../../ui/Pic';
import ManagerAnalyticsPage from '../ManagerAnalyticsPage';

class ConnectionsAnalytics extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {detailDialog: null};
	}

	renderRep(value, data) {
		if (data) {
			return (
				<div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
					<Pic personId={data.personId} picSize={40}/>
					<div style={{fontSize: 16, fontWeight: 'bold', paddingLeft: 10}}>
						{value}
					</div>
				</div>
			);
		}
		else {
			return value;
		}
	}

	render() {
		let columns = [
			{title: 'Rep Name', key: 'repName', renderFunction: this.renderRep},
			{title: 'Rep Title', key: 'repTitle'},
			{title: 'Accounts', key: 'accountsCount'},
			{title: 'Intel Exchanges', key: 'intelsExchangedCount'},
			{title: 'Logins', key: 'loginsCount'},
			{title: 'Last Login', key: 'lastLoginDate', renderFunction: DateHelper.epochToDate},
			{title: 'Recommended Partners', key: 'recommendedPartnerCount'},
			{title: 'Invites Received Pending', key: 'requestedConnectionsCount'},
			{title: 'Invites Sent Pending', key: 'initiatedConnectionsCount'},
			{title: 'Invites Declined', key: 'declinedConnectionsCount'},
			{title: 'Partners', key: 'connectionsCount'}
		];
		return (
			<Fragment>
				{this.state.detailDialog}
				<ManagerAnalyticsPage labelNoFind={'No connection data found'}
									  labelLoading={'Loading Connection Data...'}
									  sectionName={'Sales Reps'}
									  glyph={'x'}
									  columnData={columns}
									  keyData={[ManagerAnalyticsEndpoints.keyRepConnections]}
									  rowClick={(data) => {
										  this.props.dispatch(ManagerAnalyticsActions.setCurrentRepName(data));
										  DialogHelper.openDetailDialog('Sales Reps', Routes.MANAGER_ANALYTICS.SALES_REP.ROUTE, null, this.setState.bind(this));
									  }}/>
			</Fragment>
		);
	}
}

ConnectionsAnalytics.propTypes = {
	dispatch: PropTypes.func.isRequired
};

export default connect()(ConnectionsAnalytics);
