import {ArrowBack} from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import * as CoSellEngineHelperLive from './CoSellEngineHelperLive';
import {emailConfig, WORKFLOW_COSELL_PLAY, WORKFLOW_CRM_TRACKING, WORKFLOW_SALES_PLAY, WORKFLOW_THRU_CHANNEL_PLAY} from './CoSellEngineHelperLive';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapAppBackground, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import {WorkflowIntroEmailLive} from './workflow_editors/WorkflowIntroEmailLive';
import {WorkflowDistributeRecordsInternalLive} from './workflow_editors/WorkflowDistributeRecordsInternalLive';
import {WorkflowDistributeRecordsExternalLive} from './workflow_editors/WorkflowDistributeRecordsExternalLive';
import {WorkflowCrmTrackingLive} from './workflow_editors/WorkflowCrmTrackingLive';
import Stepper from '../../../ui/Stepper';
import {Routes} from '../../../globals/Routes';
import TableHelper from '../../../helpers/TableHelper';
import EmailWorkflowEditorLive from './workflow_editors/EmailWorkflowEditorLive';
import MessageBoxAlert from '../../../ui/messages/MessageBoxAlert';

class CoSellWorkflowConfigLive extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, error: false, saving: false, editor: null, steps: null, currentConfigStep: 0, summaryData: null, actionListMetadata: null};

		this.updateSteps = this.updateSteps.bind(this);
		this.renderConfigStep = this.renderConfigStep.bind(this);
		this.renderConfirmConfig = this.renderConfirmConfig.bind(this);
		this.renderSteppingButtons = this.renderSteppingButtons.bind(this);
		this.gotoPreviousStep = this.gotoPreviousStep.bind(this);
		this.gotoNextStep = this.gotoNextStep.bind(this);
		this.completeFinalStep = this.completeFinalStep.bind(this);
		this.getWorkflowSummary = this.getWorkflowSummary.bind(this);
		this.startWorkflow = this.startWorkflow.bind(this);
		this.cancelConfig = this.cancelConfig.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get cosellActionListMetadataPublicId() {
		return this.props.match.params.actionListId;

	}

	get isActionListUsed() {
		let {actionListMetadata} = this.state;
		return actionListMetadata.activeWorkflow;
	}

	get sequenceRecipePublicId() {
		return this.props.match.params.sequenceRecipePublicId;
	}

	getEditor(sequenceInfo) {
		switch (sequenceInfo.workflowName) {
			case WORKFLOW_COSELL_PLAY:
				return new WorkflowIntroEmailLive();
			case WORKFLOW_SALES_PLAY:
				return new WorkflowDistributeRecordsInternalLive();
			case WORKFLOW_THRU_CHANNEL_PLAY:
				return new WorkflowDistributeRecordsExternalLive();
			case WORKFLOW_CRM_TRACKING:
				return new WorkflowCrmTrackingLive();
		}
		return null;
	}

	async init() {
		try {
			const [actionListMetadata, workflowSequenceTemplate] = await Promise.all([
				CoSellEngineEndpoints.getCoSellActionListMetadata(this.cosellActionListMetadataPublicId),
				CoSellEngineEndpoints.getActionListWorkflowSequenceTemplates(this.sequenceRecipePublicId)
			]);
			if (this.unmounted || !actionListMetadata || !workflowSequenceTemplate) return;
			let {templates, sequenceInfo} = workflowSequenceTemplate.payload;
			templates.sort((a, b) => a.order > b.order ? 1 : -1);
			let editor = this.getEditor(sequenceInfo);
			this.setState({
				actionListMetadata: actionListMetadata.payload,
				loading: false,
				templates: templates,
				editor: editor,
				steps: editor.steps,
				sequenceInfo: sequenceInfo
			});
		}
		catch (error) {
			this.setState({loading: false, error: true});
			EnvHelper.serverError('Error from workflow config init ', error);
		}
	}

	getWorkflowSummary() {
		CoSellEngineEndpoints.getWorkflowSummaryData(
			this.cosellActionListMetadataPublicId,
			emailConfig.emailWorkflow.ownerColumn.emailColumn,
			emailConfig.emailWorkflow.ownerColumn.nameColumn,
			emailConfig.emailWorkflow.partnerColumn.emailColumn,
			emailConfig.emailWorkflow.partnerColumn.nameColumn,
			this.sequenceRecipePublicId
		)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({summaryData: result.payload});
		})
		.catch((error) => {
			this.setState({summaryDataError: error});
			EnvHelper.serverError('Error from getWorkflowSummaryData', error);
		});
	}

	startWorkflow() {
		this.setState({saving: true});
		emailConfig.actionListMetadataPublicId = this.cosellActionListMetadataPublicId;
		CoSellEngineEndpoints.startWorkflow(this.sequenceRecipePublicId, emailConfig)
		.then((result) => {
			if (this.unmounted) return;
			CoSellEngineHelperLive.clearEmailConfig();
			this.completeFinalStep();
			this.setState({saving: false});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from startWorkflow', error);
		});
	}

	updateSteps(currentConfigStep) {
		let {steps} = this.state;
		steps.forEach((step, index) => {
			step.active = index === currentConfigStep;
			step.complete = index < currentConfigStep;
		});
		this.setState({currentConfigStep: currentConfigStep});
	}

	renderConfigStep() {
		if (this.isConfirmStep) {
			let {summaryData, summaryDataError} = this.state;
			if (!summaryData && !summaryDataError) {
				this.getWorkflowSummary();
				return <Loading>Loading summary...</Loading>;
			}
			return this.renderConfirmConfig();
		}
		let {actionListMetadata, sequenceInfo} = this.state;
		let {templates, editor, currentConfigStep} = this.state;
		let isExpired = actionListMetadata.expirationOnDate < (new Date().getTime() / 1000);
		let expiredWarning;
		if (isExpired && currentConfigStep === 0) {
			expiredWarning =
				<MessageBoxAlert title={'This action list has expired'}>
					To ensure you have the most up-to-date data for your workflow, we recommend cloning this action list or creating a new one.
				</MessageBoxAlert>;
		}
		return (
			<Fragment>
				{expiredWarning}
				<EmailWorkflowEditorLive actionListMetadata={actionListMetadata}
										 sequenceInfo={sequenceInfo}
										 templates={templates}
										 workflowEditor={editor}
										 currentConfigStep={currentConfigStep}
										 onStepComplete={(isConfigStepComplete = true) => this.setState({isConfigStepComplete: isConfigStepComplete})}/>
			</Fragment>
		);
	}

	renderConfirmConfig() {
		let {editor, summaryData, sequenceInfo} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
				<div style={TableHelper.boxStyle}>
					<div style={{fontSize: 20}}>
						{sequenceInfo.displayName}
					</div>
					{summaryData ?
						<div style={{display: 'flex', flexDirection: 'column'}}>
							{editor.renderSummaryData(summaryData)}
							<div style={{marginTop: 20}}>
								<div style={{fontSize: 18}}>
									Are you ready to start this workflow?
								</div>
								<div>
									Starting this workflow will lock your action list. You will no longer be able to make any changes to this list or use
									this list for any other workflows.
								</div>
							</div>
						</div>
						:
						<div>Sorry, summary data is unavailable</div>}
				</div>
			</div>
		);
	}

	renderSteppingButtons() {
		let {currentConfigStep, isConfigStepComplete, summaryData} = this.state;
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				gap: 20,
				padding: 10,
				background: partnerTapWhite,
				borderTop: '1px solid ' + partnerTapStroke,
				borderBottom: '1px solid ' + partnerTapStroke
			}}>
				{currentConfigStep === 0 &&
				 <SecondaryButton label={'CANCEL'} onClick={this.cancelConfig}/>}
				{(currentConfigStep > 0 && !this.isActionListUsed) &&
				 <SecondaryButton label={'BACK'} icon={<ArrowBack/>} onClick={this.gotoPreviousStep}/>}
				{this.isConfirmStep ?
					<PrimaryButton label={this.isActionListUsed ? 'DONE' : 'START WORKFLOW'}
								   disabled={!summaryData}
								   onClick={this.isActionListUsed ? this.cancelConfig : this.startWorkflow}/> :
					<PrimaryButton label={'CONTINUE'} disabled={!isConfigStepComplete} onClick={this.gotoNextStep}/>}
			</div>
		);
	}

	cancelConfig() {
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(this.cosellActionListMetadataPublicId));
	}

	gotoPreviousStep() {
		let {currentConfigStep} = this.state;
		this.updateSteps(currentConfigStep - 1);
	}

	gotoNextStep() {
		let {currentConfigStep} = this.state;
		this.updateSteps(currentConfigStep + 1);
	}

	get isConfirmStep() {
		let {steps, currentConfigStep} = this.state;
		return currentConfigStep === steps?.length - 1;
	}

	completeFinalStep() {
		let {steps} = this.state;
		let lastStep = steps[steps.length - 1];
		lastStep.active = false;
		lastStep.complete = true;
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(this.cosellActionListMetadataPublicId));
	}

	render() {
		let {loading, error, steps, saving} = this.state;
		if (loading) return <Loading>Loading config...</Loading>;
		if (error) return <MessageBoxAlert>There was an unexpected error loading this workflow.</MessageBoxAlert>;
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				{saving &&
				 <ScrimMessage message={'Saving changes...'}/>}
				<ScrollingContainer divId={'co_sell_configuration'}>
					<Stepper steps={steps}/>
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 10,
						padding: 20,
						backgroundColor: partnerTapAppBackground,
						overflow: 'scroll'
					}}>
						{this.renderConfigStep()}
					</div>
					{this.renderSteppingButtons()}
				</ScrollingContainer>
			</div>
		);
	}
}

export default withRouter(CoSellWorkflowConfigLive);
