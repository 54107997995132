import {
	Air,
	Business,
	CancelScheduleSend,
	Diversity3,
	DriveFolderUpload,
	EmojiPeople,
	FmdBad,
	ForwardToInbox,
	Handshake,
	Insights,
	JoinInner,
	PersonOff,
	ScheduleSend,
	ThumbDown,
	ThumbUp,
	Verified
} from '@mui/icons-material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import DashboardTopItem from '../../../app/channel_ecosystem/shared/DashboardTopItem';
import * as ChannelMappingEndpoints from '../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../globals/Routes';
import ChannelHelper, {
	REPORT_BENEFICIAL_CUSTOMERS,
	REPORT_CUSTOMER_OPEN_PIPELINE,
	REPORT_GLOBAL_OVERLAP,
	REPORT_HOT_PROSPECTS,
	REPORT_MUTUAL_CUSTOMERS,
	REPORT_MUTUAL_PROSPECTS,
	REPORT_PROSPECT_OPEN_PIPELINE,
	REPORT_SEGMENT_GLOBAL,
	REPORT_SEGMENT_MID_MARKET,
	REPORT_SEGMENT_SMB,
	REPORT_SEGMENT_STARTUP
} from '../../../helpers/ChannelHelper';
import DialogHelper from '../../../helpers/DialogHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_VIEW_ECOSYSTEM_ACCOUNT_REPORTS} from '../../../helpers/FeatureHelper';
import {CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS} from '../../../helpers/ReportHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapPrimary, partnerTapSecondary, partnerTapWhite} from '../../../styles/partnertap_theme';
import Dialog from '../../../ui/Dialog';
import GraphLegend from '../../../ui/GraphLegend';
import BarGraph from '../../../ui/graphs/BarGraph';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import {PageHeader} from '../../../ui/PageHeader';
import Upsell from '../../../ui/Upsell';
import DashboardTable from '../shared/DashboardTable';

class CoSellDashboardPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: false,
			stackedCharts: [],
			connectedPartners: 0,
			channelUsers: 0,
			spreadsheetUploads: 0,
			totalAudits: 0
		};

		this.fetchDashboard = this.fetchDashboard.bind(this);
		this.onGraphClick = this.onGraphClick.bind(this);
		this.renderBarChartTip = this.renderBarChartTip.bind(this);
	}

	componentDidMount() {
		if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_ECOSYSTEM_ACCOUNT_REPORTS)) {
			this.fetchDashboard();
		}
		else {
			setTimeout(() => this.setState({showUpsell: 'Ecosystem Dashboard'}));
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get dataTableColumnData() {
		return [
			{title: 'Partners', key: 'partnerOrg', active: true},
			{title: 'Account Count', key: 'count', active: true}
		];
	}

	fetchDashboard() {
		this.setState({loading: true});
		ChannelMappingEndpoints.getDashboard({channelReportType: CHANNEL_REPORT_TYPE_GLOBAL_ACCOUNTS})
		.then((result) => {
			if (this.unmounted) return;
			if (!result) return;
			let {
				connectedPartnerCount,
				channelUserCount,
				partnerSpreadsheetCount,
				overlapSummaryList,
				segmentOverlapSummaryList,
				hotProspectsList,
				prospectsByOpenPipelineList,
				customersByOpenPipelineList
			} = result.payload;

			let stackedCharts = [];
			stackedCharts.push(
				this.createStackedBarChart(overlapSummaryList,
					[REPORT_MUTUAL_PROSPECTS, REPORT_MUTUAL_CUSTOMERS, REPORT_BENEFICIAL_CUSTOMERS, REPORT_HOT_PROSPECTS],
					JoinInner,
					'Co-Sell Introductions',
					'Introductions by partner organization'),
				this.createStackedBarChart(segmentOverlapSummaryList,
					[REPORT_SEGMENT_STARTUP, REPORT_SEGMENT_SMB, REPORT_SEGMENT_MID_MARKET, REPORT_SEGMENT_GLOBAL],
					JoinInner,
					'Co-Sell Segments',
					'Introductions by market segment')
			);

			let dataTables = [];
			dataTables.push(
				this.createDataTable(hotProspectsList,
					this.dataTableColumnData,
					'Our prospects that overlap with partner clients',
					REPORT_HOT_PROSPECTS),
				this.createDataTable(prospectsByOpenPipelineList,
					this.dataTableColumnData,
					'Our prospects with at least 1 open opp and our partner\'s customer',
					REPORT_PROSPECT_OPEN_PIPELINE),
				this.createDataTable(customersByOpenPipelineList,
					this.dataTableColumnData,
					'Our customers with at least 1 open opp and our partner\'s customer',
					REPORT_CUSTOMER_OPEN_PIPELINE)
			);

			this.setState({
				loading: false,
				stackedCharts: stackedCharts,
				dataTables: dataTables,
				connectedPartners: connectedPartnerCount,
				channelUsers: channelUserCount,
				spreadsheetUploads: partnerSpreadsheetCount
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchDashboard', error);
		});
	}

	createDataTable(rowData, columnData, message, reportType) {
		let title = ChannelHelper.getReportTypeTitle(reportType);
		return (
			<DashboardTable key={'dashboard_table_' + reportType}
							MaterialIcon={ChannelHelper.getReportTypeIcon(reportType)}
							title={title}
							message={message}
							columnData={columnData}
							rowData={rowData}
							rowClick={(rowItem) => this.openDetailDialog(title, reportType, rowItem)}
							hasScrollingContainerParent={true}/>
		);
	}

	renderBarChartTip(payload) {
		return payload.active &&
			   <div style={{
				   color: partnerTapSecondary,
				   border: '1px solid ' + partnerTapSecondary,
				   backgroundColor: partnerTapWhite,
				   borderRadius: 3,
				   padding: 10
			   }}>
				   {payload.label}: <em>Press for details</em>
			   </div>;
	}

	createStackedBarChart(partnerDataList, reportTypes, MaterialIcon, title, description) {
		let legend = reportTypes.map((reportType) => {
			let totalValue = 0;
			partnerDataList.forEach((partnerData) => {
				totalValue += partnerData[ChannelHelper.getReportTypeColumn(reportType)];
			});
			return {name: ChannelHelper.getReportTypeTitle(reportType), fill: ChannelHelper.getReportTypeColor(reportType), value: totalValue};
		});

		return (
			<div key={title}
				 style={{
					 flex: 1,
					 backgroundColor: partnerTapWhite,
					 boxShadow: partnerTapDropShadow,
					 padding: 10,
					 marginBottom: 10,
					 marginLeft: 5,
					 marginRight: 5,
					 maxWidth: 500,
					 minWidth: 300
				 }}>
				<div style={{paddingLeft: 10, paddingBottom: 20}}>
					<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
						<MaterialIcon style={{color: partnerTapPrimary}} fontSize={'large'}/>
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<div style={{fontSize: 20}}>{title}</div>
							<div style={{color: partnerTapInactive}}>{description}</div>
						</div>
					</div>
				</div>
				<div style={{flex: 1, display: 'flex', fontSize: EnvHelper.isDesktop ? 12 : 10}}>
					<ResponsiveContainer minWidth={200} minHeight={partnerDataList.length * 60}>
						<BarChart data={partnerDataList} layout={'vertical'} margin={{right: 20, left: 20}} key={title}>
							<XAxis type={'number'}/>
							<YAxis type={'category'} dataKey={'partnerOrg'}/>
							<CartesianGrid strokeDasharray={'3 3'}/>
							<Tooltip allowEscapeViewBox={{x: false, y: true}} offset={20} content={this.renderBarChartTip}/>
							{reportTypes.map((reportType, index) => {
								return <Bar key={index}
											stackId={title}
											dataKey={ChannelHelper.getReportTypeColumn(reportType)}
											name={ChannelHelper.getReportTypeTitle(reportType)}
											fill={ChannelHelper.getReportTypeColor(reportType)}
											onClick={(partnerDataItem) => this.onGraphClick(partnerDataItem, reportTypes, reportType, title)}/>;
							})}
						</BarChart>
					</ResponsiveContainer>
					<GraphLegend sections={legend}/>
				</div>
			</div>
		);
	}

	openDetailDialog(title, reportType, payload) {
		let path = Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.GLOBAL.REPORT.PATH(reportType);
		let org = encodeURIComponent(payload.partnerOrgCode && payload.partnerOrgCode.toLowerCase() || '');
		let params = '?partnerOrgCode=' + org + '&reportType=' + reportType;
		DialogHelper.openDetailDialog(title + ': ' + payload.partnerOrg, path, params, this.setState.bind(this));
	}

	onGraphClick(partnerDataItem, reportTypes, reportType, title) {
		let totalValue = 0;
		let sections = reportTypes.map((reportType) => {
			let value = partnerDataItem[ChannelHelper.getReportTypeColumn(reportType)];
			totalValue += value;
			return {
				reportType: reportType,
				value: value,
				name: ChannelHelper.getReportTypeTitle(reportType),
				fill: ChannelHelper.getReportTypeColor(reportType)
			};
		});
		sections.push({
			reportType: REPORT_GLOBAL_OVERLAP,
			value: totalValue,
			name: ChannelHelper.getReportTypeTitle(REPORT_GLOBAL_OVERLAP),
			fill: ChannelHelper.getReportTypeColor(REPORT_GLOBAL_OVERLAP)
		});

		let details = <BarGraph sections={sections}
								onBarClick={(section) => this.openDetailDialog(title, section.reportType, partnerDataItem)}
								noFrame={true}/>;
		let drillDownDialog = <Dialog title={title + ': ' + partnerDataItem.partnerOrg}
									  message={details}
									  yesAction={() => this.setState({drillDownDialog: null})}
									  noContentPadding={true}/>;
		this.setState({drillDownDialog: drillDownDialog});
	}

	render() {
		let {connectedPartners, stackedCharts, dataTables, showUpsell, loading} = this.state;
		if (showUpsell) return <Upsell showUpsell={showUpsell}/>;
		if (loading) return <Loading>Loading Dashboard...</Loading>;

		let actionListResults = {
			records: 480,
			initiated: 400,
			invalid: 80,
			bounces: 20,
			salesRepOptedIn: 384,
			partnerOptedIn: 386,
			salesRepDeclined: 16,
			partnerDeclined: 14,
			pending: 33,
			introductions: 337,
			crmActivities: 1020,
			actionListsSent: 12
		};

		return (
			<div>
				<PageHeader title={'Co-Sell Engine Dashboard'} icon={<Insights/>}/>
				<ScrollingContainer divId={'ecosystem_dashboard'}>
					<div style={{margin: 5, overflow: 'scroll'}}>
						<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'center'}}>
							<DashboardTopItem MaterialIcon={Air}
											  title={'Workflows'}
											  message={'Total automated workflows run'}
											  value={connectedPartners}/>
							<DashboardTopItem MaterialIcon={Business}
											  title={'Partner Organizations'}
											  message={'Total partner orgs engaged via workflows'}
											  value={connectedPartners}/>
							<DashboardTopItem MaterialIcon={Diversity3}
											  title={'Records'}
											  message={'Total records processed by workflows'}
											  value={actionListResults.records}/>
							<DashboardTopItem MaterialIcon={Verified}
											  title={'Initiated'}
											  message={'Total valid records detected by workflows'}
											  value={actionListResults.initiated}/>
							<DashboardTopItem MaterialIcon={FmdBad}
											  title={'Invalid'}
											  message={'Total invalid records with missing information'}
											  value={actionListResults.invalid}/>
							<DashboardTopItem MaterialIcon={CancelScheduleSend}
											  title={'Bounces'}
											  message={'Total records where email bounced'}
											  value={actionListResults.bounces}/>
							<DashboardTopItem MaterialIcon={ThumbUp}
											  title={'Sales Rep Opted In'}
											  message={'Total records where the sales rep opted in'}
											  value={actionListResults.salesRepOptedIn}/>
							<DashboardTopItem MaterialIcon={ThumbDown}
											  title={'Sales Rep Declined'}
											  message={'Total records where the sales rep declined'}
											  value={actionListResults.salesRepDeclined}/>
							<DashboardTopItem MaterialIcon={EmojiPeople}
											  title={'Partner Opted In'}
											  message={'Total records where the partner opted in'}
											  value={actionListResults.partnerOptedIn}/>
							<DashboardTopItem MaterialIcon={PersonOff}
											  title={'Partner Declined'}
											  message={'Total records where the partner declined'}
											  value={actionListResults.partnerDeclined}/>
							<DashboardTopItem MaterialIcon={ScheduleSend}
											  title={'Pending'}
											  message={'Total records waiting for a response'}
											  value={actionListResults.pending}/>
							<DashboardTopItem MaterialIcon={Handshake}
											  title={'Introductions'}
											  message={'Total introduction emails sent'}
											  value={actionListResults.introductions}/>
							<DashboardTopItem MaterialIcon={DriveFolderUpload}
											  title={'CRM Activities'}
											  message={'Total CRM Activities uploaded'}
											  value={actionListResults.crmActivities}/>
							<DashboardTopItem MaterialIcon={ForwardToInbox}
											  title={'Action Lists Sent'}
											  message={'Total Action Lists sent to sales reps'}
											  value={actionListResults.actionListsSent}/>
						</div>
						<div>
							<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
								{stackedCharts}
							</div>
							<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
								{dataTables}
							</div>
						</div>
					</div>
					{this.state.detailDialog}
					{this.state.drillDownDialog}
				</ScrollingContainer>
			</div>
		);
	}
}

export default withRouter(connect()(CoSellDashboardPage));
