import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as AccountEndpoints from '../../../endpoints/AccountsEndpoints';
import ChatButton from '../../../ui/buttons/ChatButton';
import PagingBase from '../../../ui/lists/PagingBase';
import AccountsRowRenderer from '../accounts/AccountRowRenderer';

export class PartnerAccountsPage extends PagingBase {

	get storageKeyBase() {
		return 'network_partners_accounts';
	}

	get title() {
		return 'Partner Accounts';
	}

	get icon() {
		return 'H';
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		AccountEndpoints.fetchSharedAccountsPage(params.page, params.pageSize, this.props.partner.personId, params.search)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					result.payload.forEach((account) => {
						account.partnerId = this.props.partner.partnerId;
						account.partnerPersonId = this.props.partner.personId;
						account.partnerName = this.props.firstName + ' ' + this.props.lastName;
						account.showChatIcon = true;
						account.partnerMatchCount = -1;
					});
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						You have no matched accounts with {this.props.partner.firstName}.
					</div>
					<div>
						Reach out for an intel chat.
					</div>
					<div style={{padding: 10}}>
						<ChatButton partnerId={this.props.partner.partnerId} partnerPersonId={this.props.partner.personId}/>
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	get rowRenderer() {
		return AccountsRowRenderer;
	}
}

PartnerAccountsPage.propTypes = {
	partner: PropTypes.object
};

export default withRouter(connect()(PartnerAccountsPage));
