import PropTypes from 'prop-types';
import React, {Component} from 'react';
import HubspotIcon from '../../../static/images/crm-icon-hubspot.png';
import AuthHelper from '../../helpers/AuthHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {hubspotColor} from '../../styles/partnertap_theme';
import TermsOfServiceDialog from '../TermsOfServiceDialog';
import PrimaryButton from './PrimaryButton';

class HubSpotButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showUserAgreementDialog: false, isTosChecked: false};
		this.onClick = this.onClick.bind(this);
		this.onUserAgreementDialogYes = this.onUserAgreementDialogYes.bind(this);
		this.onUserAgreementDialogNo = this.onUserAgreementDialogNo.bind(this);
	}

	onClick() {
		if (this.props.showTerms && !this.state.isTosChecked) {
			this.setState({showUserAgreementDialog: true});
		}
		else if (!this.props.disableLogin) {
			AuthHelper.hubSpotLogin();
		}
		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	onUserAgreementDialogYes() {
		this.setState({isTosChecked: true, showUserAgreementDialog: false}, this.onClick);
	}

	onUserAgreementDialogNo() {
		this.setState({showUserAgreementDialog: false});
	}

	render() {
		return (
			<div>
				<PrimaryButton label={this.props.label}
							   color={hubspotColor}
							   icon={<img src={EnvHelper.serviceUrl + HubspotIcon} style={{height: 18}}/>}
							   onClick={this.onClick}
							   fullWidth={true}/>
				{this.state.showUserAgreementDialog &&
				 <TermsOfServiceDialog onUserAgreementDialogYes={this.onUserAgreementDialogYes} onUserAgreementDialogNo={this.onUserAgreementDialogNo}/>}
			</div>
		);
	}
}

HubSpotButton.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	showTerms: PropTypes.bool,
	disableLogin: PropTypes.bool
};

export default HubSpotButton;
