import {ChecklistRtl, Diversity3, Insights} from '@mui/icons-material';
import _ from 'lodash';
import React from 'react';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {Routes} from '../../../globals/Routes';
import ChannelHelper from '../../../helpers/ChannelHelper';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import ReportHelper from '../../../helpers/ReportHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapSecondary} from '../../../styles/partnertap_theme';
import LinkButton from '../../../ui/buttons/LinkButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import Dialog from '../../../ui/Dialog';
import PagingBase from '../../../ui/lists/PagingBase';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import PageHeader from '../../../ui/PageHeader';
import PopoverSearchList from '../../../ui/PopoverSearchList';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import {isWorkflowAvailable, workflows} from './CoSellEngineHelper';

const ACTION_LIST_FILTER_ALL = 'All';
const ACTION_LIST_FILTER_ACTIVE = 'Active';
const ACTION_LIST_FILTER_INACTIVE = 'Inactive';
const ACTION_LIST_FILTER_LOCKED = 'Locked';
const ACTION_LIST_FILTER_UNLOCKED = 'Unlocked';
const actionListFilters = [
	ACTION_LIST_FILTER_ALL,
	ACTION_LIST_FILTER_ACTIVE,
	ACTION_LIST_FILTER_INACTIVE,
	ACTION_LIST_FILTER_LOCKED,
	ACTION_LIST_FILTER_UNLOCKED
];

class CoSellOverviewPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.unfilteredRowData = null;

		this.state.actionListFilter = ACTION_LIST_FILTER_ALL;
		this.state.showDialogDeleteActionList = null;
		this.state.showDialogWorkflow = null;

		this.cloneCoSellActionList = this.cloneCoSellActionList.bind(this);
		this.filterActionLists = this.filterActionLists.bind(this);
		this.cancelWorkflowSelection = this.cancelWorkflowSelection.bind(this);
		this.goToActionListConfig = this.goToActionListConfig.bind(this);
	}

	get storageKeyBase() {
		return 'co_sell_engine';
	}

	get title() {
		return 'Action Lists';
	}

	get icon() {
		return ChecklistRtl;
	}

	get componentAboveToolbar() {
		let navTileData = _.clone(workflows);
		navTileData.push(
			{
				name: 'Co-Sell Dashboard',
				description: 'See all results from your co-sell actions',
				MaterialIcon: Insights,
				navLabel: 'VIEW',
				navRoute: Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.DASHBOARD.ROUTE
			}
		);

		let tileStyle = TableHelper.tileStyle(250, 250, partnerTapSecondary, 10, 'pointer');
		tileStyle.margin = 5;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<div style={{width: '100%'}}>
					<PageHeader title={'Co-Sell Engine'} icon={<Diversity3 fontSize={'large'}/>}/>
				</div>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexWrap: 'wrap',
					marginBottom: 10,
					maxHeight: '50vh',
					overflow: 'scroll'
				}}>
					{navTileData.map((navTile, index) => {
						let actionFunction = () => {
							if (navTile.navRoute) {
								EnvHelper.push(navTile.navRoute);
							}
							else {
								this.setState({showDialogWorkflow: navTile});
							}
						};
						return (
							<div key={index} style={tileStyle} onClick={actionFunction}>
								<div style={{fontSize: 20, fontWeight: 'bold'}}>
									{navTile.workflow?.name || navTile.name}
								</div>
								<navTile.MaterialIcon style={{fontSize: 60, color: partnerTapSecondary, padding: 5}}/>
								{navTile.workflow?.description || navTile.description}
								<PrimaryButton label={navTile.navLabel || 'START'} onClick={actionFunction}/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	get selectActionListDialog() {
		let {showDialogWorkflow, rowData, selectedActionList, selectionMissing} = this.state;
		let availableActionLists = rowData.filter((actionListInfo) => isWorkflowAvailable(actionListInfo, showDialogWorkflow));
		return (
			<Dialog title={'Add ' + showDialogWorkflow.name + ' Workflow'}
					message={availableActionLists.length > 0 ?
						<div>
							<div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 10}}>
								Select an Action List for your {showDialogWorkflow.name}
							</div>
							<DropdownMenu title={'Action List'}
										  options={availableActionLists}
										  selectedOption={selectedActionList}
										  optionLabel={'actionListName'}
										  isSelectionMissing={selectionMissing}
										  selectionMissingMessage={'Please select an Action List'}
										  onSelect={(row) => this.setState({selectedActionList: row})}/>
						</div>
						:
						<div>
							You don't have any Action Lists available for the {showDialogWorkflow.name} Workflow
						</div>}
					yesLabel={availableActionLists.length > 0 ? 'CONTINUE' : 'CANCEL'}
					yesAction={availableActionLists.length > 0 ? () => this.goToActionListConfig(selectedActionList, showDialogWorkflow) : this.cancelWorkflowSelection}
					noLabel={availableActionLists.length > 0 ? 'CANCEL' : null}
					noAction={availableActionLists.length > 0 ? this.cancelWorkflowSelection : null}/>
		);
	}

	cancelWorkflowSelection() {
		this.setState({showDialogWorkflow: null, selectedActionList: null});
	}

	goToActionListConfig(selectedActionList, workflow) {
		if (selectedActionList) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(selectedActionList.id, workflow.config, workflow.sequence));
		}
		else {
			this.setState({selectionMissing: true});
		}
	}

	get additionalToolbarButtons() {
		return [
			<PopoverSearchList key={'filter'}
							   list={actionListFilters}
							   preselectedItem={this.state.actionListFilter}
							   labelRenderer={(filter) => 'Show ' + filter}
							   onItemSelected={this.filterActionLists}/>
		];
	}

	get columnData() {
		return [
			{
				title: 'Name',
				key: 'actionListName',
				renderFunction: (columnValue, rowData) =>
					<LinkButton label={columnValue} onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(rowData.id))}/>
			},
			{
				title: 'Actions',
				key: ACTIONS,
				renderFunction: (columnValue, rowData) => {
					let isImmutable = rowData.configuredWorkflows.length > 0;
					return (
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
							<LinkButton label={'CLONE'} onClick={() => this.cloneCoSellActionList(rowData)}/>
							<LinkButton label={'DELETE'} disabled={isImmutable} onClick={() => this.setState({showDialogDeleteActionList: rowData})}/>
						</div>
					);
				}
			},
			{title: 'Description', key: 'actionListDescription', active: false},
			{title: 'Partner Org', key: 'partnerOrgName'},
			{title: 'Asset Type', key: 'assetType', renderFunction: ReportHelper.getAssetTypeDisplayName},
			{title: 'Active', key: 'isActive', type: 'boolean'},
			{title: 'Locked', key: 'isLocked', type: 'boolean'},
			{title: 'Created By', key: 'createdByPersonName'},
			{title: 'Created Date', key: 'createdDate', type: 'date'},
			{title: 'Expiration Date', key: 'expirationDate', type: 'date', active: false}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {columnData} = this.state;
		let params = this.processParameters();
		CoSellEngineEndpoints.getAllCoSellActionLists(params.search, params.page, params.pageSize, params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertTypedValues(result.payload, columnData);
					this.unfilteredRowData = result.payload;
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {actionListFilter} = this.state;
			if (actionListFilter !== ACTION_LIST_FILTER_ALL) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							You don't have any {actionListFilter.toLowerCase()} Action Lists.
						</div>
						<PrimaryButton label={'CLEAR FILTER'} onClick={() => this.filterActionLists(ACTION_LIST_FILTER_ALL)}/>
					</div>
				);
			}

			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						You haven't saved any Action Lists. Action Lists can be saved from account and opportunity reports.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	cloneCoSellActionList(actionList) {
		this.setState({saving: true});
		CoSellEngineEndpoints.cloneCoSellActionList(actionList.id)
		.then((result) => {
			this.setState({loading: true, saving: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from cloneCoSellActionList', error);
		});
	}

	deleteCoSellActionList(actionList) {
		this.setState({saving: true, showDialogDeleteActionList: null});
		CoSellEngineEndpoints.deleteCoSellActionList(actionList.id)
		.then((result) => {
			this.setState({loading: true, saving: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from deleteCoSellActionList', error);
		});
	}

	filterActionLists(value) {
		let filteredRowData = this.unfilteredRowData;
		if (value !== ACTION_LIST_FILTER_ALL) {
			filteredRowData = this.unfilteredRowData.filter((actionList) => {
				return ((value === ACTION_LIST_FILTER_ACTIVE && actionList.isActive) ||
						(value === ACTION_LIST_FILTER_INACTIVE && !actionList.isActive) ||
						(value === ACTION_LIST_FILTER_LOCKED && actionList.isLocked) ||
						(value === ACTION_LIST_FILTER_UNLOCKED && !actionList.isLocked));
			});
		}
		this.setState({actionListFilter: value, rowData: filteredRowData});
	}

	render() {
		let {saving, showDialogDeleteActionList, showDialogWorkflow} = this.state;
		return (
			<div>
				{saving &&
				 <ScrimMessage message={'Saving...'}/>}
				{showDialogDeleteActionList &&
				 <Dialog title={'Delete Action List'}
						 message={
							 <div style={{textAlign: 'center'}}>
								 <div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 20}}>
									 Are you sure you want to delete '{showDialogDeleteActionList.actionListName}'?
								 </div>
								 <div>
									 Deleting an Action List cannot be undone.
								 </div>
							 </div>}
						 yesLabel={'DELETE'}
						 yesAction={() => this.deleteCoSellActionList(showDialogDeleteActionList)}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showDialogDeleteActionList: null})}/>}
				{showDialogWorkflow &&
				 this.selectActionListDialog}
				{super.render()}
			</div>
		);
	}
}

export default CoSellOverviewPage;
