import _ from 'lodash';
import objectAssign from 'object-assign';
import PersistenceHelper from '../helpers/PersistenceHelper';
import * as  ReduxEnum from './ReduxEnum';

export default function managerAnalyticsState(state = {
	currentCompanyName: null,
	orgId: null,
	currentRep: {name: '', personId: ''},
	searchRange: -1,
	division: null,
	managerDetails: {},
	forceAllTime: false
}, action) {
	switch (action.type) {
		case ReduxEnum.MANAGER_COMPANY_NAME: {
			let newState = objectAssign({}, state);
			newState.currentCompanyName = action.payload.name;
			newState.orgId = action.payload.id;
			return newState;
		}
		case ReduxEnum.MANAGER_REP_NAME: {
			let newState = objectAssign({}, state);
			newState.currentRep = action.payload;
			return newState;
		}
		case ReduxEnum.MANAGER_SEARCH_RANGE: {
			let newState = objectAssign({}, state);
			newState.searchRange = action.payload;
			return newState;
		}
		case ReduxEnum.MANAGER_ADMIN_DIVISION: {
			let newState = objectAssign({}, state);
			newState.division = action.payload;
			if (!_.isEmpty(newState.managerDetails) && newState.managerDetails.divisions) {
				for (let i = 0; i < newState.managerDetails.divisions.length; i++) {
					newState.managerDetails.divisions[i].isChecked = false;
				}
			}
			return newState;
		}
		case ReduxEnum.MANAGER_MANAGER_DIVISION: {
			let newState = objectAssign({}, state);
			newState.division = action.payload;
			return newState;
		}
		case ReduxEnum.MANAGER_DIVISION_STATUS: {
			let newState = objectAssign({}, state);
			newState.division = action.payload;
			PersistenceHelper.setValue(ReduxEnum.MANAGER_DIVISION_STATUS, JSON.stringify(action.payload));
			return newState;
		}
		case ReduxEnum.CLEAR_MANAGER_DIVISION_STATUS: {
			let newState = objectAssign({}, state);
			PersistenceHelper.removeValue(ReduxEnum.MANAGER_DIVISION_STATUS);
			newState.division = null;
			return newState;
		}
		case ReduxEnum.MANAGER_SET_FORCE_ALL_TIME: {
			let newState = objectAssign({}, state);
			newState.forceAllTime = action.payload;
			newState.searchRange = newState.forceAllTime ? -1 : newState.searchRange;
			return newState;
		}
		default:
			return state;
	}
}
