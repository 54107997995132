import {Diversity3} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import Dialog from '../Dialog';
import ScrimMessage from '../messages/ScrimMessage';
import TextInputBox from '../TextInputBox';
import ActionButton from './ActionButton';
import * as CoSellEngineEndpoints from '../../endpoints/CoSellEngineEndpoints';
import FeatureHelper, {FEATURE_COSELL_CREATE_ACTION_LISTS} from '../../helpers/FeatureHelper';
import MessageBox from '../messages/MessageBox';
import LinkButton from './LinkButton';

const MAX_ROW_COUNT = 10000;

class CoSellSaveButtonLive extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			showUpsellDialog: false,
			showSaveListInfoDialog: false,
			actionListName: '',
			actionListDescription: '',
			isActionListNameMissing: false,
			isActionListDescriptionMissing: false,
			showSaveInProgressDialog: false,
			showLoadingScrim: false
		};

		this.showUpsell = this.showUpsell.bind(this);
		this.onSave = this.onSave.bind(this);
		this.showInvalidList = this.showInvalidList.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	showUpsell() {
		this.setState({showUpsellDialog: true});
	}

	showInvalidList() {
		this.setState({showInvalidListDialog: true});
	}

	onSaveActionList() {
		let {actionListName, actionListDescription} = this.state;
		if (!actionListName) {
			this.setState({isActionListNameMissing: true});
			return;
		}
		if (!actionListDescription) {
			this.setState({isActionListDescriptionMissing: true});
			return;
		}
		this.setState({showSaveListInfoDialog: false, showLoadingScrim: true});
		let actionListConfig = this.props.callingPage.coSellReportConfigLive;
		actionListConfig.name = actionListName || actionListConfig.exportBaseFileName;
		actionListConfig.description = actionListDescription;
		CoSellEngineEndpoints.createCoSellActionListLive(actionListConfig)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({showLoadingScrim: false, showSaveCompleteDialog: true, actionListName: '', actionListDescription: ''});
		})
		.catch((error) => {
			this.setState({showLoadingScrim: false});
			EnvHelper.serverError('Error from onSaveActionList', error);
		});
	}

	get hasValidPartnerOrg() {
		let actionListConfig = this.props.callingPage.coSellReportConfigLive;
		return actionListConfig.partnerOrgPublicId;
	}

	get listTooLarge() {
		let actionListConfig = this.props.callingPage.coSellReportConfigLive;
		return actionListConfig.rowCount > MAX_ROW_COUNT;
	}

	onSave() {
		let actionListConfig = this.props.callingPage.downloadReportConfig;
		this.setState({showSaveListInfoDialog: true, actionListName: actionListConfig.exportBaseFileName});
	}

	get invalidMessage() {
		let message = 'Your list is invalid.';
		if (!this.hasValidPartnerOrg) {
			message = <div>Your report must be associated with a Partner Org.</div>;
		}
		else if (this.listTooLarge) {
			message =
				<div>
					<p>The creation of your action list failed because it contained too many rows.</p>
					<p>Consider applying additional filters to reduce the size of your action list to less than or equal
						to {MAX_ROW_COUNT.toLocaleString()} rows
						and try again.</p>
				</div>;
		}
		return message;
	}

	render() {
		let action = this.onSave;
		if (!FeatureHelper.isFeatureEnabled(FEATURE_COSELL_CREATE_ACTION_LISTS)) {
			action = this.showUpsell;
		}
		if (!this.hasValidPartnerOrg || this.listTooLarge) {
			action = this.showInvalidList;
		}
		let {actionListName, actionListDescription, isActionListNameMissing, isActionListDescriptionMissing} = this.state;
		return (
			<div key={'co_sell_save_action_list_button'} style={{display: 'flex'}}>
				{this.state.showLoadingScrim &&
				 <ScrimMessage message={'Saving Co-Sell Action List...'}/>}
				{this.state.showInvalidListDialog &&
				 <Dialog title={'Invalid Report'}
						 message={<div style={{padding: 20}}>
							 {this.invalidMessage}
						 </div>}
						 yesAction={() => this.setState({showInvalidListDialog: false})}
						 noContentPadding={true}/>
				}
				{this.state.showUpsellDialog &&
				 <Dialog title={'Upgrade Required'}
						 message={<MessageBox hideIcon={true}>
							 <div>
								 To save a report as an action list, you must have the <LinkButton label={'Co-Sell Engine'}
																								   onClick={() => window.open('https://partnertap.com/products/co-sell-automation/')}/> enabled.
							 </div>
							 <div>
								 The Co-Sell Engine automates sales plays for your sales teams and partners.
							 </div>
							 <div>
								 Contact your PartnerTap Customer Success Manager or <LinkButton label={'customer support'}
																								 onClick={() => window.open('https://partnertap.com/support/')}/> to
								 start using action lists.
							 </div>
						 </MessageBox>}
						 yesAction={() => this.setState({showUpsellDialog: false})}
						 noContentPadding={true}/>}
				<ActionButton toolTip={'Save for Co-Sell Engine LIVE'} onAction={action} icon={<Diversity3/>}/>
				{this.state.showSaveListInfoDialog &&
				 <Dialog title={'Save Action List for Co-Sell Workflow'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400, margin: 'auto'}}>
								 <div>
									 Your action list will contain the exact rows and columns currently shown in this report.
								 </div>
								 <div style={{paddingBottom: 10}}>
									 You'll have the option to remove rows and columns prior to running a workflow.
								 </div>
								 <TextInputBox hintText={'Action List Name'}
											   value={actionListName}
											   onChange={(value) => this.setState({actionListName: value, isActionListNameMissing: !value})}
											   errorText={isActionListNameMissing ? 'Name is required' : null}
											   doNotAutoFocus={true}
											   maxChars={100}/>
								 <TextInputBox hintText={'Action List Description'}
											   placeholderText={'Please tell your co-sell automation leader the strategy and next steps for this action list'}
											   onChange={(value) => this.setState({actionListDescription: value, isActionListDescriptionMissing: !value})}
											   errorText={isActionListDescriptionMissing ? 'Description is required' : null}
											   multiLine={true}
											   rows={3}
											   maxChars={500}/>
							 </div>}
						 yesLabel={'Save'}
						 noLabel={'Cancel'}
						 noAction={() => this.setState({showSaveListInfoDialog: false})}
						 yesAction={() => this.onSaveActionList()}/>}
				{this.state.showSaveCompleteDialog &&
				 <Dialog title={'Action List Saved'}
						 message={
							 <div>
								 <div>Your action list has been saved.</div>
								 <div>You can view action lists and start workflows in Co-Sell Engine.</div>
							 </div>}
						 yesLabel={'Go to Co-Sell Engine'}
						 noLabel={'Close'}
						 noAction={() => {
							 this.setState({showSaveCompleteDialog: false});
						 }}
						 yesAction={() => {
							 this.setState({showSaveCompleteDialog: false});
							 EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.OVERVIEW.ROUTE);
						 }}/>}
			</div>
		);
	}
}

CoSellSaveButtonLive.propTypes = {
	callingPage: PropTypes.object
};

export default connect()(CoSellSaveButtonLive);
