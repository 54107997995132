import PropTypes from 'prop-types';
import React, {Component} from 'react';
import PopoverSearchList from '../PopoverSearchList';
import SecondaryButton from './SecondaryButton';

class MenuButton extends Component {

	render() {
		let selectedItem = this.props.menuData.find((item) => {
			return item.selected;
		});
		let {MaterialIcon} = this.props;
		return (
			<PopoverSearchList list={this.props.menuData}
							   preselectedItem={selectedItem}
							   labelRenderer={(item) => item.name}
							   valueRenderer={(item) => item.key}
							   onItemSelected={(item) => this.props.onSelect(item.key)}
							   searchByObjectKeys={['name']}
							   customToggleButton={<SecondaryButton icon={<MaterialIcon/>}
																	toolTip={this.props.hideLabel ? this.props.label : ''}
																	requiresEventPropagation={true}/>}/>
		);
	}
}

MenuButton.propTypes = {
	label: PropTypes.string.isRequired,
	hideLabel: PropTypes.bool,
	MaterialIcon: PropTypes.elementType.isRequired,
	menuData: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		selected: PropTypes.bool
	})),
	onSelect: PropTypes.func.isRequired
};

export default MenuButton;
