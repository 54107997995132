import {Check, Dangerous, Lock} from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapAlert, partnerTapInactive, partnerTapTernary, partnerTapTernaryLight, partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Dialog from '../../../ui/Dialog';
import Loading from '../../../ui/Loading';
import DropdownMenu from '../../../ui/selectors/DropdownMenu';
import {
	sequenceShortName,
	WORKFLOW_CRM_TRACKING,
	WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL,
	WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL,
	WORKFLOW_INTRO_EMAIL,
	WORKFLOW_SHARE_ACTION_LIST,
	WORKFLOW_STATUS_CANCELED,
	WORKFLOW_STATUS_STARTED,
	workflows
} from './CoSellEngineHelper';
import CoSellWorkflowConfig from './CoSellWorkflowConfig';

class CoSellActionListWorkflow extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {showCancelWorkflowDialog: false, showLockActionListDialog: false, showDialogWorkflow: null};

		this.openSelectedWorkflowEditor = this.openSelectedWorkflowEditor.bind(this);
		this.closeSelectedWorkflowEditor = this.closeSelectedWorkflowEditor.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.addWorkflowOnce) return;
		let {actionListInfo} = this.props;
		if (actionListInfo) {
			let {workflowConfig} = this.props.match.params;
			if (workflowConfig) {
				this.addWorkflowOnce = true;
				let selectedWorkflow;
				let {workflowSequence} = this.props.match.params;
				if (workflowSequence) {
					selectedWorkflow = workflows.find((workflow) => workflow.config === workflowConfig && workflow.sequence === workflowSequence);
				}
				else {
					selectedWorkflow = workflows.find((workflow) => workflow.config === workflowConfig);
				}
				this.setState({showDialogWorkflow: selectedWorkflow});
			}
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	openSelectedWorkflowEditor(selectedWorkflow) {
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(this.props.match.params.actionListId, selectedWorkflow.config, selectedWorkflow.sequence));
	}

	closeSelectedWorkflowEditor() {
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.ACTION_LIST.PATH(this.props.match.params.actionListId));
	}

	saveChanges(functionCallAfterSave) {
		let {actionListInfo} = this.props;
		this.setState({showCancelWorkflowDialog: false, showLockActionListDialog: false});
		CoSellEngineEndpoints.updateCoSellActionList(actionListInfo)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({savingActionListInfo: false});
			if (functionCallAfterSave) {
				functionCallAfterSave();
			}
		})
		.catch((error) => {
			this.setState({savingActionListInfo: false});
			EnvHelper.serverError('Error from saveChanges', error);
		});
	}

	renderWorkflowControls() {
		let {actionListInfo, parentComponent} = this.props;
		if (!actionListInfo) return;
		let {showCancelWorkflowDialog, showLockActionListDialog} = this.state;
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				padding: 10,
				paddingLeft: 20,
				gap: 10,
				borderRadius: 5,
				backgroundColor: partnerTapWhite
			}}>
				{actionListInfo.status === WORKFLOW_STATUS_CANCELED ?
					<div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 16, fontWeight: 'bold', color: partnerTapAlert}}>
						<Dangerous/> Workflow Canceled
					</div>
					:
					<DropdownMenu title={'Start'}
								  options={workflows}
								  optionLabel={'name'}
								  onSelect={(workflow) => this.openSelectedWorkflowEditor(workflow)}/>}

				<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 10}}>
					{actionListInfo.configuredWorkflows.map((workflowConfig) => this.renderConfiguredWorkflow(workflowConfig))}
				</div>

				<div style={{display: 'flex', justifyContent: 'center', gap: 10}}>
					{actionListInfo.status === WORKFLOW_STATUS_STARTED &&
					 <PrimaryButton label={'CANCEL'}
									icon={<Dangerous/>}
									onClick={() => this.setState({showCancelWorkflowDialog: true})}/>}
					<SecondaryButton label={actionListInfo.isLocked ? 'LOCKED' : 'LOCK'}
									 icon={<Lock/>}
									 disabled={actionListInfo.isLocked}
									 onClick={() => this.setState({showLockActionListDialog: true})}/>
				</div>

				{showLockActionListDialog &&
				 <Dialog title={'Lock Action List?'}
						 message={
							 <div style={{textAlign: 'center'}}>
								 <div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 20}}>
									 Are you sure you want to lock this Action List?
								 </div>
								 <div>
									 A locked Action List will contain exactly what you see in this report, and can no longer be edited.
								 </div>
								 <div>
									 Locking an Action List cannot be undone.
								 </div>
							 </div>}
						 yesLabel={'YES, LOCK NOW'}
						 yesAction={() => {
							 actionListInfo.isLocked = true;
							 this.saveChanges(() => parentComponent.setState({loading: true}));
						 }}
						 noLabel={'DO NOT LOCK'}
						 noAction={() => this.setState({showLockActionListDialog: false})}/>}

				{showCancelWorkflowDialog &&
				 <Dialog title={'Cancel Action List Workflow?'}
						 message={
							 <div style={{textAlign: 'center'}}>
								 <div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 20}}>
									 Are you sure you want to cancel workflow for this Action List?
								 </div>
								 <div>
									 Cancelling will suspended all workflow automation.
								 </div>
								 <div>
									 Workflow participants will not be able to take any further action.
								 </div>
							 </div>}
						 yesLabel={'YES, CANCEL NOW'}
						 yesAction={() => {
							 actionListInfo.status = WORKFLOW_STATUS_CANCELED;
							 actionListInfo.isActive = false;
							 this.saveChanges();
						 }}
						 noLabel={'DO NOT CANCEL'}
						 noAction={() => this.setState({showCancelWorkflowDialog: false})}/>}
			</div>
		);
	}

	renderConfiguredWorkflow(workflowConfig) {
		let {actionListInfo} = this.props;

		let workflow = workflows.find((workflow) => workflow.config === workflowConfig);
		let workflowSequenceLabel;
		if (workflowConfig === WORKFLOW_SHARE_ACTION_LIST) {
			workflowSequenceLabel = sequenceShortName[actionListInfo.workflowSequenceShareActionList];
		}
		else if (workflowConfig === WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL) {
			workflowSequenceLabel = sequenceShortName[actionListInfo.workflowSequenceDistributeRecordsInternal];
		}
		else if (workflowConfig === WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL) {
			workflowSequenceLabel = sequenceShortName[actionListInfo.workflowSequenceDistributeRecordsExternal];
		}
		else if (workflowConfig === WORKFLOW_INTRO_EMAIL) {
			workflowSequenceLabel = sequenceShortName[actionListInfo.workflowSequenceIntroEmail];
		}
		else if (workflowConfig === WORKFLOW_CRM_TRACKING) {
			workflowSequenceLabel = sequenceShortName[actionListInfo.workflowSequenceCrmTracking];
		}

		return (
			<div key={workflowConfig}
				 style={{
					 display: 'flex',
					 alignItems: 'center',
					 flexDirection: 'column',
					 textAlign: 'center',
					 whiteSpace: 'nowrap',
					 gap: 2,
					 padding: 5,
					 paddingLeft: 10,
					 paddingRight: 15,
					 backgroundColor: partnerTapTernaryLight,
					 borderRadius: 10,
					 border: '1px solid ' + partnerTapTernary
				 }}>
				<div style={{display: 'flex', alignItems: 'center', gap: 5}}>
					<Check style={{color: partnerTapTernary}}/>
					{workflow.name}
				</div>
				<div style={{fontSize: 12, color: partnerTapInactive}}>
					{workflowSequenceLabel}
				</div>
				<div style={{fontSize: 12, color: partnerTapInactive}}>
					{DateHelper.epochToDate(actionListInfo.executedWorkflowDates[workflowConfig] / 1000)}
				</div>
			</div>
		);
	}

	render() {
		let {actionListInfo, workflowRecords} = this.props;
		let {showDialogWorkflow} = this.state;
		return (
			<div style={{width: '100%', display: 'flex', flexDirection: 'column', gap: 10}}>
				{!actionListInfo ?
					<Loading>Loading Action List Workflow...</Loading> :
					this.renderWorkflowControls()}
				{(actionListInfo && showDialogWorkflow) &&
				 <Dialog title={showDialogWorkflow.name + ': ' + actionListInfo.actionListName}
						 message={<CoSellWorkflowConfig actionListInfo={actionListInfo} workflow={showDialogWorkflow} workflowRecords={workflowRecords}/>}
						 noContentPadding={true}
						 useFullScreen={true}
						 yesLabel={'Close'}
						 yesAction={this.closeSelectedWorkflowEditor}/>}
			</div>
		);
	}
}

CoSellActionListWorkflow.propTypes = {
	parentComponent: PropTypes.object.isRequired,
	actionListInfo: PropTypes.object,
	workflowRecords: PropTypes.array
};

export default withRouter(CoSellActionListWorkflow);
