import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapAlert} from '../../styles/partnertap_theme';

class DropdownMenu extends Component {

	render() {
		return (
			<div style={{display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 5}}>
				<FormControl sx={{width: this.props.width || 340}} size={'small'} disabled={this.props.disabled}>
					{this.props.title && <InputLabel>{this.props.title}</InputLabel>}
					<Select value={this.props.selectedOption || ''}
							input={this.props.title ? <OutlinedInput label={this.props.title}/> : null}
							onChange={(selectionEvent) => this.props.onSelect(selectionEvent.target.value)}>
						{this.props.options.map((option, index) => {
							return (
								<MenuItem key={index} value={option} disabled={option.disabled}>
									<b>{option[this.props.optionLabel || 'label']}</b>
								</MenuItem>
							);
						})}
					</Select>
					{this.props.isSelectionMissing &&
					 <FormHelperText>
						 <div style={{color: partnerTapAlert}}>
							 {this.props.selectionMissingMessage}
						 </div>
					 </FormHelperText>}
				</FormControl>
			</div>
		);
	}
}

DropdownMenu.propTypes = {
	title: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSelect: PropTypes.func.isRequired,
	selectedOption: PropTypes.object,
	isSelectionMissing: PropTypes.bool,
	selectionMissingMessage: PropTypes.string,
	optionLabel: PropTypes.string,
	width: PropTypes.number,
	disabled: PropTypes.bool
};

export default DropdownMenu;
