import {Link} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as PartnerOrgEndpoints from '../../../endpoints/PartnerOrgEndpoints';
import FeatureHelper, {FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY} from '../../../helpers/FeatureHelper';
import StringHelper from '../../../helpers/StringHelper';
import Dialog from '../../../ui/Dialog';
import PagingBase from '../../../ui/lists/PagingBase';
import TextInputBox from '../../../ui/TextInputBox';
import Upsell from '../../../ui/Upsell';
import LinkButton from '../../../ui/buttons/LinkButton';
import EnvHelper from '../../../helpers/EnvHelper';

class PartnerOrgCrmId extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.editingPartnerOrg = null;
		this.state.crmId = null;

		this.updatePartnerOrg = this.updatePartnerOrg.bind(this);
	}

	get storageKeyBase() {
		return 'link_partner_crm';
	}

	get title() {
		return 'Partner Organizations';
	}

	get icon() {
		return Link;
	}

	get columnData() {
		return [
			{
				title: 'Partner Organization',
				key: 'partnerName',
				renderFunction: (columnValue, rowData) =>
					<LinkButton label={columnValue} disabled={EnvHelper.isSpoofing} onClick={() => this.setState({editingPartnerOrg: rowData})}/>
			},
			{title: 'CRM Record ID', key: 'crmRecordId'}
		];
	}

	updatePartnerOrg() {
		let {editingPartnerOrg, crmId} = this.state;
		if (crmId === '' || crmId) {
			PartnerOrgEndpoints.updatePartnerOrgCrmId(editingPartnerOrg.companyPartnerPublicId, editingPartnerOrg.partnerName, crmId)
			.then((result) => {
				this.setState({editingPartnerOrg: null, crmId: null, updateError: null, loading: true});
			})
			.catch((error) => {
				console.error('Error from updatePartnerOrg', error);
				this.setState({updateError: error.toString()});
			});
		}
		else {
			this.setState({updateError: 'Please enter a valid ID'});
		}
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		PartnerOrgEndpoints.getPartnerOrgs(params.page, params.pageSize, params.search, params.sort)
		.then((result) => {
				if (this.unmounted) return;
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY)) {
			return <Upsell showUpsell={'Partner Organizations'}/>;
		}

		let {editingPartnerOrg, crmId, updateError} = this.state;
		return (
			<div>
				{super.render()}
				{editingPartnerOrg &&
				 <Dialog title={'Paste CRM Record ID for ' + editingPartnerOrg.partnerName}
						 message={
							 <div style={{minWidth: 400}}>
								 <TextInputBox value={crmId !== null ? crmId : editingPartnerOrg.crmRecordId}
											   hintText={'CRM Record ID'}
											   errorText={updateError}
											   onChange={(value) => this.setState({crmId: StringHelper.removeWhiteSpace(value), updateError: null})}/>
							 </div>}
						 yesLabel={'SAVE'}
						 yesAction={this.updatePartnerOrg}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({editingPartnerOrg: null, crmId: null, updateError: null})}
				 />}
			</div>
		);
	}
}

export default connect()(PartnerOrgCrmId);
