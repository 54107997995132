import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as PartnersEndpoints from '../../../endpoints/PartnersEndpoints';
import FindPartnersButton from '../../../ui/buttons/FindPartnersButton';
import PagingBase from '../../../ui/lists/PagingBase';
import PartnerRowRenderer from '../partners/PartnerRowRenderer';

export class AccountPartnersPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.forShareOpportunities = this.props.forShareOpportunities;
		this.state.sharedOpportunities = this.props.sharedOpportunities;
		this.state.shareClick = this.props.shareClick;
		this.state.getSharedOpportunity = this.props.getSharedOpportunity;
	}

	get storageKeyBase() {
		if (this.props.forShareOpportunities) return 'network_accounts_partners_share_opportunities';
		return 'network_accounts_partners';
	}

	get title() {
		if (this.state.forShareOpportunities) return 'Share Settings';
		return 'Account Partners';
	}

	get icon() {
		if (this.state.forShareOpportunities) return 'k';
		return 'L';
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		PartnersEndpoints.fetchAccountPartnersPage(params.page, this.props.account.saleAccountLocationId, params.search)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					result.payload.forEach((partner) => {
						partner.name = partner.firstName + ' ' + partner.lastName;
						partner.matchedAccountCount = partner.accountMatchCount;
						partner.status = partner.partnerStatus;
						partner.forAccountDetails = true;
						partner.forShareOpportunities = this.state.forShareOpportunities;
						partner.sharedOpportunities = this.state.sharedOpportunities;
						partner.shareClick = this.state.shareClick;
						partner.getSharedOpportunity = this.state.getSharedOpportunity;
						partner.account = this.props.account;
					});
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						No partners found for {this.props.account.name}
					</div>
					<div>
						Search for partners or invite them by email.
					</div>
					<div style={{padding: 10}}>
						<FindPartnersButton/>
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	get rowRenderer() {
		return PartnerRowRenderer;
	}
}

AccountPartnersPage.propTypes = {
	account: PropTypes.object,
	forShareOpportunities: PropTypes.bool,
	sharedOpportunities: PropTypes.array,
	shareClick: PropTypes.func,
	getSharedOpportunity: PropTypes.func,
	accountsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		accountsState: state.accountsState
	};
}

export default connect(mapStateToProps)(AccountPartnersPage);
