import {InfoSharp} from '@mui/icons-material';
import {partnerTapPrimary} from '../../styles/partnertap_theme';
import MessageBox from './MessageBox';

class MessageBoxInfo extends MessageBox {

	get IconClass() {
		return InfoSharp;
	}

	get color() {
		return partnerTapPrimary;
	}
}

export default MessageBoxInfo;
