import React from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import {ASSET_TYPES} from '../../../../globals/Enums';
import ReportHelper from '../../../../helpers/ReportHelper';
import DropdownMenu from '../../../../ui/selectors/DropdownMenu';
import TextInputBox from '../../../../ui/TextInputBox';
import {WORKFLOW_CRM_TRACKING, workflows} from '../CoSellEngineHelper';
import {AdminConfigBase} from './AdminConfigBase';

export class AdminCrmTracking extends AdminConfigBase {

	get columnData() {
		let columns = super.columnData;
		columns.splice(2, 0, {title: 'Activity Title', key: 'activityTitle', active: true});
		columns.splice(3, 0, {
			title: 'Asset Type',
			key: 'activityAssetType',
			active: true,
			renderFunction: (columnValue) => ReportHelper.getAssetTypeDisplayName(columnValue)
		});
		return columns;
	}

	get workflow() {
		return workflows.find((workflow) => workflow.config === WORKFLOW_CRM_TRACKING);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsCrmTracking;
	}

	get updateWorkflowConfigEndpoint() {
		return CoSellEngineEndpoints.updateCoSellAdminConfigCrmTracking;
	}

	get renderAdditionalEditorFields() {
		let {editingAdminSequenceConfig} = this.state;
		return [
			<div key={'activity_editor'} style={this.sectionStyle}>
				<TextInputBox key={'title'} value={editingAdminSequenceConfig.title} hintText={'Activity Title'} doNotAutoFocus={true} onChange={() => {
					// nothing to do for standard activities
				}}/>
				{editingAdminSequenceConfig.isEditable ?
					<DropdownMenu key={'asset_type'}
								  title={'Asset Type'}
								  options={ASSET_TYPES}
								  selectedOption={ASSET_TYPES.find((assetType) => editingAdminSequenceConfig.assetType === assetType.label)}
								  onSelect={(item) => {
									  editingAdminSequenceConfig.assetType = item.label;
									  this.forceUpdate();
								  }}/>
					:
					<TextInputBox key={'asset_type'}
								  value={ReportHelper.getAssetTypeDisplayName(editingAdminSequenceConfig.assetType)}
								  hintText={'Asset Type'}
								  doNotAutoFocus={true}
								  onChange={() => {
									  // nothing to do for standard activities
								  }}/>}
			</div>
		];
	}
}
