import {AccountTree, EmojiTransportation, JoinInner, PieChartOutline} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as PartnerOrgEndpoints from '../../../../endpoints/PartnerOrgEndpoints';
import {Routes} from '../../../../globals/Routes';
import Loading from '../../../../ui/Loading';
import SubNav from '../../../../ui/navigation/SubNav';
import DetailHeader from '../../shared/DetailHeader';
import PartnerOrgAccountsPage from './PartnerOrgAccountsPage';
import PartnerOrgConnectedPartnersPage from './PartnerOrgConnectedPartnersPage';
import PartnerOrgDetail from './PartnerOrgDetail';
import PartnerOrgOppsPage from './PartnerOrgOppsPage';
import PartnerOrgSheetsUploadedPage from './PartnerOrgSheetsUploadedPage';
import PartnerOrgSummary from './PartnerOrgSummary';
import EnvHelper from '../../../../helpers/EnvHelper';

class PartnerOrgRouter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, companyPartnerData: null, error: false};
		this.fetchPartnerOrg = this.fetchPartnerOrg.bind(this);
	}

	componentDidMount() {
		this.fetchPartnerOrg();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get companyPartnerPublicId() {
		return this.props.match.params.companyPartnerPublicId;
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	fetchPartnerOrg() {
		PartnerOrgEndpoints.getPartnerOrg(this.companyPartnerPublicId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({companyPartnerData: result.payload, loading: false});
		})
		.catch((error) => {
			this.setState({loading: false, error: true});
			EnvHelper.serverError('Error from onSaveActionList', error);
		});
	}

	get subNavData() {
		let route = Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS;
		let reportTitle = this.reportType || 'All matched accounts';
		return [
			{
				label: 'Summary',
				MaterialIcon: PieChartOutline,
				route: route.ORG.PATH(this.companyPartnerPublicId, route.SUMMARY.PAGE_NAME)
			},
			{
				label: 'Details',
				MaterialIcon: AccountTree,
				route: route.ORG.PATH(this.companyPartnerPublicId, route.DETAILS.PAGE_NAME)
			},
			{
				label: 'Accounts',
				MaterialIcon: JoinInner,
				route: route.ORG.PATH(this.companyPartnerPublicId, route.MAPPED_ACCOUNTS.PAGE_NAME, reportTitle)
			}
		];
	}

	render() {
		if (this.state.loading) return <Loading>Loading Partner Organizations...</Loading>;
		if (this.state.error) return <div/>;
		let inReportDialog = this.props.history.location.pathname !== this.props.location.pathname;
		let {companyPartnerData} = this.state;
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<DetailHeader MaterialIcon={EmojiTransportation} title={'Organization Details'} detailRight={companyPartnerData.partnerName}/>
				{!inReportDialog &&
				 <SubNav subNavData={this.subNavData}/>}
				<Switch>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.SUMMARY.ROUTE}
						   render={(props) => <PartnerOrgSummary {...props} companyPartnerName={companyPartnerData.partnerName}/>}/>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.DETAILS.ROUTE}
						   render={(props) => <PartnerOrgDetail {...props}/>}/>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_ACCOUNTS.ROUTE}
						   render={(props) => <PartnerOrgAccountsPage {...props}/>}/>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.MAPPED_OPPS.ROUTE}
						   render={(props) => <PartnerOrgOppsPage {...props}/>}/>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.SHEETS_UPLOADED.ROUTE}
						   render={(props) => <PartnerOrgSheetsUploadedPage {...props} companyPartnerName={companyPartnerData.partnerName}/>}/>
					<Route exact path={Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.CONNECTED_PARTNERS.ROUTE}
						   render={(props) => <PartnerOrgConnectedPartnersPage {...props}/>}/>
				</Switch>
			</div>
		);
	}
}

PartnerOrgRouter.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerOrgRouter));
