import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapTernary} from '../styles/partnertap_theme';
import PartnerTapIcon from './PartnerTapIcon';

class SharedOppCount extends Component {

	constructor(props, context) {
		super(props, context);
		this.updated = false;
	}

	render() {
		return (
			<div style={{paddingTop: 0}}>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
					<PartnerTapIcon glyph={'K'} style={{fontSize: 24, position: 'relative', left: 4, color: partnerTapTernary}}/>
					<div style={{fontSize: 14, color: partnerTapTernary, textAlign: 'left', padding: '0 0 0 3px'}}>
						{this.props.count} Share{this.props.count !== 1 && 's'}
					</div>
				</div>
			</div>
		);
	}
}

SharedOppCount.propTypes = {
	count: PropTypes.number
};

export default SharedOppCount;
