import ColumnHelper from './ColumnHelper';

export const FILTER_VALUE_DELIMITER = '-,-';
export const FILTER_RANGE_DELIMITER = '<->';
export const FILTER_VALUE_NOT_EQUAL_SUFFIX = '-!-';
export const FILTER_VALUE_CONTAINS_SUFFIX = '-?-';
export const FILTER_VALUE_EXCLUDES_SUFFIX = '-^-';
export const FILTER_STRING_SEARCH_EQUAL = 1;
export const FILTER_STRING_SEARCH_NOT_EQUAL = -1;
export const FILTER_STRING_SEARCH_CONTAINS = 2;
export const FILTER_STRING_SEARCH_EXCLUDES = 3;

export const MAX_ITEMS_FOR_SIMPLE_FILTER_SELECTOR = 8;

export default class FilterHelper {

	static get FILTER_BY_ALL() {
		return 'all';
	}

	static get FILTER_BY_OPEN_OPPORTUNITIES() {
		return 'openOpportunities';
	}

	static getAvailableFilters(filterConfig) {
		return FilterHelper.getAllFilters(filterConfig)
		.filter((filter) => ColumnHelper.isFilterable(filter) && !filter.isAdded && !this.isInherent(filter, filterConfig));
	}

	static isInherent(filter, filterConfig) {
		return filterConfig.inherentReportFilters && this.hasOwnPropertyCaseInsensitive(filterConfig.inherentReportFilters, filter.key);
	}

	static hasOwnPropertyCaseInsensitive(object, propertyName) {
		return Object.keys(object).find((key) => key.toLowerCase() === propertyName.toLowerCase());
	}

	static getAddedFilters(filterConfig) {
		return FilterHelper.getAllFilters(filterConfig).filter((filter) => ColumnHelper.isFilterable(filter) && filter.isAdded);
	}

	static getAllFilters(filterConfig) {
		if (filterConfig && filterConfig.filters) {
			return filterConfig.filters;
		}
		return [];
	}

	static getSimpleMessageStyle() {
		return {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: 10,
			fontSize: 16,
			fontWeight: 'bold',
			whiteSpace: 'nowrap'
		};
	}
}
