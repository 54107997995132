import {ViewList} from '@mui/icons-material';
import {td, tr} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as PartnerOrgEndpoints from '../../../../endpoints/PartnerOrgEndpoints';
import DateHelper from '../../../../helpers/DateHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import NumberHelper from '../../../../helpers/NumberHelper';
import {partnerTapWhite} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import ScrollingContainer from '../../../../ui/lists/ScrollingContainer';
import Loading from '../../../../ui/Loading';
import DashboardTable from '../../shared/DashboardTable';
import PartnerOrgHelper from './PartnerOrgHelper';
import LinkButton from '../../../../ui/buttons/LinkButton';
import {Routes} from '../../../../globals/Routes';

class PartnerOrgDetail extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			details: {},
			showAlertMessage: false,
			alertMessage: '',
			partnershipCreated: false,
			companyPartnerState: null,
			partnerDetailsData: {},
			availableConnectionsData: null,
			sheetUploadsData: null
		};

		this.onViewPartnerConnectionsReport = this.onViewPartnerConnectionsReport.bind(this);
		this.onViewPartnerSheetsReport = this.onViewPartnerSheetsReport.bind(this);
	}

	componentDidMount() {
		this.companyPartnerDetail();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get companyPartnerPublicId() {
		return this.props.match.params.companyPartnerPublicId;
	}

	get activeConnectionsViewButton() {
		return (
			<PrimaryButton label={'VIEW ALL'} icon={<ViewList/>} onClick={this.onViewPartnerConnectionsReport}/>
		);
	}

	get sheetUploadsViewButton() {
		return (
			<PrimaryButton label={'VIEW ALL'} icon={<ViewList/>} onClick={this.onViewPartnerSheetsReport}/>
		);
	}

	generateActiveConnectionsColumnData(availableConnectionsData) {
		let availableConnArr = [];
		if (availableConnectionsData) {
			availableConnectionsData.forEach((availableConnection) => {
				let payloadObject = {
					user: availableConnection.ownerName,
					partner_person: availableConnection.partnerName,
					connected_date: availableConnection.createdDate ? DateHelper.epochToDate(availableConnection.createdDate) : '',
					sharing_accounts_to: availableConnection.accountsSharingTo,
					sharing_accounts_from: availableConnection.accountsSharingFrom,
					sharing_opps_to: availableConnection.oppsSharingTo,
					sharing_opps_from: availableConnection.oppsSharingFrom
				};
				availableConnArr.push(payloadObject);
			});
		}
		return availableConnArr;
	}

	generateSpreadsheetUploadsColumnData(sheetUploadsData) {
		let sheetsUpArr = [];
		if (sheetUploadsData) {
			sheetUploadsData.forEach((sheetUploadRow) => {
				let payloadObject = {
					sheet_name: sheetUploadRow.spreadsheetName,
					person_queue_id: sheetUploadRow.personQueueId,
					date_uploaded: sheetUploadRow.dateUploaded ? DateHelper.epochToDate(sheetUploadRow.dateUploaded) : '',
					sheet_records_count: sheetUploadRow.numRecords,
					matched_accounts_count: sheetUploadRow.numMatchedAccounts,
					unmapped_count: sheetUploadRow.numUnmapped,
					mutual_clients_count: sheetUploadRow.numMutualClients,
					hot_prospects_count: sheetUploadRow.numHotProspects,
					help_my_partners_count: sheetUploadRow.numHelpMyPartners,
					matched_by_audit_count: sheetUploadRow.numMatchedByAudit
				};
				sheetsUpArr.push(payloadObject);
			});
		}
		return sheetsUpArr;
	}

	processPartnerDetailData(payload) {
		return {
			partnerOrgName: payload.partnerOrgName,
			crmPartnerLink: payload.crmPartnerLink,
			partnerStatus: payload.partnerStatus,
			prmRecordId: payload.prmRecordId,
			crmRecordId: payload.crmRecordId,
			orgType: payload.companyType,
			firstConnectionDate: payload.firstConnectionDate ? DateHelper.epochToDate(payload.firstConnectionDate) : '',
			averageReferralDealSize: payload.averageReferralDealSize ? NumberHelper.formatCurrency(payload.averageReferralDealSize) : '',
			firstSpreadsheetUploadDate: payload.firstSpreadsheetUploadDate ? DateHelper.epochToDate(payload.firstSpreadsheetUploadDate) : '',
			address: payload.street,
			city: payload.city,
			state: payload.state,
			recommended: payload.isRecommended ? 'Yes' : 'No',
			ownerName: payload.ownerName,
			ownerEmail: payload.ownerEmail
		};
	}

	companyPartnerDetail() {
		this.setState({loading: true});
		PartnerOrgEndpoints.getCompanyPartnerDetail({companyPartnerPublicId: this.companyPartnerPublicId})
		.then((result) => {
			if (this.unmounted) return;
			this.setState({
				partnerDetailsData: this.processPartnerDetailData(result.payload),
				availableConnectionsData: this.generateActiveConnectionsColumnData(result.payload.activeConnections),
				sheetUploadsData: this.generateSpreadsheetUploadsColumnData(result.payload.sheetUploads),
				loading: false
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getPartnerOrgDetails', error);
		});
	}

	get activeConnectionsColumnList() {
		return [
			{title: 'User', key: 'user', active: true, type: 'string'},
			{title: 'Partner Connection', key: 'partner_person', active: true, isPartnerData: true},
			{title: 'Connection Date', key: 'connected_date', active: true},
			{title: 'Sharing Accounts To', key: 'sharing_accounts_to', active: true, type: 'string'},
			{
				title: 'Sharing Accounts From',
				key: 'sharing_accounts_from',
				active: true,
				type: 'string',
				isPartnerData: true
			},
			{title: 'Sharing Opps To', key: 'sharing_opps_to', active: true, type: 'string'},
			{title: 'Sharing Opps From', key: 'sharing_opps_from', active: true, type: 'string', isPartnerData: true}
		];
	}

	get partnerSheetUploadsColumnList() {
		return [
			{
				title: 'Sheet Name', key: 'sheet_name', active: true,
				renderFunction: (columnValue, rowData) => {
					let baseRoute = Routes.CHANNEL_ECOSYSTEM.PARTNER_SHEETS;
					let path = baseRoute.PATH(rowData.person_queue_id, baseRoute.DASHBOARD.PAGE_NAME, null, this.state.partnerDetailsData.partnerOrgName);
					return <LinkButton label={columnValue} onClick={() => EnvHelper.push(path)}/>;
				}
			},
			{title: 'Date Uploaded', key: 'date_uploaded', active: true, type: 'date'},
			{title: 'Records in Sheet', key: 'sheet_records_count', active: true, type: 'number', isPartnerData: true},
			{title: 'Matched Accounts', key: 'matched_accounts_count', active: true, type: 'number', isPartnerData: true},
			{title: 'Matched By Audit', key: 'matched_by_audit_count', active: true, type: 'number', isPartnerData: true},
			{title: 'Unmapped', key: 'unmapped_count', active: true, type: 'number', isPartnerData: true},
			{title: 'Mutual Clients', key: 'mutual_clients_count', active: true, type: 'number', isPartnerData: true},
			{title: 'Hot Prospects', key: 'hot_prospects_count', active: true, type: 'number', isPartnerData: true},
			{title: 'Help My Partners', key: 'help_my_partners_count', active: true, type: 'number', isPartnerData: true}
		];
	}

	onViewPartnerConnectionsReport() {
		let title = 'Active Connections';
		let path = PartnerOrgHelper.getPartnerConnectionsRoute(this.companyPartnerPublicId);
		let params = '?companyPartnerPublicId=' + this.companyPartnerPublicId;
		DialogHelper.openDetailDialog(title, path, params, this.setState.bind(this));
	}

	onViewPartnerSheetsReport() {
		let title = 'Recent Partner Sheets';
		let path = PartnerOrgHelper.getPartnerSheetsRoute(this.companyPartnerPublicId);
		let params = '?companyPartnerPublicId=' + this.companyPartnerPublicId;
		DialogHelper.openDetailDialog(title, path, params, this.setState.bind(this));
	}

	render() {
		let {availableConnectionsData, sheetUploadsData, partnerDetailsData} = this.state;
		if (this.state.loading) return <Loading>Loading Organization Detail...</Loading>;
		return (
			<ScrollingContainer divId={'company_partner_details'}>
				{this.state.detailDialog}
				<div style={{overflow: 'scroll'}}>
					<table style={{
						background: partnerTapWhite,
						margin: 10,
						padding: 10,
						width: 'calc(100% - 20px)',
						minWidth: 300
					}} cellSpacing={10}>
						<tbody>
						<tr>
							<td><b>Partner Org Name: </b>{partnerDetailsData.partnerOrgName}</td>
							<td>
								<b>CRM Partner Record URL: </b>
								<LinkButton label={partnerDetailsData.crmPartnerLink}
											onClick={() => window.open(partnerDetailsData.crmPartnerLink)}
											hasExternalLinkIcon={true}/>
							</td>
						</tr>
						<tr>
							<td><b>Owner Name: </b>{partnerDetailsData.ownerName}</td>
							<td><b>CRM Record ID: </b>{partnerDetailsData.crmRecordId}</td>
						</tr>
						<tr>
							<td><b>Owner Email: </b>{partnerDetailsData.ownerEmail}</td>
							<td><b>PRM Record ID: </b>{partnerDetailsData.prmRecordId}</td>
						</tr>
						<tr>
							<td><b>Partner Type: </b>{partnerDetailsData.orgType}</td>
							<td><b>Average Deal Size: </b>{partnerDetailsData.averageReferralDealSize}</td>
						</tr>
						<tr>
							<td><b>Address: </b>{partnerDetailsData.address}</td>
							<td><b>First Connection Date: </b>{partnerDetailsData.firstConnectionDate}</td>
						</tr>
						<tr>
							<td><b>City: </b>{partnerDetailsData.city}</td>
							<td><b>First Spreadsheet Date: </b>{partnerDetailsData.firstSpreadsheetUploadDate}</td>
						</tr>
						<tr>
							<td><b>State: </b>{partnerDetailsData.state}</td>
							<td><b>Recommended: </b>{partnerDetailsData.recommended}</td>
						</tr>
						</tbody>
					</table>
					<div style={{margin: 5}}>
						<DashboardTable key={'active_connections'}
										title={'Active Connections'}
										columnData={this.activeConnectionsColumnList}
										rowData={availableConnectionsData}
										hasScrollingContainerParent={true}
										elementRight={availableConnectionsData.length > 0 ? this.activeConnectionsViewButton : null}/>
						<DashboardTable key={'partner_sheets_upload'}
										title={'Recent Partner Sheets'}
										columnData={this.partnerSheetUploadsColumnList}
										rowData={sheetUploadsData}
										hasScrollingContainerParent={true}
										elementRight={sheetUploadsData.length > 0 ? this.sheetUploadsViewButton : null}/>
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

PartnerOrgDetail.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerOrgDetail));
