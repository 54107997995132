import PropTypes from 'prop-types';
import Dialog from '../Dialog';
import * as ChannelMappingEndpoints from '../../endpoints/ChannelMappingEndpoints';
import React, {Component} from 'react';
import Upsell from '../Upsell';

class SheetAuditDialog extends Component {

	componentWillUnmount() {
		this.unmounted = true;
	}

	render() {
		const {rowData, isFeatureEnabled, onClose} = this.props;
		return (<div>
			{isFeatureEnabled
				? (<Dialog title={'Please Confirm'}
						   message={'Are you sure you want to audit ' + rowData.batchName + '?'}
						   yesAction={() => {
							   ChannelMappingEndpoints.auditUpload(rowData.id)
							   .then(() => {
								   if (this.unmounted) return;
								   onClose(true);
							   });
						   }}
						   noAction={() => onClose(false)}/>)
				: (<Dialog title={'Upgrade Required'}
						   message={<Upsell feature={'Channel Ecosystem'} headline={'Do you want to audit your sheets?'} showSupportButton={true}/>}
						   yesAction={() => onClose(false)}
						   noContentPadding={true}/>)}

		</div>);
	}
}

SheetAuditDialog.propTypes = {
	rowData: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	isFeatureEnabled: PropTypes.bool.isRequired
};
export default SheetAuditDialog;