import {CloudDownload, Menu} from '@mui/icons-material';
import moment from 'moment';
import React from 'react';
import {connect} from 'react-redux';
import {
	fetchAllExcluded,
	fetchAllRecommended,
	fetchAllStats,
	fetchAllUserReport,
	fetchAllUsersByOrgReport,
	fetchAuditsByOrgReport,
	fetchAuditsInQueueReport,
	fetchBillingReport,
	fetchChannelMapperInvites,
	fetchChannelMappers,
	fetchChannelMappersNoData,
	fetchChannelShareAlerts,
	fetchReportInvites,
	restoreUser,
	terminateUser
} from '../../endpoints/AdminEndpoints';
import ChannelHelper from '../../helpers/ChannelHelper';
import * as CSVHelper from '../../helpers/CsvHelper';
import DateHelper from '../../helpers/DateHelper';
import EnvHelper from '../../helpers/EnvHelper';
import * as PageableHelper from '../../helpers/PageableHelper';
import SortHelper from '../../helpers/SortHelper';
import DownloadButton from '../../ui/buttons/DownloadButton';
import MenuButton from '../../ui/buttons/MenuButton';
import PagingBase from '../../ui/lists/PagingBase';
import Loading from '../../ui/Loading';
import OrgSelector from './shared/OrgSelector';

class AdminReportsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.currentOrg = null;
		this.state.reportList = [
			{
				key: 'allUsers',
				name: 'All Users',
				showOrgSelector: true,
				addAllOptionToOrgSelector: true,
				action: fetchAllUserReport,
				pageable: true,
				columns: [
					{title: 'PartnerTap Org', key: 'organization'},
					{title: 'Rep Name', key: 'repName'},
					{title: 'Mobile Number', key: 'mobileNumber'},
					{title: 'Email', key: 'emailAddress'},
					{title: 'First Login', key: 'createdDate', type: 'date'},
					{title: 'Last Login', key: 'lastLogin', type: 'date'},
					{title: 'Division', key: 'division'},
					{title: 'Job Title', key: 'jobTitle'},
					{title: 'Role Code', key: 'roleCode'},
					{title: 'Status', key: 'status'}
				]
			},
			{
				key: 'allStats',
				name: 'All Stats',
				action: fetchAllStats,
				columns: [
					{title: 'Date', key: 'epochDate', type: 'date'},
					{title: 'Logins', key: 'logins'},
					{title: 'Chats', key: 'chats'},
					{title: 'Invites', key: 'invites'},
					{title: 'Partnerships Requested', key: 'partnershipsRequested'},
					{title: 'Partnerships Accepted', key: 'partnershipsAccepted'},
					{title: 'New Users', key: 'newUsers'}
				]
			},
			{
				key: 'allUsersByOrg',
				name: 'User Count by Org',
				action: fetchAllUsersByOrgReport,
				columns: [
					{title: 'Organization', key: 'organization'},
					{title: 'User Count', key: 'userCount'}
				]
			},
			{
				key: 'auditsByOrg',
				name: 'Audits by Org',
				action: fetchAuditsByOrgReport,
				columns: [
					{title: 'Organization', key: 'organization'},
					{title: 'Audits Requested', key: 'auditsRequested'},
					{title: 'Audits Completed', key: 'auditsCompleted'}
				]
			},
			{
				key: 'auditsInQueue',
				name: 'Audits In Progress',
				action: fetchAuditsInQueueReport,
				columns: [
					{title: 'Uploader', key: 'uploader'},
					{title: 'Organization', key: 'orgName'},
					{title: 'File Name', key: 'spreadsheetName'},
					{title: 'Total Rows', key: 'totalRows'},
					{title: 'Matched Accounts', key: 'mappedRows'},
					{title: 'Unmatched Accounts', key: 'unmappedRows'},
					{title: 'Status', key: 'remarks'},
					{title: 'Email', key: 'uploaderEmail'},
					{title: 'Person Queue Id', key: 'personQueueId'},
					{title: 'Request Date', key: 'updatedDate', type: 'date'}
				]
			},
			{
				key: 'billing',
				name: 'Billing',
				action: fetchBillingReport,
				columns: [
					{title: 'Organization', key: 'organization'},
					{title: 'User Count', key: 'userCount'}
				]
			},
			{
				key: 'allexcluded',
				name: 'Excluded Partner Orgs',
				action: fetchAllExcluded,
				columns: [
					{title: 'Org', key: 'orgName'},
					{title: 'Excluded PartnerTap Org', key: 'partnerOrgName'},
					{title: 'Email Domains', key: 'emailDomains'},
					{title: 'Created By', key: 'createdBy'},
					{title: 'Created Date', key: 'createdDate', type: 'date'}
				]
			},
			{
				key: 'invites',
				name: 'Email Invites',
				showOrgSelector: true,
				addAllOptionToOrgSelector: true,
				action: fetchReportInvites,
				pageable: true,
				columns: [
					{title: 'Inviter First Name', key: 'inviterFirstName'},
					{title: 'Inviter Last Name', key: 'inviterLastName'},
					{title: 'Inviter Org', key: 'inviterOrg'},
					{title: 'Inviter', key: 'inviter'},
					{title: 'Invitee', key: 'invitee'},
					{title: 'Invite Product Code', key: 'productCode'},
					{title: 'Note', key: 'userNote'},
					{title: 'Registered', key: 'registered', type: 'boolean'},
					{title: 'Date', key: 'epochDate', sort: 'desc', type: 'date'}
				]
			},
			{
				key: 'channelSharingAlert',
				name: 'Channel Sharing Alerts',
				action: fetchChannelShareAlerts,
				columns: [
					{title: 'Owner Email', key: 'owner'},
					{title: 'Owner Share Type', key: 'ownerShareType'},
					{title: 'Owner Has Accounts', key: 'ownerHasAccounts', type: 'boolean'},
					{title: 'Partner Email', key: 'partner'},
					{title: 'Partner Has Accounts', key: 'partnerHasAccounts', type: 'boolean'},
					{title: 'Created Date', key: 'epochDate', type: 'date'}
				]
			},
			{
				key: 'channelmappers',
				name: 'Channel Users',
				action: fetchChannelMappers,
				columns: [
					{title: 'Org Name', key: 'orgName'},
					{title: 'First Name', key: 'firstName'},
					{title: 'Last Name', key: 'lastName'},
					{title: 'Email', key: 'email'},
					{title: 'Phone', key: 'phone'},
					{title: 'Role', key: 'userRole'},
					{title: 'Role Code', key: 'roleCode'},
					{title: 'Status', key: 'status'},
					{title: 'First Login', key: 'firstLogin', type: 'date'},
					{title: 'Last Login', key: 'lastLogin', type: 'date'},
					{title: 'Paying Client', key: 'payingClient', type: 'boolean'},
					{title: 'Total Accounts', key: 'csaCount'},
					{title: 'Partner Orgs', key: 'connectedPartnerOrgs'},
					{title: 'Partner Connections', key: 'connectedPartnerCount'},
					{title: 'Partner Sheets', key: 'spreadsheetUploadCount'},
					{title: 'Email Invites', key: 'invitesCount'},
					{title: 'Email Invites Converted', key: 'invitesConvertedCount'},
					{title: 'Inviter First Name', key: 'inviterFirstName'},
					{title: 'Inviter Last Name', key: 'inviterLastName'},
					{title: 'Inviter Org', key: 'inviterOrg'},
					{title: 'Invite Source', key: 'inviteSource'}
				]
			},
			{
				key: 'channelmappersnodata',
				name: 'Channel Users - No Accounts',
				action: fetchChannelMappersNoData,
				columns: [
					{title: 'Org Name', key: 'orgName'},
					{title: 'First Name', key: 'firstName'},
					{title: 'Last Name', key: 'lastName'},
					{title: 'Email', key: 'email'},
					{title: 'Phone', key: 'phone'},
					{title: 'Role', key: 'userRole'},
					{title: 'Role Code', key: 'roleCode'},
					{title: 'Status', key: 'status'},
					{title: 'First Login', key: 'firstLogin', type: 'date'},
					{title: 'Last Login', key: 'lastLogin', type: 'date'},
					{title: 'Paying Client', key: 'payingClient', type: 'boolean'},
					{title: 'Inviter First Name', key: 'inviterFirstName'},
					{title: 'Inviter Last Name', key: 'inviterLastName'},
					{title: 'Inviter Org', key: 'inviterOrg'},
					{title: 'Invite Source', key: 'inviteSource'}
				]
			},
			{
				key: 'channelmapperinvites',
				name: 'Channel Summary',
				action: fetchChannelMapperInvites,
				columns: [
					{title: 'Org Name', key: 'orgName'},
					{title: 'User Licenses', key: 'userLicenseCount'},
					{title: 'Email Invites', key: 'invitesCount'},
					{title: 'Email Invites Converted', key: 'invitesConvertedCount'},
					{title: 'Partner Requests Accepted', key: 'partnershipCount'}
				]
			},
			{
				key: 'allrecommended',
				name: 'Recommended Partners',
				action: fetchAllRecommended,
				columns: [
					{title: 'PartnerTap Org', key: 'orgName'},
					{title: 'Recommended Org', key: 'partnerOrgName'},
					{title: 'Created By', key: 'createdBy'},
					{title: 'Created Date', key: 'createdDate', type: 'date'}
				]
			}
		];

		this.state.dateRangeStart = moment().subtract(8, 'days');
		this.state.dateRangeEnd = moment();
		this.state.startDate = moment().subtract(8, 'days').startOf('day').utc().valueOf();
		this.state.endDate = moment().endOf('day').utc().valueOf();

		this.onSelectReport = this.onSelectReport.bind(this);
		this.onDateRangeChange = this.onDateRangeChange.bind(this);
		this.downloadReport = this.downloadReport.bind(this);
		this.processCsvDateValue = this.processCsvDateValue.bind(this);
		this.onTermUser = this.onTermUser.bind(this);
		this.onRestoreUser = this.onRestoreUser.bind(this);
		this.handleOrgSelection = this.handleOrgSelection.bind(this);
	}

	componentDidMount() {
		this.onSelectReport('allUsersByOrg');
	}

	handleOrgSelection(org) {
		this.setState({currentOrg: org, loading: true});
	}

	get storageKeyBase() {
		if (this.state.currentReport) return 'tools_admin_reports_' + this.state.currentReport.key;
		return 'tools_admin_reports';
	}

	get title() {
		if (this.state.currentReport) return this.state.currentReport.name;
		return 'Admin Reports';
	}

	get icon() {
		return CloudDownload;
	}

	get columnData() {
		return this.state.currentReport ? this.state.currentReport.columns : null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {currentReport, currentOrg, startDate, endDate, columnData} = this.state;
		if (currentReport) {
			let params = this.processParameters();
			let orgCode = currentOrg ? currentOrg.orgCode : '';
			currentReport.action(startDate, endDate, params.page, params.pageSize, params.search, params.sort, orgCode)
			.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					if (!currentReport.pageable) {
						result.payload = SortHelper.filterSearchObjectContains(params.search, result.payload, SortHelper.keysFromColumns(columnData));
						if (params.sort) result.payload = SortHelper.sortByNullLast(result.payload, params.sort);
					}
					ChannelHelper.convertTypedValues(result.payload, columnData);
					this.processData(params, result);
				}
				else {
					this.processData(params, []);
				}
			})
			.catch((error) => {
				this.processError(error);
			});
		}
	}

	get noDataMessage() {
		let {currentReport} = this.state;
		if (currentReport && currentReport.showOrgSelector && this.state.rowData && this.state.rowData.length === 0 && !this.state.searchValue) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						{!this.state.currentOrg ?
							'Please select an organization!' :
							'The selected organization has no data for ' + currentReport.name}
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	onDateRangeChange(range) {
		let startDate = moment(range.startDate).startOf('day').utc().valueOf();
		let endDate = moment(range.endDate).endOf('day').utc().valueOf();
		this.setState({loading: true, startDate: startDate, endDate: endDate});
	}

	onTermUser(personId) {
		terminateUser(personId)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				this.setState({loading: true});
			}
		});
	}

	onRestoreUser(personId) {
		restoreUser(personId)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				this.setState({loading: true});
			}
		});
	}

	onSelectReport(reportKey) {
		let selectedReport = this.state.reportList.find((report) => report.key === reportKey);
		this.state.reportList.forEach((report) => report.selected = false);
		selectedReport.selected = true;
		if (selectedReport) {
			this.setState({currentReport: selectedReport});
			this.initColumnData(selectedReport.columns);
		}
	}

	processCsvDateValue(value) {
		if (value) {
			return DateHelper.epochToDate(value);
		}
		return '';
	}

	downloadReport() {
		if (!this.state.downloading) {
			this.setState({downloading: true});
			this.fetchAllForDownload(0, false);
		}
	}

	fetchAllForDownload(page, isLastPage) {
		if (this.unmounted) return;
		if (isLastPage) {
			this.setState({downloading: false});
			return;
		}
		let {currentReport} = this.state;
		if (currentReport) {
			let orgCode = this.state.currentOrg ? this.state.currentOrg.orgCode : '';
			currentReport.action(this.state.startDate, this.state.endDate, page, PageableHelper.PAGE_SIZE_DOWNLOAD, this.state.searchValue, null, orgCode)
			.then((result) => {
				if (this.unmounted) return;
				let columnData = [...this.state.columnData];
				let selectedColumns = this.activeColumns.split(',');
				result.payload.forEach((result) => {
					Object.keys(result).forEach((key) => {
						if (['lastLogin', 'epochDate', 'createdDate'].indexOf(key) > -1) result[key] = this.processCsvDateValue(result[key]);
					});
				});
				CSVHelper.downloadCSVSelectedColumns(result.payload, currentReport.name + '.csv', columnData, selectedColumns);
				return this.fetchAllForDownload(page + 1, result.metaData.last);
			})
			.catch((error) => {
				EnvHelper.serverError('Error from fetchAllForDownload', error);
			});
		}
	}

	render() {
		let {currentReport} = this.state;
		if (!currentReport) return <Loading>Loading Report...</Loading>;
		return (
			<div key={currentReport.key} style={{display: 'flex', flexDirection: 'column'}}>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, margin: 10, marginBottom: 0}}>
					<MenuButton label={'Reports'} MaterialIcon={Menu} menuData={this.state.reportList} onSelect={this.onSelectReport}/>
					{currentReport.showOrgSelector &&
					 <OrgSelector onOrgSelection={this.handleOrgSelection} addAllOption={currentReport.addAllOptionToOrgSelector}/>}
					<DownloadButton onAction={this.downloadReport} inProgress={this.state.downloading}/>
				</div>
				{(!currentReport.showOrgSelector || this.state.currentOrg) &&
				 super.render()}
			</div>);
	}
}

export default connect()(AdminReportsPage);
