import {Launch} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {CRM_MANUAL} from '../../../globals/Enums';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapSecondary, partnerTapWhite} from '../../../styles/partnertap_theme';
import ToolTipOverlay from '../../../ui/ToolTipOverlay';
import AuthHelper from '../../../helpers/AuthHelper';

class AccountDetailHeader extends Component {

	render() {
		let {name, city, state, street, zipCode, accountType, mainAccountOwner, crmAccountId, crmAccountBaseUri} = this.props.details;
		return (
			<div style={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				justifyContent: 'space-between',
				boxShadow: partnerTapDropShadow,
				backgroundColor: partnerTapWhite,
				padding: 10,
				margin: 10
			}}>
				<div style={{display: 'flex', flexDirection: 'column', paddingLeft: 10}}>
					{crmAccountBaseUri && crmAccountId && crmAccountId.indexOf(CRM_MANUAL) === -1 ?
						<div style={{fontSize: 24, fontWeight: 'bold', paddingBottom: 5}}>
							<ToolTipOverlay title={'Open in ' + DataManagerHelper.getCrmName(AuthHelper.userPerson.crmType)}>
								<a href={crmAccountBaseUri + crmAccountId}
								   style={{color: partnerTapSecondary, cursor: 'pointer', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
								   target={'_blank'}>
									{name}
									<Launch style={{position: 'relative', top: 5, left: 5}}/>
								</a>
							</ToolTipOverlay>
						</div>
						:
						<div style={{fontSize: 24, fontWeight: 'bold'}}>
							{name}
						</div>}
					<div style={{fontSize: 16, color: partnerTapInactive}}>
						{city}, {state}
					</div>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', paddingRight: 10}}>
					<div style={{display: 'flex', whiteSpace: 'nowrap', paddingBottom: 10}}>
						<div style={{fontWeight: 'bold', width: 64}}>Owner:</div>
						{mainAccountOwner}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap', paddingBottom: 10}}>
						<div style={{fontWeight: 'bold', width: 64}}>Type:</div>
						{accountType}
					</div>
					<div style={{display: 'flex', whiteSpace: 'nowrap'}}>
						<div style={{fontWeight: 'bold', width: 64}}>Address:</div>
						{Boolean(street) && <div>{street},</div>}
						{city}, {state}, {zipCode}
					</div>
				</div>
			</div>
		);
	}
}

AccountDetailHeader.propTypes = {
	details: PropTypes.object.isRequired
};

export default AccountDetailHeader;
