import * as ApiHelper from '../helpers/ApiHelper';
import FilterHelper from '../helpers/FilterHelper';
import SortHelper from '../helpers/SortHelper';

export function fetchAccount(personSaleAccountId, partnerPersonId) {
	return ApiHelper.sendGet('/accounts/' + personSaleAccountId + (partnerPersonId ? '?partnerPersonId=' + partnerPersonId : ''));
}

export function searchAccounts(page, pageSize, search, sort, filter, countOpportunities) {
	if (!filter) filter = FilterHelper.FILTER_BY_ALL;
	let endpoint = '/accounts?filter=' + filter + (countOpportunities ? '&countOpportunities=true' : '');
	endpoint = ApiHelper.appendPageableParams(endpoint, page, pageSize, search, sort || SortHelper.sortAccountsByName);
	return ApiHelper.sendGet(endpoint);
}

export function fetchSharedAccountsPage(page, pageSize, partnerPersonId, search) {
	let endpoint = '/accounts/shared/page?partnerPersonId=' + partnerPersonId;
	endpoint = ApiHelper.appendPageableParams(endpoint, page, pageSize, search);
	return ApiHelper.sendGet(endpoint);
}
