import React from 'react';
import PagingBase from '../../../ui/lists/PagingBase';
import LinkButton from '../../../ui/buttons/LinkButton';
import UploadHelper, {PERSON_QUEUE_STATUS_PROCESSED} from '../../../helpers/UploadHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import DateHelper from '../../../helpers/DateHelper';
import {History} from '@mui/icons-material';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import AdminPartnerReassignDialog from './AdminPartnerReassignDialog';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import * as RestAdminCenterEndPoints from '../../../endpoints/RestAdminCenterEndPoints';
import AdminPartnerDeleteDialog from './AdminPartnerDeleteDialog';
import Alert from '@mui/material/Alert';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';

const AUDIT_COMPLETE = 'AUDIT_COMPLETE';
const AUDIT_ENABLED = 'AUDIT_ENABLED';

export class PartnerOrgReassignAdminPage extends PagingBase {

	constructor(props, context) {
		super(props, context);
		this.state.checkedItems = [];
		this.state.selectedItemsPersonQueuePublicIds = [];
		this.state.showReassignDialog = false;
		this.state.showDeleteDialog = false;
		this.state.showCheckBoxesForDelete = false;
		this.state.showCheckBoxesForReassign = false;
		this.state.showNoItemsSelectedAlert = false;
		this.checkItem = this.checkItem.bind(this);
	}

	reassignPartnerOrgs = () => {
		if (!this.state.showCheckBoxesForReassign) {
			this.setState({showCheckBoxesForReassign: true}, () => this.setState({columnData: this.initColumnData(null, false)}));
			return;
		}
		if (this.state.checkedItems.length === 0) {
			this.setState({showNoItemsSelectedAlert: true});
			return;
		}
		const selectedRows = this.state.rowData.filter(row => this.state.checkedItems.includes(row.id));
		const personQueuePublicIdsOfSelectedRows = selectedRows.map(row => row.personQueuePublicId);
		this.setState({selectedItemsPersonQueuePublicIds: personQueuePublicIdsOfSelectedRows, showReassignDialog: true});
	};

	deletePartnerOrgs = () => {
		if (!this.state.showCheckBoxesForDelete) {
			this.setState({showCheckBoxesForDelete: true}, () => this.setState({columnData: this.initColumnData(null, false)}));
			return;
		}
		if (this.state.checkedItems.length === 0) {
			this.setState({showNoItemsSelectedAlert: true});
			return;
		}
		const selectedRows = this.state.rowData.filter(row => this.state.checkedItems.includes(row.id));
		const personQueuePublicIdsOfSelectedRows = selectedRows.map(row => row.personQueuePublicId);
		this.setState({selectedItemsPersonQueuePublicIds: personQueuePublicIdsOfSelectedRows, showDeleteDialog: true});
	};

	onClose = (shouldReload) => {
		this.setState({showDeleteDialog: false, showReassignDialog: false});
		if (shouldReload) {
			this.setState({
				showCheckBoxesForDelete: false,
				showCheckBoxesForReassign: false,
				checkedItems: [],
				selectedItemsPersonQueuePublicIds: [],
				loading: true
			}, () => this.setState({columnData: this.initColumnData(null, false)}));
		}
	};

	checkItem(rowData) {
		if (!this.state.checkedItems.includes(rowData.id)) {
			this.setState(({checkedItems}) => ({checkedItems: [...checkedItems, rowData.id], showNoItemsSelectedAlert: false}));
		}
		else {
			this.setState(({checkedItems}) => {
				const filteredArray = checkedItems.filter(checkedItem => checkedItem !== rowData.id);
				return {checkedItems: filteredArray};
			});
		}
	}

	handleCheckButtonClick = (allRowsChecked) => {
		if (allRowsChecked) {
			this.uncheckAllItems();
		}
		else {
			this.checkAllItems();
		}
	};
	checkAllItems = () => {
		const allRowIdSet = this.state.rowData.map(row => row.id);
		this.setState({checkedItems: allRowIdSet, showNoItemsSelectedAlert: false}, () => this.setState({columnData: this.initColumnData(null, false)}));
	};
	uncheckAllItems = () => {
		this.setState({checkedItems: []}, () => this.setState({columnData: this.initColumnData(null, false)}));
	};

	cancelAction = () => {
		this.setState({
				showCheckBoxesForDelete: false,
				showCheckBoxesForReassign: false,
				showNoItemsSelectedAlert: false,
				checkedItems: [],
				selectedItemsPersonQueuePublicIds: []
			},
			() => this.setState({columnData: this.initColumnData(null, false)}));
	};

	get searchComponent() {
		const rowDataLoaded = this.state.rowData && this.state.rowData.length > 0;
		const allChecked = rowDataLoaded && (this.state.rowData.length === this.state.checkedItems.length);
		const {showCheckBoxesForDelete, showCheckBoxesForReassign, showNoItemsSelectedAlert, checkedItems} = this.state;
		const showCheckBoxes = Boolean(showCheckBoxesForDelete || showCheckBoxesForReassign);
		const showReassignButton = rowDataLoaded && (!showCheckBoxes || showCheckBoxesForReassign);
		const showDeleteButton = rowDataLoaded && (!showCheckBoxes || showCheckBoxesForDelete);
		return (<div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
			{showNoItemsSelectedAlert && <Alert variant="standard" severity="error"> You have to select atleast 1 partner sheet to update </Alert>}
			{showCheckBoxes && checkedItems.length > 0 && <span>{checkedItems.length} Selected</span>}
			{showReassignButton && <PrimaryButton label="Reassign" onClick={() => this.reassignPartnerOrgs()}/>}
			{showDeleteButton && <PrimaryButton label="Delete" onClick={() => this.deletePartnerOrgs()}/>}
			{showCheckBoxes && <SecondaryButton label="Cancel" onClick={this.cancelAction}/>}
			{!showCheckBoxes && super.searchComponent}
			{showCheckBoxes && <SecondaryButton label={allChecked ? 'Uncheck all' : 'Check all'} onClick={() => this.handleCheckButtonClick(allChecked)}/>}
		</div>);
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		RestAdminCenterEndPoints.getAdminPartnerSheets(params.page, params.pageSize, params.search, params.sort)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get storageKeyBase() {
		return 'rest-admin-partner-sheets';
	}

	get icon() {
		return History;
	}

	get title() {
		return 'Partner Sheets';
	}

	get columnData() {
		const showCheckBoxes = Boolean(this.state.showCheckBoxesForDelete || this.state.showCheckBoxesForReassign);
		return [
			{
				title: 'Selector',
				sortDisabled: true,
				key: 'rowSelector',
				active: showCheckBoxes,
				renderFunction: (cellValue, rowData) => (<div style={{display: 'flex', justifyContent: 'center'}}>
					<CheckboxButton checked={this.state.checkedItems.includes(rowData.id)}
									onChange={() => this.checkItem(rowData)}
					/>
				</div>)},
			{title: 'Created', key: 'createdDate', type: 'date', renderFunction: (createdDate) => DateHelper.epochToDate(createdDate)},
			{title: 'Uploaded By', key: 'channelMapperName'},
			{title: 'Assigned Partner Org', key: 'partnerOrgName'},
			{
				title: 'Sheet Name',
				key: 'batchName',
				renderFunction: this.isPiiOptIn ? null :(columnValue, rowData) => {
					return <LinkButton label={columnValue}
									   disabled={rowData.status !== PERSON_QUEUE_STATUS_PROCESSED}
									   onClick={() => EnvHelper.push(this.getSheetViewRoute(rowData.id, rowData.partnerOrgName))}/>;
				}
			},
			{title: '# Records', key: 'totalRows', type: 'number'},
			{title: 'Upload Status', key: 'status', renderFunction: (status) => UploadHelper.getUploadStatus(status)},
			{title: 'Audit Status', key: 'auditStatus', renderFunction: getAuditStatus},
			{title: 'Description', key: 'fileDescription'}


		];
	}

	render() {
		return (<div style={{maxWidth: (window.innerWidth - 250)}}>
			<MessageBoxInfo>
				Manage all the Partner Sheets uploaded by users in your organization. View, delete or edit the sheet to reassign it to a different partner org.
			</MessageBoxInfo>
			{this.state.showReassignDialog &&
			 <AdminPartnerReassignDialog selectedSheets={this.state.selectedItemsPersonQueuePublicIds} onClose={this.onClose}/>}
			{this.state.showDeleteDialog && <AdminPartnerDeleteDialog selectedSheets={this.state.selectedItemsPersonQueuePublicIds} onClose={this.onClose}/>}
			{super.render()}
			</div>);
	}
}

const getAuditStatus = (cell, row) => {
	if(row === AUDIT_COMPLETE) return 'Audited';
	if (row === AUDIT_ENABLED) return 'Auditing';
	return 'Not Requested';
};
