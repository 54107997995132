import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import {WORKFLOW_SHARE_ACTION_LIST, workflows} from '../CoSellEngineHelper';
import {AdminConfigBase} from './AdminConfigBase';

export class AdminShareActionList extends AdminConfigBase {

	get workflow() {
		return workflows.find((workflow) => workflow.config === WORKFLOW_SHARE_ACTION_LIST);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsShareActionList;
	}

	get updateWorkflowConfigEndpoint() {
		return CoSellEngineEndpoints.updateCoSellAdminConfigShareActionList;
	}

	get renderAdditionalEditorFields() {
		let {editingAdminSequenceConfig} = this.state;
		let {workflowSequenceShareActionList} = editingAdminSequenceConfig;
		let sequenceOrdinal = 1;
		return [this.renderEmailTemplateEditor(WORKFLOW_SHARE_ACTION_LIST, workflowSequenceShareActionList, sequenceOrdinal)];
	}
}
