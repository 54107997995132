import React, {Component} from 'react';
import FeatureHelper, {FEATURE_CHANNEL_DATA_MANAGER} from '../../../helpers/FeatureHelper';
import {PartnerOrgReassignAdminPage} from './PartnerOrgReassignAdminPage';
import MessageBoxInfo from '../../../ui/messages/MessageBoxInfo';

class AdminPartnerUpdatePage extends Component {
	render() {
		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DATA_MANAGER)) {
			return <MessageBoxInfo hideIcon={true}>Editing Partner Sheets requires administrator level access. Please reach out to your PartnerTap
				administrator. </MessageBoxInfo>;
		}
		return <PartnerOrgReassignAdminPage/>;
	}
}

export default AdminPartnerUpdatePage;