import * as ReduxEnum from './ReduxEnum';

export function showChat(personChatId, partner, account) {
	return {type: ReduxEnum.SHOW_CHAT, personChatId: personChatId, partner: partner, account: account};
}

export function updateChat() {
	return {type: ReduxEnum.UPDATE_CHAT};
}

export function hideChat() {
	return {type: ReduxEnum.HIDE_CHAT};
}
