import * as ApiHelper from '../helpers/ApiHelper';

export function getPartnerOrg(companyPartnerPublicId) {
	return ApiHelper.sendGet('/company-partners/' + companyPartnerPublicId);
}

export function getPartnerOrgs(page, pageSize, search, sort) {
	return ApiHelper.sendGet(ApiHelper.appendPageableParams('/company-partners', page, pageSize, search, sort));
}

export function updatePartnerOrgCrmId(companyPartnerPublicId, partnerName, crmId) {
	let payload = {partnerName: partnerName, crmRecordId: crmId};
	return ApiHelper.sendPut('/company-partners/' + companyPartnerPublicId, payload);
}

export function createPartnerOrg(companyPartnerPayload) {
	return ApiHelper.sendPost('/company-partners', companyPartnerPayload);
}

export function updatePartnerOrg(companyPartnerPublicId, companyPartnerPayload) {
	return ApiHelper.sendPut('/company-partners/' + companyPartnerPublicId, companyPartnerPayload);
}

export function getOrgPartnerTypes() {
	return ApiHelper.sendGet('/org-partner-types');
}

export function setOrgPartnerTypes(partnerTypePayload) {
	return ApiHelper.sendPost('/org-partner-types', partnerTypePayload);
}

export function getCompanyPartnerDetail(payload) {
	return ApiHelper.sendPostReport('/channelecosystem/partnerorg/details', payload);
}
