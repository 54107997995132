import {List} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import DialogHelper from '../../../helpers/DialogHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import NumberHelper from '../../../helpers/NumberHelper';
import StringHelper from '../../../helpers/StringHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapPrimary, partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import PartnerTapIcon from '../../../ui/PartnerTapIcon';

class DashboardTopItem extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {detailDialog: null};
		this.onView = this.onView.bind(this);
	}

	onView() {
		DialogHelper.openDetailDialog('Dashboard Detail', this.props.destination, null, this.setState.bind(this));
	}

	render() {
		let {MaterialIcon, iconGlyph, title, message, value, destination} = this.props;
		return (
			<div style={{
				flex: 1,
				display: 'flex',
				alignItems: 'center',
				boxShadow: partnerTapDropShadow,
				backgroundColor: partnerTapWhite,
				paddingLeft: EnvHelper.isDesktop ? 20 : 10,
				paddingRight: EnvHelper.isDesktop ? 20 : 10,
				paddingTop: 10,
				paddingBottom: 10,
				marginBottom: 10,
				marginLeft: 5,
				marginRight: 5,
				maxWidth: 500,
				minWidth: 300
			}}>
				{this.state.detailDialog}
				<div style={{color: partnerTapPrimary}}>
					{MaterialIcon ? <MaterialIcon fontSize={'large'} style={{paddingRight: 10}}/> : <PartnerTapIcon glyph={iconGlyph} style={{fontSize: 48}}/>}
				</div>
				<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
					<div style={{fontSize: 20}}>
						{title}
					</div>
					<div style={{color: partnerTapInactive}}>
						{message}
					</div>
				</div>
				<div data-cy={StringHelper.formatKey(title)} style={{display: 'flex', flexDirection: 'column', justifyContent: 'right', paddingLeft: 10}}>
					<div style={{paddingBottom: 5, fontSize: 20, fontWeight: 'bold', textAlign: 'right'}}>
						{NumberHelper.formatNumber(value)}
					</div>
					{destination &&
					 <PrimaryButton label={'VIEW'} icon={<List/>} onClick={this.onView}/>}
				</div>
			</div>
		);
	}
}

DashboardTopItem.propTypes = {
	MaterialIcon: PropTypes.elementType,
	iconGlyph: PropTypes.string,
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	value: PropTypes.number.isRequired,
	destination: PropTypes.string
};

export default DashboardTopItem;
