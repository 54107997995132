import {Group} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as  ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import {CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS} from '../../../../helpers/ReportHelper';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import Loading from '../../../../ui/Loading';

class PartnerOrgConnectedPartnersPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {
			companyPartnerPublicId: this.companyPartnerPublicId,
			channelReportType: CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS
		};
		this.state.filterSelectorMounted = false;
	}

	componentDidMount() {
		this.fetchConnectedPartnerColumns();
	}

	get companyPartnerPublicId() {
		return this.props.match.params.companyPartnerPublicId;
	}

	fetchConnectedPartnerColumns() {
		ChannelMappingEndpoints.getColumns({
			channelReportType: CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS,
			companyPartnerPublicId: this.companyPartnerPublicId
		})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchConnectedPartnerColumns', error);
		});
	}

	get storageKeyBase() {
		return 'company_partner_connections';
	}

	get title() {
		return 'Partner Connections';
	}

	get icon() {
		return Group;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		return {
			companyPartnerPublicId: this.companyPartnerPublicId,
			channelReportType: CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS,
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			totalElements: 0,
			personId: this.partnerPersonId,
			exportBaseFileName: 'connected_partners',
			exportFields: DownloadHelper.getFields(this.state.columnData)
		};
	}

	get additionalToolbarButtons() {
		return [<DownloadButton key={'download_button'} callingPage={this}/>];
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				channelReportType: CHANNEL_REPORT_TYPE_PARTNER_ORG_CONNECTED_PARTNERS,
				companyPartnerPublicId: this.companyPartnerPublicId
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		return super.render();
	}
}

export default connect()(PartnerOrgConnectedPartnersPage);
