import {Hub} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {CATEGORY_SHEET_OPPS, OPPORTUNITIES_ASSET} from '../../../../globals/Enums';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import {tempFeatureFlag_CoSellEngine, tempFeatureFlag_CoSellEngine_live} from '../../../../helpers/FeatureHelper';
import * as ReportHelper from '../../../../helpers/ReportHelper';
import {MATCHING_TYPE_ALL_OPPS} from '../../../../helpers/ReportHelper';
import CoSellSaveButton from '../../../../ui/buttons/CoSellSaveButton';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import SaveReportButton from '../../../../ui/buttons/SaveReportButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import Loading from '../../../../ui/Loading';
import CoSellSaveButtonLive from '../../../../ui/buttons/CoSellSaveButtonLive';

class PartnerSheetOpportunitiesPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.personQueueData = {};
		this.state.loading = false;
		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {
			personQueueIds: [this.personQueueId],
			channelReportType: ReportHelper.CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_OPPORTUNITIES
		};
		this.state.filterSelectorMounted = false;
	}

	componentDidMount() {
		this.fetchMatchedOpportunityColumnData();
	}

	get personQueueId() {
		return this.props.match.params.personQueueId;
	}

	get partnerOrgName() {
		let partnerOrgName = this.props.match.params.partnerOrgName;
		if (partnerOrgName === 'null' || partnerOrgName === 'undefined') partnerOrgName = null;
		return partnerOrgName;
	}

	get storageKeyBase() {
		return 'sheet_opps';
	}

	get title() {
		return 'Matched Opportunities';
	}

	get icon() {
		return Hub;
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get additionalToolbarButtons() {
		let {filterValues} = this.state;
		let buttons = [];
		if (tempFeatureFlag_CoSellEngine && !tempFeatureFlag_CoSellEngine_live) {
			buttons.push(<CoSellSaveButton callingPage={this}/>);
		}
		else if (tempFeatureFlag_CoSellEngine_live) {
			buttons.push(<CoSellSaveButtonLive callingPage={this} key={this.state.rowCount}/>);
		}
		buttons.push(<DownloadButton callingPage={this}/>);
		buttons.push(<SaveReportButton reportCategory={CATEGORY_SHEET_OPPS}
									   reportSettings={{
										   reportSettings: {},
										   baseSettings: ChannelHelper.baseSettings(this.storageKeyBase),
										   filter: filterValues
									   }}
									   reportParams={{
										   crmType: CATEGORY_SHEET_OPPS,
										   personQueueId: this.personQueueId
									   }}
									   showSaveDialog={this.showSaveDialog}/>);
		return buttons;
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			importType: this.props.crmType,
			channelReportType: ReportHelper.CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_OPPORTUNITIES,
			matchingType: MATCHING_TYPE_ALL_OPPS,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: 'matched_sheet_opportunities',
			exportFields: DownloadHelper.getFields(this.columnData),
			selectedColumns: this.activeColumns,
			totalElements: '0',
			personQueueIds: [this.personQueueId]
		};
	}

	get coSellReportConfig() {
		let reportConfig = this.downloadReportConfig;
		reportConfig.endpoint = '/cosell/actionlist/save';
		reportConfig.partnerOrgName = this.partnerOrgName || 'None';
		reportConfig.assetType = OPPORTUNITIES_ASSET;
		return reportConfig;
	}

	get coSellReportConfigLive() {
		return {
			reportData: this.downloadReportConfig,
			partnerOrgPublicId: this.personQueueData.partnerOrgPublicId,
			assetType: OPPORTUNITIES_ASSET,
			rowCount: this.state.rowCount
		};
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				personQueueIds: [this.personQueueId],
				selectedColumns: this.activeColumns,
				channelReportType: ReportHelper.CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_OPPORTUNITIES
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort,
			0)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	fetchMatchedOpportunityColumnData() {
		Promise.all([
			ChannelMappingEndpoints.getUploadDataForId(this.personQueueId),
			ChannelMappingEndpoints.getColumns({
				channelReportType: ReportHelper.CHANNEL_REPORT_TYPE_PARTNER_SHEET_MATCHED_OPPORTUNITIES,
				personQueueIds: [this.personQueueId]
			})
		])
		.then((results) => {
			if (this.unmounted) return;
			this.personQueueData = results[0].payload;
			this.initColumnData(results[1].payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getOppsColumns', error);
		});
	}

	render() {
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		if (this.isSavedReport) {
			return DialogHelper.renderSavedReportDialogButtons(super.render(), () => this.forceUpdate(), this.closeSavedReportDialog);
		}
		return super.render();
	}
}

export default withRouter(connect()(PartnerSheetOpportunitiesPage));
