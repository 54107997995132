import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import DashboardTopItem from '../../app/channel_ecosystem/shared/DashboardTopItem';
import * as ManagerAnalyticsEndpoints from '../../endpoints/ManagerAnalyticsEndpoints';
import {Routes} from '../../globals/Routes';
import FeatureHelper, {FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS} from '../../helpers/FeatureHelper';
import {partnerTapStroke, partnerTapWhite} from '../../styles/partnertap_theme';
import BarGraph from '../../ui/graphs/BarGraph';
import CircleGraph from '../../ui/graphs/CircleGraph';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import Loading from '../../ui/Loading';

class DashboardAnalytics extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: true,
			totalUsers: 0,
			accounts: null,
			nonOrgAnalytics: null,
			repUsageCircleSections: [],
			repUsageBarSections: [],
			repIntelCountsBarSections: [],
			intelLeaderboardExtras: []
		};
	}

	componentDidMount() {
		this.updateData(this.props.managerAnalyticsState.currentCompanyName, this.props.managerAnalyticsState.searchRange, this.props.managerAnalyticsState.division);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((prevProps.managerAnalyticsState.currentCompanyName !== this.props.managerAnalyticsState.currentCompanyName ||
			 prevProps.managerAnalyticsState.searchRange !== this.props.managerAnalyticsState.searchRange ||
			 prevProps.managerAnalyticsState.division !== this.props.managerAnalyticsState.division)) {
			this.updateData(this.props.managerAnalyticsState.currentCompanyName, this.props.managerAnalyticsState.searchRange, this.props.managerAnalyticsState.division);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	updateData(currentCompanyName, searchRange, division) {
		if (!division && !FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS)) division = ManagerAnalyticsEndpoints.ALL_DIVISIONS;
		Promise.all([
			ManagerAnalyticsEndpoints.fetchDashboardData(ManagerAnalyticsEndpoints.keyTopRepUsage, null, null, division, null),
			ManagerAnalyticsEndpoints.fetchDashboardData(ManagerAnalyticsEndpoints.keyTopCounts, searchRange, currentCompanyName, division, null),
			ManagerAnalyticsEndpoints.fetchDashboardData(ManagerAnalyticsEndpoints.keyGraphRepUsage, searchRange, null, division, 'loginCount,desc'),
			ManagerAnalyticsEndpoints.fetchDashboardData(ManagerAnalyticsEndpoints.keyGraphPartnerCounts, searchRange, currentCompanyName, division, null),
			ManagerAnalyticsEndpoints.fetchDashboardData(ManagerAnalyticsEndpoints.keyGraphIntelCounts, searchRange, currentCompanyName, division, null),
			ManagerAnalyticsEndpoints.fetchDashboardData(ManagerAnalyticsEndpoints.keyExtraIntelCounts, searchRange, currentCompanyName, division, 'chattedAccountCount,asc')
		])
		.then((results) => {
			if (this.unmounted) return;
			let totalUsers = results[0];
			let accounts = results[1];
			let repUsage = results[2];
			let partnerCounts = results[3];
			let graphIntelCounts = results[4];
			let extraIntelCounts = results[5];
			this.setState({
				totalUsers: totalUsers.payload,
				accounts: accounts.payload,
				repUsage: repUsage,
				partnerCountsCircle: this.collectPartnerCountsCircleGraph(partnerCounts.payload),
				partnerCountsExtra: this.collectPartnerCountsExtra(partnerCounts.payload),
				repUsageCircleSections: this.collectRepUsageCircle(repUsage, this.props.managerAnalyticsState.searchRange === -1 ? 2 : 1),
				repUsageBarSections: this.collectRepUsageBar(repUsage, '#00a9ff', '#00a9ff', 'loginCount'),
				intelLeaderboardBarSections: this.collectRepUsageBar(graphIntelCounts, '#00c49f', '#00a9ff', 'chattedAccountCount'),
				intelLeaderboardExtras: this.collectIntelLeaderboardExtra(extraIntelCounts.payload),
				loading: false
			});
		})
		.catch((error) => {
			console.error('Error on initial load of non org analytics and repUsage', error);
		});
	}

	collectRepUsageCircle(repData, n) {
		if (_.isEmpty(repData.payload)) {
			return [];
		}
		let totalRepsWithLogin = 0;
		repData.payload.forEach((item) => {
			if (item.loginCount > n) {
				totalRepsWithLogin++;
			}
		});
		let inactive = repData.payload.length - totalRepsWithLogin;
		let inactiveFill = partnerTapWhite;
		let inactiveStroke = '#00a9ff';
		if (totalRepsWithLogin === 0 && inactive > 0) {
			inactiveFill = partnerTapStroke;
			inactiveStroke = partnerTapWhite;
		}
		return [
			{key: 0, name: 'Active Reps', fill: '#00a9ff', stroke: partnerTapWhite, value: totalRepsWithLogin, textColor: '#8080a1'},
			{key: 1, name: 'Inactive Reps', fill: inactiveFill, stroke: inactiveStroke, value: inactive, textColor: '#00a9ff'}
		];
	}

	collectRepUsageBar(repData, fill, textColor, keyCount) {
		if (_.isEmpty(repData.payload)) {
			return [];
		}
		let result = [];
		for (let i = 0; i < repData.payload.length; i++) {
			if (i >= 5) {
				break;
			}
			result.push({
				key: i,
				name: repData.payload[i].repName,
				personId: repData.payload[i].personId,
				fill: fill,
				value: repData.payload[i][keyCount],
				textColor: textColor
			});
		}
		return result;
	}

	collectPartnerCountsCircleGraph(partnerCounts) {
		if (_.isEmpty(partnerCounts) ||
			(partnerCounts.acceptedConnectionsCount === 0 &&
			 partnerCounts.pendingSentCount === 0 &&
			 partnerCounts.pendingReceivedCount === 0 &&
			 partnerCounts.declinedConnectionsCount === 0)) {
			return [];
		}
		return [
			{key: 0, name: 'Accepted', fill: '#00c49f', value: partnerCounts.acceptedConnectionsCount, textColor: '#8080a1'},
			{key: 1, name: 'Pending Sent', fill: '#0088fe', value: partnerCounts.pendingSentCount, textColor: '#8080a1'},
			{key: 2, name: 'Pending Received', fill: '#c44fd7', value: partnerCounts.pendingReceivedCount, textColor: '#8080a1'},
			{key: 3, name: 'Declined', fill: '#ffbb28', value: partnerCounts.declinedConnectionsCount, textColor: '#8080a1'}
		];
	}

	collectPartnerCountsExtra(partnerCounts) {
		if (_.isEmpty(partnerCounts)) {
			return [];
		}
		return [{key: 0, name: 'Recommended Connections', value: partnerCounts.recommendedConnectionsCount}];
	}

	collectIntelLeaderboardExtra(extraIntelCounts) {
		if (_.isEmpty(extraIntelCounts)) {
			return [];
		}
		return [
			{key: 1, name: 'Intel Shared', value: extraIntelCounts.chatMessageCount},
			{key: 2, name: 'Reps Sharing Intel', value: extraIntelCounts.repCount}
		];
	}

	render() {
		if (this.state.loading) return <Loading>Loading Dashboard...</Loading>;
		return (
			<ScrollingContainer divId={'dashboard_analytics'}>
				<div style={{margin: 10, marginLeft: 5, marginRight: 5, overflow: 'scroll'}}>
					<div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
						<DashboardTopItem iconGlyph={'X'}
										  title={'Total Reps'}
										  value={this.state.totalUsers.repCount || 0}
										  destination={'/manager/connectionsanalytics'}/>
						<DashboardTopItem iconGlyph={'5'}
										  title={'Matched Accounts'}
										  value={this.state.accounts.matchedAccountCount || 0}
										  destination={'/manager/matchedaccountsanalytics'}/>
						<DashboardTopItem iconGlyph={'K'}
										  title={'Opps for ' + this.props.authState.profile.organization}
										  value={this.state.accounts.incomingOpportunitiesCount || 0}
										  destination={Routes.MANAGER_ANALYTICS.TAP.INBOUND}/>
					</div>
					<div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'stretch'}}>
						{this.state.partnerCountsCircle.length > 0 &&
						 <CircleGraph title={'Rep Connections'}
									  sections={this.state.partnerCountsCircle}
									  destination={Routes.MANAGER_ANALYTICS.CONNECTIONS_ANALYTICS.ROUTE}
									  extraStats={this.state.partnerCountsExtra}/>}
						{this.state.intelLeaderboardBarSections.length > 0 &&
						 <BarGraph title={'Account Based Intel Leaderboard'}
								   sections={this.state.intelLeaderboardBarSections}
								   destination={Routes.MANAGER_ANALYTICS.INTEL_ANALYTICS.ROUTE}
								   extraStats={this.state.intelLeaderboardExtras}/>}
						{this.state.repUsageBarSections.length > 0 &&
						 <BarGraph title={'Most Active Reps'}
								   sections={this.state.repUsageBarSections}/>}
						{this.state.repUsageCircleSections.length > 0 &&
						 <CircleGraph title={'Rep Login Activity'}
									  sections={this.state.repUsageCircleSections}/>}
					</div>
				</div>
			</ScrollingContainer>
		);
	}
}

DashboardAnalytics.propTypes = {
	managerDetails: PropTypes.object,
	authState: PropTypes.object.isRequired,
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default connect(mapStateToProps)(DashboardAnalytics);
