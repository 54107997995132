import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ChatHelper from '../../../helpers/ChatHelper';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapDefaultText, partnerTapInactive, partnerTapSecondary, partnerTapStroke, partnerTapWhite} from '../../../styles/partnertap_theme';
import Pic from '../../../ui/Pic';

class IntelRowRenderer extends Component {

	constructor(props, context) {
		super(props, context);
		this.chatItemClick = this.chatItemClick.bind(this);
	}

	chatItemClick() {
		ChatHelper.showChat(this.props.rowData.personChatId, this.props);
	}

	renderPics() {
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<Pic personId={this.props.rowData.partnerPersonId} tmpLogo={this.props.rowData.partnerName} picSize={30}/>
				{this.props.rowData.saleAccountId &&
				 <div style={{padding: 2}}/>}
				{this.props.rowData.saleAccountId &&
				 <Pic tmpLogo={this.props.rowData.saleAccountName} bizId={this.props.rowData.saleAccountId} picSize={30}/>}
			</div>
		);
	}

	render() {
		let milliseconds = this.props.rowData.chatDate.indexOf('-') > -1 ? DateHelper.getMillisecondsWithTimeZoneOffset(this.props.rowData.chatDate) : this.props.rowData.chatDate;
		let elapsed = DateHelper.getElapsedTime(milliseconds);

		let rowStyle = {padding: 10, borderBottom: '1px solid ' + partnerTapStroke, cursor: 'pointer'};
		if (this.props.rowData.selected) {
			rowStyle.backgroundColor = partnerTapWhite;
		}

		let partnerNameStyle = {fontSize: 14, color: partnerTapSecondary, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};
		let reStyle = {fontSize: 12, color: partnerTapDefaultText, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};
		let elapsedStyle = {fontSize: 12, color: partnerTapInactive, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};
		let messageStyle = {fontSize: 12, color: partnerTapInactive, fontStyle: 'italic', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};

		if (!EnvHelper.isDesktop) {
			elapsedStyle.float = 'right';
			messageStyle.float = 'left';
		}

		return (
			<div id={this.props.rowData.personChatId} className={'hoverActive'} style={rowStyle} onClick={this.chatItemClick}>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 81}}>
					<div style={{display: 'flex', alignItems: 'center'}}>
						{this.renderPics()}
						<div style={{paddingLeft: 10, overflow: 'hidden', width: '100%'}}>
							<div style={partnerNameStyle}>{this.props.rowData.partnerName}</div>
							{EnvHelper.inSalesNetworkProduct && <div style={reStyle}>{this.props.rowData.saleAccountName}</div>}
							<div style={elapsedStyle}>{elapsed}</div>
							<div style={messageStyle}>{this.props.rowData.message}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

IntelRowRenderer.propTypes = {
	rowData: PropTypes.shape({
		personChatId: PropTypes.string.isRequired,
		chatDate: PropTypes.string.isRequired,
		message: PropTypes.string.isRequired,
		selected: PropTypes.bool,

		partnerId: PropTypes.string,
		partnerStatus: PropTypes.string,
		partnerPersonId: PropTypes.string,
		partnerName: PropTypes.string,
		partnerOrg: PropTypes.string,

		saleAccountId: PropTypes.string,
		saleAccountLocationId: PropTypes.string,
		saleAccountName: PropTypes.string,
		saleAccountLocation: PropTypes.string
	})
};

export default withRouter(connect()(IntelRowRenderer));
