import {Settings} from '@mui/icons-material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ProfilesEndpoints from '../../endpoints/ProfilesEndpoints';
import * as SettingsEndpoints from '../../endpoints/SettingsEndpoints';
import {Routes} from '../../globals/Routes';
import EnvHelper from '../../helpers/EnvHelper';
import * as AuthActions from '../../redux/AuthActions';
import CheckboxButton from '../../ui/buttons/CheckboxButton';
import SecondaryButton from '../../ui/buttons/SecondaryButton';
import Loading from '../../ui/Loading';
import MessageBoxInfo from '../../ui/messages/MessageBoxInfo';
import PageHeader from '../../ui/PageHeader';
import {FEEDBACK_UI_ELEMENT_TIMEOUT} from '../App';

class SettingsPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.hasProfile = this.props.authState && this.props.authState.profile;
		this.emailKey = EnvHelper.getUrlParam('emailKey');
		this.userId = EnvHelper.getUrlParam('userId');
		this.hasEmailAddress = this.userId && this.userId.indexOf('@') > 1;

		if (this.hasProfile) {
			let {settings} = this.props.authState.profile;
			if (!settings) {
				settings = '1111';
			}
			this.checkBlacklist = false;
			this.allowPartnerTapEmail = settings[0] !== '0';
			this.allowPartnerEmail = settings[1] !== '0';
			this.allowDailyEmail = settings[2] !== '0';
			this.allowIncomingPartnerRequests = settings[3] !== '0';
			this.state = {
				loading: false,
				updated: false,
				emailAddress: '',
				allowPartnerTapEmail: this.allowPartnerTapEmail,
				allowPartnerEmail: this.allowPartnerEmail,
				allowDailyEmail: this.allowDailyEmail,
				allowIncomingPartnerRequests: this.allowIncomingPartnerRequests
			};
		}
		else {
			this.checkBlacklist = Boolean(this.emailKey && this.hasEmailAddress);
			this.allowPartnerTapEmail = true;
			this.allowPartnerEmail = true;
			this.allowDailyEmail = true;
			this.allowIncomingPartnerRequests = true;
			this.state = {
				loading: this.checkBlacklist,
				requireLogin: !this.checkBlacklist,
				updated: false,
				emailAddress: '',
				emailKey: '',
				allowPartnerTapEmail: true,
				allowPartnerEmail: true,
				allowDailyEmail: true,
				allowIncomingPartnerRequests: true
			};
		}

		this.onChangePartnerTapEmail = this.onChangePartnerTapEmail.bind(this);
		this.onChangePartnerEmail = this.onChangePartnerEmail.bind(this);
		this.onChangeDailyEmail = this.onChangeDailyEmail.bind(this);
		this.onChangeAllowPartner = this.onChangeAllowPartner.bind(this);
	}

	componentDidMount() {
		if (this.checkBlacklist) {
			SettingsEndpoints.excludedEmailAddress(this.userId, this.emailKey, 'STATUS')
			.then((result) => {
				if (this.unmounted) return;
				this.setState({
					loading: false,
					emailAddress: this.userId,
					emailKey: this.emailKey,
					allowPartnerTapEmail: result.payload === 'NOT_BLACKLISTED'
				});
			});
		}
	}

	componentWillUnmount() {
		if (this.timeout) clearTimeout(this.timeout);
		this.unmounted = true;
	}

	onChangePartnerTapEmail() {
		this.allowPartnerTapEmail = !this.state.allowPartnerTapEmail;
		this.updateProfileSettings();
	}

	onChangePartnerEmail() {
		this.allowPartnerEmail = !this.state.allowPartnerEmail;
		this.updateProfileSettings();
	}

	onChangeDailyEmail() {
		this.allowDailyEmail = !this.state.allowDailyEmail;
		this.updateProfileSettings();
	}

	onChangeAllowPartner() {
		this.allowIncomingPartnerRequests = !this.state.allowIncomingPartnerRequests;
		this.updateProfileSettings();
	}

	updateProfileSettings() {
		let {profile} = this.props.authState;
		if (profile.id) {
			let settings = (this.allowPartnerTapEmail ? '1' : '0') +
						   (this.allowPartnerEmail ? '1' : '0') +
						   (this.allowDailyEmail ? '1' : '0') +
						   (this.allowIncomingPartnerRequests ? '1' : '0');
			ProfilesEndpoints.updateProfileSettings(profile, settings, this.state.emailKey)
			.then((result) => {
				if (this.unmounted) return;
				profile = Object.assign({}, profile);
				profile.settings = settings;
				EnvHelper.dispatch(AuthActions.loginProfile(profile));
			});
		}
		else {
			SettingsEndpoints.excludedEmailAddress(this.state.emailAddress, this.state.emailKey, this.allowPartnerTapEmail ? 'REMOVE' : 'ADD');
		}

		this.setState({
			updated: true,
			allowPartnerTapEmail: this.allowPartnerTapEmail,
			allowPartnerEmail: this.allowPartnerEmail,
			allowDailyEmail: this.allowDailyEmail,
			allowIncomingPartnerRequests: this.allowIncomingPartnerRequests
		});
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({updated: false});
		}, FEEDBACK_UI_ELEMENT_TIMEOUT);
	}

	makeCheckBox(label, isChecked, onChange) {
		return (
			<div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}>
				<CheckboxButton checked={isChecked} onChange={onChange} disabled={EnvHelper.isSpoofing}/>
				<div style={{fontSize: 16, fontWeight: 'bold'}}>{label}</div>
			</div>
		);
	}

	render() {
		if (this.state.loading) return <Loading>Loading Settings...</Loading>;
		let hasProfile = !_.isEmpty(this.props.authState?.profile);
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<PageHeader title={'Settings'} icon={<Settings fontSize={'large'}/>}/>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll', paddingTop: hasProfile ? 20 : 140}}>
					{this.state.requireLogin ?
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
							<div style={{padding: 20}}>
								Please sign in to update your settings
							</div>
							<SecondaryButton label={'Login'} onClick={() => EnvHelper.push(Routes.HOME.ROUTE)}/>
						</div>
						:
						<div style={{width: 260, height: '100%'}}>
							{this.makeCheckBox('Receive email from PartnerTap', this.state.allowPartnerTapEmail, this.onChangePartnerTapEmail)}
							{hasProfile &&
							 <Fragment>
								 <div style={{paddingTop: 20}}>
									 {this.makeCheckBox('Receive email from your partners', this.state.allowPartnerEmail, this.onChangePartnerEmail)}
								 </div>
								 <div style={{paddingTop: 20}}>
									 {this.makeCheckBox('Receive daily summary email', this.state.allowDailyEmail, this.onChangeDailyEmail)}
								 </div>
								 <div style={{paddingTop: 20}}>
									 {this.makeCheckBox('Allow incoming partner requests', this.state.allowIncomingPartnerRequests, this.onChangeAllowPartner)}
								 </div>
							 </Fragment>}
							{this.state.updated ?
								<MessageBoxInfo title={'Setting updated'}/>
								:
								<div style={{padding: 20}}/>}
						</div>}
				</div>
			</div>
		);
	}
}

SettingsPage.propTypes = {
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(SettingsPage));
