import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as TapEndpoints from '../../../endpoints/TapEndpoints';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import FindPartnersButton from '../../../ui/buttons/FindPartnersButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import PagingBase from '../../../ui/lists/PagingBase';
import AccountDetailsRouter from '../accounts/AccountDetailsRouter';
import AccountsPage from '../accounts/AccountsPage';
import TapRowRenderer from './TapRowRenderer';

class TapPage extends PagingBase {

	onShareOpps() {
		EnvHelper.push(Routes.DASHBOARD.TAP.SHARE.ROUTE);
	}

	get isSubNavShare() {
		return EnvHelper.path.indexOf(Routes.DASHBOARD.TAP.SHARE.ROUTE) === 0;
	}

	get isSubNavShareDetail() {
		return EnvHelper.path.indexOf(Routes.DASHBOARD.TAP.SHARE_PARTNERS.PATH) === 0;
	}

	get storageKeyBase() {
		if (this.isSubNavShare) return 'network_tap_page_share_opps';
		if (this.isSubNavShareDetail) return 'network_tap_page_share_opps_detail';
		return 'network_tap_page';
	}

	get title() {
		if (this.isSubNavShare) return 'Share Opps';
		if (this.isSubNavShareDetail) return 'Share Settings';
		return 'Active Opps';
	}

	get icon() {
		return 'g';
	}

	get sortData() {
		return [
			{name: 'Date', key: 'changedDate,desc', selected: true},
			{name: 'Account Name', key: 'saleAccountName,asc'},
			{name: 'Closed Opps', key: 'closeCount,desc'},
			{name: 'Open Opps', key: 'openCount,desc'},
			{name: 'Partner Name', key: 'partnerName,asc'},
			{name: 'Organization', key: 'partnerOrg,asc'}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (this.isSubNavShare) return;
		let params = this.processParameters();
		TapEndpoints.fetchOpportunities(params.page, params.pageSize, params.search, params.sort)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			if (this.isSubNavShare) {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							You need partners with matched accounts to share opportunities.
						</div>
						<div>
							Invite partners and start profiting!
						</div>
						<div style={{padding: 10}}>
							<FindPartnersButton/>
						</div>
					</div>
				);
			}
			else {
				return (
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
						<div style={{padding: 20}}>
							You can share opportunities with any partner that has matched accounts!
						</div>
						<PrimaryButton label={'SHARE OPPORTUNITIES'} onClick={this.onShareOpps}/>
					</div>
				);
			}
		}
		return super.noDataMessage;
	}

	get rowRenderer() {
		return TapRowRenderer;
	}

	render() {
		if (this.isSubNavShare) {
			if (this.isSubNavShareDetail) {
				return <AccountDetailsRouter forShareOpportunities={true} match={this.props.match}/>;
			}
			return <AccountsPage forShareOpportunities={true}/>;
		}
		return super.render();
	}
}

TapPage.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(TapPage));
