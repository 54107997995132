import React from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import {WORKFLOW_SEQUENCE_CRM_TRACKING_NONE} from '../CoSellEngineHelper';

export class WorkflowCrmTracking {

	constructor(actionListInfo, workflow, workflowRecords) {
		this.actionListInfo = actionListInfo;
		this.workflow = workflow;
		this.workflowRecords = workflowRecords;

		this.getCurrentSequence = this.getCurrentSequence.bind(this);
		this.onSelectSequence = this.onSelectSequence.bind(this);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsCrmTracking;
	}

	get steps() {
		return [{label: 'Sequence'}];
	}

	getCurrentSequence(adminSequenceConfig) {
		return adminSequenceConfig.workflowSequenceCrmTracking === this.actionListInfo.workflowSequenceCrmTracking;
	}

	onSelectSequence(adminSequenceConfig) {
		this.actionListInfo.workflowSequenceCrmTracking = adminSequenceConfig.workflowSequenceCrmTracking;
	}

	get isUnavailable() {
		return null;
	}

	validate(currentWorkflowStep) {
		let {workflowSequenceCrmTracking} = this.actionListInfo;
		switch (currentWorkflowStep) {
			case 0:
				return workflowSequenceCrmTracking !== WORKFLOW_SEQUENCE_CRM_TRACKING_NONE;
		}
	}

	confirm() {
		let {workflowRecords} = this;
		return (
			<div>
				Upload {workflowRecords.length} activit{workflowRecords.length === 1 ? 'y' : 'ies'} to your CRM
			</div>
		);
	}
}
