import {CHANNEL_MAPPER, INVITE_STATUS_REQUESTED} from '../globals/Enums';
import * as ApiHelper from '../helpers/ApiHelper';
import {SHARE_SUMMARY} from '../helpers/EcosystemShareHelper';
import EnvHelper from '../helpers/EnvHelper';
import {PRODUCT_CHANNEL_ECOSYSTEM} from '../helpers/FeatureHelper';
import SortHelper from '../helpers/SortHelper';

export function searchPartners(page, search, sort, minMatchedAccounts, status) {
	if (!page) page = 0;
	if (!search) search = '';
	if (!sort) sort = EnvHelper.inChannelEcosystemProduct ? SortHelper.sortChannelPartnersByName : SortHelper.sortPartnersByName;
	let endpointBase = EnvHelper.inChannelEcosystemProduct ? '/partners/channel' : '/partners/page';
	let endpoint = endpointBase + '?page=' + page + '&search=' + search + '&sort=' + sort + '&minMatchedAccounts=' + minMatchedAccounts + '&status=' + status.join(',');
	return ApiHelper.sendGet(endpoint);
}

export function fetchAccountPartnersPage(page, saleAccountLocationId, search) {
	if (!search) search = '';
	let sort = 'partnerStatus,asc&sort=partnerName,asc';
	let endpoint = '/partners/saleAccount/page?page=' + page + '&saleAccountLocationId=' + saleAccountLocationId + '&search=' + search + '&sort=' + sort;
	return ApiHelper.sendGet(endpoint);
}

export function fetchPartnerRequestsReceived() {
	let endpoint = '/partners?status=' + INVITE_STATUS_REQUESTED;
	return ApiHelper.sendGet(endpoint);
}

export function fetchPartnerRecommendations(page, search, sort) {
	if (!page) page = 0;
	if (!search) search = '';
	if (!sort) sort = SortHelper.sortRecommendedPartners;
	if (sort.indexOf('partnerName') === -1) {
		sort += '&sort=partnerName,asc';
	}
	let endpoint = '/partners/recommended?page=' + page + '&search=' + search + '&sort=' + sort;
	return ApiHelper.sendGet(endpoint);
}

export function fetchPartner(partnerId) {
	return ApiHelper.sendGet('/partners/' + partnerId);
}

export function sendPartnerInvite(personId, partnerPersonId, userNote, shareOpps, productCode, accountShareOption, opportunityShareOption) {
	if (productCode === CHANNEL_MAPPER) productCode = PRODUCT_CHANNEL_ECOSYSTEM;
	let payload = {
		ownerId: personId,
		personId: partnerPersonId,
		status: INVITE_STATUS_REQUESTED,
		userNote: userNote ? userNote : '',
		sharedOpp: Boolean(shareOpps),
		productCode: productCode,
		accountsShareOption: accountShareOption ? accountShareOption : SHARE_SUMMARY,
		oppsShareOption: opportunityShareOption ? opportunityShareOption : SHARE_SUMMARY
	};
	return ApiHelper.sendPost('/partners', payload);
}

export function createPartner(partnerPersonId, productCode, connectWithPartner) {
	if (productCode === CHANNEL_MAPPER) productCode = PRODUCT_CHANNEL_ECOSYSTEM;
	let payload = {
		personId: partnerPersonId,
		productCode: productCode,
		connectWithPartner: connectWithPartner
	};
	return ApiHelper.sendPost('/partners/create', payload);
}

export function updatePartner(partnerId, status, sharedOpp, productCode) {
	let payload = {'status': status, 'sharedOpp': Boolean(sharedOpp), 'productCode': productCode};
	return ApiHelper.sendPut('/partners/' + partnerId, payload);
}

export function updatePartners(payload) {
	return ApiHelper.sendPost('/partners/updateAll', payload);
}
