import React, {Component} from 'react';
import Dialog from '../../../ui/Dialog';
import PropTypes from 'prop-types';
import Loading from '../../../ui/Loading';
import * as RestAdminCenterEndPoints from '../../../endpoints/RestAdminCenterEndPoints';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapAlert} from '../../../styles/partnertap_theme';

class AdminPartnerDeleteDialog extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			loading: false,
			deleteInProgress: false,
			deleteError: null
		};
		this.onDelete = this.onDelete.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onDelete() {
		const payload = {
			personQueuePublicIds: this.props.selectedSheets
		};
		this.setState({deleteInProgress: true});
		RestAdminCenterEndPoints.deletePartnerOrgs(payload)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({deleteInProgress: false});
			this.props.onClose(true);
		})
		.catch((error) => {
			this.setState({deleteInProgress: false, deleteError: 'Error occurred while Deleting the Partner Orgs'});
			EnvHelper.serverError('Error from onDelete', error);
		});
	}

	render() {
		const {onClose, selectedSheets} = this.props;
		const selectedSheetsCount = selectedSheets.length;
		return (<Dialog
			title="Delete Partner Sheet"
			message={
				<div>
					{this.state.deleteInProgress &&
					 <div style={{
						 position: 'absolute',
						 marginLeft: 'auto',
						 marginRight: 'auto',
						 left: 0,
						 right: 0,
						 backgroundColor: 'rgba(255, 255, 255, 0.8)',
						 borderRadius: 20
					 }}>
						 <Loading>Delete is in progress</Loading>
					 </div>}

					<div style={{display: 'flex', flexDirection: 'column', minWidth: 700, maxWidth: 700}}>
						{this.state.uploadError &&
						 <div style={{fontSize: 16, color: partnerTapAlert, textAlign: 'center'}}>
							 {this.state.uploadError}
						 </div>}
						<div style={{display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 5, width: 700}}>
							You are about to delete {`${selectedSheetsCount} partner ${selectedSheetsCount > 1 ? 'sheets' : 'sheet'} `}.
							Deleting this sheet will immediately remove its data from the partner sheet reports and from the ecosystem reports in 24 hours.
							Do you want to continue?
						</div>
					</div>
				</div>
			}
			onClose={() => onClose(false)}
			yesLabel={'DELETE'}
			yesAction={this.onDelete}
			noLabel={'CANCEL'}
			noAction={() => onClose(false)}
		/>);
	}
}

AdminPartnerDeleteDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	selectedSheets: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AdminPartnerDeleteDialog;
