import SavedReportBase from './SavedReportBase';

class SavedReportGlobal extends SavedReportBase {

	get path() {
		return this.reportUrl + '/' + this.reportType;
	}

	get params() {
		return super.params + '&' + 'reportType=' + this.reportType;
	}
}

export default SavedReportGlobal;
