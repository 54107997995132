import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import {
	WORKFLOW_INTRO_EMAIL,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN,
	WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN,
	workflows
} from '../CoSellEngineHelper';
import {AdminConfigBase} from './AdminConfigBase';

export class AdminIntroEmail extends AdminConfigBase {

	get workflow() {
		return workflows.find((workflow) => workflow.config === WORKFLOW_INTRO_EMAIL);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsIntroEmail;
	}

	get updateWorkflowConfigEndpoint() {
		return CoSellEngineEndpoints.updateCoSellAdminConfigIntroEmail;
	}

	get renderAdditionalEditorFields() {
		let additionalFields = [];
		let {editingAdminSequenceConfig} = this.state;
		let {workflowSequenceIntroEmail} = editingAdminSequenceConfig;
		let sequenceOrdinal = 1;

		if (workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN ||
			workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN) {
			additionalFields.push(this.renderEmailTemplateEditor(WORKFLOW_INTRO_EMAIL, workflowSequenceIntroEmail, sequenceOrdinal++));
		}

		if (workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN) {
			additionalFields.push(this.renderEmailTemplateEditor(WORKFLOW_INTRO_EMAIL, workflowSequenceIntroEmail, sequenceOrdinal++));
		}

		if (workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN ||
			workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN) {
			additionalFields.push(this.renderEmailTemplateEditor(WORKFLOW_INTRO_EMAIL, workflowSequenceIntroEmail, sequenceOrdinal++));
		}

		if (workflowSequenceIntroEmail === WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN) {
			additionalFields.push(this.renderEmailTemplateEditor(WORKFLOW_INTRO_EMAIL, workflowSequenceIntroEmail, sequenceOrdinal++));
		}

		additionalFields.push(this.renderEmailTemplateEditor(WORKFLOW_INTRO_EMAIL, workflowSequenceIntroEmail, sequenceOrdinal));
		return additionalFields;
	}
}
