import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import {WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL, workflows} from '../CoSellEngineHelper';
import {AdminConfigBase} from './AdminConfigBase';

export class AdminDistributeRecordsExternal extends AdminConfigBase {

	get workflow() {
		return workflows.find((workflow) => workflow.config === WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL);
	}

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsDistributeRecordsExternal;
	}

	get updateWorkflowConfigEndpoint() {
		return CoSellEngineEndpoints.updateCoSellAdminConfigDistributeRecordsExternal;
	}

	get renderAdditionalEditorFields() {
		let {editingAdminSequenceConfig} = this.state;
		let {workflowSequenceDistributeRecordsExternal} = editingAdminSequenceConfig;
		let sequenceOrdinal = 1;
		return [this.renderEmailTemplateEditor(WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL, workflowSequenceDistributeRecordsExternal, sequenceOrdinal)];
	}
}
