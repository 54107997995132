import {PersonAdd} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as InviteEndpoints from '../../../endpoints/InviteEndpoints';
import {INVITE_NOT_SENT_REPEAT, INVITE_SENT} from '../../../globals/Enums';
import EnvHelper from '../../../helpers/EnvHelper';
import InviteHelper from '../../../helpers/InviteHelper';
import {partnerTapQuaternary, partnerTapSecondary, partnerTapStroke, partnerTapTernary} from '../../../styles/partnertap_theme';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Loading from '../../../ui/Loading';
import Pic from '../../../ui/Pic';

class PartnerInviteRowRenderer extends Component {

	constructor(props, context) {
		super(props, context);
		this.emailContact = this.emailContact.bind(this);
		this.updateInviteAll = this.updateInviteAll.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	emailContact() {
		if (!this.props.rowData.inviteSent && !this.props.rowData.inviteSending) {
			this.props.rowData.inviteSending = true;
			this.forceUpdate();
			InviteEndpoints.addPartnerWithEmail([this.props.rowData.emailAddress])
			.then((result) => {
					if (this.unmounted) return;
					if (result.payload instanceof Array && result.payload.length === 1) {
						console.info('Invite Contact response: ', result.payload);
						let response = result.payload[0];
						let sent = response === INVITE_SENT || response === INVITE_NOT_SENT_REPEAT;
						if (sent) InviteHelper.storeInviteEmail(this.props.rowData.emailAddress);
						this.props.rowData.inviteSent = sent;
						this.props.rowData.inviteSending = false;
						this.forceUpdate();
						this.updateInviteAll();
					}
					else {
						console.error('Error addPartnerWithEmail response is invalid', result);
					}
				}
			);
		}
	}

	updateInviteAll() {
		this.props.onChange();
	}

	render() {
		return (
			<tr>
				<td>
					<div className={'hoverActive'}
						 style={{borderBottom: '1px solid ' + partnerTapQuaternary, padding: 10}}
						 key={this.props.rowData.id}>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
							<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<Pic tmpLogo={this.props.rowData.firstName}/>
									<div style={{paddingLeft: 10}}>
										<h3>
											<div style={{color: partnerTapSecondary}}>
												{this.props.rowData.firstName} {this.props.rowData.lastName}
											</div>
											<div style={{paddingTop: 2, color: partnerTapStroke}}>
												{this.props.rowData.organization}
											</div>
										</h3>
									</div>
								</div>
							</div>
							{this.props.rowData.inviteSent ?
								<div style={{paddingRight: 30, color: partnerTapTernary}}>Invited</div>
								:
								(this.props.rowData.inviteSending ?
									<div style={{paddingRight: 30}}><Loading small={true}/></div> :
									<SecondaryButton label={'Invite'} icon={<PersonAdd/>} onClick={this.emailContact} disabled={EnvHelper.isSpoofing}/>)}
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

PartnerInviteRowRenderer.propTypes = {
	rowData: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		emailAddress: PropTypes.string,
		organization: PropTypes.string,
		inviteSending: PropTypes.bool,
		inviteSent: PropTypes.bool
	}),
	onChange: PropTypes.func
};

export default PartnerInviteRowRenderer;
