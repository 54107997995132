import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as DataManagerEndpoints from '../../../endpoints/DataManagerEndpoints';
import {Routes} from '../../../globals/Routes';
import DataManagerHelper from '../../../helpers/DataManagerHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import PersonQueueHelper from '../../../helpers/PersonQueueHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapAlert, partnerTapAppBackground, partnerTapStroke, partnerTapTernary, partnerTapWhite} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SupportButton from '../../../ui/buttons/SupportButton';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import ProgressBar from '../../../ui/ProgressBar';
import Stepper from '../../../ui/Stepper';
import {CRM_DYNAMICS, MSFT, SFDC, SFDC_SANDBOX} from '../../../globals/Enums';
import LinkButton from '../../../ui/buttons/LinkButton';

class DataManagerWizardPageStep3 extends Component {

	constructor(props, context) {
		super(props, context);
		this.crmType = this.props.match.params.crmType;
		this.state = {
			personQueueId: null,
			loading: true,
			error: null,
			personQueue: null,
			steps: [
				{label: 'Choose CRM', active: false, complete: true},
				{label: 'Customize Data', active: false, complete: true},
				{label: 'Sync CRM', active: true, complete: true}
			]
		};
	}

	componentDidMount() {
		this.getWizardData();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get tileWidth() {
		return 250;
	}

	get tileHeight() {
		return 350;
	}

	getWizardData() {
		this.setState({'loading': true});
		let crmType = this.crmType;
		if (this.crmType === MSFT) {
			crmType = CRM_DYNAMICS;
		}
		if (this.crmType === SFDC_SANDBOX) {
			crmType = SFDC;
		}
		DataManagerEndpoints.wizardStatus(crmType)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload && result.payload.id) {
				this.setState({personQueue: result.payload, error: null, loading: false});
				this.updateProgress(result.payload.id);
			}
			else {
				this.setState({authenticated: true, error: 'Failed to get sync status.', loading: false});
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from wizardStatus', error);
		});
	}

	updateProgress(personQueueId) {
		PersonQueueHelper.pollPersonQueueStatus(personQueueId,
			(payload) => {
				if (this.unmounted) return;
				this.setState({personQueue: payload});
				if (payload.errorMessage) {
					console.error('Error from pollPersonQueueStatus', personQueueId, payload.errorMessage);
					this.setState({error: payload.errorMessage});
				}
			},
			(payload) => {
				if (this.unmounted) return;
				DataManagerHelper.crmTypeSynced = this.crmType;
				this.setState({personQueue: payload});
				if (payload.errorMessage) {
					console.error('Error from pollPersonQueueStatus', personQueueId, payload.errorMessage);
					this.setState({error: payload.errorMessage});
				}
			});
	}

	renderStep() {
		let {personQueue} = this.state;
		if (!personQueue) {
			return (
				<div style={{padding: 40}}>
					An error occurred during CRM integration please contact support.
					<LinkButton label={'Contact support'} onClick={() => window.open('https://partnertap.com/support')}/>
				</div>
			);
		}
		let onlyRenderProgressBar = false;
		let complete = personQueue.status === 'PROCESSED';
		let progressMessage = PersonQueueHelper.progressMessage(onlyRenderProgressBar, personQueue);
		let progressPercentage = complete ? 100 : PersonQueueHelper.progressPercentage(personQueue);
		let progressBar =
			<ProgressBar title={personQueue.batchName}
						 truncateTitle={true}
						 progressMessage={progressMessage}
						 onlyRenderProgressBar={onlyRenderProgressBar}
						 progressPercentage={progressPercentage}
						 totalProcessedRows={personQueue.totalRowsProcessed}/>;
		return (
			<div style={TableHelper.tileStyle(this.tileWidth, this.tileHeight, partnerTapTernary)}>
				{DataManagerHelper.getCrmIcon(this.crmType)}
				<div style={{fontSize: 16}}>
					{complete ? 'CRM sync complete' : 'Syncing your CRM'}
				</div>
				{complete ?
					<Fragment>
						<div>{personQueue.numAccounts} assets synced</div>
						<div>Press DONE below to view CRM Sync Summary</div>
					</Fragment>
					:
					progressBar}
			</div>
		);
	}

	render() {
		let upsell = DataManagerHelper.dataManagerCrmWizardAccess;
		if (upsell) return upsell;

		let errorMessage = this.state.error;
		return (
			<ScrollingContainer divId={'data_manager_wizard_page'}>
				<Stepper steps={this.state.steps}/>
				{errorMessage &&
				 <Dialog title={'Sorry, an error occurred'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								 <div style={{color: partnerTapAlert, padding: 20, wordWrap: 'break-word', maxWidth: 600}}>
									 {errorMessage}
								 </div>
								 <SupportButton/>
							 </div>}
						 yesAction={() => this.setState({error: null})}/>}
				<div style={{
					margin: 10,
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					overflow: 'scroll',
					border: 'solid 1px' + partnerTapStroke,
					borderRadius: 10,
					backgroundColor: partnerTapAppBackground
				}}>
					{this.state.loading ? <Loading>Loading...</Loading> : this.renderStep()}
				</div>
				<div style={{flex: 1}}/>
				<div style={{
					backgroundColor: partnerTapWhite,
					padding: 20,
					display: 'flex',
					gap: 10,
					justifyContent: 'center',
					borderTop: '1px solid ' + partnerTapStroke
				}}>
					<PrimaryButton label={'DONE'}
								   disabled={!DataManagerHelper.crmTypeSynced}
								   onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE)}/>
				</div>
			</ScrollingContainer>
		);
	}
}

DataManagerWizardPageStep3.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(DataManagerWizardPageStep3));
