import * as ApiHelper from '../helpers/ApiHelper';

export function fetchOpportunities(page, pageSize, search, sort) {
	let endpoint = '/opportunities/network/v2';
	if (search) {
		endpoint = '/opportunities/networksearch';
	}
	endpoint = ApiHelper.appendPageableParams(endpoint, page, pageSize, search, sort);
	return ApiHelper.sendGet(endpoint);
}

export function fetchOpportunitiesForAlertMenu() {
	return ApiHelper.sendGet('/opportunities/network/v2?page=0&sort=changedDate,asc');
}

export function fetchSharedOpportunities(personSaleAccountId) {
	return ApiHelper.sendGet('/sharedopportunity/' + personSaleAccountId);
}

export function updateSharedOpportunity(personSharedOpportunityId, personSaleAccountId, personId, share) {
	return ApiHelper.sendPost('/sharedopportunity?' +
							  (personSharedOpportunityId ? 'personSharedOpportunityId=' + personSharedOpportunityId + '&' : '') +
							  'personSaleAccountId=' + personSaleAccountId +
							  '&partnerPersonId=' + personId +
							  '&share=' + share);
}
