import {Slider} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import NumberHelper from '../../helpers/NumberHelper';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../ui/buttons/SecondaryButton';
import TextInputBox from '../TextInputBox';

class NumberRangeSelector extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = this.initMinMaxState;

		this.onSearchChangeMin = this.onSearchChangeMin.bind(this);
		this.onSearchChangeMax = this.onSearchChangeMax.bind(this);
		this.onSearchChangeRange = this.onSearchChangeRange.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let {valueMin, valueMax} = this.props;
		if (prevProps.valueMin !== valueMin || prevProps.valueMax !== valueMax) {
			setTimeout(() => {
				if (this.unmounted) return;
				this.setState(this.initMinMaxState);
			});
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get initMinMaxState() {
		let {searchMin, searchMax, valueMin, valueMax} = this.props;
		valueMin = this.floatWithDecimal(searchMin || valueMin, true);
		valueMax = this.floatWithDecimal(searchMax || valueMax, false);
		return {
			searchMin: valueMin,
			formattedMin: this.formatValue(valueMin, true),
			applyFormatToMin: true,
			searchMax: valueMax,
			formattedMax: this.formatValue(valueMax, false),
			applyFormatToMax: true
		};
	}

	onSearchChangeMin(value, isSliderEvent = false) {
		value = this.floatWithDecimal(value, true);
		this.setState({searchMin: value, formattedMin: this.formatValue(value, true), isSliderEvent: isSliderEvent});
		return value;
	}

	onSearchChangeMax(value, isSliderEvent) {
		value = this.floatWithDecimal(value, false);
		this.setState({searchMax: value, formattedMax: this.formatValue(value, false), isSliderEvent: isSliderEvent});
		return value;
	}

	onSearchChangeRange(event, value) {
		this.onSearchChangeMin(value[0], true);
		this.onSearchChangeMax(value[1], true);
	}

	floatWithDecimal(value, isMin) {
		let valueString = String(value);
		let appendDecimal = valueString.indexOf('.') !== -1 && (valueString.indexOf('.') === valueString.length - 1 || valueString.endsWith('..'));
		let appendDecimalZero = valueString.indexOf('.0') !== -1 && valueString.indexOf('.0') === valueString.length - 2;
		let appendDecimalZeroZero = valueString.indexOf('.00') !== -1 && valueString.indexOf('.00') <= valueString.length - 3;
		let appendZero = valueString.indexOf('.') !== -1 &&
						 ((valueString.indexOf('.') === valueString.length - 3 && valueString.endsWith('0')) ||
						  (valueString.indexOf('.') === valueString.length - 4 && valueString.indexOf('0') === valueString.length - 2));
		value = NumberHelper.roundNumber(valueString, 2, isMin, !isMin);
		if (appendDecimal) return value + '.';
		if (appendDecimalZero) return value + '.0';
		if (appendDecimalZeroZero) return value + '.00';
		if (appendZero) return value + '0';
		return value;
	}

	formatValue(value, isMin) {
		let formatted = parseFloat(value);
		if (!formatted || isNaN(formatted)) formatted = 0;
		if (this.props.isCurrency) {
			formatted = NumberHelper.formatCurrency(formatted, isMin, !isMin);
		}
		else {
			formatted = NumberHelper.formatNumber(formatted, isMin, !isMin);
		}
		return formatted;
	}

	constrainValue(value, isMin) {
		let {searchMin, searchMax} = this.state;
		let {valueMin, valueMax} = this.props;
		let constrained = parseFloat(value);
		if (!constrained || isNaN(constrained)) constrained = 0;
		if (constrained < valueMin) constrained = valueMin;
		if (constrained > valueMax) constrained = valueMax;
		if (isMin) {
			if (constrained > searchMax) constrained = searchMax;
		}
		else {
			if (constrained < searchMin) constrained = searchMin;
		}
		return constrained;
	}

	render() {
		let {searchMin, searchMax, formattedMin, applyFormatToMin, formattedMax, applyFormatToMax, isSliderEvent} = this.state;
		let {valueMin, valueMax, isCurrency, onClose} = this.props;
		const range = [parseFloat(searchMin), parseFloat(searchMax)];
		return (
			<div>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div style={{padding: 10}}>
						<Slider min={valueMin} max={valueMax} value={range} onChange={this.onSearchChangeRange}/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', paddingBottom: 5}}>
						<div style={{width: 40}}>
							Min
						</div>
						<TextInputBox key={formattedMin}
									  hintText={isCurrency ? 'Min $' : 'Min'}
									  value={applyFormatToMin ? formattedMin : String(searchMin)}
									  inputMode={'numeric'}
									  onFocus={() => this.setState({applyFormatToMin: false})}
									  onBlur={() => {
										  this.onSearchChangeMin(this.constrainValue(searchMin, true));
										  this.setState({applyFormatToMin: true});
									  }}
									  doNotAutoFocus={isSliderEvent}
									  onChange={this.onSearchChangeMin}
									  onEnter={(value) => {
										  let minValue = this.onSearchChangeMin(value);
										  onClose(minValue, searchMax);
									  }}
									  isSimple={true}/>
					</div>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div style={{width: 40}}>
							Max
						</div>
						<TextInputBox key={formattedMax}
									  hintText={isCurrency ? 'Max $' : 'Max'}
									  value={applyFormatToMax ? formattedMax : String(searchMax)}
									  inputMode={'numeric'}
									  onFocus={() => this.setState({applyFormatToMax: false})}
									  onBlur={() => {
										  this.onSearchChangeMax(this.constrainValue(searchMax, false));
										  this.setState({applyFormatToMax: true});
									  }}
									  doNotAutoFocus={isSliderEvent}
									  onChange={this.onSearchChangeMax}
									  onEnter={(value) => {
										  let maxValue = this.onSearchChangeMax(value);
										  onClose(searchMin, maxValue);
									  }}
									  isSimple={true}/>
					</div>
				</div>
				<div style={{display: 'flex', paddingTop: 5, justifyContent: 'center'}}>
					<div style={{padding: 5}}>
						<SecondaryButton label={'CANCEL'} onClick={() => onClose()}/>
					</div>
					<div style={{padding: 5}}>
						<PrimaryButton label={'APPLY'} onClick={() => onClose(searchMin, searchMax)}/>
					</div>
				</div>
			</div>
		);
	}
}

NumberRangeSelector.propTypes = {
	isCurrency: PropTypes.bool,
	valueMin: PropTypes.number.isRequired,
	valueMax: PropTypes.number.isRequired,
	searchMin: PropTypes.number.isRequired,
	searchMax: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired
};

export default NumberRangeSelector;
