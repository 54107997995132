export const partnerTapPrimary = '#1a3662'; // dark blue
export const partnerTapPrimaryMedium = '#264f90'; // medium dark blue
export const partnerTapPrimaryLight = '#3268bd'; // light dark blue
export const partnerTapSecondary = '#0871cf'; // blue
export const partnerTapSecondaryMedium = '#1e8ede'; // light blue
export const partnerTapSecondaryLight = '#ebf1fb'; // lighter blue
export const partnerTapTernary = '#227f28'; // green
export const partnerTapTernaryLight = '#cceecc'; // light green
export const partnerTapAlert = '#ff0000'; // red
export const partnerTapWarn = '#ffdd00'; // yellow
export const partnerTapLightestGrey = '#f9f9f9'; // to match form fields over partnerTapAppBackground
export const partnerTapAppBackground = '#f5f5f5'; // white smoke
export const partnerTapQuaternary = '#eaeaea'; // light grey
export const partnerTapStroke = '#cccbcb'; // grey
export const partnerTapInactive = '#878787'; // dark grey
export const partnerTapDefaultText = '#313b4e'; // darker grey
export const partnerTapHighlight = '#8c64b5'; // purple
export const partnerTapWhite = '#FEFEFE'; // purple
export const partnerTapBlack = '#000000'; // purple
export const partnerTapTempLogo = 'rgba(57,120,219,0.6)';
export const partnerTapDropShadow = '0 0 0 0, 0 0 3px 0 rgba(0,0,0,0.2)';
export const salesforceColor = '#00a1e0';
export const hubspotColor = '#f8761f';
export const microsoftColor = '#2f2f2f';
export const sharpSpringColor = '#142c16';
export const freshsalesColor = '#d5782c';
