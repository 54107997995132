import _ from 'lodash';
import * as ApiHelper from '../helpers/ApiHelper';
import * as CSVHelper from '../helpers/CsvHelper';
import FeatureHelper, {FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS} from '../helpers/FeatureHelper';
import * as PageableHelper from '../helpers/PageableHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';
import * as ReduxEnum from '../redux/ReduxEnum';

export const keyTopCounts = '/topcounts';
export const keyGraphRepUsage = '/repusage';
export const keyGraphPartnerCounts = '/partnercounts';
export const keyTopRepUsage = '/repcounts';
export const keyExtraIntelCounts = '/chatcounts';
export const keyGraphIntelCounts = '/chatleaders';
export const keyMatchedAccounts = '/accounts/matched';
export const keyUnmatchedAccounts = '/accounts/unmatched';
export const keyRepConnections = '/partnerships/connections';
export const keyNewPartners = '/repactivity/partners';
export const keyIntelExchange = '/chats/exchanged';
export const keyTapInbound = '/opportunities/inbound';
export const keyTapOutbound = '/opportunities/outbound';
export const keyOutboundOpportunities = '/opportunities/outboundsummary';
export const keyInboundOpportunities = '/opportunities/inboundsummary';
export const keyRepIntel = '/repactivity/chatdetails';

// manager analytics all divisions
export const ALL_DIVISIONS = 'ALL';

export function fetchRecommendedPartners() {
	return ApiHelper.sendGet('/manager/admin/recommendedpartners');
}

export function fetchFeaturedPartners() {
	return ApiHelper.sendGet('/manager/admin/featuredpartners');
}

export function setFeaturedPartners(partnerOrgName, isFeatured) {
	return ApiHelper.sendPost('/manager/admin/featuredpartners?partnerOrg=' + partnerOrgName + '&isFeatured=' + isFeatured);
}

export function fetchManagerDetails() {
	return ApiHelper.sendGet('/analytics/getmanager');
}

export function fetchAllPartnerOrganizations() {
	return ApiHelper.sendGetReport('/analytics/allpartnerorganizationnames');
}

export function fetchOwnerDivisions() {
	return ApiHelper.sendGetReport('/divisions/list');
}

export function fetchForDownload(fetchData, timeSpan, mapKeyAndPrettyName, mapKeyAndFunctionForData, partnerOrg = null, repId = null, division = null, isAdmin = false) {
	return fetchItemData(fetchData, PageableHelper.PAGE_SIZE_DOWNLOAD, 0, timeSpan, partnerOrg, division, repId, isAdmin)
	.then((result) => {
		if (!_.isEmpty(result.payload)) {
			if (!_.isEmpty(result.metaData) && result.metaData.totalElements < PageableHelper.PAGE_SIZE_DOWNLOAD) {
				let setExcluded = new Set(['personId']);
				let fileName = fetchData.keyData + (partnerOrg ? partnerOrg.toUpperCase() : '') + (timeSpan !== -1 ? timeSpan + 'days' : 'AllTime') + '.csv';
				CSVHelper.downloadCSV(result.payload, fileName, setExcluded, mapKeyAndPrettyName, mapKeyAndFunctionForData);
			}
			else if (!_.isEmpty(result.metaData)) {
				return fetchAllDataAndWriteToCSV(fetchData, PageableHelper.PAGE_SIZE_DOWNLOAD, 0, timeSpan, result.metaData.totalElements, mapKeyAndPrettyName, mapKeyAndFunctionForData, partnerOrg, division, repId, isAdmin);
			}
			else {
				console.error('Error from fetchItemData: metadata is empty');
			}
		}
	});
}

function fetchAllDataAndWriteToCSV(fetchData, pageSize, pageNumber, timeSpan, countTotalRows, objKeyAndPrettyName, mapKeyAndFunctionForData, partnerOrg = null, division = null, repId = null, isAdmin = false) {
	if (countTotalRows <= 0) return;
	return fetchItemData(fetchData, pageSize, pageNumber, timeSpan, partnerOrg, division, repId, isAdmin)
	.then((result) => {
		if (result.payload) {
			let setExcluded = new Set(['personId']);
			let fileName = fetchData.keyData + (partnerOrg ? partnerOrg.toUpperCase() : '') + (timeSpan !== -1 ? timeSpan + 'days' : 'AllTime') + 'days' + 'number' + pageNumber + '.csv';
			CSVHelper.downloadCSV(result.payload, fileName, setExcluded, objKeyAndPrettyName, mapKeyAndFunctionForData);
			return fetchAllDataAndWriteToCSV(fetchData, pageSize, ++pageNumber, timeSpan, countTotalRows - pageSize, objKeyAndPrettyName, mapKeyAndFunctionForData, partnerOrg, division, repId, isAdmin);
		}
	});
}

export function fetchItemData(fetchData, pageSize, pageNumber, timeSpan, partnerOrg = null, division = null, repId = null, isAdmin = false) {
	let key = fetchData.keyData;
	let keySort = fetchData.sort;
	let searchString = fetchData.search;
	return fetchDataByPrefix(key, pageSize, pageNumber, timeSpan, partnerOrg, keySort, searchString, division, repId, isAdmin);
}

export function fetchReportByPrefix(prefix, division) {
	let isAdmin = FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS);
	return ApiHelper.sendGetReport((!division ? '/analytics' : (isAdmin ? '/divisions' : '/reports')) + '/canned/' + prefix +
								   (division ? (isAdmin ? '?divisions=' + division : '?division=' + division) : ''));
}

export function fetchDashboardData(key, timeSpan, partnerOrg = null, division = null, sort = null, isAdmin = false) {
	return fetchDashboardOrgAnalyticsByPrefix(key, timeSpan, partnerOrg, sort, division, isAdmin);
}

function fetchDataByPrefix(prefix, pageSize, pageNumber, timeSpan, partnerOrg = null, sort = null, searchString = '', division = null, repId = null, isAdmin = false, isSent = false) {
	isAdmin = FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS);
	if (!division || division === ALL_DIVISIONS) division = '';
	let url = isAdmin ? prefix : '/reports' + prefix;
	url += '?size=' + pageSize +
		   '&page=' + pageNumber +
		   (partnerOrg ? '&partnerOrg=' + encodeURIComponent(partnerOrg) : '') +
		   (timeSpan !== -1 ? '&timeSpan=' + timeSpan : '') +
		   (searchString !== '' ? '&search=' + searchString : '') +
		   (isAdmin ? '&divisions=' + division : '&division=' + division) +
		   (repId ? '&repId=' + repId : '') +
		   (sort ? '&sort=' + sort : '') +
		   (isSent ? '&isSent=' + isSent : '');
	return ApiHelper.sendGetReport(url);
}

function fetchDashboardOrgAnalyticsByPrefix(prefix, timeSpan = null, partnerOrg = null, sort = null, division = null, isAdmin = false) {
	isAdmin = FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS);
	if (!division || division === ALL_DIVISIONS) division = '';
	let url = isAdmin ? '/dashboard' + prefix : '/reports' + prefix;
	url += '?size=' + PageableHelper.PAGE_SIZE_DOWNLOAD +
		   ((timeSpan && timeSpan !== -1) ? '&timeSpan=' + timeSpan : '') +
		   (partnerOrg ? '&partnerOrg=' + encodeURIComponent(partnerOrg.toUpperCase()) : '') +
		   (isAdmin ? '&divisions=' + division : '&division=' + division) +
		   (sort ? '&sort=' + sort : '');
	return ApiHelper.sendGetReport(url);
}

export function fetchOppSummaryCounts(
	partnerOrg = null,
	timeSpan = null,
	division = null,
	searchKeys = '',
	isSent = false,
	isAdmin = false) {
	return fetchDataByPrefix('/opportunities/oppsummarycount', 0, 0, timeSpan, partnerOrg, null, searchKeys, division, null, isAdmin, isSent);
}

export function setDivisionGroup(group) {
	PersistenceHelper.setValue(ReduxEnum.MANAGER_DIVISION_GROUP, group);
}

export function getDivisionGroup() {
	return PersistenceHelper.getValue(ReduxEnum.MANAGER_DIVISION_GROUP);
}
