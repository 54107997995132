// TODO:COLIN remove this asap
export default class HackHelper {

	set horribleHack(thisSucks) {
		this.sucks = thisSucks;
	}

	get horribleHack() {
		return this.sucks;
	}
}