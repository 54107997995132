import {DragIndicator} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ColumnHelper from '../../helpers/ColumnHelper';
import TableHelper from '../../helpers/TableHelper';

class PagingTableColumnSizer extends Component {

	constructor(props, context) {
		super(props, context);
		this.lastClientX = 0;
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
	}

	componentWillUnmount() {
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('mouseup', this.onMouseUp);
	}

	onMouseDown(event) {
		event.stopPropagation();
		event.preventDefault();
		this.lastClientX = event.clientX;
		document.getElementById(this.props.column.key + '_resize_handle').style.cursor = 'col-resize';
		document.getElementById(TableHelper.columnHeaderKey(this.props.tableKey, this.props.column)).draggable = false;
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('mouseup', this.onMouseUp);
		TableHelper.blockSortOnResize = true;
	}

	onMouseMove(event) {
		event.stopPropagation();
		event.preventDefault();
		let xDelta = event.clientX - this.lastClientX;
		if (xDelta && this.props.column.width + xDelta >= 100) {
			this.props.column.width += xDelta;
			this.props.column.enforceMinWidth = true;
			this.lastClientX = event.clientX;
			this.props.onColumnWidthChanged();
			document.body.style.cursor = 'col-resize';
			TableHelper.resizeColumnKey = 'column_resize_' + this.props.column.width;
		}
	}

	onMouseUp(event) {
		event.stopPropagation();
		event.preventDefault();
		document.body.style.cursor = 'default';
		document.getElementById(this.props.column.key + '_resize_handle').style.cursor = 'col-resize';
		document.getElementById(TableHelper.columnHeaderKey(this.props.tableKey, this.props.column)).draggable = true;
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('mouseup', this.onMouseUp);
		this.props.onColumnWidthChanged(true);
		setTimeout(() => TableHelper.blockSortOnResize = false);
	}

	render() {
		return (
			<div id={this.props.column.key + '_resize_handle'}
				 style={{cursor: 'col-resize'}}
				 onMouseDown={this.onMouseDown}
				 onClick={(e) => {
					 e.stopPropagation();
					 e.preventDefault();
				 }}>
				<DragIndicator style={ColumnHelper.columnHeaderControlStyle}/>
			</div>
		);
	}
}

PagingTableColumnSizer.propTypes = {
	tableKey: PropTypes.string.isRequired,
	column: PropTypes.object.isRequired,
	onColumnWidthChanged: PropTypes.func.isRequired
};

export default PagingTableColumnSizer;
