import {Icon} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import EnvHelper from '../helpers/EnvHelper';
import {partnerTapPrimary, partnerTapSecondary} from '../styles/partnertap_theme';

class PartnerTapIcon extends Component {

	static get logo() {
		return <img src={EnvHelper.serviceUrl + '/images/partnertap-logo.png'} alt={'PartnerTap'} style={{width: 50, height: 50}}/>;
	}

	static get logoWhite() {
		return <img src={EnvHelper.serviceUrl + '/images/partnertap-logo-white.png'} alt={'PartnerTap'} style={{width: 32, height: 32}}/>;
	}

	static get logoSmall() {
		return <img src={EnvHelper.serviceUrl + '/images/partnertap-logo-white.png'} alt={'PartnerTap'} style={{width: 20, height: 20}}/>;
	}

	static get logoTall() {
		return <img src={EnvHelper.serviceUrl + '/images/partnertap-logo-tall.png'} alt={'PartnerTap'} style={{width: 200, height: 228}}/>;
	}

	static get logoWide() {
		return <img src={EnvHelper.serviceUrl + '/images/partnertap-logo-wide.png'} alt={'PartnerTap'} style={{width: 300, height: 40}}/>;
	}

	static get logoWideLarge() {
		return <img src={EnvHelper.serviceUrl + '/images/partnertap-logo-wide.png'} alt={'PartnerTap'} style={{width: 600, height: 80}}/>;
	}

	static get headerIconStyle() {
		return {fontSize: 48, width: 28, height: 24, lineHeight: 0.2, paddingTop: 10, paddingRight: 10};
	}

	static get sideNavIconStyle() {
		return {color: partnerTapSecondary, fontSize: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50, height: 50};
	}

	static get sideNavSelectedIconStyle() {
		return {color: partnerTapPrimary, fontSize: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50, height: 50};
	}

	static get buttonIconStyle() {
		return {fontSize: 36, width: 28, height: 46, position: 'relative', top: -12};
	}

	static isGlyph(icon) {
		return typeof icon === 'string' && icon.length === 1;
	}

	render() {
		let style = this.props.style;
		if (this.props.glyph === 'I') {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingLeft: 2});
			if (style.fontSize === 44) {
				Object.assign(style, {fontSize: 48, width: 32});
			}
		}
		if (this.props.glyph === 'J' && this.props.style.fontSize === 44) {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingBottom: 4});
		}
		else if (this.props.glyph === 'x' && this.props.style.fontSize === 48) {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingTop: 4});
		}
		else if (this.props.glyph === '%' || this.props.glyph === '5' || this.props.glyph === '^' || this.props.glyph === '6') {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingLeft: 1, paddingBottom: style.fontSize === 40 ? 10 : 6});
		}
		else if (this.props.glyph === 'G' && style.fontSize === 40) {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingLeft: 2, paddingBottom: 2});
		}
		else if (this.props.glyph === 'A' && style.fontSize === 40) {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingBottom: 8});
		}
		else if ((this.props.glyph === 'C' ||
				  this.props.glyph === 'D' ||
				  this.props.glyph === 'H' ||
				  this.props.glyph === 'J' ||
				  this.props.glyph === 'k' ||
				  this.props.glyph === 'K' ||
				  this.props.glyph === 'L' ||
				  this.props.glyph === 'w' ||
				  this.props.glyph === 'W' ||
				  this.props.glyph === 'X')
				 && style.fontSize === 40) {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingBottom: 4});
		}
		else if ((this.props.glyph === 'e' || this.props.glyph === 'E') && style.fontSize !== 24 && style.fontSize !== 36) {
			style = Object.assign({}, this.props.style);
			Object.assign(style, {paddingBottom: 10});
		}
		return <Icon className={'partnertap-icons'} style={style}>{this.props.glyph}</Icon>;
	}
}

PartnerTapIcon.propTypes = {
	glyph: PropTypes.string.isRequired,
	style: PropTypes.object.isRequired
};

export default PartnerTapIcon;
