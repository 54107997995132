import {TaskOutlined} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import DateHelper from '../../../../helpers/DateHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import {partnerTapAppBackground} from '../../../../styles/partnertap_theme';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import Dialog from '../../../../ui/Dialog';
import Loading from '../../../../ui/Loading';
import PartnerTapIcon from '../../../../ui/PartnerTapIcon';
import DetailHeader from '../DetailHeader';

class SheetHeader extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showDetails: false, downloadClicked: false};
		this.handleDownloadRequest = this.handleDownloadRequest.bind(this);
	}

	handleDownloadRequest() {
		this.setState({downloadClicked: true});
		let {sheetData} = this.props;
		const link = document.createElement('a');
		document.body.appendChild(link);
		link.href = EnvHelper.serviceUrl + '/channelmap/download?downloadToken=' + sheetData.downloadToken;
		link.setAttribute('download', sheetData.batchName);
		link.click();
		document.body.removeChild(link);
	}

	renderDetails() {
		let {sheetData} = this.props;
		let border = 'solid 1px ' + partnerTapAppBackground;
		let milliSeconds = DateHelper.getMillisecondsWithTimeZoneOffset(sheetData.createdDate);
		let uploadDate = DateHelper.getFormattedDateTime(new Date(milliSeconds));
		let cellStyle = {
			padding: 10,
			borderRight: border,
			borderTop: border,
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		};
		return <Dialog title={'\'' + sheetData.batchName + '\' Sheet Details'}
					   message={
						   <div style={{padding: 20}}>
							   <table style={{width: '100%', borderSpacing: 0, borderLeft: border, borderBottom: border}}>
								   <tbody>
								   <tr>
									   <td style={cellStyle}>Upload Date</td>
									   <td style={cellStyle}>{uploadDate}</td>
								   </tr>
								   <tr>
									   <td style={cellStyle}>Rows Imported</td>
									   <td style={cellStyle}>{sheetData.totalRows}</td>
								   </tr>
								   <tr>
									   <td style={cellStyle}>Rows Failed</td>
									   <td style={cellStyle}>{sheetData.errorRows}</td>
								   </tr>
								   </tbody>
							   </table>
							   <div style={{paddingTop: 10}}>
								   <SecondaryButton disabled={this.state.downloadClicked}
													label={this.state.downloadClicked ? 'Downloading CSV' : 'Download Original CSV'}
													onClick={this.handleDownloadRequest}/>
							   </div>
						   </div>
					   }
					   yesAction={() => this.setState({showDetails: false, downloadClicked: false})}/>;
	}

	render() {
		let {sheetData} = this.props;
		let {partnerOrgName} = this.props.match.params;
		if (partnerOrgName === 'null' || partnerOrgName === 'undefined') partnerOrgName = null;
		if (!sheetData) return <Loading>Loading Sheet...</Loading>;
		let sheetInfo =
			<div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
				<div style={{fontSize: 16, paddingRight: 10}}>
					{partnerOrgName} {sheetData.batchName}
				</div>
				<SecondaryButton label={'CSV INFO'}
								 icon={<PartnerTapIcon glyph={'I'} style={PartnerTapIcon.buttonIconStyle}/>}
								 onClick={() => this.setState({showDetails: true})}/>
			</div>;
		return (
			<Fragment>
				<DetailHeader MaterialIcon={TaskOutlined} title={'Partner Sheet Details'} detailRight={sheetInfo}/>
				{this.state.showDetails && this.renderDetails()}
			</Fragment>
		);
	}
}

SheetHeader.propTypes = {
	sheetData: PropTypes.object.isRequired
};

export default withRouter(SheetHeader);
