import _ from 'lodash';
import * as ProfilesEndpoints from '../endpoints/ProfilesEndpoints';
import {
	CODE_VERIFIER_KEY,
	DEEP_LINK_KEY,
	FROM_NOTIFICATION_KEY,
	INITIAL_LOCATION_KEY,
	LAST_ROUTE_KEY,
	LOGIN_SIGN_UP_TYPE_KEY,
	LOGIN_TOKEN_KEY,
	LOGIN_USERNAME_KEY,
	PERSISTENT_KEY,
	REDIRECT_URI_KEY,
	REFERRING_EMAIL_ID_KEY,
	UPDATE_VERSION_KEY
} from '../globals/StorageKeys';
import EnvHelper from './EnvHelper';

export default class PersistenceHelper {

	static init(profile) {
		if (!this.initialized) {
			console.info('init persistence');
			this.initialized = true;
			this.profile = profile;
			this.clientPersistenceJson = null;
			if (profile.clientPersistenceJson) {
				this.clientPersistenceJson = Object.assign({}, profile.clientPersistenceJson);
			}
			else if (profile) {
				PersistenceHelper.migrateLocalStorageToServer();
			}
			else {
				console.error('Error initializing clientPersistenceJson', this.clientPersistenceJson);
			}
		}
	}

	static migrateLocalStorageToServer() {
		if (EnvHelper.isSpoofing) return;
		if (!this.clientPersistenceJson) {
			this.clientPersistenceJson = {};
			for (let i = 0; i < localStorage.length; i++) {
				let key = localStorage.key(i);
				if (PersistenceHelper.isPersistentKey(key)) {
					this.clientPersistenceJson[key] = PersistenceHelper.getValue(key);
				}
			}
			if (!_.isEmpty(this.clientPersistenceJson)) {
				console.info('migrating clientPersistenceJson:', this.clientPersistenceJson);
				this.savePersistentJson();
			}
			else {
				console.info('no clientPersistenceJson to migrate:', this.clientPersistenceJson);
			}
		}
		else {
			console.error('Error migrating clientPersistenceJson', this.clientPersistenceJson);
		}
	}

	static clearClient(doNotRestoreLocalPersistentValues) {
		EnvHelper.isSpoofing = false;
		console.info('clear client persistence');
		this.initialized = false;
		this.clientPersistenceJson = null;

		let localPersistentValues = {};
		if (!doNotRestoreLocalPersistentValues) {
			for (let i = 0; i < localStorage.length; i++) {
				let key = localStorage.key(i);
				if (PersistenceHelper.isLocalPersistentKey(key)) {
					localPersistentValues[key] = PersistenceHelper.getValue(key);
				}
			}
		}

		localStorage.clear();

		if (!doNotRestoreLocalPersistentValues) {
			Object.keys(localPersistentValues).forEach((key) => {
				PersistenceHelper.setValue(key, localPersistentValues[key]);
			});
		}
	}

	// this facility is not currently user facing, but could become a settings feature if needed
	static clearServer(profileId) {
		console.info('clear server persistence: ' + profileId);
		this.clientPersistenceJson = {};
		ProfilesEndpoints.updateClientPersistenceJson(profileId, this.clientPersistenceJson)
		.catch((message) => {
			console.error('Error clearing clientPersistenceJson', message);
		});
	}

	static blockPersistence(key) {
		if (key === LOGIN_TOKEN_KEY) return false;
		return EnvHelper.isSpoofing;
	}

	static setValue(key, value, doNotSaveToServer) {
		if (PersistenceHelper.blockPersistence(key)) return;
		if (PersistenceHelper.isPersistentKey(key)) {
			PersistenceHelper.clientPersistenceJson[key.substring(PERSISTENT_KEY.length)] = value;
			if (!doNotSaveToServer) PersistenceHelper.savePersistentJson();
		}
		else {
			localStorage.setItem(key, value);
		}
		window.dispatchEvent(new Event('PersistenceChanged'));
	}

	static getValue(key) {
		if (this.blockPersistence(key)) return;
		if (PersistenceHelper.isPersistentKey(key)) {
			return this.clientPersistenceJson[key.substring(PERSISTENT_KEY.length)];
		}
		return localStorage.getItem(key);
	}

	static removeValue(key) {
		if (PersistenceHelper.isPersistentKey(key)) {
			delete this.clientPersistenceJson[key.substring(PERSISTENT_KEY.length)];
			PersistenceHelper.savePersistentJson();
		}
		else {
			localStorage.removeItem(key);
		}
		window.dispatchEvent(new Event('PersistenceChanged'));
	}

	static isPersistentKey(key) {
		return this.clientPersistenceJson && key.indexOf(PERSISTENT_KEY) === 0 && !PersistenceHelper.isLocalPersistentKey(key);
	}

	static isLocalPersistentKey(key) {
		return key === 'persistent_admin_demo_users_sort_column' ||
			   key === LOGIN_USERNAME_KEY ||
			   key === DEEP_LINK_KEY ||
			   key === UPDATE_VERSION_KEY ||
			   key === LAST_ROUTE_KEY ||
			   key === CODE_VERIFIER_KEY ||
			   key === REDIRECT_URI_KEY ||
			   key === LOGIN_SIGN_UP_TYPE_KEY;
	}

	static savePersistentJson() {
		if (this.profile && this.clientPersistenceJson && !_.isEmpty(this.clientPersistenceJson)) {
			ProfilesEndpoints.updateClientPersistenceJson(this.profile, this.clientPersistenceJson)
			.catch((message) => {
				console.error('Error updating clientPersistenceJson', message);
			});
		}
		else {
			console.error('Error trying to savePersistentJson', this.profile, this.clientPersistenceJson);
		}
	}

	static storeInitialLocation(path) {
		if (this.initialLocation) return; // never overwrite a stored initial location, it must be cleared explicitly
		localStorage.setItem(INITIAL_LOCATION_KEY, path);
	}

	static get initialLocation() {
		return localStorage.getItem(INITIAL_LOCATION_KEY);
	}

	static clearInitialLocation() {
		localStorage.removeItem(INITIAL_LOCATION_KEY);
	}

	static storeDeepLink() {
		if (this.deepLink) return; // never overwrite a stored deep link, it must be cleared explicitly
		let deeplinkIndex = window.location.href.indexOf('#');
		if (deeplinkIndex !== -1) {
			let deepLink = window.location.href.substring(deeplinkIndex + 1);
			localStorage.setItem(DEEP_LINK_KEY, deepLink);
		}
	}

	static get deepLink() {
		return localStorage.getItem(DEEP_LINK_KEY);
	}

	static clearDeepLink() {
		localStorage.removeItem(DEEP_LINK_KEY);
	}

	static storeUpdateVersion(version) {
		localStorage.setItem(UPDATE_VERSION_KEY, version);
	}

	static get updateVersion() {
		return localStorage.getItem(UPDATE_VERSION_KEY);
	}

	static storeReferringEmailId(notificationDeepLink) {
		let referringEmailId = EnvHelper.getParam('referringEmailId', notificationDeepLink || window.location.href);
		if (referringEmailId) localStorage.setItem(REFERRING_EMAIL_ID_KEY, referringEmailId);
		let fromNotification = EnvHelper.getParam('fromNotification', notificationDeepLink || window.location.href);
		if (fromNotification) localStorage.setItem(FROM_NOTIFICATION_KEY, fromNotification);
	}

	static getReferringEmailId() {
		let referringEmailId = localStorage.getItem(REFERRING_EMAIL_ID_KEY);
		localStorage.removeItem(REFERRING_EMAIL_ID_KEY);
		if (referringEmailId) {
			let fromNotification = localStorage.getItem(FROM_NOTIFICATION_KEY);
			localStorage.removeItem(FROM_NOTIFICATION_KEY);
			if (fromNotification) referringEmailId += '&fromNotification=true';
			return referringEmailId;
		}
		return '';
	}
}
