import * as ApiHelper from '../helpers/ApiHelper';

const endpointBase = '/apikeygenerator';

export function getAllApiKeys(search, page, pageSize, sort) {
	let searchParam = "";
	if (search) {
		searchParam = "&search=" + search;
	}
	return ApiHelper.sendGetApiKeyGenerator(endpointBase + '?page=' + page + '&size=' + pageSize + '&sort=' + sort + searchParam);
}

export function createApiKey(payload) {
	return ApiHelper.sendPostApiKeyGenerator(endpointBase, payload);
}

export function updateApiKey(apiKeyId, payload) {
	return ApiHelper.sendPutAoiKeyGenerator(endpointBase + '/' + apiKeyId, payload);
}

export function deleteApiKey(apiKeyId) {
	return ApiHelper.sendDeleteAoiKeyGenerator(endpointBase + '/' + apiKeyId);
}
