import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Pie, PieChart, ResponsiveContainer} from 'recharts';
import DialogHelper from '../../helpers/DialogHelper';
import EnvHelper from '../../helpers/EnvHelper';
import NumberHelper from '../../helpers/NumberHelper';
import {partnerTapDropShadow, partnerTapInactive, partnerTapWhite} from '../../styles/partnertap_theme';
import SecondaryButton from '../buttons/SecondaryButton';
import SupportButton from '../buttons/SupportButton';
import GraphLegend from '../GraphLegend';

class CircleGraph extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {displayCount: 5, detailDialog: null};
	}

	get noContentMessage() {
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<div style={{paddingBottom: 10}}>
					We don't have enough data to provide this graph at the moment.
				</div>
				<div style={{paddingBottom: 10}}>
					Dashboard data is refreshed when your accounts are updated.
				</div>
				<div style={{paddingBottom: 10}}>
					Please reach out to us if this seems like an error.
				</div>
				<div style={{margin: 'auto'}}>
					<SupportButton/>
				</div>
			</div>
		);
	}

	render() {
		let {title, description, sections, extraStats, destination, onClick} = this.props;
		let {displayCount} = this.state;
		let displaySections = sections.slice(0, displayCount);
		return (
			<div key={displayCount}
				 style={{
					 flex: 1,
					 boxShadow: partnerTapDropShadow,
					 backgroundColor: partnerTapWhite,
					 padding: EnvHelper.isDesktop ? 20 : 10,
					 paddingTop: 10,
					 paddingBottom: 10,
					 marginBottom: 10,
					 marginLeft: 5,
					 marginRight: 5,
					 maxWidth: 500,
					 minWidth: 300,
					 cursor: destination ? 'pointer' : 'default'
				 }}
				 onClick={() => {
					 if (destination) {
						 DialogHelper.openDetailDialog(title, destination, null, this.setState.bind(this));
					 }
				 }}>
				{this.state.detailDialog}
				<div style={{paddingBottom: 20}}>
					<div style={{fontSize: 20}}>{title}</div>
					{Boolean(description) &&
					 <div style={{color: partnerTapInactive}}>{description}</div>}
				</div>
				{sections.length === 0 ?
					this.noContentMessage
					:
					<div style={{display: 'flex', backgroundColor: partnerTapWhite, justifyContent: 'space-between'}}>
						<GraphLegend sections={displaySections} sectionClick={onClick} parentHasClick={Boolean(destination)}/>
						<div style={{fontSize: EnvHelper.isDesktop ? 12 : 10}}>
							<ResponsiveContainer minWidth={200} minHeight={200}>
								<PieChart margin={{top: 20, right: 20, bottom: 20, left: 20}}>
									<Pie dataKey={'value'}
										 data={displaySections}
										 fill={partnerTapInactive}
										 outerRadius={'60%'}
										 isAnimationActive={true}
										 labelLine={true}
										 label={(data) => NumberHelper.formatNumber(data.payload.value)}
										 onClick={onClick}/>
								</PieChart>
							</ResponsiveContainer>
						</div>
					</div>}
				{sections.length > 5 &&
				 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', paddingTop: 10}}>
					 {sections.length > displayCount &&
					  <SecondaryButton label={'SHOW MORE'} onClick={() => this.setState({displayCount: displayCount + 5})}/>}
					 {displayCount > 5 &&
					  <SecondaryButton label={'SHOW LESS'} onClick={() => this.setState({displayCount: displayCount - 5})}/>}
				 </div>}
				{extraStats &&
				 <div style={{paddingTop: 10, display: 'flex', justifyContent: 'space-around', flexFlow: 'row'}}>
					 {extraStats.map((extraStat) =>
						 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', whiteSpace: 'nowrap'}} key={extraStat.key}>
							 <h3>{extraStat.value}</h3>
							 <h4>{extraStat.name}</h4>
						 </div>)}
				 </div>}
			</div>);
	}
}

CircleGraph.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	sections: PropTypes.array.isRequired,
	extraStats: PropTypes.array,
	destination: PropTypes.string,
	onClick: PropTypes.func
};

export default CircleGraph;
