import _ from 'lodash';
import React from 'react';
import ToolTipOverlay from '../ui/ToolTipOverlay';

const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexFormatKey = /[\s:,-]+/g;
const regexNonAlpfanumeric = /[^a-zA-Z\-0-9\s]+/g;
const DOMAIN_NAME_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

export default class StringHelper {

	static truncate(string, maxChars) {
		if (_.isEmpty(string)) return '';

		let ellipsis = '...';
		let truncatedString = string;

		if (string.length > maxChars - ellipsis.length) {
			truncatedString = string.slice(0, maxChars - ellipsis.length);
			truncatedString = truncatedString + ellipsis;
		}

		return truncatedString;
	}

	static truncateWithToolTip(value, maxChars) {
		return (typeof value !== 'string' || value.length <= maxChars) ? value :
			<ToolTipOverlay title={value}>
				{StringHelper.truncate(value, maxChars)}
			</ToolTipOverlay>;
	}

	static alphanumeric(value) {
		return value.replace(/[\W_]+/g, ' ').trim();
	}

	static organizationName(value) {
		return value.replace(/[^a-zA-Z0-9\s\-@.#&!]/ig, '').trim();
	}

	static trimWhiteSpace(value) {
		return value.trim();
	}

	static removeWhiteSpace(value) {
		return value.replace(/\s/g, '').trim();
	}

	static validateEmail(emailAddress) {
		if (regexEmail.test(emailAddress)) return emailAddress;
		return '';
	}

	static validateDomainList(domainList, isAlreadySplit) {
		const domains = isAlreadySplit ? domainList : domainList.split(',');
		return domains.every((domain) => DOMAIN_NAME_PATTERN.test(domain));
	}

	static validateEmails(emailList) {
		if (!emailList) return false;
		return emailList.every((email) => regexEmail.test(email));
	}

	static formatPhoneNumber(phoneNumber) {
		if (!phoneNumber) return phoneNumber;

		// first normalize the number into digits only \D is non digit
		let re = /(\D)+/gmi;
		phoneNumber = phoneNumber.replace(re, '');

		if (phoneNumber.length >= 11) {
			// if we have a number like 1-206-555-1234
			return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
		}
		else if (phoneNumber.length === 10) {
			// if we have a number like 253-555-1234
			return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		}
		else if (phoneNumber.length >= 7) {
			// if we have a number like 555-1234
			return phoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2');
		}
		else {
			return phoneNumber;
		}
	}

	static formatKey(value) {
		if (!value) return '';
		return String(value).trim().toLowerCase().replaceAll(regexNonAlpfanumeric, '').replaceAll(regexFormatKey, '_');
	}

	static getAlphaOrdinal(oneBasedIndex) {
		let mod = oneBasedIndex % 26;
		let pow = oneBasedIndex / 26 | 0;
		let out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
		return pow ? this.getAlphaOrdinal(pow) + out : out;
	}
}
