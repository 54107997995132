import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {CATEGORY_PARTNER_ACCOUNTS} from '../../../../globals/Enums';
import EcosystemShareHelper, {SHARE_SUMMARY} from '../../../../helpers/EcosystemShareHelper';
import ReportHelper, {ASSET_TYPE_ACCOUNTS} from '../../../../helpers/ReportHelper';
import PartnerConnectionPagingBase from './PartnerConnectionPagingBase';

class PartnerConnectionAccountsPage extends PartnerConnectionPagingBase {

	constructor(props, context) {
		super(props, context, 'Accounts', ReportHelper.partnerAccountReportTypes, CATEGORY_PARTNER_ACCOUNTS);
	}

	get storageKeyBase() {
		return this.getStorageKeyBase('accounts');
	}

	get isPartnerSharing() {
		let {accountShareSettings} = this.props;
		let {currentReportType} = this.state;
		return accountShareSettings.partnerAssetShareOption !== SHARE_SUMMARY || currentReportType.isMyAccounts;
	}

	get noDataMessage() {
		let {rowData} = this.state;
		if (rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			let {partner, accountShareSettings} = this.props;
			let {currentReportType} = this.state;
			if (this.upsellFeature) {
				return this.upsellFeature;
			}
			if (currentReportType.isAccountsGreenfield) {
				if (EcosystemShareHelper.includesGreenfield(accountShareSettings.partnerAssetShareOption)) {
					return (
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<div style={{textAlign: 'center', padding: 20}}>
								{partner.firstName} is sharing greenfield accounts with you, but none were found.
							</div>
						</div>
					);
				}
				return this.renderNotSharingMessage('greenfield accounts');
			}
			if (!this.isPartnerSharing) {
				return this.renderNotSharingMessage('accounts');
			}
			return EcosystemShareHelper.renderNoOverlapMessaging(
				ASSET_TYPE_ACCOUNTS,
				accountShareSettings.ownerAssetShareOption,
				accountShareSettings.partnerAssetShareOption,
				this.props.partner,
				currentReportType.filterType);
		}
		return super.noDataMessage;
	}
}

PartnerConnectionAccountsPage.propTypes = {
	accountShareSettings: PropTypes.object.isRequired,
	partner: PropTypes.object.isRequired
};

export default withRouter(connect()(PartnerConnectionAccountsPage));
