import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PartnersEndpoints from '../../endpoints/PartnersEndpoints';
import {INVITE_STATUS_ACCEPTED} from '../../globals/Enums';
import Loading from '../Loading';
import PopoverSearchList from '../PopoverSearchList';

class PartnerSelector extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, partners: [], page: 0, lastPage: false};
		this.getPartners = this.getPartners.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.loadMore = this.loadMore.bind(this);
	}

	componentDidMount() {
		this.getPartners();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getPartners(page, search) {
		PartnersEndpoints.searchPartners(page, search, null, 0, [INVITE_STATUS_ACCEPTED])
		.then((result) => {
			if (this.unmounted) return;
			let results = this.append ? this.state.partners.concat(result.payload) : result.payload;
			this.append = false;
			this.setState({loading: false, search: search, partners: results, page: page, lastPage: result.metaData ? result.metaData.last : true});
		})
		.catch((error) => {
			console.error('Error fetching partners for PartnerSelector', error);
		});
	}

	onSearch(search) {
		this.getPartners(0, search);
	}

	loadMore() {
		this.append = true;
		this.getPartners(this.state.page + 1, this.state.search);
	}

	render() {
		if (this.state.loading) return (<Loading small={true} horizontal={true}>Loading Partners...</Loading>);
		return (
			<PopoverSearchList label={'Select Partner'}
							   list={this.state.partners}
							   labelRenderer={(partner) => partner.firstName + ' ' + partner.lastName}
							   onItemSelected={this.props.onPartnerSelected}
							   pageableSearch={this.state.search}
							   pageableSearchHandler={this.onSearch}
							   loadMore={this.state.lastPage ? null : this.loadMore}/>
		);
	}
}

PartnerSelector.propTypes = {
	onPartnerSelected: PropTypes.func.isRequired
};

export default connect()(PartnerSelector);
