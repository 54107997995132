import {CloudDownload, Download} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../globals/Routes';
import * as ApiHelper from '../../helpers/ApiHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_DOWNLOAD_REPORTS} from '../../helpers/FeatureHelper';
import Dialog from '../Dialog';
import ScrimMessage from '../messages/ScrimMessage';
import TextInputBox from '../TextInputBox';
import Upsell from '../Upsell';
import ActionButton from './ActionButton';

class DownloadButton extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {showUpsellDialog: false, showDownloadCSVDialog: false, setDownloadCSVName: false, csvReportName: '', csvReportDescription: ''};

		this.showUpsell = this.showUpsell.bind(this);
		this.onDownload = this.onDownload.bind(this);
	}

	showUpsell() {
		this.setState({showUpsellDialog: true});
	}

	get useNewSystem() {
		return this.props && this.props.callingPage;
	}

	onDownloadCSV() {
		this.setState({setDownloadCSVName: false, showDownloadCSVDialog: true});
		let downloadReportConfig = this.props.callingPage.downloadReportConfig;
		let {csvReportName} = this.state;
		if (!csvReportName) {
			csvReportName = downloadReportConfig.exportBaseFileName;
		}
		downloadReportConfig.exportBaseFileName = this.addCsvExtension(csvReportName);
		downloadReportConfig.fileDescription = this.state.csvReportDescription;
		let endpoint = downloadReportConfig.endpoint;
		if (downloadReportConfig.sort || downloadReportConfig.search) {
			endpoint = ApiHelper.appendPageableParams(endpoint, null, null, downloadReportConfig.search, downloadReportConfig.sort);
		}
		ApiHelper.sendPostReport(endpoint, downloadReportConfig)
		.then((result) => {
			console.info('Download request', result);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from onDownload', error);
		});
	}

	onDownload() {
		let downloadReportConfig = this.props.callingPage.downloadReportConfig;
		this.setState({setDownloadCSVName: true, csvReportName: this.addCsvExtension(downloadReportConfig.exportBaseFileName)});
	}

	addCsvExtension(fileName) {
		if (!fileName.endsWith('.csv')) {
			fileName += '.csv';
		}
		return fileName;
	}

	render() {
		let action = this.useNewSystem ? this.onDownload : this.props.onAction;
		if (!FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_DOWNLOAD_REPORTS)) {
			action = this.showUpsell;
		}
		return (
			<div key={'download_button'} style={{display: 'flex'}}>
				{this.state.showUpsellDialog &&
				 <Dialog title={'Upgrade Required'}
						 message={<Upsell feature={'Channel Ecosystem'}
										  headline={'Do you want to download reports?'}
										  callToAction={
											  <div>
												  Reach out to your customer success representative to enable <em>Download Reports</em>
											  </div>}
										  productBullets={['Download reports']}/>}
						 yesAction={() => this.setState({showUpsellDialog: false})}
						 noContentPadding={true}/>}
				{this.useNewSystem ?
					<ActionButton key={'download_button_new'}
								  toolTip={'Create Downloadable CSV'}
								  onAction={action}
								  icon={<Download/>}/>
					:
					<ActionButton key={'download_button'}
								  toolTip={this.props.inProgress ? 'Downloading' : 'Download'}
								  onAction={action}
								  inProgress={this.props.inProgress}
								  icon={<CloudDownload/>}/>}
				{this.props.inProgress &&
				 <ScrimMessage message={'Downloading...'}/>}
				{this.state.setDownloadCSVName &&
				 <Dialog title={'Download Report as CSV'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 400, margin: 'auto'}}>
								 <TextInputBox hintText={'Report Name'}
											   value={this.state.csvReportName}
											   onChange={(value) => this.setState({csvReportName: value.replaceAll('/', '_')})}
											   doNotAutoFocus={true}
											   maxChars={64}/>
								 <TextInputBox hintText={'Report Description (optional)'}
											   onChange={(value) => this.setState({csvReportDescription: value})}
											   doNotAutoFocus={true}
											   multiLine={true}
											   rows={3}
											   maxChars={512}/>
							 </div>}
						 yesLabel={'DOWNLOAD'}
						 noLabel={'CANCEL'}
						 noAction={() => {
							 this.setState({csvReportName: '', csvReportDescription: '', setDownloadCSVName: false});
						 }}
						 yesAction={() => {
							 this.onDownloadCSV();
						 }}/>}
				{this.state.showDownloadCSVDialog &&
				 <Dialog title={'Download Report'}
						 message={
							 <div>
								 <div>Your download has started. We will send you an email when it's completed.</div>
								 <div>When the report is available, it will be displayed in Downloaded Reports.</div>
							 </div>}
						 yesLabel={'Go to Downloaded Reports'}
						 noLabel={'Close'}
						 noAction={() => {
							 this.setState({csvReportName: '', csvReportDescription: '', showDownloadCSVDialog: false});
						 }}
						 yesAction={() => {
							 this.setState({showDownloadCSVDialog: false});
							 EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.DOWNLOADED.BASE_ROUTE);
						 }}/>}
			</div>
		);
	}
}

DownloadButton.propTypes = {
	onAction: PropTypes.func,
	inProgress: PropTypes.bool,
	callingPage: PropTypes.object
};

export default connect()(DownloadButton);
