import PropTypes from 'prop-types';
import React, {Component} from 'react';

import * as PartnerOrgEndpoints from '../../../../endpoints/PartnerOrgEndpoints';
import {Routes} from '../../../../globals/Routes';
import EnvHelper from '../../../../helpers/EnvHelper';
import {partnerTapAppBackground} from '../../../../styles/partnertap_theme';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import Dialog from '../../../../ui/Dialog';
import Loading from '../../../../ui/Loading';

import ScrimMessage from '../../../../ui/messages/ScrimMessage';
import PopoverSearchList from '../../../../ui/PopoverSearchList';
import TextInputBox from '../../../../ui/TextInputBox';

export const ADD_PARTNER_TYPE = {partnerType: '+ New Partner Org Type'};

export class PartnerOrgEditorDialog extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {};
		this.state.statusMessage = '';
		this.state.error = false;
		this.state.crmPartnerRecord = null;
		this.state.prmPartnerRecord = null;
		this.state.referralSize = null;
		this.state.partnerCity = null;
		this.state.partnerOrgName = null;
		this.state.partnerAddress = null;
		this.state.partnerState = null;
		this.state.partnerZipCode = null;
		this.state.partnerCountry = null;
		this.state.partnerOrgNameMissing = false;
		this.state.showNewPartnerOrgTypeForm = false;
		this.state.selectedPartnerOrgType = null;
		this.state.partnerTypePublicId = null;
		this.state.availablePartnerTypeList = null;
		this.state.partnerOwner = null;
		this.state.partnerOwnerEmail = null;
		this.state.partnerOwnerPhone = null;
		this.state.errorMessage = null;
		this.state.loading = false;
		this.state.recommended = false;
		this.state.orgPartnerTypePublicId = null;

		this.onChangeCRMPartnerRecord = this.onChangeCRMPartnerRecord.bind(this);
		this.onChangeCRMPartnerRecordUrl = this.onChangeCRMPartnerRecordUrl.bind(this);
		this.onChangePRMPartnerRecords = this.onChangePRMPartnerRecords.bind(this);
		this.onChangePartnerOrgName = this.onChangePartnerOrgName.bind(this);
		this.onChangePartnerOwner = this.onChangePartnerOwner.bind(this);
		this.onChangePartnerOwnerEmail = this.onChangePartnerOwnerEmail.bind(this);
		this.onChangePartnerOwnerPhone = this.onChangePartnerOwnerPhone.bind(this);
		this.onChangeReferralSize = this.onChangeReferralSize.bind(this);
		this.onChangeAddress = this.onChangeAddress.bind(this);
		this.onChangeCity = this.onChangeCity.bind(this);
		this.onChangePartnerState = this.onChangePartnerState.bind(this);
		this.onChangeZipcode = this.onChangeZipcode.bind(this);
		this.createPartnerOrg = this.createPartnerOrg.bind(this);
		this.updatePartnerOrg = this.updatePartnerOrg.bind(this);
		this.dialogOk = this.dialogOk.bind(this);
		this.onChangePartnerOrgType = this.onChangePartnerOrgType.bind(this);
		this.onChangeCountry = this.onChangeCountry.bind(this);
		this.onChangeisRecommended = this.onChangeisRecommended.bind(this);
		this.postOrgPartnerTypes = this.postOrgPartnerTypes.bind(this);
	}

	componentDidMount() {
		this.fetchPartnerOrg();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onChangeCRMPartnerRecordUrl(value) {
		this.setState({crmPartnerRecordUrl: value});
	}

	onChangeCRMPartnerRecord(value) {
		this.setState({crmPartnerRecord: value});
	}

	onChangePRMPartnerRecords(value) {
		this.setState({prmPartnerRecord: value});
	}

	onChangeZipcode(value) {
		this.setState({partnerZipCode: value});
	}

	onChangePartnerState(value) {
		this.setState({partnerState: value});
	}

	onChangePartnerOrgName(value) {
		this.setState({partnerOrgName: value});
	}

	onChangeAddress(value) {
		this.setState({partnerAddress: value});
	}

	onChangeCity(value) {
		this.setState({partnerCity: value});
	}

	onChangeCountry(value) {
		this.setState({partnerCountry: value});
	}

	onChangeReferralSize(value) {
		this.setState({referralSize: value});
	}

	onChangePartnerOrgType(value) {
		this.setState({selectedPartnerOrgType: value});
	}

	onChangePartnerOwner(value) {
		this.setState({partnerOwner: value});
	}

	onChangePartnerOwnerEmail(value) {
		this.setState({partnerOwnerEmail: value});
	}

	onChangePartnerOwnerPhone(value) {
		this.setState({partnerOwnerPhone: value});
	}

	onChangeisRecommended(value) {
		this.setState({recommended: value});
	}

	get companyPartnerPayload() {
		return {
			orgPartnerTypePublicId: this.state.selectedPartnerOrgType ? this.state.selectedPartnerOrgType.orgPartnerTypePublicId : null,
			crmRecordUrl: this.state.crmPartnerRecordUrl,
			crmRecordId: this.state.crmPartnerRecord,
			prmRecordId: this.state.prmPartnerRecord,
			partnerName: this.state.partnerOrgName,
			averageReferralDealSize: this.state.referralSize,
			street: this.state.partnerAddress,
			city: this.state.partnerCity,
			state: this.state.partnerState,
			zipCode: this.state.partnerZipCode,
			country: this.state.partnerCountry,
			partnerOwner: this.state.partnerOwner,
			partnerOwnerEmail: this.state.partnerOwnerEmail,
			partnerOwnerPhone: this.state.partnerOwnerPhone,
			recommended: this.state.recommended
		};
	}

	missingMandatoryFields() {
		if (!this.state.partnerOrgName) {
			this.setState({partnerOrgNameMissing: true});
			return true;
		}
		this.setState({partnerOrgNameMissing: false});
		return false;
	}

	addPartnerTypesObject(partnerOrgTypes) {
		partnerOrgTypes.unshift(ADD_PARTNER_TYPE);
		return partnerOrgTypes;
	}

	fetchPartnerOrg() {
		this.setState({loading: true});
		let {companyPartnerPublicId} = this.props;
		if (companyPartnerPublicId) {
			Promise.all([
				PartnerOrgEndpoints.getOrgPartnerTypes(),
				PartnerOrgEndpoints.getPartnerOrg(companyPartnerPublicId)
			]).then((results) => {
				if (this.unmounted) return;
				if (results[0].payload) {
					const availablePartnerTypes = this.addPartnerTypesObject(results[0].payload);
					this.setState({availablePartnerTypeList: availablePartnerTypes});
				}
				if (results[1].payload) {
					this.populateEditValues(companyPartnerPublicId, results[1].payload);
				}
			})
			.catch((error) => {
				this.setState({loading: false, errorMessage: error.message});
			});
		}
		else {
			PartnerOrgEndpoints.getOrgPartnerTypes()
			.then((result) => {
				if (result.payload) {
					this.setState({
						availablePartnerTypeList: this.addPartnerTypesObject(result.payload),
						loading: false,
						rawAvailablePartnerTypeListPayload: result.payload
					});
				}
			});
		}
	}

	updatePartnerOrg() {
		if (this.missingMandatoryFields()) {
			return;
		}
		this.setState({saving: true});
		const {companyPartnerPublicId} = this.props;
		return PartnerOrgEndpoints.updatePartnerOrg(companyPartnerPublicId, this.companyPartnerPayload)
		.then(() => {
			if (this.unmounted) return;
			this.setState({saving: false, statusMessage: 'Company Partner Updated'});
			this.props.onClose();
		})
		.catch((error) => {
			this.setState({saving: false, errorMessage: error.message});
		});
	}

	createPartnerOrg() {
		if (this.missingMandatoryFields()) {
			return;
		}
		this.setState({saving: true});
		return PartnerOrgEndpoints.createPartnerOrg(this.companyPartnerPayload)
		.then(() => {
			if (this.unmounted) return;
			this.setState({saving: false, statusMessage: 'Company Partner Created'});
			this.props.onClose();
		})
		.catch((error) => {
			this.setState({saving: false, errorMessage: error.message});

		});
	}

	populateEditValues(companyPartnerPublicId, editParameters) {
		const {
			crmRecordId,
			crmRecordUrl,
			prmRecordId,
			partnerName,
			averageReferralDealSize,
			zipCode,
			country,
			street,
			city,
			partnerOwner,
			partnerOwnerEmail,
			partnerOwnerPhone,
			state,
			recommended,
			orgPartnerTypeResponseDto
		} = editParameters;
		this.setState({
			crmPartnerRecordUrl: crmRecordUrl,
			crmPartnerRecord: crmRecordId,
			prmPartnerRecord: prmRecordId,
			partnerOrgName: partnerName,
			referralSize: averageReferralDealSize,
			partnerZipCode: zipCode,
			partnerCountry: country,
			selectedPartnerOrgType: orgPartnerTypeResponseDto,
			partnerAddress: street,
			partnerCity: city,
			partnerOwner: partnerOwner,
			partnerOwnerEmail: partnerOwnerEmail,
			partnerOwnerPhone: partnerOwnerPhone,
			partnerState: state,
			companyPartnerPublicId: companyPartnerPublicId,
			loading: false,
			recommended: recommended
		});
	}

	handlePartnerOrgTypeSelection(orgType) {
		if (orgType.partnerType === ADD_PARTNER_TYPE.partnerType) {
			this.setState({showNewPartnerOrgTypeForm: true, selectedPartnerOrgType: null});
		}
		else {
			this.setState({showNewPartnerOrgTypeForm: false, selectedPartnerOrgType: orgType});
		}
	}

	postOrgPartnerTypes() {
		const partnerOrgType = this.state.selectedPartnerOrgType;
		const regex = /^[-<>$%&*! 0-9]/;
		if (regex.test(partnerOrgType)) return;
		if (!partnerOrgType) return;
		const partnerTypePayload = {partnerType: partnerOrgType};
		PartnerOrgEndpoints.setOrgPartnerTypes(partnerTypePayload)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				this.setState({
					statusMessage: 'Your new partner org type has been added',
					availablePartnerTypeList: this.addPartnerTypesObject(result.payload),
					showNewPartnerOrgTypeForm: false,
					selectedPartnerOrgType: result.payload.slice(-1)[0]
				});
				return result.payload;
			}
		})
		.catch((error) => {
			this.setState({errorMessage: error.message});
		});
	}

	dialogOk() {
		if (this.state.errorMessage || this.state.statusMessage === 'Your new partner org type has been added') {
			this.setState({statusMessage: null, errorMessage: null});
			return;
		}
		this.setState({statusMessage: null, errorMessage: null});
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG.ROUTE);
	}

	renderEditor() {
		let {
			showNewPartnerOrgTypeForm,
			availablePartnerTypeList,
			crmPartnerRecord,
			partnerOrgName,
			prmPartnerRecord,
			partnerOwner,
			partnerOwnerEmail,
			partnerOwnerPhone,
			selectedPartnerOrgType,
			referralSize,
			partnerAddress,
			partnerCity,
			partnerState,
			partnerZipCode,
			partnerCountry,
			statusMessage,
			partnerOrgNameMissing,
			recommended,
			errorMessage
		} = this.state;
		return (
			<div style={{backgroundColor: partnerTapAppBackground, display: 'flex', justifyContent: 'center'}}>
				<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 20, padding: 10}}>
					{errorMessage &&
					 <Dialog title={'Company Partner Error'} message={errorMessage} yesAction={this.dialogOk}/>}
					{statusMessage &&
					 <Dialog title={'Partner Type Saved'} message={statusMessage} yesAction={this.dialogOk}/>}

					<TextInputBox value={partnerOrgName}
								  hintText={'Partner Org Name'}
								  errorText={partnerOrgNameMissing ? 'Partner Org Name is required' : ''}
								  onEnter={() => this.setState({partnerOrgNameMissing: false})}
								  maxChars={2048}
								  onChange={this.onChangePartnerOrgName}
								  disabled={EnvHelper.isSpoofing}/>
					<TextInputBox value={crmPartnerRecord}
								  hintText={'CRM Partner Record ID'}
								  maxChars={255}
								  onChange={this.onChangeCRMPartnerRecord}
								  disabled={EnvHelper.isSpoofing}/>
					<TextInputBox value={prmPartnerRecord}
								  hintText={'PRM Partner Record ID'}
								  maxChars={255}
								  onChange={this.onChangePRMPartnerRecords}
								  disabled={EnvHelper.isSpoofing}/>
					<TextInputBox value={partnerOwner}
								  hintText={'Partner Manager'}
								  onChange={(value) => this.onChangePartnerOwner(value)}
								  maxChars={255}
								  disabled={EnvHelper.isSpoofing}/>
					<TextInputBox value={partnerOwnerEmail}
								  hintText={'Partner Manager Email'}
								  onChange={(value) => this.onChangePartnerOwnerEmail(value)}
								  maxChars={255}
								  disabled={EnvHelper.isSpoofing}/>
					<TextInputBox value={partnerOwnerPhone}
								  hintText={'Partner Manager Phone'}
								  onChange={(value) => this.onChangePartnerOwnerPhone(value)}
								  maxChars={50}
								  disabled={EnvHelper.isSpoofing}/>
					<PopoverSearchList list={availablePartnerTypeList || []}
									   label={'Partner Type'}
									   preselectedItem={selectedPartnerOrgType}
									   labelRenderer={(partnerOrg) => partnerOrg.partnerType}
									   onItemSelected={(type) => this.handlePartnerOrgTypeSelection(type)}
									   valueRenderer={(partnerOrg) => partnerOrg.orgPartnerTypePublicId}/>

					{showNewPartnerOrgTypeForm &&
					 <div style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center'}}>
						 <TextInputBox hintText={'Please Type a new Partner Org Type'}
									   onChange={(value) => this.onChangePartnerOrgType(value)}
									   maxChars={255}/>
						 <PrimaryButton label={'ADD ORG TYPE'}
										onClick={this.postOrgPartnerTypes}
										disabled={EnvHelper.isSpoofing}/>
					 </div>}
					<div>
						<TextInputBox hintText={'Average Deal Size'}
									  value={referralSize}
									  maxChars={2048}
									  onChange={this.onChangeReferralSize}
									  disabled={EnvHelper.isSpoofing}/>
					</div>
					<div>
						<TextInputBox hintText={'Address'}
									  value={partnerAddress}
									  maxChars={255}
									  onChange={this.onChangeAddress}
									  disabled={EnvHelper.isSpoofing}/>
					</div>
					<div style={{display: 'flex', alignItems: 'center'}}>
						<CheckboxButton checked={recommended} onChange={(event) => this.setState({recommended: event.target.checked})}/>
						Set as Recommended Partner
					</div>
					<div style={{display: 'flex', gap: 10}}>
						<div style={{flex: 1}}>
							<TextInputBox hintText={'City'}
										  value={partnerCity}
										  maxChars={255}
										  onChange={this.onChangeCity}
										  disabled={EnvHelper.isSpoofing}/>
						</div>
						<div style={{flex: 1}}>
							<TextInputBox hintText={'State'}
										  value={partnerState}
										  maxChars={2048}
										  onChange={this.onChangePartnerState}
										  disabled={EnvHelper.isSpoofing}/>
						</div>
					</div>
					<div style={{display: 'flex', gap: 10}}>
						<div style={{flex: 1}}>
							<TextInputBox hintText={'Postal Code'}
										  value={partnerZipCode}
										  maxChars={50}
										  onChange={this.onChangeZipcode}
										  disabled={EnvHelper.isSpoofing}/>
						</div>
						<div style={{flex: 1}}>
							<TextInputBox hintText={'Country'}
										  value={partnerCountry}
										  maxChars={100}
										  onChange={this.onChangeCountry}
										  disabled={EnvHelper.isSpoofing}/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		let {companyPartnerPublicId, saving} = this.state;
		if (this.state.loading) return <Loading>Loading...</Loading>;
		return (
			<div>
				{saving &&
				 <ScrimMessage message={'Saving...'}/>}
				<Dialog title={companyPartnerPublicId ? 'Update Partner Organization' : 'Add Partner Organization'}
						message={this.renderEditor()}
						yesLabel={companyPartnerPublicId ? 'UPDATE PARTNER ORG' : 'ADD PARTNER ORG'}
						yesAction={companyPartnerPublicId ? this.updatePartnerOrg : this.createPartnerOrg}
						noLabel={'CANCEL'}
						noAction={this.props.onClose}
						noContentPadding={true}/>
			</div>
		);
	}
}

PartnerOrgEditorDialog.propTypes = {
	companyPartnerPublicId: PropTypes.string,
	onClose: PropTypes.func.isRequired
};

export default PartnerOrgEditorDialog;
