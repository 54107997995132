import {CODE_VERIFIER_KEY, REDIRECT_URI_KEY} from '../globals/StorageKeys';
import * as ApiHelper from '../helpers/ApiHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';

export function wizardDiscoverOrgs(authCode, signupType) {
	let endpoint = '/datamanager/wizard/discover-orgs',
		payload = {
			authCode: authCode,
			signUpType: signupType,
			codeVerifier: PersistenceHelper.getValue(CODE_VERIFIER_KEY) || '',
			redirectUri: PersistenceHelper.getValue(REDIRECT_URI_KEY) || ''
		};
	return ApiHelper.sendGet(endpoint, payload);
}

export function wizardStep1(authCode, signupType, crmUri) {
	let endpoint = '/datamanager/wizard/1',
		payload = {
			authCode: authCode,
			signUpType: signupType,
			codeVerifier: PersistenceHelper.getValue(CODE_VERIFIER_KEY) || '',
			redirectUri: PersistenceHelper.getValue(REDIRECT_URI_KEY) || '',
			crmUri: crmUri
		};
	return ApiHelper.sendPost(endpoint, payload);
}

export function wizardStep2(signupType, assetTypeMap) {
	let endpoint = '/datamanager/wizard/2',
		payload = {
			signUpType: signupType,
			assetTypeMap: assetTypeMap
		};
	return ApiHelper.sendPost(endpoint, payload);
}

export function wizardStatus(signupType) {
	let endpoint = '/datamanager/data/' + signupType;
	return ApiHelper.sendGet(endpoint);
}

export function syncSummary() {
	let endpoint = '/datamanager/syncsummary';
	return ApiHelper.sendGet(endpoint);
}

export function syncArchive() {
	let endpoint = '/datamanager/syncarchive';
	return ApiHelper.sendGet(endpoint);
}
