import PropTypes from 'prop-types';
import React, {Component} from 'react';
import EnvHelper from '../helpers/EnvHelper';
import {partnerTapStroke, partnerTapTernary} from '../styles/partnertap_theme';
import PartnerTapIcon from './PartnerTapIcon';

class MatchCount extends Component {

	constructor(props, context) {
		super(props, context);
		this.updated = false;
	}

	render() {
		let color = this.props.count > 0 ? partnerTapTernary : partnerTapStroke;
		if (this.props.color && this.props.count > 0) {
			color = this.props.color;
		}
		let label = this.props.label || this.props.label === '' ? this.props.label : 'Account';
		if (label && label !== '' && this.props.count !== 1 && !this.props.doNotPluralize) {
			label += 's';
		}

		if (EnvHelper.isDesktop) {
			return (
				<div>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
						<PartnerTapIcon glyph={this.props.icon ? this.props.icon : 'A'}
										style={{
											fontSize: this.props.icon ? 24 : 20,
											position: 'relative',
											top: -2,
											left: 0,
											paddingRight: 2,
											color: color
										}}/>
						<div style={{fontSize: 14, color: color, textAlign: 'left'}}>
							{this.props.count}
						</div>
						<div style={{paddingLeft: 2, fontSize: 14, color: color}}>
							{label}
						</div>
					</div>
				</div>
			);
		}
		else {
			return (
				<div>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
						<PartnerTapIcon glyph={this.props.icon ? this.props.icon : 'A'}
										style={{
											fontSize: this.props.icon ? 20 : 16,
											position: 'relative',
											top: -2,
											left: 0,
											paddingRight: 1,
											color: color
										}}/>
						<div style={{fontSize: 12, color: color, textAlign: 'left', padding: '0 0 0 3px'}}>
							{this.props.count}
						</div>
						<div style={{paddingLeft: 3, fontSize: 12, color: color}}>
							{label}
						</div>
					</div>
				</div>
			);
		}
	}
}

MatchCount.propTypes = {
	count: PropTypes.number,
	icon: PropTypes.string,
	label: PropTypes.string,
	color: PropTypes.string,
	doNotPluralize: PropTypes.bool
};

export default MatchCount;
