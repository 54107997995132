import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as AuthEndpoints from '../../../endpoints/AuthEndpoints';
import * as DataManagerEndpoints from '../../../endpoints/DataManagerEndpoints';
import {HUBSPOT, MSFT, SFDC} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import {CODE_VERIFIER_KEY, DYNAMICS_URI_KEY} from '../../../globals/StorageKeys';
import AuthHelper, {DYNAMICS_CONSENT_TYPE_CRM, DYNAMICS_CONSENT_TYPE_DISCOVER} from '../../../helpers/AuthHelper';
import DataManagerHelper, {COMPANY_SALE_ACCOUNTS_DYNAMICS, COMPANY_SALE_ACCOUNTS_HUBSPOT, COMPANY_SALE_ACCOUNTS_SFDC} from '../../../helpers/DataManagerHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import PersistenceHelper from '../../../helpers/PersistenceHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapAlert, partnerTapAppBackground, partnerTapStroke, partnerTapTernary, partnerTapWhite} from '../../../styles/partnertap_theme';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import SupportButton from '../../../ui/buttons/SupportButton';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import Stepper from '../../../ui/Stepper';

class DataManagerWizardPageStep1 extends Component {

	constructor(props, context) {
		super(props, context);

		this.code = this.props.match.params.token || EnvHelper.getUrlParam('code');
		this.crmType = this.props.match.params.crmType;
		this.consentType = this.props.match.params.consentType; // MSFT only
		this.state = {
			loading: false,
			authenticated: false,
			dynamicsUri: PersistenceHelper.getValue(DYNAMICS_URI_KEY),
			error: this.errorMessage,
			sandbox: false,
			steps: [
				{label: 'Choose CRM', active: true, complete: false},
				{label: 'Customize Data', active: false, complete: false},
				{label: 'Sync CRM', active: false, complete: false}
			]
		};

		this.handleSalesforceLogin = this.handleSalesforceLogin.bind(this);
		this.handleDynamicsLogin = this.handleDynamicsLogin.bind(this);
		this.handleDynamicsDiscover = this.handleDynamicsDiscover.bind(this);
		this.handleHubspotLogin = this.handleHubspotLogin.bind(this);
	}

	componentDidMount() {
		if (DataManagerHelper.crmTypeSynced) {
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.SYNC_SUMMARY.ROUTE);
		}
		else if (!this.state.error && this.code) {
			this.handleWizardStep1();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get tileWidth() {
		return 250;
	}

	get tileHeight() {
		return 350;
	}

	get errorMessage() {
		let messages = [];
		if (this.props.match.params.error) messages.push(messages.push);
		if (EnvHelper.getUrlParam('error')) messages.push(EnvHelper.getUrlParam('error'));
		if (EnvHelper.getUrlParam('error_description')) messages.push(EnvHelper.getUrlParam('error_description'));
		return messages.length ? {errorMessage: messages.join(', ')} : null;
	}

	handleWizardStep1() {
		this.setState({loading: true});
		let action = this.consentType === DYNAMICS_CONSENT_TYPE_DISCOVER ? DataManagerEndpoints.wizardDiscoverOrgs : DataManagerEndpoints.wizardStep1;
		action(this.code, this.crmType, this.state.dynamicsUri)
		.then((result) => {
			if (this.unmounted) return;
			let authenticated = result.payload && !result.payload.code;
			let errorMessage = result.payload && result.payload.code ? result.message : null;
			this.setState({loading: false, authenticated: authenticated, error: errorMessage, discoverOrgs: result.payload});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from handleWizardStep1', error);
		});
	}

	get hasDiscoveredOrgs() {
		return this.state.discoverOrgs && this.state.discoverOrgs.length > 0;
	}

	handleDynamicsDiscover() {
		this.crmType = MSFT;
		this.consentType = DYNAMICS_CONSENT_TYPE_DISCOVER;
		this.importType = COMPANY_SALE_ACCOUNTS_DYNAMICS;
		this.setState({loading: true});
		AuthEndpoints.fetchCodeChallenge()
		.then((result) => {
			if (this.unmounted) return;
			PersistenceHelper.setValue(CODE_VERIFIER_KEY, result.payload.codeVerifier, true);
			AuthHelper.dynamicsPermission(result.payload.codeChallenge, this.consentType);
		});
	}

	handleDynamicsLogin() {
		this.crmType = MSFT;
		this.consentType = DYNAMICS_CONSENT_TYPE_CRM;
		this.importType = COMPANY_SALE_ACCOUNTS_DYNAMICS;
		this.setState({loading: true});
		AuthEndpoints.fetchCodeChallenge()
		.then((result) => {
			if (this.unmounted) return;
			PersistenceHelper.setValue(CODE_VERIFIER_KEY, result.payload.codeVerifier, true);
			AuthHelper.dynamicsPermission(result.payload.codeChallenge, this.consentType, this.state.dynamicsUri);
		});
	}

	handleSalesforceLogin() {
		this.crmType = SFDC;
		this.importType = COMPANY_SALE_ACCOUNTS_SFDC;
		this.setState({loading: true});
		AuthEndpoints.fetchCodeChallenge()
		.then((result) => {
			if (this.unmounted) return;
			AuthHelper.salesforcePermission(this.state.sandbox);
		});
	}

	handleHubspotLogin() {
		this.crmType = HUBSPOT;
		this.importType = COMPANY_SALE_ACCOUNTS_HUBSPOT;
		this.setState({loading: true});
		AuthEndpoints.fetchCodeChallenge()
		.then((result) => {
			if (this.unmounted) return;
			AuthHelper.hubSpotPermission();
		});
	}

	saveInstanceUrl(uri) {
		this.setState({dynamicsUri: uri});
		PersistenceHelper.setValue(DYNAMICS_URI_KEY, uri, true);
	}

	renderStep() {
		if (this.state.authenticated && this.consentType === DYNAMICS_CONSENT_TYPE_DISCOVER) {
			return (
				<div style={TableHelper.autoHeightTileStyle}>
					<div style={{display: 'flex', justifyContent: 'center'}}>
						{DataManagerHelper.getCrmIcon(this.crmType)}
					</div>
					<div style={{display: 'flex', flexDirection: 'column', paddingBottom: 10}}>
						{this.hasDiscoveredOrgs ?
							<Fragment>
								<div style={{fontSize: 16, padding: 10}}>
									Select your Dynamics environment
								</div>
								{this.state.discoverOrgs.map((item, index) => {
									return (
										<div key={'env_' + index}
											 style={{
												 display: 'flex',
												 flexDirection: 'column',
												 textAlign: 'left',
												 padding: 10,
												 paddingBottom: 20,
												 borderBottom: '1px solid ' + partnerTapStroke
											 }}>
											<div style={{display: 'flex', alignItems: 'center', fontSize: 20}}>
												<CheckboxButton checked={this.state.dynamicsUri === item.ApiUrl}
																title={item.FriendlyName}
																onChange={() => this.saveInstanceUrl(item.ApiUrl)}/>
												Friendly Name: {item.FriendlyName}
											</div>
											<div style={{display: 'flex', paddingLeft: 50}}>
												<div style={{width: 50}}>
													Url:
												</div>
												{item.Url}
											</div>
											<div style={{display: 'flex', paddingLeft: 50}}>
												<div style={{width: 50}}>
													ApiUrl:
												</div>
												{item.ApiUrl}
											</div>
										</div>
									);
								})}
								<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 20}}>
									<PrimaryButton label={'CONNECT'} disabled={!this.state.dynamicsUri} onClick={this.handleDynamicsLogin}/>
								</div>
							</Fragment>
							:
							<div style={{display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center', justifyContent: 'center'}}>
								<div style={{fontSize: 16}}>
									Sorry, no Dynamics instances found
								</div>
								<PrimaryButton label={'CHOOSE'} onClick={this.handleDynamicsDiscover}/>
								<SupportButton/>
							</div>}
					</div>
				</div>
			);
		}

		if (this.state.authenticated) {
			return (
				<div style={TableHelper.tileStyle(this.tileWidth, this.tileHeight, partnerTapTernary)}>
					{DataManagerHelper.getCrmIcon(this.crmType)}
					<div style={{fontSize: 16}}>
						Success!
					</div>
					<div>
						Your CRM is connected
					</div>
					<div>
						Press CONTINUE below to select your asset types
					</div>
				</div>
			);
		}

		return (
			<Fragment>
				<div style={TableHelper.tileStyle(this.tileWidth, this.tileHeight, partnerTapTernary)}>
					<div style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 42}}>
						{DataManagerHelper.getCrmIcon(COMPANY_SALE_ACCOUNTS_SFDC)}
						<div style={{display: 'flex', alignItems: 'center'}}>
							<CheckboxButton checked={this.state.sandbox} onChange={(event) => this.setState({sandbox: event.target.checked})}/>
							<div style={{fontSize: 16, fontWeight: 'bold', paddingRight: 13}}>Sandbox</div>
						</div>
					</div>
					<PrimaryButton label={'CHOOSE CRM'} onClick={this.handleSalesforceLogin} disabled={EnvHelper.isCordova}/>
				</div>
				<div style={TableHelper.tileStyle(this.tileWidth, this.tileHeight, partnerTapTernary)}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
						{DataManagerHelper.getCrmIcon(COMPANY_SALE_ACCOUNTS_DYNAMICS)}
					</div>
					<PrimaryButton label={'CHOOSE CRM'} onClick={this.handleDynamicsDiscover} disabled={EnvHelper.isCordova}/>
				</div>
				<div style={TableHelper.tileStyle(this.tileWidth, this.tileHeight, partnerTapTernary)}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
						{DataManagerHelper.getCrmIcon(COMPANY_SALE_ACCOUNTS_HUBSPOT)}
					</div>
					<PrimaryButton label={'CHOOSE CRM'} onClick={this.handleHubspotLogin} disabled={EnvHelper.isCordova}/>
				</div>
			</Fragment>
		);
	}

	render() {
		let upsell = DataManagerHelper.dataManagerCrmWizardAccess;
		if (upsell) return upsell;

		let errorMessage = this.state.error;
		return (
			<ScrollingContainer divId={'data_manager_wizard_page'}>
				<Stepper steps={this.state.steps}/>
				{errorMessage &&
				 <Dialog title={'Sorry, an error occurred'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								 <div style={{color: partnerTapAlert, padding: 20, wordWrap: 'break-word', maxWidth: 600}}>
									 {errorMessage}
								 </div>
								 <SupportButton/>
							 </div>}
						 yesAction={() => this.setState({error: null})}/>}
				<div style={{
					margin: 10,
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					overflow: 'scroll',
					border: 'solid 1px' + partnerTapStroke,
					borderRadius: 10,
					backgroundColor: partnerTapAppBackground
				}}>
					{this.state.loading ? <Loading>Loading...</Loading> : this.renderStep()}
				</div>
				<div style={{flex: 1}}/>
				<div style={{
					backgroundColor: partnerTapWhite,
					padding: 20,
					display: 'flex',
					gap: 10,
					justifyContent: 'center',
					borderTop: '1px solid ' + partnerTapStroke
				}}>
					<SecondaryButton label={'CANCEL'} onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.ROUTE)}/>
					<PrimaryButton label={'CONTINUE'}
								   disabled={this.state.loading || !(this.state.authenticated && (this.crmType !== MSFT || this.consentType === DYNAMICS_CONSENT_TYPE_CRM))}
								   onClick={() => {
									   this.setState({loading: true});
									   EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_2.PATH(this.crmType));
								   }}/>
				</div>
			</ScrollingContainer>
		);
	}
}

DataManagerWizardPageStep1.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(connect()(DataManagerWizardPageStep1));
