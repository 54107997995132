import {Dangerous} from '@mui/icons-material';
import * as PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';
import EnvHelper from '../../../../helpers/EnvHelper';
import {partnerTapAlert, partnerTapTernary, partnerTapTernaryLight, partnerTapWhite} from '../../../../styles/partnertap_theme';
import PrimaryButton from '../../../../ui/buttons/PrimaryButton';
import Dialog from '../../../../ui/Dialog';
import Loading from '../../../../ui/Loading';
import DropdownMenu from '../../../../ui/selectors/DropdownMenu';
import * as CoSellEngineHelperLive from '../CoSellEngineHelperLive';
import {Routes} from '../../../../globals/Routes';
import SecondaryButton from '../../../../ui/buttons/SecondaryButton';
import ScrimMessage from '../../../../ui/messages/ScrimMessage';
import TextInputBox from '../../../../ui/TextInputBox';
import ActiveWorkflowName from './ActiveWorkflowName';

class CoSellActionListWorkflowSelector extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			activeWorkflow: null,
			activeWorkflowName: null,
			saving: false,
			workflows: null,
			selectedWorkflow: null,
			sequences: null,
			selectedSequence: null,
			showStopWorkflowDialog: false
		};

		this.getWorkflowSequences = this.getWorkflowSequences.bind(this);
		this.stopWorkflow = this.stopWorkflow.bind(this);
		this.selectWorkflow = this.selectWorkflow.bind(this);
		this.selectSequence = this.selectSequence.bind(this);
		this.openWorkflowEditor = this.openWorkflowEditor.bind(this);
		this.cancelWorkflowSetup = this.cancelWorkflowSetup.bind(this);
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get coSellActionListMetadataPublicId() {
		return this.props.actionListMetadata.cosellActionListMetadataPublicId;
	}

	async init() {
		const [activeWorkflowResult, workflowsResult] = await Promise.all([
			CoSellEngineEndpoints.getActiveActionListWorkflow(this.coSellActionListMetadataPublicId),
			CoSellEngineEndpoints.getActionListWorkflows(this.coSellActionListMetadataPublicId)
		]);
		if (this.unmounted) return;
		const activeWorkflow = activeWorkflowResult.payload instanceof Array ? null : activeWorkflowResult.payload;
		const {workflows} = workflowsResult.payload;
		workflows.sort((a, b) => CoSellEngineHelperLive.workflowOrdinal[a.name] > CoSellEngineHelperLive.workflowOrdinal[b.name] ? 1 : -1);
		let activeWorkflowName = activeWorkflow?.sequenceDisplayName + " " + activeWorkflow?.displayName;
		this.setState({activeWorkflow: activeWorkflow, activeWorkflowName: activeWorkflowName, workflows: workflows, loading: false});
	}

	getWorkflowSequences(selectedWorkflow) {
		CoSellEngineEndpoints.getActionListWorkflowSequences(selectedWorkflow.recipePublicId)
		.then((result) => {
			if (this.unmounted) return;
			let {sequences} = result.payload;
			sequences.sort((a, b) => CoSellEngineHelperLive.sequenceOrdinal[a.name] > CoSellEngineHelperLive.sequenceOrdinal[b.name] ? 1 : -1);
			this.setState({sequences: sequences, selectedWorkflow: selectedWorkflow, loading: false});
			if (sequences.length === 1) {
				this.selectSequence(sequences[0]);
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getWorkflowSequences', error);
		});
	}

	async stopWorkflow() {
		this.setState({saving: true});
		let {activeWorkflow} = this.state;

		CoSellEngineEndpoints.stopWorkflow(activeWorkflow.instancePublicId)
		.then((result) => {
			if (this.unmounted) return;
			const newActiveWorkflow = {...activeWorkflow};
			newActiveWorkflow.status = CoSellEngineHelperLive.WORKFLOW_STATUS_STOPPED;
			this.setState({activeWorkflow: newActiveWorkflow, saving: false});
		})
		.catch((error) => {
			this.setState({saving: false});
			EnvHelper.serverError('Error from stopWorkflow', error);
		});
	}

	selectWorkflow(selectedWorkflow) {
		if (selectedWorkflow.isEnabled) {
			this.getWorkflowSequences(selectedWorkflow);
		}
		else {
			this.setState({selectedWorkflow: selectedWorkflow, selectedSequence: null, loading: false});
		}
	}

	selectSequence(selectedSequence) {
		this.setState({selectedSequence: selectedSequence});
	}

	openWorkflowEditor() {
		let {selectedSequence} = this.state;
		EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.WORKFLOW_WIZARD.PATH(this.coSellActionListMetadataPublicId, selectedSequence?.sequenceRecipePublicId));
	}

	cancelWorkflowSetup() {
		this.setState({selectedWorkflow: null, selectedSequence: null});
	}

	renderWorkflowControls() {
		let {actionListMetadata} = this.props;
		if (!actionListMetadata) return;
		let {saving, workflows, selectedWorkflow, sequences, selectedSequence} = this.state;
		let isSelectionReady = selectedWorkflow && selectedSequence;
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				padding: 10,
				gap: 10,
				borderRadius: 5,
				backgroundColor: partnerTapWhite
			}}>
				{saving && <ScrimMessage message={'Saving changes...'}/>}
				<DropdownMenu title={'Workflow'}
							  width={200}
							  options={workflows || [{label: 'Loading Workflows'}]}
							  optionLabel={'displayName'}
							  selectedOption={selectedWorkflow}
							  disabled={!workflows}
							  onSelect={this.selectWorkflow}/>
				<DropdownMenu title={'Sequence'}
							  width={400}
							  options={sequences || [{label: 'Workflow Required'}]}
							  optionLabel={'displayName'}
							  selectedOption={selectedSequence}
							  disabled={!sequences || !selectedWorkflow?.isEnabled}
							  onSelect={this.selectSequence}/>

				<PrimaryButton label={'START'} disabled={!isSelectionReady} onClick={this.openWorkflowEditor}/>

			</div>
		);
	}

	renderActiveWorkflow() {
		let {activeWorkflow, activeWorkflowName, saving} = this.state;
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				padding: 10,
				gap: 10,
				borderRadius: 5,
				backgroundColor: partnerTapWhite
			}}>
				{saving && <ScrimMessage message={'Saving changes...'}/>}
				<ActiveWorkflowName workflowName={activeWorkflowName}/>
				{(activeWorkflow?.status === CoSellEngineHelperLive.WORKFLOW_STATUS_RUNNING) &&
				 <PrimaryButton label={'STOP'} disabled={saving} onClick={() => {
					 if (activeWorkflow?.isEnabled) {
						 this.setState({showStopWorkflowDialog: true});
					 }
					 else {
						 this.setState({showNotPermittedDialog: true});
					 }
				 }}/>}

				{(activeWorkflow?.status === CoSellEngineHelperLive.WORKFLOW_STATUS_STOPPED) &&
				 <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 16, fontWeight: 'bold', color: partnerTapAlert}}>
					 <Dangerous/> Workflow stopped
				 </div>}
			</div>
		);
	}

	renderDialogs() {
		let {showStopWorkflowDialog, showNotPermittedDialog} = this.state;
		return (
			<Fragment>
				{showStopWorkflowDialog &&
				 <Dialog title={'Stop Workflow?'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
								 <div style={{fontSize: 16, fontWeight: 'bold'}}>
									 Are you sure you want to stop the workflow for this action list?
								 </div>
								 <div>
									 Stopping will immediately suspend all workflow automation.
								 </div>
								 <div>
									 Workflow participants will not be able to take any further action.
								 </div>
								 <div>
									 Please type STOP to confirm.
								 </div>
								 <div>
									 <TextInputBox isSimple={true} value={this.state.stopText || ''} onChange={(value) => this.setState({stopText: value})}/>
								 </div>
							 </div>}
						 yesDisable={this.state.stopText !== 'STOP'}
						 yesLabel={'STOP NOW'}
						 yesAction={() => {
							 this.setState({showStopWorkflowDialog: false});
							 this.stopWorkflow();
						 }}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showStopWorkflowDialog: false})}/>}
				{showNotPermittedDialog &&
				 <Dialog title={'Action not permitted'}
						 message={
							 <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
								 <div style={{fontSize: 16, fontWeight: 'bold'}}>
									 You do not have access to this feature. Please reach out to your PartnerTap administrator.
								 </div>
							 </div>}
						 yesLabel={'OK'}
						 yesAction={() => {
							 this.setState({showNotPermittedDialog: false});
						 }}
				 />}
			</Fragment>
		);
	}

	render() {
		let {loading, activeWorkflow, activeWorkflowName} = this.state;
		if (loading) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
					<Loading>Loading Action List Workflow...</Loading>
				</div>
			);
		}
		return (
			<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
				{activeWorkflow && activeWorkflowName ?
					this.renderActiveWorkflow() :
					this.renderWorkflowControls()}
				{this.renderDialogs()}
			</div>
		);
	}
}

CoSellActionListWorkflowSelector.propTypes = {
	actionListMetadata: PropTypes.object.isRequired
};

export default withRouter(CoSellActionListWorkflowSelector);
