import PropTypes from 'prop-types';
import React, {Component} from 'react';
import StringHelper from '../helpers/StringHelper';
import {partnerTapDefaultText, partnerTapPrimary, partnerTapWhite} from '../styles/partnertap_theme';

class ProgressBar extends Component {
	render() {
		let {title, truncateTitle, progressMessage, onlyRenderProgressBar, totalProcessedRows, progressPercentage, noImportedFilesLabel} = this.props;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: onlyRenderProgressBar ? 5 : 10, minWidth: 200}}>
				<div>
					{truncateTitle ? StringHelper.truncateWithToolTip(title, 32) : title}
				</div>
				{!noImportedFilesLabel && <div>
					Imported {totalProcessedRows} record{totalProcessedRows !== 1 && 's'}
				</div>}
				<div style={{
					borderRadius: 10,
					margin: '0 auto',
					width: '100%',
					height: 16,
					backgroundColor: partnerTapWhite,
					border: '1px solid ' + partnerTapDefaultText,
					display: 'flex',
					alignItems: 'center'
				}}>
					<div style={{backgroundColor: partnerTapPrimary, width: progressPercentage + '%', minWidth: 16, borderRadius: 9, height: '100%'}}/>
				</div>
				{progressMessage}
			</div>
		);
	}
}

ProgressBar.propTypes = {
	title: PropTypes.string,
	truncateTitle: PropTypes.bool,
	progressMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onlyRenderProgressBar: PropTypes.bool,
	totalProcessedRows: PropTypes.number,
	progressPercentage: PropTypes.number,
	noImportedFilesLabel: PropTypes.bool
};

export default ProgressBar;
