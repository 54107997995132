import React from 'react';
import * as AdminEndpoints from '../endpoints/AdminEndpoints';
import * as ProfilesEndpoints from '../endpoints/ProfilesEndpoints';
import * as ProfileEndpoints from '../endpoints/ProfilesEndpoints';
import * as TrackingEndpoints from '../endpoints/TrackingEndpoints';
import {DEMO, HUBSPOT, MSFT, OKTA, OKTA_REACTIVATE, OKTA_REGISTER, SAML, SFDC, SFDC_SANDBOX} from '../globals/Enums';
import {Routes} from '../globals/Routes';
import {CODE_VERIFIER_KEY, LOGIN_SIGN_UP_TYPE_KEY, LOGIN_USERNAME_KEY, REDIRECT_URI_KEY} from '../globals/StorageKeys';
import * as ApiHelper from '../helpers/ApiHelper';
import * as AuthActions from '../redux/AuthActions';
import * as NotificationActions from '../redux/NotificationActions';
import HubSpotButton from '../ui/buttons/HubSpotButton';
import MicrosoftButton from '../ui/buttons/MicrosoftButton';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import SalesforceButton from '../ui/buttons/SalesforceButton';
import EnvHelper from './EnvHelper';
import FeatureHelper, {PRODUCT_CHANNEL_ECOSYSTEM} from './FeatureHelper';
import PartnerStatusHelper from './PartnerStatusHelper';
import PersistenceHelper from './PersistenceHelper';
import DataManagerHelper from './DataManagerHelper';

const REDIRECT_PATH_SIGNUP = 'signup';
const REDIRECT_PATH_PERMISSION = 'permission';

export const DYNAMICS_CONSENT_TYPE_CRM = 'crm';
const DYNAMICS_SCOPE_CRM = ['openid', 'email', 'profile', 'offline_access'];

export const DYNAMICS_CONSENT_TYPE_DISCOVER = 'discover';
const DYNAMICS_SCOPE_DISCOVER_ORGS = ['openid', 'https://globaldisco.crm.dynamics.com/.default'];

const HUBSPOT_GRANULAR_SCOPES = [
	'oauth',
	'crm.schemas.companies.read',
	'crm.objects.companies.write',
	'crm.objects.companies.read',
	'crm.schemas.deals.read',
	'crm.objects.deals.read',
	'crm.objects.deals.write',
	'crm.objects.owners.read'
];

/* ------------------------- */
// Important note: /#/signup route is deprecated but used for OAuth configurations in various OAuth providers as a valid redirects */
// Do not remove /signup/<signupType> routes from this file */
//
// Mobile Note
// You will find special handling for mobile in the .isCordova conditions.
// iOS/Android use an in-app browser to popup a window to load SSO interfaces like msft and sfdc.
// On native mobile we do not have an 'origin' as it were, so we must always use localhost as origin, and intercept the popup window redirect to gather the authcode.
// We then forward the authcode to our /oauth route handler.
/* ------------------------- */

export default class AuthHelper {

	static salesforcePermission(useSandbox = false) {
		this.salesforceLogin(null, useSandbox, REDIRECT_PATH_PERMISSION, Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH);
	}

	static salesforceLogin(email, useSandbox = false, redirectPath = REDIRECT_PATH_SIGNUP, cordovaRouteFunction = Routes.OAUTH.PATH) {
		let server = 'login.salesforce.com';
		let signUpType = SFDC;
		if (useSandbox) {
			signUpType = SFDC_SANDBOX;
			server = 'test.salesforce.com';
		}
		PersistenceHelper.setValue(LOGIN_SIGN_UP_TYPE_KEY, signUpType);
		let sfdcUrl = 'https://' + server + '/services/oauth2/authorize?client_id=' + EnvHelper.salesforceClientId +
					  (redirectPath === REDIRECT_PATH_PERMISSION ? '&prompt=login%20consent' : '') +
					  (email ? '&login_hint=' + encodeURIComponent(email) : '') +
					  '&response_type=code' +
					  '&redirect_uri=';
		if (EnvHelper.isCordova) {
			let redirectUri = 'http://localhost:3000/' + redirectPath + '/' + signUpType;
			PersistenceHelper.setValue(REDIRECT_URI_KEY, redirectUri, true);
			let ref = EnvHelper.cordova.InAppBrowser.open(sfdcUrl + encodeURIComponent(redirectUri), '_blank');
			ref.addEventListener('loadstart', (params) => {
				if (params.url.match(/^http:\/\/localhost/) !== null) {
					let code = EnvHelper.getParam('code', params.url);
					ref.close();
					EnvHelper.push(cordovaRouteFunction(signUpType, code));
				}
			});
		}
		else {
			let redirectUri = EnvHelper.webUrl + '/' + redirectPath + '/' + signUpType;
			PersistenceHelper.setValue(REDIRECT_URI_KEY, redirectUri, true);
			window.location = sfdcUrl + encodeURIComponent(redirectUri);
		}
	}

	static dynamicsPermission(codeChallenge, consentType, organizationUrl) {
		if (!this.dynamicsInitialized) {
			this.dynamicsInitialized = true;
			this.dynamicsConsentMap = {};
			this.dynamicsConsentMap[DYNAMICS_CONSENT_TYPE_CRM] = DYNAMICS_SCOPE_CRM;
			this.dynamicsConsentMap[DYNAMICS_CONSENT_TYPE_DISCOVER] = DYNAMICS_SCOPE_DISCOVER_ORGS;
		}
		let scope = this.dynamicsConsentMap[consentType];
		if (organizationUrl) {
			scope.push(organizationUrl + '/user_impersonation');
		}
		this.azureSSO(codeChallenge, scope, REDIRECT_PATH_PERMISSION, Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH, consentType);
	}

	static microsoftLogin(codeChallenge, codeVerifier) {
		PersistenceHelper.setValue(CODE_VERIFIER_KEY, codeVerifier, true);
		let scope = ['openid', 'email', 'profile', 'offline_access', 'https://graph.microsoft.com/User.Read'];
		this.azureSSO(codeChallenge, scope, REDIRECT_PATH_SIGNUP, Routes.OAUTH.PATH);
	}

	static azureSSO(codeChallenge, scope, redirectPath, cordovaRouteFunction, consentType) {
		let signUpType = MSFT;
		PersistenceHelper.setValue(LOGIN_SIGN_UP_TYPE_KEY, signUpType);
		let scopeParam = encodeURIComponent(scope.join(' '));
		let msftUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=' + EnvHelper.microsoftClientId +
					  (redirectPath === REDIRECT_PATH_PERMISSION ? '&prompt=consent' : '') +
					  (consentType ? '&state=' + consentType : '') +
					  '&response_type=code' +
					  '&response_mode=query' +
					  '&scope=' + scopeParam +
					  '&code_challenge=' + codeChallenge +
					  '&code_challenge_method=S256' +
					  '&nonce=mynonceisbetterthanyournonce' +
					  '&redirect_uri=';
		if (EnvHelper.isCordova) {
			let redirectUri = 'http://localhost:3000/' + redirectPath + '/' + signUpType;
			PersistenceHelper.setValue(REDIRECT_URI_KEY, redirectUri, true);
			let ref = EnvHelper.cordova.InAppBrowser.open(msftUrl + encodeURIComponent(redirectUri), '_blank');
			ref.addEventListener('loadstart', (params) => {
				if (params.url.match(/^http:\/\/localhost/) !== null) {
					let code = EnvHelper.getParam('code', params.url);
					ref.close();
					EnvHelper.push(cordovaRouteFunction(signUpType, code));
				}
			});
		}
		else {
			let redirectUri = EnvHelper.webUrl + '/' + redirectPath + '/' + signUpType;
			PersistenceHelper.setValue(REDIRECT_URI_KEY, redirectUri, true);
			window.location = msftUrl + encodeURIComponent(redirectUri);
		}
	}

	static hubSpotPermission() {
		this.hubSpotLogin(REDIRECT_PATH_PERMISSION, Routes.CHANNEL_ECOSYSTEM.DATA_MANAGER.WIZARD.STEP_1.PATH);
	}

	static hubSpotLogin(redirectPath = REDIRECT_PATH_SIGNUP, cordovaRouteFunction = Routes.OAUTH.PATH) {
		let signUpType = HUBSPOT;
		PersistenceHelper.setValue(LOGIN_SIGN_UP_TYPE_KEY, signUpType);
		let hubspotUrl = 'https://app.hubspot.com/oauth/authorize?client_id=' + EnvHelper.hubspotClientId +
						 '&scope=' + HUBSPOT_GRANULAR_SCOPES.join('%20') +
						 '&redirect_uri=';
		if (EnvHelper.isCordova) {
			let redirectUri = 'http://localhost:3000/' + redirectPath + '/' + signUpType;
			PersistenceHelper.setValue(REDIRECT_URI_KEY, redirectUri, true);
			let ref = EnvHelper.cordova.InAppBrowser.open(hubspotUrl + encodeURIComponent(redirectUri), '_blank');
			ref.addEventListener('loadstart', (params) => {
				if (params.url.match(/^http:\/\/localhost/) !== null) {
					let code = EnvHelper.getParam('code', params.url);
					ref.close();
					EnvHelper.push(cordovaRouteFunction(signUpType, code));
				}
			});
		}
		else {
			let redirectUri = EnvHelper.webUrl + '/' + redirectPath + '/' + signUpType;
			PersistenceHelper.setValue(REDIRECT_URI_KEY, redirectUri, true);
			window.location = hubspotUrl + encodeURIComponent(redirectUri);
		}
	}

	static oktaLogin(email) {
		let signUpType = OKTA;
		PersistenceHelper.setValue(LOGIN_SIGN_UP_TYPE_KEY, signUpType);
		if (EnvHelper.isCordova) {
			let ref = EnvHelper.cordova.InAppBrowser.open(
				'https://auth.partnertap.com/oauth2/default/v1/authorize?client_id=' + EnvHelper.oktaClientId +
				'&login_hint=' + encodeURIComponent(email) +
				'&redirect_uri=http://localhost:3000/signup&response_type=token&scope=openid&nonce=mynonceisbetterthanyournonce&state=none', '_blank');
			ref.addEventListener('loadstart', (params) => {
				if (params.url.match(/^http:\/\/localhost/) !== null) {
					const authHash = EnvHelper.getParam('access_token', params.url);
					ref.close();
					EnvHelper.push(Routes.OAUTH.PATH(signUpType, authHash));
				}
			});
		}
		else {
			EnvHelper.push(Routes.LOGIN.ROUTE + '/' + encodeURI(email));
		}
	}

	static demoLogin(email) {
		PersistenceHelper.setValue(LOGIN_USERNAME_KEY, email);
		PersistenceHelper.setValue(LOGIN_SIGN_UP_TYPE_KEY, DEMO);
		EnvHelper.push(Routes.OAUTH.ROUTE + '/' + DEMO);
	}

	static handleAfterLoginPerson(person) {
		if (!person || person.code === 400) {
			throw new Error('handleAfterLoginPerson Error');
		}
		this.person = person;
		DataManagerHelper.crmTypeSynced = person.crmType;
		console.info('Person:', person);
		EnvHelper.isSpoofing = Boolean(person.spoofingViewSubordinateReportsPersonId || person.spoofingSuperadminPersonId);
		EnvHelper.wasSpoofing = person.restoredSpoof;
		FeatureHelper.roles = person.roles;
		FeatureHelper.products = person.products;
		FeatureHelper.features = person.features;
		ApiHelper.setAuthToken(person.securityToken);
		TrackingEndpoints.trackLogin(person);
		EnvHelper.dispatch(AuthActions.loginPerson(person));
		return ProfilesEndpoints.fetchProfile(person.id);
	}

	static handleAfterLoginProfile(profile) {
		if (!profile) {
			throw new Error('handleAfterLoginProfile Error');
		}
		this.profile = profile;
		console.info('Profile:', profile);
		PersistenceHelper.init(profile);
		PartnerStatusHelper.processInviterProfile();
		EnvHelper.dispatch(AuthActions.loginProfile(profile));
		return ProfileEndpoints.getWizardData();
	}

	static handleAfterChannelWizardSteps(channelWizardSteps) {
		EnvHelper.dispatch(AuthActions.channelWizardSteps(channelWizardSteps));
	}

	static spoofUser(spoofPersonId) {
		if (!this.spoofPath) this.spoofPath = {};
		this.spoofPath[this.person.id] = EnvHelper.path;
		AdminEndpoints.spoofUser(spoofPersonId)
		.then((result) => {
			PersistenceHelper.clearClient();
			EnvHelper.dispatch(NotificationActions.updatePartnerRequestCount(0));
			return AuthHelper.handleAfterLoginPerson(result.payload);
		})
		.then((result) => {
			return AuthHelper.handleAfterLoginProfile(result.payload);
		})
		.then((result) => {
			AuthHelper.handleAfterChannelWizardSteps(result.payload);
			EnvHelper.push(Routes.HOME.ROUTE);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from spoofUser ' + spoofPersonId, error);
		});
	}

	static restoreSpoofingAdmin(restorePersonId, callback) {
		AdminEndpoints.clearSpoof(restorePersonId)
		.then((result) => {
			PersistenceHelper.clearClient();
			return AuthHelper.handleAfterLoginPerson(result.payload);
		})
		.then((result) => {
			return AuthHelper.handleAfterLoginProfile(result.payload);
		})
		.then((result) => {
			AuthHelper.handleAfterChannelWizardSteps(result.payload);
			EnvHelper.push(this.spoofPath[restorePersonId]);
			if (callback) callback();
		})
		.catch((error) => {
			EnvHelper.serverError('Error from restoreSpoofingAdmin ' + restorePersonId, error);
		});
	}

	static get userPerson() {
		return this.person;
	}

	static get userProfile() {
		return this.profile;
	}

	static getSSOButton(signUpType, redirect) {
		switch (signUpType) {
			case SFDC:
				return <SalesforceButton showTerms={true} label={'Sign In with Salesforce'}/>;
			case SFDC_SANDBOX:
				return <SalesforceButton showTerms={true} useSandbox={true} label={'Sign In with Salesforce Sandbox'}/>;
			case MSFT:
				return <MicrosoftButton showTerms={true} label={'Sign In with Microsoft'}/>;
			case HUBSPOT:
				return <HubSpotButton showTerms={true} label={'Sign In using Hubspot'}/>;
			case OKTA_REACTIVATE:
				return <PrimaryButton label={'GO TO SIGN IN'} onClick={() => EnvHelper.push(Routes.HOME.ROUTE)}/>;
			case OKTA_REGISTER:
				return <PrimaryButton label={'REGISTER'} onClick={() => {
					EnvHelper.push(Routes.REGISTRATION.PATH(PRODUCT_CHANNEL_ECOSYSTEM, Routes.REGISTRATION.PROFILE.PAGE_NAME));
				}}/>;
			case OKTA:
				return <PrimaryButton label={'SIGN IN'} onClick={() => {
					EnvHelper.push(Routes.LOGIN.ROUTE + '/');
				}}/>;
			case SAML:
				return <PrimaryButton label={'SIGN IN'} onClick={() => {
					location.href = redirect;
				}}/>;
		}
	}

	static getDisplayString(stringKey) {
		const authStrings = {
			INVALID_SSO_FOR_ORG: 'Your account already exists. Use the buttons below to sign in or register.',
			OKTA_REACTIVATE: 'Email confirmation has been sent to your email. Follow the instructions in the email to finish your registration.'
		};
		return authStrings[stringKey] || '';
	}
}
