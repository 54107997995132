import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapPrimary} from '../../styles/partnertap_theme';
import Pic from '../../ui/Pic';

class RepHeader extends Component {

	render() {
		return (
			<div style={{display: 'flex', alignItems: 'center', paddingTop: 10, paddingLeft: 10}}>
				<Pic personId={this.props.personId} picSize={40}/>
				<div style={{fontSize: 24, paddingLeft: 10, color: partnerTapPrimary}}>{this.props.name}</div>
			</div>
		);
	}
}

RepHeader.propTypes = {
	personId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
};

export default RepHeader;
