import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {Component, Suspense} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import IntelPage from '../app/shared/IntelPage';
import * as AuthEndpoints from '../endpoints/AuthEndpoints';
import * as PartnersEndpoints from '../endpoints/PartnersEndpoints';
import * as ProfilesEndpoints from '../endpoints/ProfilesEndpoints';
import {Routes} from '../globals/Routes';
import {LAST_ROUTE_KEY, LOGIN_TOKEN_KEY, LOGIN_USERNAME_KEY} from '../globals/StorageKeys';
import * as Api from '../helpers/ApiHelper';
import {GET_CANCELLED_BY_BROWSER} from '../helpers/ApiHelper';
import AuthHelper from '../helpers/AuthHelper';
import EnvHelper from '../helpers/EnvHelper';
import FeatureHelper, {
	FEATURE_ADMIN_TOOLS_EDITORS,
	FEATURE_CHANNEL_ECOSYSTEM_ACCESS,
	FEATURE_MANAGER_ANALYTICS_ACCESS,
	FEATURE_SALES_NETWORK_ACCESS
} from '../helpers/FeatureHelper';
import InviteHelper from '../helpers/InviteHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';
import * as AuthActions from '../redux/AuthActions';
import {partnerTapPrimary, partnerTapSecondary} from '../styles/partnertap_theme';
import Dialog from '../ui/Dialog';
import ErrorMessageDialog from '../ui/messages/ErrorMessageDialog';
import LogoMessage from '../ui/messages/LogoMessage';
import ScrimMessage from '../ui/messages/ScrimMessage';
import TermsOfServiceDialog from '../ui/TermsOfServiceDialog';
import AppErrorBoundary from './AppErrorBoundary';
import CoSellEmailAccessPage from './channel_ecosystem/co_sell_engine/CoSellEmailAccessPage';
import CoSellEmailLandingPageLive from './channel_ecosystem/co_sell_engine/CoSellEmailLandingPageLive';
import OAuthPage from './login/OAuthPage';
import SamlPage from './login/SamlPage';
import {QueryClient, QueryClientProvider} from "react-query";

const SalesNetworkProduct = React.lazy(() => import('app/sales_network/SalesNetworkProduct'));
const ManagerAnalyticsProduct = React.lazy(() => import('app/manager_analytics/ManagerAnalyticsProduct'));
const ChannelEcosystemProduct = React.lazy(() => import('app/channel_ecosystem/ChannelEcosystemProduct'));
const AdminProduct = React.lazy(() => import('app/admin_tools/AdminToolsProduct'));
const LoginPage = React.lazy(() => import('app/login/LoginPage'));
const SignUpPage = React.lazy(() => import('app/login/SignUpPage'));
const OktaSignInWidget = React.lazy(() => import('app/login/OktaSignInWidget'));
const DemoLoginPage = React.lazy(() => import('app/login/DemoLoginPage'));
const VerificationPage = React.lazy(() => import('app/login/VerificationPage'));
const ProfilePage = React.lazy(() => import('app/profile/ProfilePage'));
const SettingsPage = React.lazy(() => import('app/shared/SettingsPage'));

export const MIN_APP_HEIGHT = 400;
export const FEEDBACK_UI_ELEMENT_TIMEOUT = 6000;
const BREAK_POINT_DESKTOP_WIDTH = 735;

const themeApp = createTheme({
	typography: {
		fontFamily: 'open_sansregular, Helvetica Neue, Helvetica, Arial, sans-serif'
	},
	palette: {
		primary: {main: partnerTapPrimary},
		secondary: {main: partnerTapSecondary},
		tonalOffset: 0.1
	}
});

export const queryClient = new QueryClient();


class App extends Component {

	constructor(props, context) {
		super(props, context);

		this.lastRoute = PersistenceHelper.getValue(LAST_ROUTE_KEY);
		this.loginUserName = PersistenceHelper.getValue(LOGIN_USERNAME_KEY);

		this.state = {
			isMountingWithToken: Boolean(PersistenceHelper.getValue(LOGIN_TOKEN_KEY)),
			showVersionDialog: false,
			showTermsDialog: false,
			showConnectWithPartnerDialog: false,
			siteDownTimeData: null
		};

		this.checkReleaseVersion = this.checkReleaseVersion.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.goToLogin = this.goToLogin.bind(this);
		this.updateTermsOfService = this.updateTermsOfService.bind(this);
		this.handleJoinMeUri = this.handleJoinMeUri.bind(this);

		window.addEventListener('resize', this.handleResize);
	}

	componentDidMount() {
		this.calculateSize();
		EnvHelper.initHistory(this.props.history);
		EnvHelper.initDispatch(this.props.dispatch);
		PersistenceHelper.storeReferringEmailId();
		PersistenceHelper.storeInitialLocation(this.props.location.pathname);

		if (!EnvHelper.quietMode) {
			setTimeout(this.checkReleaseVersion, 5000);
			this.interval = setInterval(this.checkReleaseVersion, 30000);
		}
		else {
			console.info('QUIET MODE! Release version polling disabled');
		}

		Api.sendGet('/configs/configs')
		.then((result) => {
			if (this.unmounted || result === 'error') return;
			if (result.payload) {
				EnvHelper.profileImageUri = result.payload.profileImageUri;
				EnvHelper.logoImageUri = result.payload.logoImageUri;
				EnvHelper.orgLogoImageUri = result.payload.orgLogoImageUri;
				EnvHelper.setCrmClientIds(result.payload);
			}
			else if (result === GET_CANCELLED_BY_BROWSER) {
				console.error('Fetch cancelled - ignore /configs.');
				return null;
			}
			else {
				throw new Error('Unable to retrieve server configuration.');
			}
		})
		.then(() => {
			if (this.unmounted) return;
			if (!this.isAuthenticated) {
				let storedToken = PersistenceHelper.getValue(LOGIN_TOKEN_KEY);
				if (storedToken) {
					EnvHelper.token = storedToken;
					AuthEndpoints.fetchLoginPerson()
					.then((result) => {
						if (this.unmounted) return;
						return AuthHelper.handleAfterLoginPerson(result.payload);
					})
					.then((result) => {
						if (this.unmounted) return;
						return AuthHelper.handleAfterLoginProfile(result.payload);
					})
					.then((result) => {
						if (this.unmounted) return;
						AuthHelper.handleAfterChannelWizardSteps(result.payload);
						this.setState({isMountingWithToken: false});
					})
					.catch((error) => {
						console.error('Error from fetchLoginPerson', error);
						this.goToLogin(true);
					});
				}
				else if (this.currentRouteRequiresAuth) {
					this.goToLogin(true);
				}
			}
		})
		.catch((error) => {
			EnvHelper.reportError('Error during login', error.message);
			this.goToLogin();
		});
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !nextState.isMountingWithToken || nextProps.authState.error !== this.props.authState.error;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let isSettings = this.props.location.pathname.indexOf('settings') > -1;
		let isEmailAccess = this.props.location.pathname.indexOf('email-access') > -1;
		let {profile, person} = this.props.authState;
		if (this.isAuthenticated) {
			EnvHelper.token = PersistenceHelper.getValue(LOGIN_TOKEN_KEY);
			if (this.currentRouteRequiresAuth && !EnvHelper.isSpoofing && !EnvHelper.wasSpoofing) {
				setTimeout(() => {
					if (!profile.tosComplete && !this.state.showTermsDialog) {
						this.setState({showTermsDialog: true});
					}
				});
			}

			if (typeof window.rg4js == 'function') {
				window.rg4js('setUser', {
					identifier: this.props.authState.person.id,
					isAnonymous: false,
					email: this.props.authState.person.userName,
					firstName: this.props.authState.profile.firstName,
					fullName: this.props.authState.profile.firstName + ' ' + this.props.authState.profile.lastName
				});
				window.rg4js('setVersion', EnvHelper.version);
			}
		}

		if (this.isAuthenticated && !this.currentRouteRequiresAuth) {
			if (!isSettings && !isEmailAccess) {
				this.handleJoinMeUri();
				let deepLink = PersistenceHelper.deepLink;
				if (deepLink) {
					PersistenceHelper.clearDeepLink();
					EnvHelper.push(deepLink);
				}
				else if (this.currentRouteAuthOptional) {
					// render current path
				}
				else if (this.lastRouteAllowed && (!this.loginUserName || this.loginUserName.toLowerCase() === person.userName.toLowerCase())) {
					// when spoofing, make sure we aren't on a partner-specific page, because current user may not be a partner of the spoofed user
					if ((EnvHelper.isSpoofing || EnvHelper.wasSpoofing) &&
						this.lastRoute.indexOf(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE) === 0) {
						this.lastRoute = Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE;
					}

					if (this.props.location.pathname !== this.lastRoute) {
						EnvHelper.push(this.lastRoute);
					}
				}
				else if (person.primaryProduct) {
					EnvHelper.push(Routes.HOME.PATH(this.props.authState));
				}
				else if (this.props.location.pathname !== Routes.HOME.ROUTE) {
					EnvHelper.push(Routes.HOME.ROUTE);
				}
			}
		}
		else if (!this.isAuthenticated && this.currentRouteRequiresAuth) {
			PersistenceHelper.clearClient();
			PersistenceHelper.removeValue(LAST_ROUTE_KEY);
			PersistenceHelper.storeDeepLink();
			this.lastRoute = null;
			this.goToLogin(true);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		clearInterval(this.interval);
		this.unmounted = true;
	}

	handleJoinMeUri() {
		InviteHelper.getJoinMeUriOrgAndUser(this.props.location.pathname, (inviterInfo) => {
			if (inviterInfo) {
				this.setState({...inviterInfo, showConnectWithPartnerDialog: inviterInfo.inviterPersonId !== this.props.authState.person.id});
			}
		});
	}

	createPartnership(connectWithPartner) {
		this.setState({showConnectWithPartnerDialog: false, creatingPartnership: true});
		let {inviterPersonId, productCode} = this.state;
		PartnersEndpoints.createPartner(inviterPersonId, productCode, connectWithPartner)
		.then((result) => {
			this.setState({creatingPartnership: false});
			EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.PARTNERS.ROUTE + '?reload');
		});
	}

	handleResize() {
		if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout(() => {
			if (this.unmounted) return;
			this.calculateSize(true);
		}, 200);
	}

	calculateSize(fromResize = false) {
		let isDesktop = window.innerWidth >= BREAK_POINT_DESKTOP_WIDTH;
		if (isDesktop !== EnvHelper.isDesktop) {
			EnvHelper.isDesktop = isDesktop;
			if (fromResize) this.forceUpdate();
		}
	}

	checkReleaseVersion() {
		if (this.unmounted) return;
		EnvHelper.getReleaseVersion()
		.then((result) => {
			if (this.unmounted) return;
			let releaseVersion = result.payload;
			let storedReleaseVersion = PersistenceHelper.updateVersion;
			PersistenceHelper.storeUpdateVersion(releaseVersion);
			console.info('EnvHelper.version: ', EnvHelper.version, ' releaseVersion:', releaseVersion, ' storedReleaseVersion: ', storedReleaseVersion);
			if (releaseVersion && releaseVersion !== 'error' && EnvHelper.version !== releaseVersion && storedReleaseVersion !== releaseVersion) {
				this.setState({showVersionDialog: true});
				this.props.dispatch(AuthActions.newVersionAvailable(true));
			}
		})
		.catch((error) => {
			console.error('Error fetching version.txt', error);
		});
	}

	get isAuthenticated() {
		return this.havePersonAndProfile(this.props.authState);
	}

	havePersonAndProfile(authState) {
		return Boolean(authState.person && authState.profile);
	}

	get currentRouteRequiresAuth() {
		let path = this.props.location.pathname;
		return !this.currentRouteAuthOptional &&
			   path.indexOf('login') === -1 &&
			   path.indexOf('oauth') === -1 &&
			   path.indexOf('signup') === -1 &&
			   path.indexOf('access') === -1 &&
			   path.indexOf('settings') === -1 &&
			   path.indexOf('register') === -1 &&
			   path.indexOf('logout') === -1 &&
			   path.indexOf('sandbox') === -1 &&
			   path.indexOf('verify') === -1 &&
			   path.indexOf('saml') === -1 &&
			   path !== '/';
	}

	get currentRouteAuthOptional() {
		let path = this.props.location.pathname;
		return path.indexOf('workflow-landing') !== -1;
	}

	get lastRouteAllowed() {
		if (this.lastRoute && !this.currentRouteAuthOptional) {
			if (this.lastRoute.indexOf(Routes.ADMIN_TOOLS.ROUTE) === 0 && FeatureHelper.isFeatureEnabled(FEATURE_ADMIN_TOOLS_EDITORS)) return true;
			if (this.lastRoute.indexOf(Routes.CHANNEL_ECOSYSTEM.ROUTE) === 0 && FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_ECOSYSTEM_ACCESS)) return true;
			if (this.lastRoute.indexOf(Routes.MANAGER_ANALYTICS.ROUTE) === 0 && FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ACCESS)) return true;
			if (this.lastRoute.indexOf(Routes.DASHBOARD.ROUTE) === 0 && FeatureHelper.isFeatureEnabled(FEATURE_SALES_NETWORK_ACCESS)) return true;
		}
		return false;
	}

	goToLogin(isExpired) {
		this.setState({isMountingWithToken: false, showTermsDialog: false});
		this.props.dispatch(AuthActions.logout());
		EnvHelper.push(isExpired ? Routes.LOGOUT.EXPIRED.ROUTE_TRUE : Routes.HOME.ROUTE);
	}

	updateTermsOfService() {
		ProfilesEndpoints.updateProfileTosComplete(this.props.authState.profile.id)
		.then((result) => {
			this.props.dispatch(AuthActions.agreeToTerms());
			this.setState({showTermsDialog: false});
		})
		.catch((e) => {
			console.error('Error from updateTermsOfService', e);
			this.goToLogin(true);
		});
	}

	render() {
		let {inviterName, orgName} = this.state;
		if (!this.isAuthenticated && (this.currentRouteRequiresAuth || this.state.isMountingWithToken)) {
			return <LogoMessage message={'Authenticating...'}/>;
		}

		// GTM tracking of every page
		window.dataLayer.push({event: 'pageview', page: {url: this.props.location.pathname}});

		if (this.currentRouteRequiresAuth) {
			this.lastRoute = this.props.location.pathname;
			PersistenceHelper.setValue(LAST_ROUTE_KEY, this.lastRoute);
		}

		let dialog = '';
		if (this.props.authState.errorMessage) {
			console.error('Error in AuthState', this.props.authState);
			dialog = <ErrorMessageDialog onGoToLogin={this.goToLogin}/>;
		}
		else if (this.state.showTermsDialog) {
			dialog = <TermsOfServiceDialog onUserAgreementDialogYes={this.updateTermsOfService} onUserAgreementDialogNo={() => this.goToLogin(false)}/>;
		}
		else if (this.state.showVersionDialog) {
			dialog = <Dialog title={'Software Update Ready!'}
							 message={
								 <div>
									 It's time to update your PartnerTap software.<br/>
									 It will only take a few seconds...
								 </div>
							 }
							 yesAction={() => window.location.reload()}
							 forceAction={true}/>;
		}
		else if (this.state.showConnectWithPartnerDialog) {
			dialog = <Dialog title={'Connect with Partner'}
							 message={
								 <div>
									 Connect with <em>{inviterName ? inviterName + ' from ' : ''}{orgName}</em> on PartnerTap
								 </div>
							 }
							 yesAction={() => {
								 this.createPartnership(true);
							 }}
							 noAction={() => {
								 this.createPartnership(false);
							 }}/>;
		}
		return (
			<QueryClientProvider client={queryClient}>
				<AppErrorBoundary key={this.props.authState.appRefreshKey}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={themeApp}>
							<div style={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								minHeight: MIN_APP_HEIGHT,
								overflow: 'hidden'
							}}>
								<Suspense fallback={<LogoMessage message={'Initializing...'}/>}>
									<Switch>
										<Route exact path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.EMAIL_ACCESS.ROUTE} component={CoSellEmailAccessPage}/>
										<Route path={Routes.CHANNEL_ECOSYSTEM.CO_SELL_ENGINE.WORKFLOW_LANDING_LIVE.ROUTE}
											   component={CoSellEmailLandingPageLive}/>
										<Route exact path={Routes.DEMO_LOGIN.ROUTE} component={DemoLoginPage}/>
										<Route exact path={Routes.SANDBOX_HOME.ROUTE} render={() => <LoginPage sandbox={true}/>}/>
										<Route exact path={Routes.HOME.ROUTE} component={LoginPage}/>
										<Route exact path={Routes.LOGOUT.ROUTE} component={LoginPage}/>
										<Route exact path={Routes.LOGOUT.EXPIRED.ROUTE} component={LoginPage}/>
										<Route path={Routes.REGISTRATION.ROUTE} component={SignUpPage}/>
										<Route exact path={Routes.LOGIN.USERNAME.ROUTE} component={OktaSignInWidget}/>
										<Route path={Routes.LOGIN.ROUTE} component={OktaSignInWidget}/>
										<Route path={Routes.OAUTH.AUTHENTICATE.ROUTE} component={OAuthPage}/>
										<Route path={Routes.SAML.ROUTE} component={SamlPage}/>
										<Route path={Routes.PROFILE.ROUTE} component={ProfilePage}/>
										<Route path={Routes.SETTINGS.ROUTE} component={SettingsPage}/>
										<Route path={Routes.INTEL_ALL_PRODUCTS.ROUTE} component={IntelPage}/>
										<Route path={Routes.DASHBOARD.ROUTE} component={SalesNetworkProduct}/>
										<Route path={Routes.CHANNEL_ECOSYSTEM.ROUTE} component={ChannelEcosystemProduct}/>
										<Route path={Routes.MANAGER_ANALYTICS.ROUTE} component={ManagerAnalyticsProduct}/>
										<Route path={Routes.ADMIN_TOOLS.ROUTE} component={AdminProduct}/>
										<Route path={Routes.VERIFY_ACCOUNT.ROUTE} component={VerificationPage}/>
									</Switch>
								</Suspense>
								{dialog}
								{this.state.creatingPartnership &&
								 <ScrimMessage message={'Updating Partnership...'}/>}
							</div>
						</ThemeProvider>
					</StyledEngineProvider>
				</AppErrorBoundary>
			</QueryClientProvider>
		);
	}
}

App.propTypes = {
	children: PropTypes.element,
	location: PropTypes.object.isRequired,
	authState: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(App));
