import {Checkbox} from '@mui/material';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapInactive, partnerTapSecondary} from '../../styles/partnertap_theme';

class CheckboxButton extends Component {

	render() {
		let {checked, disabled, style, onChange, onClick, inputProps} = this.props;
		return <Checkbox checked={checked}
						 style={({color: disabled ? partnerTapInactive : partnerTapSecondary, ...style})}
						 disabled={disabled}
						 onChange={onChange}
						 onClick={onClick}
						 inputProps={inputProps}/>;
	}
}

CheckboxButton.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	style: PropTypes.object,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	inputProps: PropTypes.object
};

export default CheckboxButton;
