import {Email, FileCopyOutlined, InfoOutlined} from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import * as InviteEndpoints from '../endpoints/InviteEndpoints';
import {INVITE_NOT_SENT_REPEAT, INVITE_SENT} from '../globals/Enums';
import {Routes} from '../globals/Routes';
import AuthHelper from '../helpers/AuthHelper';
import EcosystemShareHelper from '../helpers/EcosystemShareHelper';
import EnvHelper from '../helpers/EnvHelper';
import {PRODUCT_SALES_NETWORK} from '../helpers/FeatureHelper';
import InviteHelper from '../helpers/InviteHelper';
import StringHelper from '../helpers/StringHelper';
import {partnerTapAppBackground, partnerTapSecondary, partnerTapStroke, partnerTapWhite} from '../styles/partnertap_theme';
import PrimaryButton from './buttons/PrimaryButton';
import Dialog from './Dialog';
import TextInputBox from './TextInputBox';
import ToolTipOverlay from './ToolTipOverlay';

export const EMAIL_LABEL_STYLE = {fontSize: 16, fontWeight: 'bold', padding: 5};

export const EMAIL_INVITE_NOTE = 'Hello,\n\n' +
								 'I use PartnerTap to securely share information about my customers and prospects with my partners.\n\n' +
								 'PartnerTap automates account mapping and helps us identify new sales opportunities within our mutual accounts. ' +
								 'You can sign up for a free edition and it’s easy to get started.\n\n' +
								 'Just click the link below to set up your account and accept my partner request.\n\n';

export const MEMBER_INVITE_NOTE = 'Glad to see you’re already on PartnerTap. ' +
								  'I’d like to share information about my customers and prospects with you to see where we have mutual accounts and new sales opportunities.\n\n' +
								  'Let’s plan a partner meeting soon…';

export class EmailInvite extends Component {

	static get emailContainerStyle() {
		let containerMargin = EnvHelper.isDesktop ? 10 : 0;
		return {
			background: partnerTapAppBackground,
			padding: 10,
			marginTop: 0,
			marginLeft: containerMargin,
			marginRight: containerMargin,
			marginBottom: containerMargin,
			borderRadius: containerMargin
		};
	}

	constructor(props, context) {
		super(props, context);

		this.state = {
			emailInput: '',
			noteInput: this.props.defaultNote ? this.props.defaultNote : '',
			shareOpps: true,
			statusMessage: '',
			error: false,
			copyLabel: 'COPY'
		};

		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeNote = this.onChangeNote.bind(this);
		this.onClickShareOpps = this.onClickShareOpps.bind(this);
		this.sendInvite = this.sendInvite.bind(this);
		this.dialogOk = this.dialogOk.bind(this);
	}

	componentDidMount() {
		this.storeEmailInput();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.productCode !== this.props.productCode) {
			setTimeout(() => {
				if (this.unmounted) return;
				this.setState({copyLabel: 'COPY'});
			});
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	storeEmailInput() {
		let emailInput = this.props.emailInput || this.props.match.params.personEmailId || null;
		if (emailInput) {
			let emailList = emailInput.split(',');
			if (StringHelper.validateEmails(emailList)) {
				this.setState({emailInput: emailInput});
			}
		}
	}

	onChangeEmail(value) {
		this.setState({emailInput: value, statusMessage: '', error: false});
	}

	onChangeNote(value) {
		this.setState({noteInput: value});
	}

	onClickShareOpps(event) {
		this.setState({shareOpps: !this.state.shareOpps});
	}

	sendInvite() {
		let emailList = this.formatList(this.state.emailInput);
		if (emailList) {
			let iCurrentUser = emailList.indexOf(AuthHelper.userProfile.emailAddress);
			if (iCurrentUser !== -1) {
				emailList.splice(iCurrentUser, 1);
			}
			if (emailList.length === 0) {
				let statusMessage = 'Sorry, you can\'t invite yourself.';
				this.setState({sending: false, statusMessage: statusMessage, error: true});
				return;
			}
		}

		if (StringHelper.validateEmails(emailList)) {
			let userNote = this.state.noteInput;
			let shareOpps = this.state.shareOpps;
			let productCode = this.props.productCode;
			this.setState({sending: true});
			this.forceUpdate();
			InviteEndpoints.addPartnerWithEmail(emailList, userNote, shareOpps, productCode, EcosystemShareHelper.inviteAccountShareOption, EcosystemShareHelper.inviteOpportunityShareOption)
			.then((result) => {
					if (this.unmounted) return;
					if (result && result.payload && result.payload.length === emailList.length) {
						this.setState({
							sending: false,
							emailInput: '',
							statusMessage:
								<div style={{display: 'inline-block', textAlign: 'left'}}>
									{this.parseResponse(result.payload, emailList)}
								</div>,
							error: false
						});

						if (productCode === PRODUCT_SALES_NETWORK) {
							this.storeInvite(result.payload, emailList);
						}
					}
					else {
						this.setState({
							sending: false,
							statusMessage: 'We are unable to send this invite. Please try again later.',
							error: true
						});
					}
				}
			);
		}
		else {
			let statusMessage = !emailList ? 'Please enter an email address in the text field above.' :
				(emailList.length === 1 ? 'Please enter a valid email address. Separate multiple addresses with a comma or semicolon.' : 'Please enter valid email addresses.');
			this.setState({sending: false, statusMessage: statusMessage, error: true});
		}
	}

	formatList(emailInput) {
		if (!emailInput) return null;
		emailInput = emailInput.replace(/ /g, ''); // trim white space
		emailInput = emailInput.replace(/[;]+/gm, ','); // replace semicolon with commas
		emailInput = emailInput.replace(/[,\s]+$/g, ''); // remove hanging commas
		// look for gmail/outlook formatting
		if (emailInput.indexOf('<') > -1) {
			let emailCopyPattern = /<([^>]+)>/igm;
			let matches = [];
			let match = emailCopyPattern.exec(emailInput);
			while (match != null) {
				let foundEmail = match[0].replace(/[<,>]/gim, '');
				matches.push(foundEmail);
				match = emailCopyPattern.exec(emailInput);
			}
			if (matches.length > 0) {
				return matches;
			}
		}
		return emailInput.split(',');
	}

	storeInvite(resultList, emailList) {
		for (let i = 0; i < resultList.length; i++) {
			let result = resultList[i];
			let email = emailList[i];
			if (result === INVITE_SENT || result === INVITE_NOT_SENT_REPEAT) {
				InviteHelper.storeInviteEmail(email);
			}
		}
	}

	parseResponse(resultList, emailList) {
		let messageList = [];
		for (let i = 0; i < resultList.length; i++) {
			let result = resultList[i];
			let email = emailList[i];
			let message = InviteHelper.getStatusMessage(result, email);
			messageList.push(<div key={i}>{message}</div>);
		}
		return messageList;
	}

	dialogOk() {
		this.setState({statusMessage: ''});
	}

	render() {
		let {profile, productCode} = this.props;
		let joinMeUri;
		if (profile) {
			joinMeUri = EnvHelper.webUrl + '/#' + Routes.REGISTRATION.PATH(productCode, Routes.REGISTRATION.PROFILE.PAGE_NAME, profile.orgId, profile.orgCode, profile.personId);
		}
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				{this.state.statusMessage &&
				 <Dialog title={'Email Status'} message={this.state.statusMessage} yesAction={this.dialogOk}/>}
				<div>
					<div style={{padding: 10, borderRadius: 10, backgroundColor: partnerTapWhite, border: '1px solid ' + partnerTapStroke}}>
						<div style={{display: 'flex', alignItems: 'center'}}>
							<div style={EMAIL_LABEL_STYLE}>
								Enter your partner email addresses
							</div>
							<div style={{flex: 1}}/>
							<ToolTipOverlay title={
								<div>
									<b>Separate lists with comma or semicolon:</b><br/>
									john.smith@acme.com, jane.doe@acme.com
									<div style={{padding: 5}}/>
									<b>Or copy/paste from Gmail or Outlook:</b><br/>
									Smith, John &lt;john.smith@acme.com&gt;; Doe, Jane &lt;jane.doe@acme.com&gt;;
									<div style={{padding: 5}}/>
									<b>You can have up to 100 outstanding invites at any time.</b><br/>
								</div>
							}>
								<InfoOutlined style={{color: partnerTapSecondary, paddingLeft: 4, cursor: 'pointer'}}/>
							</ToolTipOverlay>
						</div>
						<TextInputBox hintText={'Partner Email Addresses'}
									  value={this.state.emailInput}
									  onChange={this.onChangeEmail}
									  onEnter={this.sendInvite}
									  maxChars={2048}
									  isSimple={true}
									  disabled={EnvHelper.isSpoofing}/>
						<div style={{paddingTop: 10}}>
							<div style={EMAIL_LABEL_STYLE}>
								Edit your personal note (optional)
							</div>
							<TextInputBox hintText={'Personal Note'}
										  value={this.state.noteInput}
										  multiLine={true}
										  rows={13}
										  maxChars={2048}
										  isSimple={true}
										  onChange={this.onChangeNote}
										  disabled={EnvHelper.isSpoofing}/>
						</div>
						{productCode === PRODUCT_SALES_NETWORK &&
						 <div style={{paddingTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
							 <div style={{fontSize: 16, fontWeight: 'bold'}}>Share Opportunities</div>
							 <Switch checked={this.state.shareOpps} onChange={this.onClickShareOpps}/>
						 </div>}

						<div style={{paddingTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<PrimaryButton label={this.state.sending ? 'SENDING...' : 'SEND'}
										   icon={<Email/>}
										   onClick={this.sendInvite}
										   disabled={this.state.sending || EnvHelper.isSpoofing}/>
						</div>
					</div>
				</div>
				{joinMeUri &&
				 <div style={{marginTop: 10, padding: 10, borderRadius: 10, backgroundColor: partnerTapWhite, border: '1px solid ' + partnerTapStroke}}>
					 <div style={{display: 'flex', alignItems: 'center'}}>
						 <div style={EMAIL_LABEL_STYLE}>
							 Copy your personalized channel partner invitation link
						 </div>
						 <div style={{flex: 1}}/>
						 <ToolTipOverlay title={
							 <div>
								 Share this link wherever you'd like. The link will invite other PartnerTap users to join you!
							 </div>
						 }>
							 <InfoOutlined style={{color: partnerTapSecondary, paddingLeft: 4, cursor: 'pointer'}}/>
						 </ToolTipOverlay>
					 </div>
					 <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						 <TextInputBox value={joinMeUri} isSimple={true} onChange={() => {
							 // nothing to do
						 }}/>
						 <div style={{paddingTop: 10}}>
							 <PrimaryButton label={this.state.copyLabel}
											icon={<FileCopyOutlined/>}
											onClick={() => {
												navigator.clipboard.writeText(joinMeUri)
												.then(() => {
													if (this.unmounted) return;
													this.setState({copyLabel: 'COPIED'});
												})
												.catch((error) => {
													this.setState({copyLabel: 'SORRY, ERROR!'});
													console.error('Error writing to clipboard', error);
												});
											}}/>
						 </div>
					 </div>
				 </div>}
			</div>
		);
	}
}

EmailInvite.propTypes = {
	productCode: PropTypes.string.isRequired,
	defaultNote: PropTypes.string,
	profile: PropTypes.object,
	emailInput: PropTypes.string
};

export default withRouter(EmailInvite);
