import {ConnectWithoutContact, DriveFolderUpload, FollowTheSigns, ForwardToInbox} from '@mui/icons-material';

// co-sell workflow status
export const WORKFLOW_STATUS_NONE = 'NONE';
export const WORKFLOW_STATUS_STARTED = 'STARTED';
export const WORKFLOW_STATUS_CANCELED = 'CANCELED';

// co-sell workflows
export const WORKFLOW_NONE = 'NONE';
export const WORKFLOW_SHARE_ACTION_LIST = 'SHARE_ACTION_LIST';
export const WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL = 'DISTRIBUTE_RECORDS_INTERNAL';
export const WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL = 'DISTRIBUTE_RECORDS_EXTERNAL';
export const WORKFLOW_INTRO_EMAIL = 'INTRO_EMAIL';
export const WORKFLOW_CRM_TRACKING = 'CRM_TRACKING';

// share action list workflow sequences
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_NONE = 'NONE';
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_CONNECTED_PARTNER = 'SHARE_WITH_CONNECTED_PARTNER';
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_FELLOW_EMPLOYEE = 'SHARE_WITH_FELLOW_EMPLOYEE';
export const WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_EMAIL_ADDRESS = 'SHARE_WITH_EMAIL_ADDRESS';

// distribute action list records internal workflow sequences
export const WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_INTERNAL_NONE = 'NONE';
export const WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_INTERNAL = 'DISTRIBUTE_RECORDS_INTERNAL';

// distribute action list records workflow sequences
export const WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL_NONE = 'NONE';
export const WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL = 'DISTRIBUTE_RECORDS_EXTERNAL';

// email workflow sequences
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_NONE = 'NONE';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO = 'INTRO';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN = 'PARTNER_OPT_IN';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN = 'OWNER_OPT_IN';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN = 'PARTNER_THEN_OWNER_OPT_IN';
export const WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN = 'OWNER_THEN_PARTNER_OPT_IN';

export const INTRO_EMAIL_CRM_NONE = 'NONE';
export const INTRO_EMAIL_CRM_CO_SELL = 'INTRO_EMAIL_CRM_CO_SELL';
export const INTRO_EMAIL_CRM_NEW_OPP = 'INTRO_EMAIL_CRM_NEW_OPP';

// crm tracking workflow sequences
export const WORKFLOW_SEQUENCE_CRM_TRACKING_NONE = 'NONE';
export const WORKFLOW_SEQUENCE_CRM_TRACKING_LOGO = 'LOGO';
export const WORKFLOW_SEQUENCE_CRM_TRACKING_EXPANSION = 'EXPANSION';
export const WORKFLOW_SEQUENCE_CRM_TRACKING_MATCHED = 'MATCHED';

export const workflows = [
	{
		config: WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL,
		sequence: WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_INTERNAL,
		name: 'Sales Play',
		description: 'Distribute accounts and opps to sales reps',
		MaterialIcon: ForwardToInbox,
		chooseSequenceHeader: 'Your email will contain a secure link to your Action List'
	},
	{
		config: WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL,
		sequence: WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL,
		name: 'Thru-Channel Play',
		description: 'Distribute accounts and opps to partners',
		MaterialIcon: FollowTheSigns,
		chooseSequenceHeader: 'Your email will contain a secure link to your Action List'
	},
	{
		config: WORKFLOW_INTRO_EMAIL,
		name: 'Co-Sell Play',
		description: 'Send introduction emails to sales reps and partners',
		MaterialIcon: ConnectWithoutContact,
		chooseSequenceHeader: 'You can select an immediate introduction or let one or both parties opt in before the introduction'
	},
	{
		config: WORKFLOW_CRM_TRACKING,
		name: 'CRM Tracking',
		description: 'Automatically track sales and co-sell activity in your CRM',
		MaterialIcon: DriveFolderUpload,
		chooseSequenceHeader: 'Your selected sequence will be executed upon confirmation'
	}
];

export const nudgeOptions = [
	{label: 'None', value: 0},
	{label: '3 days', value: 3},
	{label: '4 days', value: 4},
	{label: '5 days', value: 5},
	{label: '6 days', value: 6},
	{label: '7 days', value: 7},
	{label: '8 days', value: 8},
	{label: '9 days', value: 9},
	{label: '10 days', value: 10}
];

export const crmTrackingOptions = [
	{label: 'None', value: INTRO_EMAIL_CRM_NONE},
	{label: 'Co-sell introduction', value: INTRO_EMAIL_CRM_CO_SELL},
	{label: 'New opp', value: INTRO_EMAIL_CRM_NEW_OPP}
];

export const sequenceShortName = {
	[WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_CONNECTED_PARTNER]: 'Connected Partner',
	[WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_FELLOW_EMPLOYEE]: 'Fellow Employee',
	[WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_EMAIL_ADDRESS]: 'Email Address',

	[WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_INTERNAL]: 'Sales Rep Distribution',

	[WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL]: 'Partner Distribution',

	[WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO]: 'Sales Rep + Partner Intro',
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN]: 'Sales Rep > Intro',
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN]: 'Partner Rep > Intro',
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN]: 'Sales Rep > Partner > Intro',
	[WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN]: 'Partner > Sales Rep > Intro',

	[WORKFLOW_SEQUENCE_CRM_TRACKING_LOGO]: 'New Logo Account',
	[WORKFLOW_SEQUENCE_CRM_TRACKING_EXPANSION]: 'New Expansion Opp',
	[WORKFLOW_SEQUENCE_CRM_TRACKING_MATCHED]: 'New Matched Account'
};

export let workflowEmailContent = {
	[WORKFLOW_SHARE_ACTION_LIST]: {
		[WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_CONNECTED_PARTNER]: {
			email_1: {
				title: 'Share Action List with PartnerTap Partner',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Partner Email]',
				subject: 'Actionable accounts for [Partner Name]',
				body_1: 'Hey [Partner Name] at [Partner Org]!',
				customMessageEnabled: true,
				body_2: 'Here are some ripe accounts. Let\'s get to work!'
			}
		},
		[WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_FELLOW_EMPLOYEE]: {
			email_1: {
				title: 'Share Action List with Fellow Employee',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Fellow Employee Email]',
				subject: 'Actionable accounts for [Fellow Employee Name]',
				body_1: 'Hey [Fellow Employee Name],',
				customMessageEnabled: true,
				body_2: 'We think you\'d appreciate the inside track on these accounts from your friends at [Sales Rep Org]'
			}
		},
		[WORKFLOW_SEQUENCE_SHARE_ACTION_LIST_EMAIL_ADDRESS]: {
			email_1: {
				title: 'Share Action List with Email Address',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Email Address]',
				subject: 'Actionable accounts for [Partner Name]',
				body_1: 'Hey, [Name] at [Org]!',
				customMessageEnabled: true,
				body_2: 'We think you\'d appreciate the inside track on these accounts from your friends at [Sales Rep Org]'
			}
		}
	},
	[WORKFLOW_DISTRIBUTE_RECORDS_INTERNAL]: {
		[WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_INTERNAL]: {
			email_1: {
				title: 'Distribute Records to Sales Reps',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Sales Rep Email]',
				subject: 'Actionable accounts for [Sales Rep Name]',
				body_1: 'Hey, [Sales Rep Name]!',
				customMessageEnabled: true,
				body_2: 'Here are some ripe accounts. Let\'s get to work!'
			}
		}
	},
	[WORKFLOW_DISTRIBUTE_RECORDS_EXTERNAL]: {
		[WORKFLOW_SEQUENCE_DISTRIBUTE_RECORDS_EXTERNAL]: {
			email_1: {
				title: 'Distribute Records to Partners',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Partner Email]',
				subject: 'Actionable accounts for [Partner Name]',
				body_1: 'Hey [Partner Name],',
				customMessageEnabled: true,
				body_2: 'We think you\'d appreciate the inside track on these accounts from your friends at [Sales Rep Org]'
			}
		}
	},
	[WORKFLOW_INTRO_EMAIL]: {
		[WORKFLOW_SEQUENCE_INTRO_EMAIL_INTRO]: {
			email_1: {
				title: 'Sales Rep + Partner Introduction',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Sales Rep Email], [Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: '[Sales Rep Name] at [Sales Rep Org] meet [Partner Name] at [Partner Org]',
				customMessageEnabled: true,
				body_2: 'Here are the accounts you share, get to work!'
			}
		},
		[WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_OPT_IN]: {
			email_1: {
				title: 'Sales Rep Opt In',
				sendOrder: 'First Email',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Sales Rep Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: 'Wanna work? Wanna eat?',
				customMessageEnabled: true,
				body_2: 'Please indicate which accounts you\'d like to work on with [Partner Name], and we\'ll get you connected with them right away.'
			},
			email_2: {
				title: 'Sales Rep + Partner Introduction',
				sendOrder: 'Final Email',
				sendPrerequisite: 'Sent upon Sales Rep opt in',
				to: '[Sales Rep Email], [Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: '[Sales Rep Name] at [Sales Rep Org] meet [Partner Name] at [Partner Org]',
				customMessageEnabled: true,
				body_2: '[Sales Rep Name] has hand picked these accounts to co-sell with [Partner Name]!'
			}
		},
		[WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_OPT_IN]: {
			email_1: {
				title: 'Partner Opt In',
				sendOrder: 'First Email',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: 'Would you like to co-sell with [Sales Rep Org]',
				customMessageEnabled: true,
				body_2: 'Please indicate which accounts you\'d like to work on with [Partner Name], and we\'ll get you connected with them right away.'
			},
			email_2: {
				title: 'Sales Rep + Partner Introduction',
				sendOrder: 'Final Email',
				sendPrerequisite: 'Sent upon Partner opt in',
				to: '[Sales Rep Email], [Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: '[Partner Name] at [Partner Org] meet [Sales Rep Name] at [Sales Rep Org]',
				customMessageEnabled: true,
				body_2: '[Partner Name] has hand picked these accounts to co-sell with [Sales Rep Name]!'
			}
		},
		[WORKFLOW_SEQUENCE_INTRO_EMAIL_OWNER_THEN_PARTNER_OPT_IN]: {
			email_1: {
				title: 'Sales Rep Opt In',
				sendOrder: 'First Email',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Sales Rep Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: 'Wanna work? Wanna eat?',
				customMessageEnabled: true,
				body_2: 'Please indicate which [Record Type] you\'d like to work on with [Partner Name], and we\'ll get you connected with them right away.'
			},
			email_2: {
				title: 'Partner Opt In',
				sendOrder: 'Second Email',
				sendPrerequisite: 'Sent upon Sales Rep opt in',
				to: '[Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: 'Would you like to co-sell with [Sales Rep Org]. [Sales Rep Name] has hand picked some accounts to co-sell with you.',
				customMessageEnabled: true,
				body_2: 'Please indicate which accounts you\'d like to work on with [Sales Rep Name], and we\'ll get you connected with them right away.'
			},
			email_3: {
				title: 'Sales Rep + Partner Introduction',
				sendOrder: 'Final Email',
				sendPrerequisite: 'Sent upon Partner opt in',
				to: '[Sales Rep Email], [Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: '[Sales Rep Name] at [Sales Rep Org] meet [Partner Name] at [Partner Org]',
				customMessageEnabled: true,
				body_2: 'You\'ve both agreed to work on these accounts together!'
			}
		},
		[WORKFLOW_SEQUENCE_INTRO_EMAIL_PARTNER_THEN_OWNER_OPT_IN]: {
			email_1: {
				title: 'Partner Opt In',
				sendOrder: 'First Email',
				sendPrerequisite: 'Sent upon workflow confirmation',
				to: '[Partner Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: 'Would you like to co-sell with [Sales Rep Org]. [Sales Rep Name] has hand picked some accounts to co-sell with you.',
				customMessageEnabled: true,
				body_2: 'Please indicate which accounts you\'d like to work on with [Sales Rep Name], and we\'ll get you connected with them right away.'
			},
			email_2: {
				title: 'Sales Rep Opt In',
				sendOrder: 'Second Email',
				sendPrerequisite: 'Sent upon Partner opt in',
				to: '[Sales Rep Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: 'Wanna work? Wanna eat?',
				customMessageEnabled: true,
				body_2: 'Please indicate which [Record Type] you\'d like to work on with [Partner Name], and we\'ll get you connected with them right away.'
			},
			email_3: {
				title: 'Sales Rep + Partner Introduction',
				sendOrder: 'Final Email',
				sendPrerequisite: 'Sent upon Sales Rep opt in',
				to: '[Partner Email], [Sales Rep Email]',
				subject: 'New co-sell accounts for [Sales Rep Org] and [Partner Org]',
				body_1: '[Partner Name] at [Partner Org] meet [Sales Rep Name] at [Sales Rep Org]',
				customMessageEnabled: true,
				body_2: 'You\'ve both agreed to work on these accounts together!'
			}
		}
	}
};

export function updateWorkflowEmailContent(content) {
	workflowEmailContent = content;
}

export function validateWorkflowConfigEmail(config, requireOwner, requirePartner) {
	if (!config) return false;
	return (config.authorEmail && config.authorName && config.authorTitle) &&
		   (!requireOwner || (config.ownerEmailColumn && config.ownerNameColumn && config.ownerTitleColumn)) &&
		   (!requirePartner || (config.partnerEmailColumn && config.partnerNameColumn && config.partnerTitleColumn));
}

export function isWorkflowAvailable(actionListInfo, workflow) {
	if (actionListInfo.status === WORKFLOW_STATUS_CANCELED) return false;
	if (workflow.config === WORKFLOW_CRM_TRACKING) return true;
	if (actionListInfo.configuredWorkflows.length === 0) return true;
	return !(actionListInfo.configuredWorkflows.find((workflow) => workflow !== WORKFLOW_CRM_TRACKING));
}
