import React, {Fragment} from 'react';
import * as CoSellEngineEndpoints from '../../../../endpoints/CoSellEngineEndpoints';

export class WorkflowIntroEmailLive {

	get getWorkflowConfigsEndpoint() {
		return CoSellEngineEndpoints.getCoSellAdminConfigsIntroEmail;
	}

	get steps() {
		return [{label: 'Recipients', active: true}, {label: 'Messages'}, {label: 'Follow Ups'}, {label: 'Confirm'}];
	}

	configureEmail(emailWorkflowEditor, templates, currentConfigStep) {
		switch (currentConfigStep) {
			case 0: {
				return (
					<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
						{emailWorkflowEditor.renderFromEditor()}
						{emailWorkflowEditor.renderToOwnerEditor()}
						{emailWorkflowEditor.renderToPartnerEditor()}
					</div>
				);
			}
			case 1: {
				return (
					<div style={{display: 'flex', alignItems: 'top', justifyContent: 'center', flexWrap: 'wrap'}}>
						{templates.map((template, index) => emailWorkflowEditor.renderEmailPreviewTile(template, index))}
					</div>
				);
			}
			case 2: {
				return emailWorkflowEditor.renderFollowUpOptions();
			}
		}
	}

	renderSummaryData(summaryData) {
		return (
			<Fragment>
				<div>
					Total action list accounts: {summaryData?.totalActionListAccounts}
				</div>
				<div>
					Total sales rep recipients: {summaryData?.totalActionListOwners}
				</div>
				<div>
					Total partner recipients: {summaryData?.totalActionListPartners}
				</div>
			</Fragment>
		);
	}
}
