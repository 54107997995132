import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapDropShadow, partnerTapPrimary, partnerTapWhite} from '../../styles/partnertap_theme';
import LinkButton from '../buttons/LinkButton';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import {UPLOAD_HELP_KEY} from '../../globals/StorageKeys';
import StringHelper from '../../helpers/StringHelper';

class ExpandableMessage extends Component {

	constructor(props, context) {
		super(props, context);

		let {message} = this.props;
		const firstVisitKey = UPLOAD_HELP_KEY + StringHelper.formatKey(message.title).substring(0, 64);
		let isFirstVisit = !PersistenceHelper.getValue(firstVisitKey);
		PersistenceHelper.setValue(firstVisitKey, true);
		this.state = {showMore: isFirstVisit};
	}

	render() {
		let {message} = this.props;
		let {showMore} = this.state;

		let titleFontSize = 16;
		let style = {padding: 20, margin: 10, borderRadius: 20, boxShadow: partnerTapDropShadow, backgroundColor: partnerTapWhite};
		if (message.action) {
			titleFontSize = 18;
			style.margin = 0;
			style.boxShadow = null;
			style.border = '1px solid' + partnerTapPrimary;
			style.borderRadius = 10;
		}

		return (
			<div style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', ...this.props.style}}>
				<div style={style}>
					<div style={{display: 'flex', gap: 40, alignItems: 'center'}}>
						<div style={{flex: 1, fontSize: titleFontSize}}>
							{message.title}
						</div>
						<LinkButton label={showMore ? 'Show Less' : 'Learn More'} onClick={() => this.setState({showMore: !showMore})}/>
					</div>
					{showMore &&
					 <div style={{paddingTop: 20}}>
						 {message.content}
					 </div>}
					{message.action &&
					 <div style={{paddingTop: 10}}>
						 {message.action}
					 </div>}
				</div>
			</div>
		);
	}
}

ExpandableMessage.propType = {
	message: PropTypes.shape({title: PropTypes.string.isRequired, content: PropTypes.string.isRequired, action: PropTypes.element}).isRequired,
	style: PropTypes.object
};

export default ExpandableMessage;
