import {OpenInNew} from '@mui/icons-material';
import {Link} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapInactive, partnerTapSecondary} from '../../styles/partnertap_theme';
import Dialog from '../Dialog';

class LinkButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showConfirm: false};
		this.onClick = this.onClick.bind(this);
	}

	onClick(event) {
		event.stopPropagation();
		let {confirmationMessage, onClick} = this.props;
		if (confirmationMessage) {
			this.setState({showConfirm: true});
		}
		else if (onClick) {
			onClick(event);
		}
	}

	render() {
		let {label, onClick, disabled, confirmationMessage, hasExternalLinkIcon} = this.props;
		if (disabled) {
			return (
				<div style={{color: partnerTapInactive, whiteSpace: 'nowrap'}}>
					{label}
				</div>
			);
		}
		let dataCyProps = {};
		let dataCyKeys = Object.keys(this.props).filter((key) => key.startsWith('data-cy'));
		dataCyKeys.map((key) => dataCyProps[key] = this.props[key]);
		return (
			<div style={{display: 'inline-block'}}>
				<div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
					<Link style={{color: partnerTapSecondary, cursor: 'pointer', whiteSpace: 'nowrap'}} onClick={this.onClick} {...dataCyProps}>
						{label}
					</Link>
					{(hasExternalLinkIcon && label) &&
					 <OpenInNew fontSize={'small'} style={{paddingLeft: 2}}/>}
					{this.state.showConfirm &&
					 <Dialog title={'Please Confirm'}
							 message={confirmationMessage}
							 yesAction={() => {
								 onClick();
								 this.setState({showConfirm: false});
							 }}
							 noAction={() => this.setState({showConfirm: false})}/>}
				</div>
			</div>
		);
	}
}

LinkButton.propTypes = {
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	confirmationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	hasExternalLinkIcon: PropTypes.bool
};

export default LinkButton;
