import {CODE_VERIFIER_KEY, LOGIN_USERNAME_KEY, REDIRECT_URI_KEY} from '../globals/StorageKeys';
import * as ApiHelper from '../helpers/ApiHelper';
import EnvHelper from '../helpers/EnvHelper';
import PersistenceHelper from '../helpers/PersistenceHelper';

export function createAccount(sfCode, signupType) {
	let localUserName = PersistenceHelper.getValue(LOGIN_USERNAME_KEY);
	let endpoint = '/logins',
		payload = {
			authCode: sfCode,
			signUpType: signupType,
			userName: localUserName || '',
			password: localUserName || '',
			codeVerifier: PersistenceHelper.getValue(CODE_VERIFIER_KEY) || '',
			redirectUri: PersistenceHelper.getValue(REDIRECT_URI_KEY) || ''
		};
	return ApiHelper.sendPost(endpoint, payload);
}

export function basicLogin(signupType, userName, password) {
	let endpoint = '/logins',
		payload = {
			signUpType: signupType,
			userName: userName,
			password: password
		};
	return ApiHelper.sendPost(endpoint, payload);
}

export function updateDevices(personId, token) {
	let payload = {
		deviceName: EnvHelper.deviceModel,
		deviceType: EnvHelper.devicePlatform,
		personId: personId,
		token: token
	};
	return ApiHelper.sendPost('/devices', payload);
}

export function fetchLatestSignUpType(email) {
	let endpoint = '/logins/type?email=' + email.replace('+', '%2B');
	return ApiHelper.sendGet(endpoint);
}

export function fetchLoginPerson() {
	let endpoint = '/logins/loggedInUser';
	return ApiHelper.sendGet(endpoint);
}

export function getDemoUsers() {
	return ApiHelper.sendGet('/logins/demousers');
}

export function verifyAccount(personId, personHash, verificationCode) {
	let payload = {
		personId: personId,
		personHash: personHash,
		verificationCode: verificationCode
	};
	return ApiHelper.sendPut('/logins/verify', payload);
}

export function fetchCodeChallenge() {
	return ApiHelper.sendGet('/logins/challenge');
}
