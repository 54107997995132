import {Button} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import StringHelper from '../../helpers/StringHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import Dialog from '../Dialog';
import ToolTipOverlay from '../ToolTipOverlay';

class SecondaryButton extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {showConfirm: false};
		this.onClick = this.onClick.bind(this);
	}

	onClick(event) {
		let {requiresEventPropagation, confirmationMessage, onClick} = this.props;
		if (!requiresEventPropagation) event.stopPropagation(); // if this button is in a table, do not select the table row
		if (confirmationMessage) {
			this.setState({showConfirm: true});
		}
		else if (onClick) {
			onClick(event);
		}
	}

	render() {
		let {label, icon, toolTip, onClick, disabled, fullWidth, confirmationMessage} = this.props;
		let button =
			<div>
				{this.state.showConfirm &&
				 <Dialog title={'Please Confirm'}
						 message={confirmationMessage}
						 yesLabel={'CONFIRM'}
						 yesAction={() => {
							 onClick();
							 this.setState({showConfirm: false});
						 }}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showConfirm: false})}/>}
				<Button variant={'outlined'}
						color={'secondary'}
						style={{background: partnerTapWhite, height: 36, whiteSpace: 'nowrap', pointerEvents: onClick ? null : 'none'}}
						fullWidth={fullWidth}
						startIcon={label ? icon : null}
						disabled={disabled}
						onClick={this.onClick}
						data-cy={StringHelper.formatKey(label ? label : toolTip) + '_secondary_button'}>
					{label}
					{!label && icon}
				</Button>
			</div>;
		if (toolTip) {
			return (
				<ToolTipOverlay title={toolTip}>
					{button}
				</ToolTipOverlay>
			);
		}
		return button;
	}
}

SecondaryButton.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	icon: PropTypes.object,
	toolTip: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	confirmationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	fullWidth: PropTypes.bool,
	requiresEventPropagation: PropTypes.bool
};

export default SecondaryButton;
