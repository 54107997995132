import SavedReportBase from './SavedReportBase';

class SavedReportMappedAccounts extends SavedReportBase {

	get path() {
		return this.reportUrl + (this.reportType ? '/' + this.reportType : '');
	}

	get params() {
		return super.params + '&filterTitle=' + this.reportParams.filterTitle;
	}

	async validateReport() {
		return true;
	}
}

export default SavedReportMappedAccounts;
