import {Check, CheckBox, CheckBoxOutlineBlank, Close, JoinInner, Tag} from '@mui/icons-material';
import React from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import AuthHelper from '../../../helpers/AuthHelper';
import ChannelHelper from '../../../helpers/ChannelHelper';
import {ACTIONS} from '../../../helpers/ColumnHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {partnerTapInactive, partnerTapSecondary, partnerTapTernary, partnerTapTernaryLight, partnerTapWhite} from '../../../styles/partnertap_theme';
import LinkButton from '../../../ui/buttons/LinkButton';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Dialog from '../../../ui/Dialog';
import PagingBase from '../../../ui/lists/PagingBase';
import Loading from '../../../ui/Loading';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import TextInputBox from '../../../ui/TextInputBox';
import CoSellActionListInfo from './CoSellActionListInfo';
import CoSellActionListWorkflow from './CoSellActionListWorkflow';

class CoSellActionListPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.workflowRecords = null;

		this.state.loading = false;
		this.state.addingTag = false;
		this.state.tagValue = '';
		this.state.showConfirmDeleteDialog = null;

		this.getActionList = this.getActionList.bind(this);
		this.updateWorkflowRecords = this.updateWorkflowRecords.bind(this);
		this.startAddTag = this.startAddTag.bind(this);
		this.selectAllAddTags = this.selectAllAddTags.bind(this);
		this.clearAllAddTags = this.clearAllAddTags.bind(this);
		this.applyAddTag = this.applyAddTag.bind(this);
		this.cancelAddTag = this.cancelAddTag.bind(this);
		this.setActionsToTag = this.setActionsToTag.bind(this);
		this.setActionsToDelete = this.setActionsToDelete.bind(this);
	}

	componentDidMount() {
		this.getActionList();
	}

	getActionList() {
		CoSellEngineEndpoints.getCoSellActionList(this.actionListId)
		.then((result) => {
			if (this.unmounted) return;
			let actionListInfo = result.payload;
			ChannelHelper.convertOtherJsonValues([actionListInfo.sampleWorkflowRow]);

			if (this.props.match.params.workflowConfig) {
				CoSellEngineEndpoints.getCoSellActionListWorkflowRecords(this.actionListId)
				.then((result) => {
						if (this.unmounted) return;
						this.workflowRecords = result.payload;
						this.setState({actionListInfo: actionListInfo, loading: true});
					}
				)
				.catch((error) => {
					EnvHelper.serverError('Error from getCoSellActionListWorkflowRecords', error);
				});
			}
			else {
				this.setState({actionListInfo: actionListInfo, loading: true});
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionList', error);
		});
	}

	get actionListId() {
		return this.props.match.params.actionListId;
	}

	get storageKeyBase() {
		return 'co_sell_action_list';
	}

	get title() {
		return 'Accounts';
	}

	get icon() {
		return JoinInner;
	}

	get isLocked() {
		return !this.state.actionListInfo || this.state.actionListInfo.isLocked;
	}

	get componentBelowToolbar() {
		let {rowData, addingTag, tagValue} = this.state;
		if (!addingTag) return null;
		let addTagCount = rowData.filter((row) => row.addTag).length;
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: 10,
				padding: 10,
				backgroundColor: partnerTapTernaryLight
			}}>
				<div/>
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
					{addTagCount ?
						<div style={{fontSize: 16}}>
							Tag {addTagCount} row{addTagCount === 1 ? '' : 's'}
						</div>
						:
						<div style={{fontSize: 16}}>
							Select rows to tag in the table below
						</div>}
					<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
						<TextInputBox hintText={'Tag Name'}
									  value={tagValue}
									  maxWidth={200}
									  maxChars={16}
									  onChange={(value) => this.setState({tagValue: value})}
									  isSimple={true}/>
						<PrimaryButton label={'ADD TAG'} icon={<Tag/>} disabled={tagValue.length === 0 || this.countAddTags === 0} onClick={this.applyAddTag}/>
					</div>
				</div>
				<div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
					<SecondaryButton label={'SELECT ALL'} icon={<Check/>} onClick={this.selectAllAddTags}/>
					<SecondaryButton label={'CLEAR ALL'} icon={<CheckBoxOutlineBlank/>} onClick={this.clearAllAddTags}/>
				</div>
			</div>
		);
	}

	get columnData() {
		return [
			{title: 'Name', key: 'accountName'},
			{title: 'Type', key: 'accountType'},
			{title: 'Owner', key: 'accountOwner'},
			{title: 'Partner', key: 'partnerRepName', isPartnerData: true},
			{title: 'Actions', key: ACTIONS, cellColorFunction: () => this.state.addingTag && partnerTapTernaryLight}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {columnData} = this.state;
		let params = this.processParameters();
		CoSellEngineEndpoints.getCoSellActionListWorkflowRecords(
			this.actionListId,
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					this.workflowRecords = result.payload;
					let workflowRecords = this.workflowRecords.map((workflowRecord, index) => {
						workflowRecord.dto.recordId = workflowRecord.id;
						workflowRecord.dto.tags = workflowRecord.tags;

						// hack to populate fake common account data for sheet opps
						if (workflowRecord.dto.opportunityOwnerName) {
							workflowRecord.dto.accountOwner = AuthHelper.userProfile.firstName + ' ' + AuthHelper.userProfile.lastName;
							workflowRecord.dto.accountType = workflowRecord.dto.stageName + ' Opp';
							workflowRecord.dto.partnerRepName = workflowRecord.dto.opportunityOwnerName;
						}

						// hack to populate fake common data for partner assets
						if (workflowRecord.dto.partnerAccountOwner) {
							workflowRecord.dto.partnerRepName = workflowRecord.dto.partnerAccountOwner;
						}

						if (!workflowRecord.isRemoved) {
							return workflowRecord.dto;
						}
					})
					.filter((workflowRecord) => workflowRecord);
					ChannelHelper.convertOtherJsonValues(workflowRecords);
					ChannelHelper.convertTypedValues(workflowRecords, columnData);
					if (this.state.addingTag) {
						this.setActionsToTag(workflowRecords);
					}
					else {
						this.setActionsToDelete(workflowRecords);
					}
					result.payload = workflowRecords;
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	updateWorkflowRecords(workflowRecords) {
		this.setState({saving: true, showConfirmDeleteDialog: null});
		CoSellEngineEndpoints.updateCoSellActionListWorkflowRecords(this.actionListId, workflowRecords)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({loading: true, saving: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from updateCoSellActionListWorkflowRecord', error);
		});
	}

	startAddTag() {
		this.setActionsToTag();
		this.setState({addingTag: true});
	}

	selectAllAddTags() {
		this.state.rowData.forEach((row, index) => {
			row.addTag = true;
		});
		this.setActionsToTag();
	}

	clearAllAddTags() {
		this.state.rowData.forEach((row, index) => {
			row.addTag = false;
		});
		this.setActionsToTag();
	}

	get countAddTags() {
		let count = 0;
		this.state.rowData.forEach((row, index) => {
			if (row.addTag) count++;
		});
		return count;
	}

	applyAddTag() {
		let workflowRecordsChanged = [];
		this.state.rowData.forEach((row) => {
			if (row.addTag) {
				let workflowRecord = this.workflowRecords[row.recordId];
				if (!workflowRecord.tags) {
					workflowRecord.tags = [];
				}
				workflowRecord.tags.push(this.state.tagValue);
				workflowRecordsChanged.push(workflowRecord);
			}
		});
		if (workflowRecordsChanged.length > 0) {
			this.updateWorkflowRecords(workflowRecordsChanged);
		}
		this.cancelAddTag();
	}

	cancelAddTag() {
		this.state.rowData.forEach((row, index) => {
			row.addTag = false;
		});
		this.setActionsToDelete();
		this.setState({addingTag: false});
	}

	setActionsToTag(rowData = null) {
		if (!rowData) {
			rowData = this.state.rowData;
		}
		rowData.forEach((row) => {
			let checkFunction = (e) => {
				e.stopPropagation(); // so row is not selected by this click
				row.addTag = !row.addTag;
				this.setActionsToTag();
			};
			row.actions =
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5, color: partnerTapSecondary, textDecoration: 'underline'}}
					 onClick={checkFunction}>
					TAG
					{row.addTag ? <CheckBox/> : <CheckBoxOutlineBlank/>}
				</div>;
		});
		this.setState({forceUpdateKey: this.state.forceUpdateKey + 1});
	}

	renderTags(tags, row) {
		if (!tags || tags.length === 0) return null;
		return tags.map((tag, index) => {
			return (
				<div key={index}
					 style={{
						 display: 'flex',
						 alignItems: 'center',
						 justifyContent: this.isLocked ? 'center' : 'space-between',
						 color: partnerTapWhite,
						 backgroundColor: partnerTapTernary,
						 height: 28,
						 margin: 2,
						 paddingLeft: this.isLocked ? 10 : 16,
						 paddingRight: 10,
						 borderRadius: 14,
						 mixWidth: 100,
						 maxWidth: 300
					 }}>
					{tag}
					{!this.isLocked &&
					 <Close style={{fontSize: 20}} onClick={(e) => {
						 e.stopPropagation(); // so row is not selected by this click
						 let workflowRecord = this.workflowRecords[row.recordId];
						 tags.splice(workflowRecord.tags.indexOf(tag), 1);
						 workflowRecord.tags = tags;
						 this.updateWorkflowRecords([workflowRecord]);
					 }}/>}
				</div>
			);
		});
	}

	setActionsToDelete(rowData) {
		if (!rowData) {
			rowData = this.state.rowData;
		}
		rowData.forEach((row) => {
			row.actions =
				<div style={{display: 'flex', justifyContent: 'center', gap: 10}}>
					{this.isLocked ?
						<div style={{color: partnerTapInactive}}>
							LOCKED
						</div>
						:
						<LinkButton label={'DELETE'} onClick={() => this.setState({showConfirmDeleteDialog: this.workflowRecords[row.recordId]})}/>}
				</div>;
		});
	}

	render() {
		let {actionListInfo, saving, showConfirmDeleteDialog} = this.state;
		if (!actionListInfo) return <Loading>Initializing {this.title}...</Loading>;
		return (
			<div>
				{saving &&
				 <ScrimMessage message={'Saving...'}/>}
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5, margin: 5}}>
					<CoSellActionListInfo actionListInfo={actionListInfo}/>
					<CoSellActionListWorkflow parentComponent={this} actionListInfo={actionListInfo} workflowRecords={this.workflowRecords}/>
				</div>
				{showConfirmDeleteDialog &&
				 <Dialog title={'Delete Account Record'}
						 message={
							 <div style={{textAlign: 'center'}}>
								 <div style={{fontSize: 16, fontWeight: 'bold', paddingBottom: 20}}>
									 Are you sure you want to delete '{showConfirmDeleteDialog.dto.accountName}'?
								 </div>
								 <div>
									 Deleting an Account Record from an Action List cannot be undone.
								 </div>
							 </div>}
						 yesLabel={'DELETE'}
						 yesAction={() => {
							 showConfirmDeleteDialog.isRemoved = true;
							 this.updateWorkflowRecords([showConfirmDeleteDialog]);
						 }}
						 noLabel={'CANCEL'}
						 noAction={() => this.setState({showConfirmDeleteDialog: null})}/>}
				{super.render()}
			</div>
		);
	}
}

export default withRouter(CoSellActionListPage);
