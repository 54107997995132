import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

class DateRangeSelector extends Component {

	constructor(props, context) {
		super(props, context);
		let {startDate, endDate} = this.props.defaultDateRange;
		this.state = {dateRange: {startDate: startDate && startDate.toDate() || new Date(), endDate: endDate && endDate.toDate() || new Date()}};
		this.onStartDateChange = this.onStartDateChange.bind(this);
		this.onEndDateChange = this.onEndDateChange.bind(this);
	}

	onStartDateChange(date) {
		if (!date || date.toString() === 'Invalid Date') return;
		let dateRange = {startDate: date, endDate: this.state.dateRange.endDate};
		this.setState({dateRange: dateRange});
		if (this.props.onDateRangeChange) {
			this.props.onDateRangeChange(dateRange);
		}
	}

	onEndDateChange(date) {
		if (!date || date.toString() === 'Invalid Date') return;
		let dateRange = {startDate: this.state.dateRange.startDate, endDate: date};
		this.setState({dateRange: dateRange});
		if (this.props.onDateRangeChange) {
			this.props.onDateRangeChange(dateRange);
		}
	}

	render() {
		return (
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<div>
					<div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'top', justifyContent: 'center'}}>
						<div style={{padding: 5, width: 180}}>
							<DatePicker label={'Start Date'}
										value={this.state.dateRange.startDate}
										format={'MM/dd/yyyy'}
										onChange={this.onStartDateChange}/>
						</div>
						<div style={{padding: 5, width: 180}}>
							<DatePicker label={'End Date'}
										value={this.state.dateRange.endDate}
										format={'MM/dd/yyyy'}
										onChange={this.onEndDateChange}/>
						</div>
					</div>
					{this.props.onClose &&
					 <div style={{display: 'flex', paddingTop: 5, justifyContent: 'center'}}>
						 <div style={{padding: 5}}>
							 <SecondaryButton label={'CANCEL'} onClick={() => this.props.onClose()}/>
						 </div>
						 <div style={{padding: 5}}>
							 <PrimaryButton label={'APPLY'} onClick={() => this.props.onClose(this.state.dateRange)}/>
						 </div>
					 </div>}
				</div>
			</LocalizationProvider>
		);
	}
}

DateRangeSelector.propTypes = {
	defaultDateRange: PropTypes.shape({startDate: PropTypes.object.isRequired, endDate: PropTypes.object.isRequired}),
	onDateRangeChange: PropTypes.func,
	onClose: PropTypes.func
};

export default DateRangeSelector;
