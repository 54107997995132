import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ManagerAnalyticsEndpoints from '../../endpoints/ManagerAnalyticsEndpoints';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS} from '../../helpers/FeatureHelper';
import {partnerTapPrimary} from '../../styles/partnertap_theme';
import DownloadButton from '../../ui/buttons/DownloadButton';
import PagingBase from '../../ui/lists/PagingBase';
import RepHeader from './RepHeader';

class ManagerAnalyticsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);
		this.downloadReport = this.downloadReport.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((prevProps.managerAnalyticsState.currentCompanyName !== this.props.managerAnalyticsState.currentCompanyName ||
			 prevProps.managerAnalyticsState.searchRange !== this.props.managerAnalyticsState.searchRange ||
			 prevProps.managerAnalyticsState.division !== this.props.managerAnalyticsState.division)) {
			setTimeout(() => {
				if (this.unmounted) return;
				this.setState({loading: true});
			});
		}
	}

	get keyData() {
		return this.props.keyData[0];
	}

	get storageKeyBase() {
		return 'analytics_' + this.keyData;
	}

	get title() {
		return this.props.sectionName;
	}

	get icon() {
		return this.props.glyph;
	}

	get additionalToolbarButtons() {
		return [<DownloadButton key={'download_button'} onAction={this.downloadReport} inProgress={this.state.downloading}/>];
	}

	get columnData() {
		return this.props.columnData;
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {managerAnalyticsState} = this.props;
		let divisions = '';
		if (managerAnalyticsState.division) {
			managerAnalyticsState.division.forEach((divisionData) => {
				if (divisionData.isChecked && divisionData.division !== ManagerAnalyticsEndpoints.ALL_DIVISIONS) {
					divisions += encodeURIComponent(divisionData.division) + ',';
				}
			});
		}

		let params = this.processParameters();
		params.keyData = this.keyData;
		let isAdmin = FeatureHelper.isFeatureEnabled(FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS);
		ManagerAnalyticsEndpoints.fetchItemData(params,
			params.pageSize,
			params.page,
			managerAnalyticsState.searchRange,
			managerAnalyticsState.currentCompanyName,
			divisions,
			managerAnalyticsState.currentRep.personId,
			isAdmin)
		.then((result) => {
			if (this.unmounted) return;
			this.processData(params, result);
			if (this.hasRowData(result) && this.props.customCount) {
				this.props.customCount(
					managerAnalyticsState.currentCompanyName,
					managerAnalyticsState.searchRange,
					divisions,
					params.search,
					this.keyData === ManagerAnalyticsEndpoints.keyOutboundOpportunities,
					isAdmin)
				.then((result) => {
					if (this.unmounted) return;
					this.setState({rowCount: parseInt(result.payload)});
				});
			}
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		let {managerAnalyticsState} = this.props;
		let isRepView = this.keyData === ManagerAnalyticsEndpoints.keyRepIntel || this.keyData === ManagerAnalyticsEndpoints.keyNewPartners;
		let isOrgView = this.keyData === ManagerAnalyticsEndpoints.keyTapInbound || this.keyData === ManagerAnalyticsEndpoints.keyTapOutbound;
		let isPendingPartners = this.props.keyData.length > 1;
		return (
			<div>
				{(managerAnalyticsState.currentRep && (isRepView || isPendingPartners)) &&
				 <RepHeader name={managerAnalyticsState.currentRep.repName} personId={managerAnalyticsState.currentRep.personId}/>}
				{(managerAnalyticsState.currentCompanyName && isOrgView) &&
				 <div style={{fontSize: 24, paddingTop: 10, paddingLeft: 10, color: partnerTapPrimary}}>
					 Org Opp Details: {managerAnalyticsState.currentCompanyName}
				 </div>}
				{super.render()}
			</div>
		);
	}

	get rowClick() {
		return this.props.rowClick;
	}

	downloadReport() {
		this.setState({downloading: true});
		this.fetchAllForDownload()
		.then(() => {
			if (this.unmounted) return;
			this.setState({downloading: false});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchAllForDownload', error);
		});
	}

	fetchAllForDownload() {
		let {managerAnalyticsState, columnData} = this.props;
		return ManagerAnalyticsEndpoints.fetchForDownload({keyData: this.keyData, sort: this.sortClause, search: this.state.searchValue},
			managerAnalyticsState.searchRange,
			new Map(columnData.map((obj) => [obj.key, obj.title])),
			new Map(columnData.map((obj) => {
				if (obj.renderFunction !== undefined) {
					return [obj.key, obj.renderFunction];
				}
				return [];
			})),
			managerAnalyticsState.currentCompanyName,
			managerAnalyticsState.currentRep.personId,
			managerAnalyticsState.division);
	}
}

ManagerAnalyticsPage.propTypes = {
	labelNoFind: PropTypes.string.isRequired,
	labelLoading: PropTypes.string.isRequired,
	sectionName: PropTypes.string.isRequired,
	glyph: PropTypes.string.isRequired,
	columnData: PropTypes.array.isRequired,
	keyData: PropTypes.array.isRequired,
	rowClick: PropTypes.func,
	customCount: PropTypes.func,
	managerAnalyticsState: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		managerAnalyticsState: state.managerAnalyticsState
	};
}

export default withRouter(connect(mapStateToProps)(ManagerAnalyticsPage));
