import {Save} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {
	CATEGORY_COMPANY_PARTNER_ACCOUNTS,
	CATEGORY_COMPANY_PARTNER_OPPS,
	CATEGORY_GLOBAL_REPORTS,
	CATEGORY_MAPPED_ACCOUNTS,
	CATEGORY_PARTNER_ACCOUNTS,
	CATEGORY_PARTNER_OPPS,
	CATEGORY_SHEET_ACCOUNTS,
	CATEGORY_SHEET_OPPS
} from '../../../../globals/Enums';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import DateHelper from '../../../../helpers/DateHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import Dialog from '../../../../ui/Dialog';
import PagingBase from '../../../../ui/lists/PagingBase';
import SavedReportGlobal from './SavedReportGlobal';
import SavedReportMappedAccounts from './SavedReportMappedAccounts';
import SavedReportPartnerConnection from './SavedReportPartnerConnection';
import SavedReportPartnerOrg from './SavedReportPartnerOrg';
import SavedReportPartnerSheet from './SavedReportPartnerSheet';
import {ACTIONS} from '../../../../helpers/ColumnHelper';
import LinkButton from '../../../../ui/buttons/LinkButton';
import AuthHelper from '../../../../helpers/AuthHelper';
import {Routes} from '../../../../globals/Routes';

class SavedReportsPage extends PagingBase {

	get storageKeyBase() {
		return this.isShared ? 'ecosystem_saved_report_org' : 'ecosystem_saved_report';
	}

	get title() {
		return 'Ecosystem Saved Reports';
	}

	get icon() {
		return Save;
	}

	get isShared() {
		return EnvHelper.path === Routes.CHANNEL_ECOSYSTEM.ECOSYSTEM.SAVED.ORG.ROUTE;
	}

	get columnData() {
		let columns = [
			{
				title: 'Name',
				key: 'name',
				renderFunction: (columnValue, rowData) => <LinkButton label={columnValue} onClick={() => this.displaySavedReport(rowData, false)}/>
			},
			{title: 'Description', key: 'description'},
			{
				title: 'Category',
				key: 'reportCategory',
				renderFunction: (columnValue, rowData) => {
					let category = ChannelHelper.getSavedReportCategoryTitle(rowData.reportCategory);
					if (rowData.reportCategory === CATEGORY_GLOBAL_REPORTS) {
						category += ': ' + ChannelHelper.getReportTypeTitle(rowData.reportType);
					}
					return category;
				}
			},
			{title: 'Date Created', key: 'createdDate', type: 'date', renderFunction: (createdDate) => DateHelper.epochToDate(createdDate)}
		];
		if (this.isShared) {
			columns.push({title: 'Created By', key: 'savedBy'});
		}
		columns.push({
			title: 'Actions',
			key: ACTIONS,
			renderFunction: (columnValue, rowData) => {
				let userIsOwner = rowData.personId === AuthHelper.userPerson.id;
				return (
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'nowrap', gap: 10}}>
						<LinkButton label={'EDIT'}
									onClick={() => this.displaySavedReport(rowData)}
									disabled={EnvHelper.isSpoofing || !userIsOwner}/>
						<LinkButton label={'DELETE'}
									onClick={() => this.setState({deleteDialog: this.getDeleteDialog(rowData)})}
									disabled={EnvHelper.isSpoofing || !userIsOwner}/>
					</div>
				);
			}
		});
		return columns;
	}

	displaySavedReport(rowData, isEditable = true) {
		let savedReport;
		if (rowData.reportCategory === CATEGORY_GLOBAL_REPORTS) {
			savedReport = new SavedReportGlobal(rowData);
		}
		else if (rowData.reportCategory === CATEGORY_PARTNER_ACCOUNTS ||
				 rowData.reportCategory === CATEGORY_PARTNER_OPPS) {
			savedReport = new SavedReportPartnerConnection(rowData);
		}
		else if (rowData.reportCategory === CATEGORY_COMPANY_PARTNER_ACCOUNTS ||
				 rowData.reportCategory === CATEGORY_COMPANY_PARTNER_OPPS) {
			savedReport = new SavedReportPartnerOrg(rowData);
		}
		else if (rowData.reportCategory === CATEGORY_SHEET_ACCOUNTS ||
				 rowData.reportCategory === CATEGORY_SHEET_OPPS) {
			savedReport = new SavedReportPartnerSheet(rowData);
		}
		else if (rowData.reportCategory === CATEGORY_MAPPED_ACCOUNTS) {
			savedReport = new SavedReportMappedAccounts(rowData);
		}
		if (savedReport) {
			savedReport.validateReport()
			.then((result) => {
				if (result) {
					let userIsOwner = rowData.personId === AuthHelper.userPerson.id;
					DialogHelper.openSavedReportDialog(savedReport, this.setState.bind(this), isEditable && userIsOwner);
				}
				else {
					this.displayErrorDialog(savedReport.errorMessage);
				}
			});
		}
		else {
			this.displayErrorDialog('Invalid savedReport category: ' + rowData.reportCategory);
		}
	}

	getRowData() {
		if (this.gettingRowData) return;
		let params = this.processParameters();
		ChannelMappingEndpoints.getSavedReportData(this.isShared, params.page, params.pageSize, params.search, params.sort)
		.then((result) => {
			if (this.unmounted) return;
			if (result.payload) {
				this.processData(params, result);
			}
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{display: 'flex', alignItems: 'center', padding: 20}}>
						Saved {this.isShared ? 'organization' : 'personal'} reports will be listed here
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	displayErrorDialog(errorMessage) {
		let errorDialog = <Dialog title={'Error Message'} message={errorMessage} yesAction={() => this.setState({errorDialog: null})}/>;
		this.setState({errorDialog: errorDialog});
	}

	getDeleteDialog(rowData) {
		return (
			<Dialog title={'Please Confirm'}
					message={'Are you sure you want to delete ' + rowData.name + '?'}
					yesAction={() => {
						ChannelMappingEndpoints.deleteSavedReport(rowData.id)
						.then((result) => {
							if (this.unmounted) return;
							this.setState({deleteDialog: null, loading: true});
						})
						.catch((error) => {
							this.displayErrorDialog('You don\'t have permission to delete this report.');
						});
					}}
					noAction={() => this.setState({deleteDialog: null})}/>
		);
	}

	render() {
		return (
			<div>
				{super.render()}
				{this.state.deleteDialog}
				{this.state.errorDialog}
			</div>
		);
	}
}

export default withRouter(connect()(SavedReportsPage));
