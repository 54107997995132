import {Close, KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ProfileEndpoints from '../endpoints/ProfilesEndpoints';
import {Routes} from '../globals/Routes';
import AuthHelper from '../helpers/AuthHelper';
import EnvHelper from '../helpers/EnvHelper';
import FeatureHelper, {
	FEATURE_ADMIN_TOOLS_EDITORS,
	FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS,
	PRODUCT_ADMIN_TOOLS,
	PRODUCT_CHANNEL_ECOSYSTEM,
	PRODUCT_MANAGER_ANALYTICS,
	PRODUCT_SALES_NETWORK
} from '../helpers/FeatureHelper';
import SortHelper from '../helpers/SortHelper';
import {partnerTapAlert, partnerTapAppBackground, partnerTapPrimary, partnerTapWarn, partnerTapWhite} from '../styles/partnertap_theme';
import AlertMenu from '../ui/AlertMenu';
import ScrimMessage from './messages/ScrimMessage';
import PartnerTapIcon from './PartnerTapIcon';
import Pic from './Pic';
import PopoverSearchList from './PopoverSearchList';
import ToolTipOverlay from './ToolTipOverlay';

class TopBar extends Component {

	constructor(props, context) {
		super(props, context);

		let {person, profile} = this.props.authState;
		let isDoubleSpoof = person.spoofingSuperadminPersonId && person.spoofingViewSubordinateReportsPersonId;
		this.state = {
			spoofingPersonData: null,
			restoreSpoofingAdmin: null,
			loadingPrimaryAdmin: isDoubleSpoof,
			primarySpoofedProfile: isDoubleSpoof ? null : profile
		};

		this.onBack = this.onBack.bind(this);
		this.onForward = this.onForward.bind(this);
		this.handleSpoofUser = this.handleSpoofUser.bind(this);
	}

	componentDidMount() {
		if (this.state.loadingPrimaryAdmin) {
			let {person} = this.props.authState;
			ProfileEndpoints.fetchProfile(person.spoofingViewSubordinateReportsPersonId)
			.then((result) => {
				if (this.unmounted) return;
				this.setState({loadingPrimaryAdmin: false, primarySpoofedProfile: result.payload});
			})
			.catch((error) => {
				console.error('Error fetching primary spoofed profile', error);
			});
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	onBack() {
		EnvHelper.goBack();
		this.forceUpdate();
	}

	onForward() {
		EnvHelper.goForward();
		this.forceUpdate();
	}

	handleSpoofUser(personData) {
		this.setState({spoofingPersonData: personData});
		setTimeout(() => AuthHelper.spoofUser(personData.personId), 1000);
	}

	handleRestoreSpoofingAdmin(personId) {
		this.setState({restoreSpoofingAdmin: personId});
		setTimeout(() => AuthHelper.restoreSpoofingAdmin(personId, () => {
			if (this.unmounted) return;
			this.setState({restoreSpoofingAdmin: null});
		}), 1000);
	}

	get currentProductRoute() {
		if (EnvHelper.path.indexOf(Routes.ADMIN_TOOLS.ROUTE) === 0) {
			return Routes.ADMIN_TOOLS.REPORTS.ROUTE;
		}
		if (EnvHelper.path.indexOf(Routes.MANAGER_ANALYTICS.ROUTE) === 0) {
			return Routes.MANAGER_ANALYTICS.DASHBOARD.ROUTE;
		}
		if (EnvHelper.path.indexOf(Routes.CHANNEL_ECOSYSTEM.ROUTE) === 0) {
			return Routes.CHANNEL_ECOSYSTEM.ROUTE;
		}
		return Routes.DASHBOARD.ROUTE;
	}

	render() {
		let {authState} = this.props;
		let {person, profile, channelSubordinates} = authState;
		if (!profile) {
			return (
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					padding: 10,
					background: partnerTapAppBackground,
					borderBottom: '1px solid ' + partnerTapPrimary
				}}>
					{PartnerTapIcon.logoWide}
				</div>
			);
		}

		let productList = [];
		if (FeatureHelper.isProductEnabled(PRODUCT_SALES_NETWORK)) {
			productList.push({label: 'Sales Network', path: Routes.DASHBOARD.ROUTE});
		}
		if (FeatureHelper.isProductEnabled(PRODUCT_MANAGER_ANALYTICS)) {
			productList.push({label: 'Manager Analytics', path: Routes.MANAGER_ANALYTICS.DASHBOARD.ROUTE});
		}
		if (FeatureHelper.isProductEnabled(PRODUCT_CHANNEL_ECOSYSTEM)) {
			productList.push({label: 'Channel Ecosystem', path: Routes.CHANNEL_ECOSYSTEM.ROUTE});
		}
		if (FeatureHelper.isProductEnabled(PRODUCT_ADMIN_TOOLS) && FeatureHelper.isFeatureEnabled(FEATURE_ADMIN_TOOLS_EDITORS)) {
			productList.push({label: 'Admin Tools', path: Routes.ADMIN_TOOLS.REPORTS.ROUTE});
		}
		let selectedProduct = productList.find((item) => item.path === this.currentProductRoute);

		let {spoofingPersonData, restoreSpoofingAdmin, primarySpoofedProfile} = this.state;
		return (
			<div className={'printNone'}
				 style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, background: partnerTapPrimary}}>
				{spoofingPersonData &&
				 <ScrimMessage message={'Spoofing login for ' + spoofingPersonData.firstName + ' ' + spoofingPersonData.lastName + '...'}/>}
				{restoreSpoofingAdmin &&
				 <ScrimMessage message={'Clearing spoof, restoring admin...'}/>}
				<div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center'}}>
					{!EnvHelper.isDesktop &&
					 <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 50}}>
						 <KeyboardArrowLeft onClick={this.onBack}
											fontSize={'large'}
											style={{
												cursor: EnvHelper.canGoBack ? 'pointer' : 'default',
												color: partnerTapWhite,
												opacity: EnvHelper.canGoBack ? 1 : 0.333
											}}/>
						 <KeyboardArrowRight onClick={this.onForward}
											 fontSize={'large'}
											 style={{
												 cursor: EnvHelper.canGoForward ? 'pointer' : 'default',
												 color: partnerTapWhite,
												 opacity: EnvHelper.canGoBack ? 1 : 0.333
											 }}/>
					 </div>}

					<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
						{EnvHelper.isDesktop &&
						 <div style={{paddingTop: 3, paddingLeft: 5}}>
							 <ToolTipOverlay title={'v' + EnvHelper.version}>
								 {PartnerTapIcon.logoWhite}
							 </ToolTipOverlay>
						 </div>}
						<PopoverSearchList list={productList}
										   preselectedItem={selectedProduct}
										   labelRenderer={(product) => product.label}
										   onItemSelected={(profile) => EnvHelper.push(profile.path)}
										   noOutline={true}
										   minWidth={168}
										   maxWidth={168}/>
					</div>

					{EnvHelper.inChannelEcosystemProduct &&
					 (channelSubordinates && channelSubordinates.length &&
					 !person.spoofingViewSubordinateReportsPersonId &&
					 FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS)) &&
					 <PopoverSearchList label={'Team Member Reports'}
										list={channelSubordinates}
										labelRenderer={(reportingProfile) => reportingProfile.firstName + ' ' + reportingProfile.lastName + ' - ' + reportingProfile.roleDisplayName}
										valueRenderer={(reportingProfile) => reportingProfile.personId}
										onItemSelected={(reportingProfile) => this.handleSpoofUser(reportingProfile)}
										searchHandler={(search, list) => SortHelper.filterSearchObjectContains(search, list, ['firstName', 'lastName'])}
										noOutline={true}
										minWidth={200}
										maxWidth={200}/>}

					<div style={{display: 'flex', alignItems: 'center', paddingRight: 5}}>
						<AlertMenu pathCurrent={this.props.history.location.pathname}/>
						<Pic personId={authState.person.id} picSize={30}/>
					</div>
				</div>

				{Boolean(person.spoofingSuperadminPersonId) &&
				 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5, background: partnerTapWarn}}>
					 {this.state.loadingPrimaryAdmin ?
						 <div style={{display: 'flex', alignItems: 'center', padding: 5, gap: 10}}>
							 <CircularProgress size={10} disableShrink={true}/> Loading Spoof Admin...
						 </div>
						 :
						 <Fragment>
							 <div style={{padding: 5}}>
								 Superadmin viewing reports as
								 {' ' + primarySpoofedProfile.firstName} {primarySpoofedProfile.lastName}
								 {Boolean(primarySpoofedProfile.ptJobTitle) && ', ' + primarySpoofedProfile.ptJobTitle}
								 {Boolean(primarySpoofedProfile.organization) && ', ' + primarySpoofedProfile.organization}
							 </div>
							 <Close style={{color: partnerTapAlert, cursor: 'pointer'}}
									onClick={() => this.handleRestoreSpoofingAdmin(person.spoofingSuperadminPersonId)}/>
						 </Fragment>}
				 </div>}

				{Boolean(person.spoofingViewSubordinateReportsPersonId) &&
				 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5, background: partnerTapWarn}}>
					 <div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}>
						 <div style={{padding: 5}}>
							 Viewing subordinate {profile.firstName} {profile.lastName}'s reports
						 </div>
						 <Close style={{color: partnerTapAlert, cursor: 'pointer'}}
								onClick={() => this.handleRestoreSpoofingAdmin(person.spoofingViewSubordinateReportsPersonId)}/>
					 </div>
				 </div>}
			</div>
		);
	}
}

TopBar.propTypes = {
	authState: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(TopBar));
