import React from 'react';

import * as ChannelMappingEndpoints from '../../../../endpoints/ChannelMappingEndpoints';
import {Routes} from '../../../../globals/Routes';
import ChannelHelper from '../../../../helpers/ChannelHelper';
import DialogHelper from '../../../../helpers/DialogHelper';
import DownloadHelper from '../../../../helpers/DownloadHelper';
import EnvHelper from '../../../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES, tempFeatureFlag_CoSellEngine_live} from '../../../../helpers/FeatureHelper';
import ReportHelper, {
	ASSET_TYPE_OPPS,
	CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS,
	CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES
} from '../../../../helpers/ReportHelper';
import StringHelper from '../../../../helpers/StringHelper';
import CheckboxButton from '../../../../ui/buttons/CheckboxButton';
import DownloadButton from '../../../../ui/buttons/DownloadButton';
import SaveReportButton from '../../../../ui/buttons/SaveReportButton';
import PagingBase from '../../../../ui/lists/PagingBase';
import Loading from '../../../../ui/Loading';
import Upsell from '../../../../ui/Upsell';
import ReportMenu from '../../shared/ReportMenu';
import CoSellSaveButtonLive from '../../../../ui/buttons/CoSellSaveButtonLive';

class PartnerOrgPagingBase extends PagingBase {

	constructor(props, context, assetType, reportTypes, reportCategory) {
		super(props, context);

		let currentReport;
		let isDashboardReport;
		let reportType = this.reportType;
		if (DialogHelper.savedReportData.settings) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, DialogHelper.savedReportData.settings.reportTitle); // from saved report
		}
		else if (reportType) {
			currentReport = ReportHelper.getReportTypeByTitle(reportTypes, decodeURIComponent(reportType)); // from report dropdown or dashboard summary
			isDashboardReport = DialogHelper.isDialogOpen; // from dashboard summary
		}
		if (!currentReport) {
			currentReport = reportTypes[0];
		}

		this.state.loading = false;
		this.state.assetType = assetType;
		this.state.reportTypes = reportTypes;
		this.state.reportCategory = reportCategory;
		this.state.currentReportType = currentReport;
		this.state.isDashboardReport = isDashboardReport;
		this.state.reportByLocation = Boolean((DialogHelper.savedReportData.settings && DialogHelper.savedReportData.settings.reportByLocation) || this.props.match.params.reportByLocation);

		this.state.filterSelectorFunction = ChannelMappingEndpoints.getFilterData;
		this.state.filterSelectorArgs = {
			companyPartnerPublicId: this.companyPartnerPublicId,
			matchingType: currentReport.matchingType,
			channelReportType: currentReport.channelReportType
		};
		this.state.filterSelectorMounted = false;
		if (DialogHelper.savedReportData.settings) {
			this.initialFilterValues = DialogHelper.savedReportData.settings.filters;
		}

		this.fetchColumns = this.fetchColumns.bind(this);
		this.onUpdateReportType = this.onUpdateReportType.bind(this);
	}

	componentDidMount() {
		let {currentReportType, assetType} = this.state;
		let showOppsGreenfieldUpsell = currentReportType.isOppsGreenfield && !FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES);
		if (showOppsGreenfieldUpsell) {
			setTimeout(() => this.setState({showUpsell: 'Shared ' + ReportHelper.getAssetTypeDisplayName(assetType)}));
		}
		else {
			this.fetchColumns();
		}
	}

	get title() {
		let {assetType, reportTypes, currentReportType, reportByLocation, isDashboardReport} = this.state;
		let title = EnvHelper.getParam('filterTitle', this.props.location.search) || currentReportType.reportTitle;
		let key = assetType === ASSET_TYPE_OPPS ? 'partner_org_opps_report_menu' : 'partner_org_accounts_report_menu';
		return (
			<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
				{(isDashboardReport || this.isSavedReport) ?
					title :
					<ReportMenu reportTypes={reportTypes}
								currentReportType={currentReportType}
								onUpdateReportType={(reportType) => this.onUpdateReportType(reportType, reportByLocation)}
								data-cy={key}/>}
				<div style={{display: 'flex', alignItems: 'center', fontSize: 14}}>
					<CheckboxButton style={{padding: 4}}
									checked={reportByLocation}
									onChange={() => this.onUpdateReportType(currentReportType, !reportByLocation)}/>
					by Location
				</div>
			</div>
		);
	}

	onUpdateReportType(reportType, reportByLocation) {
		let upsellForReportType = reportType.upsellIfDisabledReportFeature(reportByLocation);
		if (upsellForReportType) {
			this.setState({showUpsellDialogForFeature: upsellForReportType});
		}
		else {
			let {isDashboardReport} = this.state;
			if (isDashboardReport || this.isSavedReport) {
				this.setState({reportByLocation: reportByLocation, loading: true});
			}
			else {
				let route = Routes.CHANNEL_ECOSYSTEM.PARTNER_ORGS.ORG;
				EnvHelper.push(route.PATH(this.companyPartnerPublicId, this.state.assetType.toLowerCase(), reportType.reportTitle, reportByLocation));
			}
		}
	}

	get icon() {
		let {currentReportType, isDashboardReport} = this.state;
		return (isDashboardReport || this.isSavedReport) ? ReportHelper.getReportFilterIcon(currentReportType.assetType, currentReportType.filterType) : null;
	}

	get companyPartnerPublicId() {
		return this.props.match.params.companyPartnerPublicId;
	}

	get reportType() {
		return this.props.match.params.reportType;
	}

	get storageKeyBase() {
		return this.getStorageKeyBase('accounts');
	}

	get displayCoSellSave() {
		let {currentReportType} = this.state;
		return currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_ACCOUNTS ||
			   currentReportType.channelReportType === CHANNEL_REPORT_TYPE_PARTNER_ORG_MATCHED_OPPORTUNITIES;
	}

	get additionalToolbarButtons() {
		let {currentReportType, reportCategory, filterValues, reportByLocation} = this.state;
		let buttons = [];
		if (currentReportType.isDownloadable) {
			if (tempFeatureFlag_CoSellEngine_live && this.displayCoSellSave) {
				buttons.push(<CoSellSaveButtonLive callingPage={this} key={this.state.rowCount}/>);
			}
			buttons.push(<DownloadButton callingPage={this}/>);
		}
		buttons.push(<SaveReportButton reportType={this.reportType}
									   reportCategory={reportCategory}
									   reportSettings={{
										   reportSettings: currentReportType,
										   baseSettings: ChannelHelper.baseSettings(this.storageKeyBase),
										   filter: filterValues,
										   reportByLocation: reportByLocation
									   }}
									   reportParams={{
										   companyPartnerPublicId: this.companyPartnerPublicId,
										   filterTitle: this.getParamValue('filterTitle')
									   }}
									   showSaveDialog={this.showSaveDialog}
									   isShared={false}/>);
		return buttons;
	}

	get noDataMessage() {
		if (this.state.rowData.length === 0 && !this.hasGeneralNoDataMessage) {
			return (
				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: 16}}>
					<div style={{padding: 20}}>
						None of your connected partners are sharing accounts with you.
					</div>
					<div>
						We recommend you contact your partners to pitch the mutual value of sharing data.
					</div>
				</div>
			);
		}
		return super.noDataMessage;
	}

	get downloadReportConfig() {
		let params = this.processParameters(true);
		let {currentReportType, columnData, reportByLocation} = this.state;
		return {
			endpoint: ChannelMappingEndpoints.CHANNEL_DOWNLOAD_ENDPOINT,
			companyPartnerPublicId: this.companyPartnerPublicId,
			matchingType: currentReportType.matchingType,
			search: params.search,
			filters: params.filters,
			sort: this.sortClause,
			exportBaseFileName: currentReportType.downloadFileName,
			exportFields: DownloadHelper.getFields(columnData),
			selectedColumns: this.activeColumns,
			totalElements: '0',
			mapByLocations: reportByLocation,
			personId: this.partnerPersonId,
			channelReportType: currentReportType.channelReportType
		};
	}

	get coSellReportConfigLive() {
		return {
			reportData: this.downloadReportConfig,
			partnerOrgPublicId: this.companyPartnerPublicId,
			assetType: ReportHelper.getAssetTypeFromDisplayName(this.state.assetType),
			rowCount: this.state.rowCount
		};
	}

	getStorageKeyBase(assetType) {
		let {currentReportType} = this.state;
		if (this.state.isDashboardReport) {
			let filterTitle = '';
			let paramFilterTitle = this.getParamValue('filterTitle');
			if (paramFilterTitle) {
				filterTitle = decodeURIComponent(paramFilterTitle);
				filterTitle = '_' + StringHelper.formatKey(filterTitle);
			}
			return 'company_partner_' + assetType + '_' + this.reportType + filterTitle;
		}
		let reportType = currentReportType ? '_' + StringHelper.formatKey(currentReportType.reportTitle) : '';
		return 'company_partner_' + assetType + '_' + this.companyPartnerPublicId + reportType;
	}

	fetchColumns() {
		let {currentReportType} = this.state;
		ChannelMappingEndpoints.getColumns({
			channelReportType: currentReportType.channelReportType,
			matchingType: currentReportType.matchingType,
			companyPartnerPublicId: this.companyPartnerPublicId
		})
		.then((result) => {
			if (this.unmounted) return;
			this.initColumnData(result.payload);
			this.setState({filters: this.filterSelectorColumns});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchColumns', error);
		});
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get filterSelectorConfig() {
		let config = super.filterSelectorConfig;
		if (config) {
			let {currentReportType} = this.state;
			config.inherentReportFilters = currentReportType.inherentReportFilters;
		}
		return config;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let {currentReportType, reportByLocation} = this.state;
		let params = this.processParameters();
		ChannelMappingEndpoints.getRecords(
			{
				companyPartnerPublicId: this.companyPartnerPublicId,
				matchingType: currentReportType.matchingType,
				mapByLocations: reportByLocation,
				channelReportType: currentReportType.channelReportType
			},
			params.search,
			params.filters,
			params.page,
			params.pageSize,
			params.sort)
		.then((result) => {
				if (this.unmounted) return;
				if (this.hasRowData(result)) {
					ChannelHelper.convertOtherJsonValues(result.payload);
					ChannelHelper.convertTypedValues(result.payload, this.columnData);
				}
				this.processData(params, result);
			}
		)
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		if (this.state.showUpsell) return <Upsell showUpsell={this.state.showUpsell} useScrollingContainer={true}/>;
		if (!this.state.columnData) return <Loading>Loading Metadata...</Loading>;
		if (this.isSavedReport) {
			return DialogHelper.renderSavedReportDialogButtons(super.render(), () => this.forceUpdate(), this.closeSavedReportDialog);
		}
		return super.render();
	}
}

export default PartnerOrgPagingBase;
