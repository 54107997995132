export default class ColorHelper {

	static gradientHexArray(count) {
		let colors = [];
		let frequency = Math.PI * 2 / count;
		let offset = Math.PI * 2 / 3;
		let factor = 110;
		let base = 130;
		for (let i = 0; i < count; ++i) {
			let red = Math.sin(frequency * i) * factor + base;
			let green = Math.sin(frequency * i - offset) * factor + base;
			let blue = Math.sin(frequency * i + offset) * factor + base;
			colors.push(this.rgb2color(red, green, blue));
		}
		return colors;
	}

	static rgb2color(r, g, b) {
		return '#' + this.byte2hex(r) + this.byte2hex(g) + this.byte2hex(b);
	}

	static byte2hex(n) {
		const nybHexString = '0123456789ABCDEF';
		return String(nybHexString.substr((n >> 4) & 0x0F, 1)) + nybHexString.substr(n & 0x0F, 1);
	}
}
