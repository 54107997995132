import {NewReleases} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ChatHelper from '../../../helpers/ChatHelper';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import TableHelper from '../../../helpers/TableHelper';
import {partnerTapInactive, partnerTapSecondary, partnerTapStroke, partnerTapTernary} from '../../../styles/partnertap_theme';
import ChatButton from '../../../ui/buttons/ChatButton';
import Pic from '../../../ui/Pic';

class TapRowRenderer extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {data: this.props.rowData};
	}

	render() {
		let {rowData} = this.props;

		let updated;
		if (rowData.changedDate) {
			let newDate = DateHelper.epochToDate(rowData.changedDate);
			let isToday = new Date().getTime() - rowData.changedDate < (1000 * 60 * 60 * 24);
			if (isToday) {
				updated = (
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: 10}}>
						<NewReleases style={{paddingRight: 4, color: partnerTapTernary}}/>
						<div style={{color: partnerTapTernary}}>{newDate}</div>
					</div>
				);
			}
			else {
				updated = <div style={{color: partnerTapInactive, display: 'flex', justifyContent: 'flex-end', paddingBottom: 10}}>{newDate}</div>;
			}
		}

		let partnerId = rowData.partnerPartnerId;
		let partnerPersonId = rowData.partnerPersonId;
		let personSaleAccountId = rowData.personSaleAccountId;
		let saleAccountLocationId = rowData.saleAccountLocationId;
		let openCount = rowData.openCount || 0;
		let closedCount = rowData.closeCount || 0;
		return (
			<tr>
				<td>
					<div ref={TableHelper.widthObserverFunction.bind(this)}
						 className={'hoverActive'}
						 style={{
							 display: 'flex',
							 flexDirection: 'column',
							 justifyContent: 'center',
							 borderBottom: '1px solid ' + partnerTapStroke,
							 padding: 10
						 }}
						 onClick={() => {
							 ChatHelper.showChatPartner(partnerId, partnerPersonId, personSaleAccountId, saleAccountLocationId, null, true);
						 }}>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<div style={{display: 'flex', alignItems: 'center'}} ref={(element) => this.infoElement = element}>
								<div style={{display: 'flex', flexDirection: EnvHelper.isDesktop ? 'row' : 'column', alignItems: 'center'}}>
									<div style={{paddingBottom: EnvHelper.isDesktop ? 0 : 2, paddingRight: EnvHelper.isDesktop ? 4 : 0}}>
										<Pic personId={partnerPersonId} partnerId={partnerId} picSize={EnvHelper.isDesktop ? 50 : 30}/>
									</div>
									<div>
										<Pic tmpLogo={rowData.saleAccountName} bizId={rowData.saleAccountId} picSize={EnvHelper.isDesktop ? 50 : 30}/>
									</div>
								</div>
								<div style={{
									paddingLeft: 10,
									display: 'flex',
									flexDirection: 'column',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
								}}>
									<div style={{color: partnerTapSecondary, fontSize: EnvHelper.isDesktop ? 18 : 16}}>
										{rowData.partnerName}
									</div>
									<div style={{color: partnerTapInactive, fontSize: EnvHelper.isDesktop ? 14 : 12}}>
										{rowData.partnerOrg}
									</div>
									{rowData.saleAccountName &&
									 <div style={{paddingTop: 4, paddingRight: 4, fontSize: EnvHelper.isDesktop ? 14 : 12}}>
										 <em>{rowData.saleAccountName.toUpperCase()} </em>
									 </div>}
									<div style={{fontSize: EnvHelper.isDesktop ? 14 : 12, color: partnerTapTernary}}>
										{openCount} Open | {closedCount} Closed
									</div>
								</div>
							</div>
							<div style={{display: 'flex', flexDirection: 'column', paddingLeft: 10}}>
								{updated}
								<ChatButton partnerId={partnerId}
											partnerPersonId={partnerPersonId}
											personSaleAccountId={personSaleAccountId}
											saleAccountLocationId={saleAccountLocationId}
											fromPartnerOpp={true}/>
							</div>
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

TapRowRenderer.propTypes = {
	rowData: PropTypes.shape({
		id: PropTypes.string,
		personId: PropTypes.string,
		partnerId: PropTypes.string,
		partnerName: PropTypes.string,
		saleAccountId: PropTypes.string,
		saleAccountName: PropTypes.string,
		accountUploaded: PropTypes.string,
		status: PropTypes.string,
		changedDate: PropTypes.number,
		partnerOrg: PropTypes.string,
		partnerPartnerId: PropTypes.string,
		openCount: PropTypes.number,
		closeCount: PropTypes.number
	}).isRequired
};

export default TapRowRenderer;
