import {Forum} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
import * as AccountsEndpoints from '../../../endpoints/AccountsEndpoints';
import * as TapEndpoints from '../../../endpoints/TapEndpoints';
import {Routes} from '../../../globals/Routes';
import EnvHelper from '../../../helpers/EnvHelper';
import ChatButton from '../../../ui/buttons/ChatButton';
import Loading from '../../../ui/Loading';
import SubNav from '../../../ui/navigation/SubNav';
import PageHeader from '../../../ui/PageHeader';
import IntelPage from '../../shared/IntelPage';
import ShareOpportunityAccountHeader from '../tap/ShareOpportunityAccountHeader';
import AccountPartnersPage from './AccountPartnersPage';

class AccountDetailsRouter extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {loading: true, showInfoDialog: false, account: null, sharedOpportunities: []};
		this.fetchAccount = this.fetchAccount.bind(this);
		this.getSharedOpportunities = this.getSharedOpportunities.bind(this);
		this.shareClick = this.shareClick.bind(this);
		this.getSharedOpportunity = this.getSharedOpportunity.bind(this);
	}

	componentDidMount() {
		this.fetchAccount();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchAccount() {
		AccountsEndpoints.fetchAccount(this.props.match.params.personSaleAccountId)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({account: result.payload});
			this.getSharedOpportunities();
		})
		.catch((error) => {
			this.getSharedOpportunities();
			console.error('Error fetching account', error);
		});
	}

	getSharedOpportunities() {
		if (this.props.forShareOpportunities) {
			let account = this.state.account;
			account.opportunitySharedCount = 0;
			TapEndpoints.fetchSharedOpportunities(this.state.account.id)
			.then((result) => {
				if (this.unmounted) return;
				result.payload.forEach((opp) => {
					if (opp.share) account.opportunitySharedCount++;
				});
				this.setState({loading: false, sharedOpportunities: result.payload});
			})
			.catch((error) => {
				console.error('Error fetching shared opportunities', error);
			});
		}
		else {
			this.setState({loading: false});
		}
	}

	shareClick(personId, sharedOpportunity) {
		let account = this.state.account;
		account.opportunitySharedCount = 0;
		if (sharedOpportunity) {
			sharedOpportunity.share = !sharedOpportunity.share;
			TapEndpoints.updateSharedOpportunity(sharedOpportunity.id, account.id, personId, sharedOpportunity.share)
			.then((result) => {
				if (this.unmounted) return;
				if (sharedOpportunity.share) account.opportunitySharedCount++;
				this.forceUpdate();
			});
		}
		else {
			TapEndpoints.updateSharedOpportunity(null, account.id, personId, true)
			.then((result) => {
				if (this.unmounted) return;
				this.state.sharedOpportunities.push({
					id: result.payload.personSharedOpportunityId,
					personSaleAccountId: account.id,
					personId: personId,
					share: true
				});
				account.opportunitySharedCount++;
				this.forceUpdate();
			});
		}
	}

	getSharedOpportunity(personId) {
		if (this.state.sharedOpportunities.length > 0) {
			return this.state.sharedOpportunities.find((sharedOpportunity) => {
				return sharedOpportunity.personId === personId;
			});
		}
		return null;
	}

	render() {
		if (this.state.loading) return <Loading>Loading Account Details...</Loading>;
		let account = this.state.account;
		if (this.props.forShareOpportunities) {
			return (
				<div key={account.opportunitySharedCount}>
					<ShareOpportunityAccountHeader account={account}/>
					<AccountPartnersPage account={account}
										 forShareOpportunities={true}
										 sharedOpportunities={this.state.sharedOpportunities}
										 shareClick={this.shareClick}
										 getSharedOpportunity={this.getSharedOpportunity}/>
				</div>
			);
		}
		let subNavData = [
			{
				label: 'Partners',
				glyph: 'L',
				route: Routes.DASHBOARD.ACCOUNTS.ROUTE + '/' + account.id + '/' + account.saleAccountLocationId + '/partners'
			},
			{
				label: 'Intel',
				MaterialIcon: Forum,
				route: Routes.DASHBOARD.ACCOUNTS.ROUTE + '/' + account.id + '/' + account.saleAccountLocationId + '/intel'
			}
		];
		let accountLocation = (account.city ? account.city : '') +
							  (account.city && account.state ? ', ' : ' ') +
							  (account.state ? account.state : '');
		return (
			<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
				<PageHeader accountId={account.saleAccountId} crmAccountId={account.crmAccountId} title={account.name} subtitle={accountLocation}>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<ChatButton isNewChat={true} account={account}/>
					</div>
				</PageHeader>
				{!EnvHelper.isDesktop &&
				 <div style={{marginBottom: 5}}>
					 <SubNav subNavData={subNavData}/>
				 </div>}
				<Switch>
					<Route path={Routes.DASHBOARD.ACCOUNTS.ACCOUNT.INTEL.ROUTE} render={(props) => {
						return <IntelPage inDetails={true} {...props}/>;
					}}/>
					<Route path={this.props.match.path + '/partners'} render={() => {
						return <AccountPartnersPage account={account}/>;
					}}/>
				</Switch>
			</div>
		);
	}
}

AccountDetailsRouter.propTypes = {
	forShareOpportunities: PropTypes.bool,
	match: PropTypes.object
};

export default withRouter(connect()(AccountDetailsRouter));
