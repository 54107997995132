import {Check, ErrorOutline} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {partnerTapAlert, partnerTapInactive, partnerTapStroke, partnerTapTernary, partnerTapTernaryLight, partnerTapWhite} from '../styles/partnertap_theme';
import ItemCount from './ItemCount';

class Stepper extends Component {

	render() {
		let {steps} = this.props;
		let lastCompleteIndex = 0;
		steps.forEach((step, index) => {
			if (step.complete) lastCompleteIndex = index + 1;
		});
		return (
			<div style={{
				display: 'flex',
				flexWrap: 'wrap',
				rowGap: 10,
				padding: 10,
				backgroundColor: partnerTapWhite,
				borderBottom: '1px solid' + partnerTapStroke
			}}>
				{steps.map((step, index) => {
					let customContent;
					let stepColor = partnerTapInactive;
					let isLastStep = index === steps.length - 1;
					if (step.active) {
						stepColor = partnerTapTernary;
					}
					else if (step.complete) {
						stepColor = partnerTapTernaryLight;
						customContent = <Check style={{color: partnerTapWhite, paddingTop: 4}}/>;
					}
					else if (step.error) {
						stepColor = partnerTapAlert;
						customContent = <ErrorOutline style={{color: partnerTapWhite, paddingTop: 4}}/>;
					}
					return (
						<div key={'stepper_' + index}
							 style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexGrow: isLastStep ? 0 : 1}}>
							<ItemCount count={index + 1} backgroundColor={stepColor} customContent={customContent}/>
							{(steps.length <= 3 || step.active || step.error) &&
							 <div style={{fontWeight: step.active ? 'bold' : 'normal', fontSize: 20, paddingLeft: 10}}>
								 {step.label}
							 </div>}
							{!isLastStep &&
							 <div style={{
								 backgroundColor: index < lastCompleteIndex ? partnerTapTernary : partnerTapInactive,
								 color: partnerTapWhite,
								 flexGrow: 1,
								 height: 1,
								 marginLeft: 20,
								 marginRight: 10
							 }}/>}
						</div>
					);
				})}
			</div>
		);
	}
}

Stepper.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, active: PropTypes.bool, complete: PropTypes.bool, error: PropTypes.bool}))
};

export default Stepper;
