import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as PartnersEndpoints from '../../../endpoints/PartnersEndpoints';
import {
	INVITE_STATUS_ACCEPTED,
	INVITE_STATUS_INITIATED,
	INVITE_STATUS_RECOMMENDED,
	INVITE_STATUS_REQUESTED,
	INVITE_STATUS_RETRACTED,
	INVITE_STATUS_UNPARTNERED,
	STATUS_TERMINATED
} from '../../../globals/Enums';
import {Routes} from '../../../globals/Routes';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import {PRODUCT_SALES_NETWORK} from '../../../helpers/FeatureHelper';
import PartnerStatusHelper from '../../../helpers/PartnerStatusHelper';
import TableHelper from '../../../helpers/TableHelper';
import {
	partnerTapDefaultText,
	partnerTapInactive,
	partnerTapPrimary,
	partnerTapSecondary,
	partnerTapStroke,
	partnerTapTernary,
	partnerTapWhite
} from '../../../styles/partnertap_theme';
import CancelButton from '../../../ui/buttons/CancelButton';
import ChatButton from '../../../ui/buttons/ChatButton';
import InviteButton from '../../../ui/buttons/InviteButton';
import PendingButton from '../../../ui/buttons/PendingButton';
import ShareButton from '../../../ui/buttons/ShareButton';
import MatchCount from '../../../ui/MatchCount';
import Pic from '../../../ui/Pic';

class PartnerRowRenderer extends Component {

	constructor(props, context) {
		super(props, context);
		this.partnerClick = this.partnerClick.bind(this);
		this.updatePartner = this.updatePartner.bind(this);
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	partnerClick() {
		if (this.props.rowData.personStatus !== STATUS_TERMINATED) {
			EnvHelper.push(Routes.DASHBOARD.PARTNERS.ROUTE + '/' + this.props.rowData.partnerId + '/' + this.props.rowData.personId + '/accounts');
		}
	}

	updatePartner(status) {
		PartnersEndpoints.updatePartner(this.props.rowData.partnerId, status, false, PRODUCT_SALES_NETWORK)
		.then((result) => {
				if (this.unmounted) return;
				this.props.rowData.partnerStatus = status;
				this.forceUpdate();
			}
		)
		.catch((error) => {
				console.error('Error updatePartner cannot ' + status + ' partner', error);
			}
		);
	}

	render() {
		let personId = this.props.person.id;
		let matchedAccountCount = this.props.rowData.matchedAccountCount || 0;
		let isTerminated = this.props.rowData.personStatus === STATUS_TERMINATED;
		let isAccountDetails = this.props.rowData.forAccountDetails;
		let isRecommended = this.props.rowData.partnerStatus === INVITE_STATUS_RECOMMENDED || this.props.rowData.partnerStatus === INVITE_STATUS_UNPARTNERED;
		let wrapperStyle = {
			display: 'flex',
			padding: EnvHelper.isDesktop ? 10 : 8,
			borderBottom: '1px solid ' + partnerTapStroke,
			backgroundColor: partnerTapWhite
		};
		let personInfo = (
			<div ref={(element) => this.infoElement = element}
				 style={{flex: 1, display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
				<Pic personId={this.props.rowData.personId} partnerId={this.props.rowData.id} disabled={isTerminated}/>
				<div style={{
					height: 68,
					paddingLeft: 10,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					whiteSpace: 'nowrap',
					overflow: 'hidden'
				}}>
					<div style={{
						color: this.props.rowData.partnerStatus === INVITE_STATUS_ACCEPTED ? partnerTapSecondary : partnerTapDefaultText,
						fontSize: EnvHelper.isDesktop ? 18 : 16,
						paddingBottom: 1,
						display: 'block',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}>
						{this.props.rowData.partnerName}
					</div>
					<div style={{
						color: partnerTapInactive,
						fontSize: EnvHelper.isDesktop ? 14 : 12,
						paddingBottom: 2,
						display: 'block',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}}>
						{isTerminated && 'Formerly:'}
						{this.props.rowData.ptJobTitle && this.props.rowData.ptJobTitle + ', '}{this.props.rowData.organization}
					</div>
					{(!isTerminated && !isAccountDetails) && <MatchCount count={matchedAccountCount}/>}
				</div>
			</div>
		);

		let rowContent;
		if (this.props.rowData.partnerStatus === INVITE_STATUS_ACCEPTED) {
			let isShareOpps = this.props.rowData.forShareOpportunities;
			let sharedOpportunity = isShareOpps ? this.props.rowData.getSharedOpportunity(this.props.rowData.personId) : null;
			let rowIsHot = !isTerminated && !isShareOpps;
			rowContent =
				<div className={rowIsHot ? 'hoverActive' : null} style={wrapperStyle}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<div style={{flex: 1, display: 'flex'}} onClick={rowIsHot ? this.partnerClick : null}>
							{personInfo}
						</div>

						{(!isAccountDetails && !isShareOpps && this.props.rowData.isNew) &&
						 <div style={{padding: 5, fontSize: 20, color: partnerTapTernary}}>New!</div>}

						{isShareOpps &&
						 <ShareButton isCancel={sharedOpportunity && sharedOpportunity.share}
									  onClick={() => this.props.rowData.shareClick(this.props.rowData.personId, sharedOpportunity)}/>}

						{(isAccountDetails && !isShareOpps) &&
						 <ChatButton partnerId={this.props.rowData.id}
									 partnerPersonId={this.props.rowData.personId}
									 personSaleAccountId={this.props.rowData.account.id}
									 saleAccountLocationId={this.props.rowData.account.saleAccountLocationId}/>}
					</div>
				</div>;
		}
		else if (this.props.rowData.partnerStatus === INVITE_STATUS_REQUESTED && this.props.rowData.personId !== personId) {
			rowContent =
				<div style={wrapperStyle}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<div style={{flex: 1, display: 'flex'}}>
							{personInfo}
						</div>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<div style={{color: partnerTapPrimary, paddingBottom: EnvHelper.isDesktop ? 10 : 2, whiteSpace: 'nowrap'}}>
								Invited {DateHelper.epochToDate(this.props.rowData.createdDate)}
							</div>
							<PendingButton itemData={this.props.rowData}/>
						</div>
					</div>
				</div>;
		}
		else if (this.props.rowData.partnerStatus === INVITE_STATUS_INITIATED) {
			rowContent =
				<div style={wrapperStyle}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<div style={{flex: 1, display: 'flex'}}>
							{personInfo}
						</div>
						<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
							<div style={{color: partnerTapPrimary, paddingBottom: EnvHelper.isDesktop ? 10 : 2, whiteSpace: 'nowrap'}}>
								Invited {DateHelper.epochToDate(this.props.rowData.createdDate)}
							</div>
							<CancelButton minWidth={EnvHelper.isDesktop ? 120 : 80}
										  onClick={() => this.updatePartner(INVITE_STATUS_RETRACTED)}
										  confirmationMessage={'Are you sure you want to retract this invite?'}
										  disabled={EnvHelper.isSpoofing}/>
						</div>
					</div>
				</div>;
		}
		else {
			rowContent =
				<div style={wrapperStyle}>
					<div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
						<div style={{flex: 1, display: 'flex', overflow: 'hidden'}}>
							{personInfo}
						</div>
						<InviteButton inviteData={this.props.rowData}
									  userNote={PartnerStatusHelper.renderNoteForInvite(this.props.rowData.firstName, this.props.authState.profile)}
									  userNoteRows={15}
									  forceEnabled={isRecommended}/>
					</div>
				</div>;
		}

		return (
			<tr>
				<td>
					<div ref={TableHelper.widthObserverFunction.bind(this)}>
						{rowContent}
					</div>
				</td>
			</tr>
		);
	}
}

PartnerRowRenderer.propTypes = {
	rowData: PropTypes.shape({
		id: PropTypes.string,
		personId: PropTypes.string,
		partnerName: PropTypes.string,
		status: PropTypes.string,
		organization: PropTypes.string,
		matchedAccountCount: PropTypes.number,
		personStatus: PropTypes.string,
		ptJobTitle: PropTypes.string,
		forAccountDetails: PropTypes.bool,
		forShareOpportunities: PropTypes.bool,
		sharedOpportunities: PropTypes.array,
		shareClick: PropTypes.func,
		getSharedOpportunity: PropTypes.func,
		partnerStatus: PropTypes.string,
		isNew: PropTypes.bool,
		updatedDate: PropTypes.number,
		account: PropTypes.object
	}).isRequired,
	authState: PropTypes.object.isRequired,
	person: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		authState: state.authState,
		person: state.authState.person
	};
}

export default connect(mapStateToProps)(PartnerRowRenderer);
