import React from 'react';
import {UPLOAD_DATA_MANAGER_ENRICH_SHEET, UPLOAD_DATA_MANAGER_SHEET, UPLOAD_PARTNER_SHEET, UPLOAD_PII_SHARING_OPT_IN} from '../globals/Enums';
import CsvBestPractices from '../app/channel_ecosystem/shared/sheets/CsvBestPractices';

// sheet upload column keys
export const UPLOAD_SHEET_COLUMN_ACCOUNT_NAME = 'NAME';
export const UPLOAD_SHEET_COLUMN_ACCOUNT_TYPE = 'TYPE';

export const UPLOAD_SHEET_COLUMN_ACCOUNT_ID = 'ACCOUNT_ID';
export const UPLOAD_SHEET_COLUMN_DUNS = 'DUNS';
export const UPLOAD_SHEET_COLUMN_TAX_ID = 'TAX_ID';
export const UPLOAD_SHEET_COLUMN_NAICS_CODE = 'NAICS_CODE';
export const UPLOAD_SHEET_COLUMN_NCES_ID = 'NCES_ID';
export const UPLOAD_SHEET_COLUMN_SIC_CODE = 'SIC_CODE';

export const UPLOAD_SHEET_COLUMN_WEBSITE = 'WEBSITE';
export const UPLOAD_SHEET_COLUMN_STREET = 'STREET';
export const UPLOAD_SHEET_COLUMN_CITY = 'CITY';
export const UPLOAD_SHEET_COLUMN_STATE = 'STATE';
export const UPLOAD_SHEET_COLUMN_ZIP = 'ZIP';
export const UPLOAD_SHEET_COLUMN_COUNTRY = 'COUNTRY';
export const UPLOAD_SHEET_COLUMN_PHONE = 'PHONE';
export const UPLOAD_SHEET_COLUMN_INDUSTRY = 'INDUSTRY';
export const UPLOAD_SHEET_COLUMN_NUMBER_OF_EMPLOYEES = 'NUMBER_OF_EMPLOYEES';

export const UPLOAD_SHEET_COLUMN_OWNER_NAME = 'OWNERNAME';
export const UPLOAD_SHEET_COLUMN_OWNER_EMAIL = 'OWNEREMAIL';
export const UPLOAD_SHEET_COLUMN_OWNER_TITLE = 'OWNERTITLE';
export const UPLOAD_SHEET_COLUMN_OWNER_DIVISION = 'OWNERDIVISION';

// sheet upload error types
export const SHEET_ERROR_BLANK_HEADERS = 'BLANK_HEADERS';
export const SHEET_ERROR_OVERSIZED_HEADERS = 'OVERSIZED_HEADERS';
export const SHEET_ERROR_MISSING_MANDATORY_SAMPLES = 'MISSING_MANDATORY_SAMPLES';

// person queue statuses
export const PERSON_QUEUE_STATUS_INIT = 'INIT';
export const PERSON_QUEUE_STATUS_MAPPING_COMPLETED = 'MAPPING_COMPLETED'; // when mapping completes before S3 upload completes
export const PERSON_QUEUE_STATUS_UPLOADED_TO_S3 = 'UPLOADED_TO_S3';
export const PERSON_QUEUE_STATUS_UPLOADED = 'UPLOADED';
export const PERSON_QUEUE_STATUS_MAPPED = 'MAPPED';
export const PERSON_QUEUE_STATUS_READY = 'READY';
export const PERSON_QUEUE_STATUS_PROCESSING = 'PROCESSING';
export const PERSON_QUEUE_STATUS_PROCESSED = 'PROCESSED';
export const PERSON_QUEUE_STATUS_NOT_PROCESSED = 'NOT_PROCESSED';
export const PERSON_QUEUE_STATUS_FAILED_TO_PROCESS = 'FAILED_TO_PROCESS';
export const PERSON_QUEUE_STATUS_DW_PENDING = 'DW_PENDING';
export const PERSON_QUEUE_STATUS_ARCHIVED = 'ARCHIVED';

export default class UploadHelper {

	static getUploadSteps(uploadType, onStep, hasError) {
		return [
			{label: UploadHelper.getUploadTitle(uploadType), active: onStep === 0 && !hasError, error: onStep === 0 && hasError, complete: onStep > 0},
			{label: 'Required Columns', active: onStep === 1 && !hasError, error: onStep === 1 && hasError, complete: onStep > 1},
			{label: 'Recommended Columns', active: onStep === 2 && !hasError, error: onStep === 2 && hasError, complete: onStep > 2},
			{label: 'Custom Columns', active: onStep === 3 && !hasError, error: onStep === 3 && hasError, complete: onStep > 3}
		];
	}

	static getUploadTitle(uploadType) {
		switch (uploadType) {
			case UPLOAD_DATA_MANAGER_SHEET:
				return 'Add Accounts';
			case UPLOAD_DATA_MANAGER_ENRICH_SHEET:
				return 'Enrich Accounts';
			case UPLOAD_PARTNER_SHEET:
				return 'Partner Accounts';
			default:
				return 'Accounts';
		}
	}

	static getSheetTitle(uploadType) {
		switch (uploadType) {
			case UPLOAD_DATA_MANAGER_ENRICH_SHEET:
				return 'Enrichment Sheets';
			case UPLOAD_PARTNER_SHEET:
				return 'Partner Sheets';
			case UPLOAD_PII_SHARING_OPT_IN:
				return 'PII Opt-In Sheets';
			default:
				return 'Account Sheets';
		}
	}

	static uploadCsvLabel(uploadType) {
		switch (uploadType) {
			case UPLOAD_DATA_MANAGER_ENRICH_SHEET:
				return 'enrichment';
			case UPLOAD_PARTNER_SHEET:
				return 'partner\'s';
			case UPLOAD_PII_SHARING_OPT_IN:
				return 'PII Opt-In';
			default:
				return 'account';
		}
	}

	static getUploadStatus(uploadStatus) {
		switch (uploadStatus) {
			case PERSON_QUEUE_STATUS_UPLOADED:
				return 'Uploaded';
			case PERSON_QUEUE_STATUS_MAPPED:
				return 'Ready to Process';
			case PERSON_QUEUE_STATUS_READY:
			case PERSON_QUEUE_STATUS_PROCESSING:
			case PERSON_QUEUE_STATUS_DW_PENDING:
			case PERSON_QUEUE_STATUS_MAPPING_COMPLETED:
				return 'Processing';
			case PERSON_QUEUE_STATUS_FAILED_TO_PROCESS:
				return 'Failed to process';
			case PERSON_QUEUE_STATUS_ARCHIVED:
				return 'Archived';
			case PERSON_QUEUE_STATUS_NOT_PROCESSED:
				return 'Waiting to process';
			case PERSON_QUEUE_STATUS_PROCESSED:
				return 'Processed';
			default:
				return uploadStatus;
		}
	}

	static getUploadHelp(uploadType, step) {
		if (step === -1) {
			switch (uploadType) {
				case UPLOAD_PARTNER_SHEET:
				case UPLOAD_DATA_MANAGER_SHEET:
					return {
						title: 'Data Manager is where you bring your data into PartnerTap',
						content:
							<ul style={{paddingLeft: 20, textAlign: 'left'}}>
								<li style={{marginBottom: 10}}>Connect and refresh your CRM data</li>
								<li style={{marginBottom: 10}}>Upload and enrich account data via CSV</li>
								<li style={{marginBottom: 10}}>Send custom integration data to our support team</li>
								<li style={{marginBottom: 10}}>Review and manage all of your accounts and opportunities</li>
							</ul>
					};
				case UPLOAD_DATA_MANAGER_ENRICH_SHEET:
			}
		}

		let bullet0 = <div/>;
		let bullet1 = <li style={{marginBottom: 10}}>Check the sample values to verify you have selected the correct column.</li>;
		let bullet2 = <li style={{marginBottom: 10}}>If a column is missing or needs to be changed, use the drop down list to select a new column.</li>;
		let bullet3 = <li style={{marginBottom: 10}}>These columns are required.</li>;

		let step1Title;
		switch (uploadType) {
			case UPLOAD_PARTNER_SHEET:
			case UPLOAD_DATA_MANAGER_SHEET:
				step1Title = 'Confirm that Account Name and Account Type columns are assigned correctly';
				break;
			case UPLOAD_DATA_MANAGER_ENRICH_SHEET:
				step1Title = 'Select the unique account identifier that you specified in your prior upload';
				bullet0 =
					<li style={{marginBottom: 10}}>
						To append new data to your existing account records,
						your enrichment sheet must include the same unique account ID that you used in your original upload.
					</li>;
				bullet3 = <li style={{marginBottom: 10}}>This column is required.</li>;
				break;
		}

		let isPartnerSheet = uploadType === UPLOAD_PARTNER_SHEET;
		switch (step) {
			case 0:
				return {
					title: 'Upload your ' + this.uploadCsvLabel(uploadType) + ' CSV following our best practices',
					content: <CsvBestPractices uploadType={uploadType}/>
				};
			case 1:
				return {
					title: step1Title,
					content:
						<ul style={{paddingLeft: 20, textAlign: 'left'}}>
							{bullet0}
							{bullet1}
							{bullet2}
							{bullet3}
						</ul>
				};
			case 2:
				return {
					title: 'Confirm that recommended columns are assigned correctly',
					content:
						<ul style={{paddingLeft: 20, textAlign: 'left'}}>
							{bullet1}
							{bullet2}
						</ul>
				};
			case 3:
				return {
					title: 'Select which custom columns to include in your upload' + (!isPartnerSheet ? ' and tag any that contain PII' : ''),
					content:
						<ul style={{paddingLeft: 20, textAlign: 'left'}}>
							<li style={{marginBottom: 10}}>
								<b>Selecting custom columns:</b><br/>
								Bringing in additional data through custom columns helps you create more granular, hyper-targeted reports to action.
								You will still have full control over which columns are shared to each partner.
							</li>
							{!isPartnerSheet &&
							 <li style={{marginBottom: 10}}>
								 <b>Tagging data as PII (Personally Identifiable Information) is optional:</b><br/>
								 This is only applicable for organizations that have opt-in/opt-out policies for sharing employee information.
							 </li>}
						</ul>
				};
			default:
				return {
					title: 'Unknown Step: ' + step,
					content: 'Error'
				};
		}
	}
}
