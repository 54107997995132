import objectAssign from 'object-assign';
import * as ReduxEnum from './ReduxEnum';

export default function notificationsState(state = {
	message: {},
	updateRoute: '',
	partnerRequestCount: -1
}, action) {
	switch (action.type) {
		case ReduxEnum.RECEIVED_NOTIFICATION: {
			let newState = objectAssign({}, state);
			newState.message = action.payload;
			return newState;
		}
		case ReduxEnum.CLEAR_NOTIFICATION: {
			let newState = objectAssign({}, state);
			newState.message = {};
			return newState;
		}
		case ReduxEnum.UPDATE_ROUTE_FROM_NOTIFICATION: {
			let newState = objectAssign({}, state);
			newState.updateRoute = action.route;
			return newState;
		}
		case ReduxEnum.PARTNER_REQUEST_COUNT: {
			let newState = objectAssign({}, state);
			newState.partnerRequestCount = action.count;
			return newState;
		}
		default:
			return state;
	}
}
