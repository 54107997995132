import {LocalDateTime} from '@js-joda/core';
import {Forum} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import IntelChat from '../../app/sales_network/intel/IntelChat';
import IntelRowRenderer from '../../app/sales_network/intel/IntelRowRenderer';
import * as AccountsEndpoints from '../../endpoints/AccountsEndpoints';
import * as ChatEndpoints from '../../endpoints/ChatEndpoints';
import * as ProfileEndpoints from '../../endpoints/ProfilesEndpoints';
import {Routes} from '../../globals/Routes';
import ChatHelper from '../../helpers/ChatHelper';
import EcosystemShareHelper from '../../helpers/EcosystemShareHelper';
import EnvHelper from '../../helpers/EnvHelper';
import {PRODUCT_CHANNEL_ECOSYSTEM, PRODUCT_SALES_NETWORK} from '../../helpers/FeatureHelper';
import * as ChatActions from '../../redux/IntelActions';
import {partnerTapAppBackground, partnerTapStroke} from '../../styles/partnertap_theme';
import ChatButton from '../../ui/buttons/ChatButton';
import ScrollingContainer from '../../ui/lists/ScrollingContainer';
import Loading from '../../ui/Loading';
import PageHeader from '../../ui/PageHeader';
import PartnerTapIcon from '../../ui/PartnerTapIcon';

export class IntelPage extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {loading: true, chatThreads: [], selectedThread: null, forceUpdateKey: 0};

		this.getChatThreads = this.getChatThreads.bind(this);
		this.selectOrCreateChatThread = this.selectOrCreateChatThread.bind(this);
		this.getChatThreadInfo = this.getChatThreadInfo.bind(this);
		this.onPartnerSelected = this.onPartnerSelected.bind(this);
		this.onAccountSelected = this.onAccountSelected.bind(this);
		this.forceChatThreadListRender = this.forceChatThreadListRender.bind(this);
	}

	componentDidMount() {
		if (!this.routeToProductIntel()) {
			this.getChatThreads();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.blockUpdateDispatch) {
			this.blockUpdateDispatch = true;
			setTimeout(() => {
				if (this.unmounted) return;
				ChatHelper.currentChatThreads = this.state.chatThreads;
				this.props.dispatch(ChatActions.updateChat());
				this.blockUpdateDispatch = false;
			});
		}

		if (this.props.intelState.personChatId) {
			this.props.dispatch(ChatActions.hideChat());
			this.selectOrCreateChatThread(this.props.intelState.personChatId, true);
		}

		if (EnvHelper.isDesktop) {
			setTimeout(() => {
				if (this.unmounted) return;
				if (this.state.selectedThread) {
					let threadItemDiv = document.getElementById(this.state.selectedThread.personChatId);
					if (threadItemDiv) threadItemDiv.scrollIntoView({behavior: 'smooth'});
				}
			}, 1000);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	routeToProductIntel() {
		let {personChatId} = this.props.match.params;
		if (personChatId && EnvHelper.path.indexOf(Routes.INTEL_ALL_PRODUCTS.PAGE_NAME) !== -1) {
			ChatEndpoints.fetchChat(personChatId)
			.then((result) => {
				if (this.unmounted) return;
				let thread = result.payload;
				if (thread.partnerProductCode === PRODUCT_SALES_NETWORK) {
					EnvHelper.push(Routes.DASHBOARD.INTEL.THREAD.PATH(thread.personChatId));
				}
				else if (thread.partnerProductCode === PRODUCT_CHANNEL_ECOSYSTEM) {
					EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.PARTNER_CONNECTIONS.INTEL.PARTNER.PATH(thread.partnerPersonId, thread.personChatId));
				}
			});
			return true;
		}
		return false;
	}

	getPersonChatId(props) {
		return this.getParamValue(props.intelState.personChatId || props.match.params.personChatId);
	}

	getParamValue(param) {
		return (param && param !== '_') ? param : '';
	}

	get isAccountRequired() {
		return EnvHelper.inSalesNetworkProduct;
	}

	get showChatList() {
		return !(EnvHelper.inChannelEcosystemProduct && this.props.inDetails);
	}

	getChatThreads() {
		let personChatId = this.getPersonChatId(this.props);
		let partnerPersonId = this.getParamValue(this.props.match.params.partnerPersonId);
		let saleAccountLocationId = this.getParamValue(this.props.match.params.saleAccountLocationId);
		let partnerAndAccountSpecified = Boolean(partnerPersonId && (!this.isAccountRequired || saleAccountLocationId));

		let fetchItems = [];
		if (EnvHelper.inChannelEcosystemProduct) {
			fetchItems.push(ChatEndpoints.fetchChatLatest(null, null));
		}
		else {
			fetchItems.push(ChatEndpoints.fetchChatLatest(partnerPersonId, saleAccountLocationId));
		}
		Promise.all(fetchItems)
		.then((results) => {
			if (this.unmounted) return;
			let chatThreads = results[0].payload;
			ChatHelper.initChatThreads(this.props.authState.person.id, chatThreads);
			this.setState({chatThreads: chatThreads});

			let selectedThread;
			if (personChatId) {
				selectedThread = chatThreads.find((thread) => {
					return thread.personChatId === personChatId;
				});
			}

			if (!selectedThread && partnerAndAccountSpecified) {
				selectedThread = chatThreads.find((thread) => {
					let partnerMatch = thread.partnerPersonId === partnerPersonId;
					let accountMatch = !this.isAccountRequired || (thread.saleAccountLocationId === saleAccountLocationId);
					return partnerMatch && accountMatch;
				});
			}

			if (!selectedThread && (partnerAndAccountSpecified || !chatThreads.length || personChatId === ChatHelper.NEW_CHAT)) {
				selectedThread = this.newChatThread();
				this.setState({chatThreads: [selectedThread, ...chatThreads]});
			}

			if (EnvHelper.isDesktop && this.showChatList && !selectedThread && chatThreads.length) {
				selectedThread = chatThreads[0];
			}

			if (!selectedThread && !this.showChatList) {
				selectedThread = this.newChatThread();
			}

			if (selectedThread) {
				this.getChatThreadInfo(selectedThread);
			}
			else {
				this.setState({loading: false});
			}

			this.props.dispatch(ChatActions.updateChat());
		})
		.catch((error) => {
			console.error('Error trying to getChatThreads', error);
		});
	}

	selectOrCreateChatThread(personChatId, isFromDispatch) {
		let {chatThreads, selectedThread} = this.state;
		if (personChatId && (!selectedThread || selectedThread.personChatId !== personChatId)) {
			let newSelectedThread = chatThreads.find((thread) => thread.personChatId === personChatId);
			if (newSelectedThread) {
				this.getChatThreadInfo(newSelectedThread);
			}
			else {
				if (personChatId === ChatHelper.NEW_CHAT) {
					newSelectedThread = this.newChatThread(isFromDispatch);

					let existingNewSelectedThread = chatThreads.find((thread) => thread.personChatId === newSelectedThread.personChatId);
					if (existingNewSelectedThread) {
						this.setState({selectedThread: existingNewSelectedThread});
					}
					else {
						this.setState({chatThreads: [newSelectedThread, ...chatThreads]});
						this.selectThread(newSelectedThread);
						return;
					}
				}

				let topThread = chatThreads[0];
				if (topThread && selectedThread && topThread.personChatId === selectedThread.personChatId) {
					return;
				}

				if (topThread && topThread.personChatId !== selectedThread.personChatId) {
					this.setState({selectedThread: chatThreads[0]});
				}
			}
		}
	}

	getChatThreadInfo(thread) {
		let fetchItems = [];
		if (thread.partnerPersonId && !ChatHelper.hasPartner(thread)) {
			fetchItems.push(ProfileEndpoints.fetchProfile(thread.partnerPersonId));
		}
		if (thread.personSaleAccountId && !ChatHelper.hasAccount(thread)) {
			let partnerPersonId;
			if (this.props.match.params.fromPartnerOpp) {
				partnerPersonId = thread.partnerPersonId;
			}
			fetchItems.push(AccountsEndpoints.fetchAccount(thread.personSaleAccountId, partnerPersonId));
		}
		if (fetchItems.length === 0) {
			this.selectThread(thread);
			return;
		}
		if (!EnvHelper.isDesktop) this.setState({loading: true});
		Promise.all(fetchItems)
		.then((results) => {
			if (this.unmounted) return;
			let resultsIndex = 0;

			if (thread.partnerPersonId) {
				let partnerProfile = results[resultsIndex++].payload;
				ChatHelper.setChatValuesFromPartner(thread, partnerProfile);
			}

			if (thread.personSaleAccountId) {
				let saleAccount = results[resultsIndex].payload;
				ChatHelper.setChatValuesFromAccount(thread, saleAccount);
			}
			this.selectThread(thread);
		})
		.catch((error) => {
			console.error('Error trying to getChatThreadInfo', error);
		});
	}

	selectThread(selectedThread) {
		let forceUpdateKey = Math.random();
		this.state.chatThreads.forEach((thread) => {
			thread.forceUpdateKey = 1000000 * forceUpdateKey++;
			thread.selected = false;
		});
		selectedThread.forceUpdateKey++;
		selectedThread.selected = true;
		this.setState({selectedThread: selectedThread, loading: false});
	}

	newChatThread(isFromDispatch) {
		let partnerPersonId;
		let personSaleAccountId;
		let saleAccountLocationId;
		if (EnvHelper.inSalesNetworkProduct && !isFromDispatch) {
			partnerPersonId = this.getParamValue(this.props.match.params.partnerPersonId);
			personSaleAccountId = this.getParamValue(this.props.match.params.personSaleAccountId);
			saleAccountLocationId = this.getParamValue(this.props.match.params.saleAccountLocationId);
		}
		else if (this.props.partner) {
			partnerPersonId = this.props.partner.personId;
		}

		let newThread = {
			personChatId: ChatHelper.NEW_CHAT,
			partnerName: 'Select Partner',
			saleAccountName: this.isAccountRequired ? 'Select Account' : '',
			chatDate: '' + LocalDateTime.now(),
			message: 'New Intel',
			forceUpdateKey: Math.random(),
			partnerPersonId: partnerPersonId,
			personSaleAccountId: personSaleAccountId,
			saleAccountLocationId: saleAccountLocationId
		};

		if (this.props.intelState.partner) {
			ChatHelper.setChatValuesFromPartner(newThread, this.props.intelState.partner);
		}
		if (this.props.intelState.account) {
			ChatHelper.setChatValuesFromAccount(newThread, this.props.intelState.account);
		}

		if ((EnvHelper.inChannelEcosystemProduct && partnerPersonId) || (EnvHelper.inSalesNetworkProduct && partnerPersonId && personSaleAccountId)) {
			this.initializeNewChat(newThread);
		}

		return newThread;
	}

	onPartnerSelected(partner) {
		let {chatThreads, selectedThread} = this.state;
		let hasAccount = ChatHelper.hasAccount(selectedThread);
		let affectedThread;
		if (hasAccount || !this.isAccountRequired) {
			affectedThread = chatThreads.find((thread) => {
				let partnerMatch = thread.partnerPersonId === partner.personId;
				let accountMatch = !this.isAccountRequired || thread.saleAccountLocationId === selectedThread.saleAccountLocationId;
				return partnerMatch && accountMatch;
			});

			if (affectedThread) {
				this.state.chatThreads.shift();
				this.getChatThreadInfo(affectedThread);
			}
		}

		if (!affectedThread && this.isNewThread(selectedThread)) {
			ChatHelper.setChatValuesFromPartner(selectedThread, partner);
			this.initializeNewChat(selectedThread);
			this.forceChatThreadListRender();
		}
	}

	onAccountSelected(account) {
		let {chatThreads, selectedThread} = this.state;
		let affectedThread = chatThreads.find((thread) => {
			let partnerMatch = thread.partnerPersonId === selectedThread.partnerPersonId;
			let accountMatch = thread.saleAccountLocationId === account.saleAccountLocationId;
			return partnerMatch && accountMatch && !this.isNewThread(thread);
		});
		if (affectedThread) {
			this.state.chatThreads.shift();
			this.getChatThreadInfo(affectedThread);
		}
		else {
			ChatHelper.setChatValuesFromAccount(selectedThread, account);
			this.initializeNewChat(selectedThread);
			this.forceChatThreadListRender();
		}
	}

	forceChatThreadListRender() {
		if (EnvHelper.isDesktop) {
			this.setState({forceUpdateKey: this.state.forceUpdateKey + 1});
		}
		else {
			this.setState({selectedThread: this.state.selectedThread});
		}
	}

	initializeNewChat(thread) {
		thread.personChatId = this.temporaryChatId(thread);
		thread.message = 'New Intel';
		thread.forceUpdateKey++;
	}

	isNewThread(thread) {
		return thread &&
			   ((!thread.partnerPersonId || (EnvHelper.inSalesNetworkProduct && !thread.saleAccountLocationId)) &&
			   (thread.personChatId === ChatHelper.NEW_CHAT || thread.personChatId === this.temporaryChatId(thread)));
	}

	temporaryChatId(thread) {
		if (!thread) return '';
		return thread.partnerPersonId + (thread.saleAccountLocationId ? '_' + thread.saleAccountLocationId : '_NO_ACCOUNT');
	}

	render() {
		if (this.state.loading) return <Loading>Loading Intel...</Loading>;
		let {chatThreads, selectedThread, forceUpdateKey} = this.state;
		let headerTitle = EnvHelper.inChannelEcosystemProduct ? 'Collaborate' : 'All Intel';
		let headerIcon = EnvHelper.inChannelEcosystemProduct ?
			<Forum/> :
			<PartnerTapIcon glyph={'c'} style={Object.assign(PartnerTapIcon.headerIconStyle, {paddingTop: 7, paddingRight: 0, width: 36})}/>;
		let showChatList = this.showChatList;
		let showReachOutActions = !EnvHelper.isDesktop && !showChatList;
		let chatList = showChatList ?
			<div key={forceUpdateKey} style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'scroll', backgroundColor: partnerTapAppBackground}}>
				{chatThreads.map((chatThread, index) => <IntelRowRenderer key={chatThread.forceUpdateKey || index} rowData={chatThread}/>)}
			</div>
			:
			(showReachOutActions ?
				<div style={{paddingTop: 10}}>
					{EcosystemShareHelper.renderReachOutActions(this.props.partner)}
				</div>
				:
				<div/>);
		let chatPage = <IntelChat key={(selectedThread ? selectedThread.forceUpdateKey : 0)}
								  currentThread={selectedThread}
								  onPartnerSelected={this.onPartnerSelected}
								  onAccountSelected={this.onAccountSelected}
								  onChatSent={this.forceChatThreadListRender}
								  hideChatHeader={EnvHelper.isDesktop}
								  noHeightOffset={!EnvHelper.isDesktop && Boolean(selectedThread)}/>;
		return (
			<ScrollingContainer divId={'intel_page'}>
				{!this.props.inDetails && !(!EnvHelper.isDesktop && selectedThread) &&
				 <PageHeader title={headerTitle} icon={headerIcon} count={chatThreads.length}>
					 <div style={{display: 'flex', justifyContent: 'flex-end'}}>
						 <ChatButton isNewChat={true}/>
					 </div>
				 </PageHeader>}
				{EnvHelper.isDesktop ?
					<div style={{
						flex: 1,
						display: 'flex',
						overflow: 'hidden',
						border: '1px solid ' + partnerTapStroke,
						margin: showChatList ? 5 : 'auto',
						width: showChatList ? '' : '100%',
						maxWidth: showChatList ? 'none' : 580,
						marginTop: 0
					}}>
						{chatList}
						<div style={{
							flex: 2,
							display: 'flex',
							flexDirection: 'column',
							borderLeft: showChatList ? '1px solid ' + partnerTapStroke : 'none'
						}}>
							{chatPage}
						</div>
					</div>
					:
					(selectedThread ? chatPage : chatList)}
			</ScrollingContainer>
		);
	}
}

IntelPage.propTypes = {
	partner: PropTypes.object,
	inDetails: PropTypes.bool,
	intelState: PropTypes.object.isRequired,
	authState: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		intelState: state.intelState,
		authState: state.authState
	};
}

export default connect(mapStateToProps)(IntelPage);
