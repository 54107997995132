import {LICENCE_PAID} from '../globals/Enums';
import EnvHelper from './EnvHelper';

// product codes
export const PRODUCT_CHANNEL_ECOSYSTEM = 'CHANNEL';
export const PRODUCT_MANAGER_ANALYTICS = 'MANAGER_ANALYTICS';
export const PRODUCT_SALES_NETWORK = 'SALES';
export const PRODUCT_ADMIN_TOOLS = 'ADMIN_TOOLS';
export const PRODUCT_CO_SELL_ENGINE = 'CO_SELL_ENGINE';

// feature codes
export const FEATURE_ADMIN_TOOLS_EDITORS = 'ADMIN_TOOLS_ADMINISTRATOR';
export const FEATURE_ADMIN_TOOLS_REPORTS = 'ADMIN_TOOLS_RUN_REPORTS';
export const FEATURE_REQUIRE_API_KEY_MANAGEMENT = 'REQUIRE_API_KEY_MANAGEMENT';
export const FEATURE_CHANNEL_PARTNER_ORGANIZATIONS_VISIBILITY = 'PARTNER_ORGANIZATIONS_VISIBILITY';
export const FEATURE_CHANNEL_AUDIT_PARTNER_SHEET = 'CHANNEL_AUDIT_PARTNER_SHEET';
export const FEATURE_CHANNEL_ECOSYSTEM_ACCESS = 'CHANNEL_DATA_ACCESS';
export const FEATURE_CHANNEL_DATA_MANAGER = 'CHANNEL_DATA_MANAGER';
export const FEATURE_CHANNEL_DOWNLOAD_REPORTS = 'CHANNEL_DOWNLOAD_REPORTS';
export const FEATURE_CHANNEL_GREENFIELD_ACCOUNT_SHARING = 'CHANNEL_GREENFIELD_ACCOUNT_SHARING';
export const FEATURE_CHANNEL_GREENFIELD_OPP_SHARING = 'CHANNEL_GREENFIELD_OPP_SHARING';
export const FEATURE_CHANNEL_MATCHING_BY_LOCATION_CONNECTED = 'CHANNEL_MATCHING_BY_LOCATION_CONNECTED';
export const FEATURE_CHANNEL_MATCHING_BY_LOCATION_SHEETS = 'CHANNEL_MATCHING_BY_LOCATION_SHEETS';
export const FEATURE_CHANNEL_SHARE_OPPS = 'CHANNEL_SHARE_OPPS';
export const FEATURE_CHANNEL_SHARE_OPPS_CONNECTED_REPORTS = 'CHANNEL_SHARE_OPPS_CONNECTED_REPORTS';
export const FEATURE_CHANNEL_UPLOAD_ACCOUNTS_MANUALLY = 'CHANNEL_UPLOAD_ACCOUNTS_MANUALLY';
export const FEATURE_CHANNEL_UPLOAD_AUGMENTED_ACCOUNT_DATA = 'CHANNEL_UPLOAD_AUGMENTED_ACCOUNT_DATA';
export const FEATURE_CHANNEL_UPLOAD_PARTNER_SHEETS = 'CHANNEL_UPLOAD_PARTNER_SHEETS';
export const FEATURE_CHANNEL_VIEW_ACCOUNT_DETAIL_PAGE = 'CHANNEL_VIEW_ACCOUNT_DETAIL_PAGE';
export const FEATURE_CHANNEL_VIEW_ECOSYSTEM_ACCOUNT_REPORTS = 'CHANNEL_VIEW_ECOSYSTEM_ACCOUNT_REPORTS';
export const FEATURE_CHANNEL_VIEW_ECOSYSTEM_OPP_REPORTS = 'CHANNEL_VIEW_ECOSYSTEM_OPP_REPORTS';
export const FEATURE_CHANNEL_VIEW_GREENFIELD_ACCOUNTS = 'CHANNEL_VIEW_GREENFIELD_ACCOUNTS';
export const FEATURE_CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES = 'CHANNEL_VIEW_GREENFIELD_OPPORTUNITIES';
export const FEATURE_CHANNEL_VIEW_USER_REPORT = 'CHANNEL_VIEW_USER_REPORT';
export const FEATURE_CHANNEL_VIEW_SUBORDINATE_REPORTS = 'CHANNEL_VIEW_SUBORDINATE_REPORTS';
export const FEATURE_CHANNEL_VIEW_OPP_DETAIL_PAGE = 'CHANNEL_VIEW_OPP_DETAIL_PAGE';
export const FEATURE_CHANNEL_CRM_SYNC = 'CHANNEL_CRM_SYNC';
export const FEATURE_CHANNEL_REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO = 'REQUIRE_OPT_IN_TO_SHARE_CONTACT_INFO';
export const FEATURE_MANAGER_ANALYTICS_ACCESS = 'MANAGER_ANALYTICS_DATA_ACCESS';
export const FEATURE_MANAGER_ANALYTICS_ADMIN_REPORTS = 'MANAGER_VIEW_ADMIN_REPORTS';

export const FEATURE_SALES_NETWORK_ACCESS = 'SALES_DATA_ACCESS';

export const FEATURE_COSELL_CREATE_ACTION_LISTS = 'COSELL_CREATE_ACTION_LISTS';
export const FEATURE_COSELL_EDIT_ACTION_LISTS = 'COSELL_EDIT_ACTION_LISTS';
export const FEATURE_COSELL_EDIT_ALL_ACTION_LISTS = 'COSELL_EDIT_ALL_ACTION_LISTS';
export const FEATURE_COSELL_VIEW_ALL_ACTION_LISTS = 'COSELL_VIEW_ALL_ACTION_LISTS';
export const FEATURE_COSELL_RUN_COSELL_PLAY_WORKFLOWS = 'COSELL_RUN_COSELL_PLAY_WORKFLOWS';
export const FEATURE_COSELL_RUN_SALES_PLAY_WORKFLOWS = 'COSELL_RUN_SALES_PLAY_WORKFLOWS';
export const FEATURE_COSELL_RUN_THRU_CHANNEL_SALES_PLAY_WORKFLOWS = 'COSELL_RUN_THRU_CHANNEL_SALES_PLAY_WORKFLOWS';

export let tempFeatureFlag_CoSellEngine = false;
export let tempFeatureFlag_CoSellEngine_live = false;
export let tempFeatureFlag_UploadFullServiceCsv = false;

export default class FeatureHelper {

	static set roles(roles) {
		console.info('Roles:', roles);
		this.allRoles = roles;
	}

	static get roles() {
		return this.allRoles;
	}

	static set products(products) {
		console.info('Products:', products);
		this.userProducts = products;
	}

	static get products() {
		return this.userProducts;
	}

	static set features(features) {
		console.info('Features:', features);
		this.userFeatures = features;
		tempFeatureFlag_CoSellEngine_live = !EnvHelper.isDemoTier;
		tempFeatureFlag_CoSellEngine = tempFeatureFlag_CoSellEngine_live || EnvHelper.isDemoTier;
	}

	static isProductPaid(productCode) {
		return Boolean(this.userProducts?.find((product) => product.productCode === productCode && product.license === LICENCE_PAID));
	}

	static isProductEnabled(productCode) {
		return Boolean(this.userProducts?.find((product) => product.productCode === productCode && product.enable));
	}

	static isFeatureEnabled(featureCode) {
		return Boolean(this.userFeatures?.find((feature) => feature.featureCode === featureCode && feature.enable));
	}

	static isFeatureEnabledForRole(featureCode, role) {
		return Boolean(role.features?.find((feature) => feature.featureCode === featureCode && feature.enable));
	}
}
