import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import {MenuItem, Popover} from '@mui/material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {MAX_ITEMS_FOR_SIMPLE_FILTER_SELECTOR} from '../helpers/FilterHelper';
import SortHelper from '../helpers/SortHelper';
import StringHelper from '../helpers/StringHelper';
import {partnerTapDefaultText, partnerTapSecondary, partnerTapStroke} from '../styles/partnertap_theme';
import SearchBox from '../ui/SearchBox';
import CheckboxButton from './buttons/CheckboxButton';
import PrimaryButton from './buttons/PrimaryButton';
import SecondaryButton from './buttons/SecondaryButton';

class PopoverSearchList extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			search: this.props.pageableSearch,
			selectedItem: this.props.preselectedItem || null,
			originalPreselectedItem: this.props.preselectedItem || null,
			isOpen: false,
			anchorElement: null
		};
		this.handleSearchChanged = this.handleSearchChanged.bind(this);
		this.handleTogglePopover = this.handleTogglePopover.bind(this);
		this.setAll = this.setAll.bind(this);
		this.isChecked = this.isChecked.bind(this);
		this.setChecked = this.setChecked.bind(this);
		this.onItemSelection = this.onItemSelection.bind(this);
		this.saveOriginalValues = this.saveOriginalValues.bind(this);
		this.restoreOriginalValues = this.restoreOriginalValues.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.preselectedItem && props.preselectedItem !== state.originalPreselectedItem) {
			return {selectedItem: props.preselectedItem, originalPreselectedItem: props.preselectedItem};
		}
		return null;
	}

	componentDidMount() {
		this.saveOriginalValues();
	}

	handleSearchChanged(search) {
		this.setState({search: search});
		if (this.props.pageableSearchHandler) {
			this.props.pageableSearchHandler(search);
		}
	}

	handleTogglePopover(event, restoreOriginalValuesOnClose) {
		if (this.props.disabled) return;
		if (event && !this.state.anchorElement) {
			this.setState({anchorElement: event.currentTarget});
		}
		let isOpen = !this.state.isOpen;
		this.setState({isOpen: isOpen, search: null});
		if (!isOpen) {
			if (this.props.isMultipleChoice && !this.props.doNotRestoreValues) {
				if (restoreOriginalValuesOnClose) {
					this.restoreOriginalValues();
				}
				else {
					this.saveOriginalValues();
				}
			}
		}
	}

	saveOriginalValues() {
		if (this.props.isMultipleChoice) {
			this.props.list.forEach((item) => {
				item.originalValue = this.isChecked(item);
			});
		}
	}

	restoreOriginalValues() {
		if (this.props.isMultipleChoice) {
			this.props.list.forEach((item) => {
				this.setChecked(item, item.originalValue);
			});
		}
	}

	get buttonLabel() {
		let itemSelected;
		let countSelected = 0;
		if (this.props.isMultipleChoice) {
			itemSelected = this.props.list.find((item) => {
				let isChecked = Boolean(item.isChecked);
				if (this.props.isCheckedFunction) {
					isChecked = this.props.isCheckedFunction(item);
				}
				return isChecked;
			});
			this.props.list.forEach((item) => {
				let isChecked = Boolean(item.isChecked);
				if (this.props.isCheckedFunction) {
					isChecked = this.props.isCheckedFunction(item);
				}
				if (isChecked) {
					countSelected++;
				}
			});
		}
		else {
			itemSelected = this.state.selectedItem;
			countSelected = itemSelected ? 1 : 0;
		}

		if (countSelected === 1) {
			if (this.props.labelRenderer) {
				return this.props.labelRenderer(itemSelected);
			}
			else {
				return itemSelected;
			}
		}
		else if (this.props.isMultipleChoice) {
			let label = this.props.label;
			let count = countSelected === 0 ? '' : countSelected + ' ';
			let lastChar = label.substring(label.length - 1);
			if (lastChar === 'y') {
				label = label.substring(0, label.length - 1);
				return count + label + (countSelected === 1 ? 'y' : 'ies');
			}
			else if (lastChar === 'o') {
				label = label.substring(0, label.length - 1);
				return count + label + (countSelected === 1 ? 'o' : 'oes');
			}
			else if (lastChar === 'f') {
				label = label.substring(0, label.length - 1);
				return count + label + (countSelected === 1 ? 'f' : 'ves');
			}
			else {
				return count + label + (countSelected === 1 ? '' : 's');
			}
		}
		return this.props.label;
	}

	get filteredList() {
		if (this.props.pageableSearchHandler) {
			return this.props.list;
		}
		else if (this.props.searchHandler) {
			return this.props.searchHandler(this.state.search, this.props.list);
		}
		else if (this.props.searchByObjectKeys) {
			return SortHelper.filterSearchObjectContains(this.state.search, this.props.list, this.props.searchByObjectKeys);
		}
		else {
			return SortHelper.filterSearchArray(this.state.search, this.props.list);
		}
	}

	setAll(list, isChecked) {
		list.forEach((item) => this.setChecked(item, isChecked));
		this.onItemSelection();
		if (this.props.isMultipleChoice) this.forceUpdate(); // so checkboxes update immediately
	}

	isChecked(item) {
		if (this.props.isCheckedFunction) {
			return Boolean(this.props.isCheckedFunction(item));
		}
		return Boolean(item.isChecked);
	}

	setChecked(item, isChecked) {
		if (this.props.setCheckedFunction) {
			this.props.setCheckedFunction(item, isChecked);
		}
		else {
			item.isChecked = isChecked;
		}
	}

	onItemSelection(item) {
		if (!this.props.isMultipleChoice) {
			this.props.onItemSelected(item);
		}
	}

	makeMenuItem(item, index) {
		let isMultipleChoice = this.props.isMultipleChoice;
		let isChecked = this.isChecked(item) || (!isMultipleChoice && this.state.selectedItem === item);
		return (
			<MenuItem key={'popover_search_list_' + index}
					  data-cy={'popover_search_list_item_' + index}
					  selected={isChecked}
					  value={this.props.valueRenderer ? this.props.valueRenderer(item) : item}
					  onClick={() => {
						  if (isMultipleChoice) this.setChecked(item, !isChecked);
						  this.setState({selectedItem: item});
						  this.onItemSelection(item);
						  if (!isMultipleChoice) this.handleTogglePopover();
					  }}>
				{isMultipleChoice && <CheckboxButton checked={isChecked}/>}
				{this.props.labelRenderer ? this.props.labelRenderer(item) : item}
			</MenuItem>
		);
	}

	render() {
		let {disabled, loadMore, isMultipleChoice, noOutline} = this.props;
		let list = this.filteredList;
		let showSearch = list.length > MAX_ITEMS_FOR_SIMPLE_FILTER_SELECTOR || this.state.search;
		return (
			<div>
				{this.props.customToggleButton ?
					<div onClick={(event) => this.handleTogglePopover(event, true)}>
						{this.props.customToggleButton}
					</div>
					:
					<div onClick={(event) => this.handleTogglePopover(event, true)}
						 data-cy={'popover_search_list_' + (this.props.label ? StringHelper.formatKey(this.props.label) : list.length)}
						 style={{
							 display: 'flex',
							 alignItems: 'center',
							 justifyContent: 'space-between',
							 minWidth: this.props.minWidth || 'auto',
							 maxWidth: this.props.maxWidth || 'auto',
							 margin: 'auto',
							 height: 40,
							 paddingLeft: 10,
							 border: noOutline ? null : '1px solid ' + partnerTapStroke,
							 borderRadius: 4,
							 backgroundColor: 'rgba(255, 255, 255, 0.5)',
							 cursor: 'pointer'
						 }}>
						<div style={{
							color: disabled ? partnerTapStroke : partnerTapDefaultText,
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							paddingRight: 10
						}}>
							{this.buttonLabel}
						</div>
						<div style={{
							color: disabled ? partnerTapStroke : partnerTapSecondary,
							borderLeft: noOutline ? null : '1px solid ' + partnerTapStroke,
							paddingTop: 5,
							paddingLeft: 5
						}}>
							{this.state.isOpen ? <ArrowDropUp/> : <ArrowDropDown/>}
						</div>
					</div>}
				<Popover open={this.state.isOpen || Boolean(this.state.search)}
						 anchorEl={this.state.anchorElement}
						 anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
						 transformOrigin={{horizontal: 'left', vertical: 'top'}}
						 onClose={(event) => this.handleTogglePopover(event, true)}>
					{showSearch &&
					 <div style={{padding: 4}}>
						 <SearchBox defaultValue={this.state.search} onSearch={this.handleSearchChanged} autoApply={true}/>
						 {isMultipleChoice &&
						  <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: 4}}>
							  <SecondaryButton label={'Check All'} onClick={() => this.setAll(list, true)}/>
							  <SecondaryButton label={'Clear All'} onClick={() => this.setAll(list, false)}/>
						  </div>}
					 </div>}
					<div style={{margin: 4, maxHeight: '50vh', overflow: 'scroll'}}>
						{list.map((item, index) => {
							return this.makeMenuItem(item, index, showSearch);
						})}
						{loadMore &&
						 <MenuItem style={{fontWeight: 'bold'}} onClick={loadMore}>
							 + Load More
						 </MenuItem>}
						{!list.length &&
						 <div style={{
							 display: 'flex',
							 flexDirection: 'column',
							 alignItems: 'center',
							 padding: 10,
							 fontSize: 16,
							 fontWeight: 'bold',
							 whiteSpace: 'nowrap'
						 }}>
							 {this.state.search ? 'No Search Results' : 'No Results'}
						 </div>}
					</div>
					{this.props.isMultipleChoice &&
					 <div style={{display: 'flex', paddingTop: 5, justifyContent: 'center'}}>
						 {!this.props.doNotRestoreValues &&
						  <div style={{padding: 5}}>
							  <SecondaryButton label={'Cancel'} onClick={(event) => this.handleTogglePopover(event, true)}/>
						  </div>}
						 <div style={{padding: 5}}>
							 <PrimaryButton label={this.props.doNotRestoreValues ? 'Done' : 'Apply'}
											onClick={(event) => {
												if (this.props.isMultipleChoice) {
													this.handleTogglePopover(event, false);
												}
												if (this.props.onItemSelected) {
													this.props.onItemSelected();
												}
											}}/>
						 </div>
					 </div>}
				</Popover>
			</div>
		);
	}
}

PopoverSearchList.propTypes = {
	label: PropTypes.string,
	list: PropTypes.array.isRequired,
	preselectedItem: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
	onItemSelected: PropTypes.func,
	labelRenderer: PropTypes.func,
	valueRenderer: PropTypes.func,
	searchHandler: PropTypes.func,
	minWidth: PropTypes.number,
	maxWidth: PropTypes.number,
	noOutline: PropTypes.bool,
	disabled: PropTypes.bool,
	pageableSearch: PropTypes.string,
	pageableSearchHandler: PropTypes.func,
	loadMore: PropTypes.func,
	searchByObjectKeys: PropTypes.array,
	isMultipleChoice: PropTypes.bool,
	doNotRestoreValues: PropTypes.bool,
	isCheckedFunction: PropTypes.func,
	setCheckedFunction: PropTypes.func,
	customToggleButton: PropTypes.object,
	hintText: PropTypes.string
};

export default PopoverSearchList;
