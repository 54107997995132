import {UPLOAD_PII_SHARING_OPT_IN} from '../globals/Enums';
import * as ApiHelper from '../helpers/ApiHelper';
import {MEDIA_TYPE_MULTIPART} from '../helpers/ApiHelper';

export const CHANNEL_DOWNLOAD_ENDPOINT = '/channelecosystem/download';

export function uploadCSV(file, fileName, fileDescription, importType, orgCode, orgId, companyPartnerPublicId, newPartnerOrgName, acceptBlankHeaders) {
	const URI = importType === UPLOAD_PII_SHARING_OPT_IN ? '/pii/upload/optin' : '/channelmap/upload';
	let endpoint = URI + '?fileName=' + encodeURIComponent(fileName) + '&fileDescription=' + encodeURIComponent(fileDescription) + '&importType=' + importType;
	if (companyPartnerPublicId) endpoint += '&companyPartnerPublicId=' + companyPartnerPublicId;
	if (newPartnerOrgName) endpoint += '&partnerName=' + newPartnerOrgName;
	if (orgCode) endpoint += '&partnerOrgCode=' + orgCode;
	if (orgId) endpoint += '&orgId=' + orgId;
	if (acceptBlankHeaders) endpoint += '&acceptBlankHeaders=true';
	return ApiHelper.sendPost(endpoint, file, MEDIA_TYPE_MULTIPART);
}

export function addNewPartnerOrg(partnerName) {
	return ApiHelper.sendPost('/channelmap/addNewCompanyPartner', partnerName);
}

export function updatePartnerOrg(companyPartnerPublicId = '', partnerOrgCode = '', personQueuePublicId) {
	return ApiHelper.sendPost('/channelmap/updatePartnerOrg', {
		'partnerOrgCode': partnerOrgCode,
		'companyPartnerPublicId': companyPartnerPublicId,
		'personQueuePublicId': personQueuePublicId
	});
}

export function getPersonUpload(personUploadId) {
	return ApiHelper.sendGet('/channelmap/uploadInfo/' + personUploadId);
}

export function updatePersonUpload(personUploadId, mappings, virtualHeaders) {
	let payload = {'personUploadId': personUploadId, 'mappings': mappings, 'virtualHeaders': virtualHeaders};
	return ApiHelper.sendPost('/channelmap/mappingupdate', payload);
}

export function updateCustomFields(personUploadId, fields) {
	let payload = {'personUploadId': personUploadId, 'customFields': fields};
	return ApiHelper.sendPost('/channelmap/customfields', payload);
}

export function mappingComplete(personUploadId, customMetadata) {
	let payload = {'personUploadId': personUploadId, 'customMetadata': customMetadata};
	return ApiHelper.sendPost('/channelmap/mappingcomplete', payload);
}

export function saveReport(reportType, reportCategory, reportSettings, reportParams, reportName, reportDescription, reportShared) {
	let payload = {
		'reportType': reportType,
		'reportCategory': reportCategory,
		'reportParams': JSON.stringify(reportParams),
		'settings': JSON.stringify(reportSettings),
		'name': reportName,
		'description': reportDescription,
		'shared': reportShared
	};
	return ApiHelper.sendPost('/savedreport/save', payload);
}

export function getSavedReportData(shared, page, pageSize, search, sort) {
	return ApiHelper.sendGet(ApiHelper.appendPageableParams('/savedreport/list?shared=' + shared, page, pageSize, search, sort));
}

export function updateReport(reportId, reportSettings, reportName, reportDescription, reportShared) {
	let payload = {
		'settings': JSON.stringify(reportSettings),
		'name': reportName,
		'description': reportDescription,
		'shared': reportShared
	};
	return ApiHelper.sendPost('/savedreport/update/' + reportId, payload);
}

export function deleteSavedReport(reportId) {
	return ApiHelper.sendPut('/savedreport/delete/' + reportId);
}

export function getUploadDataForId(personQueueId) {
	return ApiHelper.sendGet('/channelmap/data/' + personQueueId);
}

export function getUploadHistory(importType, page, pageSize, search, sort) {
	return ApiHelper.sendGet(ApiHelper.appendPageableParams('/channelmap/data?importType=' + importType, page, pageSize, search, sort));
}

export function getUploadSheetCount() {
	return ApiHelper.sendGet('/channelmap/data/count');
}

export function getCompanyPartners() {
	return ApiHelper.sendGetReport('/channelecosystem/global/partners');
}

export function saveShareSettings(partnerId, ownerAssetShareOption, filters, columnsChanged, assetType) {
	let endpoint = '/channelpartner/savesharesettings?partnerId=' + partnerId;
	let payload = {
		'ownerAssetShareOption': ownerAssetShareOption,
		'filters': filters || {},
		'columnsChanged': columnsChanged ? columnsChanged : [],
		'assetType': assetType
	};
	return ApiHelper.sendPost(endpoint, payload);
}

export function getShareSettings(partnerId) {
	return ApiHelper.sendGet('/channelpartner/getsharesettings?partnerId=' + partnerId);
}

export function getSharedColumns(partnerId) {
	return ApiHelper.sendGetReport('/channelecosystem/partnerperson/getsharedcolumns?partnerId=' + partnerId);
}

export function getSharingCounts(partnerId) {
	return ApiHelper.sendGetReport('/channelecosystem/partnerperson/sharingcounts?partnerId=' + partnerId);
}

export function archiveUpload(personQueueId) {
	return ApiHelper.sendPut('/channelmap/archive/' + personQueueId, {});
}

export function auditUpload(personQueueId) {
	return ApiHelper.sendPut('/channelmap/audit/' + personQueueId, {});
}

export function getSubordinates() {
	return ApiHelper.sendGet('/channelpartner/subordinates');
}

export function getChannelPartnerOrgs(partnerOrgPublicId = '') {
	return ApiHelper.sendGet('/channelmap/channelpartnerorgs?partnerOrgPublicId=' + partnerOrgPublicId);
}

export function getRecords(args, search, filters, currentPage, pageSize, sort, totalElements) {
	let endpoint = '/channelecosystem/records?page=' + currentPage;
	let payload = {
		'personQueueIds': args.personQueueIds ? args.personQueueIds : [],
		'selectedColumns': args.selectedColumns,
		'search': search || '',
		'filters': filters || {},
		'mapByLocations': Boolean(args.mapByLocations),
		'channelReportType': args.channelReportType,
		'personId': args.personId,
		'matchingType': args.matchingType || 'MUTUAL',
		'partnerOrgCodes': args.partnerOrgCodes || [],
		'reportType': args.reportType,
		'byOrg': args.byOrg,
		'companyPartnerPublicId': args.companyPartnerPublicId,
		'coSellActionListMetadataPublicId': args.coSellActionListMetadataPublicId,
		'importType': args.importType
	};
	if (sort) endpoint += '&sort=' + sort;
	if (pageSize) endpoint += '&size=' + pageSize;
	if (totalElements) {
		endpoint += '&totalElements=' + totalElements;
	}
	else {
		endpoint += '&totalElements=0';
	}
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function getColumns(payload) {
	return ApiHelper.sendPostReport('/channelecosystem/columns', payload);
}

export function getFilterData(args, search, filters, filterField, currentPage, pageSize) {
	let normalizedFilter = filterField.replace(/\s/g, '');
	let endpoint = '/channelecosystem/filterdata?page=' + currentPage + '&size=' + pageSize + '&filterField=' + filterField + '&sort=' + normalizedFilter;
	let payload = {
		'channelReportType': args.channelReportType,
		'matchingType': args.matchingType,
		'personId': args.personId,
		'personQueueIds': args.personQueueIds ? args.personQueueIds : [],
		'search': search || '',
		'filters': filters || {},
		'mapByLocations': Boolean(args.mapByLocations),
		'reportType': args.reportType,
		'companyPartnerPublicId': args.companyPartnerPublicId,
		'byOrg': args.byOrg || false,
		'partnerOrgCodes': args.partnerOrgCodes || [],
		'coSellActionListMetadataPublicId': args.coSellActionListMetadataPublicId
	};
	return ApiHelper.sendPostReport(endpoint, payload);
}

export function getDashboard(args) {
	return ApiHelper.sendPostReport('/channelecosystem/dashboard', args);
}
