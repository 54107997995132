import {Check, DoNotDisturb, Download, JoinInner} from '@mui/icons-material';
import React from 'react';
import {withRouter} from 'react-router-dom';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import EnvHelper from '../../../helpers/EnvHelper';
import PagingBase from '../../../ui/lists/PagingBase';
import ChannelHelper from '../../../helpers/ChannelHelper';
import Loading from '../../../ui/Loading';
import CoSellActionListMetadataLive from './co_sell_components/CoSellActionListMetadataLive';
import {partnerTapTernary, partnerTapTernaryLight, partnerTapWhite} from '../../../styles/partnertap_theme';
import ActionButton from '../../../ui/buttons/ActionButton';

class CoSellActionListResultsPageLive extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.unmounted = false;
		this.state.loading = false;
		this.state.downloading = false;

		this.state.workflowName = null;
		this.state.filterSelectorFunction = CoSellEngineEndpoints.getCoSellAnalyticsFilters;
		this.state.filterSelectorArgs = {
			coSellActionListMetadataPublicId: this.actionListMetadataPublicId
		};
		this.state.filterSelectorMounted = false;

		this.initData = this.initData.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
	}

	componentDidMount() {
		this.setState({loading: true});
		this.initData();
	}

	initData() {
		Promise.all([
			CoSellEngineEndpoints.getCoSellActionListMetadata(this.actionListMetadataPublicId),
			CoSellEngineEndpoints.getActiveActionListWorkflow(this.actionListMetadataPublicId)
		])
		.then((results) => {
			if (this.unmounted) return;
			let activeWorkflow = results[1].payload;
			if(activeWorkflow.dateExecuted) {
				let activeWorkflowName = activeWorkflow.sequenceDisplayName + " " + activeWorkflow.displayName;
				this.setState({actionListMetadata: results[0].payload, activeWorkflow: activeWorkflow, workflowName: activeWorkflowName });
				return CoSellEngineEndpoints.getCoSellAnalyticsColumns(this.actionListMetadataPublicId);
			}
			this.setState({actionListMetadata: results[0].payload, loading: false});
		})
		.then((result) => {
			if(result) {
				this.initColumnData(result.payload);
			}
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getCoSellActionListMetadata and columns for analytics ', error);
		});
	}

	getRowData() {
		let params = this.processParameters();
		CoSellEngineEndpoints.getCoSellAnalyticsRecords(this.actionListMetadataPublicId, params)
		.then((result) => {
			if (this.unmounted) return;
			if (this.hasRowData(result)) {
				ChannelHelper.convertTypedValues(result.payload, this.columnData);
			}
			this.processData(params, result);
		});
	}

	get additionalToolbarButtons() {
		let buttons = [];
		buttons.push(<ActionButton key={'download_button_new'}
								   toolTip={'Create Downloadable CSV'}
								   onAction={this.handleDownload}
								   inProgress={this.state.downloading}
								   icon={<Download/>}/>);
		return buttons;
	}

	handleDownload() {
		let params = this.processParameters(true);
		this.setState({downloading: true});
		CoSellEngineEndpoints.getCoSellAnalyticsDownload(this.actionListMetadataPublicId, params.filters, params.search)
		.then(result => {
			this.setState({downloading: false});
		})
		.catch(error => EnvHelper.serverError('Error downloading the file ', error));
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	get actionListMetadataPublicId() {
		return this.props.match.params.actionListId;
	}

	get storageKeyBase() {
		return 'co_sell_action_list_results';
	}

	get title() {
		return 'Workflow Results';
	}

	get icon() {
		return JoinInner;
	}

	renderWorkflowName() {
		let {workflowName} = this.state;
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				padding: 10,
				gap: 10,
				borderRadius: 5,
				backgroundColor: partnerTapWhite
			}}>
				<div style={{
					display: 'flex',
					alignItems: 'center',
					gap: 5,
					padding: 10,
					fontSize: 18,
					color: partnerTapTernary,
					backgroundColor: partnerTapTernaryLight,
					border: '1px solid ' + partnerTapTernary,
					borderRadius: 5
				}}>
					{workflowName ? <div><Check/> {workflowName}</div> : <div><DoNotDisturb /> No active workflow</div>}
				</div>
			</div>
		);
	}

	render() {
		let {actionListMetadata, activeWorkflow} = this.state;
		if (!actionListMetadata) return <Loading>Loading Action List...</Loading>;
		return (
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 5, margin: 5}}>
				<CoSellActionListMetadataLive actionListMetadata={actionListMetadata} activeWorkflow={activeWorkflow}/>
				{this.renderWorkflowName()}
				{super.render()}
			</div>
		);
	}

}

export default withRouter(CoSellActionListResultsPageLive);
