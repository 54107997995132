import React, {Fragment} from 'react';
import {partnerTapTernary, partnerTapTernaryLight} from '../../../../styles/partnertap_theme';
import {Check, DoNotDisturb} from '@mui/icons-material';

const ActiveWorkflowName = ({ workflowName }) => {
	return (<Fragment>
		<div style={{
			display: 'flex',
			alignItems: 'center',
			gap: 5,
			padding: 10,
			fontSize: 18,
			color: partnerTapTernary,
			backgroundColor: partnerTapTernaryLight,
			border: '1px solid ' + partnerTapTernary,
			borderRadius: 5
		}}>
			{workflowName ? <div><Check/> {workflowName}</div> : <div><DoNotDisturb/> No active workflow</div>}
		</div>
	</Fragment>);
};

export default ActiveWorkflowName;