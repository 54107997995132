import {Add, Business} from '@mui/icons-material';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import {SALES_REP, STATUS_ACTIVE, STATUS_INACTIVE, STATUS_TERMINATED} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import {ADMIN_CURRENT_ORG_ID_KEY} from '../../globals/StorageKeys';
import ChannelHelper from '../../helpers/ChannelHelper';
import * as CsvHelper from '../../helpers/CsvHelper';
import EnvHelper from '../../helpers/EnvHelper';
import * as PageableHelper from '../../helpers/PageableHelper';
import PersistenceHelper from '../../helpers/PersistenceHelper';
import StringHelper from '../../helpers/StringHelper';
import CheckboxButton from '../../ui/buttons/CheckboxButton';
import DownloadButton from '../../ui/buttons/DownloadButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Dialog from '../../ui/Dialog';
import PagingBase from '../../ui/lists/PagingBase';
import PopoverSearchList from '../../ui/PopoverSearchList';
import TextInputBox from '../../ui/TextInputBox';
import LinkButton from '../../ui/buttons/LinkButton';

class AdminOrgsPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		// metadata
		this.state.loading = false;
		this.state.standardRoles = [];
		this.state.filterSelectorFunction = AdminEndpoints.fetchOrgsFilterData;
		this.state.filterSelectorMounted = false;
		this.state.downloading = false;

		// new org
		this.state.addingOrg = false;
		this.state.orgName = '';
		this.state.domainList = '';
		this.state.orgStatus = STATUS_ACTIVE;
		this.state.orgRole = SALES_REP;
		this.state.isDemoOrg = false;
		this.state.orgUpdateErrorMessage = '';

		this.onSaveNewOrg = this.onSaveNewOrg.bind(this);
		this.downloadReport = this.downloadReport.bind(this);
	}

	componentDidMount() {
		this.fetchOrgColumns();
	}

	get storageKeyBase() {
		return 'tools_admin_organizations';
	}

	get title() {
		return 'All Orgs';
	}

	get icon() {
		return Business;
	}

	fetchOrgColumns() {
		AdminEndpoints.fetchOrgsColumns()
		.then((result) => {
			if (this.unmounted) return;
			let orgName = result.payload.find((row) => row.key === 'name');
			if (orgName) {
				orgName.renderFunction = (columnValue, rowData) => {
					if (!rowData) return columnValue;
					return <LinkButton label={columnValue}
									   onClick={() => {
										   PersistenceHelper.setValue(ADMIN_CURRENT_ORG_ID_KEY, rowData.orgId);
										   EnvHelper.push(Routes.ADMIN_TOOLS.ORGS.ORG_DETAILS.PATH(rowData.orgId));
									   }}/>;
				};
			}
			this.initColumnData(result.payload);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from fetchOrgReportColumns', error);
		});
	}

	get additionalToolbarButtons() {
		return [
			<PrimaryButton key={'add_org_button'} label={'ADD ORG'} icon={<Add/>} onClick={() => this.setState({addingOrg: true})}/>,
			<DownloadButton key={'download_button'} onAction={this.downloadReport} inProgress={this.state.downloading}/>
		];
	}

	downloadReport() {
		if (!this.state.downloading) {
			this.setState({downloading: true});
			this.fetchAllForDownload(0, false);
		}
	}

	fetchAllForDownload(page, isLastPage) {
		if (this.unmounted) return;
		if (isLastPage) {
			this.setState({downloading: false});
			return;
		}
		let params = this.processParameters(true);
		return AdminEndpoints.fetchOrgsList(params.search, params.filters,
			page,
			PageableHelper.PAGE_SIZE_DOWNLOAD,
			params.sort,
			0)
		.then((result) => {
			if (this.unmounted) return;
			ChannelHelper.convertOtherJsonValues(result.payload);
			ChannelHelper.convertTypedValues(result.payload, this.state.columnData);
			CsvHelper.downloadCSVSelectedColumns(result.payload, 'admin_orgs.csv', this.state.columnData, this.activeColumns.split(','));
			return this.fetchAllForDownload(page + 1, result.metaData.last);
		})
		.catch((error) => {
			EnvHelper.serverError('Error from adminOrgs', error);
		});
	}

	get filterSelectorArgs() {
		return {};
	}

	onColumnActiveChanged() {
		super.onColumnActiveChanged();
		this.setState({loading: true});
	}

	get columnData() {
		return this.state ? this.state.columnData : null;
	}

	getRowData() {
		if (this.gettingRowData) return;
		if (!this.state.filterSelectorMounted) return;
		let params = this.processParameters();
		Promise.all([AdminEndpoints.fetchOrgsList(params.search, params.filters, params.page, params.pageSize, params.sort), AdminEndpoints.fetchRoles()])
		.then((results) => {
			if (this.unmounted) return;
			let standardRoles = results[1].payload;
			this.setState({standardRoles: standardRoles});
			ChannelHelper.convertTypedValues(results[0].payload, this.columnData);
			this.processData(params, results[0]);

		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		return (
			<div>
				{super.render()}
				{this.addOrgDialog}
			</div>
		);
	}

	get addOrgDialog() {
		if (this.state.addingOrg) {
			return (
				<Dialog title={'Add New Org'}
						message={
							<div style={{display: 'flex', flexDirection: 'column', gap: 10, width: 360}}>
								<TextInputBox hintText={'Org Name'}
											  errorText={this.state.orgNameMissing ? 'Org Name is required' : null}
											  value={this.state.orgName}
											  onChange={(value) => this.setState({orgName: value, orgNameMissing: false})}
											  maxChars={255}
											  isSimple={true}/>
								<TextInputBox hintText={'Domain List'}
											  errorText={this.state.orgUpdateErrorMessage}
											  value={this.state.domainList}
											  onChange={(value) => this.setState({domainList: value, orgUpdateErrorMessage: null})}
											  maxChars={1000}
											  isSimple={true}/>
								<PopoverSearchList label={'Select Status'}
												   list={[STATUS_INACTIVE, STATUS_ACTIVE, STATUS_TERMINATED]}
												   onItemSelected={(status) => this.setState({orgStatus: status})}/>
								<PopoverSearchList label={'Select Default Role'}
												   list={this.state.standardRoles}
												   labelRenderer={(role) => role.displayName}
												   searchByObjectKeys={['displayName']}
												   onItemSelected={(role) => this.setState({defaultRole: role.roleCode})}/>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<CheckboxButton checked={this.state.isDemoOrg} onChange={(event) => this.setState({isDemoOrg: event.target.checked})}/>
									Demo Org
								</div>
							</div>}
						yesLabel={'SAVE'}
						yesAction={this.onSaveNewOrg}
						noLabel={'CANCEL'}
						noAction={() => this.setState({addingOrg: false})}/>
			);
		}
		return null;
	}

	onSaveNewOrg() {
		let {orgName, domainList, orgStatus, defaultRole, isDemoOrg} = this.state;
		orgName = StringHelper.trimWhiteSpace(orgName);
		domainList = StringHelper.trimWhiteSpace(domainList);
		if (!orgName) {
			this.setState({orgNameMissing: true});
		}
		else if (!domainList) {
			this.setState({orgUpdateErrorMessage: 'Domain List is required'});
		}
		else if (!StringHelper.validateDomainList(domainList, false)) {
			this.setState({orgUpdateErrorMessage: 'Domain List is invalid'});
		}
		else {
			domainList = domainList.split(',');
			AdminEndpoints.createOrg(orgName, domainList, orgStatus, defaultRole, isDemoOrg)
			.then(() => {
				if (this.unmounted) return;
				this.setState({
					loading: true,
					addingOrg: false,
					orgName: '',
					domainList: '',
					orgStatus: STATUS_ACTIVE,
					defaultRole: SALES_REP,
					isDemoOrg: false
				});
			})
			.catch((error) => {
				EnvHelper.serverError('Error from createOrg', error);
			});
		}
	}
}

export default connect()(AdminOrgsPage);
