import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import * as CoSellEngineEndpoints from '../../../endpoints/CoSellEngineEndpoints';
import EnvHelper from '../../../helpers/EnvHelper';
import {
	partnerTapAppBackground,
	partnerTapInactive,
	partnerTapPrimary,
	partnerTapSecondary,
	partnerTapSecondaryLight,
	partnerTapWhite
} from '../../../styles/partnertap_theme';
import PrimaryButton from '../../../ui/buttons/PrimaryButton';
import Dialog from '../../../ui/Dialog';
import Loading from '../../../ui/Loading';
import LinkButton from '../../../ui/buttons/LinkButton';
import Radio from '@mui/material/Radio';
import PartnerTapIcon from '../../../ui/PartnerTapIcon';
import {
	LANDING_STEP_ACTION_LIST_EXTERNAL_EMAIL,
	LANDING_STEP_ACTION_LIST_INTERNAL_EMAIL,
	LANDING_STEP_OPT_IN_OWNER,
	LANDING_STEP_OPT_IN_PARTNER,
	LANDING_STEP_OWNER_AND_PARTNER_INTRO_EMAIL
} from './CoSellEngineHelperLive';
import CheckboxButton from '../../../ui/buttons/CheckboxButton';
import ScrimMessage from '../../../ui/messages/ScrimMessage';
import * as CsvHelper from '../../../helpers/CsvHelper';

const COLUMN_KEYS = {
	ACTION_LIST_OWNER_ACCOUNT_NAME: 'actionListOwnerAccountName',
	ACTION_LIST_PARTNER_ACCOUNT_NAME: 'actionListPartnerAccountName',
	OPT_IN_SIDE_NAME: 'optInSideName',
	OPT_IN_SIDE_EMAIL: 'optInSideEmail',
	NON_OPT_IN_SIDE_NAME: 'nonOptInSideName',
	NON_OPT_IN_SIDE_TITLE: 'nonOptInSideTitle',
	NON_OPT_IN_SIDE_EMAIL: 'nonOptInSideEmail'
};

// Extracted common styles
const cellStyle = {
	padding: '10px 20px',
	border: '1px solid'
};

const headerCellStyle = {
	...cellStyle,
	fontWeight: 'bold',
	backgroundColor: partnerTapSecondaryLight
};

class CoSellEmailLandingPageLive extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			loading: true,
			actionListMetadata: null,
			optInRecords: [],
			sortColumn: null,
			sortDirection: 'asc',
		};

		this.getWorkflowLandingForStep = this.getWorkflowLandingForStep.bind(this);
		this.onCheck = this.onCheck.bind(this);
		this.onCheckAll = this.onCheckAll.bind(this);
		this.confirm = this.confirm.bind(this);
		this.confirmCanceled = this.confirmCanceled.bind(this);
		this.confirmed = this.confirmed.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.handleSort = this.handleSort.bind(this);
	}

	componentDidMount() {
		this.getWorkflowLandingForStep();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	get stepInstancePublicId() {
		return this.props.match.params.stepInstancePublicId;
	}

	getWorkflowLandingForStep() {
		CoSellEngineEndpoints.getWorkflowLandingForStep(this.stepInstancePublicId)
		.then((result) => {
			if (this.unmounted) return;
			let { actionListMetadata, stepName, accounts, isConfirmed } = result.payload;
			accounts.forEach((account) => account.isOptIn = true);
			this.setState({
				actionListMetadata: actionListMetadata,
				stepName: stepName,
				isConfirmed: isConfirmed,
				optInRecords: accounts,
				loading: false
			});
		})
		.catch((error) => {
			EnvHelper.serverError('Error from getWorkflowLandingForStep', error);
		});
	}

	updateWorkflowLandingForStep() {
		this.setState({ saving: true });
		let { optInRecords } = this.state;
		let payload =
			{
				'optedInAccounts': optInRecords.filter((optInRecord) => optInRecord.isOptIn).map((optInRecord) => optInRecord.actionListAccountId),
				'optedOutAccounts': optInRecords.filter((optInRecord) => !optInRecord.isOptIn).map((optInRecord) => optInRecord.actionListAccountId)
			};
		CoSellEngineEndpoints.updateWorkflowLandingForStep(this.stepInstancePublicId, payload)
		.then((result) => {
			if (this.unmounted) return;
			this.setState({ saving: false });
		})
		.catch((error) => {
			this.setState({ saving: false });
			EnvHelper.serverError('Error from updateWorkflowLandingForStep', error);
		});
	}

	handleDownload() {
		CoSellEngineEndpoints.downloadWorkflowLandingForStep(this.stepInstancePublicId)
		.then(response => response.blob())
		.then(csv => {
			CsvHelper.triggerFileDownload(csv, 'opt_in_accounts.csv');
		})
		.catch(error => console.error('Error downloading the file:', error));
	}

	onCheck(optInRecord, isChecked) {
		optInRecord.isOptIn = isChecked;
		this.forceUpdate();
	}

	onCheckAll(isChecked) {
		let { optInRecords } = this.state;
		optInRecords.forEach((optInRecord) => optInRecord.isOptIn = isChecked);
		this.forceUpdate();
	}

	handleSort(columnKey) {
		// TODO: We should get rid this method and the implementation around it once we're equipped to deal with jooq sorting
		let { sortColumn, sortDirection, optInRecords } = this.state;
		let newDirection = 'asc';
		if (sortColumn === columnKey && sortDirection === 'asc') {
			newDirection = 'desc';
		}

		const sortedRecords = optInRecords.sort((a, b) => {
			if (a[columnKey] < b[columnKey]) return newDirection === 'asc' ? -1 : 1;
			if (a[columnKey] > b[columnKey]) return newDirection === 'asc' ? 1 : -1;
			return 0;
		});

		this.setState({
			sortColumn: columnKey,
			sortDirection: newDirection,
			optInRecords: sortedRecords,
		});
	}

	getSortIcon(columnKey) {
		const { sortColumn, sortDirection } = this.state;
		if (sortColumn === columnKey) {
			return sortDirection === 'asc' ? <ArrowDropUp /> : <ArrowDropDown />;
		}
		return null;
	}

	get optInCount() {
		let optInCount = 0;
		let { optInRecords } = this.state;
		optInRecords.forEach((accountOptIn) => optInCount += accountOptIn.isOptIn ? 1 : 0);
		return optInCount;
	}

	get renderOptInAccountTable() {
		const { stepName, optInRecords, showConfirmDialog } = this.state;
		const isOwnerRecipient = stepName === LANDING_STEP_OPT_IN_OWNER;
		const accountColumnKey = isOwnerRecipient ? COLUMN_KEYS.ACTION_LIST_OWNER_ACCOUNT_NAME : COLUMN_KEYS.ACTION_LIST_PARTNER_ACCOUNT_NAME;

		const centerAlignedCellStyle = {
			...cellStyle,
			textAlign: 'center'
		};

		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 20 }}>
				<table style={{ borderCollapse: 'collapse', width: '80%', border: '1px solid' }}>
					<thead>
					<tr style={{ fontSize: 16 }}>
						<td style={headerCellStyle} onClick={() => this.handleSort(accountColumnKey)}>
							Account Name {this.getSortIcon(accountColumnKey)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.NON_OPT_IN_SIDE_NAME)}>
							Sales Rep {this.getSortIcon(COLUMN_KEYS.NON_OPT_IN_SIDE_NAME)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.NON_OPT_IN_SIDE_TITLE)}>
							Sales Rep Title {this.getSortIcon(COLUMN_KEYS.NON_OPT_IN_SIDE_TITLE)}
						</td>
						<td style={centerAlignedCellStyle} colSpan={2}>
							Reply
						</td>
					</tr>
					<tr style={{ backgroundColor: partnerTapAppBackground, textAlign: 'center', fontSize: 14 }}>
						<td style={cellStyle} />
						<td style={cellStyle} />
						<td style={cellStyle} />
						<td style={cellStyle}>
							<LinkButton label={'OPT IN TO ALL'} onClick={() => this.onCheckAll(true)} />
						</td>
						<td style={cellStyle}>
							<LinkButton label={'OPT OUT OF ALL'} onClick={() => this.onCheckAll(false)} />
						</td>
					</tr>
					</thead>
					<tbody>
					{optInRecords.map((optInRecord, index) => (
						<tr key={optInRecord.actionListAccountId} style={{ backgroundColor: index % 2 ? partnerTapAppBackground : partnerTapWhite }}>
							<td style={cellStyle}>
								{isOwnerRecipient ? optInRecord.actionListOwnerAccountName : optInRecord.actionListPartnerAccountName}
							</td>
							<td style={cellStyle}>
								{optInRecord.nonOptInSideName}
							</td>
							<td style={cellStyle}>
								{optInRecord.nonOptInSideTitle}
							</td>
							<td style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
								<Radio
									style={{ color: partnerTapSecondary }}
									checked={optInRecord.isOptIn}
									onChange={() => this.onCheck(optInRecord, true)}
								/>
								Yes, I'd like to opt in
							</td>
							<td style={{ ...cellStyle, whiteSpace: 'nowrap' }}>
								<Radio
									style={{ color: partnerTapSecondary }}
									checked={!optInRecord.isOptIn}
									onChange={() => this.onCheck(optInRecord, false)}
								/>
								No thanks
							</td>
						</tr>
					))}
					</tbody>
				</table>
				<div style={{ padding: '20px' }}>
					<PrimaryButton label={'CONFIRM'} onClick={this.confirm} />
				</div>
				{showConfirmDialog && this.renderConfirmDialog}
			</div>
		);
	}

	get renderOptInAccountTableMobile() {
		let { stepName, optInRecords, showConfirmDialog } = this.state;
		let isOwnerRecipient = stepName === LANDING_STEP_OPT_IN_OWNER;
		const accountColumnKey = isOwnerRecipient ? COLUMN_KEYS.ACTION_LIST_OWNER_ACCOUNT_NAME : COLUMN_KEYS.ACTION_LIST_PARTNER_ACCOUNT_NAME;

		return (
			<div>
				<div style={{ display: 'flex', justifyContent: 'center', padding: 10, gap: 20, fontSize: 12 }}>
					<LinkButton label={'OPT IN TO ALL'} onClick={() => this.onCheckAll(true)} />
					<LinkButton label={'OPT OUT OF ALL'} onClick={() => this.onCheckAll(false)} />
				</div>
				<table width={'100%'} border={1} style={{ borderCollapse: 'collapse' }}>
					<thead>
					<tr style={{ backgroundColor: partnerTapSecondaryLight, fontSize: 16, fontWeight: 'bold' }}>
						<td style={{ padding: 5 }} onClick={() => this.handleSort(accountColumnKey)}>
							Account Name {this.getSortIcon(accountColumnKey)}
						</td>
						<td style={{ padding: 5 }} onClick={() => this.handleSort(COLUMN_KEYS.NON_OPT_IN_SIDE_NAME)}>
							Sales Rep {this.getSortIcon(COLUMN_KEYS.NON_OPT_IN_SIDE_NAME)}
						</td>
						<td style={{ padding: 5, textAlign: 'center' }}>
							Opt In
						</td>
					</tr>
					</thead>
					<tbody>
					{optInRecords.map((optInRecord, index) => {
						return (
							<tr key={optInRecord.actionListAccountId}
								style={{ backgroundColor: index % 2 ? partnerTapAppBackground : partnerTapWhite, fontSize: 14 }}>
								<td style={{ padding: 5 }}>
									{isOwnerRecipient ? optInRecord.actionListOwnerAccountName : optInRecord.actionListPartnerAccountName}
								</td>
								<td style={{ padding: 5 }}>
									<div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
										<div>{optInRecord.nonOptInSideName}</div>
										<div>{optInRecord.nonOptInSideTitle}</div>
									</div>
								</td>
								<td style={{ padding: 5 }}>
									<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
										<CheckboxButton style={{ color: partnerTapSecondary }}
														checked={optInRecord.isOptIn}
														onChange={(event) => this.onCheck(optInRecord, event.target.checked)} />
										<div style={{ whiteSpace: 'nowrap' }}>Yes, opt in</div>
									</div>
								</td>
							</tr>
						);
					})}
					</tbody>
				</table>
				<div style={{ display: 'flex', justifyContent: 'center', padding: 40, backgroundColor: partnerTapWhite }}>
					<PrimaryButton label={'CONFIRM'} onClick={this.confirm} />
				</div>
				{showConfirmDialog &&
				 this.renderConfirmDialog}
			</div>
		);
	}

	get renderConfirmDialog() {
		let optInCount = this.optInCount;
		return (
			<Dialog title={'Please Confirm'}
					message={
						<div>
							You opted in to {optInCount} account{optInCount !== 1 ? 's' : ''}. Are you ready to submit your response?
						</div>}
					yesAction={this.confirmed}
					yesLabel={'YES'}
					noAction={this.confirmCanceled}
					noLabel={'NOT YET'} />
		);
	}

	get renderIntroAccountTable() {
		const { actionListMetadata, optInRecords } = this.state;

		return (
			<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
				<table style={{ borderCollapse: 'collapse', width: '80%', border: '1px solid' }}>
					<thead>
					<tr style={{ fontSize: 16 }}>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.ACTION_LIST_OWNER_ACCOUNT_NAME)}>
							{actionListMetadata.ownerOrg.orgName} Account Name {this.getSortIcon(COLUMN_KEYS.ACTION_LIST_OWNER_ACCOUNT_NAME)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.OPT_IN_SIDE_NAME)}>
							{actionListMetadata.ownerOrg.orgName} Account Owner {this.getSortIcon(COLUMN_KEYS.OPT_IN_SIDE_NAME)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.OPT_IN_SIDE_EMAIL)}>
							{actionListMetadata.ownerOrg.orgName} Account Owner Email {this.getSortIcon(COLUMN_KEYS.OPT_IN_SIDE_EMAIL)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.ACTION_LIST_PARTNER_ACCOUNT_NAME)}>
							{actionListMetadata.partnerOrg.orgName} Account Name {this.getSortIcon(COLUMN_KEYS.ACTION_LIST_PARTNER_ACCOUNT_NAME)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.NON_OPT_IN_SIDE_NAME)}>
							{actionListMetadata.partnerOrg.orgName} Account Owner {this.getSortIcon(COLUMN_KEYS.NON_OPT_IN_SIDE_NAME)}
						</td>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.NON_OPT_IN_SIDE_EMAIL)}>
							{actionListMetadata.partnerOrg.orgName} Account Owner Email {this.getSortIcon(COLUMN_KEYS.NON_OPT_IN_SIDE_EMAIL)}
						</td>
					</tr>
					</thead>
					<tbody>
					{optInRecords.map((optInRecord, index) => (
						<tr key={optInRecord.actionListAccountId} style={{ backgroundColor: index % 2 ? partnerTapAppBackground : partnerTapWhite }}>
							<td style={cellStyle}>
								{optInRecord.actionListOwnerAccountName}
							</td>
							<td style={cellStyle}>
								{optInRecord.optInSideName}
							</td>
							<td style={cellStyle}>
								{optInRecord.optInSideEmail}
							</td>
							<td style={cellStyle}>
								{optInRecord.actionListPartnerAccountName}
							</td>
							<td style={cellStyle}>
								{optInRecord.nonOptInSideName}
							</td>
							<td style={cellStyle}>
								{optInRecord.nonOptInSideEmail}
							</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		);
	}

	get renderInternalDistributionTable() {
		const { actionListMetadata, optInRecords } = this.state;

		return (
			<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
				<table style={{ borderCollapse: 'collapse', width: '80%', border: '1px solid' }}>
					<thead>
					<tr style={{ fontSize: 16 }}>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.ACTION_LIST_OWNER_ACCOUNT_NAME)}>
							{actionListMetadata.ownerOrg.orgName} Accounts {this.getSortIcon(COLUMN_KEYS.ACTION_LIST_OWNER_ACCOUNT_NAME)}
						</td>
					</tr>
					</thead>
					<tbody>
					{optInRecords.map((optInRecord, index) => (
						<tr key={optInRecord.actionListAccountId} style={{ backgroundColor: index % 2 ? partnerTapAppBackground : partnerTapWhite }}>
							<td style={cellStyle}>
								{optInRecord.actionListOwnerAccountName}
							</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		);
	}

	get renderExternalDistributionTable() {
		const { actionListMetadata, optInRecords } = this.state;

		return (
			<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
				<table style={{ borderCollapse: 'collapse', width: '80%', border: '1px solid' }}>
					<thead>
					<tr style={{ fontSize: 16 }}>
						<td style={headerCellStyle} onClick={() => this.handleSort(COLUMN_KEYS.ACTION_LIST_PARTNER_ACCOUNT_NAME)}>
							{actionListMetadata.partnerOrg.orgName} Accounts {this.getSortIcon(COLUMN_KEYS.ACTION_LIST_PARTNER_ACCOUNT_NAME)}
						</td>
					</tr>
					</thead>
					<tbody>
					{optInRecords.map((optInRecord, index) => (
						<tr key={optInRecord.actionListAccountId} style={{ backgroundColor: index % 2 ? partnerTapAppBackground : partnerTapWhite }}>
							<td style={cellStyle}>
								{optInRecord.actionListPartnerAccountName}
							</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
		);
	}

	confirm() {
		this.setState({ showConfirmDialog: true });
	}

	confirmCanceled() {
		this.setState({ showConfirmDialog: false });
	}

	confirmed() {
		this.setState({ showConfirmDialog: false, isConfirmed: true });
		this.updateWorkflowLandingForStep();
	}

	render() {
		if (this.state.loading) return <Loading>Loading Action List...</Loading>;
		let { actionListMetadata, stepName, saving, isConfirmed } = this.state;

		let isOwnerOptInStep = stepName === LANDING_STEP_OPT_IN_OWNER;
		let isPartnerOptInStep = stepName === LANDING_STEP_OPT_IN_PARTNER;
		let isIntroStep = stepName === LANDING_STEP_OWNER_AND_PARTNER_INTRO_EMAIL;
		let isActionListInternal = stepName === LANDING_STEP_ACTION_LIST_INTERNAL_EMAIL;
		let isActionListExternal = stepName === LANDING_STEP_ACTION_LIST_EXTERNAL_EMAIL;

		let headerText = 'HIGH PROPENSITY TO BUY ACCOUNTS WITH ' +
						 (isOwnerOptInStep ? actionListMetadata.partnerOrg.orgName : actionListMetadata.ownerOrg.orgName).toUpperCase();
		let instructionText1 = 'Opt in to the accounts below for an introduction to the account owner at ' +
							   (isOwnerOptInStep ? actionListMetadata.partnerOrg.orgName : actionListMetadata.ownerOrg.orgName).toUpperCase();
		if (isIntroStep) {
			headerText = 'ACCOUNT INTRODUCTIONS';
			instructionText1 = 'Please coordinate a call to discuss account strategy and next steps for these accounts.';
		}
		else if (isActionListInternal) {
			headerText = 'HIGH PROPENSITY TO BUY ACCOUNTS';
			instructionText1 = 'The accounts below have a high propensity to buy ' + actionListMetadata.ownerOrg.orgName + ' solutions from you.';
		}
		else if (isActionListExternal) {
			headerText = 'HIGH PROPENSITY TO BUY ACCOUNTS FROM ' + actionListMetadata.ownerOrg.orgName;
			instructionText1 = 'The accounts below have a high propensity to buy ' + actionListMetadata.ownerOrg.orgName + ' solutions from you.';
		}

		return (
			<div style={{ padding: EnvHelper.isDesktop ? 10 : 1, overflow: 'scroll' }}>
				{saving && <ScrimMessage message={'Saving Changes...'} />}
				<div style={{
					color: partnerTapWhite,
					backgroundColor: partnerTapPrimary,
					fontSize: 20,
					textAlign: 'center',
					padding: 10,
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10
				}}>
					{headerText}
				</div>
				{isConfirmed ?
					<div style={{ backgroundColor: partnerTapWhite, fontSize: 18, textAlign: 'center', padding: 20 }}>
						<div>
							Thank you for your response!
						</div>
						{this.optInCount ?
							<div>
								Please keep an eye out for your introduction email.
							</div>
							:
							<div>
								Because you didn't opt in, no further action is required.
							</div>}
						<div style={{ fontSize: 14, color: partnerTapInactive, paddingTop: 20 }}>
							(You can close this page now)
						</div>
					</div>
					:
					<div style={{ fontSize: 18, textAlign: 'center', padding: 20 }}>
						<div>
							{instructionText1}
						</div>
					</div>}
				{(!isConfirmed && (isOwnerOptInStep || isPartnerOptInStep)) &&
				 (EnvHelper.isDesktop ? this.renderOptInAccountTable : this.renderOptInAccountTableMobile)}
				{isIntroStep && this.renderIntroAccountTable}
				{isActionListInternal && this.renderInternalDistributionTable}
				{isActionListExternal && this.renderExternalDistributionTable}

				{(isIntroStep || isActionListInternal || isActionListExternal) && (
					<div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
						<PrimaryButton label={'DOWNLOAD'} onClick={this.handleDownload} />
					</div>
				)}

				<div style={{ textAlign: 'center', fontSize: '12px', marginTop: '10px', marginBottom: '20px' }}>
					PartnerTap is the leading partner ecosystem and co-selling platform. Our software automates account mapping, enables deal sharing, and automates co-selling workflows and attribution.
				</div>
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: partnerTapPrimary,
					color: partnerTapWhite,
					padding: 10,
					borderBottomLeftRadius: 10,
					borderBottomRightRadius: 10
				}}>
					{PartnerTapIcon.logoWhite}
					<span style={{
						fontSize: '20px',
						fontWeight: 'bold',
						marginLeft: '10px',
						display: 'flex',
						alignItems: 'center'
					}}>
				PARTNERTAP
			</span>
				</div>
			</div>
		);
	}
}

CoSellEmailLandingPageLive.propTypes = {
	match: PropTypes.object.isRequired
};

export default withRouter(CoSellEmailLandingPageLive);