import {convert, LocalDateTime} from '@js-joda/core';
import {Keyboard, Send} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as ChatEndpoints from '../../../endpoints/ChatEndpoints';
import {INVITE_STATUS_ACCEPTED} from '../../../globals/Enums';
import ChatHelper from '../../../helpers/ChatHelper';
import DateHelper from '../../../helpers/DateHelper';
import EnvHelper from '../../../helpers/EnvHelper';
import * as PageSizeHelper from '../../../helpers/PageableHelper';
import {
	partnerTapAppBackground,
	partnerTapDefaultText,
	partnerTapInactive,
	partnerTapPrimary,
	partnerTapQuaternary,
	partnerTapSecondary,
	partnerTapStroke,
	partnerTapWhite
} from '../../../styles/partnertap_theme';
import SecondaryButton from '../../../ui/buttons/SecondaryButton';
import Dialog from '../../../ui/Dialog';
import ScrollingContainer from '../../../ui/lists/ScrollingContainer';
import Loading from '../../../ui/Loading';
import Pic from '../../../ui/Pic';
import AccountSelector from '../../../ui/selectors/AccountSelector';
import PartnerSelector from '../../../ui/selectors/PartnerSelector';
import TextInputBox from '../../../ui/TextInputBox';

const TYPING = 'typing';
const NOT_TYPING = 'notTyping';

class IntelChat extends Component {

	constructor(props, context) {
		super(props, context);
		this.page = 0;
		this.state = {
			loading: true,
			currentThread: this.props.currentThread,
			ably: null,
			channel: null,
			threadMessages: [],
			isMoreToLoad: false,
			currentChat: '',
			partnerTyping: false
		};
		this.loadMoreChat = this.loadMoreChat.bind(this);
		this.sendChatMessage = this.sendChatMessage.bind(this);
		this.onChatReceived = this.onChatReceived.bind(this);
	}

	componentDidMount() {
		this.getChatThread();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.blockAutoScrollForTyping) {
			this.blockAutoScrollForTyping = false;
			return;
		}

		if (this.scrollThreadToBottomTimeout) clearTimeout(this.scrollThreadToBottomTimeout);
		this.scrollThreadToBottomTimeout = setTimeout(() => {
			if (this.autoScrollToChatItem) {
				let lastTopMessage = document.getElementById('chatItem' + this.autoScrollToChatItem);
				if (lastTopMessage) lastTopMessage.scrollIntoView();
				this.autoScrollToChatItem = 0;
			}
			else {
				let scrollingChatDiv = document.getElementById('scrollingChatDiv');
				if (scrollingChatDiv) {
					scrollingChatDiv.scrollTo({top: scrollingChatDiv.scrollHeight, behavior: 'smooth'});
				}
			}
		});
	}

	componentWillUnmount() {
		if (this.state.channel) {
			this.state.channel.detach((error) => {
				if (error) {
					console.error('Error detaching from channel ' + this.state.channelKey, error);
				}
				else {
					this.state.channel.presence.unsubscribe();
					this.state.channel.unsubscribe();
				}
			});
		}
		if (this.typingTimeout) clearTimeout(this.typingTimeout);
		if (this.typingStoppedTimeout) clearTimeout(this.typingStoppedTimeout);
		if (this.presenceLostTimeout) clearTimeout(this.presenceLostTimeout);
		this.unmounted = true;
	}

	get currentUserPersonId() {
		return this.props.authState.person.id;
	}

	get isMissingPartner() {
		return !this.hasPartner;
	}

	get hasPartner() {
		return ChatHelper.hasPartner(this.state.currentThread);
	}

	get hasActivePartner() {
		return this.hasPartner && this.state.currentThread.partnerStatus === INVITE_STATUS_ACCEPTED;
	}

	get isMissingAccount() {
		return EnvHelper.inSalesNetworkProduct && !this.hasAccount;
	}

	get hasAccount() {
		return EnvHelper.inSalesNetworkProduct && ChatHelper.hasAccount(this.state.currentThread);
	}

	getChatThread() {
		if (this.isMissingPartner || this.isMissingAccount) {
			this.setState({loading: false, channel: null, channelKey: null, threadMessages: []});
		}
		else if (!this.state.ably) {
			ChatHelper.getClient(this.state.currentThread.partnerId, this.state.currentThread.saleAccountLocationId)
			.then((result) => {
				if (this.unmounted) return;
				let channel = result.client.channels.get(result.channelKey);
				this.initChat(channel, result.channelKey, result.client);
			});
		}
		else {
			ChatHelper.getChannelKey(this.state.currentThread.partnerId, this.state.currentThread.saleAccountLocationId)
			.then((result) => {
				if (this.unmounted) return;
				let channel = this.state.ably.channels.get(result);
				this.initChat(channel, result, this.state.ably);
			});
		}
	}

	initChat(channel, channelKey, client) {
		if (channel.subscriptions.any.includes(this.onChatReceived)) {
			console.error('Error chat already initiated');
			return;
		}

		ChatEndpoints.fetchChatHistory(channelKey, this.page, PageSizeHelper.PAGE_SIZE_INTEL_CHAT)
		.then((result) => {
			if (this.unmounted) return;
			this.autoScrollToChatItem = Math.min(result.payload.length, PageSizeHelper.PAGE_SIZE_INTEL_CHAT) - 1;
			this.setState({
				loading: false,
				ably: client,
				channel: channel,
				channelKey: channelKey,
				threadMessages: result.payload ? result.payload.reverse() : [],
				isMoreToLoad: result.metaData !== undefined && result.metaData.last !== undefined && !result.metaData.last
			});
		})
		.catch((error) => {
			console.error('Error trying to fetchChatHistory: ' + error);
		});

		channel.attach(() => {
			channel.presence.subscribe('update', member => {
				let isNotMe = member.clientId !== this.currentUserPersonId;
				let isTyping = member.data.action === TYPING;
				if (isNotMe && isTyping) {
					this.setState({partnerTyping: true});

					// in some cases we lose the presence updates so make sure the typing icon goes away
					if (this.presenceLostTimeout) clearTimeout(this.presenceLostTimeout);
					this.presenceLostTimeout = setTimeout(() => this.setState({partnerTyping: false}), 5000);
				}
			});
		});
		channel.subscribe(this.onChatReceived);
	}

	onChatReceived(message) {
		if (this.unmounted) return;
		if (!message.data) return;
		if (!message.data.extras) return;
		let extras = message.data.extras;
		let chat = {
			channelId: extras.channelId,
			publisherId: extras.publisherId,
			subscriberId: extras.subscriberId,
			saleAccountId: extras.accountId,
			message: message.data.chat,
			publisherName: extras.publisherName,
			chatDate: convert(LocalDateTime.now()).toEpochMilli()
		};

		let isMarkAsRead = this.currentUserPersonId !== extras.publisherId && !EnvHelper.isSpoofing;
		if (isMarkAsRead) {
			ChatEndpoints.createOrUpdateChatToRead(message.id, extras.channelId, message.data.chat, message.timestamp, extras.publisherId);
		}
		this.setState({partnerTyping: false, threadMessages: [...this.state.threadMessages, chat]});
	}

	renderHeader() {
		return (
			<div style={{display: 'flex', flexDirection: 'column'}}>
				{(EnvHelper.inChannelEcosystemProduct || !this.hasPartner) && this.renderPartnerDetails()}
				{this.renderAccountDetails()}
			</div>
		);
	}

	get chatHeaderStyle() {
		return {
			display: 'flex',
			alignItems: 'center',
			height: 40,
			padding: 5,
			paddingLeft: 10,
			borderBottom: '1px solid ' + partnerTapStroke,
			backgroundColor: partnerTapWhite
		};
	}

	renderPartnerDetails() {
		if (this.hasPartner) {
			return (
				<div style={this.chatHeaderStyle}>
					<Pic picSize={30} personId={this.state.currentThread.partnerPersonId} tmpLogo={this.state.currentThread.partnerName}/>
					<div style={{display: 'flex', alignItems: 'baseline', paddingLeft: 5}}>
						<div style={{fontWeight: 'bold'}}>
							{this.state.currentThread.partnerName}
						</div>
						<div style={{paddingLeft: 5, fontSize: 12, color: partnerTapInactive}}>
							{this.state.currentThread.partnerOrg}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div style={this.chatHeaderStyle}>
				<div style={{paddingLeft: 5}}>To:</div>
				<div style={{paddingLeft: 10}}>
					<PartnerSelector onPartnerSelected={(partner) => {
						this.props.onPartnerSelected(partner);
						this.forceUpdate();
					}}/>
				</div>
			</div>
		);
	}

	renderAccountDetails() {
		if (this.hasAccount) {
			return (
				<div style={this.chatHeaderStyle}>
					<Pic bizId={this.state.currentThread.saleAccountId} tmpLogo={this.state.currentThread.saleAccountName} picSize={30}/>
					<div style={{display: 'flex', alignItems: 'baseline', paddingLeft: 5}}>
						<div style={{fontWeight: 'bold'}}>
							{this.state.currentThread.saleAccountName}
						</div>
						{!this.state.currentThread.isGeneralChat &&
						 <div style={{paddingLeft: 6, fontSize: 12, color: partnerTapInactive}}>
							 {this.state.currentThread.saleAccountLocation}
						 </div>}
					</div>
				</div>
			);
		}

		if (this.isMissingAccount) {
			if (this.isMissingPartner) {
				return (
					<div style={this.chatHeaderStyle}>
						<div style={{paddingLeft: 5}}>Re:</div>
						<div style={{paddingLeft: 10, color: partnerTapInactive}}>
							Account
						</div>
					</div>
				);
			}

			return (
				<div style={this.chatHeaderStyle}>
					<div style={{paddingLeft: 5}}>Re:</div>
					<div style={{paddingLeft: 10}}>
						<AccountSelector partnerPersonId={this.state.currentThread.partnerPersonId}
										 onAccountSelected={(account) => {
											 this.props.onAccountSelected(account);
											 this.forceUpdate();
										 }}/>
					</div>
				</div>
			);
		}
		return '';
	}

	renderMessages() {
		let messageListStyle = {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			overflow: 'scroll',
			border: 1,
			borderColor: partnerTapPrimary,
			backgroundColor: partnerTapAppBackground
		};

		if (this.state.threadMessages.length === 0) {
			let requirementStyle = {
				textAlign: 'center',
				padding: 5,
				margin: 20,
				fontWeight: 'bold',
				color: partnerTapWhite,
				backgroundColor: partnerTapPrimary
			};
			if (this.isMissingPartner) {
				return (
					<div id={'scrollingChatDiv'} style={messageListStyle}>
						<div style={requirementStyle}>Select the partner you want to chat with above</div>
					</div>
				);
			}
			if (this.isMissingAccount) {
				return (
					<div id={'scrollingChatDiv'} style={messageListStyle}>
						<div style={requirementStyle}>Select the account you want to chat about above</div>
					</div>
				);
			}
			return (
				<div id={'scrollingChatDiv'} style={messageListStyle}>
					<div style={requirementStyle}>Enter chat below</div>
				</div>
			);
		}

		return (
			<div id={'scrollingChatDiv'} style={messageListStyle}>
				{this.state.isMoreToLoad &&
				 <div style={{textAlign: 'center', padding: 10}}>
					 <SecondaryButton label={'Load more'} onClick={this.loadMoreChat}/>
				 </div>}

				{this.state.threadMessages.map((item, index) => {
					let isMyChat = this.currentUserPersonId === item.publisherId;
					let chatDate;
					if (typeof item.chatDate === 'string') {
						chatDate = new Date(DateHelper.getMillisecondsWithTimeZoneOffset(item.chatDate));
					}
					else {
						chatDate = new Date(item.chatDate);
					}
					return (
						<div key={index} id={'chatItem' + index} style={{display: 'flex', alignItems: 'center'}}>
							{!isMyChat &&
							 <div style={{paddingLeft: 10, paddingTop: 30}}>
								 <Pic personId={item.publisherId} tmpLogo={item.name}/>
							 </div>}
							<div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: isMyChat ? 'flex-end' : 'flex-start', padding: 10}}>
								<div style={{padding: 10, paddingBottom: 5, fontSize: 12, color: partnerTapInactive}}>
									{DateHelper.getElapsedTime(chatDate, chatDate)}
								</div>
								<div style={{
									backgroundColor: isMyChat ? partnerTapPrimary : partnerTapWhite,
									border: '1px solid ' + partnerTapPrimary,
									borderRadius: 20,
									color: isMyChat ? partnerTapWhite : partnerTapDefaultText,
									padding: 10,
									fontSize: 16,
									wordWrap: 'break-word',
									overflowWrap: 'break-word',
									maxWidth: 310
								}}>
									{item.data}
									{ChatHelper.linkUrls(item.message)}
								</div>
							</div>
						</div>
					);
				})}
				{this.state.partnerTyping ?
					<div style={{display: 'flex', alignItems: 'center'}}>
						<div style={{paddingLeft: 10, paddingTop: 30}}>
							<Pic personId={this.state.currentThread.partnerPersonId} tmpLogo={this.state.currentThread.partnerName}/>
						</div>
						<div style={{padding: 10}}>
							<div style={{backgroundColor: partnerTapQuaternary, borderRadius: 3, color: '#000000', textAlign: 'left', padding: 5}}>
								<div style={{animation: 'bounce 2s infinite'}}>
									<Keyboard style={{color: partnerTapSecondary}}/>
								</div>
							</div>
						</div>
					</div>
					:
					<div style={{paddingLeft: 10, paddingTop: 30}}/>}
			</div>
		);
	}

	loadMoreChat() {
		ChatEndpoints.fetchChatHistory(this.state.channelKey, ++this.page, PageSizeHelper.PAGE_SIZE_INTEL_CHAT)
		.then((result) => {
			if (this.unmounted) return;
			let newContent = result.payload.reverse().concat(this.state.threadMessages);
			let lastMessageCount = this.state.threadMessages.length;
			let currentMessageCount = newContent.length;
			this.autoScrollToChatItem = currentMessageCount - lastMessageCount - 1;
			this.setState({threadMessages: newContent, isMoreToLoad: !result.metaData.last});
		})
		.catch((error) => {
			console.error('Error trying to fetchChatHistory for loadMoreChat: ' + error);
		});
	}

	renderInputField() {
		let isInputDisabled = Boolean(EnvHelper.isSpoofing || this.isMissingPartner || this.isMissingAccount || !this.hasActivePartner);
		let hintText = (EnvHelper.isSpoofing ? 'Spoofing' :
			(this.isMissingPartner ? 'Partner Required' :
				(this.isMissingAccount ? 'Account Required' :
					(!this.hasActivePartner ? 'Partner Inactive' : 'Enter Chat'))));
		return (
			<div style={{display: 'flex', backgroundColor: partnerTapPrimary, padding: 5, borderTop: 'solid 1px ' + partnerTapStroke}}>
				{this.state.lostConnection &&
				 <Dialog title={'Chat Failed'}
						 message={'Sorry, the chat connection failed. Please try again after we reconnect...'}
						 yesAction={() => window.location.reload()}/>}
				<div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{flex: 1, backgroundColor: partnerTapWhite, borderRadius: 4}}>
						<TextInputBox hintText={hintText}
									  value={this.state.currentChat}
									  isSimple={true}
									  multiLine={true}
									  maxChars={1000}
									  disabled={isInputDisabled}
									  onChange={(value) => {
										  this.blockAutoScrollForTyping = true;
										  this.setState({currentChat: value});
									  }}
									  onKeyDown={(event) => {
										  if (!event) {
											  return;
										  }
										  let shiftPressed = event.shiftKey;
										  if (event.key === 'Enter' && !shiftPressed) {
											  event.preventDefault();
											  this.sendChatMessage();
											  if (this.typingTimeout) clearTimeout(this.typingTimeout);
											  if (this.typingStoppedTimeout) clearTimeout(this.typingStoppedTimeout);
											  this.state.channel.presence.update({action: NOT_TYPING}, (error) => {
												  if (error) {
													  console.error('Error from Ably presence for NOT_TYPING on enter:', error);
													  // since this error can happen when we irrevocably lose our connection to ably, refresh the page to reconnect
													  this.setState({lostConnection: true});
												  }
											  });
										  }
										  else {
											  if (this.typingTimeout) clearTimeout(this.typingTimeout);
											  this.typingTimeout = setTimeout(() => {
												  this.state.channel.presence.update({action: TYPING}, (error) => {
													  if (error) {
														  console.error('Error from Ably presence for TYPING:', error);
													  }
												  });
											  }, 1000);

											  if (this.typingStoppedTimeout) clearTimeout(this.typingStoppedTimeout);
											  this.typingStoppedTimeout = setTimeout(() => {
												  this.state.channel.presence.update({action: NOT_TYPING}, (error) => {
													  if (error) {
														  console.error('Error from Ably presence for NOT_TYPING:', error);
													  }
												  });
											  }, 5000);
										  }
									  }}/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', padding: 10, cursor: isInputDisabled ? 'not-allowed' : 'pointer'}}
						 onClick={this.sendChatMessage}>
						<Send style={{color: partnerTapWhite}}/>
					</div>
				</div>
			</div>
		);
	}

	sendChatMessage() {
		if (!/\S/.test(this.state.currentChat) || EnvHelper.isSpoofing) {
			return;
		}

		let profile = this.props.authState.profile;
		let extras = {
			channelId: this.state.channelKey,
			publisherName: profile.firstName + ' ' + profile.lastName,
			publisherId: profile.personId,
			subscriberId: this.state.currentThread.partnerPersonId,
			subscriberName: this.state.currentThread.partnerName,
			partnerId: this.state.currentThread.partnerId
		};
		if (this.state.currentThread.saleAccountId) {
			extras.accountId = this.state.currentThread.saleAccountId;
		}
		if (this.state.currentThread.saleAccountLocationId) {
			extras.accountLocationId = this.state.currentThread.saleAccountLocationId;
		}
		let mainPayload = {chat: this.state.currentChat, extras: extras};
		this.state.channel.publish('message', mainPayload, (error) => {
			if (error) {
				EnvHelper.reportError('Error from Ably', JSON.stringify(error));
			}
		});

		let thread = this.state.currentThread;
		thread.message = this.state.currentChat;
		thread.chatDate = '' + LocalDateTime.now();

		this.setState({currentChat: ''});
		if (this.props.onChatSent) {
			this.props.onChatSent();
		}
	}

	render() {
		if (this.state.loading) return <Loading>Loading Intel...</Loading>;
		return (
			<ScrollingContainer divId={'intel_chat_page'} noHeightOffset={this.props.noHeightOffset}>
				{!(this.props.hideChatHeader && !this.isMissingPartner && !this.isMissingAccount) && this.renderHeader()}
				{this.renderMessages()}
				{this.renderInputField()}
			</ScrollingContainer>
		);
	}
}

IntelChat.propTypes = {
	currentThread: PropTypes.object,
	onPartnerSelected: PropTypes.func.isRequired,
	onAccountSelected: PropTypes.func.isRequired,
	onChatSent: PropTypes.func,
	hideChatHeader: PropTypes.bool,
	noHeightOffset: PropTypes.bool,
	authState: PropTypes.object.isRequired

};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default withRouter(connect(mapStateToProps)(IntelChat));
