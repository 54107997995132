import * as ApiHelper from '../helpers/ApiHelper';
import EnvHelper from '../helpers/EnvHelper';

// LIVE
const endpointVersion = '/v1';
const endpointActionListBase = endpointVersion + '/cosell-action-list';
const endpointWorkflowBase = endpointVersion + '/cosell-workflow';
const endpointLandingBase = endpointVersion + '/cosell-landing';

// Action Lists
export function getAllCoSellActionListsLive(search, page, pageSize, sort, selectedColumns) {
	return ApiHelper.sendGetCoSellActionListMetadata(endpointActionListBase + '?page=' + page + '&size=' + pageSize + '&sort=' + sort + '&selectedColumns=' + selectedColumns + '&searchTerm=' + search + '&searchColumn=name');
}

export function getCoSellActionListMetadata(actionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListMetadata(endpointActionListBase + '/' + actionListMetadataPublicId);
}

export function updateCoSellActionListMetadata(actionListMetadataPublicId, payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + actionListMetadataPublicId, payload);
}

export function createCoSellActionListLive(payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase, payload);
}

export function cloneCoSellActionListLive(cosellActionListMetadataPublicId, payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + cosellActionListMetadataPublicId + '/clone', payload);
}

export function deleteCoSellActionListLive(cosellActionListMetadataPublicId) {
	return ApiHelper.sendDeleteCoSellActionListMetadata(endpointActionListBase + '/' + cosellActionListMetadataPublicId);
}

export function updateCoSellActionListRecords(cosellActionListMetadataPublicId, action, payload) {
	return ApiHelper.sendPostCoSellActionListMetadata(endpointActionListBase + '/' + cosellActionListMetadataPublicId + '/record/bulk-update?action=' + action, payload);
}

// Workflows
export function getActionListWorkflows(cosellActionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/all-workflows/action-list/' + cosellActionListMetadataPublicId);
}

export function getActionListWorkflowsAvailable() {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/all-workflows');
}

export async function fetchWorkflows() {
	const response = await getActionListWorkflowsAvailable();
	return mapWorkflows(response.payload);
}

const iconMapper = {
	COSELL_PLAY: "ConnectWithoutContact",
	SALES_PLAY: "ForwardToInbox",
	THRU_CHANNEL_PLAY: "FollowTheSigns",
	CRM_TRACKING: "DriveFolderUpload",
};

const mapWorkflows = (workflows) => {
	return Object.keys(workflows).reduce((acc, key) => {
		acc[key] = {
			...workflows[key],
			MaterialIcon: iconMapper[key] || null,
		};
		return acc;
	}, {});
};

export function getActiveActionListWorkflow(cosellActionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/active-workflow/action-list/' + cosellActionListMetadataPublicId);
}

export function getActionListWorkflowSequences(workflowRecipeId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/' + workflowRecipeId + '/sequence');
}

export function getActionListWorkflowSequenceTemplates(sequenceRecipeId) {
	return ApiHelper.sendGetCoSellActionListWorkflows(endpointWorkflowBase + '/template/sequence/' + sequenceRecipeId);
}

export function getWorkflowSummaryData(cosellActionListMetadataPublicId, ownerEmailColumn, ownerNameColumn, partnerEmailColumn, partnerNameColumn, sequenceRecipeId) {
	let payload = {
		ownerEmailColumn: ownerEmailColumn,
		ownerNameColumn: ownerNameColumn,
		partnerEmailColumn: partnerEmailColumn,
		partnerNameColumn: partnerNameColumn
	};
	return ApiHelper.sendPostCoSellActionListWorkflows(endpointWorkflowBase + '/action-list-summary/action-list/' + cosellActionListMetadataPublicId + '/sequenceRecipeId/' + sequenceRecipeId, payload);
}

export function startWorkflow(sequenceRecipeId, payload) {
	return ApiHelper.sendPostCoSellActionListWorkflows(endpointWorkflowBase + '/sequence/' + sequenceRecipeId, payload);
}

export function stopWorkflow(workflowRecipeId) {
	return ApiHelper.sendDeleteCoSellWorkflow(endpointWorkflowBase + '/' + workflowRecipeId);
}

export function getCoSellAnalyticsRecords(coSellActionListMetadataPublicId, params) {
	let payload = {
		filters: params.filters || {},
		search: params.search || ''
	};
	let endpoint = endpointVersion + '/workflow-activity-report/' + coSellActionListMetadataPublicId + '/records?page=' + params.page;
	if (params.sort) endpoint += '&sort=' + params.sort;
	if (params.pageSize) endpoint += '&size=' + params.pageSize;
	return ApiHelper.sendPostCoSellAnalytics(endpoint, payload);
}

export function getCoSellAnalyticsColumns(coSellActionListMetadataPublicId) {
	return ApiHelper.sendGetCoSellAnalytics(endpointVersion + '/workflow-activity-report/' + coSellActionListMetadataPublicId + '/columns');
}

export function getCoSellAnalyticsFilters(args, search, filters, field, currentPage, pageSize) {
	let payload = {
		filters: filters || {},
		search: search || ""
	};
	return ApiHelper.sendPostCoSellAnalytics(endpointVersion + '/workflow-activity-report/' + args.coSellActionListMetadataPublicId + '/filter-data?field=' + field + '&page=' + currentPage + '&size=' + pageSize, payload);
}

export function getCoSellAnalyticsDownload(coSellActionListMetadataPublicId, filters, search) {
	let payload = {
		filters: filters || {},
		search: search || ""
	};
	return ApiHelper.sendPostCoSellAnalyticsDownload(endpointVersion + '/workflow-activity-report/' + coSellActionListMetadataPublicId + '/download', payload, "workflow_report.csv");
}

// Landing
export function getWorkflowLandingForStep(stepInstancePublicId) {
	return ApiHelper.sendGetCoSellLanding(endpointLandingBase + '/' + stepInstancePublicId);
}

export function updateWorkflowLandingForStep(stepInstancePublicId, payload) {
	return ApiHelper.sendPostCoSellLanding(endpointLandingBase + '/' + stepInstancePublicId, payload);
}

export function downloadWorkflowLandingForStep(stepInstancePublicId) {
	let downloadCoSellLandingEndpoint = EnvHelper.coSellLandingUrl + endpointLandingBase + '/' + stepInstancePublicId + '/download';
	return fetch(downloadCoSellLandingEndpoint, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	});
}

// DEMO
export function getAllCoSellActionLists() {
	return ApiHelper.sendGetReport('/cosell/allactionlists');
}

export function getCoSellActionList(actionListId, emailAccessPayload) {
	return ApiHelper.sendGetReport('/cosell/actionlist/' + actionListId, emailAccessPayload);
}

export function updateCoSellActionList(actionList) {
	return ApiHelper.sendPostReport('/cosell/actionlist', actionList);
}

export function getCoSellActionListResults(actionListId) {
	return ApiHelper.sendGetReport('/cosell/actionlist/results/' + actionListId);
}

export function cloneCoSellActionList(actionListId) {
	return ApiHelper.sendGetReport('/cosell/actionlist/clone/' + actionListId);
}

export function deleteCoSellActionList(actionListId) {
	return ApiHelper.sendGetReport('/cosell/actionlist/delete/' + actionListId);
}

export function getCoSellActionListWorkflowRecords(actionListId) {
	return ApiHelper.sendGetReport('/cosell/actionlistworkflowrecords/' + actionListId);
}

export function updateCoSellActionListWorkflowRecords(actionListId, workflowRecords) {
	return ApiHelper.sendPostReport('/cosell/actionlistworkflowrecords/' + actionListId, workflowRecords);
}

// DEMO ADMIN
export function getCoSellAdminConfigsShareActionList() {
	return ApiHelper.sendGetReport('/cosell/admin/shareactionlistconfigs');
}

export function updateCoSellAdminConfigShareActionList(shareActionListConfig) {
	return ApiHelper.sendPostReport('/cosell/admin/shareactionlistconfig/update', shareActionListConfig);
}

export function getCoSellAdminConfigsDistributeRecordsInternal() {
	return ApiHelper.sendGetReport('/cosell/admin/distributerecordsinternalconfigs');
}

export function updateCoSellAdminConfigDistributeRecordsInternal(distributeRecordsInternalConfig) {
	return ApiHelper.sendPostReport('/cosell/admin/distributerecordsinternalconfig/update', distributeRecordsInternalConfig);
}

export function getCoSellAdminConfigsDistributeRecordsExternal() {
	return ApiHelper.sendGetReport('/cosell/admin/distributerecordsexternalconfigs');
}

export function updateCoSellAdminConfigDistributeRecordsExternal(distributeRecordsExternalConfig) {
	return ApiHelper.sendPostReport('/cosell/admin/distributerecordsexternalconfig/update', distributeRecordsExternalConfig);
}

export function getCoSellAdminConfigsIntroEmail() {
	return ApiHelper.sendGetReport('/cosell/admin/introemailconfigs');
}

export function updateCoSellAdminConfigIntroEmail(introEmailConfig) {
	return ApiHelper.sendPostReport('/cosell/admin/introemailconfig/update', introEmailConfig);
}

export function getCoSellAdminConfigsCrmTracking() {
	return ApiHelper.sendGetReport('/cosell/admin/crmtrackingconfigs');
}

export function updateCoSellAdminConfigCrmTracking(crmTrackingConfig) {
	return ApiHelper.sendPostReport('/cosell/admin/crmtrackingconfig/update', crmTrackingConfig);
}
