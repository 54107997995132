import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {COLUMN_KEY_ACCOUNT_NAME, COLUMN_KEY_OPPORTUNITY_NAME} from '../../globals/Enums';
import {Routes} from '../../globals/Routes';
import ColumnHelper, {ACTIONS} from '../../helpers/ColumnHelper';
import DateHelper from '../../helpers/DateHelper';
import EnvHelper from '../../helpers/EnvHelper';
import FeatureHelper, {FEATURE_CHANNEL_VIEW_ACCOUNT_DETAIL_PAGE, FEATURE_CHANNEL_VIEW_OPP_DETAIL_PAGE} from '../../helpers/FeatureHelper';
import NumberHelper from '../../helpers/NumberHelper';
import StringHelper from '../../helpers/StringHelper';
import {
	partnerTapAppBackground,
	partnerTapInactive,
	partnerTapSecondary,
	partnerTapSecondaryLight,
	partnerTapSecondaryMedium,
	partnerTapWhite
} from '../../styles/partnertap_theme';
import LinkButton from '../buttons/LinkButton';

const MAX_CELL_LENGTH = 64;

class BasicRowRenderer extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {selectedRowIndex: -1};
	}

	render() {
		let {rowData, columnData, index} = this.props;
		let isSelected = this.state.selectedRowIndex === index;
		let rowColor = isSelected ? partnerTapSecondaryLight : (index % 2 !== 0 ? partnerTapAppBackground : partnerTapWhite);
		let separatorColor = isSelected ? partnerTapSecondaryMedium : (index % 2 === 0 ? partnerTapAppBackground : partnerTapWhite);
		let columnOrdinal = 0;
		let rowStyle = {background: rowColor, transition: 'background-color 0.1s ease-in'};
		let isRowDisabled = rowData['is_deleted'];
		return (
			<tr style={rowStyle}>
				{columnData.map((column) => {
					if (ColumnHelper.isVisible(column)) {
						column.index = columnOrdinal++;
						let displayValue;
						if (column.objectKey) {
							displayValue = rowData[column.objectKey];
							if (displayValue) displayValue = displayValue[column.key];
						}
						else {
							displayValue = rowData[column.key];
						}

						if (ColumnHelper.isBoolean(column)) {
							if (typeof displayValue === 'string') {
								displayValue = displayValue.toUpperCase() === 'TRUE';
							}
							if (column.renderFunction) {
								displayValue = column.renderFunction(displayValue, rowData);
							}
							else {
								displayValue =
									<div style={{display: 'flex', justifyContent: 'center'}}>
										{displayValue === true ? 'True' : 'False'}
									</div>;
							}
						}
						else if (displayValue || displayValue === false || displayValue === 0 || column.renderFunction) {
							if (column.renderFunction) {
								displayValue = column.renderFunction(displayValue, rowData);
							}
							else if (ColumnHelper.isDate(column)) {
								if (!column.dateTypeWasConverted && !isNaN(displayValue) && displayValue !== 0) {
									displayValue = DateHelper.getFormattedDate(new Date(DateHelper.getMillisecondsWithTimeZoneOffset(displayValue)));
								}
							}
							else if (ColumnHelper.isCurrency(column)) {
								displayValue = NumberHelper.formatCurrency(displayValue);
							}
							else if (ColumnHelper.isNumber(column)) {
								displayValue = NumberHelper.formatNumber(displayValue);
							}
							else if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_ACCOUNT_DETAIL_PAGE) && column.key === COLUMN_KEY_ACCOUNT_NAME && rowData.assetObjId) {
								displayValue =
									<LinkButton label={displayValue}
												disabled={isRowDisabled}
												onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.ACCOUNTS.ACCOUNT_DETAILS.PATH(rowData.assetObjId))}
												data-cy={StringHelper.formatKey(displayValue)}/>;
							}
							else if (FeatureHelper.isFeatureEnabled(FEATURE_CHANNEL_VIEW_OPP_DETAIL_PAGE) && column.key === COLUMN_KEY_OPPORTUNITY_NAME && rowData.opportunityObjId) {
								displayValue =
									<LinkButton label={displayValue}
												disabled={isRowDisabled}
												onClick={() => EnvHelper.push(Routes.CHANNEL_ECOSYSTEM.OPPORTUNITIES.OPPORTUNITY_DETAILS.PATH(rowData.opportunityObjId))}
												data-cy={StringHelper.formatKey(displayValue)}/>;
							}
							else if (FeatureHelper.products && ColumnHelper.isProductCode(column)) {
								let product = FeatureHelper.products.find((product) => product.productCode === displayValue);
								if (product) {
									displayValue = product.displayName;
								}
							}
							else if (FeatureHelper.roles && ColumnHelper.isRoleCode(column)) {
								let role = FeatureHelper.roles.find((role) => role.roleCode === displayValue);
								if (role) {
									displayValue = role.displayName;
								}
							}
						}

						let border = isSelected ? 'solid 1px ' + partnerTapSecondaryMedium : 'solid 1px ' + rowColor;
						let cellStyle = {
							padding: 10,
							borderTop: border,
							borderLeft: border,
							borderBottom: border,
							borderRight: 'solid 1px ' + separatorColor,
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						};

						if (isRowDisabled) {
							cellStyle.color = partnerTapInactive;
						}

						if (ColumnHelper.isDate(column) || ColumnHelper.isNumber(column) || ColumnHelper.isCurrency(column)) {
							cellStyle.textAlign = 'right';
						}

						if (this.props.rowClick && index === 0) {
							cellStyle.color = partnerTapSecondary;
						}

						if (displayValue !== 0 && !displayValue) {
							displayValue = '-';
							cellStyle.textAlign = 'center';
						}

						let isSticky = column.index === 0;
						if (isSticky) {
							cellStyle.position = 'sticky';
							cellStyle.left = 0;
							cellStyle.zIndex = 999;
							cellStyle.background = rowStyle.background;
						}

						if (column.cellColorFunction) {
							let cellColor = column.cellColorFunction(displayValue, rowData);
							if (cellColor) cellStyle.backgroundColor = cellColor;
						}

						if (column.cellStyleFunction) {
							let additionalStyles = column.cellStyleFunction(displayValue, rowData);
							if (additionalStyles) cellStyle = {...cellStyle, ...additionalStyles};
						}

						return (
							<td key={'column_' + column.index + '_row_' + index}
								className={'hoverActive'}
								onClick={() => {
									if (column.key === ACTIONS) return;
									if (this.props.rowClick) this.props.rowClick(rowData);
									this.setState({selectedRowIndex: this.state.selectedRowIndex !== -1 ? -1 : index});
								}}
								style={cellStyle}>
								{StringHelper.truncateWithToolTip(displayValue, MAX_CELL_LENGTH)}
							</td>
						);
					}
				})}
			</tr>
		);
	}
}

BasicRowRenderer.propTypes = {
	index: PropTypes.number.isRequired,
	rowData: PropTypes.object.isRequired,
	columnData: PropTypes.array.isRequired,
	rowClick: PropTypes.func
};

export default connect()(BasicRowRenderer);
