import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CRM_SALESFORCE} from '../globals/Enums';
import {partnerTapDropShadow, partnerTapPrimary, partnerTapStroke, partnerTapWhite} from '../styles/partnertap_theme';
import Pic from '../ui/Pic';
import ItemCount from './ItemCount';

export class PageHeader extends Component {

	constructor(props, context) {
		super(props, context);
		this.onTitleClick = this.onTitleClick.bind(this);
	}

	onTitleClick() {
		let link = this.accountLink;
		if (link) {
			window.open(link, '_blank');
		}
	}

	get accountLink() {
		if (this.props.authState && this.props.authState.person && this.props.authState.person.crmType === CRM_SALESFORCE && this.props.crmAccountId) {
			if (this.props.authState.profile.customCrmDomain) {
				return this.props.authState.profile.customCrmDomain + this.props.crmAccountId;
			}
			else {
				return 'https://na50.lightning.force.com/' + this.props.crmAccountId;
			}
		}
		return null;
	}

	render() {
		let isDetail = this.props.personId || this.props.accountId;
		let headerStyle = {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: 10,
			marginBottom: 5,
			backgroundColor: partnerTapWhite,
			boxShadow: partnerTapDropShadow,
			minHeight: 50
		};
		if (this.props.style) Object.assign(headerStyle, this.props.style);

		let hasLink = this.accountLink != null;
		return (
			<div style={headerStyle}>
				{isDetail ?
					<div style={{display: 'flex', alignItems: 'center', overflow: 'hidden', cursor: hasLink ? 'pointer' : 'default'}}
						 onClick={this.onTitleClick}>
						<Pic personId={this.props.personId}
							 partnerId={this.props.partnerId}
							 bizId={this.props.accountId}
							 tmpLogo={this.props.title}
							 picSize={40}/>
						<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: 10}}>
							<div style={{fontSize: 20, whiteSpace: 'nowrap'}}>
								{this.props.title}
							</div>
							<div style={{fontSize: 14, color: partnerTapStroke, overflow: 'hidden', whiteSpace: 'nowrap'}}>
								{this.props.subtitle}
							</div>
						</div>
					</div>
					:
					<div style={{display: 'flex', alignItems: 'center', textTransform: 'capitalize'}}>
						{this.props.icon &&
						 <div style={{paddingTop: 4, paddingRight: 10, color: partnerTapPrimary}}>
							 {this.props.icon}
						 </div>}
						<div style={{paddingRight: 10, fontSize: 20, whiteSpace: 'nowrap'}}>
							{this.props.title}
						</div>
						{this.props.subtitle &&
						 <div style={{paddingRight: 10, fontSize: 14, color: partnerTapStroke, overflow: 'hidden', whiteSpace: 'nowrap'}}>
							 {this.props.subtitle}
						 </div>}
						{this.props.count > 0 &&
						 <ItemCount count={this.props.count}/>}
					</div>}
				<div style={{flex: 1}}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

PageHeader.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	personId: PropTypes.string,
	partnerId: PropTypes.string,
	accountId: PropTypes.string,
	crmAccountId: PropTypes.string,
	icon: PropTypes.element,
	count: PropTypes.number,
	style: PropTypes.object,
	authState: PropTypes.object
};

function mapStateToProps(state) {
	return {
		authState: state.authState
	};
}

export default connect(mapStateToProps)(PageHeader);
