import {Block} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as AdminEndpoints from '../../endpoints/AdminEndpoints';
import DateHelper from '../../helpers/DateHelper';
import SortHelper from '../../helpers/SortHelper';
import {partnerTapWhite} from '../../styles/partnertap_theme';
import CheckboxButton from '../../ui/buttons/CheckboxButton';
import PagingBase from '../../ui/lists/PagingBase';
import OrgSelector from './shared/OrgSelector';

class AdminExcludedPartnersPage extends PagingBase {

	constructor(props, context) {
		super(props, context);

		this.saveCount = 0;

		this.state.loading = false;
		this.state.selectedOrg = null;

		this.handleOrgSelection = this.handleOrgSelection.bind(this);
		this.handleSelectAllCheck = this.handleSelectAllCheck.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
	}

	handleOrgSelection(org) {
		this.setState({selectedOrg: org, loading: true});
	}

	get storageKeyBase() {
		return 'tools_admin_excluded_partners';
	}

	get title() {
		return 'Excluded Partner Orgs';
	}

	get icon() {
		return Block;
	}

	get columnData() {
		return [
			{title: 'PartnerTap Org', key: 'partnerOrgName'},
			{
				title: 'Excluded',
				key: 'enabled',
				renderFunction: (columnValue, rowData) =>
					<div style={{display: 'flex', justifyContent: 'center'}}>
						<CheckboxButton checked={Boolean(columnValue)} onChange={() => this.handleCheck(rowData)}/>
					</div>
			},
			{title: 'Created Date', key: 'createdDate', type: 'date', renderFunction: DateHelper.epochToDate},
			{title: 'Created By', key: 'createdBy'}
		];
	}

	getRowData() {
		if (this.gettingRowData) return;
		let {columnData} = this.state;
		let params = this.processParameters();
		AdminEndpoints.fetchExcludedPartners(this.state.selectedOrg.orgId)
		.then((result) => {
			if (this.unmounted) return;
			if (this.hasRowData(result)) {
				result.payload = SortHelper.filterSearchObjectContains(params.search, result.payload, SortHelper.keysFromColumns(columnData));
				if (params.sort) result.payload = SortHelper.sortByNullLast(result.payload, params.sort);
			}
			this.processData(params, result);
		})
		.catch((error) => {
			this.processError(error);
		});
	}

	render() {
		let allChecked = this.areAllOrgsSelected;
		return (
			<div key={allChecked}>
				<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: partnerTapWhite}}>
					<div style={{padding: 10}}>
						<OrgSelector onOrgSelection={this.handleOrgSelection}/>
					</div>
					<div style={{display: 'flex', alignItems: 'center', paddingRight: 20}}>
						<CheckboxButton checked={allChecked} disabled={!this.state.selectedOrg} onChange={this.handleSelectAllCheck}/>
						Exclude All Partner Orgs
					</div>
				</div>
				{Boolean(this.state.selectedOrg) && super.render()}
			</div>
		);
	}

	handleCheck(partnerOrg) {
		partnerOrg.enabled = !partnerOrg.enabled;
		this.saveChanges(partnerOrg);
	}

	handleSelectAllCheck(event) {
		let allChecked = event.target.checked;
		this.state.rowData.forEach((partnerOrg) => {
			if (partnerOrg.enabled !== allChecked) {
				partnerOrg.enabled = allChecked;
				this.saveChanges(partnerOrg);
			}
		});
	}

	get areAllOrgsSelected() {
		return !(this.state.rowData.find((partnerOrg) => !partnerOrg.enabled));
	}

	saveChanges(partnerOrg) {
		this.saveCount++;
		AdminEndpoints.saveExcludedPartner(this.state.selectedOrg.orgId, partnerOrg.partnerOrgId, partnerOrg.enabled)
		.then(() => {
			console.info('Saved changed', this.state.selectedOrg);
		})
		.catch((error) => {
			console.error('Error setting org as excluded partner', error);
			this.setState({errorMessage: 'Error setting org as excluded partner: ' + error, loading: false});
		})
		.finally(() => {
			this.saveCount--;
			if (this.saveCount === 0) {
				this.forceUpdate();
			}
		});
	}
}

AdminExcludedPartnersPage.propTypes = {
	state: PropTypes.shape({
		loading: PropTypes.bool.isRequired
	})
};

export default connect()(AdminExcludedPartnersPage);
